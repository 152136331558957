import { type ColormapDictionary } from 'common/types';

export const TEMPERATURE_COLORMAP: ColormapDictionary = {
  0x001: '#30123B',
  0x002: '#3E3994',
  0x003: '#455ED3',
  0x004: '#4681F7',
  0x005: '#3AA3FC',
  0x006: '#23C4E3',
  0x007: '#18DEC0',
  0x008: '#2DF09D',
  0x009: '#5CFC70',
  0x010: '#90FF48',
  0x011: '#B6F735',
  0x012: '#D7E535',
  0x013: '#F0CC3A',
  0x014: '#FDAE35',
  0x015: '#FC8825',
  0x016: '#F26014',
  0x017: '#E04008',
  0x018: '#C52603',
  0x019: '#A21201',
  0x020: '#7A0403',
};

export const TEMPERATURE_COLORMAP_LABELS = ['< -4°C', '0', '4', '8', '12', '16', '20', '>23°C'];
