import { type AxiosResponse } from 'axios';

import { getExternalData } from 'services/rest/restApiService';
import { type CompositesProvidersApiResponse, type CompositesResultsApiResponse } from 'domain/composites/types';
import { COMPOSITES_API_URLS } from './compositesApiUrls';

export const compositesAPI = {
  getProviders: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<CompositesProvidersApiResponse>> =>
    getExternalData(COMPOSITES_API_URLS.getProviders, { limit, offset }),

  getResults: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<CompositesResultsApiResponse>> =>
    getExternalData(COMPOSITES_API_URLS.getResults, { limit, offset }),
};
