import { useEffect, useState } from 'react';

export const useRefreshLayer = (dependencies: (string | number | boolean)[]) => {
  const [visible, setVisible] = useState(true);

  const dependencyList = JSON.stringify(dependencies);

  useEffect(() => {
    setVisible(false);

    const timeout = setTimeout(() => setVisible(true), 1);

    return () => clearTimeout(timeout);
  }, [dependencyList]);

  return { visible };
};
