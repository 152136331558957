import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { type MultilayerTooltipData } from 'common/types/mapData';
import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { debounce } from 'common/utils';
import { buildMultilayerViewTooltipStringHelper, checkIsOutsideBounds } from 'common/utils/map';
import { MapTooltip } from 'ui/map/tooltip';
import { Loading } from 'ui/molecules/loading';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { type UrlWithProps } from 'services/titiler/types';

interface MultipleLayersHoverToValuesTitilerTooltipProps {
  urlsWithBounds: UrlWithProps[];
}

export const MultipleLayersHoverToValuesTitilerTooltip = ({
  urlsWithBounds,
}: MultipleLayersHoverToValuesTitilerTooltipProps) => {
  const [tooltip, setTooltip] = useState<MultilayerTooltipData | undefined>();

  const map = useMap();

  const { extentLatLngBounds, isInfoLoading } = useGetExtentPolygon(urlsWithBounds[0]?.url);

  map.off('click');
  map.off('mousemove');

  map.on(
    'mousemove',
    debounce(async (event: { originalEvent: PointerEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      if (!urlsWithBounds.length || checkIsOutsideBounds(latLng, extentLatLngBounds)) {
        return;
      }

      titilerApiService.getPointsArrayInfo(urlsWithBounds, latLng?.lng, latLng?.lat).then((response) =>
        setTooltip({
          lat: latLng?.lat,
          lng: latLng?.lng,
          series: response
            .filter((result) => result?.info?.values?.[0])
            .map((result) => ({
              label: urlsWithBounds.find(({ name }) => name === result.name)?.label || '',
              value: result.info.values[0],
            })),
        }),
      );
    }, TOOLTIP_DISPLAY_DELAY),
  );

  if (isInfoLoading) {
    return <Loading />;
  }

  return tooltip ? (
    <MapTooltip tooltipData={tooltip} content={buildMultilayerViewTooltipStringHelper(tooltip)} />
  ) : null;
};
