import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';

import { useUserStore } from 'infrastructure/user/userStore';
import { LOCAL_STORAGE_REFRESH_TOKEN_NAME } from 'common/constants/services';
import { type Token } from 'domain/user/types';
import { TokenType } from 'domain/user/enums';
import { LOGIN_REFRESH_ADVANCE } from 'domain/user/constants';
import { getTokenLifetimeHelper } from 'domain/user/utils';
import { localStorageService } from './localStorageService';

export const useWatchTokenValidityWorker = async () => {
  const { showLoginPrompt } = useUserStore();

  const localStorageRefreshToken = localStorageService.getValue<string | null>(LOCAL_STORAGE_REFRESH_TOKEN_NAME, null);

  const tokenName = TokenType.REFRESH;
  const token = localStorageRefreshToken;

  const decodedToken: unknown = token && token.length > 0 && jwt_decode(token as string);
  const tokenLifetime: unknown = decodedToken && getTokenLifetimeHelper(decodedToken as Token);

  useEffect(() => {
    if (!tokenLifetime) {
      return;
    }

    if (Number(tokenLifetime) - LOGIN_REFRESH_ADVANCE <= 0) {
      showLoginPrompt();
    }

    const timeout = setTimeout(() => {
      showLoginPrompt();
    }, Number(tokenLifetime) - LOGIN_REFRESH_ADVANCE);

    return () => clearTimeout(timeout);
  }, [decodedToken, showLoginPrompt, tokenLifetime, tokenName]);
};
