import { SIDEBAR_TOGGLE_DELAY } from 'common/constants';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';

export const useGetSidebarHomeButtonHover = () => {
  const showSidebar = useSidebarStore.use.unfoldSidebar();
  const isSidepanelOpen = useSidebarStore.use.isSidebarPanelOpen();

  const canOpenSidebar = !isSidepanelOpen;

  const handleHomeButtonHover = () => {
    if (canOpenSidebar) {
      setTimeout(() => {
        showSidebar();
      }, SIDEBAR_TOGGLE_DELAY);
    }
  };

  return { handleHomeButtonHover };
};
