import styled from 'styled-components';

import { Tag } from 'ui/atoms/tag';
import { type TagProps } from 'ui/atoms/tag/Tag';

export interface TagData extends TagProps {
  id: number;
}

export interface TagListProps {
  header?: string;
  tags: TagData[];
}

export const TagList = ({ header, tags }: TagListProps) => (
  <StyledTagList>
    {header}
    <StyledTags>
      {tags.map(({ id, variant, text, checked, onClick }) => (
        <Tag key={id} variant={variant} text={text} checked={checked} onClick={onClick} />
      ))}
    </StyledTags>
  </StyledTagList>
);

const StyledTagList = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-transform: capitalize;
`;

const StyledTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 4px;
`;

TagList.displayName = 'TagList';
