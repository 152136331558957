import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { MINE_EXTRACTION_PATH_NAME } from 'domain/extraction/constants';
import { useExtractionLayersVisibility } from './useExtractionLayersVisibility';

export const useExtractionLifecycleEvents = () => {
  const { areaId } = useParams();
  const { pathname } = useLocation();

  const { clearClassesVisibility } = useExtractionLayersVisibility();
  const removeWarningErrorsByViewPath = useErrorsStore.use.removeWarningErrorsByViewPath();

  useEffect(() => {
    return () => {
      clearClassesVisibility();
      removeWarningErrorsByViewPath(MINE_EXTRACTION_PATH_NAME);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, pathname]);
};
