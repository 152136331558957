import styled from 'styled-components';

import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';

interface Props {
  url: string;
  bidxArray: string[];
  rescaleArray: number[][];
}

export const AreaCardAvatar = ({ url, bidxArray, rescaleArray }: Props) => {
  const aoiImageUrl = titilerApiAdapter.buildGrayscaleTitilerImageUrl(url, {
    bidx: bidxArray[0],
    rescale: rescaleArray[0],
    maxSize: 512,
    imageHeight: 350,
    imageWidth: 350,
    colorMap: 'gray',
  });

  if (!aoiImageUrl) {
    return null;
  }

  return (
    <StyledAreaPreview
      src={aoiImageUrl}
      alt="Area preview"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.remove();
      }}
    />
  );
};

const StyledAreaPreview = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
