import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { useQueryParameter } from 'common/navigation/hooks';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { type GeoJsonColorsDictionary } from 'common/types/mapData';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { getInfrastructureLayerColor } from 'domain/areas/helpers';

export const useGetInfrastructureLayerColor = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const areReportsActive = useGetAreReportsActiveUseCase();

  return (colors: GeoJsonColorsDictionary) => getInfrastructureLayerColor(colors, areReportsActive, isComparisonActive);
};
