import { type ColormapDictionary } from 'common/types';

export const parseColormap = (colormap: ColormapDictionary) => {
  const arrayFromColormap: [string, string][] = Object.entries(colormap);
  const sortedArray = arrayFromColormap.sort((a, b) => Number(a[0]) - Number(b[0]));
  const domain: number[] = sortedArray.map((item) => Number(item[0]));
  const colors = sortedArray.map((item) => item[1]);
  const linearGradient = domain.map((value: number) => `${colormap[value]} ${value * 100}%`).join(', ');

  return { colors, linearGradient };
};
