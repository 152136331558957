import { type ListItemStatus } from 'common/types';
import { LayerManagerLegendItem } from 'components/layerManager/legacy/LegendItem';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { LayerManagerThresholdSlider } from 'components/layerManager/ThresholdSlider';
import { type MoistureCommonProps } from 'domain/water/types';
import { MOISTURE_SLIDER_CONFIG } from 'domain/water/configs';

export const MoistureLayerManagerLegendItem = ({ indicator, layerName, min, max }: MoistureCommonProps) => {
  const { id, indicator_name, min_value, max_value, colormap, precision } = indicator;

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const toggleDataLayerVisibility = useLayerManagerStore.use.toggleDataLayerVisibility();

  const layerManagerOption = {
    id,
    name: indicator_name,
    label_name: indicator_name,
    color: '',
    status: 'active' as ListItemStatus,
  };

  const customMin = min_value === null ? min : min_value;
  const customMax = max_value === null ? max : max_value;

  return (
    <LayerManagerLegendItem
      option={layerManagerOption}
      objectVisibility={isDataLayerVisible}
      handleObjectsVisibility={toggleDataLayerVisibility}
      iconColormap={colormap}
    >
      <LayerManagerThresholdSlider
        layerRange={{
          min: customMin,
          max: customMax,
          initialValue: customMin,
        }}
        layerName={layerName}
        config={{ ...MOISTURE_SLIDER_CONFIG, precision, step: 10 ** -precision }}
      />
    </LayerManagerLegendItem>
  );
};
