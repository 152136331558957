import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type WaterbodyType, type NotificationsMockInterface, type ReclamationAreaType } from 'domain/monitoring/types';

interface MonitoringInfoStore {
  notifications: NotificationsMockInterface | undefined;
  setNotifications: (notifications: NotificationsMockInterface | undefined) => void;

  waterbodies: WaterbodyType[] | undefined;
  setWaterbodies: (waterbodies: WaterbodyType[] | undefined) => void;

  reclamationAreas: ReclamationAreaType[] | undefined;
  setReclamationAreas: (reclamationAreas: ReclamationAreaType[] | undefined) => void;
}

const useMonitoringStoreBase = create<MonitoringInfoStore>()(
  devtools(
    (set) => ({
      notifications: undefined,
      setNotifications: (notifications: NotificationsMockInterface | undefined) =>
        set({ notifications: notifications }, false, 'MonitoringInfoStore/notifications'),

      waterbodies: undefined,
      setWaterbodies: (waterbodies: WaterbodyType[] | undefined) =>
        set({ waterbodies: waterbodies }, false, 'MonitoringInfoStore/waterbodies'),

      reclamationAreas: undefined,
      setReclamationAreas: (reclamationAreas: ReclamationAreaType[] | undefined) =>
        set({ reclamationAreas: reclamationAreas }, false, 'MonitoringInfoStore/reclamationAreas'),
    }),
    { name: 'MonitoringInfoStore' },
  ),
);

export const useMonitoringInfoStore = createSelectors(useMonitoringStoreBase);
