import { Comparator } from 'components';
import { useTimeline } from 'components/Timeline/useTimeline';
import { Timeline } from 'components/Timeline/Timeline';
import { Graph } from 'components/graph';
import { useQueryParameter } from 'common/navigation/hooks';
import { GREENERY_OBJECT_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { type Datetime } from 'common/types/time';

interface SegmentationTimelineProps {
  timestamps: Datetime[];
  areTimestampsLoading: boolean;
  validTimestamp: string | null;
  validSecondTimestamp: string | null;
  isComparisonActive: boolean;
  areReportsActive: boolean;
}

export const SegmentationTimeline = ({
  timestamps,
  areTimestampsLoading,
  validTimestamp,
  validSecondTimestamp,
  isComparisonActive,
  areReportsActive,
}: SegmentationTimelineProps) => {
  const greeneryObjectQuery = useQueryParameter(GREENERY_OBJECT_QUERY_PARAMETER);

  const {
    filteredTimestamps,
    firstDate,
    lastDate,
    rangeValue,
    selectedTimestamps,
    setRangeValue,
    isTimelineShowed,
    firstTimestamp,
    lastTimestamp,
  } = useTimeline(timestamps, !isComparisonActive && !areReportsActive);

  return (
    <>
      {isComparisonActive && !areReportsActive && (
        <Comparator
          variant="NEW_TO_OLDER"
          timestamps={timestamps}
          areTimestampsLoading={areTimestampsLoading}
          isCompareDisabled={!validTimestamp || !validSecondTimestamp || !greeneryObjectQuery}
          noButton
        />
      )}
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          visible: !isComparisonActive && !areReportsActive,
        }}
      />
      {!areReportsActive && (
        <Graph
          isTimelineVisible={isTimelineShowed}
          dateRange={{ firstDate: firstTimestamp, lastDate: lastTimestamp }}
        />
      )}
    </>
  );
};
