import { useEffect } from 'react';

import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useTimelineService } from 'components/Timeline/timelineService';
import { quarterlyDataService } from 'views/greenery/quarterly/quarterlyDataService';
import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';
import { getQuarterlyResultId } from 'components/Timeline/helpers';

export const useUpdateQuarterlyComparisonCogsUseCase = (areaId: number) => {
  const setComparatorUrl = useGreeneryStore.use.setComparatorUrl();

  const { comparatorCogUrl, execute } = greeneryRepository.useGetQuarterlySegmentationsComparatorCog();

  const { quarterlyResults } = quarterlyDataService.useFetchQuarterlyResults(areaId);

  const { timestamp, secondTimestamp } = useTimelineService();

  const saveComparatorCogsToStore = () => {
    if (comparatorCogUrl) {
      setComparatorUrl(comparatorCogUrl);
    }
  };

  const updateQuarterlyComparatorCogs = (): void => {
    if (typeof secondTimestamp === 'string' && timestamp) {
      const firstComparedDateId = quarterlyResults && getQuarterlyResultId(timestamp, quarterlyResults);
      const secondComparedDateId = quarterlyResults && getQuarterlyResultId(secondTimestamp, quarterlyResults);

      saveComparatorCogsToStore();
      execute(firstComparedDateId, secondComparedDateId);
    }
  };

  useEffect(() => {
    saveComparatorCogsToStore();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparatorCogUrl]);

  return { updateQuarterlyComparatorCogs };
};
