import { type ChangeEvent } from 'react';

import { Input } from 'ui/atoms/input';
import { type InputVariant } from 'ui/atoms/input/Input';
import { Label, type LabelVariant } from 'ui/atoms/label/Label';
import { type Palette } from 'ui/types';

export type LabeledInputVariant = 'PRIMARY' | 'SECONDARY' | 'BASIC';

type InputStyle = Required<{
  labelVariant: LabelVariant;
  inputVariant: InputVariant;
}>;

const styles: Palette<LabeledInputVariant, InputStyle> = {
  PRIMARY: {
    labelVariant: 'VERTICAL',
    inputVariant: 'PRIMARY',
  },
  SECONDARY: {
    labelVariant: 'VERTICAL',
    inputVariant: 'SECONDARY',
  },
  BASIC: {
    labelVariant: 'HORIZONTAL',
    inputVariant: 'BASIC',
  },
};

export interface InputElementProps {
  value: string;
  setValue: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  width?: number;
  variant?: LabeledInputVariant;
  error?: string;
  disabled?: boolean;
}

export const LabeledInput = ({
  value,
  setValue,
  name,
  type,
  label,
  placeholder,
  width,
  variant,
  error,
  disabled,
}: InputElementProps) => (
  <Label content={label || ''} variant={styles[variant!].labelVariant} fontSize={8}>
    <Input
      value={value}
      onChange={setValue}
      name={name}
      type={type}
      placeholder={placeholder}
      autoComplete="on"
      width={width}
      $variant={styles[variant!].inputVariant}
      $error={!!error}
      disabled={disabled}
    />
  </Label>
);

LabeledInput.defaultProps = {
  variant: 'PRIMARY' as LabeledInputVariant,
};

LabeledInput.displayName = 'LabeledInputElement';
