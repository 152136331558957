import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAoiNumber } from 'common/navigation/hooks';
import { type LayerObjectWithUrl } from 'common/types/mapData';
import { useGetInfrastructureLayerColor } from 'views/areas/areaDetails/shapesCollection/useGetInfrastructureLayerColor';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useInfrastructureLayersVisibility } from './useInfrastructureLayersVisibility';
import { useInitializeLayersOrderRecord } from '../../draggableLayerManager/useInitializeLayersOrderRecord';
import { LAYERS_KEYS } from '../../draggableLayerManager/draggableLayersKeys';
import { LayerManagerLegendDndList } from '../../LegendDndListEnhanced';
import { useLayerOrderStore } from '../../layersOrderStore';
import { FoldableSection } from '../../foldableSection';
import { TransientStateCheckbox } from '../../TransientStateCheckbox';

interface Props {
  id: LAYERS_KEYS;
  areaName: string;
}

export const InfrastructureSection = ({ id, areaName }: Props) => {
  const areaId = useAoiNumber();
  const { pathname } = useLocation();

  const { shapes } = areasRepository.useFetchInfrastructureShapes(areaName);

  const { classesVisibility, toggleMultipleQueries, updateClassesVisibility } = useInfrastructureLayersVisibility();

  useEffect(() => {
    updateClassesVisibility(shapes.reduce((acc, { name }) => ({ ...acc, [name]: true }), {}));
  }, [shapes?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const { nestedLayersOrderRecord, setNestedLayersOrderRecord } = useLayerOrderStore();

  const layersOrderRecord = nestedLayersOrderRecord
    ? nestedLayersOrderRecord[pathname]?.[LAYERS_KEYS.INFRASTRUCTURE]
    : null;

  const setLayersOrderRecord = (layersOrder: number[]): void => {
    setNestedLayersOrderRecord(pathname, LAYERS_KEYS.INFRASTRUCTURE, layersOrder);
  };

  useInitializeLayersOrderRecord(id, Array.from(Array(shapes.length).keys()));

  const getInfrastructureLayerColor = useGetInfrastructureLayerColor();

  const infrastructureLayers: LayerObjectWithUrl[] = shapes.map(({ id, name, label_name, colors }) => ({
    id,
    name,
    label_name,
    color: getInfrastructureLayerColor(colors),
    status: 'active',
    url: '',
  }));

  return (
    <FoldableSection
      id={id}
      headerContent={
        <TransientStateCheckbox
          title="additional data layers"
          description={'Additional data layers'}
          options={infrastructureLayers}
          objectsVisibility={classesVisibility}
          updateLayersVisibility={updateClassesVisibility}
          disabled={!infrastructureLayers.length}
        />
      }
      disabled={!infrastructureLayers.length}
    >
      <LayerManagerLegendDndList
        areaId={areaId}
        layersOptions={infrastructureLayers}
        layersVisibility={classesVisibility}
        toggleLayerVisibility={toggleMultipleQueries}
        layersOrderRecord={layersOrderRecord}
        setLayersOrderRecord={setLayersOrderRecord}
      />
    </FoldableSection>
  );
};
