import { useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';

import { type SetCurrentLevelOfDetail } from 'domain/displacements/types';

const get1500OrPowedZoom = (currentZoom: number) => (currentZoom > 0 ? Math.pow(2, currentZoom) : 1500);

export const ZoomTracker = ({ setCurrentLevelOfDetail }: SetCurrentLevelOfDetail) => {
  const map = useMapEvents({
    zoom() {
      setCurrentLevelOfDetail(get1500OrPowedZoom(map.getZoom()));
    },
  });

  useEffect(() => {
    setCurrentLevelOfDetail(get1500OrPowedZoom(map.getZoom()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
