import { limitNumberToNonNegative } from 'common/utils';
import { userRepository } from 'infrastructure/user/userRepository';

export const useGetAvailableQuota = (activeSubscriptionCapacity: number | undefined): number | undefined => {
  const { userSubscriptionDetails } = userRepository.useFetchUserSubscriptionDetails();

  const normalizedAvailableQuota =
    userSubscriptionDetails &&
    limitNumberToNonNegative(userSubscriptionDetails.quota_limit - userSubscriptionDetails.current_usage);

  const availableQuota = userSubscriptionDetails ? normalizedAvailableQuota : activeSubscriptionCapacity;

  return availableQuota;
};
