import { useGetAoiName } from 'common/hooks/getAoiName';
import { InfrastructureSection } from 'components/layerManager/commonContent/infrastructure/InfrastructureSection';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayerManager/draggableLayersKeys';

export const useGetInfrastructureLayersItem = () => {
  const { areaName } = useGetAoiName();

  return areaName
    ? [
        {
          id: LAYERS_KEYS.INFRASTRUCTURE,
          name: 'infrastructure',
          content: <InfrastructureSection id={LAYERS_KEYS.INFRASTRUCTURE} {...{ areaName }} />,
        },
      ]
    : [];
};
