import { DateSelect } from 'ui';
import { CreatorDataColumn } from 'ui/creator/creatorDataColumn';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { SegmentationSingleDateReportsCreatorContent } from 'views/reports/creator/content/views/SegmentationSingleDateReportsCreatorContent';
import { SegmentationDoubleDateReportsCreatorContent } from 'views/reports/creator/content/views/SegmentationDoubleDateReportsCreatorContent';
import { ReportsCreatorDataColumnDatesLabel } from 'views/reports/creator/components/ReportsCreatorDataColumnDatesLabel';
import { ReportsCreatorSavedDataColumn } from 'views/reports/creator/components/ReportsCreatorSavedDataColumn';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { WithDailySegmentationCogResult } from 'views/greenery/segmentation/WithDailySegmentationCogResult';
import { useTimelineService } from 'components/Timeline/timelineService';

interface SegmentationReportsCreatorContentProps {
  areaId: number;
}

export const SegmentationReportsCreatorContent = ({ areaId }: SegmentationReportsCreatorContentProps) => {
  const { timestamps, areTimestampsLoading, validTimestamp } = useGreeneryTimestamps();
  const { timestamp, changeTimestamp } = useTimelineService();

  const { greeneryObjects, greeneryObjectsLoading } = greeneryRepository.useFetchGreeneryObjects();

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const firstDateReportsSegmentationDataset = useReportsStore.use.firstDateReportsSegmentationDataset();
  const secondDateReportsSegmentationDataset = useReportsStore.use.secondDateReportsSegmentationDataset();
  const clearReportsSegmentationDataset = useReportsStore.use.clearReportsSegmentationDataset();

  return isCompareDatesType ? (
    <>
      {firstDateReportsSegmentationDataset && (
        <ReportsCreatorSavedDataColumn
          dataset={firstDateReportsSegmentationDataset}
          clearDataset={clearReportsSegmentationDataset}
        />
      )}
      {secondDateReportsSegmentationDataset && (
        <ReportsCreatorSavedDataColumn
          dataset={secondDateReportsSegmentationDataset}
          clearDataset={clearReportsSegmentationDataset}
        />
      )}
      {!secondDateReportsSegmentationDataset &&
        !areTimestampsLoading &&
        (validTimestamp ? (
          <WithDailySegmentationCogResult
            Component={SegmentationDoubleDateReportsCreatorContent}
            props={{
              greeneryObjects,
              greeneryObjectsLoading,
              timestamp: validTimestamp,
              areaId,
              timestamps,
              changeTimestamp,
            }}
            timestamp={validTimestamp}
          />
        ) : (
          <CreatorDataColumn
            data={[]}
            isConfirmDisabled
            topContent={
              <>
                <ReportsCreatorDataColumnDatesLabel no={firstDateReportsSegmentationDataset ? 2 : 1} />
                <DateSelect
                  {...{ timestamps, timestamp, changeTimestamp }}
                  forbiddenTimestamp={firstDateReportsSegmentationDataset?.timestamp}
                />
              </>
            }
          />
        ))}
    </>
  ) : (
    validTimestamp && (
      <WithDailySegmentationCogResult
        Component={SegmentationSingleDateReportsCreatorContent}
        props={{ greeneryObjects, greeneryObjectsLoading, timestamp: validTimestamp, areaId }}
        timestamp={validTimestamp}
      />
    )
  );
};
