import {
  useAoiNumber,
  useHandleQueryParameterByPrimitiveValue,
  useTreeShakeQueryParams,
} from 'common/navigation/hooks';
import { DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { ReturnButton } from 'ui/molecules/returnButton/ReturnButton';
import { AreaInfo } from 'ui/organisms/areaInfoDrawer';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { PROSPECTING_VIEW_CONFIG } from 'domain/prospecting/configs';
import { ProspectingLayerManager } from './ProspectingLayerManager';

export const Prospecting = () => {
  const areaId = useAoiNumber();

  useTreeShakeQueryParams(PROSPECTING_VIEW_CONFIG);

  const [selectedDepositSite, setSelectedDepositSite] = useHandleQueryParameterByPrimitiveValue(
    DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER,
  );

  const { depositClassificationSites } = explorationRepository.useFetchDepositClassificationSites(areaId, 1);
  const selectedSite = depositClassificationSites.find((site) => site.properties.index === selectedDepositSite);

  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <AreaDetailsLayout
      returnButton={
        <ReturnButton
          label="Back to full site"
          visible={!!selectedSite}
          onClick={() => setSelectedDepositSite(undefined)}
        />
      }
      toolboxContent={<>{!areReportsActive && <LayerManagerControlButton />}</>}
    >
      {!areReportsActive && (
        <>
          <AreaInfo />
          <ProspectingLayerManager />
        </>
      )}
    </AreaDetailsLayout>
  );
};
