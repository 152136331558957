/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  type LayersStatsDictionary,
  type LayerManagerSliderConfigLegacy,
  type LayersTresholdsDictionary,
  type LayersVisibilityDictionary,
} from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { LabeledCheckbox } from 'ui';
import { LayerManagerLegendDndList } from './LegendDndList';

interface LayerManagerLegendProps {
  areaId: number;
  options: LayerObject[];
  objectsVisibility: LayersVisibilityDictionary;
  toggleLayerVisibility: (name: string) => void;
  updateLayersVisibility: (layers: LayersVisibilityDictionary) => void;
  sliderConfig?: LayerManagerSliderConfigLegacy;
  isSliderWithInput?: boolean;
  mineralStats?: LayersStatsDictionary;
  layersTresholds?: LayersTresholdsDictionary;
  changeLayerTreshold?: (layerState: LayersTresholdsDictionary) => void;
  title?: string;
  noBadges?: boolean;
  radio?: boolean;
  lowerClearance?: number;
  isDndView?: boolean;
  layersOrderRecord?: Record<string, number[]> | null;
  setLayersOrderRecord?: (key: string, layersOrder: number[]) => void;
  isBoxHidden?: boolean;
  children?: ReactNode;
}

export const LayerManagerLegend = ({
  areaId,
  options,
  objectsVisibility,
  toggleLayerVisibility,
  updateLayersVisibility,
  sliderConfig,
  isSliderWithInput,
  mineralStats,
  layersTresholds,
  changeLayerTreshold,
  title = 'all classes',
  noBadges,
  radio,
  lowerClearance,
  isDndView,
  layersOrderRecord,
  setLayersOrderRecord,
  isBoxHidden,
  children,
}: LayerManagerLegendProps) => {
  const [availableLayers, setAvailableLayers] = useState<LayersVisibilityDictionary>({});
  const [allLayersChecked, setAllLayersChecked] = useState(false);
  const [transitionalState, setTransitionalState] = useState(false);

  useEffect(() => {
    const layers: LayersVisibilityDictionary = {};

    for (const { name } of options) {
      layers[name] = !!objectsVisibility[name];
    }
    setAvailableLayers(layers);

    if (Object.values(layers).every((value) => !value)) {
      setAllLayersChecked(false);
      setTransitionalState(false);
      return;
    }

    if (Object.values(layers).every((value) => value)) {
      setAllLayersChecked(true);
      setTransitionalState(false);
      return;
    }

    setTransitionalState(true);
  }, [options, objectsVisibility]);

  if (!options.length) {
    return null;
  }

  const sameStateLayers = { ...availableLayers };
  Object.keys(sameStateLayers).forEach((key) => (sameStateLayers[key] = !allLayersChecked));

  return (
    <>
      {options.length && (
        <HeaderWrapper>
          <LabeledCheckbox
            variant={transitionalState ? 'WIDE_TRANSITIONAL' : 'WIDE'}
            onChange={() => updateLayersVisibility(sameStateLayers)}
            checked={allLayersChecked || transitionalState}
            isBoxHidden={isBoxHidden || options.length <= 1}
          >
            {title}
          </LabeledCheckbox>
          {children}
        </HeaderWrapper>
      )}
      <Wrapper>
        <LayerManagerLegendDndList
          areaId={areaId}
          objectsVisibility={objectsVisibility}
          options={options}
          toggleLayerVisibility={toggleLayerVisibility}
          changeLayerTreshold={changeLayerTreshold}
          sliderConfig={sliderConfig}
          isSliderWithInput={isSliderWithInput}
          mineralStats={mineralStats}
          layersTresholds={layersTresholds}
          noBadges={noBadges}
          radio={radio}
          lowerClearance={lowerClearance}
          isDndView={isDndView}
          layersOrderRecord={layersOrderRecord}
          setLayersOrderRecord={setLayersOrderRecord}
        />
      </Wrapper>
    </>
  );
};

const HeaderWrapper = styled.div`
  padding: 10px 0;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.selectGray};
  margin: 0 -1rem;
`;
