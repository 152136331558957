/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ProductProvider } from './types';

export const areProductProviderResultsValid = (results: any): results is ProductProvider[] => {
  return (
    Array.isArray(results) &&
    results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.name === 'string' &&
        typeof result.label === 'string' &&
        typeof result.resolution === 'number' &&
        typeof result.description === 'string' &&
        typeof result.enabled === 'boolean',
    )
  );
};
