import { type CSSProperties, type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { IconWrapper } from 'ui/atoms/iconWrapper';
import { type Palette } from 'ui/types';

type MapControlButtonVariants = 'DEFAULT' | 'UPPER' | 'LOWER';

type MapControlButtonStyles = Required<
  Pick<
    CSSProperties,
    'borderTopLeftRadius' | 'borderTopRightRadius' | 'borderBottomRightRadius' | 'borderBottomLeftRadius'
  >
>;

const styles: Palette<MapControlButtonVariants, MapControlButtonStyles> = {
  DEFAULT: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  UPPER: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  LOWER: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
};

interface MapControlButtonProps {
  onClick: () => void;
  icon: ReactNode;
  variant?: MapControlButtonVariants;
  testid?: string;
  active?: boolean;
  disabled?: boolean;
  iconWidth?: number;
  iconColor?: string;
}

export const MapControlButton = ({
  onClick,
  icon,
  variant = 'DEFAULT',
  iconWidth,
  iconColor,
  active,
  disabled,
  testid,
}: MapControlButtonProps) => {
  return (
    <ButtonBody {...{ variant, active, disabled, onClick }} data-testid={testid}>
      <IconWrapper {...{ active, disabled, iconWidth, iconColor }}>{icon}</IconWrapper>
    </ButtonBody>
  );
};

interface ButtonBodyProps {
  variant?: MapControlButtonVariants;
  active?: boolean;
  disabled?: boolean;
  topOffset?: number;
}

const ButtonBody = styled.button<ButtonBodyProps>`
  display: grid;
  width: 30px;
  height: 30px;
  padding: 0.5rem;
  margin: 0;
  border: none;
  border-top-left-radius: ${({ variant }) => styles[variant!].borderTopLeftRadius}px;
  border-top-right-radius: ${({ variant }) => styles[variant!].borderTopRightRadius}px;
  border-bottom-right-radius: ${({ variant }) => styles[variant!].borderBottomRightRadius}px;
  border-bottom-left-radius: ${({ variant }) => styles[variant!].borderBottomLeftRadius}px;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  place-content: center;
  font-size: 24px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  transition: margin-top 200ms ease-in-out;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.midGray};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.black};

      &:hover {
        background-color: ${({ theme }) => theme.color.white};
      }
    `}

  &:active {
    transform: scale(0.9);
  }
`;
