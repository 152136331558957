import chroma from 'chroma-js';

import { useLayerOrderStore } from 'components';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { useSortedNestedLayersData } from 'components/layerManager/useSortedNestedLayersData';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { type SurfaceMineralsDisplayed } from 'domain/exploration/types';
import { useMlLayersVisibility } from './useSurfaceLayersVisibility';
import { ExplorationMapLayer } from '../ExplorationMapLayer';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

interface MlMineralsMapLayersProps {
  minerals: SurfaceMineralsDisplayed[];
}

export const MlMineralsMapContent = ({ minerals }: MlMineralsMapLayersProps) => {
  const { nestedLayersOrderRecord } = useLayerOrderStore();
  const sortedMinerals = useSortedNestedLayersData(
    nestedLayersOrderRecord,
    EXPLORATION_LAYERS_KEYS.ML,
    minerals,
    'mineral_id',
  );

  const { classesVisibility: layersVisibility } = useMlLayersVisibility();

  return (
    <>
      {sortedMinerals.map(({ id, name, url, color, mineral_id }) => (
        <WithLayerIndex
          key={id}
          Component={ExplorationMapLayer}
          props={{
            name: `ml_${name}`,
            url,
            isVisible: layersVisibility[name] || false,
            builder: titilerApiAdapter.buildMonocolorFloatCogUrl,
            params: { url, color: chroma(color).hex() },
          }}
          sectionId={EXPLORATION_LAYERS_KEYS.ML}
          layerId={mineral_id}
        />
      ))}
    </>
  );
};
