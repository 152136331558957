import { type RefObject } from 'react';
import { type ResetPasswordRequestStatus } from 'domain/user/types';
import { ResetPasswordRequestStatusMessages } from 'domain/user/enums';

export const useGetIssueProps = (
  formRef: RefObject<HTMLFormElement>,
  email: string,
  status: ResetPasswordRequestStatus,
) => {
  const handleEmailButtonClickUseCase = () => {
    formRef.current && formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const getIssueProps = () => {
    let issueText: string =
      ResetPasswordRequestStatusMessages[status] || 'Oops! Something went wrong. Please try again later.';

    if (status === 'SUCCESS') {
      issueText = `${ResetPasswordRequestStatusMessages[status]} ${email}`;
    }

    return {
      header: 'Change Your Password',
      text: issueText,
      handleOkButtonClick: handleEmailButtonClickUseCase,
    };
  };

  return { getIssueProps };
};
