import styled from 'styled-components';

interface LoadingDotsProps {
  color?: string;
}

export const LoadingDots = ({ color }: LoadingDotsProps) => {
  return (
    <LoadingDotsWrapper color={color}>
      <span />
      <span />
      <span />
    </LoadingDotsWrapper>
  );
};

const LoadingDotsWrapper = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 24px;
  height: 16px;
  padding-top: 6px;

  span {
    display: block;
    width: 5px;
    height: 5px;
    background-color: ${({ color, theme }) => (color ? color : theme.color.white)};
    border-radius: 50%;
    flex-shrink: 0;

    &:nth-child(1) {
      animation: bounce 0.3s ease-in-out infinite alternate;
    }

    &:nth-child(2) {
      animation: bounce 0.3s ease-in-out 0.1s infinite alternate;
    }

    &:nth-child(3) {
      animation: bounce 0.3s ease-in-out 0.2s infinite alternate;
    }

    @keyframes bounce {
      100% {
        transform: translateY(-100%);
      }
    }
  }
`;
