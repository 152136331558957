import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';

import { type DownloadedFile } from './types';

export const getFullFileName = (name: string, extension: string) => name + '.' + extension;

export const copyInClipboard = (value: string | undefined) => value && navigator.clipboard.writeText(value);

export const downloadSingleFile = async (fileData: DownloadedFile, setLoading?: (value: boolean) => void) => {
  const { url, name, extension } = fileData;

  try {
    setLoading && setLoading(true);
    const buffer = await JSZipUtils.getBinaryContent(url);
    const file = saveAs(new Blob([buffer]), getFullFileName(name, extension));
    return file;
  } catch (error) {
    console.error(error);
  } finally {
    setLoading && setLoading(false);
  }
};

export const downloadAllFilesWithNamesAsZip = async (
  urlWithNames: DownloadedFile[],
  fileNameMask: string,
  setLoading: (value: boolean) => void,
) => {
  const zip = new JSZip();

  try {
    setLoading(true);
    const filesBuffer: { content: ArrayBuffer; name: string }[] = await Promise.all(
      urlWithNames.map(async ({ url, name, extension }) => ({
        content: await JSZipUtils.getBinaryContent(url),
        name: getFullFileName(name, extension),
      })),
    );

    filesBuffer
      .sort((a, b) => a.content.byteLength - b.content.byteLength)
      .forEach((buffer) => {
        zip.file(buffer.name, buffer.content, { binary: true });
      });

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, getFullFileName(fileNameMask, 'zip'));
    });
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};
