import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';

export const TrialPeriodNotificationItem = () => {
  const { trialDuration } = subscriptionRepository.useGetTrialDuration();

  const trialPeriod = trialDuration?.trial_in_days;

  return (
    <li key="trial-notification">
      {trialPeriod
        ? `Free ${trialPeriod}-day trial! Cancel within ${trialPeriod} days to not get charged.`
        : 'Free trial!'}
    </li>
  );
};
