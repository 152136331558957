import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type DataPoint } from 'common/types/mapData';
import { type AdditionalOptionsTypes, type AdditionalDataTypes, type GraphData } from './types';

interface GraphState {
  graphVisible: boolean;
  showGraph: () => void;
  escapeGraph: () => void;
  chosenPoint: DataPoint | undefined;
  setChosenPoint: (dataPoint: DataPoint) => void;
  resetChosenPoint: () => void;
  graphData: GraphData | undefined;
  setGraphData: (graphData: GraphData) => void;
  resetGraphData: () => void;
  additionalData: AdditionalDataTypes;
  setAdditionalData: (additionalData: AdditionalDataTypes) => void;
  resetAdditionalData: () => void;
  additionalDataOptions: AdditionalOptionsTypes;
  setAdditionalDataOption: (additionalDataOption: AdditionalOptionsTypes) => void;
  isFetching: boolean;
  setGraphFetching: () => void;
  resetGraphFetching: () => void;
  resetGraphStore: () => void;
}

const initialState = {
  graphVisible: false,
  chosenPoint: undefined,
  graphData: undefined,
  additionalData: {},
  additionalDataOptions: {},
  isFetching: false,
};

const useGraphStoreBase = create<GraphState>()(
  devtools(
    (set) => ({
      graphVisible: initialState.graphVisible,
      showGraph: () => set({ graphVisible: true }, false, 'graph/showGraph'),
      escapeGraph: () => set({ graphVisible: false, chosenPoint: undefined }, false, 'graph/escapeGraph'),
      chosenPoint: initialState.chosenPoint,
      setChosenPoint: (dataPoint) => set({ chosenPoint: dataPoint }, false, 'graph/setChosenPoint'),
      resetChosenPoint: () => set({ chosenPoint: undefined }, false, 'graph/resetChosenPoint'),
      graphData: initialState.graphData,
      setGraphData: (graphData) => set({ graphData }, false, 'graph/setPointHistory'),
      resetGraphData: () => set({ graphData: undefined }, false, 'graph/resetGraphData'),
      additionalData: initialState.additionalData,
      setAdditionalData: (additionalData) => set({ additionalData }, false, 'graph/setAdditionalData'),
      resetAdditionalData: () => set({ additionalData: {} }, false, 'graph/resetAdditionalData'),
      additionalDataOptions: initialState.additionalDataOptions,
      setAdditionalDataOption: (additionalDataOption) =>
        set(
          (state) => ({ additionalDataOptions: { ...state.additionalDataOptions, ...additionalDataOption } }),
          false,
          'graph/insertAdditionalDataOption',
        ),
      isFetching: initialState.isFetching,
      setGraphFetching: () => set({ isFetching: true }, false, 'graph/setGraphFetching'),
      resetGraphFetching: () => set({ isFetching: false }, false, 'graph/unsetGraphFetching'),
      resetGraphStore: () => set(initialState, false, 'graph/resetGraphStore'),
    }),
    { name: 'graphStore' },
  ),
);

export const useGraphStore = createSelectors(useGraphStoreBase);
