import {
  LAT_LNG_QUERY_PARAMETER,
  DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER,
  REPORT_QUERY_PARAMETERS,
  ZOOM_QUERY_PARAMETER,
  MAP_STYLE_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { PROSPECTING_PATH_NAME } from 'domain/prospecting/constants';

export const PROSPECTING_VIEW_CONFIG = {
  name: PROSPECTING_PATH_NAME,
  path: ROUTES.prospecting,
  params: [
    LAT_LNG_QUERY_PARAMETER,
    ZOOM_QUERY_PARAMETER,
    DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER,
    ...REPORT_QUERY_PARAMETERS,
    MAP_STYLE_QUERY_PARAMETER,
  ],
  tabs: {},
};
