import { type ReactNode } from 'react';
import { Pane } from 'react-leaflet';

interface LayerProps {
  visible?: boolean;
  children?: ReactNode;
  name: string;
  className?: string;
  zIndex: number;
}

export const Layer = ({ visible = true, name, children, zIndex, className }: LayerProps) => (
  <>
    {visible ? (
      <Pane name={name} style={{ zIndex: zIndex }} className={className}>
        {children}
      </Pane>
    ) : null}
  </>
);
