import { type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as HeroLogo } from 'assets/svg/hero-logo.svg';
import { ROUTES } from 'common/navigation/routes';

interface AppHeaderProps {
  children?: ReactNode;
}

export const AppHeader = ({ children }: AppHeaderProps) => {
  return (
    <Header>
      <Link to={ROUTES.areasList}>
        <LogoWrapper>
          <HeroLogo />
        </LogoWrapper>
      </Link>
      {children}
    </Header>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 25px;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  height: 90px;
  padding: 16px 60px 0;
  background: rgba(46, 43, 43, 0.8);
  backdrop-filter: blur(15px);
`;

const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.color.white};
  width: 160px;
`;
