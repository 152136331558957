import styled, { css } from 'styled-components';

import { type ColormapDictionary } from 'common/types';
import { InfoPoint, Switcher } from 'ui';
import { ColorScaleBar } from 'ui/colorScale/ColorScaleBar';

interface ModeSwitcherProps {
  isModeOn: boolean;
  label: string;
  tooltipContent?: React.ReactNode;
  colormap?: ColormapDictionary;
  segmentedColorbar?: boolean;
  disabled?: boolean;
  toggleMode?: () => void;
}

export const ModeSwitcher = ({
  isModeOn,
  label,
  tooltipContent,
  colormap,
  segmentedColorbar = false,
  disabled = false,
  toggleMode,
}: ModeSwitcherProps) => {
  return (
    <ModeSwitcherWrapper>
      <SwitcherWrapper>
        <SwitcherLabel disabled={disabled}>
          <Switcher checked={isModeOn} onChange={toggleMode} />
          {label}
        </SwitcherLabel>
        {tooltipContent && <InfoPoint name={`mode-switcher-${label}`} tooltipContent={tooltipContent} />}
      </SwitcherWrapper>
      {isModeOn && colormap && (
        <ColorScaleWrapper data-testid="mode-switcher-color-scale">
          <ColorScaleBar colormap={colormap} segmented={segmentedColorbar} height={6} />
        </ColorScaleWrapper>
      )}
    </ModeSwitcherWrapper>
  );
};

const ModeSwitcherWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const SwitcherWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 10px;
`;

const SwitcherLabel = styled.label<{ disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};
`;

const ColorScaleWrapper = styled.div`
  width: 100%;
  margin-top: 3px;
`;
