import { InfoArea } from 'ui/infoArea';
import { useWaterbodies } from '../waterbodies';
import { MultipleWaterInfoAreaContent, SingleWaterInfoAreaContent, useWaterInfoArea } from '../infoArea';

export const WaterQualityInfoArea = () => {
  const { selectedWaterbody } = useWaterbodies();
  const waterbodyId = selectedWaterbody?.value;
  const { singleWaterInfoArea, multipleWaterInfoArea, infoAreaProps } = useWaterInfoArea();

  return (
    <InfoArea {...infoAreaProps}>
      {waterbodyId ? (
        <SingleWaterInfoAreaContent singleWaterInfoArea={singleWaterInfoArea} />
      ) : (
        <MultipleWaterInfoAreaContent multipleWaterInfoArea={multipleWaterInfoArea} />
      )}
    </InfoArea>
  );
};
