import { type BaseMapVariant } from 'ui/map/baseMap/enums';
import { type MapStyle } from './types';

export const mapStyle: { [key in BaseMapVariant]: MapStyle } = {
  default: {
    styleUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    vendor: {
      name: 'OpenStreetMap',
      url: 'http://osm.org/copyright',
    },
  },
  light: {
    name: 'OSM:LightGray',
  },
  dark: {
    name: 'OSM:DarkGray',
  },
  streets: {
    name: 'OSM:Streets',
  },
  satellite: {
    name: 'ArcGIS:Imagery',
  },
};
