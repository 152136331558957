import { type ValueAndLabel } from 'common/types';
import { getLayerNameWithAoiId } from 'common/utils';
import { useAoiNumber } from 'common/navigation/hooks';
import { useLayerManagerStore } from './layerManagerStore';

export const useCurrentOptionThreshold = (selectedOption: ValueAndLabel | undefined) => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();

  const areaId = useAoiNumber();

  const layerName = selectedOption && getLayerNameWithAoiId(String(selectedOption.value), areaId);

  const thresholdResponse = layerName ? layersTresholds[layerName] : undefined;

  const threshold = typeof thresholdResponse === 'number' ? thresholdResponse : thresholdResponse?.[0];

  return { threshold };
};
