import { View } from '@react-pdf/renderer';

import { ReportPdfScreenshots } from 'views/reports/external/pdfGenerator/extended/ReportPdfScreenshots';
import { commonStyles } from 'views/reports/external/pdfGenerator/styles';
import { ReportPdfExtendedTable } from 'views/reports/external/pdfGenerator/extended/ReportPdfExtendedTable';
import { ReportPdfBarChart } from 'views/reports/external/pdfGenerator/extended/ReportPdfBarChart';
import { type ExternalReportExtendedTable, type ExternalReportScreenshots } from 'domain/reports/types';

interface ReportPdfExtendedContentProps {
  screenshots: ExternalReportScreenshots;
  table: ExternalReportExtendedTable;
  chartSrc?: string | null;
}

export const ReportPdfExtendedContent = ({ screenshots, table, chartSrc }: ReportPdfExtendedContentProps) => (
  <>
    <ReportPdfScreenshots screenshots={screenshots} />
    <View style={commonStyles.flexColumn}>
      {table.data.length && <ReportPdfExtendedTable table={table} />}
      {chartSrc && <ReportPdfBarChart chartSrc={chartSrc} />}
    </View>
  </>
);
