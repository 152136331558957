import { type ComponentType } from 'react';

import { useLayerIndex } from 'components/layerManager/draggableLayerManager/useLayerIndex';

interface Props<T> {
  Component: ComponentType<T & { zIndex: number }>;
  props: T;
  sectionId: string;
  layerId?: number;
}

export const WithLayerIndex = <T,>({ Component, props, sectionId, layerId }: Props<T>) => {
  const { visible, index } = useLayerIndex(sectionId, layerId);

  if (!visible) {
    return null;
  }

  return <Component {...(props as JSX.IntrinsicAttributes & T)} zIndex={index} />;
};
