import { type ReactNode } from 'react';

import {
  FoldableSection,
  FoldableSectionCheckbox,
  REQUEST_MORE_DATA_MESSAGE,
  useFoldableSectionStatus,
} from 'components';
import { LINEAMENTS_INFO_POINT_DESCRIPTION } from 'domain/exploration/configs';
import { useLineamentsLayerManagerStore } from './lineamentsLayerManagerStore';
import { useLineaments } from '../useLineaments';

interface LineamentsFoldableSectionProps {
  id: string;
  children: ReactNode;
}

export const LineamentsFoldableSection = ({ id, children }: LineamentsFoldableSectionProps) => {
  const areLineamentsVisible = useLineamentsLayerManagerStore.use.areLineamentsVisible();
  const toggleLineamentsVisibility = useLineamentsLayerManagerStore.use.toggleLineamentsVisibility();

  const { rangeResult } = useLineaments();

  const { description, areResultsAvailable } = useFoldableSectionStatus({
    defaultDescription: LINEAMENTS_INFO_POINT_DESCRIPTION,
    noDataMessage: REQUEST_MORE_DATA_MESSAGE,
    areResultsAvailable: !!rangeResult,
  });

  return (
    <FoldableSection
      id={id}
      headerContent={
        <FoldableSectionCheckbox
          variant="NORMAL"
          title="lineaments"
          description={description}
          isCheckboxChecked={areLineamentsVisible && areResultsAvailable}
          onCheckboxToggle={toggleLineamentsVisibility}
          disabled={!areResultsAvailable}
        />
      }
      disabled={!areResultsAvailable}
    >
      {children}
    </FoldableSection>
  );
};
