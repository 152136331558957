import axios, { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { type TypedDocumentString } from 'gql/graphql';
import { BACKEND_PREFIX, LOCAL_STORAGE_ACCESS_TOKEN_NAME } from 'common/constants/services';
import { refreshTokenHelper } from 'services/rest/refreshTokenHelper';
import { localStorageService } from 'services/localStorageService';
import { ROUTES } from 'common/navigation/routes';
import { checkIsGraphqlError } from './helpers';

export const useExecuteGraphqlMutation = <TResult, TVariables>(
  url: string | undefined,
  queryKey: string,
  options: object,
  query: TypedDocumentString<TResult, TVariables>,
  retry?: number,
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
) => {
  const localStorageAccessToken = localStorageService.getValue<string | null>(LOCAL_STORAGE_ACCESS_TOKEN_NAME, null);
  let accessToken: string | null | undefined = localStorageAccessToken;

  const navigate = useNavigate();

  const queryResponse = useMutation(
    [queryKey],
    async () => {
      const { data } = await axios({
        url: url + '/api/v1/graphql',
        method: 'POST',
        data: {
          query: query,
          variables: variables,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },
    {
      ...options,
      onError: async (error) => {
        if (error instanceof AxiosError && error.response?.status === 403) {
          const newAccessToken = await refreshTokenHelper(
            axios.create({
              withCredentials: true,
              baseURL: BACKEND_PREFIX,
            }),
          );

          if (!newAccessToken) {
            return;
          }

          accessToken = newAccessToken;
          queryResponse.mutate();
        }
      },
      onSuccess: async (response) => {
        if (response.errors && checkIsGraphqlError(response.errors[0].message)) {
          if (response.errors[0].message === 'JWT token decoding error') {
            navigate(ROUTES.logout);
          }

          const newAccessToken = await refreshTokenHelper(
            axios.create({
              withCredentials: true,
              baseURL: BACKEND_PREFIX,
            }),
          );

          if (!newAccessToken) {
            return;
          }

          accessToken = newAccessToken;
          queryResponse.mutate();
        }
      },
      retry: retry || 0,
    },
  );

  return queryResponse;
};
