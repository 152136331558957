import { useEffect, useState, type ChangeEvent } from 'react';

import { type BoundsArray, type PointArray, type Position } from 'common/types/mapData';
import { geocodeSearchClient } from 'services/geocodeSearch/geocodeSearchClient';
import { buildResultString, extractTextFromQuery } from 'ui/organisms/geocodeSearch/helpers';
import { type GeocodeSearchResult } from 'ui/organisms/geocodeSearch/types';

export const useGeocodeSearch = (setSearchResult: (position: Position | BoundsArray) => void) => {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<GeocodeSearchResult[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<GeocodeSearchResult | null>(null);

  useEffect(() => {
    if (selectedResult) {
      if (selectedResult.boundingBox) {
        const { topLeftPoint, btmRightPoint } = selectedResult.boundingBox;
        const shift = topLeftPoint.lon > 0 && btmRightPoint.lon < 0;

        shift
          ? selectedResult.position
            ? setSearchResult(selectedResult.position)
            : undefined
          : setSearchResult([Object.values(topLeftPoint) as PointArray, Object.values(btmRightPoint) as PointArray]);
      } else {
        setSearchResult(selectedResult.position);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResult]);

  const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);

    if (!e.target.value.length) {
      setIsDropdownVisible(false);
    }
  };

  const onResultSelect = (result: GeocodeSearchResult) => {
    setSelectedResult(result);
    setIsDropdownVisible(false);
    setQuery(buildResultString(result));
  };

  const getGeocodeData = async () => {
    setIsError(false);
    const sanitizedQuery = extractTextFromQuery(query);

    try {
      const geocodeResults = await geocodeSearchClient(sanitizedQuery);

      setResults(geocodeResults);

      if (!selectedResult || query !== buildResultString(selectedResult)) {
        setIsDropdownVisible(true);
      }
    } catch (error) {
      console.log(`Unable to fetch geocode results >>> ${error}`);
      setIsError(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onQuerySubmit = (e: any) => {
    e.preventDefault();

    getGeocodeData();
  };

  useEffect(() => {
    setIsError(false);

    const delayInputTimeoutId = setTimeout(() => {
      query && getGeocodeData();
    }, 1_000);

    return () => clearTimeout(delayInputTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { query, results, isDropdownVisible, isError, onQueryChange, onQuerySubmit, onResultSelect };
};
