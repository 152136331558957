import { ReactComponent as LayersIcon } from 'assets/svg/layersIcon.svg';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { MapControlButton } from 'ui/map/mapControlButton';

export const LayerManagerControlButton = () => {
  const isLayerManagerOpen = useLayerManagerStore.use.isLayerManagerOpen();
  const toggleLayerManager = useLayerManagerStore.use.toggleLayerManager();

  return (
    <MapControlButton icon={<LayersIcon />} iconWidth={16} active={isLayerManagerOpen} onClick={toggleLayerManager} />
  );
};
