import { View, Text, Svg, Rect } from '@react-pdf/renderer';

import { colorScaleStyles } from 'views/reports/external/pdfGenerator/styles';
import { type ExternalReportColorScale } from 'domain/reports/types';

export const ReportPdfSegmentedColorScale = ({ colorScale }: { colorScale: ExternalReportColorScale }) => (
  <View style={colorScaleStyles.wrapper}>
    <View style={colorScaleStyles.labelsWrapper}>
      <View style={colorScaleStyles.labels}>
        {colorScale.labels.map((label, index) => (
          <Text key={index}>{label}</Text>
        ))}
      </View>
      <View>
        <Text>{colorScale.unit}</Text>
      </View>
    </View>
    <View style={colorScaleStyles.colorBar}>
      <Svg width="260" height="8">
        {Object.entries(colorScale.colormap)
          .sort((a, b) => Number(a[0]) - Number(b[0]))
          .map((color, index, array) => {
            const segmentsQty = array.length;
            const segmentWidth = 100 / segmentsQty;
            const offset = index * segmentWidth;

            return <Rect key={color[0]} width={`${segmentWidth}%`} x={`${offset}%`} height="100%" fill={color[1]} />;
          })}
      </Svg>
    </View>
  </View>
);
