import { SURFACE_PRECISION_HA } from 'common/constants';
import { type ValueAndLabel } from 'common/types';
import { type TooltipData } from 'common/types/mapData';
import { replaceSquareMetersWithHectares, simpleRound } from 'common/utils';
import { buildSingleViewTooltipStringHelper } from 'common/utils/map';
import {
  type WaterAreaComparatorResultIds,
  type WaterAreaResult,
  type WaterBody,
  type WaterGraphResult,
} from 'domain/water/types';

export const mapWaterBodiesToOptionsHelper = (waterbodies: WaterBody[]): ValueAndLabel[] => {
  return waterbodies.map((w) => ({
    value: w.id,
    label: w.name,
  }));
};

export const buildWaterBodyTooltipStringHelper =
  (stats: boolean = false) =>
  (tooltip: TooltipData) => {
    return `
    <h4>Waterbody:</h4>
    <p>${tooltip.label}</p>
    ${stats ? buildSingleViewTooltipStringHelper(tooltip) : ''}
    `;
  };

export const getGraphDataFromRequestHelper = (waterGraphResult: WaterGraphResult[]) => {
  return waterGraphResult
    .reduce<Array<[Date, number]>>((acc, point) => acc.concat([[new Date(point.date), point.value]]), [])
    .sort((a, b) => +a[0] - +b[0]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformToWaterGraphResultForm = <T extends { [key: string]: any }>(
  array: T[],
  keysDict: { date: keyof T; id: keyof T; value: keyof T },
): WaterGraphResult[] => {
  return array.map((item) => ({
    date: item[keysDict.date],
    id: item[keysDict.id],
    value: +simpleRound(replaceSquareMetersWithHectares(item[keysDict.value])!, SURFACE_PRECISION_HA),
  }));
};

export const findMatchingIdsForCommonWaterbodyId = (
  firstResults: WaterAreaResult[],
  secondResults: WaterAreaResult[],
  waterbodyId: string | null,
) => {
  const resultIds: WaterAreaComparatorResultIds[] = [];

  for (const firstResult of firstResults) {
    for (const secondResult of secondResults) {
      if (
        firstResult.waterbody_id === secondResult.waterbody_id &&
        (!waterbodyId || waterbodyId === String(firstResult.waterbody_id))
      ) {
        resultIds.push({ firstResultId: firstResult.id, secondResultId: secondResult.id });
      }
    }
  }

  return resultIds;
};
