import { useParams } from 'react-router-dom';

import { InfoArea } from 'ui/infoArea';
import { useInfoAreaService } from 'ui/infoArea/infoAreaService';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { useTimelineService } from 'components/Timeline/timelineService';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { MINE_EXTRACTION_INFO_AREA_DESCRIPTION } from 'domain/extraction/constants';
import { ExtractionLayerManager } from './ExtractionLayerManager';
import { ExtractionColorScale } from './ExtractionColorScale';
import { ExtractionInfoArea } from './ExtractionInfoArea';
import { ExtractionTimeline } from './ExtractionTimeline';

export const Extraction = () => {
  const { areaId } = useParams();
  const { timestamp } = useTimelineService();

  const infoAreaProps = useInfoAreaService();
  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <AreaDetailsLayout
      legend={!areReportsActive && <ExtractionColorScale />}
      toolbarContent={
        <InfoArea {...infoAreaProps}>
          {MINE_EXTRACTION_INFO_AREA_DESCRIPTION}
          {timestamp && <ExtractionInfoArea timestamp={timestamp} />}
        </InfoArea>
      }
      toolboxContent={<>{!areReportsActive && <LayerManagerControlButton />}</>}
    >
      {!areReportsActive && areaId && <ExtractionLayerManager areaId={Number(areaId)} />}
      <ExtractionTimeline areReportsActive={areReportsActive} />
    </AreaDetailsLayout>
  );
};
