import MDEditor from '@uiw/react-md-editor';
import { type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as QuestionBaloon } from 'assets/svg/question-baloon.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user-icon.svg';
import { IconButton } from 'ui/atoms/iconButton';
import { type IconButtonVariants } from 'ui/atoms/iconButton/IconButton';
import { type Palette } from 'ui/types';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';

export type ChatListItemVariant = 'PRIMARY' | 'SECONDARY' | 'ERROR';

type ChatListItemStyle = Required<
  Pick<CSSProperties, 'background' | 'flexDirection' | 'color'> & {
    iconColor: IconButtonVariants;
    iconSize: number;
    icon: ReactNode;
  }
>;

const styles: Palette<ChatListItemVariant, ChatListItemStyle> = {
  PRIMARY: {
    background: 'selectGray',
    color: 'white',
    flexDirection: 'row-reverse',
    iconColor: 'PRIMARY_STATIC',
    iconSize: 30,
    icon: <UserIcon />,
  },
  SECONDARY: {
    background: 'mineShaftDark',
    color: 'white',
    flexDirection: 'row',
    iconColor: 'SECONDARY_STATIC',
    iconSize: 18,
    icon: <QuestionBaloon />,
  },
  ERROR: {
    background: 'congoBrown',
    color: 'sundown',
    flexDirection: 'row',
    iconColor: 'SECONDARY_STATIC',
    iconSize: 18,
    icon: <QuestionBaloon />,
  },
};

export interface ChatListItemProps {
  variant?: ChatListItemVariant;
  content: string;
  maxWidth?: CSSProperties['maxWidth'];
  isLoading?: boolean;
}

export const ChatListItem = ({ variant, content, maxWidth, isLoading }: ChatListItemProps) => {
  return (
    <StyledListItem variant={variant!} maxWidth={maxWidth}>
      <IconButton
        icon={styles[variant!].icon}
        $iconSize={styles[variant!].iconSize}
        variant={styles[variant!].iconColor}
      />
      <ListItemInnerWrapper variant={variant!}>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <StyledMarkdownEditor style={{ backgroundColor: 'transparent' }} variant={variant!} source={content} />
        )}
      </ListItemInnerWrapper>
    </StyledListItem>
  );
};

const StyledListItem = styled.li<{ variant: ChatListItemVariant; maxWidth?: CSSProperties['maxWidth'] }>`
  word-break: break-word;
  max-width: ${({ maxWidth }) => maxWidth}px;
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  flex-direction: ${({ variant }) => styles[variant].flexDirection};
  align-items: flex-start;
  margin-left: ${({ variant }) => (variant === 'PRIMARY' ? 'auto' : '0')};
  margin-right: ${({ variant }) => (variant === 'SECONDARY' ? 'auto' : '0')};
  gap: 8px;
  color: ${({ theme }) => theme.color.white};
`;

const ListItemInnerWrapper = styled.div<{ variant: ChatListItemVariant }>`
  background: ${({ theme, variant }) => theme.color[styles[variant].background]};
  border-radius: 10px;
  text-align: left;
  padding: 12px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
`;

const StyledMarkdownEditor = styled(MDEditor.Markdown)<{ variant: ChatListItemVariant }>`
  p,
  div {
    background-color: ${({ theme, variant }) => theme.color[styles[variant!].background]};
    color: ${({ theme, variant }) => theme.color[styles[variant!].color]};
  }
`;

ChatListItem.defaultProps = {
  variant: 'PRIMARY' as ChatListItemVariant,
};

ChatListItem.displayName = 'LabeledInputElement';
