import { ColorScale } from 'ui';
import { type LayerRange, type ColormapDictionary } from 'common/types';

interface Props {
  colormap: ColormapDictionary;
  unit: string;
  layerRange: LayerRange;
}

export const SnowCoverColorScale = ({ colormap, unit, layerRange: { min, max } }: Props) => {
  const labels = [min, max].map(String);

  return <ColorScale {...{ colormap, unit, labels }} />;
};
