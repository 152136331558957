import { useQueryParameter, useQueryMultipleToggle } from 'common/navigation/hooks';
import { FILTERS_ENVIRONMENT_QUERY_PARAM_NAME } from 'common/navigation/queryParams';
import { type TagData } from 'ui/molecules/tagList/TagList';
import { useGetAllAreasEnvironments } from 'views/areas/areasList/getAllAreasEnvironments';
import { FILTERS_AREA_ENVIRONMENT_SET } from 'domain/areas/configs';

export const useGetAreasEnvironmentSet = (): TagData[] => {
  const areaTypeQuery = useQueryParameter(FILTERS_ENVIRONMENT_QUERY_PARAM_NAME);
  const { toggleMultipleQueries } = useQueryMultipleToggle(FILTERS_ENVIRONMENT_QUERY_PARAM_NAME);

  const uniqueAreaEnvironments = useGetAllAreasEnvironments();

  const areaTypeSet = uniqueAreaEnvironments.map((environment) => {
    const { id, variant, text } = FILTERS_AREA_ENVIRONMENT_SET[environment];

    return {
      id,
      variant,
      text,
      checked: !areaTypeQuery?.split('-').includes(environment),
      onClick: () => toggleMultipleQueries(environment),
    };
  });

  return areaTypeSet;
};
