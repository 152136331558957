import { useEffect } from 'react';

import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';
import { useTimelineService } from 'components/Timeline/timelineService';
import { quarterlyDataService } from 'views/greenery/quarterly/quarterlyDataService';

export const quarterlyTimelineService = {
  useQuarterlyTimeline: (areaId: number, isComparisonActive: boolean) => {
    const { quarterlyResultsTimestamps: timestamps, quarterlyResultsLoading } =
      quarterlyDataService.useFetchQuarterlyResults(areaId);

    const { timestamp, secondTimestamp, changeTimestamp } = useTimelineService();

    useEffect(() => {
      if (timestamp || !timestamps || timestamps.length === 0) {
        changeTimestamp(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quarterlyTimelineService = {
      timelineData: { timestamps: timestamps, timestamp, visible: !isComparisonActive },
      comparatorData: {
        comparedDatesOptions: timestamps,
        firstComparedDate: timestamp,
        secondComparedDate: secondTimestamp,
        isFetching: quarterlyResultsLoading,
      },
    };

    return quarterlyTimelineService;
  },

  useTimestampsChange: () => {
    const clearComparatorUrl = useGreeneryStore.use.clearComparatorUrl();

    const { changeTimestamp, changeSecondTimestamp } = useTimelineService();

    const onFirstTimestampChange = (timestamp: string) => {
      changeTimestamp(timestamp);
      clearComparatorUrl();
    };

    const onSecondTimestampChange = (timestamp: string) => {
      changeSecondTimestamp(timestamp);
      clearComparatorUrl();
    };

    return { onFirstTimestampChange, onSecondTimestampChange };
  },
};
