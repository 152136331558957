import { useQueryParameter, useAoiNumber } from 'common/navigation/hooks';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useTimelineService } from 'components/Timeline/timelineService';
import { GREENERY_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const vegetationDataService = {
  useFetchVegetationResults: (date?: string | null) => {
    const areaId = useAoiNumber();
    const greeneryIndicatorId = useQueryParameter(GREENERY_INDICATOR_QUERY_PARAMETER);
    const { timestamp } = useTimelineService();

    const { greeneryResults, greeneryResultsLoading } = greeneryRepository.useFetchVegetationResults(
      areaId,
      greeneryIndicatorId ? Number(greeneryIndicatorId) : undefined,
      date || timestamp,
    );

    const greeneryResultsWithUniqueIds = greeneryResults.filter(
      (obj, index, self) => index === self.findIndex((t) => t.greenery_object_id === obj.greenery_object_id),
    );

    return {
      greeneryResults: greeneryResultsWithUniqueIds,
      greeneryResultsLoading,
    };
  },
};
