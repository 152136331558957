import { ColorScale } from 'ui';
import { useCurrentCog } from 'views/temperature/useCurrentCog';
import { getCelciusFromKelvin } from 'common/utils';
import { TEMPERATURE_COLORMAP } from 'domain/temperature/colors';

export const TemperatureColorScale = () => {
  const { range } = useCurrentCog();

  if (!range.length) return null;

  const colormapStepsNumber = Object.keys(TEMPERATURE_COLORMAP).length;

  const buildColormapLabelsFromRange = (range: number[], steps: number): string[] => {
    const segment = (range[1] - range[0]) / steps;
    const labels = [];
    for (let i = 0; i <= steps; i++) {
      labels.push(range[0] + segment * i);
    }
    return labels.map(
      (value, index) =>
        `${index === 0 ? '<' : ''}${index === steps ? '>' : ''}${getCelciusFromKelvin(value).toFixed()}${
          [0, steps].includes(index) ? '°C' : ''
        }`,
    );
  };

  const buildTooltipLabelsFromRange = (range: number[], steps: number): string[] => {
    const segment = (range[1] - range[0]) / steps;
    const labels = [];
    for (let i = 0; i <= steps - 1; i++) {
      labels.push(
        `${getCelciusFromKelvin(range[0] + segment * i).toFixed(2)} — ${getCelciusFromKelvin(
          range[0] + segment * (i + 1),
        ).toFixed(2)}°C`,
      );
    }
    return labels;
  };

  return (
    <ColorScale
      colormap={TEMPERATURE_COLORMAP}
      labels={buildColormapLabelsFromRange(range, Math.ceil(colormapStepsNumber / 3))}
      tooltipLabels={buildTooltipLabelsFromRange(range, colormapStepsNumber)}
      segmented
    />
  );
};
