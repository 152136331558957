import { ReactComponent as WaterdropIcon } from 'assets/svg/water-drop-icon.svg';
import { AreaPages } from 'common/navigation/constants';
import { useBuildNavigationItem } from 'views/areas/areaDetails/useBuildNavigationItem';
import { WATER_VIEW_CONFIG } from 'domain/water/configs';

export const useBuildWaterNavItem = () => {
  return useBuildNavigationItem({
    config: WATER_VIEW_CONFIG,
    label: 'water',
    icon: <WaterdropIcon />,
    iconSize: 12,
    type: AreaPages.WATER,
  });
};
