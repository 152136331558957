import { useEffect, useState } from 'react';

import { hotspotsRepository } from 'infrastructure/hotspots/hotspotsRepository';
import { Loading } from 'ui/molecules/loading';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { type GreeneryHotspot } from 'domain/greenery/types';
import { useGetVegetationHotspotsMapContentUseCase } from '../getVegetationHotspotsMapContentUseCase';
import { VegetationHotspotsDataLayer } from '../VegetationHotspotsDataLayer';

interface VegetationMapContentHotspotsProps {
  greeneryIndicator: number;
}

export const VegetationMapContentHotspots = ({ greeneryIndicator }: VegetationMapContentHotspotsProps) => {
  const { hotspotTypes, isLoading: areHotspotTypesLoading } = hotspotsRepository.useFetchHotspotTypes();

  const { classesVisibility } = useGreeneryLayersVisibility();
  const [results, setResults] = useState<GreeneryHotspot[]>([]);

  const { hotspots, isLoading } = useGetVegetationHotspotsMapContentUseCase();

  useEffect(() => {
    setResults(hotspots);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotspots.length]);

  const currentHotspotUrl = results.find((result) => result.indicator_id === greeneryIndicator)?.cog_file_path;

  if (isLoading || areHotspotTypesLoading) {
    return <Loading />;
  }

  return currentHotspotUrl ? (
    <VegetationHotspotsDataLayer
      currentHotspotUrl={currentHotspotUrl}
      greeneryObjects={hotspotTypes}
      layersVisibility={classesVisibility}
    />
  ) : null;
};
