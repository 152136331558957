import { View, Text, Image } from '@react-pdf/renderer';

import { commonStyles, screenshotStyles } from 'views/reports/external/pdfGenerator/styles';
import { type ExternalReportScreenshots } from 'domain/reports/types';

export const ReportPdfScreenshots = ({ screenshots }: { screenshots: ExternalReportScreenshots }) => (
  <View style={screenshotStyles.compar_wiew_wrapper}>
    <View style={commonStyles.flexGrow}>
      <Text style={screenshotStyles.image__date}>{screenshots.first.label}</Text>
      <Image style={screenshotStyles.image__wide} src={screenshots.first.src} />
    </View>
    <View style={commonStyles.flexGrow}>
      <Text style={screenshotStyles.image__date}>{screenshots.second.label}</Text>
      <Image style={screenshotStyles.image__wide} src={screenshots.second.src} />
    </View>
  </View>
);
