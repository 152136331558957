import { type SubscriptionProductDTO } from 'domain/subscription/types';
import { type SubscriptionColorsConfig } from './types';

type ColorConfigKey = SubscriptionProductDTO['name'] | 'Default';

const colorsConfig: Record<ColorConfigKey, SubscriptionColorsConfig> = {
  'Standard Exploration': {
    bgColor: null,
    color: 'white',
    themeBgColor: 'donkeyBrown',
    areaQuotaColor: 'alto',
  },
  'Extended Exploration': {
    bgColor: null,
    color: 'white',
    themeBgColor: 'primary',
    areaQuotaColor: 'primaryLight',
  },
  'Enterprise Package': {
    bgColor: 'silverDark',
    color: 'tuatara',
    themeBgColor: 'primary',
    areaQuotaColor: 'tuatara',
  },
  Default: {
    bgColor: 'silverDark',
    color: 'tuatara',
    themeBgColor: 'primary',
    areaQuotaColor: 'tuatara',
  },
};

export const getColorsConfig = (name: ColorConfigKey) => colorsConfig[name] || colorsConfig.Default;
