import { type ReactNode } from 'react';
import styled from 'styled-components';

import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';

interface CreatorProps {
  children: ReactNode;
}

export const Creator = ({ children }: CreatorProps) => (
  <StyledCreator>
    <StyledSimpleBar>
      <Wrapper>{children}</Wrapper>
    </StyledSimpleBar>
  </StyledCreator>
);

const StyledCreator = styled.div`
  background-color: ${({ theme }) => theme.color.mineShaftDark};
  border: 1px solid ${({ theme }) => theme.color.mineShaftDark};
  word-break: break-word;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 270px;
`;

const StyledSimpleBar = styled(ScrollbarWrapper)`
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

Creator.displayName = 'Creator';
