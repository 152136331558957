import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import env from 'react-dotenv';

import { useCaptchaValidationService } from 'services/captchaValidationService';
import { Input } from 'ui/atoms/input';
import { FormLayout, FormButtons } from 'ui/form';
import { ConsentCheckbox } from 'ui/organisms/consentCheckbox';
import { useSignUp } from 'views/user/useSignUp';

export const SignUp = () => {
  const captchaKey = env.REACT_APP_RECAPTCHA_KEY;

  const { captchaRef, captchaToken, onCaptchaValidate, onCaptchaExpiry } = useCaptchaValidationService();

  const {
    inputs: { username, email, company },
    status,
    errors,
    consentChecked,
    setConsentChecked,
    onInputChange,
    onSubmit,
    onRedirect,
  } = useSignUp(captchaToken);

  if (!captchaKey) {
    console.error('REACT_APP_RECAPTCHA_KEY is not provided');
  }

  return (
    <FormLayout header="Sign Up">
      {status === 'success' ? (
        <SuccessMessage>
          Check your mailbox.
          <br />
          <br />
          We sent an email with next steps to your email address: {email}
          <br />
          <br />
          You can close this tab.
        </SuccessMessage>
      ) : (
        <Form onSubmit={onSubmit}>
          <InputsWrapper>
            <InputsColumn>
              <Input name="username" placeholder="Full Name" value={username} onChange={onInputChange} required />
              <Input name="company" placeholder="Company Name" value={company} onChange={onInputChange} required />
            </InputsColumn>
            <InputsColumn>
              <Input name="email" placeholder="E-mail" type="email" value={email} onChange={onInputChange} required />
            </InputsColumn>
          </InputsWrapper>
          <ConsentCheckbox checked={consentChecked} onChange={() => setConsentChecked((prev) => !prev)} />
          {captchaKey && (
            <ReCAPTCHA
              ref={captchaRef}
              onChange={onCaptchaValidate}
              onExpired={onCaptchaExpiry}
              sitekey={captchaKey}
              theme="dark"
            />
          )}
          {errors.map((error) => (
            <Error key={error}>{error}</Error>
          ))}
          <FormButtons isLoading={status === 'loading'} handleCancel={onRedirect} />
        </Form>
      )}
    </FormLayout>
  );
};

const Form = styled.form`
  margin-top: 40px;
`;

const InputsWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`;

const InputsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Error = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.errorPink};
  margin: 22px 0 0;
`;

const SuccessMessage = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 14px;
`;
