import { getDottedDateFromUnixEpochTime } from 'common/utils/datetime';
import { RangeSelectorModule } from 'ui/slider/RangeSelectorModule';

type RangeSelectorProps = {
  rangeValue: number[];
  setRangeValue: (rangeValue: number[]) => void;
  firstDate: number;
  lastDate: number;
  localRange: number[] | undefined;
  anchor?: number[];
  label?: string;
};

export const TimelineRangeSelector = ({
  rangeValue,
  setRangeValue,
  firstDate,
  lastDate,
  localRange,
  label = 'Select Timeframe for Timeline',
}: RangeSelectorProps) => {
  const lowerRange = localRange && localRange[0];
  const upperRange = localRange && localRange[localRange.length - 1];

  return (
    <RangeSelectorModule
      endValues={[0, 100]}
      selectedValues={rangeValue}
      setSelectedValues={setRangeValue}
      header={label}
      minLabel={getDottedDateFromUnixEpochTime(firstDate)}
      maxLabel={getDottedDateFromUnixEpochTime(lastDate)}
      firstValueLabel={lowerRange ? getDottedDateFromUnixEpochTime(lowerRange) : ''}
      lastValueLabel={upperRange ? getDottedDateFromUnixEpochTime(upperRange) : ''}
      geometry={{ right: 0, top: -116 }}
    />
  );
};
