import { Rectangle } from 'react-leaflet';
import { Layer } from 'components/map';
import { ROUTES } from 'common/navigation/routes';
import { useNavigateWithQuery } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useMonitoringInfoStore } from '../store';

export const MapWaterAreas = () => {
  const waterbodies = useMonitoringInfoStore.use.waterbodies();
  const navigateWithQuery = useNavigateWithQuery();

  return (
    <>
      {waterbodies && (
        <Layer visible={true} name="waterbodies-ovewview" zIndex={500}>
          {waterbodies.map((item) => (
            <Rectangle
              pathOptions={{ color: '#68D5FF' }}
              bounds={item.geometry.coordinates}
              key={item.properties.id}
              eventHandlers={{
                click: () =>
                  navigateWithQuery(ROUTES.waterQuality, [
                    { key: WATERBODY_QUERY_PARAMETER, value: String(item.properties.id) },
                  ]),
              }}
            />
          ))}
        </Layer>
      )}
    </>
  );
};
