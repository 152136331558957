import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type WarningError } from 'domain/@errors/types';
import { type WarningErrorType } from 'domain/@errors/enums';

type WarningErrorList = Record<WarningErrorType, WarningError>;
type WarningErrorItem = Partial<WarningErrorList>;

interface ErrorsSliceState {
  warningErrors: WarningErrorList;
  updateWarningError: (error: WarningErrorItem) => void;
  removeWarningErrorByKey: (type: WarningErrorType) => void;
  removeWarningErrorsByViewPath: (viewPath: string) => void;
  toggleWarningErrorListVisibility: () => void;
  showWarningErrorList: () => void;
  isWarningErrorListVisible: boolean;
}

const initialState = {
  warningErrors: {} as WarningErrorList,
  isWarningErrorListVisible: true,
};

const useErrorsStoreBase = create<ErrorsSliceState>()(
  devtools(
    (set) => ({
      warningErrors: initialState.warningErrors,
      updateWarningError: (error) =>
        set(
          (state) => ({
            warningErrors: {
              ...state.warningErrors,
              ...error,
            },
          }),
          false,
          'errors/updateWarningError',
        ),
      removeWarningErrorByKey: (key) =>
        set(
          (state) => {
            delete state.warningErrors[key];
            return { warningErrors: state.warningErrors };
          },
          false,
          'errors/removeWarningErrorByKey',
        ),
      removeWarningErrorsByViewPath: (viewPath) =>
        set(
          (state) => {
            for (const key in state.warningErrors) {
              if (state.warningErrors[key as WarningErrorType].viewPath === viewPath) {
                delete state.warningErrors[key as WarningErrorType];
              }
            }
            return { warningErrors: state.warningErrors };
          },
          false,
          'errors/removeWarningErrorsByViewPath',
        ),
      isWarningErrorListVisible: initialState.isWarningErrorListVisible,
      toggleWarningErrorListVisibility: () =>
        set(
          (state) => ({
            isWarningErrorListVisible: !state.isWarningErrorListVisible,
          }),
          false,
          'errors/toggleWarningErrorListVisibility',
        ),
      showWarningErrorList: () =>
        set(
          () => ({
            isWarningErrorListVisible: true,
          }),
          false,
          'errors/showWarningErrorList',
        ),
    }),
    { name: 'errorsStore' },
  ),
);

export const useErrorsStore = createSelectors(useErrorsStoreBase);
