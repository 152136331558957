import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from 'common/navigation/routes';
import { Button, Container } from 'ui';

interface Props {
  handleClose: () => void;
  message?: string;
}

export const NoSubscriptionModalContent = ({ message, handleClose }: Props) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(ROUTES.subscriptionsStart);
    handleClose();
  };

  return (
    <Container variant="VERTICAL_START" gap={35}>
      <ModalContentText>{message || 'Start a subscription to unlock this function.'}</ModalContentText>
      <div>
        <Button variant="PRIMARY_BIG" onClick={onClick} squared>
          subscriptions
        </Button>
      </div>
    </Container>
  );
};

const ModalContentText = styled.div`
  max-width: 310px;
`;
