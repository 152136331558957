import Dygraph from 'dygraphs';

import { GraphSeries } from './enums';
import { GRAPH_PADDING_RIGHT } from './constants';
import { mainGraphConfig } from './mainGraphConfig';
import { getAllAxesNamesWithPlotter } from './helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const barChartPlotter = (e: any) => {
  const ctx = e.drawingContext;
  const points = e.points;
  const [minY, , minY2] = e.dygraph.yAxisRanges().flat();
  const [, y_bottom] = minY < minY2 ? e.dygraph.toDomCoords(0, minY, 0) : e.dygraph.toDomCoords(0, minY2, 1);

  ctx.fillStyle = 'rgba(104, 213, 255, 0.4)';
  let min_sep = Infinity;

  for (let i = 1; i < points.length; i++) {
    const sep = points[i].canvasx - points[i - 1].canvasx;
    if (sep < min_sep) min_sep = sep;
  }

  const maxWidth = 0.9 * min_sep;
  const bar_width = Math.floor(13 > maxWidth ? maxWidth : 13);

  for (const point of points) {
    if (!point.yval) continue;
    const center_x = point.canvasx;

    const drawArgs = [center_x - bar_width / 2, y_bottom, bar_width, -(y_bottom - point.canvasy)];
    ctx.fillRect(...drawArgs);
    ctx.strokeRect(...drawArgs);
  }
};

export const buildLinearGraphConfig = (
  names: string[],
  valueFormatter: (num_or_millis: number, _opts: never, seriesName: string) => string,
) => ({
  strokeWidth: 2.0,
  pointSize: 4,
  drawHighlightPointCallback: Dygraph.Circles.DEFAULT,
  highlightCircleSize: 6,
  height: 135,
  includeZero: false, // if false (default) - collapse empty Y range

  axes: {
    x: {
      ...mainGraphConfig.axes.x,
      axisLabelWidth: 0,
    },
    y: {
      ...mainGraphConfig.axes.y,
      gridLinePattern: [4, 4],
      pixelsPerLabel: 20,
      valueFormatter,
    },
    y2: {
      valueFormatter,
      axisLabelFormatter: (dateNumber: number) => {
        return valueFormatter(dateNumber, null as never, GraphSeries.TEMPERATURE);
      },
      axisLabelWidth: GRAPH_PADDING_RIGHT - 5,
      drawAxis: true,
      valueRange: [null, null] as [number | null, number | null], // default null means +-infinity
    },
  },

  series: {
    ...getAllAxesNamesWithPlotter(names),
    ...{
      temperature: {
        strokeWidth: 1.0,
        color: '#FF9900',
        drawPoints: false,
        axis: 'y2',
      },
      precipitation: {
        plotter: barChartPlotter,
        drawPoints: false,
        color: 'rgba(104, 213, 255, 0.4)',
        axis: 'y2',
      },
    },
  },
  visibility: [true, true, true],
});
