import { type AxiosResponse } from 'axios';

import { getExternalData } from 'services/rest/restApiService';
import { TEMPERATURE_API_URLS } from 'infrastructure/temperature/temperatureApiUrls';

export const temperatureApiAdapter = {
  getResults: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(TEMPERATURE_API_URLS.getResults, { limit, offset }),
};
