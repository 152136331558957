import { type ReactNode } from 'react';
import { View, Text } from '@react-pdf/renderer';

import { commonStyles, tableStyles } from 'views/reports/external/pdfGenerator/styles';

interface PdfTableValueColumnProps {
  children?: ReactNode;
  headerText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
  dataKey: string;
  fixed?: number;
  alignRight?: boolean;
  fg1?: boolean;
}

export const PdfTableValueColumn = ({
  children,
  headerText,
  data,
  dataKey,
  alignRight,
  fg1,
}: PdfTableValueColumnProps) => {
  const alignRightStyles = alignRight ? tableStyles.table__value__tar : {};
  const flexGrown1Styles = fg1 ? commonStyles.flexGrow : {};

  return (
    <View style={{ ...tableStyles.table__column, ...flexGrown1Styles }}>
      <Text style={{ ...tableStyles.table__value, ...alignRightStyles }}>{headerText}</Text>
      {children ||
        data?.map((item, index) => (
          <Text style={{ ...tableStyles.table__value, ...alignRightStyles }} key={index}>
            {typeof item[dataKey] === 'string' ? item[dataKey] : '—'}
          </Text>
        ))}
    </View>
  );
};
