import styled, { type CSSProperties } from 'styled-components';

import { type Palette } from 'ui/types';
import { LoadingSpinner } from 'ui/atoms/loadingSpinner';

type SpinOverlayVariant = 'LOADING' | 'SAVING' | 'SAVED';

type OverlayStyles = Required<Pick<CSSProperties, 'backgroundColor'>>;

const styles: Palette<SpinOverlayVariant, OverlayStyles> = {
  LOADING: {
    backgroundColor: 'rgba(35, 32, 32, 1)',
  },
  SAVING: {
    backgroundColor: 'rgba(35, 32, 32, 0.9)',
  },
  SAVED: {
    backgroundColor: 'rgba(35, 32, 32, 0.6)',
  },
};

interface SpinOverlayProps {
  variant?: SpinOverlayVariant;
}

export const SpinOverlay = ({ variant = 'LOADING' }: SpinOverlayProps) => (
  <StyledSpinOverlay variant={variant}>{variant !== 'SAVED' && <LoadingSpinner />}</StyledSpinOverlay>
);

const StyledSpinOverlay = styled.div<{ variant: SpinOverlayVariant }>`
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: ${({ variant }) => styles[variant].backgroundColor};
`;

SpinOverlay.displayName = 'SpinOverlay';
