import styled from 'styled-components';

import { ReactComponent as Logo } from 'ui/assets/terraeye-logo-small.svg';

interface HomeButtonProps {
  onClick: () => void;
  onHover?: () => void;
}

export const HomeButton = ({ onClick, onHover }: HomeButtonProps) => (
  <LogoWrapper role="button" aria-label="home-button" onClick={onClick} onMouseEnter={onHover}>
    <TerraEyeLogo />
  </LogoWrapper>
);

const LogoWrapper = styled.div`
  width: 50px;
  height: 50px;
  z-index: 1000;
  display: grid;
  place-items: center;
`;

const TerraEyeLogo = styled(Logo)`
  color: ${({ theme }) => theme.color.silverDark};
  width: 30px;
  height: 30px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: none;
  }
`;
