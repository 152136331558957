import { useEffect } from 'react';
import { InfoArea, useInfoAreaService } from 'ui/infoArea';
import { ColorScaleBadge, Select } from 'ui';
import { AppLayerManager } from 'components/layerManager/legacy';
import { useGraphStore } from 'components/graph/graphStore';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useUserStore } from 'infrastructure/user/userStore';
import { TEMPERATURE_COLORMAP } from 'domain/temperature/colors';
import { TemperatureColorScale } from './TemperatureColorScale';
import { useSpatialResolution } from './useSpatialResolution';
import { TemperatureTimeline } from './TemperatureTimeline';

export const Temperature = () => {
  const infoAreaCommonProps = useInfoAreaService();

  const { userPermissions } = useUserStore();

  const { resolutionOptions, resolution, changeResolution } = useSpatialResolution();

  const resetGraphStore = useGraphStore.use.resetGraphStore();

  useEffect(() => {
    return () => {
      resetGraphStore();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!userPermissions) return null;

  return (
    <AreaDetailsLayout
      legend={<TemperatureColorScale />}
      toolbarContent={
        <>
          <InfoArea {...infoAreaCommonProps}>
            <h4>Land Surface Temperature</h4>
          </InfoArea>
          <Select
            name="spatial-resolution"
            options={resolutionOptions}
            selectedOption={resolution}
            onChange={changeResolution}
          />
        </>
      }
      toolboxContent={<LayerManagerControlButton />}
    >
      <AppLayerManager
        mainLayerLabel={<ColorScaleBadge colormap={TEMPERATURE_COLORMAP} label={'temperature'} segmented />}
        mainLayerOpacityAdjustable
      ></AppLayerManager>
      <TemperatureTimeline />
    </AreaDetailsLayout>
  );
};
