import { useAoiNumber } from 'common/navigation/hooks';
import { extractionRepository } from 'infrastructure/extraction/extractionRepository';

export const useGetExtractionResultsUseCase = () => {
  const areaId = useAoiNumber();

  const { mineResults, mineResultsLoading } = extractionRepository.useGetMineResults(areaId);

  return { mineResults, mineResultsLoading };
};
