import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';

interface shareModalState {
  isShareModalOpen: boolean;
  openShareModal: () => void;
  closeShareModal: () => void;
  toggleShareModal: () => void;
}

const initialState = {
  isShareModalOpen: false,
};

const useShareModalStoreBase = create<shareModalState>()(
  devtools(
    (set) => ({
      isShareModalOpen: initialState.isShareModalOpen,
      openShareModal: () => set({ isShareModalOpen: true }, false, 'shareModal/open'),
      closeShareModal: () => set({ isShareModalOpen: false }, false, 'shareModal/close'),
      toggleShareModal: () =>
        set((state) => ({ isShareModalOpen: !state.isShareModalOpen }), false, 'shareModal/toggle'),
    }),
    { name: 'shareModalStore' },
  ),
);

export const useShareModalStore = createSelectors(useShareModalStoreBase);
