import styled from 'styled-components';

import { getPercentageValue, getShortenedMultiple } from 'common/utils';
import { Tooltip } from 'ui/atoms/tooltip';
import { type Segment } from './types';
import { getFilteredSegments } from './helpers';

interface BarsProps {
  segments: Segment[];
  range: number[];
  allowance?: number;
}

export const Bars = ({ segments, range, allowance = 0 }: BarsProps) => {
  const filteredSegments = getFilteredSegments(segments, range);

  const yValues = filteredSegments.map((segment) => segment.value);
  const highestValueWithAllowance = (Math.max(...yValues) * (100 + allowance)) / 100;

  return filteredSegments.map(({ start, end, value }, index) => {
    const percent = getPercentageValue(value, [0, highestValueWithAllowance]);

    return (
      <Tooltip
        key={`bar-${index}`}
        content={`${start.toFixed(2)}-${end.toFixed(2)}°, count: ${getShortenedMultiple(value)}`}
        placement="top"
        interactive
      >
        <Bar percent={percent} start={getPercentageValue(start, range)} end={getPercentageValue(end, range)} />
      </Tooltip>
    );
  });
};

const Bar = styled.li<{ percent: number; start: number; end: number }>`
  height: ${({ percent }) => percent}%;
  width: ${({ start, end }) => end - start}%;
  background: #06a1a1;
  z-index: 25;
  border-right: 2px solid ${({ theme }) => theme.color.mineShaftABitLighter};
  border-bottom: 1px solid ${({ theme }) => theme.color.silver};
  position: absolute;
  left: ${({ start }) => `${start}%`};
  cursor: default;

  &:hover {
    background: #0a6e6e;
  }
`;
