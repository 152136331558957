import { type ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import { useUserStore } from 'infrastructure/user/userStore';
import { LoginPrompt } from 'views/user/LoginPrompt';
import { Fallback } from 'ui';

interface AppLayoutProps {
  sidebar: ReactNode;
  children?: ReactNode;
  topBarless?: boolean;
}

export const AppLayout = ({ sidebar, children, topBarless }: AppLayoutProps) => {
  const { isLoginPromptVisible } = useUserStore();

  return (
    <>
      {isLoginPromptVisible && <LoginPrompt />}
      <PageWrapper>
        {sidebar}
        <MainContentWrapper>
          {!topBarless && <TopBar />}
          <ErrorBoundary FallbackComponent={(fallbackProps) => <Fallback {...fallbackProps} isTransparentBg />}>
            {children}
          </ErrorBoundary>
        </MainContentWrapper>
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.mineShaftDark};
`;

const MainContentWrapper = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  position: relative;
  width: 100%;
`;

const TopBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.mineShaftDark};
  -webkit-box-shadow: 0px 36px 64px 32px ${({ theme }) => theme.color.blackTransparent78};
  -moz-box-shadow: 0px 36px 64px 32px ${({ theme }) => theme.color.blackTransparent78};
  box-shadow: 0px 36px 64px 32px ${({ theme }) => theme.color.blackTransparent78};
  filter: opacity(0.8);
  z-index: 200;
`;
