import { useEffect } from 'react';
import { temperatureRepository } from 'infrastructure/temperature/temperatureRepository';
import { useGraphStore } from 'components/graph/graphStore';

export const useTemperaturePointHistory = () => {
  const selectedPoint = useGraphStore.use.chosenPoint();
  const handleDataPointSelect = useGraphStore.use.setChosenPoint();

  const setPointHistory = useGraphStore.use.setGraphData();

  const { history, fetchTemperaturePointHistory, isHistoryLoading } =
    temperatureRepository.useFetchTemperaturePointHistory();

  const getHistoryForChosenPoint = async (lat: number, lng: number, value: number) => {
    fetchTemperaturePointHistory(lat, lng);

    handleDataPointSelect({ lat, lng, value });
  };

  useEffect(() => {
    if (history?.length) {
      setPointHistory(history);
    }
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTemperaturePointClick = (latitude: number, longitude: number, value: number) => {
    getHistoryForChosenPoint(latitude, longitude, value);
  };

  useEffect(() => {
    if (selectedPoint) {
      getHistoryForChosenPoint(selectedPoint.lat, selectedPoint.lng, selectedPoint.value);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { handleTemperaturePointClick, isHistoryLoading };
};
