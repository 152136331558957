import { useGraphStore } from 'components/graph/graphStore';
import { useHandleMeteoDataUseCase } from 'components/graph/handleMeteoDataUseCase';
import { type GraphData } from 'components/graph/types';

export const usePointHistory = () => {
  const additionalDataOptions = useGraphStore.use.additionalDataOptions();

  const showGraph = useGraphStore.use.showGraph();
  const setPointHistory = useGraphStore.use.setGraphData();
  const resetAdditionalData = useGraphStore.use.resetAdditionalData();
  const resetGraphStore = useGraphStore.use.resetGraphStore();

  const { setAdditionalDataOptionUseCase } = useHandleMeteoDataUseCase();

  const setPointHistoryUseCase = (history: GraphData, logError: (message: string) => void) => {
    setPointHistory(history);
    resetAdditionalData();
    setAdditionalDataOptionUseCase(additionalDataOptions, logError);
    showGraph();
  };

  const clearPointHistoryUseCase = () => {
    resetGraphStore();
  };

  return { setPointHistoryUseCase, clearPointHistoryUseCase };
};
