import { Switch, switchClasses } from '@mui/base';
import styled from 'styled-components';

interface SwitcherProps {
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

export const Switcher = ({ checked, disabled, onChange }: SwitcherProps) => {
  return (
    <StyledSwitch role="switch" aria-checked={checked} checked={checked} disabled={disabled} onChange={onChange} />
  );
};

const StyledSwitch = styled(Switch)`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 6px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
  }

  & .${switchClasses.track} {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.color.doveGray};
    border-radius: 3px;
    transition: background-color 120ms ease-in-out;
  }

  & .${switchClasses.thumb} {
    position: relative;
    display: block;
    top: -3px;
    left: 0px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    transition: left 120ms ease-in-out;
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 18px;
    }

    .${switchClasses.track} {
      background-color: ${({ theme }) => theme.color.silverDark};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;
