import { useEffect } from 'react';
import { Container } from 'ui/atoms/container';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { AppLayerManager, LayerManagerLegend } from 'components/layerManager/legacy';
import { useExtractionObjectTypesService } from 'views/mine/extractionLayersService';
import { useGetExtractionPolygonsUseCase } from 'views/mine/useGetExtractionPolygonsUseCase';
import { useLayerOrderStore } from 'components/layerManager/layersOrderStore';
import { MINE_ACTIVITY_INDEX_LABEL } from 'domain/extraction/configs';
import { useExtractionLayersVisibility } from './useExtractionLayersVisibility';

interface ExtractionLayerManagerProps {
  areaId: number;
}

export const ExtractionLayerManager = ({ areaId }: ExtractionLayerManagerProps) => {
  const { layersOrderRecord, setLayersOrderRecord } = useLayerOrderStore();

  const { segmentationLegendOptions } = useExtractionObjectTypesService();

  const { minePolygonsLoading } = useGetExtractionPolygonsUseCase();

  // TODO: refactor when PIG data will be handled
  const disableInfrastructureLayer = areaId !== 516;

  const mainLayerLabel =
    areaId === 773 || areaId === 775 || areaId === 776 || areaId === 777 ? MINE_ACTIVITY_INDEX_LABEL : undefined;

  const { classesQuery, classesVisibility, changeClassQuery, updateClassesVisibility, toggleMultipleQueries } =
    useExtractionLayersVisibility();

  useEffect(() => {
    if (!classesQuery) {
      changeClassQuery('mining');
    }
  }, [classesQuery, changeClassQuery]);

  return (
    <AppLayerManager disableInfrastructureLayer={disableInfrastructureLayer} mainLayerLabel={mainLayerLabel}>
      {minePolygonsLoading ? (
        <Container mt={10}>
          <LoadingDots />
        </Container>
      ) : (
        <LayerManagerLegend
          areaId={areaId}
          options={segmentationLegendOptions}
          objectsVisibility={classesVisibility}
          toggleLayerVisibility={toggleMultipleQueries}
          updateLayersVisibility={updateClassesVisibility}
          isDndView
          layersOrderRecord={layersOrderRecord}
          setLayersOrderRecord={setLayersOrderRecord}
        />
      )}
    </AppLayerManager>
  );
};
