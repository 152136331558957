import { type LayerObject } from 'common/types/mapData';
import { useAoiNumber } from 'common/navigation/hooks';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { Container } from 'ui/atoms/container';
import { useGreeneryObjectsUpdateUseCase } from 'views/greenery/useCases/greeneryObjectsUpdateUseCase';
import { segmentationDataService } from 'views/greenery/segmentation/segmentationDataService';
import { WithDailySegmentationCog } from 'views/greenery/segmentation/WithDailySegmentationCog';
import { GreeneryLayerManagerLegend } from 'views/greenery/components/GreeneryLayerManagerLegend';

interface SegmentationLayerManagerProps {
  layerObjects: LayerObject[];
  greeneryObjectsLoading: boolean;
  viewSpecificDisabledLayers?: string[];
  noBadges?: boolean;
  timestamp: string;
}

export const SegmentationLayerManager = ({
  layerObjects,
  greeneryObjectsLoading,
  viewSpecificDisabledLayers = [],
  noBadges,
  timestamp,
}: SegmentationLayerManagerProps) => {
  const areaId = useAoiNumber();

  const { resultId } = segmentationDataService.useSegmentationResult(timestamp);

  useGreeneryObjectsUpdateUseCase(layerObjects, areaId);

  return (
    <>
      {resultId &&
        (greeneryObjectsLoading ? (
          <Container mt={10}>
            <LoadingDots />
          </Container>
        ) : (
          <WithDailySegmentationCog
            Component={GreeneryLayerManagerLegend}
            props={{ areaId, layerObjects, viewSpecificDisabledLayers, noBadges, chosenResultId: resultId }}
            resultId={resultId}
          />
        ))}
    </>
  );
};
