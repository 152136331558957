import { Image, View } from '@react-pdf/renderer';

import { commonStyles, screenshotStyles } from 'views/reports/external/pdfGenerator/styles';
import { ReportPdfSimpleTable } from 'views/reports/external/pdfGenerator/simple/ReportPdfSimpleTable';
import { ReportPdfDonutChart } from 'views/reports/external/pdfGenerator/simple/ReportPdfDonutChart';
import { type ExternalReportChart, type ExternalReportSimpleTable } from 'domain/reports/types';

interface ReportPdfSimpleContentProps {
  screenshotScr: string;
  table: ExternalReportSimpleTable;
  chart?: ExternalReportChart;
}

export const ReportPdfSimpleContent = ({ screenshotScr, table, chart }: ReportPdfSimpleContentProps) => (
  <>
    <Image style={screenshotStyles.single} src={screenshotScr} />
    <View style={commonStyles.flexRow}>
      {chart && <ReportPdfDonutChart chart={chart} />}
      {table.data.length && <ReportPdfSimpleTable table={table} />}
    </View>
  </>
);
