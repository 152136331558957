import { useGraphStore } from 'components/graph/graphStore';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';

export const useWaterAreaSelectUseCase = (waterbodyIdQuery: string | null) => {
  const graphData = useGraphStore.use.graphData();
  const resetGraphStore = useGraphStore.use.resetGraphStore();

  const { extentPolygon } = useGetExtentPolygon();

  const setChosenPoint = useGraphStore.use.setChosenPoint();

  const { fetchWaterAreaHistory, isHistoryLoading } = waterRepository.useFetchWaterAreaHistory();

  const selectWaterArea = (lat: number, lng: number, value: number) => {
    if (graphData && !waterbodyIdQuery) {
      resetGraphStore();
    }

    if (extentPolygon && waterbodyIdQuery) {
      fetchWaterAreaHistory(Number(waterbodyIdQuery));

      setChosenPoint({ lat, lng, value });
    }

    return () => {
      resetGraphStore();
    };
  };

  return { isHistoryLoading, selectWaterArea };
};
