import styled from 'styled-components';

import { ReactComponent as ChevronIcon } from './chevron.svg';

type ChevronVariant = 'DEFAULT' | 'RIGHT' | 'LEFT';

interface ChevronProps {
  isFlipped?: boolean;
  width?: number;
  height?: number;
  variant?: ChevronVariant;
  marginRight?: number;
  marginLeft?: number;
  offset?: [number, number];
  disabled?: boolean;
}

export const Chevron = ({
  isFlipped,
  width = 19,
  height = 11,
  variant = 'DEFAULT',
  marginRight,
  marginLeft,
  offset,
  disabled,
}: ChevronProps) => (
  <ChevronWrapper
    {...{ isFlipped, marginRight, marginLeft, offset, disabled }}
    left={variant === 'LEFT'}
    right={variant === 'RIGHT'}
  >
    <ChevronIcon data-testid={`chevron-${variant.toLowerCase()}`} width={width} height={height} />
  </ChevronWrapper>
);

interface ChevronWrapperProps {
  isFlipped?: boolean;
  right?: boolean;
  left?: boolean;
  marginRight?: number;
  marginLeft?: number;
  offset?: [number, number];
  disabled?: boolean;
}

const ChevronWrapper = styled.div<ChevronWrapperProps>`
  color: ${({ disabled, theme }) => theme.color[disabled ? 'whiteTransparent40' : 'white']};
  display: flex;
  align-items: center;
  transition: transform ${({ theme }) => theme.transitionFunction.main};
  margin-left: ${({ marginLeft }) => marginLeft}px;
  margin-right: ${({ marginRight }) => marginRight}px;
  transform: ${({ offset }) => offset && `translate(${offset[0]}px, ${offset[1]}px)`}${({ isFlipped: isOpen }) =>
      isOpen && 'rotate(180deg)'}${({ right }) => right && 'rotate(270deg)'}${({ left }) => left && 'rotate(90deg)'};
`;
