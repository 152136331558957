import { useAoiNumber } from 'common/navigation/hooks';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';

export const useGetSelectedClassifierMineralsResults = (classifierId: number) => {
  const areaId = useAoiNumber();

  const { mineralsResults } = explorationRepository.useFetchMineralsResults(areaId);

  const selectedClassifierMineralsResults = mineralsResults.filter(
    ({ classifier_id }) => classifier_id === classifierId,
  );

  return { selectedClassifierMineralsResults };
};
