import styled, { keyframes } from 'styled-components';

import { ScrollbarWrapper } from 'ui';
import {
  type SubscriptionProductDTO,
  type SubscriptionRequest,
  type SubscriptionRequestAction,
} from 'domain/subscription/types';
import { SubscriptionItem } from './SubscriptionItem';

interface SubscriptionItemProps {
  list: SubscriptionProductDTO[];
  loading: boolean;
  handleSubscriptionRequest: (request: SubscriptionRequest, action: SubscriptionRequestAction) => void;
}

export const SubscriptionsList = ({ list, loading, handleSubscriptionRequest }: SubscriptionItemProps) => {
  return (
    <Wrapper>
      <StyledList>
        <SubscriptionsGrid>
          {loading
            ? Array.from({ length: 3 }).map((_, i) => <Skeleton key={i} />)
            : list.map((subscription) => (
                <SubscriptionItem key={subscription.label} {...{ subscription, handleSubscriptionRequest }} />
              ))}
        </SubscriptionsGrid>
      </StyledList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  max-width: 1600px;
  overflow: hidden;
  padding: 60px 20px 120px 0;
`;

const StyledList = styled(ScrollbarWrapper)`
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    margin: 110px 0 20px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  @-moz-document url-prefix() {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.color.midGray} transparent;
    scrollbar-gutter: stable;
  }
`;

const SubscriptionsGrid = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 40px;
  margin-right: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoint.xl}) {
    flex-wrap: wrap;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const skeletonAnimation = keyframes`
    from {
      background-position-x: 125%;
    }
    to {
      background-position-x: -25%;
    }
  `;

const Skeleton = styled.div`
  position: relative;
  width: 300px;
  height: 450px;
  padding: 20px 30px;
  background: ${({ theme }) =>
    `linear-gradient(65deg, transparent 32%, ${theme.color.midGray} 50%, ${theme.color.mineShaft} 68%)`};
  background-size: 250% 100%;
  animation: 2s ${skeletonAnimation} linear infinite;
`;
