import { useQueries, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { alterArrayElements } from 'common/utils';
import {
  MINE_ACTIVITY_INDEX_HEXAGONS_QUERY_KEY,
  MINE_ACTIVITY_INDEX_QUERY_KEY,
  MINE_OBJECTS_CACHE_TIME,
  MINE_OBJECTS_QUERY_KEY,
  MINE_OBJECTS_STALE_TIME,
  MINE_POLYGON_QUERY_KEY,
  MINE_RESULTS_QUERY_KEY,
} from 'infrastructure/extraction/constants';
import { extractionAPI } from 'infrastructure/extraction/extractionApiAdapter';
import { restAPI } from 'services/rest/restApiService';
import { useHandleWarningErrorUseCase } from 'views/@errors/handleTypicalWarningErrorUseCase';
import { type MinePolygonsTuple } from 'domain/extraction/types';
import {
  areMineActivityIndexHexagonsValid,
  areMineActivityIndexResultsValid,
  areMineResultsValid,
} from 'domain/extraction/typeguards';
import { WarningErrorType } from 'domain/@errors/enums';

export const extractionRepository = {
  // TODO: use when https://github.com/FourPointSPZOO/terraeye-backend/issues/374 issue resolved
  useFetchMineObjects: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data } = useQuery(
      [
        MINE_OBJECTS_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => extractionAPI.getMineObjects({ limit, offset }),
      {
        staleTime: MINE_OBJECTS_STALE_TIME,
        cacheTime: MINE_OBJECTS_CACHE_TIME,
      },
    );

    return {
      mineObjects: data?.data?.results || [],
    };
  },

  useGetMineResults: (areaId: number, limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading, error, isSuccess } = useQuery(
      [
        MINE_RESULTS_QUERY_KEY,
        {
          areaId,
          limit,
          offset,
        },
      ],
      () => extractionAPI.getMineResultsWithLimit({ areaId, limit, offset }),
    );

    useHandleWarningErrorUseCase(WarningErrorType.EXTRACTION_RESULTS, data, error, isSuccess);

    const mineResults = areMineResultsValid(data) ? data.data.results : [];

    return {
      mineResults,
      mineResultsLoading: isLoading,
    };
  },

  useFetchMinePolygons: (mineResultIds: number[], allResultsQty: number) => {
    const minePolygonsResponse = useQueries({
      queries: mineResultIds.map((id) => ({
        queryKey: [MINE_POLYGON_QUERY_KEY, id],
        queryFn: () => extractionAPI.getMinePolygon(id),
      })),
    });

    const minePolygonsCoordinates = minePolygonsResponse
      .map((result) => result.data?.data)
      .filter((result) => result)
      .map((result) => {
        return {
          [result!.properties.mine_object.id]: alterArrayElements(result!.geometry.coordinates),
        };
      });

    const error = minePolygonsResponse.every((result) => result.isError)
      ? minePolygonsResponse.find((result) => result.error instanceof AxiosError)?.error
      : undefined;
    const isSuccess = !minePolygonsResponse.length && !!allResultsQty;

    useHandleWarningErrorUseCase(WarningErrorType.EXTRACTION_POLYGONS, undefined, error, isSuccess);

    const minePolygons: MinePolygonsTuple = Object.assign({}, ...minePolygonsCoordinates);
    const minePolygonsLoading = minePolygonsResponse.some((result) => result.isLoading);

    return {
      minePolygons,
      minePolygonsLoading,
    };
  },

  useFetchMineActivityIndexResults: (
    areaName: string | undefined,
    date: string,
    limit: number = DEFAULT_RESULTS_PER_PAGE,
    offset?: number,
  ) => {
    const { data } = useQuery(
      [
        MINE_ACTIVITY_INDEX_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => extractionAPI.getMineActivityIndexResults({ limit, offset }),
    );

    const results = areMineActivityIndexResultsValid(data) ? data.data.results : [];

    const result = results.find((result) => result.aoi_name === areaName && result.date === date);

    return { result };
  },

  useFetchMineActivityIndexHexagons: (id: number, url: string) => {
    const { data } = useQuery([MINE_ACTIVITY_INDEX_HEXAGONS_QUERY_KEY, { id }], () => restAPI.external(url));

    const hexagons = areMineActivityIndexHexagonsValid(data) ? data.data.features : [];

    return { hexagons };
  },
};
