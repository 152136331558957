import { useMainSliderService } from 'components/Timeline/mainSliderService';
import { useUpdateTimestamp } from 'common/navigation/hooks';
import { TimelineWrapper } from 'components/Timeline/TimelineWrapper';
import { TimelineRangeSelector } from 'components/Timeline/RangeSelector';
import { Slider } from 'components/Timeline/Slider';
import { type GraphConfig } from 'components/graph/types';
import { useTimelineService } from 'components/Timeline/timelineService';

interface TimelineProps {
  selectedTimestamps: number[] | undefined;
  filteredTimestamps: string[];
  rangeValue: number[];
  setRangeValue: React.Dispatch<React.SetStateAction<number[]>>;
  firstDate: number;
  lastDate: number;
  onClearAction?: () => void;
  visible?: boolean;
  quarterly?: boolean;
  inverted?: boolean;
  graphAvailable?: boolean;
  dontUpdateTimestamp?: boolean;
  graphConfigExtension?: GraphConfig;
}

export const Timeline = ({
  selectedTimestamps,
  filteredTimestamps,
  rangeValue,
  setRangeValue,
  firstDate,
  lastDate,
  onClearAction,
  visible,
  quarterly,
  inverted,
  dontUpdateTimestamp,
}: TimelineProps) => {
  const { timestamp, changeTimestamp } = useTimelineService();

  const {
    trackDates,
    selectedDate,
    timelinePoints,
    handlePosition: mainHandlePosition,
    onTrackClick: handleSliderTrackClick,
    onHandleMove: handleSliderTrackHandleMouseDown,
  } = useMainSliderService(
    selectedTimestamps,
    timestamp,
    changeTimestamp,
    onClearAction,
    quarterly,
    dontUpdateTimestamp,
  );

  const isTimestampUpdateEnabled = !!selectedTimestamps?.length;

  useUpdateTimestamp(
    filteredTimestamps,
    timestamp,
    changeTimestamp,
    isTimestampUpdateEnabled,
    inverted,
    dontUpdateTimestamp,
  );

  const isTimelineShowed = !!(filteredTimestamps.length && visible && firstDate && lastDate);

  return (
    <>
      {isTimelineShowed && (
        <TimelineWrapper>
          <TimelineRangeSelector
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
            firstDate={firstDate}
            lastDate={lastDate}
            localRange={selectedTimestamps}
            label="Select Timeframe for Timeline"
          />
          <Slider
            trackDates={trackDates}
            selectedDate={selectedDate}
            timelinePoints={timelinePoints}
            handlePosition={mainHandlePosition}
            handleTrackClick={handleSliderTrackClick}
            handleTrackHandleMouseDown={handleSliderTrackHandleMouseDown}
            quarterly={quarterly}
          />
        </TimelineWrapper>
      )}
    </>
  );
};
