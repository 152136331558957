import { type CSSProperties, useState } from 'react';
import styled, { css } from 'styled-components';

import { type Palette } from 'ui/types';
import { ReactComponent as CrossIcon } from 'assets/svg/rounded-cross-fat.svg';
import { ReactComponent as CheckIcon } from 'assets/svg/rounded-check.svg';

export type TagVariant = 'BASIC' | 'NEW' | 'ARCHIVED' | 'REJECTED';

type TagStyles = Required<
  Pick<CSSProperties, 'backgroundColor'> & {
    hoverBackgroundColor?: CSSProperties['backgroundColor'];
  }
>;

export const tagStyles: Palette<TagVariant, TagStyles> = {
  NEW: {
    backgroundColor: 'keppel',
    hoverBackgroundColor: 'paradiso',
  },
  ARCHIVED: {
    backgroundColor: 'copperRust',
    hoverBackgroundColor: 'spicyMix',
  },
  BASIC: {
    backgroundColor: 'midGray',
    hoverBackgroundColor: 'selectGray',
  },
  REJECTED: {
    backgroundColor: 'notificationDarkRed',
    hoverBackgroundColor: 'darkTan',
  },
};

export interface TagProps {
  variant?: TagVariant;
  text?: string;
  checked?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
}

export const Tag = ({ variant = 'BASIC', text, checked, onClick }: TagProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <StyledTag
      $variant={variant}
      $notSelected={checked === false}
      onClick={onClick}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      {text}
      {typeof checked == 'boolean' && (
        <IconWrapper>
          {((checked && !isHovering) || (!checked && isHovering)) && <CheckIcon />}
          {((checked && isHovering) || (!checked && !isHovering)) && <CrossIcon />}
        </IconWrapper>
      )}
    </StyledTag>
  );
};

const StyledTag = styled.div<{ $variant: TagVariant; $notSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 7px;
  min-height: 20px;
  background-color: ${(props) => props.theme.color[tagStyles[props.$variant!].backgroundColor]};
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  word-break: break-word;
  cursor: pointer;
  padding: 0 7px;
  border-radius: 4px;

  &:hover {
    background-color: ${(props) => props.theme.color[tagStyles[props.$variant!].hoverBackgroundColor]};
  }

  ${({ $notSelected }) =>
    $notSelected &&
    css`
      background-color: ${({ theme }) => theme.color.silverDark};
      color: ${({ theme }) => theme.color.mineShaftABitLighter};

      &:hover {
        background-color: ${({ theme }) => theme.color.lightGray};
      }
    `}
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  width: 8px;
`;

Tag.displayName = 'Tag';
