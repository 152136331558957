import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { WaterAreaSingleDateReportsExternalData } from 'views/reports/external/data/WaterAreaSingleDateReportsExternalData';
import { WaterAreaDobuleDateReportsExternalInitialData } from 'views/reports/external/data/WaterAreaDobuleDateReportsExternalInitialData';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface WaterAreaReportsExternalDataProps {
  areaId: number;
  timestamp: string;
  reportInfo: ExternalReportHeaderCommon;
}

export const WaterAreaReportsExternalData = ({ areaId, timestamp, reportInfo }: WaterAreaReportsExternalDataProps) => {
  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  return isCompareDatesType ? (
    <WaterAreaDobuleDateReportsExternalInitialData areaId={areaId} timestamp={timestamp} reportInfo={reportInfo} />
  ) : (
    <WaterAreaSingleDateReportsExternalData areaId={areaId} timestamp={timestamp} reportInfo={reportInfo} />
  );
};
