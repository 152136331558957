import { MILISECONDS_MULTIPLIER } from 'common/constants/datetime';
import { type Token } from 'domain/user/types';

export const getTokenLifetimeHelper = (token: Token) => token.exp * MILISECONDS_MULTIPLIER - Date.now();

export const buildTokenLifetimeInfoHelper = (token: Token) => {
  const delta = getTokenLifetimeHelper(token);
  const hours = new Date(delta).getUTCHours();
  const minutes = new Date(delta).getUTCMinutes();
  const seconds = new Date(delta).getUTCSeconds();
  const standarized = (amount: number) => (amount < 10 ? '0' : '') + amount;

  return delta >= 0 ? `${standarized(hours)}:${standarized(minutes)}:${standarized(seconds)}` : 'none';
};

export const getUsername = (email: string, firstName?: string, lastName?: string): string => {
  const explicitUsername = [firstName, lastName].join(' ');
  const emailUsername = email.replace(/\..*/, '').split(new RegExp('[-_@ ]')).slice(0, 2).join(' ');
  const capitalizedUsername = emailUsername.charAt(0).toUpperCase() + emailUsername.slice(1);
  const username = explicitUsername.length > 1 ? explicitUsername : capitalizedUsername;

  return username;
};

export const getInitials = (username: string | undefined): string =>
  username
    ? username
        .split(' ')
        .map((w) => w.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('')
    : 'FP';
