import styled from 'styled-components';

import { Notification } from 'ui/atoms/notification/Notification';
import { useMonitoringInfoStore } from 'views/monitoring/store';
import { AreaInfoEnvironmentsIcons } from 'domain/areas/AreaInfoEnvironmentsIcons';

export const NotificationsSection = () => {
  const notifications = useMonitoringInfoStore.use.notifications();

  return (
    notifications && (
      <div>
        <StyledDescription>NOTIFICATIONS</StyledDescription>
        <StyledBox>
          {notifications.properties.notifications.map((item, i) => (
            <Notification
              icon={AreaInfoEnvironmentsIcons[item.properties.view].icon}
              $iconSize={AreaInfoEnvironmentsIcons[item.properties.view].$iconSize}
              key={i}
              variant={item.properties.danger ? 'DANGER' : 'PRIMARY'}
              to={item.properties.route}
            >
              {item.properties.text}
            </Notification>
          ))}
        </StyledBox>
      </div>
    )
  );
};

const StyledDescription = styled.p`
  font-weight: 600;
  margin: 0;
  letter-spacing: 1px;
`;

const StyledBox = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
