import {
  ML_CLASSES_QUERY_PARAMETER,
  MTMF_CLASSES_QUERY_PARAMETER,
  SAM_CLASSES_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { useQueryParamsClasses } from 'components/layerManager/useQueryParamsClasses';
import { type LayersVisibilityInterface } from 'domain/exploration/types';

export const useSamLayersVisibility = (): LayersVisibilityInterface =>
  useQueryParamsClasses(SAM_CLASSES_QUERY_PARAMETER);

export const useMtmfLayersVisibility = (): LayersVisibilityInterface =>
  useQueryParamsClasses(MTMF_CLASSES_QUERY_PARAMETER);

export const useMlLayersVisibility = (): LayersVisibilityInterface => useQueryParamsClasses(ML_CLASSES_QUERY_PARAMETER);
