import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'common/navigation/routes';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { calculationsRepository } from 'infrastructure/calculations/calculationsRepository';
import { Button } from 'ui/atoms/button';

export const RequestCancelButton = () => {
  const navigate = useNavigate();

  const { clearCalculationsPayloadPartial } = calculationsRepository.useCalculationsPayload();
  const { clearAreaRequestPayload } = areasRepository.useAreaRequestPayload();

  const onCancel = () => {
    clearCalculationsPayloadPartial();
    clearAreaRequestPayload();
    navigate(ROUTES.areasList);
  };

  return (
    <Button name="request-cancel" onClick={onCancel} variant="SECONDARY">
      cancel
    </Button>
  );
};
