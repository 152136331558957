import { useEffect } from 'react';

import { useGetAoiName } from 'common/hooks/getAoiName';
import { Select } from 'ui/atoms';
import { type ValueAndLabel } from 'ui/types';
import { useGreeneryViewOptions } from 'views/greenery/useGreeneryViewOptions';
import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { GREENERY_DATA_PROVIDER_DEFAULT } from 'domain/greenery/configs';

const mockedDataProviderOptions = [
  {
    value: 'planetscope',
    label: 'PlanetScope',
  },
];

export const GreeneryDataProvidersSelect = () => {
  const { areaName } = useGetAoiName();

  const { mapViewOption, resetViewOption } = useGreeneryViewOptions();

  const dataProvider = useGreeneryStore.use.dataProvider();
  const setDataProvider = useGreeneryStore.use.setDataProvider();

  const { segmentationResultsMocked } = greeneryRepository.useFetchSegmentationResultsMocked(areaName);

  const mockedResultsProviderOptions = mockedDataProviderOptions.filter(({ value }) =>
    segmentationResultsMocked.some(({ provider }) => provider === value),
  );

  const options = [GREENERY_DATA_PROVIDER_DEFAULT, ...mockedResultsProviderOptions];

  const selectedOption = mockedDataProviderOptions.find(({ value }) => value === dataProvider);

  useEffect(() => {
    if (mapViewOption?.value && dataProvider !== GREENERY_DATA_PROVIDER_DEFAULT.value) {
      setTimeout(() => resetViewOption(), 100);
    }
  }, [mapViewOption?.value, dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

  const viewOptionsSelectData = {
    options,
    selectedOption,
    initialStateLabel: GREENERY_DATA_PROVIDER_DEFAULT.label,
    noOptionsLabel: GREENERY_DATA_PROVIDER_DEFAULT.label,
    name: 'data provider selection',
    minWidth: 148,
  };

  const onChange = (option: ValueAndLabel) => {
    setDataProvider(String(option.value));
  };

  return <Select {...viewOptionsSelectData} onChange={onChange} minWidth={150} />;
};
