import { useRef } from 'react';
import styled from 'styled-components';

import { Button, Input } from 'ui';
import { ShareBlockActionWrapper } from './styled';
import { copyInClipboard } from '../helpers';

export const ShareLinkBlock = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const copyUrl = async () => {
    try {
      await copyInClipboard(inputRef.current?.value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div>Copy the link below to share this view with your team.</div>
      <ShareBlockActionWrapper>
        <InputWrapper>
          <Input $variant="PRIMARY" ref={inputRef} value={window.location.href} readOnly />
        </InputWrapper>
        <Button onClick={copyUrl} squared>
          Copy Link
        </Button>
      </ShareBlockActionWrapper>
    </div>
  );
};

const InputWrapper = styled.div`
  width: 390px;
  border-bottom: 1px solid ${({ theme }) => theme.color.silverDark};
`;
