import { type ColormapDictionary, type LayersVisibilityDictionary } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { DataLayer } from 'components/map';
import { useGetVegetationMapLayerDataUseCase } from 'views/greenery/vegetation/getVegetationMapLayerDataUseCase';

interface VegetationMapLayerProps {
  resultId: number;
  vegetationCogUrl: string;
  layerId: number;
  greeneryObjects: LayerObject[];
  layersVisibility: LayersVisibilityDictionary;
  colormap: ColormapDictionary;
  range: number[];
}

export const VegetationMapLayer = ({
  resultId,
  vegetationCogUrl,
  layerId,
  greeneryObjects,
  layersVisibility,
  colormap,
  range,
}: VegetationMapLayerProps) => {
  const { cogUrl, cogBounds } = useGetVegetationMapLayerDataUseCase(vegetationCogUrl, colormap, range);

  return (
    <DataLayer
      isVisible={!!layersVisibility[greeneryObjects.filter(({ id }) => id === layerId)[0]?.name]}
      layerName={`vegetationLayer-${resultId}`}
      zIndex={490}
      cogUrl={cogUrl}
      bounds={cogBounds}
      fixed
    />
  );
};
