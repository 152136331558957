import { INDICATORS_OPTION_NAME } from 'common/navigation/constants';
import { useViewOptions } from 'common/navigation/useViewOptions';

export const useSnowViewOptions = () => {
  const props = useViewOptions();

  const { mapViewOption } = props;

  const isIndicatorsView = mapViewOption?.value === INDICATORS_OPTION_NAME;

  return { ...props, isIndicatorsView };
};
