import { useRef, type FormEvent, type ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Popup } from 'react-leaflet';

import { COORDINATES_PRECISION, MAX_PIN_NOTE_LETTERS } from 'common/constants';
import { Button } from 'ui/atoms/button';
import { type NotificationType } from 'domain/monitoring/types';

interface PinProps {
  item: NotificationType;
  openedItem?: NotificationType | null;
  children?: ReactNode;
  onInit?: (popup: L.Popup) => void;
  onSubmit?: (item: NotificationType) => void;
  staticText?: boolean;
  onOpen?: (pin: NotificationType) => void;
  onClose?: (pin: NotificationType) => void;
}

export const NotePopup = ({ item, openedItem, onInit, onSubmit, onOpen, onClose, staticText }: PinProps) => {
  const [value, setValue] = useState(item.properties.text);
  const editableFieldRef = useRef<HTMLTextAreaElement>(null);

  const onSubmitHandler = (event: FormEvent) => {
    event.preventDefault();
    onSubmit && onSubmit(item);
  };

  const onInitHandler = (popup: L.Popup) => {
    if (openedItem) {
      if (openedItem.properties.id === item.properties.id && !popup) {
        onClose && onClose(item);
      }
    } else if (openedItem === null && popup && popup.isOpen()) {
      onOpen && onOpen(item);
    }

    onInit && onInit(popup);
  };

  return (
    <Popup ref={onInitHandler}>
      <StyledWrapper>
        <StyledHeader>
          <small>Location</small>
          <div>{item.geometry.coordinates.map((coord) => coord.toFixed(COORDINATES_PRECISION)).join(' ')}</div>
        </StyledHeader>
        <ContentWrapper onSubmit={onSubmitHandler}>
          <StyledTextArea
            name="note_textarea"
            placeholder="Type note here..."
            maxLength={MAX_PIN_NOTE_LETTERS}
            required
            rows={4}
            value={value}
            disabled={staticText}
            ref={editableFieldRef}
            onChange={(event) => setValue(event.target.value)}
          />
          <ButtonsWrapper $alignStart={true}>
            {onSubmit && (
              <Button variant="PRIMARY_SMALL" type="submit">
                Go to section
              </Button>
            )}
          </ButtonsWrapper>
        </ContentWrapper>
      </StyledWrapper>
    </Popup>
  );
};

const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.color.white};
  width: 215px;
  cursor: auto;
`;
const ContentWrapper = styled.form`
  padding: 8px 10px 10px;
`;

const StyledHeader = styled.div`
  padding: 8px 11px 4px;
  background-color: ${({ theme }) => theme.color.selectGray};
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 4px 4px 0 0;

  & small {
    font-size: 0.6em;
  }
`;

const StyledTextArea = styled.textarea`
  resize: none;
  padding: 0;
  padding-right: 5px;
  background: none;
  border: 0;
  outline: 0;
  color: inherit;
  font-weight: 600;
  line-height: 16px;
  font-size: 12px;
  width: 100%;

  height: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.chicago};
    border-radius: 3px;
  }
`;

const ButtonsWrapper = styled.div<{ $alignStart?: boolean }>`
  margin-top: 10px;
  display: flex;
  justify-content: ${({ $alignStart }) => ($alignStart ? 'flex-start' : 'flex-end')};
  gap: 8px;
  width: 100%;
`;
