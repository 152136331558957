import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { useEffect } from 'react';
import { CreatorOptionsColumn } from 'ui/creator/creatorOptionsColumn';
import { ContentInteractive, OptionButton } from 'ui/creator/optionButton';
import { useDepositOptions } from 'views/areas/areaRequestNew/useDepositOptions';

interface DepositsColumnProps {
  setDepositsColumnValidity: (valid: boolean) => void;
}

export const DepositsColumn = ({ setDepositsColumnValidity }: DepositsColumnProps) => {
  const { areDepositsLoading } = explorationRepository.useFetchDeposits();

  const { depositOptions, currentDeposit } = useDepositOptions();

  useEffect(() => {
    setDepositsColumnValidity(!!currentDeposit);
  }, [currentDeposit, setDepositsColumnValidity]);

  return (
    <CreatorOptionsColumn header="What would you like to prospect for?" width={270} isLoading={areDepositsLoading}>
      {depositOptions.map(({ id, label, content, selected, disabled, onClick }) => (
        <OptionButton key={id} label={label} selected={selected} disabled={disabled} onClick={onClick}>
          <ContentInteractive {...{ label, info: content, warning: false, selected }} />
        </OptionButton>
      ))}
    </CreatorOptionsColumn>
  );
};
