import { Container } from 'ui';
import { OpacitySlider } from 'components/layerManager/OpacitySlider';
import { useLineamentsLayerManagerStore } from './lineamentsLayerManagerStore';

export const LineamentsOpacitySlider = () => {
  const lineamentsOpacity = useLineamentsLayerManagerStore.use.lineamentsOpacity();
  const setLineamentsOpacity = useLineamentsLayerManagerStore.use.setLineamentsOpacity();

  const onLineamentsOpacityChange = (value: number | number[]) => {
    setLineamentsOpacity(Array.isArray(value) ? value[0] : value);
  };

  return (
    <Container variant="VERTICAL_START" gap={10} mt={10}>
      <Container wMax gap={10} pr={10}>
        <OpacitySlider
          ariaLabel="lineaments opacity slider"
          opacity={lineamentsOpacity}
          setOpacity={onLineamentsOpacityChange}
        />
      </Container>
    </Container>
  );
};
