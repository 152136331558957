import { DataLayer } from 'components/map';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { Loading } from 'ui/molecules/loading';

interface ExplorationMapLayerProps<T> {
  zIndex: number;
  name: string;
  url: string;
  isVisible: boolean;
  builder: (url: string, params: T) => string | undefined;
  params: T;
  opacity?: number;
}

export const ExplorationMapLayer = <T,>({
  zIndex,
  name,
  url,
  isVisible,
  builder,
  params,
  opacity = 0.5,
}: ExplorationMapLayerProps<T>) => {
  const { cogUrl, extentLatLngBounds, isInfoLoading } = useGetCogWithBoundsImproved(builder, url, params);

  if (isInfoLoading) {
    return <Loading />;
  }

  if (cogUrl && extentLatLngBounds) {
    return (
      <DataLayer
        key={zIndex}
        isVisible={isVisible}
        layerName={name}
        zIndex={zIndex}
        cogUrl={cogUrl}
        bounds={extentLatLngBounds}
        opacity={opacity}
      />
    );
  }

  return null;
};
