import { type ReactNode } from 'react';
import styled from 'styled-components';
import { MapContainer, TileLayer } from 'react-leaflet';
import VectorBasemapLayer from 'react-esri-leaflet/plugins/VectorBasemapLayer';

import { type BaseMapVariant } from './enums';
import { mapStyle } from './mapStyle';
import { isArcGISMap, isOpenStreetMap } from './typeguards';
import { ZoomControlPlugin } from './ZoomControlPlugin';
import { LoadEndNotifier } from './LoadEndNotifier';
import {
  MAP_DEFAULT_CENTER,
  MAP_INITIAL_ZOOM,
  MAP_MAX_ZOOM,
  MAP_MIN_ZOOM,
  MAP_HORIZONTAL_OFFSET_CORRECTION,
} from './constants';

interface BaseMapProps {
  variant: BaseMapVariant;
  center: string[] | number[];
  zoom?: number;
  children?: ReactNode;
}

export const BaseMap = ({ variant, center, zoom, children }: BaseMapProps) => {
  const MAP_STYLE = mapStyle[variant];

  return (
    <StyledMapContainer
      attributionControl={false}
      zoomControl={false}
      center={center || MAP_DEFAULT_CENTER}
      zoom={zoom || MAP_INITIAL_ZOOM}
      minZoom={MAP_MIN_ZOOM}
      maxZoom={MAP_MAX_ZOOM}
      zoomSnap={0.5}
      zoomDelta={0.5}
      wheelPxPerZoomLevel={90}
      worldCopyJump={true}
      maxBounds={[
        [90, 180],
        [90, -180],
        [-90, -180],
        [-90, 180],
      ]}
    >
      {isOpenStreetMap(MAP_STYLE) && (
        <TileLayer
          url={MAP_STYLE.styleUrl}
          attribution={`&copy; <a href="${MAP_STYLE.vendor.url}">${MAP_STYLE.vendor.name}</a>`}
        />
      )}
      {isArcGISMap(MAP_STYLE) && (
        <VectorBasemapLayer apiKey={process.env.REACT_APP_ARCGIS_MAP_KEY} name={MAP_STYLE.name} />
      )}
      <ZoomControlPlugin zoom={zoom} />
      <LoadEndNotifier zoom={zoom} />
      {children}
    </StyledMapContainer>
  );
};

const StyledMapContainer = styled(MapContainer)<{ selectionMode?: boolean }>`
  position: absolute;
  left: ${MAP_HORIZONTAL_OFFSET_CORRECTION}px;
  top: 0;
  right: ${MAP_HORIZONTAL_OFFSET_CORRECTION}px;
  bottom: 0;
  z-index: 100;
  cursor: ${({ selectionMode }) => (selectionMode ? 'crosshair' : 'grab')};
`;
