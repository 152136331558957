import { QueryClient } from '@tanstack/react-query';

const DEFAULT_STALE_TIME = 10 * 60 * 1_000;
const DEFAULT_CACHE_TIME = 15 * 60 * 1_000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: false,
      staleTime: DEFAULT_STALE_TIME,
      cacheTime: DEFAULT_CACHE_TIME,
    },
  },
});
