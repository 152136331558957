export const mathJaxConfig = {
  loader: { load: ['[tex]/html'] },
  tex: {
    inlineMath: [['$', '$']],
    displayMath: [['$$', '$$']],
  },
  hideUntilTypeset: 'first',
  options: {
    enableMenu: false,
  },
};
