import { COMPARISON_OPTION_NAME, TIMELINE_OPTION_NAME } from 'common/navigation/constants';
import { Select } from 'ui/atoms';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { useGreeneryViewOptions } from 'views/greenery/useGreeneryViewOptions';
import { useGreeneryDataProvider } from 'views/greenery/useGreeneryDataProvider';

export const SegmentationViewOptionsSelect = () => {
  const { timestamps } = useGreeneryTimestamps();

  const { mapViewOption, changeViewOption, resetViewOption } = useGreeneryViewOptions();

  const { isSentinelProvider } = useGreeneryDataProvider();

  const viewOptions = [
    {
      value: COMPARISON_OPTION_NAME,
      label: 'comparison',
      disabled: timestamps.length <= 1 || !isSentinelProvider,
    },
  ];

  const defaultOption = TIMELINE_OPTION_NAME;

  const viewOptionsSelectData = {
    options: viewOptions,
    selectedOption: mapViewOption,
    initialStateLabel: defaultOption,
    noOptionsLabel: defaultOption,
    name: 'view selection',
    minWidth: 148,
  };

  return <Select {...viewOptionsSelectData} onChange={changeViewOption} reset={resetViewOption} minWidth={150} />;
};
