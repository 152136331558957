import { useQueryClient } from '@tanstack/react-query';

import { SUBSCRIPTION_CANCEL_CLEANUP_TIMEOUT } from 'common/constants/services';
import {
  SUBSCRIPTION_CANCEL_POLLING_INTERVAL,
  SUBSCRIPTION_STATUS_QUERY_KEY,
} from 'infrastructure/subscription/constants';
import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { useUserStore } from 'infrastructure/user/userStore';

export const useCancelSubscription = (
  closeUnsubscribeModal: () => void,
  openConfirmModal: () => void,
  setErrorMessage: (message: string) => void,
) => {
  const { isWaitingForSubscriptionCancel, setIsWaitingForSubscriptionCancel } = useUserStore();

  const onMutate = () => {
    setIsWaitingForSubscriptionCancel(true);
  };

  const cleanup = () => {
    setIsWaitingForSubscriptionCancel(false);
    queryClient.invalidateQueries({ queryKey: [SUBSCRIPTION_STATUS_QUERY_KEY] });
  };

  const queryClient = useQueryClient();
  const { handleSubscriptionCancel, isSubscriptionCanceling } = subscriptionRepository.useCancel(onMutate);

  const handleUnsubscribe = () => {
    handleSubscriptionCancel(undefined, {
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: [SUBSCRIPTION_STATUS_QUERY_KEY] });

        const interval = setInterval(async () => {
          if (!isWaitingForSubscriptionCancel) {
            clearInterval(interval);
            cleanup();
            closeUnsubscribeModal();
            openConfirmModal();
          }
        }, SUBSCRIPTION_CANCEL_POLLING_INTERVAL);

        setTimeout(() => {
          clearInterval(interval);
          cleanup();
        }, SUBSCRIPTION_CANCEL_CLEANUP_TIMEOUT);
      },
      onError: (error) => {
        setErrorMessage(`Unable to finish operation: ${error}`);
        cleanup();
        closeUnsubscribeModal();
      },
    });
  };

  return { handleUnsubscribe, isSubscriptionCanceling };
};
