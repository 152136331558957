import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as SettingsGearIcon } from 'assets/svg/settingsGearIcon.svg';
import { MapControlButton } from 'ui/map/mapControlButton';
import { RangeSelector, type RangeSelectorProps } from './RangeSelector';

export const RangeSelectorModule = (props: RangeSelectorProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <Wrapper>
      <MapControlButton
        active={visible}
        icon={<SettingsGearIcon />}
        iconWidth={20}
        onClick={() => setVisible((prev) => !prev)}
        testid="range-timeline-button"
      />
      <RangeSelector {...props} visible={visible} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
