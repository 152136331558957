import { type NavigationItemConfig, type AreaType } from 'common/navigation/types';
import { useBuildExplorationNavItem } from 'views/exploration/useBuildExplorationNavItem';
import { useBuildDisplacementsNavItem } from 'views/displacements/useBuildDisplacementsNavItem';
import { useBuildGreeneryNavItem } from 'views/greenery/useBuildGreeneryNavItem';
import { useBuildExtractionNavItem } from 'views/mine/useBuildExtractionNavItem';
import { useBuildMonitoringNavItem } from 'views/monitoring/useBuildMonitoringNavItem';
import { useBuildProspectingNavItem } from 'views/prospecting/useBuildProspectingNavItem';
import { useBuildTemperatureNavItem } from 'views/temperature/useBuildTemperatureNavItem';
import { useBuildWaterNavItem } from 'views/water/useBuildWaterNavItem';

export const useGetNavigationConfigByAreaType = (type: AreaType | undefined): NavigationItemConfig[] => {
  const monitoringNavigationItem = useBuildMonitoringNavItem();
  const prospectingNavigationItem = useBuildProspectingNavItem();
  const extractionNavigationItem = useBuildExtractionNavItem();
  const mineralizationNavigationItem = useBuildExplorationNavItem();
  const waterNavigationItem = useBuildTemperatureNavItem();
  const greeneryNavigationItem = useBuildGreeneryNavItem();
  const displacementsNavigationItem = useBuildDisplacementsNavItem();
  const temperatureNavigationItem = useBuildWaterNavItem();

  const monitoringConfig = [
    monitoringNavigationItem,
    extractionNavigationItem,
    waterNavigationItem,
    greeneryNavigationItem,
    displacementsNavigationItem,
    temperatureNavigationItem,
  ];

  const prospectingConfig = [
    prospectingNavigationItem,
    mineralizationNavigationItem,
    extractionNavigationItem,
    waterNavigationItem,
    greeneryNavigationItem,
    displacementsNavigationItem,
    temperatureNavigationItem,
  ];

  switch (true) {
    case type === 'M':
      return monitoringConfig;
    case type === 'P':
      return prospectingConfig;
    default:
      return [];
  }
};
