import styled from 'styled-components';

import { progressBarLoading } from 'common/animations';

export const InfiniteProgressBar = () => (
  <Bar>
    <BarContent />
  </Bar>
);

const Bar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #00000066;
  border-radius: 5px;
  overflow: hidden;
`;

const BarContent = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: auto;
  background-color: white;
  border-radius: 5px;
  transition: transform 0.2s linear 0s;
  transform-origin: left center;
  animation: 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running ${progressBarLoading};
`;
