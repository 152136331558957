import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type BandCombosData } from 'domain/composites/types';

interface CompositesState {
  areCompositesVisible: boolean;
  toggleCompositesVisibility: () => void;
  dataOpacityDictionary: { [key: string]: number };
  setDataOpacity: (name: string, value: number) => void;
  selectedBandCombos: BandCombosData | undefined;
  setSelectedBandCombos: (bandCombos: BandCombosData) => void;
  clearSelectedBandCombos: () => void;
  areCompositesBetaVisible: boolean;
  toggleCompositesBetaVisibility: () => void;
  providersOpacityDictionary: { [name: string]: number };
  setProviderOpacity: (name: string, opacity: number) => void;
  selectedProviderName: string | null;
  setSelectedProviderName: (name: string) => void;
  compositesSelection: { [providerName: string]: string };
  setCompositesSelection: (selection: { [providerName: string]: string }) => void;
}

const initialState = {
  areCompositesVisible: true,
  dataOpacityDictionary: {},
  selectedBandCombos: undefined,
  areCompositesBetaVisible: true,
  providersOpacityDictionary: {},
  selectedProviderName: null,
  compositesSelection: {},
};

const useCompositesStoreBase = create<CompositesState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        toggleCompositesVisibility: () =>
          set(
            (state) => ({ areCompositesVisible: !state.areCompositesVisible }),
            false,
            'composites/toggleCompositesVisibility',
          ),
        setDataOpacity: (name, value) =>
          set(
            (state) => ({ dataOpacityDictionary: { ...state.dataOpacityDictionary, [name]: value } }),
            false,
            'composites/setDataOpacity',
          ),
        setSelectedBandCombos: (bandCombos) =>
          set(() => ({ selectedBandCombos: bandCombos }), false, 'composites/setSelectedBandCombos'),
        clearSelectedBandCombos: () =>
          set(() => ({ selectedBandCombos: undefined }), false, 'composites/clearSelectedBandCombos'),
        toggleCompositesBetaVisibility: () =>
          set(
            (state) => ({ areCompositesBetaVisible: !state.areCompositesBetaVisible }),
            false,
            'composites/toggleCompositesBetaVisibility',
          ),
        setProviderOpacity: (name, opacity) =>
          set(
            (state) => ({ providersOpacityDictionary: { ...state.providersOpacityDictionary, [name]: opacity } }),
            false,
            'composites/setProviderOpacity',
          ),
        setSelectedProviderName: (name) =>
          set(() => ({ selectedProviderName: name }), false, 'composites/setSelectedProviderName'),
        setCompositesSelection: (selection) =>
          set(
            (state) => ({
              compositesSelection: {
                ...state.compositesSelection,
                ...selection,
              },
            }),
            false,
            'composites/setCompositesSelection',
          ),
      }),
      { name: 'RSOM_compositesStore', storage: createJSONStorage(() => localStorage) },
    ),
    { name: 'compositesStore' },
  ),
);

export const useCompositesStore = createSelectors(useCompositesStoreBase);
