import styled from 'styled-components';

export interface SectionHeadProps {
  header: string;
  description?: string;
}

export const SectionHead = ({ header, description }: SectionHeadProps) => (
  <StyledSectionHead>
    <StyledHeader>{header}</StyledHeader>
    <StyledDescription>{description}</StyledDescription>
  </StyledSectionHead>
);

const StyledSectionHead = styled.div`
  width: 100%;
`;

const StyledHeader = styled.h4`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.color.primary};
  margin-bottom: 8px;
`;

const StyledDescription = styled.h5`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.color.silverDark};
`;

SectionHead.displayName = 'SectionHead';
