import { useEffect } from 'react';

import { CreatorOptionsColumn } from 'ui/creator/creatorOptionsColumn';
import { ContentInteractive, OptionButton } from 'ui/creator/optionButton';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { AREA_REQUEST_DATA_SUPPLIERS, AREA_REQUEST_STANDARD_SUBSCRIPTION_NAME } from 'domain/areas/configs';

interface SuppliersColumnProps {
  setSuppliersColumnValidity: (valid: boolean) => void;
}

export const SuppliersColumn = ({ setSuppliersColumnValidity }: SuppliersColumnProps) => {
  const suppliersSelection = useAreaRequestStore.use.suppliersSelection();
  const setSuppliersSelection = useAreaRequestStore.use.setSuppliersSelection();
  const selectedSubscription = useAreaRequestStore.use.selectedSubscription();
  const selectionStatus = useAreaRequestStore.use.selectionStatus();

  const suppliersOptions = AREA_REQUEST_DATA_SUPPLIERS.map(
    ({ id, name, label, extended_feature, disabled_permanently }) => {
      const disabledByStandard =
        extended_feature && selectedSubscription?.name === AREA_REQUEST_STANDARD_SUBSCRIPTION_NAME;

      return {
        id,
        name,
        label,
        selected: !!suppliersSelection[name],
        info: disabledByStandard ? 'Not available in Standard Exploration Subscription' : undefined,
        disabled: disabled_permanently || disabledByStandard,
        disabledByStandard,
        onClick: () => setSuppliersSelection(name),
      };
    },
  );

  const validSelectedSuppliers = Object.entries(suppliersSelection)
    .filter((s) => !!s[1])
    .filter((s) => !suppliersOptions.find(({ name }) => name === s[0])?.disabledByStandard);

  useEffect(() => {
    setSuppliersColumnValidity(!!validSelectedSuppliers.length);
  }, [validSelectedSuppliers, setSuppliersColumnValidity]);

  useEffect(() => {
    const selectedDisabledOption = suppliersOptions.find((item) => item.disabledByStandard && item.selected);

    if (selectedDisabledOption) {
      setSuppliersSelection(selectedDisabledOption.name, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscription?.name, selectionStatus]);

  return (
    <CreatorOptionsColumn header="Select data providers" width={190}>
      {suppliersOptions.map(({ id, label, selected, disabled, info, onClick }) => (
        <OptionButton key={id} {...{ label, selected, disabled, onClick }} variant="CHECKBOX">
          <ContentInteractive {...{ label, info, warning: false, selected }} />
        </OptionButton>
      ))}
    </CreatorOptionsColumn>
  );
};
