import { LabeledCheckbox } from 'ui';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useLayerManagerStore } from '../../layerManagerStore';
import { AuxiliaryControlsWrapper, IndentedContentWraper } from '../../styled';
import { DraggableLayerManagerSection } from '../../draggableLayerManager';
import { useInitializeLayersOrderRecord } from '../../draggableLayerManager/useInitializeLayersOrderRecord';
import { type LAYERS_KEYS } from '../../draggableLayerManager/draggableLayersKeys';
import { OpacitySlider } from '../../OpacitySlider';

interface HillshadeItemProps {
  id: LAYERS_KEYS;
  areaName: string;
}

export const HillshadeItem = ({ id, areaName }: HillshadeItemProps) => {
  const { layersList } = areasRepository.useFetchTopographyLayers(areaName);
  const isHillshadeVisible = useLayerManagerStore.use.isHillshadeVisible();
  const toggleHillshadeVisibility = useLayerManagerStore.use.toggleHillshadeVisibility();
  const hillshadeOpacity = useLayerManagerStore.use.hillshadeOpacity();
  const setHillshadeOpacity = useLayerManagerStore.use.setHillshadeOpacity();

  const onHillshadeOpacityChange = (value: number | number[]) => {
    setHillshadeOpacity(Array.isArray(value) ? value[0] : value);
  };

  useInitializeLayersOrderRecord(id);

  if (!layersList.length) {
    return null;
  }

  return (
    <DraggableLayerManagerSection id={id}>
      <IndentedContentWraper>
        <LabeledCheckbox variant="WIDE" onChange={toggleHillshadeVisibility} checked={isHillshadeVisible}>
          hillshade
        </LabeledCheckbox>
        <AuxiliaryControlsWrapper disabled={!isHillshadeVisible}>
          <OpacitySlider
            ariaLabel="hillshade opacity slider"
            opacity={hillshadeOpacity}
            setOpacity={onHillshadeOpacityChange}
          />
        </AuxiliaryControlsWrapper>
      </IndentedContentWraper>
    </DraggableLayerManagerSection>
  );
};
