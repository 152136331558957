import { useState } from 'react';

import { formatDate } from 'common/utils/datetime';
import { capitalizeAllFirstLetters, replaceSquareMetersWithHectares } from 'common/utils';
import { SURFACE_PRECISION_HA } from 'common/constants';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdfExtendedContent } from 'views/reports/external/pdfGenerator/extended/ReportPdfExtendedContent';
import { ReportPdfBarChartGenerate } from 'views/reports/external/pdfGenerator/extended/ReportPdfBarChartGenerate';
import { useGetExtractionResultsUseCase } from 'views/mine/useGetExtractionResultsUseCase';
import {
  type ExternalReportExtendedTableData,
  type ExternalReportHeaderCommon,
  type ReportsDatasetExtended,
} from 'domain/reports/types';
import {
  EXTERNAL_REPORT_EXTRACTION_SPECIAL_CLASS_IDS,
  EXTERNAL_REPORT_SURFACE_AREA_UNIT,
} from 'domain/reports/constants';
import { SEGMENTATION_OBJECTS_OPTIONS } from 'domain/extraction/configs';
import { getExtendedTableData } from 'domain/reports/helpers';
import { isExternalReportExtendedTableDataValid } from 'domain/reports/typeguards';

interface ExtractionDoubleDateReportsExternalDataProps {
  reportInfo: ExternalReportHeaderCommon;
  firstDataset: ReportsDatasetExtended;
  secondDataset: ReportsDatasetExtended;
}

export const ExtractionDoubleDateReportsExternalData = ({
  reportInfo,
  firstDataset,
  secondDataset,
}: ExtractionDoubleDateReportsExternalDataProps) => {
  const [chartSrc, setChartSrc] = useState<string | null>(null);

  const { mineResults } = useGetExtractionResultsUseCase();

  const firstDate = formatDate(firstDataset.timestamp);
  const secondDate = formatDate(secondDataset.timestamp);

  const data = secondDataset.data
    .filter(({ checked }) => checked)
    .map(({ id }): ExternalReportExtendedTableData | undefined => {
      const option = SEGMENTATION_OBJECTS_OPTIONS.find((option) => option.id === id);

      if (!option) {
        return undefined;
      }

      const firstDateSurfaceInM2 = mineResults
        .filter((result) => result.img_date === firstDataset.timestamp)
        .find((item) => item.mine_object.id === id)?.area;
      const first = replaceSquareMetersWithHectares(firstDateSurfaceInM2);

      const secondDateSurfaceM2 = mineResults
        .filter((result) => result.img_date === secondDataset.timestamp)
        .find((item) => item.mine_object.id === id)?.area;
      const second = replaceSquareMetersWithHectares(secondDateSurfaceM2);

      const { firstValue, secondValue, difference, trend } = getExtendedTableData(first, second, SURFACE_PRECISION_HA);

      return {
        id,
        label: capitalizeAllFirstLetters(option.label_name),
        color: option.secondaryColor,
        firstValue,
        secondValue,
        difference,
        trend,
        special: EXTERNAL_REPORT_EXTRACTION_SPECIAL_CLASS_IDS.some((item) => item === id),
      };
    })
    .filter((item) => item);

  const validData = isExternalReportExtendedTableDataValid(data) ? data : [];
  const sortedValidData = validData.sort(({ special }) => (special ? +special - 0.5 : 0));

  const header = {
    ...reportInfo,
    reportType: 'Comparison Report',
    reportFunctionality: 'Modified Terrain Analysis',
    dates: {
      first: firstDate,
      second: secondDate,
    },
  };

  const screenshots = {
    first: {
      src: firstDataset.screenshotScr,
      label: `Date 1: ${firstDate}`,
    },
    second: {
      src: secondDataset.screenshotScr,
      label: `Date 2: ${secondDate}`,
    },
  };

  const table = {
    tableHeader: 'Modified Terrain Comparison Per Class',
    dataHeader: 'Class Name',
    unit: EXTERNAL_REPORT_SURFACE_AREA_UNIT,
    firstDate,
    secondDate,
    data: sortedValidData,
  };

  return (
    <>
      <ReportPdfBarChartGenerate
        data={sortedValidData}
        header="Modified Terrain Comparison Per Class Date 1 vs. Date 2"
        unit={EXTERNAL_REPORT_SURFACE_AREA_UNIT}
        setChartSrc={setChartSrc}
      />
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} />
          <ReportPdfExtendedContent screenshots={screenshots} table={table} chartSrc={chartSrc} />
        </>
      </ReportPdf>
    </>
  );
};
