import { useAoiNumber } from 'common/navigation/hooks';
import { useTimelineService } from 'components/Timeline/timelineService';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useWaterIndicators } from './indicators/useWaterIndicators';

export const useWaterQualityResults = () => {
  const areaId = useAoiNumber();

  const { timestamp } = useTimelineService();

  const { selectedIndicator } = useWaterIndicators();
  const indicatorId = selectedIndicator ? Number(selectedIndicator.id) : undefined;

  const { waterQualityResults, waterQualityResultsLoading } = waterRepository.useFetchWaterQualityResults(
    areaId,
    timestamp,
    indicatorId,
  );
  const waterQualityResultIds = waterQualityResults.map(({ id }) => id);

  const { waterQualityCogsResponse, waterQualityCogsLoading } =
    waterRepository.useFetchWaterQualityCogsResponse(waterQualityResultIds);

  const { waterQualityMockedResults, waterQualityMockedResultsLoading } =
    waterRepository.useFetchWaterQualityResultsMocked(areaId, timestamp, indicatorId);
  const waterQualityMockedResultCogs = waterQualityMockedResults.map(({ id, indicator_file_path }) => ({
    id,
    url: indicator_file_path,
  }));

  const waterQualityMockedCogsResponse = waterQualityMockedResultCogs.map((item) => ({
    data: { data: { url: item.url, id: item.id } },
  }));

  const waterQualityMergedResults = [...waterQualityResults, ...waterQualityMockedResults];

  const waterQualityMergedCogsResponse = [...waterQualityCogsResponse, ...waterQualityMockedCogsResponse];

  return {
    waterQualityMergedResults,
    waterQualityMergedCogsResponse,
    waterQualityResultsLoading:
      waterQualityResultsLoading || waterQualityMockedResultsLoading || waterQualityCogsLoading,
  };
};
