import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { calculationsAPI } from 'infrastructure/calculations/calculationsApiAdapter';
import {
  CALCULATIONS_PAYLOAD_LOCAL_STORAGE_KEY,
  CALCULATIONS_STATUS_QUERY_KEY,
} from 'infrastructure/calculations/constants';
import { localStorageService } from 'services/localStorageService';
import { type CalculationsPayload, type CalculationsPayloadPartial } from 'domain/calculations/types';

export const calculationsRepository = {
  useTriggerCalculations: () => {
    const [isCalculationLoading, setIsCalculationLoading] = useState(false);
    const [calculationError, setCalculationError] = useState<Error | null>(null);

    const triggerSamCalculations = async (payload: CalculationsPayload) => {
      setIsCalculationLoading(true);
      try {
        const calculationResponse = await calculationsAPI.postRequest({
          aoi_id: payload.aoiId,
          deposit: payload.deposit,
          quarter: payload.quarter,
          year: payload.year,
          subscription_name: payload.subscriptionName,
          providers: payload.providers,
        });

        return calculationResponse.data.id;
      } catch (error) {
        error instanceof Error ? setCalculationError(error) : console.log('Unknown error:', error);
      } finally {
        setIsCalculationLoading(false);
      }
    };

    return { triggerSamCalculations, isCalculationLoading, calculationError };
  },

  useGetStatus: (requestId: string) =>
    useQuery([CALCULATIONS_STATUS_QUERY_KEY, { requestId }], () => calculationsAPI.getStatus(requestId)),

  useCalculationsPayload: () => {
    const calculationsPayloadPartial = localStorageService.getValue(CALCULATIONS_PAYLOAD_LOCAL_STORAGE_KEY);

    const setCalculationsPayloadPartial = (payload: CalculationsPayloadPartial) => {
      localStorageService.setValue(CALCULATIONS_PAYLOAD_LOCAL_STORAGE_KEY, payload);
    };

    const clearCalculationsPayloadPartial = () => {
      localStorageService.removeValue(CALCULATIONS_PAYLOAD_LOCAL_STORAGE_KEY);
    };

    return { calculationsPayloadPartial, setCalculationsPayloadPartial, clearCalculationsPayloadPartial };
  },
};
