import { type ComponentType } from 'react';

import { useGetAoiName } from 'common/hooks/getAoiName';
import { Loading } from 'ui/molecules/loading';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { WithMoistureCogStats } from 'views/water/moisture/WithMoistureCogStats';
import { type MoistureIndicator, type MoistureCommonProps, type MoistureResult } from 'domain/water/types';

interface Props<T> {
  Component: ComponentType<T & MoistureCommonProps>;
  props?: T;
  timestamp: string;
  indicator: MoistureIndicator;
  enableLoading?: boolean;
}

export const WithMoistureResult = <T,>({ Component, props, timestamp, indicator, enableLoading }: Props<T>) => {
  const { areaName } = useGetAoiName();

  const { results, isLoading } = waterRepository.useFetchMoistureResults(areaName);

  const result: MoistureResult | undefined = results.filter(
    ({ img_date, indicator_id }) => img_date === timestamp && indicator_id === indicator.id,
  )[0];

  if (enableLoading && isLoading) {
    return <Loading />;
  }

  if (!result) {
    return null;
  }

  const moistureLayerName = `${areaName}_${result.id}_moisture_${indicator.indicator_name}`;

  return (
    <WithMoistureCogStats
      Component={Component}
      props={props}
      url={result.url}
      layerName={moistureLayerName}
      indicator={indicator}
      enableLoading={enableLoading}
    />
  );
};
