import styled from 'styled-components';

import { type DescriptionData } from 'domain/composites/types';

interface TooltipContentProps {
  data: DescriptionData;
}

export const TooltipContent = ({ data }: TooltipContentProps) => {
  return (
    <Wrapper>
      {data.title}:
      <OptionsList>
        {data.options.map(({ id, label, config }) => (
          <li key={id}>
            {label}
            <br></br>
            {JSON.stringify(config).replaceAll(',', '; ').replaceAll('"', '')}
          </li>
        ))}
      </OptionsList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 312px;
  padding: 4px;
`;

const OptionsList = styled.ul`
  margin-top: 12px;
  padding-left: 20px;
`;
