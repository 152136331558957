import * as map from 'components/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useLayerIndex } from 'components/layerManager/draggableLayerManager/useLayerIndex';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayerManager/draggableLayersKeys';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';

interface CenteringExtendProps {
  areaId: number;
  enabled: boolean;
}

export const CenteringExtent = ({ areaId, enabled }: CenteringExtendProps) => {
  const isExtentVisible = useLayerManagerStore.use.isExtentVisible();

  const { extentPolygon } = useGetExtentPolygon();

  const { visible, index } = useLayerIndex(LAYERS_KEYS.EXTENT);

  return (
    <>
      {extentPolygon && (
        <map.Layer visible={true} name={'mapCenteringFrame'} zIndex={100}>
          <map.LeafletMapViewCenteringFrame areaId={areaId} latLngs={extentPolygon} />
        </map.Layer>
      )}
      <map.Layer visible={isExtentVisible && enabled && visible} name={'extentPolygon'} zIndex={index}>
        {extentPolygon && (
          <map.PolylineView
            positions={extentPolygon}
            pathOptions={{
              color: 'white',
              opacity: 0.75,
            }}
            pane={'extentPolygon'}
          />
        )}
      </map.Layer>
    </>
  );
};
