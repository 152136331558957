import { Fragment } from 'react';

import { COMPARISON_OPTION_NAME, HOTSPOTS_OPTION_NAME } from 'common/navigation/constants';
import { useQueryParameter } from 'common/navigation/hooks';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import * as map from 'components/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { ShareModal } from 'components/downloadModal/legacyShareModal';
import { MultipleLayersHoverToValuesTitilerTooltip } from 'components/map/tooltip/MultipleLayersHoverToValuesTitilerTooltip';
import { Loading } from 'ui/molecules/loading';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS } from 'domain/greenery/configs';
import { VegetationMapContentHotspots } from './VegetationMapContentHotspots';
import { VegetationMapContentComparator } from './VegetationMapContentComparator';
import { useGetGreeneryCogUrlsWithIdsUseCase } from './getGreeneryCogUrlsWithIdsUseCase';
import { VegetationMapLayer } from './VegetationMapLayer';
import { useGreeneryLayersVisibility } from '../../useGreeneryLayersVisibility';
import { useVegetationIndicators } from '../indicators/useVegetationIndicators';
import { vegetationDataService } from '../vegetationDataService';

export const VegetationMapContent = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isHotspotViewActive: boolean = viewQuery === HOTSPOTS_OPTION_NAME;
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const { classesVisibility } = useGreeneryLayersVisibility();
  const takeScreenshotFlag = useReportsStore.use.takeScreenshotFlag();

  const { selectedIndicator } = useVegetationIndicators();
  const { greeneryObjects, greeneryObjectsLoading } = greeneryRepository.useFetchGreeneryObjects();
  const { greeneryResults, greeneryResultsLoading } = vegetationDataService.useFetchVegetationResults();

  const filteredResults = greeneryResults.filter(
    ({ greenery_object_id }) =>
      !VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS.includes(
        greeneryObjects.find(({ id }) => id === greenery_object_id)?.name || '',
      ),
  );

  const { urlsWithBounds } = useGetGreeneryCogUrlsWithIdsUseCase(filteredResults);

  const shareModalLinks = urlsWithBounds
    .filter((item) => {
      const objectName = greeneryObjects.find(({ id }) => id === item.id)?.name;

      return objectName && classesVisibility[objectName];
    })
    .map(({ url }) => url);

  const remappedUrlsWithBounds = urlsWithBounds
    .map((set) => ({
      id: set.id,
      url: getAzureBlobStorageUrl(set.url),
      bounds: set.bounds,
      label: greeneryObjects.find(({ id }) => id === set.id)?.label_name || 'N/A',
      name: greeneryObjects.find(({ id }) => id === set.id)?.name || 'N/A',
    }))
    .filter(({ name }) => Object.keys(classesVisibility).includes(name));

  if (greeneryObjectsLoading || greeneryResultsLoading) {
    return <Loading />;
  }

  if (!selectedIndicator) {
    return null;
  }

  if (isComparisonActive) {
    return <VegetationMapContentComparator range={[selectedIndicator.min_value, selectedIndicator.max_value]} />;
  }

  if (isHotspotViewActive) {
    return <VegetationMapContentHotspots greeneryIndicator={selectedIndicator.id} />;
  }

  return (
    <>
      <map.Layer visible={isDataLayerVisible} name={'dataLayer'} zIndex={490}>
        {urlsWithBounds.map(({ id, url, bounds }) => (
          <Fragment key={url}>
            {url && bounds && (
              <VegetationMapLayer
                resultId={id}
                vegetationCogUrl={getAzureBlobStorageUrl(url)}
                layerId={id}
                greeneryObjects={greeneryObjects}
                layersVisibility={classesVisibility}
                colormap={selectedIndicator.colormap}
                range={[selectedIndicator.min_value, selectedIndicator.max_value]}
              />
            )}
          </Fragment>
        ))}
      </map.Layer>
      {!takeScreenshotFlag && <MultipleLayersHoverToValuesTitilerTooltip urlsWithBounds={remappedUrlsWithBounds} />}
      <ShareModal links={shareModalLinks} activeTab={GreeneryActiveTab.VEGETATION} />
    </>
  );
};
