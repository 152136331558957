/* eslint-disable @typescript-eslint/no-explicit-any */
import { type AxiosResponse } from 'axios';
import {
  type DataRequestAnalysisTypesApiResponse,
  type DataRequestDataTypesApiResponse,
} from 'domain/dataRequest/types';

export const areDataTypesValid = (
  data: AxiosResponse<any, any> | undefined,
): data is AxiosResponse<DataRequestDataTypesApiResponse, any> => {
  return (
    data &&
    Array.isArray(data.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.data_type === 'string' &&
        typeof result.eo_data_source === 'string' &&
        typeof result.wavelength_min === 'number' &&
        typeof result.wavelength_max === 'number' &&
        typeof result.include_tir === 'boolean' &&
        typeof result.number_of_bands === 'number' &&
        typeof result.spatial_resolution_min === 'number' &&
        typeof result.spatial_resolution_max === 'number' &&
        typeof result.extended_feature === 'boolean' &&
        typeof result.information === 'string',
    )
  );
};

export const areAnalysisTypesValid = (
  data: AxiosResponse<any, any> | undefined,
): data is AxiosResponse<DataRequestAnalysisTypesApiResponse, any> => {
  return (
    data &&
    Array.isArray(data.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.analysis_type === 'string' &&
        typeof result.extended_feature === 'boolean' &&
        typeof result.information === 'string',
    )
  );
};
