import { useParams } from 'react-router-dom';

import { ColorScale } from 'ui/colorScale';
import { addAlphaToColormapObject } from 'common/utils/color';
import { MINE_ACTIVITY_INDEX_COLORSCALE_LABELS } from 'domain/extraction/configs';
import { MINE_ACTIVITY_INDEX_COLORMAP, MINE_ACTIVITY_INDEX_OPACITY } from 'domain/extraction/colors';

export const ExtractionColorScale = () => {
  const { areaId } = useParams();

  if (areaId !== '773' && areaId !== '775' && areaId !== '776' && areaId !== '777') {
    return null;
  }

  const colorScaleColormap = addAlphaToColormapObject(MINE_ACTIVITY_INDEX_COLORMAP, MINE_ACTIVITY_INDEX_OPACITY);

  return <ColorScale colormap={colorScaleColormap} labels={MINE_ACTIVITY_INDEX_COLORSCALE_LABELS} />;
};
