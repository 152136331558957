import { Slider } from 'ui';
import { useSliderValueDebounce } from './useSliderValueDebounce';
import { LabeledSliderWrapper } from './styled';

interface OpacitySliderProps {
  ariaLabel: string;
  opacity: number;
  setOpacity: (value: number | number[]) => void;
}

export const OpacitySlider = ({ ariaLabel, opacity, setOpacity }: OpacitySliderProps) => {
  const { value, setValue } = useSliderValueDebounce(opacity, setOpacity);

  return (
    <LabeledSliderWrapper>
      opacity
      <Slider
        ariaLabel={ariaLabel}
        marks={{
          '0': '',
          '1': '',
        }}
        value={value}
        onChange={setValue}
        defaultValue={1}
        min={0}
        max={1}
        step={0.01}
      />
    </LabeledSliderWrapper>
  );
};
