import { useEffect, useState } from 'react';

import { DateSelect } from 'ui';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { Container } from 'ui/atoms/container';
import { CreatorDataColumn, type CreatorDataColumnState } from 'ui/creator/creatorDataColumn/CreatorDataColumn';
import { isCreatorDataColumnDataValid } from 'ui/creator/typeguards';
import { type CreatorDataColumnData } from 'ui/creator/types';
import { ReportsCreatorDataColumnDatesLabel } from 'views/reports/creator/components/ReportsCreatorDataColumnDatesLabel';
import { ReportsCreatorMapOptions } from 'views/reports/creator/components/ReportsCreatorMapOptions';
import { useExtractionObjectTypesService } from 'views/mine/extractionLayersService';
import { useExtractionLayersVisibility } from 'views/mine/useExtractionLayersVisibility';
import { useGetExtractionPolygonsUseCase } from 'views/mine/useGetExtractionPolygonsUseCase';
import { useTimelineService } from 'components/Timeline/timelineService';
import { SEGMENTATION_OBJECTS_OPTIONS } from 'domain/extraction/configs';

export const ExtractionDoubleDateReportsCreatorContent = ({ timestamps }: { timestamps: string[] }) => {
  const { timestamp, changeTimestamp } = useTimelineService();

  const {
    classesVisibility: layersVisibility,
    toggleMultipleQueries: toggleLayerVisibility,
    updateClassesVisibility: updateLayersVisibility,
  } = useExtractionLayersVisibility();

  const { segmentationLegendOptions } = useExtractionObjectTypesService();
  const { minePolygonsLoading: isLoading } = useGetExtractionPolygonsUseCase();

  const firstDateReportsExtractionDataset = useReportsStore.use.firstDateReportsExtractionDataset();
  const setFirstDateReportsExtractionDataset = useReportsStore.use.setFirstDateReportsExtractionDataset();
  const setSecondDateReportsExtractionDataset = useReportsStore.use.setSecondDateReportsExtractionDataset();

  const reportScreenshotSrc = useReportsStore.use.reportScreenshotSrc();
  const setTakeScreenshotFlag = useReportsStore.use.setTakeScreenshotFlag();

  const [columnState, setColumnState] = useState<CreatorDataColumnState>('DEFAULT');

  const handleConfirmClick = () => {
    setColumnState('SAVING');
    setTakeScreenshotFlag(true);
  };

  const currentDateDataset = segmentationLegendOptions.map(({ id, name, label_name }) => {
    const otherDateOption = firstDateReportsExtractionDataset?.data.find((item) => item.id === id);
    const label = firstDateReportsExtractionDataset && !otherDateOption ? `${label_name} (Date 2 only)` : label_name;
    const checked = otherDateOption ? otherDateOption.checked : !!layersVisibility[name];

    return {
      id,
      label,
      checked,
      disabled: !!otherDateOption,
      onChange: () => toggleLayerVisibility(name),
    };
  });

  const firstDateDatasetOnly = firstDateReportsExtractionDataset
    ? firstDateReportsExtractionDataset.data
        .filter(({ id }) => !currentDateDataset.some((item) => item.id === id))
        .map(({ id, checked }): CreatorDataColumnData | undefined => {
          const option = SEGMENTATION_OBJECTS_OPTIONS.find((option) => option.id === id);

          if (!option) {
            return undefined;
          }

          return {
            id,
            label: `${option.label_name} (Date 1 Only)`,
            checked,
            disabled: true,
            onChange: () => toggleLayerVisibility(option.name),
          };
        })
        .filter((item) => item)
    : [];

  const validFirstDateDatasetOnly = isCreatorDataColumnDataValid(firstDateDatasetOnly) ? firstDateDatasetOnly : [];
  const data = [...currentDateDataset, ...validFirstDateDatasetOnly];

  const isConfirmDisabled = !data.some(({ checked }) => checked) || isLoading;

  useEffect(() => {
    if (reportScreenshotSrc && columnState === 'SAVING' && timestamp) {
      const reportsDataset = {
        timestamp,
        data,
        screenshotScr: reportScreenshotSrc,
      };

      firstDateReportsExtractionDataset
        ? setSecondDateReportsExtractionDataset(reportsDataset)
        : setFirstDateReportsExtractionDataset(reportsDataset);

      setColumnState('DEFAULT');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportScreenshotSrc]);

  useEffect(() => {
    if (firstDateReportsExtractionDataset) {
      const layersStateUpdate = firstDateReportsExtractionDataset.data
        .map(({ id, checked }) => {
          const option = segmentationLegendOptions.find((option) => option.id === id);

          return (
            option && {
              [option.name]: checked,
            }
          );
        })
        .filter((item) => item !== undefined);

      layersStateUpdate.length && updateLayersVisibility(Object.assign({}, ...layersStateUpdate));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDateReportsExtractionDataset]);

  return (
    <CreatorDataColumn
      data={data}
      state={columnState}
      isLoading={isLoading}
      isConfirmDisabled={isConfirmDisabled}
      handleConfirmClick={handleConfirmClick}
      topContent={
        <>
          <ReportsCreatorDataColumnDatesLabel no={firstDateReportsExtractionDataset ? 2 : 1} />
          <DateSelect
            {...{ timestamps, timestamp, changeTimestamp }}
            forbiddenTimestamp={firstDateReportsExtractionDataset?.timestamp}
          />
        </>
      }
    >
      <Container mt={15}>
        <ReportsCreatorMapOptions />
      </Container>
    </CreatorDataColumn>
  );
};
