import { ReactComponent as ThermometerIcon } from 'assets/svg/thermometer-icon.svg';
import { AreaEnvironments } from 'common/navigation/constants';
import { useBuildNavigationItem } from 'views/areas/areaDetails/useBuildNavigationItem';
import { TEMPERATURE_VIEW_CONFIG } from 'domain/temperature/configs';

export const useBuildTemperatureNavItem = () => {
  return useBuildNavigationItem({
    config: TEMPERATURE_VIEW_CONFIG,
    label: 'temperature',
    icon: <ThermometerIcon />,
    iconSize: 18,
    type: AreaEnvironments.TEMPERATURE,
  });
};
