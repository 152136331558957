import { useParams } from 'react-router-dom';

import { ColorScale } from 'ui/colorScale';
import { addAlphaToColormapObject } from 'common/utils/color';
import { MULTILAYER_SEGMENTATION_COLORMAP, BIOMASS_COLORMAP } from 'domain/greenery/colors';
import {
  BIOMASS_COLORSCALE_LABELS,
  BIOMASS_COLORSCALE_UNIT,
  BIOMASS_OPACITY,
  SEGMENTATION_COMPARISON_COLORSCALE_RANGE_NAMES,
} from 'domain/greenery/configs';

interface SegmentationColorScaleProps {
  isComparison: boolean;
}

export const SegmentationColorScale = ({ isComparison }: SegmentationColorScaleProps) => {
  const { areaId } = useParams();

  const colorScaleColormap = addAlphaToColormapObject(BIOMASS_COLORMAP, BIOMASS_OPACITY);

  return (
    <>
      {isComparison ? (
        <ColorScale
          colormap={MULTILAYER_SEGMENTATION_COLORMAP}
          labels={SEGMENTATION_COMPARISON_COLORSCALE_RANGE_NAMES}
          segmented
          divided
        />
      ) : areaId === '522' ? (
        <ColorScale colormap={colorScaleColormap} labels={BIOMASS_COLORSCALE_LABELS} unit={BIOMASS_COLORSCALE_UNIT} />
      ) : null}
    </>
  );
};
