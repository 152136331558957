import { useQueryParameter, useQueryMultipleToggle } from 'common/navigation/hooks';
import { FILTERS_STATUS_QUERY_PARAM_NAME } from 'common/navigation/queryParams';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { type TagData } from 'ui/molecules/tagList/TagList';
import { FILTERS_AREA_STATUS_SET } from 'domain/areas/configs';
import { isAreaStatusValid } from 'domain/areas/typeguards';
import { type AreaStatus } from 'domain/areas/types';

export const useGetAreasStatusSet = (): TagData[] => {
  const { areasList } = areasRepository.useFetchAreasOfInterestList();

  const statusQuery = useQueryParameter(FILTERS_STATUS_QUERY_PARAM_NAME);
  const { toggleMultipleQueries } = useQueryMultipleToggle(FILTERS_STATUS_QUERY_PARAM_NAME);

  const statuses = areasList
    .map(({ status }) => status)
    .filter((status): status is AreaStatus => isAreaStatusValid(status));

  const uniqueStatuses = Array.from(new Set(statuses));

  const statusSet = uniqueStatuses.map((status) => {
    const { id, variant, text } = FILTERS_AREA_STATUS_SET[status];

    return {
      id,
      variant,
      text,
      checked: !statusQuery?.includes(status),
      onClick: () => toggleMultipleQueries(status),
    };
  });

  return statusSet;
};
