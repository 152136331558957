import { useGetInfrastructureLayersItem } from './infrastructure/useGetInfrastructureLayersItem';
import { useGetTopographyLayersItem } from './topography/useGetTopographyLayersItem';
import { useGetExtentLayerItem } from './extent/useGetExtentLayerItem';
import { useGetSatelliteLayerItem } from './satellite/useGetSatelliteLayerItem';
import { useGetBasemapLayerItem } from './basemap/useGetBasemapLayerItem';

export const useCommonSections = () => {
  const extentLayersItem = useGetExtentLayerItem();
  const infrastructureLayersItem = useGetInfrastructureLayersItem();
  const topographyLayersItem = useGetTopographyLayersItem();
  const satelliteLayersItem = useGetSatelliteLayerItem();
  const basemapLayersItem = useGetBasemapLayerItem();

  const commonSections = [
    ...extentLayersItem,
    ...infrastructureLayersItem,
    ...topographyLayersItem,
    ...satelliteLayersItem,
    ...basemapLayersItem,
  ];

  return { commonSections };
};
