import { useEffect } from 'react';

import { useQueryParameter } from 'common/navigation/hooks';
import { COHERENCE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { usePointHistory } from 'components/graph/usePointHistory';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { type DisplacementsResult } from 'domain/displacements/types';

export const useSetInitialKmzUrl = () => {
  const { clearPointHistoryUseCase } = usePointHistory();
  const sortedUrls = useDisplacementsStore.use.kmzUrls();
  const queryCoherence = useQueryParameter(COHERENCE_QUERY_PARAMETER);
  const setDisplacementsKmzId = useDisplacementsStore.use.setKmzId();
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();

  useEffect(() => {
    if (sortedUrls) {
      const kmzUrlFromQueryCoherence = (queryCoherence &&
        sortedUrls.find(({ coherence }) => +queryCoherence === coherence)) as DisplacementsResult | undefined;

      const kmzId = kmzUrlFromQueryCoherence?.id || sortedUrls[sortedUrls.length - 1]?.id;

      if (kmzId) {
        setDisplacementsKmzId(kmzId);
        clearPointHistoryUseCase();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedUrls, isDataLayerVisible]);
};
