import { type ReactNode } from 'react';

import { useShareModalStore } from 'components/downloadModal/legacyShareModal';
import { BaseModal } from 'ui/modal';

interface ShareModalProps {
  children: ReactNode;
  maxWidth?: number;
}

export const ShareModalBody = ({ children, maxWidth }: ShareModalProps) => {
  const isModalOpen = useShareModalStore.use.isShareModalOpen();
  const closeModal = useShareModalStore.use.closeShareModal();

  return (
    <BaseModal isOpen={isModalOpen} closeModal={closeModal} maxWidth={maxWidth}>
      {children}
    </BaseModal>
  );
};
