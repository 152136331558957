import { buildGradientRangeColormapArray } from 'common/utils/color';
import { Loading } from 'ui/molecules/loading';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { DataLayer } from 'components/map';
import { SingleLayerClickToValueTitilerTooltip } from 'components/map/tooltip/SingleLayerClickToValueTitilerTooltip';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { type MoistureCommonProps } from 'domain/water/types';
import { useGetCogRange } from './useGetCogRange';

export const MoistureMapDataLayer = ({ indicator, layerName, min, max, sourceUrl }: MoistureCommonProps) => {
  const isVisible = useLayerManagerStore.use.isDataLayerVisible();

  const range = useGetCogRange(layerName, min, max);
  const rangeMin = range[0] + 0.0001;
  const rangeMax = indicator.max_value !== null ? indicator.max_value : range[1];

  const expression = `((b1>=${min})&(b1<${max}))*b1`;
  const colormapArray = buildGradientRangeColormapArray(indicator.colormap, rangeMin, max, rangeMin, rangeMax);

  const titilerUrl = titilerApiAdapter.buildGenericMonocolorCogUrl(getAzureBlobStorageUrl(sourceUrl), {
    expression,
    colormap: JSON.stringify(colormapArray),
  });

  const { extentLatLngBounds, isInfoLoading } = useGetExtentPolygon(sourceUrl);

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !extentLatLngBounds) {
    return null;
  }

  return (
    <>
      <DataLayer
        isVisible={isVisible}
        layerName={'moistureLayer'}
        zIndex={480}
        cogUrl={titilerUrl}
        bounds={extentLatLngBounds}
      />
      {isVisible && (
        <SingleLayerClickToValueTitilerTooltip
          url={getAzureBlobStorageUrl(sourceUrl)}
          unit={indicator.unit}
          precision={indicator.precision}
        />
      )}
    </>
  );
};
