import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import styled from 'styled-components';

import { customCanvasBackgroundColor } from 'views/reports/external/pdfGenerator/helpers';
import { type ExternalReportSimpleTableData } from 'domain/reports/types';

interface ReportPdfDonutChartGenerateProps {
  data: ExternalReportSimpleTableData[];
  header: string;
  setChartSrc: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ReportPdfDonutChartGenerate = ({ data, header, setChartSrc }: ReportPdfDonutChartGenerateProps) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (!chartRef?.current) return;

    const chart = new Chart(chartRef.current, {
      type: 'doughnut',
      data: {
        labels: data.map((item) => item.label),
        datasets: [
          {
            label: header,
            data: data.filter((item) => !item.special).map((item) => item.percentage),
            backgroundColor: data.filter((item) => !item.special).map((item) => item.color),
          },
        ],
      },
      plugins: [customCanvasBackgroundColor],
      options: {
        cutout: '73%',
        borderWidth: 0,
        plugins: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          customCanvasBackgroundColor: {
            color: 'white',
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        animation: {
          onComplete: (event) => {
            if (event.initial) {
              const blob = chart.toBase64Image('image/png', 1);
              setChartSrc(blob);
              imgRef.current && (imgRef.current.src = blob);
            }
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ChartWrapper>
      <canvas style={{ visibility: 'hidden' }} ref={chartRef} id="myChart" />
      <img ref={imgRef} width="100%" alt="genereting blob for pdf" />
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  position: absolute;
  z-index: -1;
  background-color: white;
  width: 500px;
`;
