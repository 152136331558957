import { type ComponentType } from 'react';
import { type LayerRange } from 'common/types';
import { Container } from 'ui';
import { LoadingDots } from 'ui/atoms/loadingDots';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getAzureBlobStorageUrl } from './utils';

interface Props<T> {
  Component: ComponentType<T & { layerRange: LayerRange; precision: number }>;
  props: T;
  url: string;
  precision: number;
  isFetchingEnabled?: boolean;
  isLoadingEnabled?: boolean;
}

export const WithCogBandRange = <T,>({
  Component,
  props,
  url,
  precision,
  isFetchingEnabled = true,
  isLoadingEnabled,
}: Props<T>) => {
  const { cogBandRange, isLoading } = titilerInfoService.useGetCogBandRange(
    getAzureBlobStorageUrl(url),
    isFetchingEnabled,
    precision,
  );

  if (isLoading && isLoadingEnabled) {
    return (
      <Container pt={6}>
        <LoadingDots />
      </Container>
    );
  }

  if (!cogBandRange) {
    return null;
  }

  return <Component {...props} layerRange={cogBandRange} precision={precision} />;
};
