import { type ComponentType } from 'react';

import { useTimelineService } from 'components/Timeline/timelineService';
import { useMoistureIndicators } from 'views/water/moisture/indicators/useMoistureIndicators';
import { WithMoistureResult } from 'views/water/moisture/WithMoistureResult';
import { type MoistureCommonProps } from 'domain/water/types';

interface Props<T> {
  Component: ComponentType<T & MoistureCommonProps>;
  props?: T;
  enableLoading?: boolean;
}

export const WithMoistureData = <T,>({ Component, props, enableLoading }: Props<T>) => {
  const { timestamp } = useTimelineService();
  const { selectedIndicator } = useMoistureIndicators();

  if (!timestamp || !selectedIndicator) {
    return null;
  }

  return (
    <WithMoistureResult
      Component={Component}
      props={props}
      timestamp={timestamp}
      indicator={selectedIndicator}
      enableLoading={enableLoading}
    />
  );
};
