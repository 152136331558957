import { type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';
import { type Palette } from 'ui/types';

export type LabelVariant = 'HORIZONTAL' | 'VERTICAL';

type LabelStyles = Required<Pick<CSSProperties, 'flexDirection'>>;

const styles: Palette<LabelVariant, LabelStyles> = {
  HORIZONTAL: {
    flexDirection: 'row',
  },
  VERTICAL: {
    flexDirection: 'column',
  },
};

interface LabelProps {
  content: string;
  children: ReactNode;
  variant?: LabelVariant;
  color?: string;
  fontSize?: number;
}

export const Label = ({ content, children, variant, color, fontSize }: LabelProps) => (
  <StyledLabel $variant={variant} color={color} fontSize={fontSize}>
    <LabelText>{content}</LabelText>
    {children}
  </StyledLabel>
);

interface StyledLabelProps {
  $variant?: LabelVariant;
  color?: string;
  fontSize?: number;
}

const StyledLabel = styled.label<StyledLabelProps>`
  display: inline-flex;
  flex-direction: ${({ $variant }) => styles[$variant!].flexDirection};
  align-items: ${({ $variant }) => ($variant === 'HORIZONTAL' ? 'center' : 'flex-start')};
  gap: ${({ $variant }) => ($variant === 'HORIZONTAL' ? '12px' : '0')};
  width: 100%;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 400;
  line-height: 2;
  color: ${({ color }) => color};
  cursor: pointer;
`;

const LabelText = styled.span`
  height: 16px;
  white-space: nowrap;
  margin-bottom: -8px;
`;

Label.defaultProps = {
  variant: 'HORIZONTAL' as LabelVariant,
  content: 'Label text',
  color: 'inherit',
  fontSize: 12,
  children: '',
};

Label.displayName = 'LabelElement';
