import { Container, ModeSwitcher } from 'ui';
import { AuxiliaryControlsWrapper } from 'components';
import { LayerManagerThresholdSlider } from 'components/layerManager/ThresholdSlider';
import { OpacitySlider } from 'components/layerManager/OpacitySlider';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import {
  LINEAMENT_DENSITY_ERROR_INFO_POINT_DESCRIPTION,
  LINEAMENT_DENSITY_INFO_POINT_DESCRIPTION,
  LINEAMENT_DENSITY_SLIDER_CONFIG,
} from 'domain/exploration/configs';
import { SPECTRAL_COLORMAP } from 'domain/exploration/colors';
import { LINEAMENT_DENSITY_LAYER_NAME } from 'domain/exploration/constants';
import { useLineamentsLayerManagerStore } from './lineamentsLayerManagerStore';
import { useLineamentDensity } from '../useLineamentDensity';

interface LineamentsDensityLayerManagerProps {
  areaName: string;
}

export const LineamentsDensityLayerManager = ({ areaName }: LineamentsDensityLayerManagerProps) => {
  const areLineamentsVisible = useLineamentsLayerManagerStore.use.areLineamentsVisible();
  const isLineamentsSelectionMode = useLineamentsLayerManagerStore.use.isLineamentsSelectionMode();
  const isLineamentDensityVisible = useLineamentsLayerManagerStore.use.isLineamentDensityVisible();
  const toggleLineamentDensityVisibility = useLineamentsLayerManagerStore.use.toggleLineamentDensityVisibility();
  const lineamentDensityOpacity = useLineamentsLayerManagerStore.use.lineamentDensityOpacity();
  const setLineamentDensityOpacity = useLineamentsLayerManagerStore.use.setLineamentDensityOpacity();

  const onLineamentDensityOpacityChange = (value: number | number[]) => {
    setLineamentDensityOpacity(Array.isArray(value) ? value[0] : value);
  };

  const { densityResult } = useLineamentDensity(areaName);

  return (
    <>
      <Container mv={14} height={18}>
        <ModeSwitcher
          label="density map"
          isModeOn={isLineamentDensityVisible}
          disabled={!densityResult || !areLineamentsVisible || isLineamentsSelectionMode}
          tooltipContent={
            !densityResult ? LINEAMENT_DENSITY_ERROR_INFO_POINT_DESCRIPTION : LINEAMENT_DENSITY_INFO_POINT_DESCRIPTION
          }
          toggleMode={toggleLineamentDensityVisibility}
          colormap={SPECTRAL_COLORMAP}
        />
      </Container>
      <AuxiliaryControlsWrapper
        disabled={!isLineamentDensityVisible || !densityResult || !areLineamentsVisible || isLineamentsSelectionMode}
      >
        {isLineamentDensityVisible && densityResult && (
          <Container variant="VERTICAL_STRETCH" gap={4} mb={14}>
            <Container gap={10}>
              cutoff
              <WithCogBandRange
                Component={LayerManagerThresholdSlider}
                props={{ layerName: LINEAMENT_DENSITY_LAYER_NAME, config: LINEAMENT_DENSITY_SLIDER_CONFIG }}
                url={densityResult.url}
                precision={LINEAMENT_DENSITY_SLIDER_CONFIG.precision}
                isLoadingEnabled
              />
            </Container>
            <OpacitySlider
              ariaLabel="lineaments density opacity slider"
              opacity={lineamentDensityOpacity}
              setOpacity={onLineamentDensityOpacityChange}
            />
          </Container>
        )}
      </AuxiliaryControlsWrapper>
    </>
  );
};
