import { Graph } from 'components/graph';
import { Timeline } from 'components/Timeline/Timeline';
import { useTimeline } from 'components/Timeline/useTimeline';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { useDisplacementsTimeline } from './useDisplacementsTimeline';
import { useCogFixer } from './cogFixer';

export const DisplacementsTimeline = () => {
  const { timelineData } = useDisplacementsTimeline();
  useCogFixer(timelineData);

  const {
    filteredTimestamps,
    firstDate,
    lastDate,
    rangeValue,
    selectedTimestamps,
    setRangeValue,
    isTimelineShowed,
    firstTimestamp,
    lastTimestamp,
  } = useTimeline(timelineData.timestamps, timelineData.visible);

  const GraphInfoJsx = useDisplacementsStore.use.graphInfoJsx();

  return (
    <>
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          inverted: true,
          visible: timelineData.visible,
        }}
      />
      <Graph
        isTimelineVisible={isTimelineShowed}
        dateRange={{ firstDate: firstTimestamp, lastDate: lastTimestamp }}
        label={'displacements'}
      >
        {GraphInfoJsx && GraphInfoJsx()}
      </Graph>
    </>
  );
};
