import { SIDEBAR_MAX_WIDTH, SIDEBAR_MIN_WIDTH } from 'common/constants';
import styled, { css } from 'styled-components';

interface SidebarContainerProps {
  $folded?: boolean;
  $bare?: boolean;
  isSidebarPanelOpen?: boolean;
}

export const SidebarContainer = styled.nav<SidebarContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.white};
  background: ${({ $bare, theme }) => ($bare ? 'transparent' : theme.color.codGray)};
  z-index: 1200;
  height: 100svh;
  max-width: ${({ $folded }) => ($folded ? SIDEBAR_MIN_WIDTH : SIDEBAR_MAX_WIDTH)}px;
  min-width: ${({ $folded }) => ($folded ? SIDEBAR_MIN_WIDTH : SIDEBAR_MAX_WIDTH)}px;
  transition: all ${({ theme }) => theme.transitionFunction.main};

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 2;
    background: ${({ theme }) => theme.color.codGray};
    opacity: 0;
    visibility: hidden;
    transition: all ${({ theme }) => theme.transitionFunction.main};

    ${({ isSidebarPanelOpen, $bare }) =>
      isSidebarPanelOpen &&
      !$bare &&
      css`
        opacity: 0.5;
        visibility: visible;
      `}
  }
`;
