import { useLocation } from 'react-router-dom';

import { DoubleDateSelect } from 'components';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { useTimelineService } from 'components/Timeline/timelineService';
import { DateSelect } from 'ui';
import {
  REPORTS_CREATOR_COLUMN_INSUFFICIENT_DATA_LABEL,
  REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_NO_SELECT_DATE_LABEL,
  REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_SELECT_DATE_LABEL,
  REPORTS_CREATOR_SINGLE_TYPE_COLUMN_DATE_LABEL,
} from 'domain/reports/constants';
import { GreeneryActiveTab } from 'domain/greenery/enums';

export const GreeneryReportsCreatorDates = () => {
  const { pathname } = useLocation();

  const { timestamps, areTimestampsLoading } = useGreeneryTimestamps();
  const { timestamp, secondTimestamp, changeTimestamp, changeSecondTimestamp } = useTimelineService();

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();
  const setIsReportsSecondDateSelected = useReportsStore.use.setIsReportsSecondDateSelected();

  if (areTimestampsLoading) {
    return <LoadingDots />;
  }

  if (!timestamps.length || (isCompareDatesType && timestamps.length <= 1)) {
    return REPORTS_CREATOR_COLUMN_INSUFFICIENT_DATA_LABEL;
  }

  return isCompareDatesType ? (
    pathname.includes(GreeneryActiveTab.SEGMENTATION) ? (
      <div>{REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_NO_SELECT_DATE_LABEL}</div>
    ) : pathname.includes(GreeneryActiveTab.VEGETATION) ? (
      <>
        <div>{REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_SELECT_DATE_LABEL}</div>
        <DoubleDateSelect
          {...{ timestamps, timestamp, secondTimestamp, changeTimestamp, changeSecondTimestamp }}
          handleAreBothDatesSelected={(isSelected: boolean) => setIsReportsSecondDateSelected(isSelected)}
        />
      </>
    ) : null
  ) : (
    <>
      <div>{REPORTS_CREATOR_SINGLE_TYPE_COLUMN_DATE_LABEL}</div>
      <DateSelect {...{ timestamps, timestamp, changeTimestamp }} />
    </>
  );
};
