import { type Datetime } from 'common/types/time';
import { sanitizeDate } from 'common/utils/datetime';
import { useDeleteQueryParameters, useReplaceQueryParameters } from 'common/navigation/hooks';
import { SECOND_DATE_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const useSecondTimestampChange = () => {
  const replaceQueryParameters = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeSecondTimestamp = (timestamp: Datetime): void => {
    const sanitizedTimestamp = typeof timestamp === 'string' ? sanitizeDate(timestamp) : undefined;

    setTimeout(() => {
      sanitizedTimestamp
        ? replaceQueryParameters([{ key: SECOND_DATE_QUERY_PARAMETER, value: sanitizedTimestamp }])
        : deleteQueryParameters([SECOND_DATE_QUERY_PARAMETER]);
    });
  };

  return { changeSecondTimestamp };
};
