import { useEffect } from 'react';

import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { CreatorOptionsColumn } from 'ui/creator/creatorOptionsColumn';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { ReportsCreatorOptionsColumnContent } from 'views/reports/creator/components/ReportsCreatorOptionsColumnContent';
import { SingleDateReportsCreatorContent } from 'views/reports/creator/content/SingleDateReportsCreatorContent';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { reportsQueryService } from 'views/reports/reportsQueryService';
import { useVegetationIndicators } from 'views/greenery/vegetation/indicators/useVegetationIndicators';
import { REPORTS_INDICATOR_STATS } from 'domain/reports/constants';

interface VegetationReportsCreatorContentProps {
  areaId: number;
  timestamp: string;
}

export const VegetationReportsCreatorContent = ({ areaId, timestamp }: VegetationReportsCreatorContentProps) => {
  const { timestamps } = useGreeneryTimestamps();

  const { indicatorStatsQuery, toggleIndicatorStats } = reportsQueryService.useReportsIndicatorStats();

  const { selectedIndicator, indicatorOptions, changeIndicator } = useVegetationIndicators();

  const isReportsSecondDateSelected = useReportsStore.use.isReportsSecondDateSelected();

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();

  const { segmentationAreaResults, areSegmentationAreaResultsLoading } =
    greeneryRepository.useFetchSegmentationAreaResults(areaId, timestamp);

  const {
    classesVisibility: layersVisibility,
    updateClassesVisibility: updateLayersVisibility,
    clearClassesVisibility: clearLayersVisibility,
  } = useGreeneryLayersVisibility();

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const options = indicatorOptions.map(({ value, label }) => {
    return {
      id: Number(value),
      label,
      selected: selectedIndicator?.id === value,
      onClick: () => changeIndicator(value),
    };
  });

  const handleSegmentationClassClick = (name: string) => {
    clearLayersVisibility();
    updateLayersVisibility({ [name]: true });
  };

  const segmentationClassOptions = greeneryObjects
    .filter(({ id }) => segmentationAreaResults.some(({ greenery_object_id }) => greenery_object_id === id))
    .map(({ id, name, label_name }) => ({
      id,
      label: label_name,
      selected: layersVisibility[name],
      onClick: () => handleSegmentationClassClick(name),
    }));

  const data = REPORTS_INDICATOR_STATS.map(({ id, label }) => ({
    id,
    label,
    checked: !!indicatorStatsQuery?.includes(String(id)),
    onChange: () => toggleIndicatorStats(String(id)),
  }));

  useEffect(() => {
    if (Object.keys(layersVisibility).length > 1) {
      clearLayersVisibility();
    }
  }, [layersVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isCompareDatesType ? !isReportsSecondDateSelected : !(timestamp && timestamps.includes(timestamp))) {
    return null;
  }

  const isSomeIndicatorSelected = options.some(({ selected }) => selected);
  const isSomeSegmentationClassSelected = segmentationClassOptions.some(({ selected }) => selected);

  return (
    <>
      <CreatorOptionsColumn width={240}>
        <ReportsCreatorOptionsColumnContent options={options} small />
      </CreatorOptionsColumn>
      {isSomeIndicatorSelected && (
        <CreatorOptionsColumn isLoading={areSegmentationAreaResultsLoading} width={240}>
          <ReportsCreatorOptionsColumnContent options={segmentationClassOptions} small />
        </CreatorOptionsColumn>
      )}
      {isSomeIndicatorSelected && isSomeSegmentationClassSelected && !areSegmentationAreaResultsLoading && (
        <SingleDateReportsCreatorContent data={data} />
      )}
    </>
  );
};
