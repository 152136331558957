import styled from 'styled-components';

import { Button } from 'ui';
import { useUserStore } from 'infrastructure/user/userStore';
import {
  type SubscriptionProductDTO,
  type SubscriptionRequest,
  type SubscriptionRequestAction,
} from 'domain/subscription/types';
import { type SubscriptionColorsConfig } from './types';
import { getColorsConfig } from './colorsConfig';
import { PriceTile } from './PriceTile';
import { TrialPeriodNotificationItem } from './TrialPeriodNotificationItem';

interface SubscriptionItemProps {
  subscription: SubscriptionProductDTO;
  handleSubscriptionRequest: (request: SubscriptionRequest, action: SubscriptionRequestAction) => void;
}

export const SubscriptionItem = ({ subscription, handleSubscriptionRequest }: SubscriptionItemProps) => {
  const { label, price, area_quota, info, name } = subscription;

  const colorsConfig = getColorsConfig(name);

  const features = info.split('\n').map((feature) => feature.slice(1));

  const { userPermissions } = useUserStore();

  const isTrialActive = userPermissions?.role === 2;

  const trialSubscription = isTrialActive && subscription.name === 'Standard Exploration';

  const handleButtonClick = () =>
    handleSubscriptionRequest(
      {
        name,
        trial: trialSubscription,
      },
      'CREATE',
    );

  return (
    <ItemBody $bgColor={colorsConfig.bgColor} color={colorsConfig.color}>
      <HeaderWrapper>
        <Title>{label}</Title>
        <PriceTile price={price} name={name} />
      </HeaderWrapper>
      <FeaturesList>
        {trialSubscription && <TrialPeriodNotificationItem />}
        {features.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </FeaturesList>
      <SubscriptionBottomContent>
        <PointsInfo color={colorsConfig.areaQuotaColor}>{area_quota} calculation points</PointsInfo>
        <Button
          variant={name === 'Standard Exploration' ? 'TERTIARY' : 'PRIMARY'}
          squared
          fullWidth
          onClick={handleButtonClick}
        >
          subscribe
        </Button>
      </SubscriptionBottomContent>
    </ItemBody>
  );
};

const ItemBody = styled.article<{
  $bgColor: SubscriptionColorsConfig['bgColor'];
  color: SubscriptionColorsConfig['color'];
}>`
  display: flex;
  flex-direction: column;
  font-family: 'Barlow Condensed', sans-serif;
  position: relative;
  padding: 20px 30px;
  border: 1px solid ${({ theme }) => theme.color.alto};
  background-color: ${({ theme, $bgColor }) => ($bgColor ? theme.color[$bgColor] : 'transparent')};
  color: ${({ theme, color }) => theme.color[color]};

  @media (max-width: ${({ theme }) => theme.breakpoint.xl}px) {
    padding: 46px 18px 48px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 36px 30px 42px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 36px 18px 42px;
  }

  max-width: 16.25rem;
  max-width: clamp(16.25rem, 13.6rem + 11.48vw, 19.0625rem);
  min-height: 460px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const Title = styled.h4`
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
  max-width: 160px;

  @media (max-width: ${({ theme }) => theme.breakpoint.xl}px) {
    font-size: 27px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    font-size: 24px;
    line-height: 28px;
    margin-right: 40px;
  }
`;

const FeaturesList = styled.ul`
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 20px;
  margin: 20px 0 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

interface PointsInfoProps {
  color: SubscriptionColorsConfig['color'];
}

const SubscriptionBottomContent = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PointsInfo = styled.p<PointsInfoProps>`
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: center;
  margin: 30px 0;
  color: ${({ theme, color }) => theme.color[color]};

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    font-size: 19px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.sm}px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
  }
`;
