import { type LayerObject } from 'common/types/mapData';
import { SegmentationObjectsSelect } from 'views/greenery/components/SegmentationObjectsSelect';
import { WithDailySegmentationCog } from 'views/greenery/segmentation/WithDailySegmentationCog';
import { segmentationDataService } from 'views/greenery/segmentation/segmentationDataService';

interface SegmentationGreeneryObjectsSelectProps {
  isComparisonActive: boolean;
  greeneryObjects: LayerObject[];
  timestamp: string;
}

export const SegmentationGreeneryObjectsSelect = ({
  greeneryObjects,
  isComparisonActive,
  timestamp,
}: SegmentationGreeneryObjectsSelectProps) => {
  const { resultId } = segmentationDataService.useSegmentationResult(timestamp);

  return (
    <>
      {isComparisonActive && greeneryObjects && resultId && (
        <WithDailySegmentationCog
          Component={SegmentationObjectsSelect}
          props={{ layerObjects: greeneryObjects }}
          {...{ resultId }}
        />
      )}
    </>
  );
};
