import { StyleSheet } from '@react-pdf/renderer';

export const barDiagramFontSize = 18;

export const commonStyles = StyleSheet.create({
  body: {
    padding: 40,
    fontFamily: 'Barlow',
    fontSize: 8,
    fontWeight: 400,
  },

  subheader: {
    fontWeight: 600,
    fontSize: 10,
  },

  bold: {
    fontWeight: 600,
  },

  flexGrow: {
    flexGrow: 1,
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    gap: 30,
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    gap: 34,
  },
});

export const headerStyles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    fontWeight: 600,
    paddingLeft: 55,
    position: 'relative',
    alignItems: 'center',
  },

  logo: {
    width: 37,
    position: 'absolute',
    left: 0,
  },

  labels: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },

  line: {
    height: 19,
    borderBottom: '0.5 solid black',
  },

  main__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%',
    margin: '10px 0 12px',
  },

  info__line: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },

  info__line_head: {
    width: 56,
  },
});

export const colorScaleStyles = StyleSheet.create({
  wrapper: {
    alignSelf: 'flex-end',
    width: 260,
    marginBottom: 12,
  },
  labelsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    width: '100%',
  },
  labels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
    width: '100%',
  },
  colorBar: {
    overflow: 'hidden',
    borderRadius: 4,
  },
});

export const screenshotStyles = StyleSheet.create({
  single: {
    width: '100%',
    height: 285,
    backgroundColor: '#AEAEAE',
  },

  image__wide: {
    width: '100%',
    height: 137,
    backgroundColor: '#AEAEAE',
  },

  compar_wiew_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 20,
  },

  image__date: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 10,
  },
});

export const tableStyles = StyleSheet.create({
  terrain_pie: {
    width: 250,
  },

  table: {
    flexGrow: 1,
    lineHeight: 1,
  },

  column_wrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    position: 'relative',
  },

  table__column: {
    display: 'flex',
    gap: 12,
  },

  table__color__square: {
    width: 8,
    height: 8,
    borderRadius: 2,
    top: 1,
  },

  table__value: {
    lineHeight: 1,
    height: 8,
  },

  table__value__tar: {
    alignSelf: 'flex-end',
  },

  table__bottom_borders_wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
  },

  table__bottom_border: {
    borderBottom: '0.5 solid #C7C7C7',
    width: '100%',
    position: 'relative',
    top: 7,
  },
});

export const diagramStyles = StyleSheet.create({
  bar_chart__wrapper: {
    position: 'relative',
  },
  bar_chart: {
    width: '100%',
  },

  donut_chart__wrapper: {
    position: 'relative',
    height: 236,
  },
  donut_chart__header: {
    position: 'absolute',
    width: 236,
    height: 236,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  donut_chart: {
    width: 236,
    height: 236,
  },
});
