import styled from 'styled-components';

import { Button } from 'ui/atoms/button';
import { ReactComponent as SuccessIcon } from 'assets/svg/mail-icon.svg';
import { ReactComponent as FailIcon } from 'assets/svg/mail-error-icon.svg';
import { type ResetPasswordRequestStatus } from 'domain/user/types';

export type ForgotPasswordResolutionType = Omit<ResetPasswordRequestStatus, 'IDLE'>;

interface ForgotPasswordResolutionProps {
  variant: ForgotPasswordResolutionType;
  onTryAgainButtonClick?: () => void;
}

export const ForgotPasswordResolution = ({ variant, onTryAgainButtonClick }: ForgotPasswordResolutionProps) => {
  return (
    <MailWrapper>
      {variant === 'SUCCESS' ? (
        <SuccessMessageWrapper>
          <SuccessIcon />
          <p>Check your mailbox!</p>
        </SuccessMessageWrapper>
      ) : (
        <>
          <MailFailIcon />
          <Button onClick={onTryAgainButtonClick} squared>
            try again
          </Button>
        </>
      )}
    </MailWrapper>
  );
};

const MailWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

const SuccessMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  p {
    margin: 0;
  }

  svg {
    width: 268px;
    max-width: 100%;
    margin-top: 30px;
  }
`;

const MailFailIcon = styled(FailIcon)`
  width: 150px;
  max-width: 100%;
  margin: 30px 0 36px 8px;

  path:nth-child(1) {
    stroke: ${({ theme }) => theme.color.lightGray};
  }

  path:nth-child(2) {
    fill: ${({ theme }) => theme.color.primary};
  }

  path:nth-child(3) {
    stroke: ${({ theme }) => theme.color.white};
  }
`;
