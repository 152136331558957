import { type ValueAndLabel } from 'common/types';
import { Select } from 'ui';
import { type BaseMapVariant } from 'ui/map/baseMap/enums';
import { useMapStyle } from 'components/map/useMapStyle';
import { createBasemapOptions, createOtionFromMapStyle } from './helpers';

export const BasemapSelector = () => {
  const { mapStyle, changeMapStyle } = useMapStyle();

  const handleBasemapChange = (option: ValueAndLabel) => {
    changeMapStyle(option.value as BaseMapVariant);
  };

  return (
    <Select
      name="basemap-dropdown"
      options={createBasemapOptions()}
      selectedOption={createOtionFromMapStyle(mapStyle)}
      onChange={handleBasemapChange}
    />
  );
};
