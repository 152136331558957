import { useEffect, useState } from 'react';

import { type ChatRole, type Message } from 'ui/chat/types';
import { type Status } from 'common/types';
import { AI_ASSISTANT_MESSAGES_SESSION_STORAGE_KEY } from 'common/constants';
import { isChatResponseValid } from 'common/typeguards';
import { useExecuteGraphqlMutation } from 'services/graphql/executeGraphqlMutation';
import { CHAT_GPT_API_PREFIX, CHAT_GPT_MUTATION_KEY } from 'services/graphql/constants';
import { QueryGptChatDocument } from 'ui/chat/assistantChatQuery';
import { useChatPromptService } from './chatPromptService';

export const useChatService = () => {
  const promptMessage = useChatPromptService();

  const [response, setResponse] = useState<Message | undefined>();
  const [messages, setMessages] = useState<Message[]>(
    (JSON.parse(sessionStorage.getItem(AI_ASSISTANT_MESSAGES_SESSION_STORAGE_KEY) as string) as Message[] | null) ?? [],
  );

  const [status, setStatus] = useState<Status>('idle');

  const promptChat = (messageContent: string) => {
    setMessages((messages) => [
      ...messages,
      {
        role: 'user',
        displayedContent: messageContent,
        content: `${promptMessage ? `${promptMessage} ` : ''}${messageContent}`,
      },
    ]);
  };

  const { data, mutate, error } = useExecuteGraphqlMutation(
    CHAT_GPT_API_PREFIX,
    CHAT_GPT_MUTATION_KEY,
    {},
    QueryGptChatDocument,
    0,
    {
      messages: messages,
    },
  );

  useEffect(() => {
    sessionStorage.setItem(AI_ASSISTANT_MESSAGES_SESSION_STORAGE_KEY, JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    if (error) {
      setStatus('error');
    }
  }, [error]);

  useEffect(() => {
    if (isChatResponseValid(data?.data?.conversation)) {
      const payload = data?.data.conversation.choices[0].message;
      setResponse({ role: payload.role as ChatRole, content: payload.content, displayedContent: payload.content });
      setStatus('success');
    }
  }, [data]);

  const sendMessage = async () => {
    try {
      setStatus('loading');
      mutate();
    } catch (e) {
      if (e instanceof Error) {
        setResponse({
          role: 'system' as ChatRole,
          content: `Error: ${e.message}`,
          displayedContent: `Error: ${e.message}`,
        });
      }
      setStatus('error');
    }
  };

  useEffect(() => {
    if (messages.slice(-1)[0]?.role === 'user') {
      const timer = setTimeout(() => {
        sendMessage();
      }, 500);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length]);

  useEffect(() => {
    response && setMessages((messages) => [...messages, response]);
  }, [response]);

  return { promptChat, messages, status };
};
