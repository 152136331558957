import { type ReactNode, useState } from 'react';
import styled from 'styled-components';

import { HideShowButton } from 'ui/atoms/hideShowButton';
import { CreatorWrapper, HideShowButtonWrapper, Label } from 'ui/creator/styled';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { getReadableArea } from 'common/utils/leafletDraw';
import { type AreaSelectionStatus } from 'domain/areas/types';

interface AreaRequestCreatorWrapperProps {
  children: ReactNode;
  status: AreaSelectionStatus;
}

export const AreaRequestCreatorWrapper = ({ children, status }: AreaRequestCreatorWrapperProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const selectedArea = useAreaRequestStore.use.area();

  return (
    <CreatorWrapper isOpen={isOpen}>
      <Label>
        Request a New Prospecting Area.{' '}
        <span>
          {'invalidArea' in status && selectedArea !== undefined && (
            <>
              Currently Selected area size:
              <AreaStatus warning>{` ${getReadableArea(selectedArea)} ${status.invalidArea}`}</AreaStatus>
            </>
          )}
          {'message' in status && status.message && <AreaStatus>{status.message}</AreaStatus>}
        </span>
      </Label>
      {children}
      <HideShowButtonWrapper>
        <HideShowButton
          isShown={isOpen}
          showLabel="show selection"
          hideLabel="hide selection"
          onClick={() => setIsOpen((prev) => !prev)}
        />
      </HideShowButtonWrapper>
    </CreatorWrapper>
  );
};

const AreaStatus = styled.span<{ warning?: boolean }>`
  color: ${({ theme, warning }) => (warning ? theme.color.errorPink : theme.color.white)};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;
