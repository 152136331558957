export enum EXPLORATION_LAYERS_KEYS {
  LINEAMENTS = 'lineaments',
  INDICES = 'indices',
  SAM = 'sam',
  MTMF = 'mtmf',
  ML = 'ml',
  CLUSTERS = 'clusters',
  COMPOSITES = 'composites',
  COMPOSITES_BETA = 'composites_beta',
}
