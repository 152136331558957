import { type CSSProperties, type ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import { Chevron } from 'ui/atoms';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { LegendItemWraper } from 'components/layerManager/styled';
import { DragIcon } from 'components/layerManager/DragIcon';

interface FoldableSectionProps {
  id: string;
  headerContent: ReactNode;
  children: ReactNode;
  disabled?: boolean;
}

export const FoldableSection = ({ id, headerContent, children, disabled }: FoldableSectionProps) => {
  const [isFolded, setIsFolded] = useState(true);

  const onFoldedStateToggle = () => setIsFolded(!isFolded);

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, active } = useSortable({
    id,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString({
      x: 0,
      y: transform?.y || 0,
      scaleX: transform?.scaleX || 1,
      scaleY: transform?.scaleY || 1,
    }),
    transition,
  };

  return (
    <DraggableLegendItem
      data-testid={`legend-item-${id}`}
      ref={setNodeRef}
      style={active ? style : undefined}
      active={!!active}
      isDragged={active?.id === id}
    >
      <FoldableSectionHeader>
        <DragIconWrapper ref={setActivatorNodeRef} {...attributes} {...listeners}>
          <DragIcon />
        </DragIconWrapper>
        <FoldingButton onClick={onFoldedStateToggle} {...{ disabled }}>
          <Chevron width={12} marginRight={8} isFlipped={!disabled && !isFolded} disabled={disabled} />
        </FoldingButton>
        <HeaderContentWrapper disabled={disabled}>{headerContent}</HeaderContentWrapper>
      </FoldableSectionHeader>
      <FoldableSectionContent disabled={disabled || isFolded}>{children}</FoldableSectionContent>
    </DraggableLegendItem>
  );
};

const DraggableLegendItem = styled(LegendItemWraper)<{ active: boolean; isDragged: boolean }>`
  position: relative;
  border: none;

  ${({ active, isDragged }) =>
    active &&
    !isDragged &&
    css`
      opacity: 0.6;
    `};

  ${({ active }) =>
    active &&
    css`
      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        cursor: move;
        z-index: 2;
      }
    `};
`;

const FoldingButton = styled.button<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.color.white};
  border: none;
  cursor: pointer;
`;

const DragIconWrapper = styled.div`
  width: 16px;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: transparent;
  cursor: move;
  margin-left: -16px;
`;

const FoldableSectionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 0;
`;

const HeaderContentWrapper = styled.div<{ disabled?: boolean }>`
  flex-grow: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const FoldableSectionContent = styled.div<{ disabled: boolean }>`
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};
  width: 100%;
  transition: all ${({ theme }) => theme.transitionFunction.main};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.mineShaftLight};
  flex-grow: 1;
  margin-right: -16px;
`;
