import styled from 'styled-components';

import { Button, Container } from 'ui';
import { downloadAllFilesWithNamesAsZip, downloadSingleFile, getFullFileName } from './helpers';
import { useAppendExtentLink } from './useAppendExtentLink';
import { type DownloadedFile } from './types';

interface DownloadModalProps {
  downloadData: {
    id: number;
    title: string;
    list: DownloadedFile[];
  }[];
  isDownloading: boolean;
  fileNameEnding: string;
  setIsDownloading: (value: boolean) => void;
}

export const DownloadModalContent = ({
  downloadData,
  isDownloading,
  fileNameEnding,
  setIsDownloading,
}: DownloadModalProps) => {
  const { aoiDetails } = useAppendExtentLink();

  const enhancedDownloadData = [
    ...downloadData,
    ...(aoiDetails ? [{ id: 0, title: 'General', list: [aoiDetails] }] : []),
  ];

  const isSingleLink = enhancedDownloadData.flatMap((data) => data.list).length === 1;
  const fileName = isSingleLink ? enhancedDownloadData[0].list[0].name : `exploration${fileNameEnding}`;
  const fileExtension = isSingleLink ? enhancedDownloadData[0].list[0].extension : 'zip';

  const handleDownload = () => {
    isSingleLink
      ? downloadSingleFile({ ...enhancedDownloadData.flatMap((data) => data.list)[0] }, setIsDownloading)
      : downloadAllFilesWithNamesAsZip(
          enhancedDownloadData.flatMap((data) => data.list),
          fileName,
          setIsDownloading,
        );
  };

  if (!enhancedDownloadData.length) {
    return 'No layer selected.';
  }

  return (
    <>
      {enhancedDownloadData.map((list) => (
        <div key={list.id}>
          <h4>{list.title}:</h4>
          <FilesList>
            {list.list.map((listItem) => (
              <li key={listItem.id}>{listItem.label}</li>
            ))}
          </FilesList>
        </div>
      ))}
      <FilesDownloadBlock>
        <Container variant="VERTICAL_START">
          <FileNameWrapper>{getFullFileName(fileName, fileExtension)}</FileNameWrapper>
          <Container mt={25} mb={10}>
            <Button onClick={handleDownload} squared isLoading={isDownloading} minWidth={220}>
              Download {fileExtension}
            </Button>
          </Container>
          {isDownloading && (
            <>
              You can close this window.
              <br />
              The downloaded data will be available in a minute.
            </>
          )}
        </Container>
      </FilesDownloadBlock>
    </>
  );
};

const FilesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin: 0.8em 0 0.4em;
  margin-left: 1.4em;
`;

const FilesDownloadBlock = styled.div`
  font-size: 14px;
`;

const FileNameWrapper = styled.div`
  word-break: break-word;
  line-height: 16px;
`;
