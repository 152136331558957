import styled from 'styled-components';

import { Button } from 'ui/atoms/button';
import { Container } from 'ui/atoms/container';
import { TextAndLink } from 'ui/atoms/textAndLink';
import { LabeledInput } from 'ui/molecules/labeledInput';
import { ForgotPassword } from 'ui/organisms/forgotPassword';
import { type LoginFormProps } from 'ui/organisms/loginSidebar/types';
import { ConsentCheckbox } from 'ui/organisms/consentCheckbox';

export const LoginForm = ({
  username,
  usernameInputElementProps,
  passwordInputElementProps,
  forgotPasswordPopup,
  forgotPasswordServiceData,
  privacyPolicyCheckbox,
  handleFormSubmit,
  errorText,
  isLoggingIn,
}: LoginFormProps) => (
  <>
    <StyledForm onSubmit={handleFormSubmit}>
      <Container variant="VERTICAL" gap={16} mb={26} wMax>
        <LabeledInput {...usernameInputElementProps} />
        <LabeledInput {...passwordInputElementProps} />
        <Container wMax>
          <TextAndLink
            text="Forgot your password?"
            linkedText="Click here"
            handleClick={forgotPasswordPopup.handleOpen}
          />
        </Container>
      </Container>
      <ConsentCheckbox checked={privacyPolicyCheckbox.isChecked} onChange={() => privacyPolicyCheckbox.handleCheck()} />
      <SumbitWrapper>
        <Button
          type="submit"
          name="login"
          disabled={!privacyPolicyCheckbox.isChecked}
          minWidth={120}
          squared
          isLoading={isLoggingIn}
        >
          Log in
        </Button>
        <span>{errorText}</span>
      </SumbitWrapper>
    </StyledForm>
    {forgotPasswordPopup.isOpen && (
      <ForgotPassword
        isOpen={forgotPasswordPopup.isOpen}
        username={username}
        forgotPasswordServiceData={forgotPasswordServiceData}
        handleClose={forgotPasswordPopup.handleClose}
      />
    )}
  </>
);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
  width: 100%;
`;

const SumbitWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  & span {
    font-weight: 600;
    font-size: 10px;
    color: ${({ theme }) => theme.color.redLoginError};
  }
`;
