import styled from 'styled-components';

import { theme } from 'common/theme';
import { useUserStore } from 'infrastructure/user/userStore';
import { Container } from 'ui/atoms/container';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { TitledSection } from 'ui/atoms/titledSection';
import { SubscriptionsLink } from 'views/subscriptions/SubscriptionsLink';
import { useSetSubscriptionErrorMessage } from './useSetSubscriptionErrorMessage';
import { useGetActiveSubscription } from '../useGetActiveSubscription';
import { SubscriptionPreview } from '../SubscriptionPreview';
import { Notification } from '../styled';

export const SubscriptionSection = () => {
  const { activeSubscriptionData, activeSubscriptionError, isSubscriptionExpired, isActiveSubscriptionLoading } =
    useGetActiveSubscription();

  const { errorMessage } = useSetSubscriptionErrorMessage({
    activeSubscriptionData,
    activeSubscriptionError,
    isSubscriptionExpired,
  });

  const { isSubscriptionStatusPending } = useUserStore();

  return (
    <>
      <TitledSection title="current subscription">
        {!isSubscriptionStatusPending && isActiveSubscriptionLoading && (
          <Container mt={9}>
            <LoadingDots color={theme.color.errorPink} />
          </Container>
        )}
        {errorMessage && !isSubscriptionStatusPending && !isActiveSubscriptionLoading && (
          <Notification>{errorMessage}</Notification>
        )}
        {isSubscriptionStatusPending && <Notification>payment is being processed</Notification>}
        {!errorMessage && activeSubscriptionData?.canceled && <Notification>subscription cancelled</Notification>}
        <section>
          {activeSubscriptionData && <SubscriptionPreview variant="SMALL" subscription={activeSubscriptionData} />}
        </section>
      </TitledSection>
      <ButtonsContainer>
        <SubscriptionsLink sidebar />
      </ButtonsContainer>
    </>
  );
};

const ButtonsContainer = styled(Container)`
  width: 164px;
  margin-top: 18px;
`;
