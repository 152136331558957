import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { usePointHistory } from 'components/graph/usePointHistory';
import { useGraphStore } from 'components/graph/graphStore';
import { displacementsRepository } from 'infrastructure/displacements/displacementsRepository';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { useTreeShakeQueryParams } from 'common/navigation/hooks';
import { DISPLACEMENTS_PATH_NAME, DISPLACEMENTS_VIEW_CONFIG } from 'domain/displacements/constants';
import { sortKmzUrls } from 'domain/displacements/helpers/displacementHelpers';
import { useSetInitialKmzUrl } from './useSetInitialKmzUrl';

export const useDisplacementsLifecycle = () => {
  const { areaId } = useParams();
  const numberAreaId = areaId && Number(areaId);

  const resetGraphStore = useGraphStore.use.resetGraphStore();
  const setKmzUrls = useDisplacementsStore.use.setKmzUrls();
  const clearData = useDisplacementsStore.use.clearDisplacementsData();
  const removeWarningErrorsByViewPath = useErrorsStore.use.removeWarningErrorsByViewPath();

  const { clearPointHistoryUseCase } = usePointHistory();

  const displacementsResult =
    numberAreaId && !Number.isNaN(numberAreaId) ? displacementsRepository.useFetchKMZurls(numberAreaId) : undefined;
  const KMZurlsData = displacementsResult && displacementsResult.KMZurlsData;

  useSetInitialKmzUrl();

  useTreeShakeQueryParams(DISPLACEMENTS_VIEW_CONFIG);

  useEffect(() => {
    if (KMZurlsData?.length) {
      const sortedUrls = sortKmzUrls(KMZurlsData).map((kmz_) => {
        const kmz = { ...kmz_ };
        kmz.coherence = kmz.coherence || 0;
        return kmz;
      });
      setKmzUrls(sortedUrls.length > 1 ? sortedUrls.filter((kmz) => kmz.resolution === 80) : sortedUrls);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [KMZurlsData]);

  useEffect(() => {
    return () => {
      clearData();
      clearPointHistoryUseCase();
      resetGraphStore();
      removeWarningErrorsByViewPath(DISPLACEMENTS_PATH_NAME);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);
};
