import { useEffect, useState } from 'react';

import { AbortControllerWithReload } from 'common/classes';
import { displacementsRepository } from 'infrastructure/displacements/displacementsRepository';
import { type LoadingVariant } from 'ui/molecules/loading/Loading';
import { useUpdateDisplacementsTimestamps } from 'views/displacements/mapContent/useUpdateDisplacementsTimestamps';
import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { type GeoJSONCollection, type InitGeoData } from 'domain/displacements/types';
import { allowedKmzFileTypes, DISPLACEMENTS_PATH_NAME } from 'domain/displacements/constants';
import { WarningErrorType } from 'domain/@errors/enums';

const initGeoData: GeoJSONCollection = {
  twoByTwoPoints: [],
  fiveByFivePoints: [],
  twentyByTwentyPoints: [],
};

const abortControllerWrapper = new AbortControllerWithReload();

export const useKmzData = (kmzId: number | undefined) => {
  const [kmzLoadingState, setKmzLoadingState] = useState<LoadingVariant>('DEFAULT');
  const [geoData, setGeoData] = useState<GeoJSONCollection>(initGeoData);
  const [levelsOfDetail, setLevelsOfDetail] = useState<number[]>([]);
  const [isGeoDataAvailable, setIsGeoDataAvailable] = useState<boolean>(false);
  const [selectedGeoJSONData, setSelectedGeoJSONData] = useState<InitGeoData>(null);

  const updateWarningError = useErrorsStore.use.updateWarningError();

  const { getSignedKmzUrl } = displacementsRepository.useGetSignedKmzUrl();

  const { updateDisplacementTimeStamps } = useUpdateDisplacementsTimestamps();

  useEffect(() => {
    if (geoData !== initGeoData) {
      setKmzLoadingState('DEFAULT');
      updateDisplacementTimeStamps(geoData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoData]);

  const clearData = () => {
    abortControllerWrapper.controller.abort();
    setKmzLoadingState('DEFAULT');
    setGeoData(initGeoData);
    setIsGeoDataAvailable(false);
    setLevelsOfDetail([]);
    setSelectedGeoJSONData(null);
  };

  const errorHandler = (error: Error) => {
    if (error.name === 'AbortError') return;
    console.error(error);
    setKmzLoadingState('DEFAULT');

    let errorType = WarningErrorType.DISPLACEMENTS_RESULTS;
    if (error.message?.indexOf('DataCloneError') > -1) {
      errorType = WarningErrorType.DISPLACEMENTS_KMZ_RESULTS;
    }

    updateWarningError({
      [errorType]: {
        type: errorType,
        viewPath: DISPLACEMENTS_PATH_NAME,
      },
    });
  };

  useEffect(() => {
    clearData();

    if (!kmzId) {
      return;
    }

    const worker = new Worker(new URL('../workers/handleKmzWorker.ts', import.meta.url));
    abortControllerWrapper.reload();
    setKmzLoadingState('LOADING');

    getSignedKmzUrl(kmzId).then((url) => {
      url &&
        fetch(url, {
          method: 'get',
          signal: abortControllerWrapper.controller.signal,
        })
          .then((res) => res.blob())
          .then((kmzFile) => {
            if (!allowedKmzFileTypes.includes(kmzFile.type)) {
              throw Error('Wrong file type: ' + kmzFile.type);
            }
            setKmzLoadingState('RENDERING');

            worker.postMessage(kmzFile);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            worker.onmessage = (e: MessageEvent<{ name: string; data: any }>) => {
              const responce = e.data;
              if (responce.name === 'setLevelsOfDetail') {
                setLevelsOfDetail((prevState: number[]) => [
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ...new Set(
                    prevState
                      .concat(responce.data)
                      .filter((amountOfPixels: number) => amountOfPixels > 0)
                      .sort(),
                  ),
                ]);
              } else if (responce.name === 'setGeoData') {
                setGeoData(responce.data);
              } else if (responce.name === 'setIsGeoDataAvailable') {
                setIsGeoDataAvailable(responce.data);
              } else if (responce.name === 'error') {
                console.log(responce);
              }
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            worker.onerror = errorHandler;
          })
          .catch(errorHandler);
    });

    return function () {
      console.log('Worker terminated');
      worker.terminate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmzId]);

  return { kmzLoadingState, isGeoDataAvailable, geoData, levelsOfDetail, selectedGeoJSONData, setSelectedGeoJSONData };
};
