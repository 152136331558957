import { type Scalars, type Exact, TypedDocumentString } from 'gql/graphql';

export type AreaOfInterestCreateMutation = {
  insertAoi: {
    rawAoi: JSON;
  };
};

export type AreaOfInterestCreateMutationVariables = Exact<{
  rawAoi: Scalars['JSON']['input'];
}>;

export const AreasOfInterestCreateDocument = new TypedDocumentString(`
  mutation InsertAoiNew($rawAoi: JSON!) {
    insertAoi(rawAoi: $rawAoi) {
      id
    }
  }
  `) as unknown as TypedDocumentString<AreaOfInterestCreateMutation, AreaOfInterestCreateMutationVariables>;
