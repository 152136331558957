import { type LayersVisibilityDictionary } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { useGetCogWithBoundsGeneric } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { DataLayer } from 'components/map';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';

interface VHRSegmentationLayerProps {
  url: string;
  vhrObjects: LayerObject[];
  layersVisibility: LayersVisibilityDictionary;
}

export const VhrMapDataLayer = ({ url, vhrObjects, layersVisibility }: VHRSegmentationLayerProps) => {
  const { cogUrl, cogBounds } = useGetCogWithBoundsGeneric(
    titilerApiAdapter.buildMultilayerMonocolorCogUrlGeneric,
    getAzureBlobStorageUrl(url),
    {
      objects: vhrObjects.filter(({ name }) => layersVisibility[name]),
      nodataValue: 5,
    },
  );

  if (cogUrl && cogBounds) {
    return (
      <DataLayer isVisible={true} layerName={'vhrSegmentationLayer'} zIndex={490} cogUrl={cogUrl} bounds={cogBounds} />
    );
  }

  return null;
};
