import styled from 'styled-components';

export const Notification = styled.p`
  color: ${({ theme }) => theme.color.errorPink};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.1;
  margin-bottom: -8px;
  text-transform: uppercase;
`;
