import { useEffect, useRef } from 'react';
import { useLocation, type Location } from 'react-router-dom';
import { useMap, useMapEvents } from 'react-leaflet';
import { type MapLayerMouseEvent } from 'maplibre-gl';

import { debounce } from 'common/utils';
import { LOCAL_STORAGE_MAP_CENTER_NAME } from 'common/constants/services';
import { useReplaceQueryParameters } from 'common/navigation/hooks';
import { localStorageService } from 'services/localStorageService';
import { MAP_POSITION_SAVE_DELAY } from 'common/navigation/constants';
import { LAT_LNG_QUERY_PARAMETER, ZOOM_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const MapReferenceMemoizer = () => {
  const location = useLocation();
  const locationRef = useRef<Location>(location);
  const map = useMap();
  const center = map.getCenter();
  const replaceQueryParameter = useReplaceQueryParameters();

  const saveLastPositionHandler = () => {
    localStorageService.setValue(LOCAL_STORAGE_MAP_CENTER_NAME, center);
  };

  const debouncedOnMoveendHandler = debounce((event: MapLayerMouseEvent) => {
    const { lat, lng } = event.target.getCenter();
    const zoom = event.target.getZoom();
    replaceQueryParameter(
      [
        {
          key: LAT_LNG_QUERY_PARAMETER,
          value: `${lat}:${lng}`,
        },
        {
          key: ZOOM_QUERY_PARAMETER,
          value: String(zoom),
        },
      ],
      locationRef.current,
    );
  }, MAP_POSITION_SAVE_DELAY);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useMapEvents({
    unload: saveLastPositionHandler,
    dragend: debouncedOnMoveendHandler,
    zoomend: debouncedOnMoveendHandler,
  });

  return null;
};
