import { getUniqueSortedTimestamps } from 'common/utils/datetime';
import { useGetExtractionResultsUseCase } from 'views/mine/useGetExtractionResultsUseCase';

export const useExtractionTimestamps = () => {
  const { mineResults, mineResultsLoading } = useGetExtractionResultsUseCase();

  const imgDates = mineResults.map(({ img_date }) => img_date);
  const timestamps = getUniqueSortedTimestamps(imgDates);

  return { timestamps, areTimestampsLoading: mineResultsLoading };
};
