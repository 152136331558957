import { type ReactNode } from 'react';

import { useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { ZOOM_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { MapToolbox } from 'ui/map/mapToolbox';
import { useMapAlign } from 'components/map/hooks';
import { PluggedInCustomMapScale } from 'views/areas/PluggedInCustomMapScale';

interface MapToolboxProps {
  children?: ReactNode;
}

export const PluggedInMapToolbox = ({ children }: MapToolboxProps) => {
  const replaceQueryParameter = useReplaceQueryParameters();

  const zoom = useQueryParameter(ZOOM_QUERY_PARAMETER);

  const setZoom = (zoom: number) =>
    replaceQueryParameter([
      {
        key: ZOOM_QUERY_PARAMETER,
        value: String(zoom),
      },
    ]);

  const alignMap = useMapAlign();

  return (
    <MapToolbox
      childrenUpper={children}
      toolboxData={{
        zoom: zoom ? Number(zoom) : undefined,
        setZoom,
        alignMap,
      }}
      childrenLower={<PluggedInCustomMapScale />}
    />
  );
};
