import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { type DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export const useLegendOptions = <K, T extends { id: K }>(
  options: T[],
  layersOrderRecord: K[] | null,
  setLayersOrderRecord: (layersOrder: K[]) => void,
  isDndView?: boolean,
) => {
  const [dndOptions, setDndOptions] = useState<T[]>([]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isDndView) return;

    const optionsIds = layersOrderRecord || [];

    if (!optionsIds.length && !!options.length && setLayersOrderRecord) {
      setLayersOrderRecord(options.map((option) => option.id));
    }

    let optionsArray: T[] | undefined;

    if (optionsIds.length && options.length) {
      optionsArray = optionsIds
        .map((id) => {
          return options.find((option) => option.id === id);
        })
        .filter((option) => option !== undefined) as T[];

      const newOptions = options.filter((option) => {
        return !optionsIds.includes(option.id);
      });

      if (newOptions.length > 0) {
        optionsArray = [...newOptions, ...optionsArray];
      }
    }

    setDndOptions(optionsArray || options);
  }, [options, pathname, layersOrderRecord, isDndView, setLayersOrderRecord]);

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (over) {
      if (active.id !== over?.id && setLayersOrderRecord) {
        let newDndOptions = [...dndOptions];

        const activeIndex = newDndOptions.findIndex((item) => item.id === active.id);
        const overIndex = newDndOptions.findIndex((item) => item.id === over.id);
        newDndOptions = arrayMove(newDndOptions, activeIndex, overIndex);

        setLayersOrderRecord(newDndOptions.map((option) => option.id));

        setDndOptions(newDndOptions);
      }
    }
  };

  return {
    dndOptions,
    setDndOptions,
    handleDragEnd,
  };
};
