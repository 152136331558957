import { type ValueAndLabel } from 'common/types';
import { COORDINATES_PRECISION } from 'common/constants';
import { type TooltipData, type LayerObject } from 'common/types/mapData';
import { GREENERY_OBJECTS_REPORT_COLORMAP } from 'domain/greenery/colors';

export const buildGreeneryDetailedTooltipStringHelper = (tooltip: TooltipData) => {
  return `
    <h4>Greenery type:</h4>
    <p>${tooltip.label}</p>
    <h4>coordinates</h4>
    <p>${tooltip.lat.toFixed(COORDINATES_PRECISION)}</p>
    <p>${tooltip.lng.toFixed(COORDINATES_PRECISION)}</p>
    ${
      typeof tooltip.value === 'string'
        ? `
      <h4>value</h4>
      <p>${tooltip.value}</p>
    `
        : ''
    }
  `;
};

export const buildGreeneryDetailedAnyValueTooltipStringHelper = (tooltip: TooltipData) => {
  return `
    <h4>Greenery type:</h4>
    <p>${tooltip.label}</p>
    <h4>coordinates</h4>
    <p>${tooltip ? tooltip.lat.toFixed(COORDINATES_PRECISION) : undefined}</p>
    <p>${tooltip ? tooltip.lng.toFixed(COORDINATES_PRECISION) : undefined}</p>
    ${
      tooltip?.value
        ? `
      <h4>value</h4>
      ${`<p>${tooltip.value}</p>`}
    `
        : ''
    }
  `;
};

export const buildGreeneryLabelTooltipStringHelper = (tooltip: TooltipData) => {
  return `
    <h4>Greenery type:</h4>
    <p>${tooltip.label}</p>
  `;
};

export const mapGreeneryObjectsToOptionsHelper = (indicators: LayerObject[]): ValueAndLabel[] => {
  return indicators.map((i) => ({
    value: i.id,
    label: i.label_name,
  }));
};

export const mapGreeneryObjectsReportColorHelper = (greeneryObjects: LayerObject[]) => {
  const mappedGreeneryObjects = greeneryObjects.map((object) => {
    const color = GREENERY_OBJECTS_REPORT_COLORMAP.find((objectColor) => objectColor.id === object.id)?.color;

    return color ? { ...object, color } : object;
  });

  return mappedGreeneryObjects;
};
