import { SURFACE_PRECISION_HA } from 'common/constants';
import { type ValueAndLabel } from 'common/types';
import { replaceSquareMetersWithHectares, simpleRound } from 'common/utils';
import { type WaterAreaResult, type WaterBody } from 'domain/water/types';

export const Water = {
  calculateTotalWaterArea: (waterAreas: WaterAreaResult[], selectedWaterbody?: ValueAndLabel): string | undefined => {
    const selectedAreas = selectedWaterbody
      ? waterAreas?.filter((area) => area?.waterbody_id === selectedWaterbody.value)
      : waterAreas;
    const areas = !!selectedAreas && selectedAreas.map((item) => item?.area);
    const summaryArea = !!areas && areas.length > 0 ? areas.reduce((a, b) => a + b) : undefined;
    const totalAreaInHectares = replaceSquareMetersWithHectares(summaryArea);
    const totalWaterArea =
      typeof totalAreaInHectares === 'number' ? simpleRound(totalAreaInHectares, SURFACE_PRECISION_HA) : undefined;
    return totalWaterArea;
  },

  getCurrentArea: (waterAreas: WaterAreaResult[], waterbodyId: number, timestamp: string) => {
    const currentWaterBody: WaterAreaResult = waterAreas.filter(
      (area) => area?.waterbody_id === Number(waterbodyId) && area.img_date === timestamp,
    )[0];
    const currentWaterbodySurface: number | undefined = currentWaterBody?.area;
    return currentWaterbodySurface;
  },

  getWaterbodyClass: (
    waterbodiesList: WaterBody[] | undefined,
    selectedWaterbody: ValueAndLabel | undefined,
  ): string => {
    const selectedWaterBodyItem = waterbodiesList?.filter(({ id }) => id === selectedWaterbody?.value);
    const waterbodyClass = selectedWaterBodyItem ? selectedWaterBodyItem[0]?.type_id.toFixed() : '';
    return waterbodyClass || '';
  },
};
