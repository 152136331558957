import { useEffect, useState } from 'react';

import { type LeafletBounds } from 'common/types/mapData';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { DataLayer } from 'components/map';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayerManager/draggableLayersKeys';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';

interface SatelliteImageLayerProps {
  url: string;
  bidxArray: string[];
  rescaleArray: number[][];
  isGrayscaleSatellite: boolean;
  isVisible: boolean;
  isVhrView: boolean;
  colorMap: string;
  colorFormula: string;
}

export const SatelliteImageLayer = ({
  url,
  bidxArray,
  rescaleArray,
  isGrayscaleSatellite,
  isVisible,
  isVhrView,
  colorMap,
  colorFormula,
}: SatelliteImageLayerProps) => {
  const [cogData, setCogData] = useState<{ cog: string | undefined; bounds: LeafletBounds | undefined }>({
    cog: undefined,
    bounds: undefined,
  });

  // TODO: remove when vhr cogs handling on backend will be ready
  const vhrCogUrl =
    '/tms/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=%2Fvsiaz%2Fusers%2Fdk%2FSKYWATCH_PL_PS_20170528T0849_ALL_Tile_0_0_ad19.tif&rescale=0%2C0.4&bidx=3&bidx=2&bidx=1';
  // TODO

  const grayscaleSatelliteCogUrl = titilerApiAdapter.buildGrayscaleSatelliteImageUrl(url, {
    bidx: bidxArray[0],
    rescale: rescaleArray[0],
    colorMap,
  });

  const satelliteCogUrl = titilerApiAdapter.buildSatelliteImageUrl(url, {
    bidxArray,
    rescaleArray,
    colorFormula,
  });

  const { extentLatLngBounds } = useGetExtentPolygon(url);

  useEffect(() => {
    switch (true) {
      case !isGrayscaleSatellite && isVhrView:
        setCogData({ cog: vhrCogUrl, bounds: undefined });
        break;
      case isGrayscaleSatellite && !!url:
        setCogData({ cog: grayscaleSatelliteCogUrl, bounds: extentLatLngBounds });
        break;
      default:
        setCogData({ cog: satelliteCogUrl, bounds: extentLatLngBounds });
    }
  }, [satelliteCogUrl, isGrayscaleSatellite, colorMap]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {cogData.cog && extentLatLngBounds?._northEast && extentLatLngBounds?._southWest ? (
        <WithLayerIndex
          Component={DataLayer}
          props={{
            isVisible,
            layerName: LAYERS_KEYS.SATELLITE,
            bounds: extentLatLngBounds,
            cogUrl: cogData.cog,
            fixed: isVhrView,
          }}
          sectionId={LAYERS_KEYS.SATELLITE}
        />
      ) : null}
    </>
  );
};
