import { useMemo } from 'react';

import { type ListItemStatus } from 'common/types';
import { type LayerObjectWithUrl } from 'common/types/mapData';
import { type MineralTypeDTO } from 'domain/exploration/types';
import { DEPOSITS_PREFERRED_MINERALS_LAYERS } from 'domain/exploration/configs';
import { useGetSelectedClassifierMineralsResults } from '../../useGetSelectedClassifierMineralsResults';

export const useLegendOptions = (minerals: MineralTypeDTO[], classifierId: number) => {
  const { selectedClassifierMineralsResults } = useGetSelectedClassifierMineralsResults(classifierId);

  const mineralsPresentIds = selectedClassifierMineralsResults.map(({ mineral_id }) => mineral_id);

  const presentMinerals = minerals.filter(({ id }) => mineralsPresentIds.includes(id));

  const legendOptions: LayerObjectWithUrl[] = useMemo(
    () =>
      minerals
        .map((option) => {
          const result = selectedClassifierMineralsResults.find(({ mineral_id }) => mineral_id === option.id);

          if (!result) {
            return null;
          }

          return {
            id: option.id,
            name: `${classifierId}_${option.name}`,
            label_name: option.label_name,
            color: option.color,
            status: 'active' as ListItemStatus,
            url: result.file_path,
          };
        })
        .filter((mineral): mineral is LayerObjectWithUrl => !!mineral),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minerals.length, JSON.stringify(mineralsPresentIds)],
  );

  const filteredOptions = presentMinerals.filter(({ name, deposit_id }) =>
    DEPOSITS_PREFERRED_MINERALS_LAYERS[deposit_id]
      ? DEPOSITS_PREFERRED_MINERALS_LAYERS[deposit_id].includes(name)
      : false,
  );

  const preferredOptions = filteredOptions?.length > 0 ? filteredOptions : presentMinerals;

  return { legendOptions, preferredOptions };
};
