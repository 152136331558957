/* // TODO: remove when mercedes data will be handled */
import { useEffect } from 'react';

import { useAoiNumber } from 'common/navigation/hooks';
import { LayerManagerLegendItem } from 'components/layerManager/legacy/LegendItem';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';

export const useWaterAreaLayerManagerSpecialContent = () => {
  const enablePleiadesAndPlanetScope = process.env.REACT_APP_ENABLE_PLEIADES_AND_PLANET_SCOPE;

  const areaId = useAoiNumber();
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();

  const layersVisibility = useLayerManagerStore.use.layersVisibility();
  const toggleLayerVisibility = useLayerManagerStore.use.toggleLayerVisibility();
  const toggleDataLayerVisibility = useLayerManagerStore.use.toggleDataLayerVisibility();

  const onObjectsToggle = (name: string) => {
    toggleLayerVisibility(name);

    if (name === 'pleiades' && layersVisibility['planet_scope']) {
      toggleLayerVisibility('planet_scope');
    }

    if (name === 'planet_scope' && layersVisibility['pleiades']) {
      toggleLayerVisibility('pleiades');
    }
  };

  const isSpecialModeActive = layersVisibility['pleiades'] || layersVisibility['planet_scope'];

  useEffect(() => {
    if (isSpecialModeActive) {
      toggleDataLayerVisibility();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layersVisibility['pleiades'], layersVisibility['planet_scope']]);

  useEffect(() => {
    if (isDataLayerVisible) {
      layersVisibility['pleiades'] && toggleLayerVisibility('pleiades');
      layersVisibility['planet_scope'] && toggleLayerVisibility('planet_scope');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLayerVisible]);

  const WaterAreaLayerManagerSpecialContent = () =>
    areaId === 353 && enablePleiadesAndPlanetScope ? (
      <>
        <LayerManagerLegendItem
          option={{
            id: 100,
            name: 'pleiades',
            label_name: 'Pleiades',
            color: '',
            status: 'active',
          }}
          handleObjectsVisibility={onObjectsToggle}
          objectVisibility={layersVisibility['pleiades']}
        />
        <LayerManagerLegendItem
          option={{
            id: 101,
            name: 'planet_scope',
            label_name: 'PlanetScope',
            color: '',
            status: 'active',
          }}
          handleObjectsVisibility={onObjectsToggle}
          objectVisibility={layersVisibility['planet_scope']}
        />
      </>
    ) : null;

  return { WaterAreaLayerManagerSpecialContent };
};
/* TODO */
