import { useRefreshLayer } from 'common/hooks/useRefreshLayer';
import { useLayerOrderStore } from 'components/layerManager/layersOrderStore';
import { useSortedNestedLayersData } from 'components/layerManager/useSortedNestedLayersData';
import { findLayerIndex } from 'components/layerManager/draggableLayerManager/helpers';
import { type SurfaceMineralsDisplayed } from 'domain/exploration/types';
import { MINERALIZATION_SLIDER_CONFIG } from 'domain/exploration/configs';
import { useMtmfLayersVisibility } from './useSurfaceLayersVisibility';
import { MineralsPlainMapLayer, MtmfGradientMapLayer } from './MineralsMapLayer';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

interface MtmfMineralsMapLayersProps {
  areaId: number;
  minerals: SurfaceMineralsDisplayed[];
}

export const MtmfMineralsMapContent = ({ areaId, minerals }: MtmfMineralsMapLayersProps) => {
  const { nestedLayersOrderRecord } = useLayerOrderStore();
  const sortedMinerals = useSortedNestedLayersData(
    nestedLayersOrderRecord,
    EXPLORATION_LAYERS_KEYS.MTMF,
    minerals,
    'mineral_id',
  );

  const isGradientModeOn = useExplorationLayerManagerStore.use.gradientModeState()[2];
  const { classesVisibility: layersVisibility } = useMtmfLayersVisibility();

  const gradientLayer = sortedMinerals.find(({ name }) => !!layersVisibility[name]);

  const explorationLayersOrderTable = useLayerOrderStore.use.layersOrderTable();

  const findMtmfLayerIndex = (mineral_id: number): number =>
    explorationLayersOrderTable
      ? findLayerIndex(explorationLayersOrderTable, EXPLORATION_LAYERS_KEYS.MTMF, mineral_id)
      : 0;

  const { visible } = useRefreshLayer([sortedMinerals.length]);

  if (!visible) {
    return null;
  }

  return (
    <>
      {isGradientModeOn && gradientLayer ? (
        <MtmfGradientMapLayer
          areaId={areaId}
          index={findMtmfLayerIndex(gradientLayer.mineral_id)}
          name={gradientLayer.name}
          url={gradientLayer.url}
          isVisible={layersVisibility[gradientLayer.name]}
          layerRange={MINERALIZATION_SLIDER_CONFIG.mtmf.staticRange}
        />
      ) : (
        <>
          {sortedMinerals.map(({ id, name, url, color, mineral_id }) => (
            <MineralsPlainMapLayer
              key={id}
              index={findMtmfLayerIndex(mineral_id)}
              {...{ areaId, url, color }}
              name={name}
              classifierId={2}
              isVisible={layersVisibility[name] || false}
            />
          ))}
        </>
      )}
    </>
  );
};
