import { useEffect } from 'react';

import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { useGetAvailableQuota } from 'views/subscriptions/useGetAvailableQuota';
import { useIsTrialUser } from 'views/user/useIsTrialUser';
import { useGetActiveSubscription } from 'views/subscriptions/useGetActiveSubscription';
import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { buildRequestStatusHelper, buildSelectionStatusHelper } from 'domain/areas/helpers';
import { useSubscriptionColumnLoading } from './useSubscriptionColumnLoading';

export const useAreaRequestStatus = () => {
  const selectedSubscription = useAreaRequestStore.use.selectedSubscription();
  const selectedSubscriptionAreaQuota = selectedSubscription?.area_quota;
  const selectedAreaInMeters = useAreaRequestStore.use.area();
  const setSelectionStatus = useAreaRequestStore.use.setSelectionStatus();
  const setRequestStatus = useAreaRequestStore.use.setRequestStatus();
  const isTrialUser = useIsTrialUser();

  const { subscriptionProductsList } = subscriptionRepository.useGetProductsList();
  const maximumSubscriptionAreaQuota = Math.max(...subscriptionProductsList.map((o) => o.area_quota));

  const { activeSubscriptionData } = useGetActiveSubscription();

  const { isLoading } = useSubscriptionColumnLoading();

  const activeSubscriptionCapacity = activeSubscriptionData ? activeSubscriptionData.capacity : undefined;
  const availableAreaQuota = useGetAvailableQuota(activeSubscriptionCapacity);

  const selectionStatus = buildSelectionStatusHelper(
    selectedAreaInMeters,
    selectedSubscriptionAreaQuota,
    maximumSubscriptionAreaQuota,
    availableAreaQuota,
    activeSubscriptionCapacity,
  );

  useEffect(() => {
    setSelectionStatus(selectionStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedAreaInMeters,
    selectedSubscriptionAreaQuota,
    maximumSubscriptionAreaQuota,
    availableAreaQuota,
    activeSubscriptionCapacity,
    setSelectionStatus,
  ]);

  const requestStatus = buildRequestStatusHelper(
    isTrialUser,
    selectedAreaInMeters,
    availableAreaQuota,
    isLoading,
    activeSubscriptionData?.name,
    activeSubscriptionCapacity,
    selectedSubscription,
  );

  useEffect(() => {
    setRequestStatus(requestStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTrialUser,
    selectedAreaInMeters,
    availableAreaQuota,
    isLoading,
    activeSubscriptionData?.name,
    activeSubscriptionCapacity,
    selectedSubscription,
    setRequestStatus,
  ]);
};
