import { type GeoJsonProperties, type Geometry, type Feature } from 'geojson';

import { type GeoJSONElement, type GeoJSONArray } from 'domain/displacements/types';

export const concatGeoJSON = (geoJSONData: GeoJSONArray) => {
  if (!geoJSONData) {
    return;
  }

  if (geoJSONData.length === 1) {
    return geoJSONData;
  }

  const features = geoJSONData
    .reduce((combinedFeatures: Feature<Geometry | null, GeoJsonProperties>[], featureCollection: GeoJSONElement) => {
      if (featureCollection?.features) return combinedFeatures.concat(featureCollection.features);
      return combinedFeatures;
    }, [])
    .map((feature, i) => {
      const feature_ = { ...feature };
      feature_.id = i;
      return feature_;
    });

  return {
    type: 'FeatureCollection',
    features,
  };
};

export const concatMultiGeoJSON = (geoJSONDataArray: GeoJSONArray[]) => {
  const combainedFeatureCollection = geoJSONDataArray.reduce((acc, geoJSONData) => acc.concat(geoJSONData), []);
  return concatGeoJSON(combainedFeatureCollection);
};
