import { ApplicationInsights, type ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import env from 'react-dotenv';

import { LOCAL_STORAGE_ACCEPT_COOKIE } from 'common/constants/services';
import { localStorageService } from './localStorageService';

const browserHistory = createBrowserHistory();

const getAppInsightsInstrumentationKey = () => {
  const appInsightsKey = env?.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY;

  if (!appInsightsKey) {
    throw Error('REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY is not provided');
  }

  const acceptedCookie = localStorageService.getValue<boolean>(LOCAL_STORAGE_ACCEPT_COOKIE, false);
  const appInsightsInstrumentationKey = acceptedCookie ? appInsightsKey || 'ad37a123-8312-4425-ac72-159ff4053f8a' : '';

  return appInsightsInstrumentationKey;
};

const reactPlugin: unknown = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getAppInsightsInstrumentationKey(),
    enableAutoRouteTracking: true,
    extensions: [reactPlugin as ITelemetryPlugin],
    extensionConfig: {
      [(reactPlugin as ITelemetryPlugin).identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

export const updateTelemetryStatus = () => {
  appInsights.config.instrumentationKey = getAppInsightsInstrumentationKey();
};

export { reactPlugin, appInsights };
