import styled from 'styled-components';

import { type DisplayableMapPoint } from 'common/types/mapData';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { ReactComponent as InclineArrow } from 'assets/svg/incline-arrow.svg';
import { simpleRound } from 'common/utils';

interface MultipleInfoAreaContentProps {
  multipleWaterInfoArea: {
    description: string;
    waterbodiesNumber?: number;
    totalAreaInHectares: string | undefined;
  };
}

export const MultipleWaterInfoAreaContent = ({
  multipleWaterInfoArea: { description, waterbodiesNumber, totalAreaInHectares },
}: MultipleInfoAreaContentProps) => (
  <InfoAreaList>
    {description}
    <InfoAreaItem>All Water Bodies: {waterbodiesNumber}</InfoAreaItem>
    {totalAreaInHectares && <InfoAreaItem>Total Area: {totalAreaInHectares} ha</InfoAreaItem>}
  </InfoAreaList>
);

interface SingleWaterInfoAreaProps {
  singleWaterInfoArea: {
    waterbodyLabel: string;
    dataPoint: DisplayableMapPoint | null;
    waterbodyClass: string;
    waterbodyData: {
      currentValue: number | null;
      valuePrecision: number | undefined;
      valuesDifference: number | null;
      unit: string | undefined;
      arrowInverted: boolean | undefined;
      isFetching: boolean;
    };
  };
}

const handleExpectedValueState = (
  value: number | null,
  valuePrecision: number | undefined,
  isLoading: boolean,
  unit: string | undefined,
) => {
  switch (true) {
    case isLoading:
      return <LoadingDots color="black" />;
    case !isLoading && value !== null:
      return `${simpleRound(value!, valuePrecision)}${unit ? ` ${unit}` : ''}`;
    default:
      return '---';
  }
};

export const SingleWaterInfoAreaContent = ({
  singleWaterInfoArea: {
    waterbodyLabel,
    dataPoint,
    waterbodyClass,
    waterbodyData: { currentValue, valuePrecision, valuesDifference, unit, arrowInverted, isFetching },
  },
}: SingleWaterInfoAreaProps) => {
  const averageValue = handleExpectedValueState(currentValue, valuePrecision, isFetching, unit);
  const differenceValue = handleExpectedValueState(valuesDifference, valuePrecision, false, unit);

  return (
    <InfoAreaList>
      <InfoAreaNameItem>{waterbodyLabel}</InfoAreaNameItem>
      {dataPoint && (
        <InfoAreaCoordinatesItem>
          <div>{dataPoint.lat}</div>
          <div>{dataPoint.lng}</div>
        </InfoAreaCoordinatesItem>
      )}
      <InfoAreaItem>Waterbody class: {waterbodyClass}</InfoAreaItem>
      <InfoAreaLabeledIndicator>
        <IndicatorLabel>current average</IndicatorLabel>
        <IndicatorField color="#FF8B68">{averageValue}</IndicatorField>
      </InfoAreaLabeledIndicator>
      <InfoAreaLabeledIndicator>
        <IndicatorLabel>compared to last mo.</IndicatorLabel>
        <IndicatorField>
          {differenceValue}
          {differenceValue && typeof arrowInverted === 'boolean' && <TrendArrow $decline={arrowInverted} />}
        </IndicatorField>
      </InfoAreaLabeledIndicator>
      <InfoAreaLabeledIndicator>
        <IndicatorLabel>treshold value</IndicatorLabel>
        <IndicatorField color="#AADA85">---</IndicatorField>
      </InfoAreaLabeledIndicator>
    </InfoAreaList>
  );
};

const InfoAreaList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const InfoAreaItem = styled.li`
  margin-top: 8px;
`;

const InfoAreaNameItem = styled.li`
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-top: 4px;
`;

const InfoAreaCoordinatesItem = styled.li`
  font-size: 14px;
  font-weight: 400;
  line-height: 0.85;
  text-transform: uppercase;
  margin-top: 4px;
`;

const InfoAreaLabeledIndicator = styled.li`
  display: flex;
  margin-top: 12px;
`;

const IndicatorLabel = styled.div`
  font-size: 8px;
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  word-wrap: break-word;
  flex-basis: 58px;
  margin-right: 4px;
`;

const IndicatorField = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 8px;
  color: black;
  background-color: ${({ color }) => color || '#fff'};
`;

const TrendArrow = styled(InclineArrow)<{ $decline: boolean }>`
  transform: ${({ $decline }) => ($decline ? 'rotate3d(1, 0, 0, 180deg)' : 'none')};
`;
