import styled from 'styled-components';

import { ReactComponent as ExclamationIcon } from './exclamation-icon.svg';
import { Tooltip } from '../../atoms/tooltip';

interface SupportContactLinkProps {
  rotation: number;
}

export const SupportContactLink = ({ rotation }: SupportContactLinkProps) => {
  return (
    <Tooltip content="contact support">
      <Wrapper href="mailto:support@terraeye.com" $rotation={rotation}>
        <ExclamationIcon />
      </Wrapper>
    </Tooltip>
  );
};

const Wrapper = styled.a<{ $rotation: number }>`
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.color.mineShaft};
  color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
  transform: ${({ $rotation }) => `rotate(${$rotation}deg)`};

  svg {
    height: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.selectGray};
  }
`;
