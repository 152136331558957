import { Image, View, Text } from '@react-pdf/renderer';

import { commonStyles, diagramStyles } from 'views/reports/external/pdfGenerator/styles';
import { type ExternalReportChart } from 'domain/reports/types';

export const ReportPdfDonutChart = ({ chart }: { chart: ExternalReportChart }) => (
  <View style={diagramStyles.donut_chart__wrapper}>
    <Image style={diagramStyles.donut_chart} src={chart.src} />
    <View style={diagramStyles.donut_chart__header}>
      <Text style={commonStyles.subheader}>{chart.header}</Text>
    </View>
  </View>
);
