import { useMutation, useQuery } from '@tanstack/react-query';

import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { dataRequestAPI } from 'infrastructure/dataRequest/dataRequestApiAdapter';
import {
  DATA_REQUEST_DATA_TYPES_QUERY_KEY,
  DATA_REQUEST_ANALYSIS_TYPES_QUERY_KEY,
} from 'infrastructure/dataRequest/constants';
import { type DataRequestOrder } from 'domain/dataRequest/types';
import { areAnalysisTypesValid, areDataTypesValid } from 'domain/dataRequest/typeguards';

export const dataRequestRepository = {
  useFetchDataTypes: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        DATA_REQUEST_DATA_TYPES_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => dataRequestAPI.getDataTypes({ limit, offset }),
    );

    const dataTypes = areDataTypesValid(data) ? data.data.results : [];

    return {
      dataTypes,
      areDataTypesLoading: isLoading,
    };
  },

  useFetchAnalysisTypes: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        DATA_REQUEST_ANALYSIS_TYPES_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => dataRequestAPI.getAnalysisTypes({ limit, offset }),
    );

    const analysisTypes = areAnalysisTypesValid(data) ? data.data.results : [];

    return {
      analysisTypes,
      areAnalysisTypesLoading: isLoading,
    };
  },

  usePostOrder: () => {
    const { mutate, status, error } = useMutation((order: DataRequestOrder) => dataRequestAPI.postOrder(order));

    return {
      handleOrderPost: mutate,
      orderPostStatus: status,
      orderPostError: error,
    };
  },
};
