import styled from 'styled-components';

import { Wrapper } from 'ui/atoms/wrapper';
import { LabeledCheckbox } from 'ui/molecules/labeledCheckbox';
import { TextAndLink } from 'ui/atoms/textAndLink';

export interface ConsentCheckboxProps {}

export interface ConsentCheckboxProps {
  checked: boolean;
  onChange: (event: React.SyntheticEvent) => void;
}

export const ConsentCheckbox = ({ checked, onChange }: ConsentCheckboxProps) => (
  <Wrapper bottom={10}>
    <LabeledCheckbox variant="LOGIN" checked={checked} onChange={onChange} reversed>
      <ConsentLinks>
        <TextAndLink
          text="I declare that I have read the"
          linkedText="Terms and Conditions"
          link="https://terraeye.co/terms-and-conditions"
        />
        <TextAndLink text=" and" linkedText="Privacy Policy" link="https://terraeye.co/privacy-policy-terraeye-app" />
        {' and accept their provisions.'}
      </ConsentLinks>
    </LabeledCheckbox>
  </Wrapper>
);

const ConsentLinks = styled.div`
  display: inline;
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
