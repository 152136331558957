import { areasListQueryService } from 'views/areas/areasList/areasListQueryService';
import { InputForm } from 'ui/molecules/inputForm';

export const Search = () => {
  const { query, onQueryChange } = areasListQueryService.useFilterList();

  return (
    <InputForm
      variant="SEARCH"
      name="areas-search"
      placeholder="Search areas&hellip;"
      value={query || ''}
      onChange={onQueryChange}
    />
  );
};
