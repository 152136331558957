import { type AxiosResponse } from 'axios';

import { restAPI } from 'services/rest/restApiService';
import { SUBSCRIPTION_API_PREFIX } from 'infrastructure/subscription/constants';
import { SUBSCRIPTION_API_URLS } from 'infrastructure/subscription/subscriptionApiUrls';
import {
  type SubscriptionResponseDTO,
  type SubscriptionRequest,
  type SubscriptionStatusResponseDTO,
  type SubscriptionProductDTO,
  type TrialDurationResponseDTO,
} from 'domain/subscription/types';

export const subscriptionAPI = {
  getProductsList: (): Promise<AxiosResponse<SubscriptionProductDTO[]>> =>
    restAPI.services.get(`${SUBSCRIPTION_API_PREFIX}/api/v1${SUBSCRIPTION_API_URLS.getProductsList}`),

  create: (request: SubscriptionRequest): Promise<AxiosResponse<SubscriptionResponseDTO>> =>
    restAPI.services.post(`${SUBSCRIPTION_API_PREFIX}/api/v1${SUBSCRIPTION_API_URLS.create}`, request),

  getStatus: (): Promise<AxiosResponse<SubscriptionStatusResponseDTO>> =>
    restAPI.services.get(`${SUBSCRIPTION_API_PREFIX}/api/v1${SUBSCRIPTION_API_URLS.getStatus}`),

  cancel: (): Promise<AxiosResponse<string>> =>
    restAPI.services.get(`${SUBSCRIPTION_API_PREFIX}/api/v1${SUBSCRIPTION_API_URLS.cancel}`),

  upgrade: (request: SubscriptionRequest): Promise<AxiosResponse<SubscriptionResponseDTO>> =>
    restAPI.services.post(`${SUBSCRIPTION_API_PREFIX}/api/v1${SUBSCRIPTION_API_URLS.upgrade}`, request),

  trialDuration: (): Promise<AxiosResponse<TrialDurationResponseDTO>> =>
    restAPI.services.get(`${SUBSCRIPTION_API_PREFIX}/api/v1${SUBSCRIPTION_API_URLS.trialDuration}`),
};
