import { useLocation } from 'react-router-dom';

import { SURFACE_PRECISION_HA } from 'common/constants';
import { type ValueAndLabel } from 'common/types';
import { type DisplayableMapPoint } from 'common/types/mapData';
import { replaceSquareMetersWithHectares } from 'common/utils';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useAoiNumber, useQueryParameter } from 'common/navigation/hooks';
import { useInfoAreaService } from 'ui/infoArea/infoAreaService';
import { useGraphStore } from 'components/graph/graphStore';
import { useWaterStore } from 'infrastructure/water/waterStore';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useWaterAreaResults } from 'views/water/surface/useWaterAreaResults';
import { useWaterIndicators } from 'views/water/quality/indicators/useWaterIndicators';
import { useTimelineService } from 'components/Timeline/timelineService';
import { getDisplayableMapPoint } from 'common/utils/map';
import { type WaterBody } from 'domain/water/types';
import { WaterActiveTab } from 'domain/water/enums';
import { mapWaterBodiesToOptionsHelper } from 'domain/water/helpers';
import { WATER_AREA_INFO_AREA_DESCRIPTION, WATER_QUALITY_INFO_AREA_DESCRIPTION } from 'domain/water/configs';
import { Water } from 'domain/water/WaterModel';

export const useWaterInfoArea = () => {
  const { pathname } = useLocation();
  const { timestamp } = useTimelineService();

  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);
  const waterbodyId = waterbodyIdQuery ? Number(waterbodyIdQuery) : undefined;
  const isAreaTabActive: boolean = pathname.includes(WaterActiveTab.SURFACE);

  const areaId = useAoiNumber();

  const { selectedIndicator } = useWaterIndicators();

  const { waterQualityResults, waterQualityResultsLoading } = waterRepository.useFetchWaterQualityResults(
    areaId,
    timestamp,
    selectedIndicator?.id,
  );
  const selectedWaterbodyStats = waterQualityResults.find(
    ({ waterbody_id }) => String(waterbody_id) === waterbodyIdQuery,
  );

  const { waterBodiesList } = waterRepository.useFetchWaterbodies(areaId);
  const selectedWaterbody: WaterBody | undefined = waterBodiesList?.find(({ id }) => id === waterbodyId);
  const selectedWaterbodyOption: ValueAndLabel | undefined =
    selectedWaterbody && mapWaterBodiesToOptionsHelper([selectedWaterbody])[0];
  const selectedPoint = useGraphStore.use.chosenPoint();

  const { waterAreaResults, areWaterAreaResultsLoading } = useWaterAreaResults();

  const infoAreaCommonProps = useInfoAreaService();

  // TODO: find real last month timestamp
  const lastMoTimestamp = timestamp ? timestamp : undefined;
  const { areWaterAreaResultsLoading: previousResultLoading } = useWaterAreaResults(lastMoTimestamp, waterbodyId);

  const waterbodyDefaultView = useWaterStore.use.waterBodyDefaultView();

  const getSingleWaterInfoAreaProps = () => {
    const waterbodyLabel: string = selectedWaterbodyOption?.label || '';

    const dataPoint: DisplayableMapPoint | null = selectedPoint
      ? getDisplayableMapPoint([selectedPoint.lng, selectedPoint.lat])
      : waterbodyDefaultView
      ? getDisplayableMapPoint([waterbodyDefaultView.lon, waterbodyDefaultView.lat])
      : null;

    const waterbodyClass = Water.getWaterbodyClass(waterBodiesList, selectedWaterbodyOption);

    const currentWaterbodySurface =
      waterbodyId && timestamp
        ? replaceSquareMetersWithHectares(Water.getCurrentArea(waterAreaResults, waterbodyId, timestamp))
        : null;
    const difference = null;

    const waterAreaData = {
      currentValue: currentWaterbodySurface,
      valuePrecision: SURFACE_PRECISION_HA,
      valuesDifference: difference,
      unit: 'ha',
      arrowInverted: difference ? difference < 0 : undefined,
      isFetching: previousResultLoading || areWaterAreaResultsLoading,
    };

    const waterQualityData = {
      currentValue: selectedWaterbodyStats ? selectedWaterbodyStats.avg_value : null,
      valuePrecision: selectedIndicator?.precision,
      valuesDifference: difference,
      unit: selectedIndicator?.unit,
      arrowInverted: undefined,
      isFetching: waterQualityResultsLoading,
    };

    return {
      waterbodyLabel,
      dataPoint,
      waterbodyClass,
      waterbodyData: isAreaTabActive ? waterAreaData : waterQualityData,
    };
  };

  const getMultipleWaterInfoAreaProps = () => {
    const description = isAreaTabActive ? WATER_AREA_INFO_AREA_DESCRIPTION : WATER_QUALITY_INFO_AREA_DESCRIPTION;
    const waterbodiesNumber: number | undefined = waterBodiesList?.length;
    const totalAreaInHectares: string | undefined = Water.calculateTotalWaterArea(
      waterAreaResults,
      selectedWaterbodyOption,
    );

    return {
      description,
      waterbodiesNumber,
      totalAreaInHectares,
    };
  };

  const waterInfoAreaService = {
    singleWaterInfoArea: getSingleWaterInfoAreaProps(),
    multipleWaterInfoArea: getMultipleWaterInfoAreaProps(),
    infoAreaProps: infoAreaCommonProps,
  };

  return waterInfoAreaService;
};
