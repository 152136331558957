import chroma from 'chroma-js';
import { type ColormapDictionary } from 'common/types';

export const buildColorScaleHelper = (
  colorMap: ColormapDictionary,
  domainLevel?: number,
): chroma.Scale<chroma.Color> => {
  if (!colorMap) {
    return chroma.scale();
  }

  const arrayFromColormap: [string, string][] = Object.entries(colorMap);
  const sortedArray = arrayFromColormap.sort((a, b) => Number(a[0]) - Number(b[0]));
  const colors: string[] = sortedArray.map((item) => item[1].replace('#', ''));
  const domain: number[] = sortedArray.map((item) => Number(item[0]));

  const domainWithLevel = domainLevel && domain.map((k) => -domainLevel + 2 * domainLevel * k);

  return chroma.scale(colors).domain(domainWithLevel ? domainWithLevel : domain);
};
