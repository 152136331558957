import { useEffect } from 'react';
import styled from 'styled-components';

import { Select } from 'ui/atoms';
import { getFirstSelectTimestamps, getSecondSelectTimestamps, isSecondSelectTimestampForbidden } from './helpers';
import { type ValueAndLabel, type DoubleDateSelectVariant } from '../../types';
import { convertTimestampToSelectOption, mapTimestampsToSelectOptions } from '../../utils';

export interface DoubleDateSelectProps {
  variant?: DoubleDateSelectVariant;
  timestamps: string[];
  timestamp: string | null;
  secondTimestamp: string | null;
  changeTimestamp: (timestamp: string) => void;
  changeSecondTimestamp: (timestamp: string) => void;
  areTimestampsLoading?: boolean;
  handleAreBothDatesSelected?: (areSelected: boolean) => void;
}

export const DoubleDateSelect = ({
  variant = 'DEFAULT',
  timestamps,
  timestamp,
  secondTimestamp,
  changeTimestamp,
  changeSecondTimestamp,
  areTimestampsLoading = false,
  handleAreBothDatesSelected,
}: DoubleDateSelectProps) => {
  const commonSelectData = {
    isFetching: areTimestampsLoading,
    initialStateLabel: 'Select date',
    noOptionsLabel: 'No dates',
  };

  const firstSelectTimestamps = getFirstSelectTimestamps(timestamps, variant);

  const firstSelectData = {
    ...commonSelectData,
    options: mapTimestampsToSelectOptions(firstSelectTimestamps),
    selectedOption:
      timestamp && firstSelectTimestamps.includes(timestamp) ? convertTimestampToSelectOption(timestamp) : undefined,
    name: 'first date selection',
  };

  const secondSelectTimestamps =
    timestamp && firstSelectTimestamps.includes(timestamp)
      ? getSecondSelectTimestamps(timestamps, timestamp, variant)
      : [];

  const secondSelectData = {
    ...commonSelectData,
    options: mapTimestampsToSelectOptions(secondSelectTimestamps),
    selectedOption:
      secondTimestamp && secondSelectTimestamps.includes(secondTimestamp)
        ? convertTimestampToSelectOption(secondTimestamp)
        : undefined,
    name: 'second date selection',
  };

  const onFirstDateChange = (option: ValueAndLabel) => {
    changeTimestamp(String(option.value));
  };

  const onSecondDateChange = (option: ValueAndLabel) => {
    changeSecondTimestamp(String(option.value));
  };

  useEffect(() => {
    if ((!timestamp || !firstSelectTimestamps.includes(timestamp)) && firstSelectTimestamps.length) {
      changeTimestamp(firstSelectTimestamps[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      timestamp &&
      firstSelectTimestamps.includes(timestamp) &&
      (!secondTimestamp ||
        !secondSelectTimestamps.includes(secondTimestamp) ||
        isSecondSelectTimestampForbidden(timestamp, secondTimestamp, variant)) &&
      secondSelectTimestamps.length
    ) {
      changeSecondTimestamp(secondSelectTimestamps[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  useEffect(() => {
    if (handleAreBothDatesSelected) {
      if (firstSelectData.selectedOption?.value && secondSelectData.selectedOption?.value) {
        handleAreBothDatesSelected(true);
      } else {
        handleAreBothDatesSelected(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp, secondTimestamp]);

  return (
    <DoubleDateSelectWrapper>
      <Select {...firstSelectData} onChange={onFirstDateChange} minWidth={140} />
      <Select {...secondSelectData} onChange={onSecondDateChange} minWidth={140} />
    </DoubleDateSelectWrapper>
  );
};

const DoubleDateSelectWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
