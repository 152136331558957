import { useEffect, useState } from 'react';

import { useDeleteQueryParameters, useQueryParameter } from 'common/navigation/hooks';
import { USER_TOKEN_QUERY_PARAMETER, USER_UDI_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { userRepository } from 'infrastructure/user/userRepository';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

export const useHandleChangePasswordUseCase = () => {
  const [passwords, setPasswords] = useState({
    firstPassword: '',
    secondPassword: '',
  });

  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [showPasswordsMatchError, setShowPasswordsMatchError] = useState(false);

  const [errorInfo, setErrorInfo] = useState<string[] | null>(null);
  const [isTokenError, setIsTokenError] = useState(false);

  const navigate = useNavigate();
  const deleteQueryParams = useDeleteQueryParameters();

  const uid = useQueryParameter(USER_UDI_QUERY_PARAMETER);
  const token = useQueryParameter(USER_TOKEN_QUERY_PARAMETER);

  useEffect(() => {
    if (!token || !uid) {
      navigate(ROUTES.login);
    }
  }, [token, uid, navigate]);

  useEffect(() => {
    setShowPasswordsMatchError(false);
    setPasswordsMatch(passwords.firstPassword === passwords.secondPassword);
    setErrorInfo(null);
  }, [passwords]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({
      ...passwords,
      [event.target.name]: event.target.value,
    });
  };

  const { mutate, status, error } = userRepository.useChangePassword();

  useEffect(() => {
    setErrorInfo(null);

    if (error) {
      if (error instanceof AxiosError) {
        const passwordErrors = error.response?.data.new_password2 as string[];

        if (passwordErrors && passwordErrors.length > 0) {
          setErrorInfo(passwordErrors);
          return;
        }

        if (error.response?.data.token) {
          setIsTokenError(true);
          return;
        }
      }

      setErrorInfo(['Something went wrong. Please try again.']);
    }
  }, [error]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setShowPasswordsMatchError(true);

    if (!passwordsMatch || !token || !uid) return;

    mutate({
      token: token,
      uid: uid,
      newPassword1: passwords.firstPassword,
      newPassword2: passwords.secondPassword,
    });
  };

  const onUserRedirect = () => {
    deleteQueryParams([USER_UDI_QUERY_PARAMETER, USER_TOKEN_QUERY_PARAMETER]);
    navigate(ROUTES.login);
  };

  return {
    passwords,
    onInputChange,
    passwordsMatch,
    showPasswordsMatchError,
    onSubmit,
    onUserRedirect,
    status,
    errorInfo,
    isTokenError,
  };
};
