import { useEffect } from 'react';
import { Chat } from 'ui/chat/chat';
import { useChatStore } from './chatStore';
import { useChatService } from './chatService';

export const AssistantChat = () => {
  const closeChatFullscreen = useChatStore.use.closeChatFullscreen();
  const { promptChat, messages, status } = useChatService();

  useEffect(() => {
    return () => {
      closeChatFullscreen();
    };
  }, [closeChatFullscreen]);

  return <Chat messages={messages} promptChat={promptChat} status={status} />;
};
