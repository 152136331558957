import { type ReactNode } from 'react';
import styled from 'styled-components';

import { Chevron } from 'ui';

interface WarningErrorListProps {
  errorItems: ReactNode[];
  isListVisible: boolean;
  toggleListVisibility: () => void;
}

export const WarningErrorList = ({ errorItems, isListVisible, toggleListVisibility }: WarningErrorListProps) => {
  return (
    <Wrapper childrenBottom={isListVisible}>
      <Content>
        Missing information in this view:
        <List visible={isListVisible}>{errorItems}</List>
      </Content>
      <ChevronWrapper onClick={toggleListVisibility}>
        <Label visible={isListVisible}>Show</Label>
        <Chevron isFlipped={isListVisible} width={16} height={9} marginLeft={8} />
      </ChevronWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ childrenBottom: boolean }>`
  display: flex;
  align-items: ${({ childrenBottom }) => (childrenBottom ? 'flex-end' : 'flex-start')};
  background-color: ${({ theme }) => theme.color.selectGray};
  font-size: 10px;
  line-height: 1.4em;
  letter-spacing: 0.04em;
  padding: 14px 10px;
`;

const Content = styled.div`
  flex-basis: 100%;
  line-height: 1.4;
`;

interface ListProps {
  visible: boolean;
}

const List = styled.ul<ListProps>`
  display: ${({ visible }) => !visible && 'none'};
  margin: 0;
  padding-left: 1.4rem;
`;

const ChevronWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const Label = styled.div<ListProps>`
  display: ${({ visible }) => visible && 'none'};
  font-size: 11px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-left: 8px;
`;
