import { useEffect, useState } from 'react';

import { INFOAREA_TOP_OFFSET_OFF, INFOAREA_TOP_OFFSET_ON, INFO_AREA_DEFAULT_WIDTH } from 'common/constants/infoArea';
import { LOCAL_STORAGE_INFO_AREA_VISIBILITY_NAME } from 'common/constants/services';
import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { useInfoAreaWarningErrorsService } from 'views/@errors/infoAreaWarningErrorsService';
import { localStorageService } from 'services/localStorageService';

export const useInfoAreaService = () => {
  const [isInfoAreaVisible, setIsInfoAreaVisible] = useState<boolean>(
    !!localStorageService.getValue(LOCAL_STORAGE_INFO_AREA_VISIBILITY_NAME, true),
  );

  const toggleInfoArea = () => setIsInfoAreaVisible(!isInfoAreaVisible);

  const showWarningErrorList = useErrorsStore.use.showWarningErrorList();

  const toggleInfoAreaVisibility = () => {
    localStorage.setItem(LOCAL_STORAGE_INFO_AREA_VISIBILITY_NAME, JSON.stringify(!isInfoAreaVisible));
    toggleInfoArea();
  };

  const displayInfoArea = () => {
    localStorage.setItem(LOCAL_STORAGE_INFO_AREA_VISIBILITY_NAME, JSON.stringify(true));
    setIsInfoAreaVisible(true);
  };

  const errors = useInfoAreaWarningErrorsService();

  useEffect(() => {
    if (errors?.isInfoAreaToShow) {
      displayInfoArea();
      showWarningErrorList();
    }
  }, [errors?.isInfoAreaToShow]); // eslint-disable-line react-hooks/exhaustive-deps

  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const topOffset = isComparisonActive ? INFOAREA_TOP_OFFSET_ON : INFOAREA_TOP_OFFSET_OFF;
  const buttonTopOffset = isComparisonActive ? INFOAREA_TOP_OFFSET_ON - INFOAREA_TOP_OFFSET_OFF : 0;

  const infoAreaCommonProps = {
    geometry: {
      width: INFO_AREA_DEFAULT_WIDTH,
      topOffset,
      zIndex: 1000,
    },
    buttonTopOffset,
    errors: errors?.errorList || null,
    iconColor: errors?.infoAreaIconColor,
    isInfoAreaVisible,
    toggleInfoAreaVisibility,
  };

  return infoAreaCommonProps;
};
