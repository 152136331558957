import { WaterAreaDobuleDateReportsExternalData } from 'views/reports/external/data/WaterAreaDobuleDateReportsExternalData';
import { useReportsExternalInitialDataService } from 'views/reports/external/data/services/reportsExternalInitialDataService';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface WaterAreaDobuleDateReportsExternalInitialDataProps {
  areaId: number;
  timestamp: string;
  reportInfo: ExternalReportHeaderCommon;
}

export const WaterAreaDobuleDateReportsExternalInitialData = ({
  areaId,
  timestamp,
  reportInfo,
}: WaterAreaDobuleDateReportsExternalInitialDataProps) => {
  const initialDataset = useReportsExternalInitialDataService(timestamp);

  return (
    initialDataset && (
      <WaterAreaDobuleDateReportsExternalData areaId={areaId} initialDataset={initialDataset} reportInfo={reportInfo} />
    )
  );
};
