import { useEffect, useState } from 'react';

import { hotspotsRepository } from 'infrastructure/hotspots/hotspotsRepository';
import { useQueryParameter } from 'common/navigation/hooks';
import { GREENERY_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGetVegetationHotspotsMapContentUseCase } from 'views/greenery/vegetation/getVegetationHotspotsMapContentUseCase';
import { VegetationHotspotsLayerManagerLegend } from 'views/greenery/vegetation/layerManager/VegetationHotspotsLayerManagerLegend';

interface Props {
  areaId: number;
}

export const VegetationHotspotsLayerManagerContent = ({ areaId }: Props) => {
  const [url, setUrl] = useState<string | undefined>(undefined);

  const indicatorQuery = useQueryParameter(GREENERY_INDICATOR_QUERY_PARAMETER);

  const { hotspotTypes, isLoading: areHotspotTypesLoading } = hotspotsRepository.useFetchHotspotTypes();

  const { hotspots, isLoading: areHotspotsLoading } = useGetVegetationHotspotsMapContentUseCase();

  const currentQueryUrl = hotspots.find(({ indicator_id }) => indicator_id === Number(indicatorQuery))?.cog_file_path;

  useEffect(() => {
    indicatorQuery && setUrl(currentQueryUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueryUrl]);

  return (
    <>
      {url ? (
        <VegetationHotspotsLayerManagerLegend
          areaId={areaId}
          hotspotTypes={hotspotTypes}
          cogUrl={url}
          isLoading={areHotspotTypesLoading || areHotspotsLoading}
        />
      ) : null}
    </>
  );
};
