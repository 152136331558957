import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { type TooltipData } from 'common/types/mapData';
import { debounce } from 'common/utils';
import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { MapTooltip } from 'ui/map/tooltip';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';
import { checkIsOutsideBounds } from 'common/utils/map';
import { buildGreeneryDetailedAnyValueTooltipStringHelper } from 'domain/greenery/helpers';

interface BiomassTitilerTooltipProps {
  url: string;
}

export const BiomassTitilerTooltip = ({ url }: BiomassTitilerTooltipProps) => {
  const [tooltip, setTooltip] = useState<TooltipData | undefined>();

  const map = useMap();

  const { info } = titilerInfoService.useGetInfoGeneric(getAzureBlobStorageUrl(url));
  const bounds = getBoundsFromCogInfo(info);

  map.off('click');

  map.on(
    'click',
    debounce(async (event: { originalEvent: PointerEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      if (!url || checkIsOutsideBounds(latLng, bounds)) {
        return;
      }

      const info = await titilerApiService.getPointInfoGeneric(getAzureBlobStorageUrl(url), latLng?.lng, latLng?.lat);

      const values = info?.values;

      if (info.detail === 'Point is outside dataset bounds') {
        setTooltip(undefined);
      }

      if (values && Array.isArray(values) && values.length > 0) {
        values.forEach((value: number) => {
          if (value > 0) {
            setTooltip({
              id: 500,
              lat: latLng?.lat,
              lng: latLng?.lng,
              value: `${value.toFixed(0)} Mg/ha`,
              label: 'Biomass',
            });
          }
        });
      }
    }, TOOLTIP_DISPLAY_DELAY),
  );

  return tooltip ? (
    <MapTooltip tooltipData={tooltip} content={buildGreeneryDetailedAnyValueTooltipStringHelper(tooltip)} />
  ) : null;
};
