import { useEffect, useState } from 'react';

import { useDebounce } from 'common/hooks/useDebounce';
import { areValuesEqual } from './helpers';

export const useSliderValueDebounce = (
  value: number | number[],
  changeThreshold: (value: number | number[]) => void,
) => {
  const [localValue, setLocalValue] = useState(value);
  const debouncedSliderValue = useDebounce(localValue);

  useEffect(() => {
    changeThreshold(debouncedSliderValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSliderValue]);

  useEffect(() => {
    if (!areValuesEqual(value, localValue)) {
      setLocalValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return { value: localValue, setValue: setLocalValue };
};
