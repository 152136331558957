import { AppLayerManager } from 'components/layerManager/legacy';

export const ProspectingLayerManager = () => {
  // TODO: restore below commented code when pins feature available

  // const layersVisibility = useLayerManagerStore.use.layersVisibility();
  // const toggleLayerVisibility = useLayerManagerStore.use.toggleLayerVisibility();
  // const updateLayersVisibility = useLayerManagerStore.use.updateLayersVisibility();

  // useEffect(() => {
  //   if (layersVisibility[PINS_LAYER_LABEL] === undefined) {
  //     updateLayersVisibility({ [PINS_LAYER_LABEL]: true });
  //   }
  // }, [layersVisibility, updateLayersVisibility]);

  return (
    <AppLayerManager>
      {/* <LegendItemWraper>
        <LabeledCheckbox
          children={PINS_LAYER_LABEL}
          variant="WIDE"
          onChange={() => toggleLayerVisibility(PINS_LAYER_LABEL)}
          checked={layersVisibility[PINS_LAYER_LABEL] || false}
        />
      </LegendItemWraper> */}
    </AppLayerManager>
  );
};
