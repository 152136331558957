import { type ChangeEvent, type SyntheticEvent } from 'react';
import styled from 'styled-components';

import { InputForm } from 'ui/molecules/inputForm';
import { buildResultString } from 'ui/organisms/geocodeSearch/helpers';
import { type GeocodeSearchResult } from 'ui/organisms/geocodeSearch/types';

interface GeocodeSearchProps {
  query: string;
  onQueryChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onQuerySubmit: (e: SyntheticEvent<Element, Event>) => void;
  isDropdownVisible: boolean;
  results: GeocodeSearchResult[];
  onResultSelect: (result: GeocodeSearchResult) => void;
  isError?: boolean;
}

export const GeocodeSearch = ({
  query,
  onQueryChange,
  onQuerySubmit,
  isDropdownVisible,
  results,
  onResultSelect,
  isError,
}: GeocodeSearchProps) => {
  return (
    <Wrapper>
      <InputForm
        variant="SEARCH"
        name="geocode-search"
        placeholder="Search for location&hellip;"
        value={query}
        onChange={onQueryChange}
        onSubmit={onQuerySubmit}
        width={200}
      />

      {isError ? (
        <SearchErrorInfo>
          <p>
            An error has occurred. <br /> Please try again.
          </p>
        </SearchErrorInfo>
      ) : (
        <DropdownList visible={isDropdownVisible}>
          {results.map((result) => (
            <DropdownListItem key={result.id} onClick={() => onResultSelect(result)}>
              {buildResultString(result)}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 1005;
  display: flex;
  flex-direction: column;
`;

const DropdownList = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  flex-grow: 1;
  margin-top: 4px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.duneDarker};
`;

const SearchErrorInfo = styled.div`
  margin-top: 10px;
  border-radius: 8px;
  width: 200px;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.duneDarker};

  p {
    line-height: 18px;
    margin: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.color.errorPink};
  }
`;

const DropdownListItem = styled.div`
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.color.duneLighter};
  }
`;
