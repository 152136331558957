import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { fetchingFlashingAnimation } from 'common/animations';
import { type MultiGraphData, type GraphData, type GraphLabelType, type GraphConfig } from './types';
import { GRAPH_PADDING_RIGHT } from './constants';
import { type GraphSeries } from './enums';
import { createMultiGraphDataScalesLabelsCommand, checkY2state } from './helpers';
import { useGraphStore } from './graphStore';
import { createDygraph } from './createDygraph';
import { OpenGraphButton } from './OpenGraphButton';

interface GraphProps {
  isTimelineVisible: boolean;
  dateRange: {
    firstDate: number;
    lastDate: number;
  };
  label?: string;
  children?: ReactNode;
  graphHeaderContent?: ReactNode;
  graphConfigExtension?: GraphConfig;
}

export const Graph = ({
  isTimelineVisible,
  dateRange,
  label,
  children,
  graphHeaderContent,
  graphConfigExtension,
}: GraphProps) => {
  const isGraphFetching = useGraphStore.use.isFetching();
  const [isGraphMaximized, setIsGraphMaximized] = useState(true);
  const additionalData = useGraphStore.use.additionalData();
  const additionalDataOptions = useGraphStore.use.additionalDataOptions();
  const data = useGraphStore.use.graphData();

  const [graphData, setGraphData] = useState<GraphData | MultiGraphData | undefined>(data);
  const [graphLabel, setGraphLabel] = useState<string[] | undefined>(undefined);
  const { isY2hidden, isPrecipitation } = checkY2state(graphLabel);

  const dygraphContainerRef = useRef<HTMLDivElement>(null);
  const graphWindowBackgroundRef = useRef<HTMLDivElement>(null);

  const closeAndClearGraphData = useCallback(() => {
    if (isGraphMaximized) {
      graphWindowBackgroundRef.current?.addEventListener(
        'transitionend',
        () => {
          setGraphData(undefined);
        },
        {
          once: true,
        },
      );

      setIsGraphMaximized(false);
    } else {
      setGraphData(undefined);
    }
  }, [isGraphMaximized]);

  const activeAdditionalDataOptions = Object.entries(additionalDataOptions)
    .filter(([_, value]) => value === true)
    .map(([key]) => key);

  useEffect(() => {
    if (data) {
      let mergedDateNumberArrays: GraphData | MultiGraphData | undefined = undefined;
      let currentLabel: GraphLabelType = label ? ['Date', label.toUpperCase()] : undefined;

      activeAdditionalDataOptions.forEach((seriesName) => {
        [mergedDateNumberArrays, currentLabel] = createMultiGraphDataScalesLabelsCommand({
          label: currentLabel,
          seriesName: seriesName as GraphSeries,
          additionalData: additionalData,
          data: (mergedDateNumberArrays || data) as GraphData | MultiGraphData,
        });
      });

      const mergedGraphData = (mergedDateNumberArrays || data) as MultiGraphData;
      const filteredGraphData = mergedGraphData.filter(
        (item) => item[0].getTime() >= dateRange.firstDate && item[0].getTime() <= dateRange.lastDate,
      );

      setGraphData(filteredGraphData.length ? filteredGraphData : mergedGraphData);
      setGraphLabel(currentLabel);
    }
  }, [data, additionalData, additionalDataOptions, label, dateRange.firstDate, dateRange.lastDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data && graphData) {
      closeAndClearGraphData();
    }
  }, [closeAndClearGraphData, data, graphData]);

  useEffect(() => {
    if (dygraphContainerRef.current && graphData) {
      createDygraph({
        dygraphContainer: dygraphContainerRef.current,
        dygraphData: graphData,
        labels: graphLabel,
        isY2hidden: isY2hidden,
        isPrecipitation: isPrecipitation,
        graphConfigExtension,
      });
      setIsGraphMaximized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData]);

  return (
    <GraphWindowBackground
      isGraphOpen={isGraphMaximized && !!graphData}
      ref={graphWindowBackgroundRef}
      isGraphFetching={isGraphFetching}
    >
      {!!graphData && (
        <OpenGraphButton
          isGraphOpen={isGraphMaximized}
          isTimelineVisible={isTimelineVisible}
          onClick={() => setIsGraphMaximized(!isGraphMaximized)}
        />
      )}
      <StyledGraphWindow isTimelineVisible={isTimelineVisible}>
        <StyledDygraphInfo>
          <>
            {graphHeaderContent}
            {children}
          </>
        </StyledDygraphInfo>
        <StyledGraphWrapper isY2hidden={isY2hidden}>
          <div ref={dygraphContainerRef}></div>
        </StyledGraphWrapper>
      </StyledGraphWindow>
    </GraphWindowBackground>
  );
};

Graph.displayName = 'Graph';
Graph.defaultProps = {
  isTimelineVisible: true,
};

const graphHeight = '235px';

const GraphWindowBackground = styled.div<{ isGraphOpen: boolean; isGraphFetching: boolean }>`
  z-index: 1001;
  position: absolute;
  height: ${graphHeight};
  bottom: ${({ isGraphOpen }) => (!isGraphOpen ? '-' + graphHeight : '0px')};
  left: 0;
  right: 0;
  background: rgba(31, 31, 31, 0.9);
  transition: bottom ${({ theme }) => theme.transitionFunction.main},
    left ${({ theme }) => theme.transitionFunction.main};

  ${({ isGraphFetching }) => isGraphFetching && fetchingFlashingAnimation};
`;

const StyledDygraphInfo = styled.div`
  display: flex;
  gap: 15px;
  margin-left: 55px;
`;

const StyledGraphWrapper = styled.div<{ isY2hidden: boolean }>`
  padding-right: ${({ isY2hidden }) => (isY2hidden ? GRAPH_PADDING_RIGHT + 'px' : 0)};
  .dygraph-legend {
    color: black;
    width: auto;
    border-radius: 5px;
    padding: 5px;
  }

  .dygraph-legend span {
    color: inherit !important;
  }

  .dygraph-axis-label {
    color: white;
  }

  & > div {
    overflow: visible !important;
  }
`;

const StyledGraphWindow = styled.div<{ isTimelineVisible: boolean }>`
  z-index: 500;
  position: absolute;
  height: ${graphHeight};
  left: 0;
  right: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: ${({ isTimelineVisible }) => (isTimelineVisible ? 'flex-start' : 'space-between')};
  padding: 20px 10px;
`;
