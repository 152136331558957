import { type ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as FitInIcon } from 'assets/svg/fitInIcon.svg';
import { MapControlButton } from 'ui/map/mapControlButton';
import { CustomZoomControl } from 'ui/map/customZoomControl';
import { Wrapper } from 'ui/atoms/wrapper/Wrapper';

export type MapToolboxVariant = 'FULLSCREEN' | 'WINDOWED';

const styles = {
  FULLSCREEN: {
    margin: 27,
    top: 127,
  },
  WINDOWED: {
    margin: -90,
    top: 10,
  },
};

interface MapToolboxProps {
  variant?: MapToolboxVariant;
  childrenUpper?: ReactNode;
  toolboxData: {
    zoom?: number | undefined;
    setZoom?: (zoom: number) => void;
    alignMap?: () => void;
  };
  childrenLower?: ReactNode;
}

export const MapToolbox = ({
  variant,
  childrenUpper,
  toolboxData: { zoom, setZoom, alignMap },
  childrenLower,
}: MapToolboxProps) => (
  <Wrapper variant="ABSOLUTE" margin={styles[variant!].margin} top={styles[variant!].top}>
    <ToolboxWrapper>
      {childrenUpper}
      {alignMap && <MapControlButton icon={<FitInIcon />} iconWidth={20} active={false} onClick={alignMap} />}
      {setZoom && <CustomZoomControl zoom={zoom || 0} setZoom={setZoom} />}
      {childrenLower}
    </ToolboxWrapper>
  </Wrapper>
);

const ToolboxWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

MapToolbox.defaultProps = {
  variant: 'FULLSCREEN' as MapToolboxVariant,
};
