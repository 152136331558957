import { type ComponentType } from 'react';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';

interface Props<T> {
  Component: ComponentType<T & { cogUrl: string }>;
  props: T;
  resultId: number;
}

export const WithDailySegmentationCog = <T,>({ Component, props, resultId }: Props<T>) => {
  const { segmentationCogUrl: cogUrl } = greeneryRepository.useFetchSegmentationCog(resultId);

  return <>{cogUrl && <Component {...(props as T)} cogUrl={cogUrl} />}</>;
};
