export enum WaterActiveTab {
  QUALITY = 'quality',
  SURFACE = 'surface',
  MOISTURE = 'moisture',
  SNOW_COVER = 'snow',
}

export enum WaterbodyIndicators {
  CHLOROPHYLL = 'chlorophyll',
  TURBIDITY = 'turbidity',
  NDCI = 'ndci',
  DOC = 'doc',
  CDOM = 'cdom',
  TSS = 'tss',
  NDTI = 'ndti',
  IRON_OXIDE = 'iron_oxide',
  CYA = 'cya',
  COLOR = 'color',
  CHLA_1 = 'chla_1',
  CHLA_2 = 'chla_2',
  CHLA_3 = 'chla_3',
  SURFACE = 'surface',
}
