import { useAoiNumber } from 'common/navigation/hooks';
import { registryRepository } from 'infrastructure/registry/registryRepository';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { type AoiCogExtended } from 'domain/areas/types';
import { areasRepository } from './areasRepository';

export const useGetAoiCogs = () => {
  const areaId = useAoiNumber();

  const { providers } = registryRepository.useFetchProductProviders();
  const providerNames = providers.map(({ name }) => name);

  const { aoiCogs } = areasRepository.useFetchAreaOfInterestCogsByProviders(areaId, providerNames);

  // TODO: resign from fetching for TiTiler info for each cog and adding isRGB field, when no more 3-bands cogs available in database
  const { cogsWithInfo } = titilerInfoService.useGetCogsWithInfo(aoiCogs);

  const aoiCogsExtended: AoiCogExtended[] = cogsWithInfo.map((item) => ({
    ...item.cog,
    isRGB: item.info.count === 3,
  }));

  return {
    aoiCogs: aoiCogsExtended,
  };
};
