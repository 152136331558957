import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { SubscriptionsList } from './SubscriptionsList';
import { useSubscriptionService } from '../useSubscriptionService';

export const SubscriptionsStart = () => {
  const { subscriptionProductsList, isSubscriptionProductsListLoading, subscriptionProductsListError } =
    subscriptionRepository.useGetProductsList();

  const { onSubscriptionRequest, isSubscriptionRequesting: _ } = useSubscriptionService(console.dir, console.dir);

  if (subscriptionProductsListError) {
    return <h1>Unable to fetch subscriptions data</h1>;
  }

  return (
    <>
      <h1>Start a Subscription Today</h1>
      <SubscriptionsList
        list={subscriptionProductsList}
        loading={isSubscriptionProductsListLoading}
        handleSubscriptionRequest={onSubscriptionRequest}
      />
    </>
  );
};
