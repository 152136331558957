import { useAoiNumber } from 'common/navigation/hooks';
import { GreeneryLayerManagerLegend } from 'views/greenery/components/GreeneryLayerManagerLegend';
import { useSegmentationOtherProviderResult } from 'views/greenery/segmentation/useSegmentationOtherProviderResult';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';

interface Props {
  timestamp: string;
}

export const SegmentationOtherProvidersLayerManager = ({ timestamp }: Props) => {
  const areaId = useAoiNumber();

  const { cogUrl } = useSegmentationOtherProviderResult(timestamp);

  const { greeneryObjects: layerObjects } = greeneryRepository.useFetchGreeneryObjects();

  if (!cogUrl) {
    return null;
  }

  return <GreeneryLayerManagerLegend {...{ areaId, layerObjects, cogUrl }} />;
};
