import { useLayerManagerStore } from 'components/layerManager';
import { useAoiNumber } from 'common/navigation/hooks';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { type DownloadedFile } from './types';

export const useAppendExtentLink = () => {
  const isExtentVisible = useLayerManagerStore.use.isExtentVisible();
  const areaId = useAoiNumber();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const extentUrl = URL.createObjectURL(
    new Blob([JSON.stringify(areaOfInterestDetails, null, 2)], {
      type: 'text/plain',
    }),
  );

  const aoiDetails =
    isExtentVisible && areaOfInterestDetails
      ? {
          id: 'details',
          url: extentUrl,
          name: `${areaId}_${areaOfInterestDetails?.properties.name}_details`,
          label: 'Area of Interest',
          extension: 'json',
        }
      : null;

  const appendExtentLink = (preparedLinks: DownloadedFile[]) =>
    [...preparedLinks, aoiDetails].filter((details) => details !== null) as DownloadedFile[];

  return { aoiDetails, appendExtentLink };
};
