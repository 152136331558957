import { useEffect } from 'react';

import { useAoiNumber } from 'common/navigation/hooks';
import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { useGetPreviousTimestamp } from 'components/Timeline/getPreviousTimestamp';
import { useWaterTimestamps } from 'views/water/useWaterTimestamps';
import { useGraphStore } from 'components/graph/graphStore';
import { useWaterStore } from 'infrastructure/water/waterStore';
import { usePointHistory } from 'components/graph/usePointHistory';
import { WATER_PATH_NAME } from 'domain/water/constants';

export const useWaterLifecycle = () => {
  const areaId = useAoiNumber();

  const resetGraphStore = useGraphStore.use.resetGraphStore();
  const clearWaterData = useWaterStore.use.clearWaterData();
  const removeWarningErrorsByViewPath = useErrorsStore.use.removeWarningErrorsByViewPath();
  const { timestamps } = useWaterTimestamps();

  const setPreviousTimestamp = useWaterStore.use.setPreviousTimestamp();

  useGetPreviousTimestamp(timestamps, setPreviousTimestamp);

  const { clearPointHistoryUseCase } = usePointHistory();

  useEffect(() => {
    return () => {
      clearWaterData();
      removeWarningErrorsByViewPath(WATER_PATH_NAME);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);

  useEffect(() => {
    return () => {
      clearPointHistoryUseCase();
      clearWaterData();
      resetGraphStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
