import { UserIcon, AssistantIcon, NotificationIcon } from 'ui/sidebar/icons';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { type SidebarFooterButton } from 'ui/sidebar/types';
import { SidebarFooterButtonId } from 'ui/sidebar/enums';

export const useGetSidebarFooterButtons = () => {
  const openSidebarPanel = useSidebarStore.use.openSidebarPanel();

  const sidebarFooterButtons: SidebarFooterButton[] = [
    {
      id: SidebarFooterButtonId.USER_SETTINGS,
      icon: UserIcon,
      iconVariant: 'PRIMARY',
      iconSize: 24,
      sidebarPanelType: 'USER_PANEL',
      action: () => openSidebarPanel('USER_PANEL'),
    },
    {
      id: SidebarFooterButtonId.CHAT_GPT,
      icon: AssistantIcon,
      sidebarPanelType: 'ASSISTANT_PANEL',
      action: () => openSidebarPanel('ASSISTANT_PANEL'),
    },
    {
      id: SidebarFooterButtonId.NOTIFICATIONS,
      icon: NotificationIcon,
      sidebarPanelType: 'NOTIFICATIONS_PANEL',
      action: () => openSidebarPanel('NOTIFICATIONS_PANEL'),
    },
  ];

  return { sidebarFooterButtons };
};
