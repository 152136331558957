import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { type SubscriptionStatusResponseDTO } from 'domain/subscription/types';

interface Props {
  activeSubscriptionData: SubscriptionStatusResponseDTO | undefined;
  activeSubscriptionError: unknown;
  isSubscriptionExpired: boolean;
}

export const useSetSubscriptionErrorMessage = ({
  activeSubscriptionData,
  activeSubscriptionError,
  isSubscriptionExpired,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (activeSubscriptionError instanceof AxiosError && activeSubscriptionError.response?.status === 404) {
      setErrorMessage && setErrorMessage("you don't have a subscription");
    }
  }, [activeSubscriptionError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeSubscriptionData && setErrorMessage) {
      if (
        activeSubscriptionData.status !== 'active' &&
        activeSubscriptionData.status !== 'trialing' &&
        activeSubscriptionData.status !== 'canceled'
      ) {
        const status = activeSubscriptionData.status === 'past_due' ? 'payment failed' : activeSubscriptionData.status;
        setErrorMessage(`Subscription status: ${status}`);
        return;
      }

      if (isSubscriptionExpired) {
        setErrorMessage('Subscription has expired');
        return;
      }

      setErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscriptionData]);

  return { errorMessage };
};
