import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { useTimelineService } from 'components/Timeline/timelineService';

export const useDisplacementsTimeline = () => {
  const timestamps = useDisplacementsStore.use.timestamps();
  const { timestamp } = useTimelineService();

  const timelineData = { timestamps, timestamp, visible: false };

  return {
    timelineData,
  };
};
