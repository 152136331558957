import { useRefreshLayer } from 'common/hooks/useRefreshLayer';
import { findLayerIndex } from './helpers';
import { useLayerOrderStore } from '../layersOrderStore';

export const useLayerIndex = (sectionId: string, layerId?: number) => {
  const layersOrderTable = useLayerOrderStore.use.layersOrderTable();

  const index = layersOrderTable ? findLayerIndex(layersOrderTable, sectionId, layerId || 0) : 0;

  const { visible } = useRefreshLayer([index]);

  return { visible, index };
};
