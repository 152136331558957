import { type LayerObject } from 'common/types/mapData';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { Loading } from 'ui/molecules/loading';
import { DataLayer } from 'components/map';
import { ShareModal } from 'components/downloadModal/legacyShareModal';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { SegmentationTitilerTooltip } from 'views/greenery/components';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { useBiomass } from 'views/greenery/segmentation/useBiomass';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { mapGreeneryObjectsReportColorHelper } from 'domain/greenery/helpers';
import { GreeneryActiveTab } from 'domain/greenery/enums';

interface SegmentationDataLayerProps {
  url: string;
  objects: LayerObject[];
}

export const SegmentationDataLayer = ({ url, objects }: SegmentationDataLayerProps) => {
  const { cogUrl, extentLatLngBounds, isInfoLoading } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildMultilayerMonocolorCogUrlGeneric,
    getAzureBlobStorageUrl(url),
    {
      objects,
    },
  );

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();

  const { isBiomassActive } = useBiomass();
  const { classesVisibility } = useGreeneryLayersVisibility();

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const takeScreenshotFlag = useReportsStore.use.takeScreenshotFlag();

  if (isInfoLoading) {
    return <Loading />;
  }

  return (
    <>
      <DataLayer
        isVisible={isDataLayerVisible}
        layerName={'segmentationLayer'}
        zIndex={490}
        cogUrl={cogUrl}
        bounds={extentLatLngBounds}
      />
      {!takeScreenshotFlag && !isBiomassActive && (
        <SegmentationTitilerTooltip
          greeneryObjects={greeneryObjects}
          layersVisibility={classesVisibility}
          cogUrl={url}
          cogBounds={extentLatLngBounds}
        />
      )}
    </>
  );
};

export const SegmentationDataLayerPreparation = ({ resultId }: { resultId: number }) => {
  const { segmentationCogUrl, isSegmentationCogLoading } = greeneryRepository.useFetchSegmentationCog(resultId);

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();

  const { classesVisibility } = useGreeneryLayersVisibility();
  const areReportsActive = useGetAreReportsActiveUseCase();

  const selectedObjects = (
    areReportsActive ? mapGreeneryObjectsReportColorHelper(greeneryObjects) : greeneryObjects
  ).filter(({ name }) => classesVisibility[name]);

  if (isSegmentationCogLoading) {
    return <Loading />;
  }

  if (!segmentationCogUrl) {
    return null;
  }

  return (
    <>
      {selectedObjects.length && <SegmentationDataLayer url={segmentationCogUrl} objects={selectedObjects} />}
      <ShareModal
        links={selectedObjects.length ? [segmentationCogUrl] : []}
        activeTab={GreeneryActiveTab.SEGMENTATION}
      />
    </>
  );
};
