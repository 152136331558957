import { useAoiNumber } from 'common/navigation/hooks';
import { getUniqueSortedTimestamps } from 'common/utils/datetime';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useTimelineService } from 'components/Timeline/timelineService';

export const useGreeneryTimestamps = () => {
  const areaId = useAoiNumber();

  const { timestamp, secondTimestamp } = useTimelineService();

  const { greeneryDateResults, areGreeneryDateResultsLoading } = greeneryRepository.useFetchGreeneryDateResults(areaId);

  const imgDates = greeneryDateResults.map(({ img_date }) => img_date);
  const timestamps = getUniqueSortedTimestamps(imgDates);
  const validTimestamp = !!timestamp && timestamps.includes(timestamp) ? timestamp : null;
  const validSecondTimestamp =
    !!secondTimestamp && timestamps.includes(secondTimestamp) && secondTimestamp !== timestamp ? secondTimestamp : null;

  return {
    timestamps,
    areTimestampsLoading: areGreeneryDateResultsLoading,
    validTimestamp,
    validSecondTimestamp,
  };
};
