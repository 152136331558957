import { DateSelect } from 'ui';
import { CreatorDataColumn } from 'ui/creator/creatorDataColumn';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { ExtractionDoubleDateReportsCreatorContent } from 'views/reports/creator/content/views/ExtractionDoubleDateReportsCreatorContent';
import { ExtractionSingleDateReportsCreatorContent } from 'views/reports/creator/content/views/ExtractionSingleDateReportsCreatorContent';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { ReportsCreatorSavedDataColumn } from 'views/reports/creator/components/ReportsCreatorSavedDataColumn';
import { ReportsCreatorDataColumnDatesLabel } from 'views/reports/creator/components/ReportsCreatorDataColumnDatesLabel';
import { useExtractionTimestamps } from 'views/mine/useExtractionTimestamps';
import { useGetExtractionResultsUseCase } from 'views/mine/useGetExtractionResultsUseCase';

interface ExtractionReportsCreatorContentProps {
  timestamp: string | null;
  changeTimestamp: (timestamp: string | null) => void;
}

export const ExtractionReportsCreatorContent = ({
  timestamp,
  changeTimestamp,
}: ExtractionReportsCreatorContentProps) => {
  const { timestamps } = useExtractionTimestamps();

  const { mineResultsLoading } = useGetExtractionResultsUseCase();

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const firstDateReportsExtractionDataset = useReportsStore.use.firstDateReportsExtractionDataset();
  const secondDateReportsExtractionDataset = useReportsStore.use.secondDateReportsExtractionDataset();
  const clearReportsExtractionDataset = useReportsStore.use.clearReportsExtractionDataset();

  return (
    timestamps &&
    (isCompareDatesType ? (
      <>
        {firstDateReportsExtractionDataset && (
          <ReportsCreatorSavedDataColumn
            dataset={firstDateReportsExtractionDataset}
            clearDataset={clearReportsExtractionDataset}
          />
        )}
        {secondDateReportsExtractionDataset && (
          <ReportsCreatorSavedDataColumn
            dataset={secondDateReportsExtractionDataset}
            clearDataset={clearReportsExtractionDataset}
          />
        )}
        {!secondDateReportsExtractionDataset &&
          !mineResultsLoading &&
          (timestamp && timestamps.includes(timestamp) ? (
            <ExtractionDoubleDateReportsCreatorContent timestamps={timestamps} />
          ) : (
            <CreatorDataColumn
              data={[]}
              isConfirmDisabled
              topContent={
                <>
                  <ReportsCreatorDataColumnDatesLabel no={firstDateReportsExtractionDataset ? 2 : 1} />
                  <DateSelect
                    {...{ timestamps, timestamp, changeTimestamp }}
                    forbiddenTimestamp={firstDateReportsExtractionDataset?.timestamp}
                  />
                </>
              }
            />
          ))}
      </>
    ) : (
      timestamp && timestamps.includes(timestamp) && <ExtractionSingleDateReportsCreatorContent />
    ))
  );
};
