import { Select } from 'ui';
import { INDICATORS_OPTION_NAME, TIMELINE_OPTION_NAME } from 'common/navigation/constants';
import { useViewOptions } from 'common/navigation/useViewOptions';

export const SnowViewOptionsSelect = () => {
  const { mapViewOption, changeViewOption, resetViewOption } = useViewOptions();

  const waterbodyViewOptions = [
    {
      value: INDICATORS_OPTION_NAME,
      label: 'indicators',
      disabled: false,
    },
  ];

  const viewOptionsSelectData = {
    options: waterbodyViewOptions,
    selectedOption: mapViewOption,
    initialStateLabel: TIMELINE_OPTION_NAME,
    name: 'view selection',
    minWidth: 130,
  };

  return <Select {...viewOptionsSelectData} onChange={changeViewOption} reset={resetViewOption} />;
};
