export const MAP_DEFAULT_CENTER = [0, 0];
export const MAP_MAX_ZOOM = 18;
export const MAP_MIN_ZOOM = 2;
export const MAP_INITIAL_ZOOM = 0;
export const MAP_DEFAULT_ZOOMING_TIME = 4;

export const MAP_SAMPLE_CENTER = [52.2, 21.2];
export const MAP_SAMPLE_ZOOM = 9;

export const MAP_HORIZONTAL_OFFSET_CORRECTION = -150;
