import { WaterActiveTab } from 'domain/water/enums';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { ExtractionActiveTab } from 'domain/extraction/enums';

export enum ReportType {
  COMPARE_DATES = '1',
  SINGLE_DATE = '2',
}

export enum ReportFunctionality {
  SEGMENTATION = GreeneryActiveTab.SEGMENTATION,
  VEGETATION = GreeneryActiveTab.VEGETATION,
  SURFACE = WaterActiveTab.SURFACE,
  TERRAIN = ExtractionActiveTab.TERRAIN,
  QUALITY = WaterActiveTab.QUALITY,
}
