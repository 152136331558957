import styled from 'styled-components';

import { Tag } from 'ui/atoms/tag';
import { areasListQueryService } from 'views/areas/areasList/areasListQueryService';
import { type AreaTags } from 'domain/areas/types';

interface AreaDataProps {
  tags: AreaTags;
}

export const AreaCardTags = ({ tags }: AreaDataProps) => {
  const tagsArray = Object.keys(tags);
  const { onAreaCardTagClick } = areasListQueryService.useOnAreaCardTagClick();

  return (
    <AreaCardTagsWrapper>
      {tagsArray.map((tag) => (
        <Tag text={tag} key={tag} onClick={() => onAreaCardTagClick(tag)} />
      ))}
    </AreaCardTagsWrapper>
  );
};

const AreaCardTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
