import { useState } from 'react';

import { Button } from 'ui/atoms/button';
import { Container } from 'ui/atoms/container';
import { ForgotPassword } from 'ui/organisms/forgotPassword';
import { useForgotPasswordService } from 'views/user/forgotPasswordService';

export const ResetPassword = () => {
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const forgotPasswordServiceData = useForgotPasswordService();

  const onCloseModal = () => {
    setResetPasswordModalOpen(false);
    forgotPasswordServiceData.handleTryAgainButtonClick();
  };

  return (
    <>
      <ForgotPassword
        isOpen={resetPasswordModalOpen}
        forgotPasswordServiceData={forgotPasswordServiceData}
        username=""
        handleClose={onCloseModal}
      />

      <Container mb={40} mt={26}>
        <Button variant="PRIMARY_SMALL" onClick={() => setResetPasswordModalOpen(true)}>
          Reset password
        </Button>
      </Container>
    </>
  );
};
