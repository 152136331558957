import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'common/navigation/routes';
import { useUserStore } from 'infrastructure/user/userStore';
import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { type SubscriptionRequestAction, type SubscriptionRequest } from 'domain/subscription/types';

export const useSubscriptionService = (handleError: (error: Error) => void, handleCleanup: () => void) => {
  const navigate = useNavigate();

  const { setClientSecret, setSessionId } = useUserStore();

  const { handleSubscriptionUpgrade: onSubscriptionUpgrade, isSubscriptionUpgrading } =
    subscriptionRepository.useUpgrade();
  const { onSubscriptionCreate, isSubscriptionCreating } = subscriptionRepository.useCreate();

  const onSubscriptionRequest = (payload: SubscriptionRequest, action: SubscriptionRequestAction) => {
    const handleSubscriptionRequest = action === 'CREATE' ? onSubscriptionCreate : onSubscriptionUpgrade;

    return handleSubscriptionRequest(payload, {
      onSuccess: (response) => {
        setClientSecret(response.data.client_secret);
        setSessionId(response.data.session_id);
        console.log(`${payload} request submitted successfully.`);
        handleCleanup();
        navigate(ROUTES.paymentProcess);
      },
      onError: (error) => {
        if (error instanceof Error) {
          handleError(error);
        }
      },
    });
  };

  return {
    onSubscriptionRequest,
    isSubscriptionRequesting: isSubscriptionUpgrading || isSubscriptionCreating,
  };
};
