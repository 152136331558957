import styled from 'styled-components';

import { Chevron } from 'ui';

interface HideShowButtonProps {
  isShown: boolean;
  showLabel?: string;
  hideLabel?: string;
  onClick: () => void;
}

export const HideShowButton = ({ isShown, showLabel = 'show', hideLabel = 'hide', onClick }: HideShowButtonProps) => (
  <Wrapper onClick={onClick} role="button" aria-label="Button that shows or hides some content">
    <Chevron isFlipped={!isShown} width={14} />
    <Label>{isShown ? hideLabel : showLabel}</Label>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  cursor: pointer;
  padding: 0 1.2em;
  border-radius: 15px;
  white-space: nowrap;
  color: white;

  &:hover {
    background-color: ${({ theme }) => theme.color.midGray};
  }
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-left: 1.2em;
`;
