import { Outlet, useNavigate } from 'react-router-dom';

import { Sidebar } from 'ui/sidebar';
import { AppLayout } from 'layout/appLayout/AppLayout';
import { AppHeader } from 'layout/appHeader';
import styled from 'styled-components';
import { ReturnButton } from 'ui';
import { ROUTES } from 'common/navigation/routes';

export const Subscriptions = () => {
  const navigate = useNavigate();

  const returnToAreasList = () => {
    navigate(`/#${ROUTES.areasList}`);
  };

  return (
    <AppLayout sidebar={<Sidebar variant="BARE" />} topBarless>
      <AppHeader />
      <Wrapper>
        <ReturnButton label="back to area list" onClick={returnToAreasList} visible />
        <Outlet />
      </Wrapper>
    </AppLayout>
  );
};

const Wrapper = styled.div`
  padding-top: 100px;
  height: 100%;

  h1 {
    font-size: 28px;
    line-height: 1.3;
    font-weight: 600;
    font-family: 'Barlow condensed', sans-serif;
  }
`;
