import styled from 'styled-components';

import { DEFAULT_BACKDROP_Z_INDEX } from 'common/constants';
import { Backdrop } from 'ui/atoms/backdrop';
import { LoadingSpinner } from 'ui/atoms/loadingSpinner';
import { Wrapper } from 'ui/atoms/wrapper';
import { Container } from 'ui/atoms/container';

export enum SpinnerState {
  DEFAULT = '',
  LOADING = 'Loading...',
  RENDERING = 'Rendering...',
}

export type LoadingVariant = 'DEFAULT' | 'LOADING' | 'RENDERING';

type LoadingProps = {
  variant?: LoadingVariant;
  zIndex?: number;
  leftOffset?: number;
  customMessage?: string;
};

export const Loading = ({ variant, zIndex, leftOffset, customMessage }: LoadingProps) => {
  return (
    <Backdrop zIndex={zIndex}>
      <Wrapper left={leftOffset} top={42}>
        <Container variant="CENTERING">
          <LoadingSpinner />
        </Container>
        <LoadingTextWrapper>{customMessage || SpinnerState[variant!]}</LoadingTextWrapper>
      </Wrapper>
    </Backdrop>
  );
};

const LoadingTextWrapper = styled.div`
  margin-top: 20px;
  z-index: 1100;
  min-height: 22px;
`;

Loading.displayName = 'Loading';
Loading.defaultProps = {
  variant: 'DEFAULT' as LoadingVariant,
  zIndex: DEFAULT_BACKDROP_Z_INDEX,
};
