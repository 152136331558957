import { useEffect, useState } from 'react';

import { type ValueAndLabel } from 'common/types';
import { useSurfaceMinerals } from 'views/exploration/useSurfaceMinerals';
import { useSamLayersVisibility } from 'views/exploration/minerals/useSurfaceLayersVisibility';

export const useSelectedMineral = () => {
  const { selectedMinerals, areMineralsLoading } = useSurfaceMinerals(1);
  const { classesVisibility } = useSamLayersVisibility();

  const options = selectedMinerals
    .filter(({ name }) => Object.keys(classesVisibility).includes(name))
    .map(({ name, label }) => ({ label, value: name }));

  const [selectedMineral, setSelectedMineral] = useState<ValueAndLabel | undefined>(undefined);

  useEffect(() => {
    if (
      options.length &&
      (!selectedMineral || !options.map(({ value }) => value).includes(String(selectedMineral.value)))
    ) {
      setSelectedMineral(options[0]);
    }

    if (!options.length) {
      setSelectedMineral(undefined);
    }
  }, [options, selectedMineral]);

  const selectedMineralUrl = selectedMinerals.find(({ name }) => name === String(selectedMineral?.value))?.url;

  return { selectedMineral, setSelectedMineral, options, selectedMineralUrl, areMineralsLoading };
};
