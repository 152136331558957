import styled from 'styled-components';

import { IconWrapper } from 'ui/atoms/iconWrapper';
import { LabeledCheckbox } from 'ui/molecules/labeledCheckbox';
import { ReactComponent as TemperatureIcon } from 'assets/svg/temperature.svg';
import { ReactComponent as PrecipitationIcon } from 'assets/svg/precipitation.svg';
import { useHandleMeteoDataUseCase } from './handleMeteoDataUseCase';
import { useGraphStore } from './graphStore';

interface GraphWeatherControlsProps {
  reportError: (message: string) => void;
}

export const GraphWeatherControls = ({ reportError }: GraphWeatherControlsProps) => {
  const additionalDataOptions = useGraphStore.use.additionalDataOptions();
  const { setAdditionalDataOptionUseCase: setAdditionalDataOption } = useHandleMeteoDataUseCase();

  return (
    <ControlsWrapper>
      <LabeledCheckboxWrapper>
        <LabeledCheckbox
          onChange={() => setAdditionalDataOption({ precipitation: !additionalDataOptions.precipitation }, reportError)}
          checked={!!additionalDataOptions.precipitation}
        >
          <CheckboxLabel title={'Precipitation (mm)'} icon={<PrecipitationIcon />} />
        </LabeledCheckbox>
      </LabeledCheckboxWrapper>
      <LabeledCheckboxWrapper>
        <LabeledCheckbox
          onChange={() => setAdditionalDataOption({ temperature: !additionalDataOptions.temperature }, reportError)}
          checked={!!additionalDataOptions.temperature}
        >
          <CheckboxLabel title={'Temperature (°C)'} icon={<TemperatureIcon />} />
        </LabeledCheckbox>
      </LabeledCheckboxWrapper>
    </ControlsWrapper>
  );
};

const CheckboxLabel = ({ icon, title }: { icon: JSX.Element; title: string }) => {
  return (
    <LabelWrapper>
      <IconWrapper iconWidth={16}>{icon}</IconWrapper>
      <LabelTitle>{title}</LabelTitle>
    </LabelWrapper>
  );
};

const ControlsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 10px;
`;

const LabeledCheckboxWrapper = styled.div`
  padding-bottom: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.color.whiteTransparent30};
`;

const LabelWrapper = styled.div`
  display: flex;
`;

const LabelTitle = styled.span`
  margin-left: 5px;
  margin-right: 20px;
  text-transform: initial;
`;
