import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayerManager/draggableLayersKeys';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { TileLayer } from 'components/map/layers/TileLayer';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { Loading } from 'ui/molecules/loading';

interface HillshadeLayerProps {
  url: string;
}

export const HillshadeLayer = ({ url }: HillshadeLayerProps) => {
  const isHillshadeVisible = useLayerManagerStore.use.isHillshadeVisible();
  const hillshadeOpacity = useLayerManagerStore.use.hillshadeOpacity();

  const { cogUrl, extentLatLngBounds, isInfoLoading } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildHillshadeCogUrl,
    url,
    {},
  );

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!cogUrl || !extentLatLngBounds) {
    return null;
  }

  return (
    <WithLayerIndex
      Component={TileLayer}
      props={{
        isVisible: isHillshadeVisible,
        layerName: LAYERS_KEYS.HILLSHADE,
        bounds: extentLatLngBounds,
        opacity: hillshadeOpacity,
        cogUrl: cogUrl,
      }}
      sectionId={LAYERS_KEYS.HILLSHADE}
    />
  );
};
