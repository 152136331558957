import { type ComponentType } from 'react';
import { segmentationDataService } from 'views/greenery/segmentation/segmentationDataService';
import { WithDailySegmentationCog } from 'views/greenery/segmentation/WithDailySegmentationCog';

interface Props<T> {
  Component: ComponentType<T & { cogUrl: string }>;
  props: T;
  timestamp: string;
}

export const WithDailySegmentationCogResult = <T,>({ Component, props, timestamp }: Props<T>) => {
  const { resultId } = segmentationDataService.useSegmentationResult(timestamp);

  if (!resultId) {
    return null;
  }

  return <WithDailySegmentationCog Component={Component} props={props} resultId={resultId} />;
};
