import { CreatorSimpleDataColumn } from 'ui/creator/creatorSimpleDataColumn';
import { type CreatorDataColumnData } from 'ui/creator/types';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';

export const ReportsCreatorMapOptions = () => {
  const isSatelliteVisible = useLayerManagerStore.use.isSatelliteVisible();
  const toggleSatelliteVisibility = useLayerManagerStore.use.toggleSatelliteVisibility();
  const isExtentVisible = useLayerManagerStore.use.isExtentVisible();
  const toggleExtentVisibility = useLayerManagerStore.use.toggleExtentVisibility();

  const data: CreatorDataColumnData[] = [
    {
      id: 1,
      label: 'satellite image',
      checked: isSatelliteVisible,
      onChange: toggleSatelliteVisibility,
    },
    {
      id: 2,
      label: 'extent',
      checked: isExtentVisible,
      onChange: toggleExtentVisibility,
    },
  ];

  return <CreatorSimpleDataColumn label="map options to include" data={data} />;
};
