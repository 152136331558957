import { ExplanationTile } from 'ui/creator/explanationTile';

export const ExpirationColumn = () => {
  return (
    <ExplanationTile
      header="Unable to request area"
      description={'Your subscription has expired'}
      accent="Contact our support team"
      url="mailto:support@terraeye.co"
    />
  );
};
