import { useState } from 'react';

import { DownloadModalContent } from 'components/downloadModal/DownloadModalContent';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { useMlLayersVisibility, useMtmfLayersVisibility, useSamLayersVisibility } from './useSurfaceLayersVisibility';
import { useGetExplorationFileNameEnding } from '../useGetExplorationFileNameEnding';
import { useSurfaceMinerals } from '../useSurfaceMinerals';
import { useIndicesLayersVisibility, useIndices } from '../indices';

export const MineralsDownloadModalContent = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const fileNameEnding = useGetExplorationFileNameEnding();

  const { classesVisibility: samClassesVisibility } = useSamLayersVisibility();
  const { selectedMinerals: samMinerals } = useSurfaceMinerals(1);
  const selectedSamMinerals = samMinerals.filter(({ name }) => samClassesVisibility[name]);

  const { classesVisibility: mtmfClassesVisibility } = useMtmfLayersVisibility();
  const { selectedMinerals: mtmfMinerals } = useSurfaceMinerals(2);
  const selectedMtmfMinerals = mtmfMinerals.filter(({ name }) => mtmfClassesVisibility[name]);

  const { classesVisibility: mlClassesVisibility } = useMlLayersVisibility();
  const { selectedMinerals: mlMinerals } = useSurfaceMinerals(3);
  const selectedMlMinerals = mlMinerals.filter(({ name }) => mlClassesVisibility[name]);

  const { classesVisibility: indicesClassesVisibility } = useIndicesLayersVisibility();
  const { indicesResults: indices } = useIndices();
  const selectedIndices = indices.filter(({ name }) => indicesClassesVisibility[name]);

  const samFilesList = selectedSamMinerals.map(({ id, url, label, name }) => ({
    id: String(id),
    url: titilerApiAdapter.buildGenericPreviewTifUrl(getAzureBlobStorageUrl(url)),
    label,
    name: `${name}_sam${fileNameEnding}`,
    extension: 'tiff',
  }));

  const mtmfFilesList = selectedMtmfMinerals.map(({ id, url, label, name }) => ({
    id: String(id),
    url: titilerApiAdapter.buildGenericPreviewTifUrl(getAzureBlobStorageUrl(url)),
    label,
    name: `${name}_mtmf${fileNameEnding}`,
    extension: 'tiff',
  }));

  const mlFilesList = selectedMlMinerals.map(({ id, url, label, name }) => ({
    id: String(id),
    url: titilerApiAdapter.buildGenericPreviewTifUrl(getAzureBlobStorageUrl(url)),
    label,
    name: `${name}_ml${fileNameEnding}`,
    extension: 'tiff',
  }));

  const indicesFilesList = selectedIndices.map(({ id, url, label_name, name }) => ({
    id: String(id),
    url: titilerApiAdapter.buildGenericPreviewTifUrl(getAzureBlobStorageUrl(url)),
    label: label_name,
    name: `${name}_indices${fileNameEnding}`,
    extension: 'tiff',
  }));

  const downloadData = [
    {
      id: 1,
      title: 'Minerals - SAM',
      list: samFilesList,
    },
    {
      id: 2,
      title: 'Minerals - MTMF',
      list: mtmfFilesList,
    },
    {
      id: 3,
      title: 'Minerals - ML',
      list: mlFilesList,
    },
    {
      id: 4,
      title: 'Geological Spectral Indices',
      list: indicesFilesList,
    },
  ].filter((item) => item.list.length);

  return (
    <DownloadModalContent
      {...{
        downloadData,
        isDownloading,
        fileNameEnding,
        setIsDownloading,
      }}
    />
  );
};
