import { Timeline } from 'components/Timeline/Timeline';
import { useTimeline } from 'components/Timeline/useTimeline';
import { waterRepository } from 'infrastructure/water/waterRepository';

interface SnowCoverTimelineProps {
  areaName: string;
  visible: boolean;
}

export const SnowCoverTimeline = ({ areaName, visible }: SnowCoverTimelineProps) => {
  const { results } = waterRepository.useFetchSnowCoverResults(areaName);

  const dates = results.map(({ img_date }) => img_date);

  const timestamps = Array.from(new Set(dates));

  const { filteredTimestamps, firstDate, lastDate, rangeValue, selectedTimestamps, setRangeValue } = useTimeline(
    timestamps,
    visible,
  );

  return (
    <>
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          visible: visible,
        }}
      />
    </>
  );
};
