import { type ReactNode } from 'react';
import styled from 'styled-components';
import { BaseModal } from 'ui/modal';

interface IssueProps {
  issueProps: {
    header: string;
    text?: string;
    okButtonValue?: string;
    noButtons?: boolean;
    handleOkButtonClick: () => void;
  };
  children?: ReactNode;
  isOpen: boolean;
  closeModal: () => void;
}

export const ForgotPasswordBody = ({ issueProps: { header, text }, children, isOpen, closeModal }: IssueProps) => {
  return (
    <BaseModal {...{ isOpen, closeModal }} maxWidth={425}>
      <Container>
        <Header>{header}</Header>
        <Text>{text}</Text>
        {children}
      </Container>
    </BaseModal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h4`
  font-size: 24px;
  font-weight: 600;
  font-family: 'Barlow Condensed', sans-serif;
  line-height: 24px;
  margin-right: 50px;
  margin-bottom: 14px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin: 0 10px 0 0;
  word-break: break-word;
`;
