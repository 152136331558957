import { type GraphData } from 'components/graph/types';
import { getCoordinateString } from 'components/graph/helpers';
import { type OpenedMarkerData } from '../types';
import { beginningOfDygraphData, endOfDygraphData } from '../constants';

type stringFromRegexSearch = string | undefined;
type numberFromRegexSearch = number | undefined;
interface getDygraphInfoReturnedInterface {
  dygraph: string;
  isReferencePoint: boolean;
  dygraphInfo: string;
  coordinateString: string;
}

export function getDygraphInfo(openedMarkerData: OpenedMarkerData): getDygraphInfoReturnedInterface {
  const dygraph = openedMarkerData.properties.description.value as string;
  const coordinateString = getCoordinateString('Point', openedMarkerData.geometry.coordinates);
  const isReferencePoint = openedMarkerData.properties['icon'] === 'star.png';
  const dygraphInfo = dygraph.substring(
    0,
    dygraph.indexOf(
      isReferencePoint
        ? '  <br /> *Double click'
        : "<script type='text/javascript' src='../../../dygraph-combined.js'></script>",
    ),
  );
  return { dygraph, isReferencePoint, dygraphInfo, coordinateString };
}

export function getVelocity(dygraphInfo: string): stringFromRegexSearch {
  const re = /(Mean LOS velocity \[cm\/year]:)(.*?)+(?=<br \/>)/g;
  return dygraphInfo.match(re)?.[0];
}

export function getCumulativeDisplacement(dygraphInfo: string): numberFromRegexSearch {
  const re = /(?<=Cumulative displacement \[cm\]:)(.*?)+(?=<br \/>)/g;
  const valueString = dygraphInfo.match(re)?.[0];
  return valueString ? +valueString : undefined;
}

export function getTemporalCoherence(dygraphInfo: string): stringFromRegexSearch {
  const re = /(?<=Temporal coherence: )(.*?)+(?=<br \/>)/g;
  return dygraphInfo.match(re)?.[0];
}

export function getDygraphData(dygraph: string) {
  const regex = /\\n/g;

  const diagGeoData = dygraph
    .substring(dygraph.lastIndexOf(beginningOfDygraphData) - 1, dygraph.indexOf(endOfDygraphData) + 2)
    .replace(regex, '\n');

  return diagGeoData;
}

export function getDatesFromDygraphStringValue(dygraph: string) {
  const diagGeoData = getDygraphData(dygraph).replace(/[A-z]/g, '');
  // eslint-disable-next-line
  return diagGeoData.match(/[0-9]{4}([\-\/ \.])[0-9]{2}[\-\/ \.][0-9]{2}/g);
}

export function parseGraphDataFromCSV(str: string) {
  const regex = /(\d{4}-\d{2}-\d{2}),\s*(-?\d+\.\d+)/g;
  const datesValues: GraphData = [];
  const matches = str.matchAll(regex);

  for (const match of Array.from(matches)) {
    const [, dateString, valueString] = match;
    const date = new Date(dateString);
    const value = parseFloat(valueString);
    datesValues.push([date, value]);
  }

  return datesValues;
}
