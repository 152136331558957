import { useEffect } from 'react';
import styled from 'styled-components';

import { Select, InfoPoint, Container } from 'ui';
import { type ValueAndLabel } from 'ui/types';
import { FoldableSectionContentWrapper } from 'components';
import { CompositesProviderFieldBeta } from './CompositesProviderFieldBeta';
import { useCompositesStore } from '../compositesStore';

interface Props {
  providersWithOptions: {
    id: number;
    name: string;
    label: string;
    options: ValueAndLabel[];
    description: React.ReactNode;
  }[];
}

export const CompositesProvidersListBeta = ({ providersWithOptions }: Props) => {
  const selectedProviderName = useCompositesStore.use.selectedProviderName();
  const setSelectedProviderName = useCompositesStore.use.setSelectedProviderName();
  const areCompositesBetaVisible = useCompositesStore.use.areCompositesBetaVisible();
  const toggleCompositesBetaVisibility = useCompositesStore.use.toggleCompositesBetaVisibility();
  const providersOpacityDictionary = useCompositesStore.use.providersOpacityDictionary();
  const setProviderOpacity = useCompositesStore.use.setProviderOpacity();
  const compositesSelection = useCompositesStore.use.compositesSelection();
  const setCompositesSelection = useCompositesStore.use.setCompositesSelection();

  const onProviderChange = (name: string) => {
    setSelectedProviderName(name);
    !areCompositesBetaVisible && toggleCompositesBetaVisibility();
  };

  const onOpacityChange = (provider: string, value: number | number[]) => {
    if (typeof value === 'number') {
      setProviderOpacity(provider, value);
    }
  };

  const onCompositeChange = (option: ValueAndLabel) => {
    if (selectedProviderName) {
      setCompositesSelection({ [selectedProviderName]: String(option.value) });
    }
  };

  const providersList = providersWithOptions.map(({ id, name, label, options, description }) => {
    const isSelected = areCompositesBetaVisible && name === selectedProviderName;
    const opacity = typeof providersOpacityDictionary[name] === 'number' ? providersOpacityDictionary[name] : 1;
    const selectedOption = options.find(({ value }) => value === compositesSelection[name]);

    return { id, name, label, isSelected, opacity, options, selectedOption, description };
  });

  useEffect(() => {
    const selectedProviderOptions = providersList.find(({ name }) => name === selectedProviderName)?.options;

    if (
      selectedProviderName &&
      selectedProviderOptions?.length &&
      (!compositesSelection[selectedProviderName] ||
        !selectedProviderOptions.some(({ value }) => value === compositesSelection[selectedProviderName]))
    ) {
      setCompositesSelection({ [selectedProviderName]: String(selectedProviderOptions[0].value) });
    }
  }, [selectedProviderName, compositesSelection, providersList, setCompositesSelection]);

  return (
    <FoldableSectionContentWrapper>
      <ProvidersList>
        {providersList.map(({ id, name, label, isSelected, opacity, options, selectedOption, description }) => (
          <CompositesProviderFieldBeta
            key={id}
            {...{ name, label, onProviderChange, onOpacityChange, isSelected, opacity }}
          >
            band<br></br>combo
            <Select
              options={options}
              selectedOption={selectedOption}
              onChange={onCompositeChange}
              initialStateLabel="Select Band Combo"
              name="composite-select"
            />
            <InfoPoint name={name} tooltipContent={<Container width={270}>{description}</Container>} />
            {/* TODO: in the future add info point with description assigned to each composite in db */}
          </CompositesProviderFieldBeta>
        ))}
      </ProvidersList>
    </FoldableSectionContentWrapper>
  );
};

const ProvidersList = styled.ul`
  list-style: none;
  padding: 0 14px;
`;
