import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import env from 'react-dotenv';

type PaymentProviderProps = {
  children: React.ReactNode;
  clientSecret: string | null;
};

const stripePublishableKey = env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const PaymentProvider = ({ clientSecret, children }: PaymentProviderProps) => {
  if (!stripePublishableKey) {
    throw new Error("REACT_APP_STRIPE_PUBLISHABLE_KEY wasn't found");
  }

  const stripePromise = loadStripe(stripePublishableKey);

  if (!clientSecret) {
    throw new Error("Client secret wasn't provided");
  }

  return (
    <EmbeddedCheckoutProvider options={{ clientSecret }} stripe={stripePromise}>
      {children}
    </EmbeddedCheckoutProvider>
  );
};
