import { useEffect } from 'react';

import { ForgotPasswordBody } from 'ui/organisms/forgotPassword/ForgotPasswordBody';
import { Loading } from 'ui/molecules/loading';
import { type ForgotPasswordServiceData } from './types';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { ForgotPasswordResolution } from './ForgotPasswordResolution';

interface ForgotPasswordProps {
  username: string;
  forgotPasswordServiceData: ForgotPasswordServiceData;
  isOpen: boolean;
  handleClose: () => void;
}

export const ForgotPassword = ({
  username,
  forgotPasswordServiceData: {
    isLoading,
    issueProps,
    resetPasswordRequestStatus,
    inputElementProps,
    formElement,
    disableSubmitButton,
    setDefaultEmail,
    handleTryAgainButtonClick,
    handleCaptchaReset,
  },
  isOpen,
  handleClose: handleClosePopUp,
}: ForgotPasswordProps) => {
  const handleClose = () => {
    handleClosePopUp();
    handleCaptchaReset();
  };

  useEffect(() => {
    username && setDefaultEmail(username);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  return (
    <ForgotPasswordBody {...{ isOpen, closeModal: handleClose, issueProps }} closeModal={handleClose}>
      {isLoading && <Loading />}
      {resetPasswordRequestStatus === 'IDLE' ? (
        <ForgotPasswordForm
          inputElementProps={inputElementProps}
          formElement={formElement}
          disableSubmitButton={disableSubmitButton}
          handleClose={handleClose}
          handleOkButtonClick={issueProps.handleOkButtonClick}
        />
      ) : (
        <ForgotPasswordResolution
          variant={resetPasswordRequestStatus}
          onTryAgainButtonClick={handleTryAgainButtonClick}
        />
      )}
    </ForgotPasswordBody>
  );
};
