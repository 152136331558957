import { useGetIsComparisonActive } from 'common/navigation/hooks';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { ShareModalControlButton } from 'components/downloadModal/legacyShareModal';
import { ColorScale } from 'ui/colorScale';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { WATER_COMPARISON_COLOR_SCALE_RANGE_NAMES } from 'domain/water/configs';
import { WATER_AREA_COMPARISON_COLORMAP } from 'domain/water/colors';
import { useWaterAreaLifeCycleUseCase } from './waterAreaLifeCycleUseCase';
import { WaterAreaInfoArea } from './WaterAreaInfoArea';
import { WaterAreaLayerManager } from './WaterAreaLayerManager';
import { WaterAreaTimeline } from './WaterAreaTimeline';
import { useGetWaterAreaRemappedResults } from './useGetWaterAreaRemappedResults';
import { WaterAreaComparator } from './comparator';
import { WaterbodiesSelect, WaterbodiesResetButton } from '../waterbodies';
import { WaterViewOptionsSelect } from '../viewOptions';
import { DataProvidersSelect } from '../dataProviders';

export const WaterArea = () => {
  const areReportsActive = useGetAreReportsActiveUseCase();
  const { isComparisonActive } = useGetIsComparisonActive();
  const { remappedResults, areRemappedResultsLoading } = useGetWaterAreaRemappedResults();

  useWaterAreaLifeCycleUseCase();

  return (
    <AreaDetailsLayout
      returnButton={<WaterbodiesResetButton />}
      legend={
        isComparisonActive && !areReportsActive ? (
          <ColorScale
            colormap={WATER_AREA_COMPARISON_COLORMAP}
            labels={WATER_COMPARISON_COLOR_SCALE_RANGE_NAMES}
            segmented
            divided
          />
        ) : null
      }
      toolbarContent={
        <>
          <WaterAreaInfoArea />
          {!areReportsActive && (
            <>
              <WaterbodiesSelect />
              <DataProvidersSelect results={remappedResults} areResultsLoading={areRemappedResultsLoading} />
              <WaterViewOptionsSelect />
            </>
          )}
        </>
      }
      toolboxContent={
        <>
          {!areReportsActive && <LayerManagerControlButton />}
          {!areReportsActive && !isComparisonActive && <ShareModalControlButton />}
        </>
      }
    >
      {isComparisonActive && !areReportsActive && <WaterAreaComparator />}
      {!areReportsActive && (
        <WaterAreaLayerManager isComparisonActive={isComparisonActive} isReportLayerManager={areReportsActive} />
      )}
      <WaterAreaTimeline />
    </AreaDetailsLayout>
  );
};
