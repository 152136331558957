import { createRef, type MouseEventHandler, type RefObject } from 'react';
import styled from 'styled-components';

import { getDottedDateFromUnixEpochTime } from 'common/utils/datetime';
import { type TimelineLabel, type TimelinePoint } from 'components/Timeline/types';
import { TIMELINE_SIDE_BORDERS } from 'components/Timeline/constants';
import { getQuarterLabelFromDate } from 'components/Timeline/helpers';

interface SliderProps {
  trackDates: TimelineLabel[] | undefined;
  selectedDate: number | undefined;
  timelinePoints: TimelinePoint[] | undefined;
  handlePosition: number | undefined;
  handleTrackClick: (e: MouseEvent) => void;
  handleTrackHandleMouseDown: (trackRef: RefObject<HTMLDivElement>, e: MouseEvent) => void;
  quarterly?: boolean;
}

export const Slider = ({
  trackDates,
  selectedDate,
  timelinePoints,
  handlePosition,
  handleTrackClick,
  handleTrackHandleMouseDown,
  quarterly,
}: SliderProps) => {
  const trackRef = createRef<HTMLDivElement>();

  const onTrackMouseDown = (e: MouseEvent) => handleTrackHandleMouseDown(trackRef, e);

  return (
    <SliderBody role="slider" aria-label="timeline slider">
      <Track ref={trackRef} onClick={handleTrackClick as unknown as MouseEventHandler<HTMLDivElement>} />
      <>
        {timelinePoints && (
          <TrackPointsWrapper>
            {timelinePoints.map((point, id) => (
              <TrackPoint key={id} positionX={point.positionX} data-testid="trackpoint" />
            ))}

            {selectedDate && handlePosition !== undefined && (
              <TrackHandle
                data-testid="main-track-handle"
                positionX={handlePosition}
                onMouseDown={onTrackMouseDown as unknown as MouseEventHandler}
              >
                <HandleDate>
                  {quarterly
                    ? getQuarterLabelFromDate(getDottedDateFromUnixEpochTime(selectedDate))
                    : getDottedDateFromUnixEpochTime(selectedDate)}
                </HandleDate>
              </TrackHandle>
            )}
          </TrackPointsWrapper>
        )}

        {trackDates && (
          <TrackDates quarterly={quarterly}>
            {trackDates.map((trackDate, id) => (
              <TrackDate key={id} positionX={trackDate.positionX}>
                {trackDate.date}
              </TrackDate>
            ))}
          </TrackDates>
        )}
      </>
    </SliderBody>
  );
};

const SliderBody = styled.div`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  margin: 0 16px 11px;
  font-weight: 600;
  font-size: 10px;
  width: 100%;
  height: 8px;
  z-index: 1000;
`;

export const Track = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  opacity: 0.3;
  cursor: pointer;
  border-radius: 3px;
  z-index: 1000;
`;

const TrackPointsWrapper = styled.div`
  margin: 0 ${TIMELINE_SIDE_BORDERS * 100}%;
  position: relative;
  height: 100%;
`;

type PositionOnTrackProps = {
  positionX: number | undefined;
};

const TrackPoint = styled.div<PositionOnTrackProps>`
  position: absolute;
  width: 2px;
  left: ${({ positionX }) => (positionX ? positionX : 0)}%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  opacity: 0.5;
  transform: translateX(-1px);
`;

export const TrackHandle = styled.div<PositionOnTrackProps>`
  position: absolute;
  top: 0;
  left: ${({ positionX }) => positionX && positionX}%;
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  transform: translate(-50%, -5px);
  border-radius: 50%;
  z-index: 1500;
`;

const HandleDate = styled.div`
  position: absolute;
  top: 23px;
  left: -19px;
  width: 60px;
  height: 20px;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  line-height: 2;
  text-align: center;
  border-radius: 4px;
`;

const TrackDates = styled.div<{ quarterly?: boolean }>`
  position: absolute;
  display: flex;
  top: 18px;
  right: ${TIMELINE_SIDE_BORDERS * 100}%;
  left: ${TIMELINE_SIDE_BORDERS * 100}%;
`;

const TrackDate = styled.div<{ positionX?: number }>`
  margin: 0 4px;
  left: ${({ positionX }) => positionX}%;
  position: ${({ positionX }) => (positionX ? 'absolute' : 'initial')};
  transform: translateX(-50%);
`;
