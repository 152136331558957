import styled from 'styled-components';

export const GraphWaterInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
`;

export const GraphWaterInfoTitleWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
