import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Select } from 'ui';
import { type ValueAndLabel } from 'common/types';
import { useLayerManagerStore } from 'components';
import { useVegetationIndicators } from 'views/greenery/vegetation/indicators/useVegetationIndicators';
import { GreeneryActiveTab } from 'domain/greenery/enums';

export const VegetationIndicatorsSelect = () => {
  const { indicatorOptions, selectedIndicator, areIndicatorsLoading, changeIndicator } = useVegetationIndicators();

  const { pathname } = useLocation();
  const isVegetationTab = pathname.includes(GreeneryActiveTab.VEGETATION);

  useEffect(() => {
    if (!selectedIndicator && isVegetationTab && indicatorOptions[0]?.value) {
      setTimeout(() => {
        changeIndicator(indicatorOptions[0].value);
      }, 500);
    }
  }, [JSON.stringify(indicatorOptions.map(({ value }) => value))]); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: refactor when mercedes data will be handled
  const layersVisibility = useLayerManagerStore.use.layersVisibility();
  const isSpecialModeActive = layersVisibility['pleiades'] || layersVisibility['planet_scope'];
  const filteredOptions = indicatorOptions.filter(({ value }) => value === 'ndvi');
  // TODO

  const indicatorsSelectData = {
    options: isSpecialModeActive ? filteredOptions : indicatorOptions,
    selectedOption: indicatorOptions.find(({ value }) => String(value) === String(selectedIndicator?.id)),
    initialStateLabel: 'Select Greenery Indicator',
    noOptionsLabel: 'No Greenery Indicators',
    isFetching: areIndicatorsLoading,
    minWidth: 130,
  };

  const handleIndicatorChange = (option: ValueAndLabel) => {
    changeIndicator(option.value);
  };

  return <Select {...indicatorsSelectData} onChange={handleIndicatorChange} name="greenery indicator selection" />;
};
