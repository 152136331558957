import { useQueryParameter, useAoiNumber } from 'common/navigation/hooks';
import { DATE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { formatDate } from 'common/utils/datetime';
import { DEFAULT_DATE_FORMAT_DASH } from 'common/constants/datetime';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { ShareModalContent } from './ShareModalContent';
import { type DownloadedFile } from '../types';
import { DownloadModal } from '../DownloadModal';

interface ShareModalProps {
  links: string[];
  activeTab: string;
  getReadyLinks?: () => DownloadedFile[] | Promise<DownloadedFile[]>;
}

export const ShareModal = ({ links, activeTab }: ShareModalProps) => {
  const date = useQueryParameter(DATE_QUERY_PARAMETER);
  const areaId = useAoiNumber();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const fileName = `${areaOfInterestDetails?.id}_${activeTab}${
    date ? `_${formatDate(date, DEFAULT_DATE_FORMAT_DASH)}` : ''
  }`;

  return (
    <DownloadModal maxWidth={1000} title="Share & Download">
      <ShareModalContent fileLinks={links} fileType="tiff" fileName={fileName} />
    </DownloadModal>
  );
};
