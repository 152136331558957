import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import { VIEW_UPDATER_ANIMATION_DEFAULT_DURATION, VIEW_UPDATER_ANIMATION_DEFAULT_LINEARITY } from 'common/constants';
import { useQueryParameter } from 'common/navigation/hooks';
import { useMapStore } from 'components/map/mapStore';
import { LAT_LNG_QUERY_PARAMETER } from 'common/navigation/queryParams';

interface FrameProps {
  areaId: number;
  latLngs?: number[][];
}

export const LeafletMapViewCenteringFrame = ({ areaId, latLngs }: FrameProps) => {
  const map = useMap();

  const mapAlign = useMapStore.use.alignMap();

  const centerFromUrl = useQueryParameter(LAT_LNG_QUERY_PARAMETER);

  useEffect(() => {
    let polygon = L.polygon(latLngs, { color: 'transparent', pane: 'mapCenteringFrame' });

    if (mapAlign || !centerFromUrl) {
      polygon.addTo(map);
      map.flyToBounds(
        polygon.getBounds({
          animate: true,
          duration: VIEW_UPDATER_ANIMATION_DEFAULT_DURATION,
          easeLinearity: VIEW_UPDATER_ANIMATION_DEFAULT_LINEARITY,
        }),
      );

      return () => {
        polygon.remove();
        polygon = null;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapAlign, areaId]);

  return null;
};
