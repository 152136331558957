import { type AxiosInstance } from 'axios';
import mem from 'mem';

import { LOCAL_STORAGE_ACCESS_TOKEN_NAME, TOKEN_REFRESH_MAX_AGE } from 'common/constants/services';
import { USER_API_URLS } from 'infrastructure/user/userApiUrls';
import { localStorageService } from 'services/localStorageService';
import { isRefreshTokenResponseValid } from 'domain/user/typeguards';

const maxAge = TOKEN_REFRESH_MAX_AGE;

export const refreshTokenHelper = async (axiosInstance: AxiosInstance) => {
  const uri = axiosInstance.getUri();

  try {
    const response = await axiosInstance.post(USER_API_URLS.tokenRefresh, undefined, {
      withCredentials: true,
      baseURL: uri !== '/api/v1' ? '/api/v1' : undefined,
    });

    if (!isRefreshTokenResponseValid(response)) {
      throw new Error('invalid token refresh response...');
    }

    localStorageService.setValue(LOCAL_STORAGE_ACCESS_TOKEN_NAME, response.data.access);

    return response.data.access;
  } catch (error) {
    return;
  }
};

export const memoizedRefreshToken = mem(refreshTokenHelper, {
  maxAge,
});
