import chroma from 'chroma-js';

import { Loading } from 'ui/molecules/loading';
import { DataLayer } from 'components/map';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { type MoistureIndicator, type MoistureResult } from 'domain/water/types';
import { useMoistureLayersVisibility } from './useMoistureLayersVisibility';

export const MoistureMapMultiDataLayer = ({
  result,
  indicator,
}: {
  result: MoistureResult;
  indicator: MoistureIndicator;
}) => {
  const { classesVisibility } = useMoistureLayersVisibility();

  const colormapArray = [
    [
      [indicator.min_value, indicator.max_value],
      [...chroma(result.color).rgb(), 255],
    ],
  ];

  const titilerUrl = titilerApiAdapter.buildGenericMonocolorCogUrl(getAzureBlobStorageUrl(result.url), {
    expression: 'b1',
    colormap: JSON.stringify(colormapArray),
  });

  const { extentLatLngBounds, isInfoLoading } = useGetExtentPolygon(result.url);

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !extentLatLngBounds) {
    return null;
  }

  return (
    <DataLayer
      isVisible={!!classesVisibility[result.name]}
      layerName={'moistureMultiLayer'}
      zIndex={480}
      cogUrl={titilerUrl}
      bounds={extentLatLngBounds}
    />
  );
};
