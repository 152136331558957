import { type LayerRange } from 'common/types';
import { buildGradientColormapArray } from 'common/utils/color';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { SPECTRAL_COLORMAP } from 'domain/exploration/colors';
import { useLineamentsLayerManagerStore } from './layerManager/lineamentsLayerManagerStore';
import { ExplorationMapLayer } from '../ExplorationMapLayer';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

interface LineamentDensityMapLayerProps {
  layerRange: LayerRange;
  name: string;
  url: string;
  isVisible: boolean;
  threshold: number;
}

export const LineamentDensityMapLayer = ({
  layerRange,
  name,
  url,
  isVisible,
  threshold,
}: LineamentDensityMapLayerProps) => {
  const opacity = useLineamentsLayerManagerStore.use.lineamentDensityOpacity();

  const expression = `((b1>=${threshold})&(b1<=${layerRange.max}))*b1`;
  const colormapArray = buildGradientColormapArray(SPECTRAL_COLORMAP, layerRange.min, layerRange.max);

  return (
    <WithLayerIndex
      Component={ExplorationMapLayer}
      props={{
        name,
        url: getAzureBlobStorageUrl(url),
        isVisible,
        opacity,
        builder: titilerApiAdapter.buildGenericMonocolorCogUrl,
        params: { expression, colormap: JSON.stringify(colormapArray) },
      }}
      sectionId={EXPLORATION_LAYERS_KEYS.LINEAMENTS}
      layerId={1}
    />
  );
};
