import { useRef, useState } from 'react';
import { type ReCAPTCHA } from 'react-google-recaptcha';

export const useCaptchaValidationService = () => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const onCaptchaValidate = () => {
    const token = captchaRef.current?.getValue();

    if (!token) {
      setCaptchaToken(null);
      return;
    }

    setCaptchaToken(token);
  };

  const onCaptchaExpiry = () => setCaptchaToken(null);

  return { captchaRef, captchaToken, onCaptchaValidate, onCaptchaExpiry };
};
