import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ChatListItem } from 'ui/chat/chatListItem';
import type { Message } from 'ui/chat/types';
import './markdownOverride.css';
import { type Status } from 'common/types';

interface ChatListProps {
  messages: Message[];
  status: Status;
  isChatFullscreen: boolean;
}

export const ChatList = ({ isChatFullscreen, messages, status }: ChatListProps) => {
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    listRef.current && listRef.current.scrollIntoView({ block: 'end' });
  }, [status, messages.length, isChatFullscreen]);

  return (
    <StyledListWrapper>
      <StyledList ref={listRef}>
        {messages.map((m, index) => (
          <ChatListItem
            variant={m.role === 'user' ? 'PRIMARY' : 'SECONDARY'}
            key={index}
            content={m.displayedContent}
          />
        ))}
        {status === 'loading' && <ChatListItem variant="SECONDARY" content="..." isLoading />}
        {status === 'error' && <ChatListItem variant="ERROR" content="Something went wrong. Try again." />}
      </StyledList>
    </StyledListWrapper>
  );
};

const StyledListWrapper = styled.div`
  max-width: 750px;
  width: 100%;
  margin-inline: auto;
  color: ${({ theme }) => theme.color.white};
`;

const StyledList = styled.ul<{ width?: number }>`
  padding: 0 0 20px;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
