import styled from 'styled-components';

import { type AreaType, type AreaEnvironmentsTypes } from 'common/navigation/types';
import { Tooltip, AreaTypeIcon, IconButton, Container } from 'ui';
import { AreaInfoEnvironmentsIcons } from 'domain/areas/AreaInfoEnvironmentsIcons';

interface Props {
  type: AreaType;
  environments: AreaEnvironmentsTypes[];
}

export const AreaDataBadge = ({ type, environments }: Props) => {
  const areaDestination = type === 'M' ? 'Environment Monitoring' : type === 'P' ? 'Mineral Prospecting' : null;
  const validEnvironments = environments.filter((env) => env in AreaInfoEnvironmentsIcons);

  if (!areaDestination && !validEnvironments.length) {
    return;
  }

  const environmentsLabels = validEnvironments.map((env) => AreaInfoEnvironmentsIcons[env].description);
  const areaAvailableData = environmentsLabels.length ? `Available data: ${environmentsLabels.join(', ')}` : null;

  return (
    <Tooltip
      content={
        <TooltipContent>
          {areaDestination && <TooltipContentTitle>{areaDestination}</TooltipContentTitle>}
          {areaAvailableData}
        </TooltipContent>
      }
      interactive
    >
      <Container data-testid="area-data-badge">
        <AreaTypeIcon type={type} rounded={!validEnvironments.length} />
        <EnvironmentIconsWrapper>
          <Container gap={4}>
            {validEnvironments.map((env) => (
              <IconButton
                key={env}
                icon={AreaInfoEnvironmentsIcons[env].icon}
                size={22}
                $iconSize={AreaInfoEnvironmentsIcons[env].$iconSize}
                variant="OVERVIEW_NO_HOVER"
              />
            ))}
          </Container>
        </EnvironmentIconsWrapper>
      </Container>
    </Tooltip>
  );
};

const TooltipContent = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.color.white};
`;

const TooltipContentTitle = styled.h4`
  font-weight: 600;
`;

const EnvironmentIconsWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.selectGray};
  padding: 0 8px 0 4px;
  border-radius: 0 20px 20px 0;
  pointer-events: none;
`;
