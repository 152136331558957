import { type ReactNode } from 'react';
import styled from 'styled-components';

import { CreatorColumnWrapper } from 'ui/creator/creatorDataColumn/CreatorColumnWrapper';
import { CreatorSimpleDataColumn } from 'ui/creator/creatorSimpleDataColumn';
import { SpinOverlay } from 'ui/creator/spinOverlay';
import { SELECTION_BUTTON_HEIGHT } from 'ui/atoms/selectionButton/SelectionButton';
import { SelectionButton } from 'ui/atoms/selectionButton';
import { type CreatorDataColumnData } from 'ui/creator/types';

const TOP_CONTENT_HEIGHT = 60;
export type CreatorDataColumnState = 'DEFAULT' | 'SAVING' | 'SAVED';

interface CreatorDataColumnProps {
  children?: ReactNode;
  topContent?: ReactNode;
  data: CreatorDataColumnData[];
  state?: CreatorDataColumnState;
  isLoading?: boolean;
  isConfirmDisabled?: boolean;
  handleConfirmClick?: () => void;
}

export const CreatorDataColumn = ({
  children,
  topContent,
  data,
  state = 'DEFAULT',
  isLoading,
  isConfirmDisabled,
  handleConfirmClick,
}: CreatorDataColumnProps) => (
  <CreatorColumnWrapper width={240}>
    {state !== 'DEFAULT' && (
      <BottomOffset>
        <SpinOverlay variant={state} />
      </BottomOffset>
    )}
    <TopContent>{topContent}</TopContent>
    <Wrapper>
      <CreatorSimpleDataColumn data={data} isLoading={isLoading}>
        {children}
      </CreatorSimpleDataColumn>
    </Wrapper>
    <SelectionButton variant={state} onClick={handleConfirmClick} disabled={isConfirmDisabled} />
  </CreatorColumnWrapper>
);

const BottomOffset = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: ${SELECTION_BUTTON_HEIGHT}px;
  left: 0;
`;

const TopContent = styled.div`
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  padding-right: 13px;
  padding-left: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.blackTransparent20};
`;

const Wrapper = styled.div`
  height: calc(100% - ${TOP_CONTENT_HEIGHT + SELECTION_BUTTON_HEIGHT}px);
`;

CreatorDataColumn.displayName = 'CreatorDataColumn';
