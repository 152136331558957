import { Backdrop } from 'ui/atoms/backdrop';
import { LoginSidebarConnected } from 'views/user/LoginSidebarConnected';

export const LoginPrompt = () => {
  return (
    <>
      <LoginSidebarConnected redirect={false} />
      <Backdrop zIndex={1500} />
    </>
  );
};
