/* eslint-disable @typescript-eslint/no-explicit-any */
import { Polygon } from 'react-leaflet';
import chroma from 'chroma-js';

import { Layer } from 'components/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { extractionRepository } from 'infrastructure/extraction/extractionRepository';
import { MINE_ACTIVITY_INDEX_COLORMAP, MINE_ACTIVITY_INDEX_OPACITY } from 'domain/extraction/colors';
import { type MineActivityIndexHexagon } from 'domain/extraction/types';

const getHexagonColor = chroma.scale(Object.values(MINE_ACTIVITY_INDEX_COLORMAP));

interface MineActivityIndexHexagonsProps {
  id: number;
  url: string;
}

export const MineActivityIndexHexagons = ({ id, url }: MineActivityIndexHexagonsProps) => {
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();

  const { hexagons } = extractionRepository.useFetchMineActivityIndexHexagons(id, url);

  if (!hexagons.length) return null;

  return (
    <Layer visible={isDataLayerVisible} name="mine-activity-index-layer" zIndex={600}>
      {hexagons.map((hexagon: MineActivityIndexHexagon) => (
        <Polygon
          pathOptions={{
            color: getHexagonColor(hexagon.properties['count%']),
            fillOpacity: MINE_ACTIVITY_INDEX_OPACITY,
            stroke: false,
          }}
          positions={hexagon.geometry.coordinates}
          key={hexagon.id}
        />
      ))}
    </Layer>
  );
};
