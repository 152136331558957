import { formatDate } from 'common/utils/datetime';
import { capitalizeAllFirstLetters, replaceSquareMetersWithHectares, simpleRound } from 'common/utils';
import { SURFACE_PRECISION_HA } from 'common/constants';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdfSimpleContent } from 'views/reports/external/pdfGenerator/simple/ReportPdfSimpleContent';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { useExtractionObjectTypesService } from 'views/mine/extractionLayersService';
import { useGetExtractionResultsUseCase } from 'views/mine/useGetExtractionResultsUseCase';
import { useExtractionLayersVisibility } from 'views/mine/useExtractionLayersVisibility';
import { EXTERNAL_REPORT_SURFACE_AREA_UNIT } from 'domain/reports/constants';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface ExtractionSingleDateReportsExternalDataProps {
  reportInfo: ExternalReportHeaderCommon;
  timestamp: string;
}

export const ExtractionSingleDateReportsExternalData = ({
  reportInfo,
  timestamp,
}: ExtractionSingleDateReportsExternalDataProps) => {
  const { segmentationLegendOptions } = useExtractionObjectTypesService();
  const { mineResults } = useGetExtractionResultsUseCase();

  const { classesVisibility } = useExtractionLayersVisibility();

  const reportScreenshotSrc = useReportsStore.use.reportScreenshotSrc();

  const data = segmentationLegendOptions
    .filter(({ name }) => classesVisibility[name])
    .map(({ id, label_name, color }) => {
      const area = mineResults
        .filter(({ img_date }) => img_date === timestamp)
        .find(({ mine_object }) => mine_object.id === id)?.area;

      const surface = replaceSquareMetersWithHectares(area);
      const value = typeof surface === 'number' ? simpleRound(surface, SURFACE_PRECISION_HA) : null;

      return {
        id,
        label: capitalizeAllFirstLetters(label_name),
        color,
        value,
      };
    });

  const header = {
    ...reportInfo,
    reportType: 'Single Date Report',
    reportFunctionality: 'Modified Terrain Analysis',
    dates: {
      first: formatDate(timestamp),
    },
  };

  const table = {
    tableHeader: 'Modified Terrain Per Class',
    dataHeader: 'Class Name',
    unit: EXTERNAL_REPORT_SURFACE_AREA_UNIT,
    data,
  };

  if (!reportScreenshotSrc) {
    return null;
  }

  return (
    <ReportPdf>
      <>
        <ReportPdfHeader header={header} />
        <ReportPdfSimpleContent screenshotScr={reportScreenshotSrc} table={table} />
      </>
    </ReportPdf>
  );
};
