import { useGetAoiName } from 'common/hooks/getAoiName';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';

export const useVegetationOtherProviderResults = (timestamp: string, indicatorId: number) => {
  const { areaName } = useGetAoiName();
  const { vegetationResultsMocked } = greeneryRepository.useFetchVegetationResultsMocked(areaName);

  const results = vegetationResultsMocked.filter(
    ({ img_date, indicator_id }) => img_date === timestamp && indicator_id === indicatorId,
  );

  return { results };
};
