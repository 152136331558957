import styled from 'styled-components';

import { ReactComponent as ReportsIcon } from 'assets/svg/report-icon.svg';
import { reportsQueryService } from 'views/reports/reportsQueryService';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { useUserStore } from 'infrastructure/user/userStore';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useAoiNumber } from 'common/navigation/hooks';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { NavigationItem } from 'components';
import { REPORTS_CREATOR_OPTIONS } from 'domain/reports/constants';
import { useChangeViewUseCase } from './changeViewUseCase';
import { useGetAreasNavConfig } from './navigationConfigsService';
import { NavigationExtendedItem } from './NavigationExtendedItem';

export const SidebarNavigation = () => {
  const areaId = useAoiNumber();

  const { areaNavigationConfig } = useGetAreasNavConfig();

  useChangeViewUseCase(areaNavigationConfig);

  const { userPermissions } = useUserStore();
  const { activateReports } = reportsQueryService.useReportsActiveness();
  const areReportsActive = useGetAreReportsActiveUseCase();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const isAnyReportsViewOptionAvailable = (areaOfInterestDetails?.properties.environments || []).some((environment) =>
    REPORTS_CREATOR_OPTIONS.some((option) =>
      option.options.some((innerOption) => innerOption.environment === environment),
    ),
  );

  const isSidebarFolded = useSidebarStore.use.isSidebarFolded();

  const areReportsEnabled =
    areaId &&
    userPermissions &&
    userPermissions.granted_permissions.views.includes('reports') &&
    isAnyReportsViewOptionAvailable;

  return (
    <NavigationWrapper>
      {areaNavigationConfig.map((item, index) => (
        <li key={index}>
          <NavigationExtendedItem item={item} areaId={areaId} active={item.active} />
        </li>
      ))}
      {areReportsEnabled && (
        <li>
          <NavigationItem
            isNarrowed={isSidebarFolded}
            icon={<ReportsIcon />}
            iconSize={20}
            active={areReportsActive}
            label_name="Reports"
            onClick={activateReports}
          />
        </li>
      )}
    </NavigationWrapper>
  );
};

const NavigationWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  gap: 1px;
`;
