import { type ExtendedIndicator } from 'common/types';
import { WaterQualityDobuleDateReportsExternalData } from 'views/reports/external/data/WaterQualityDobuleDateReportsExternalData';
import { useReportsExternalInitialDataService } from 'views/reports/external/data/services/reportsExternalInitialDataService';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface WaterQualityDobuleDateReportsExternalInitialDataProps {
  areaId: number;
  timestamp: string;
  selectedIndicator: ExtendedIndicator;
  reportInfo: ExternalReportHeaderCommon;
}

export const WaterQualityDobuleDateReportsExternalInitialData = ({
  areaId,
  timestamp,
  selectedIndicator,
  reportInfo,
}: WaterQualityDobuleDateReportsExternalInitialDataProps) => {
  const initialDataset = useReportsExternalInitialDataService(timestamp);

  return (
    initialDataset && (
      <WaterQualityDobuleDateReportsExternalData
        areaId={areaId}
        initialDataset={initialDataset}
        selectedIndicator={selectedIndicator}
        reportInfo={reportInfo}
      />
    )
  );
};
