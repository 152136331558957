import { useState } from 'react';

import { formatDate } from 'common/utils/datetime';
import { replaceSquareMetersWithHectares, capitalizeAllFirstLetters } from 'common/utils';
import { type LayerObject } from 'common/types/mapData';
import { SURFACE_PRECISION_HA } from 'common/constants';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdfExtendedContent } from 'views/reports/external/pdfGenerator/extended/ReportPdfExtendedContent';
import { ReportPdfBarChartGenerate } from 'views/reports/external/pdfGenerator/extended/ReportPdfBarChartGenerate';
import {
  type ExternalReportExtendedTableData,
  type ExternalReportHeaderCommon,
  type ReportsDatasetExtended,
} from 'domain/reports/types';
import {
  EXTERNAL_REPORT_OTHER_TABLE_CLASS,
  EXTERNAL_REPORT_SURFACE_AREA_UNIT,
  EXTERNAL_REPORT_UNCLASSIFIED_TABLE_CLASS,
} from 'domain/reports/constants';
import { getExtendedTableData } from 'domain/reports/helpers';
import { isExternalReportExtendedTableDataValid } from 'domain/reports/typeguards';

interface SegmentationDoubleDateReportsExternalDataProps {
  reportInfo: ExternalReportHeaderCommon;
  firstDataset: ReportsDatasetExtended;
  secondDataset: ReportsDatasetExtended;
  areaId: number;
  greeneryObjects: LayerObject[];
}

export const SegmentationDoubleDateReportsExternalData = ({
  reportInfo,
  firstDataset,
  secondDataset,
  areaId,
  greeneryObjects,
}: SegmentationDoubleDateReportsExternalDataProps) => {
  const [chartSrc, setChartSrc] = useState<string | null>(null);

  const { segmentationAreaResults: firstDateSegmentationAreaResults } =
    greeneryRepository.useFetchSegmentationAreaResults(areaId, firstDataset.timestamp);

  const { segmentationAreaResults: secondDateSegmentationAreaResults } =
    greeneryRepository.useFetchSegmentationAreaResults(areaId, secondDataset.timestamp);

  const data = secondDataset.data
    .filter(({ checked }) => checked)
    .map(({ id }): ExternalReportExtendedTableData | undefined => {
      const option = greeneryObjects.find((object) => object.id === id);

      if (!option) {
        return undefined;
      }

      const firstDateSurfaceInM2 = firstDateSegmentationAreaResults.find(
        ({ greenery_object_id }) => greenery_object_id === id,
      )?.area;
      const secondDateSurfaceInM2 = secondDateSegmentationAreaResults.find(
        ({ greenery_object_id }) => greenery_object_id === id,
      )?.area;

      const first = replaceSquareMetersWithHectares(firstDateSurfaceInM2);
      const second = replaceSquareMetersWithHectares(secondDateSurfaceInM2);

      const { firstValue, secondValue, difference, trend } = getExtendedTableData(first, second, SURFACE_PRECISION_HA);

      return {
        id,
        label: capitalizeAllFirstLetters(option.label_name),
        color: option.color,
        firstValue,
        secondValue,
        difference,
        trend,
      };
    })
    .filter((item) => item);

  const validData = isExternalReportExtendedTableDataValid(data) ? data : [];

  const firstDateNotSelectedSurfaceInM2 = firstDateSegmentationAreaResults
    .filter((item) => firstDataset.data.some(({ id, checked }) => item.greenery_object_id === id && !checked))
    .reduce((acc, { area }) => acc + area, 0);

  const secondDateNotSelectedSurfaceInM2 = secondDateSegmentationAreaResults
    .filter((item) => secondDataset.data.some(({ id, checked }) => item.greenery_object_id === id && !checked))
    .reduce((acc, { area }) => acc + area, 0);

  const firstDateNotSelectedSurfaceInHa = replaceSquareMetersWithHectares(firstDateNotSelectedSurfaceInM2);
  const secondDateNotSelectedSurfaceInHa = replaceSquareMetersWithHectares(secondDateNotSelectedSurfaceInM2);

  if (firstDateNotSelectedSurfaceInHa || secondDateNotSelectedSurfaceInHa) {
    const { firstValue, secondValue, difference, trend } = getExtendedTableData(
      firstDateNotSelectedSurfaceInHa,
      secondDateNotSelectedSurfaceInHa,
      SURFACE_PRECISION_HA,
    );

    validData.push({
      id: -1,
      label: EXTERNAL_REPORT_OTHER_TABLE_CLASS.label,
      color: EXTERNAL_REPORT_OTHER_TABLE_CLASS.color,
      firstValue,
      secondValue,
      difference,
      trend,
    });
  }

  const firstDateAllSurfaceInM2 = firstDateSegmentationAreaResults.reduce((acc, { area }) => acc + area, 0);
  const firstDateAllSurfaceInHa = replaceSquareMetersWithHectares(firstDateAllSurfaceInM2);
  const firstDateUnclassifiedSurfaceInHa = firstDateAllSurfaceInHa
    ? reportInfo.areaSize.value - firstDateAllSurfaceInHa
    : 0;
  const firstDateUnclassifiedNonnegativeSurfaceInHa =
    firstDateUnclassifiedSurfaceInHa < 0 ? 0 : firstDateUnclassifiedSurfaceInHa;

  const secondDateAllSurfaceInM2 = secondDateSegmentationAreaResults.reduce((acc, { area }) => acc + area, 0);
  const secondDateAllSurfaceInHa = replaceSquareMetersWithHectares(secondDateAllSurfaceInM2);
  const secondDateUnclassifiedSurfaceInHa = secondDateAllSurfaceInHa
    ? reportInfo.areaSize.value - secondDateAllSurfaceInHa
    : 0;
  const secondDateUnclassifiedNonnegativeSurfaceInHa =
    secondDateUnclassifiedSurfaceInHa < 0 ? 0 : secondDateUnclassifiedSurfaceInHa;

  if (firstDateUnclassifiedNonnegativeSurfaceInHa || secondDateUnclassifiedNonnegativeSurfaceInHa) {
    const { firstValue, secondValue, difference, trend } = getExtendedTableData(
      firstDateUnclassifiedNonnegativeSurfaceInHa,
      secondDateUnclassifiedNonnegativeSurfaceInHa,
      SURFACE_PRECISION_HA,
    );

    validData.push({
      id: -2,
      label: EXTERNAL_REPORT_UNCLASSIFIED_TABLE_CLASS.label,
      color: EXTERNAL_REPORT_UNCLASSIFIED_TABLE_CLASS.color,
      firstValue,
      secondValue,
      difference,
      trend,
    });
  }

  const firstDate = formatDate(firstDataset.timestamp);
  const secondDate = formatDate(secondDataset.timestamp);

  const header = {
    ...reportInfo,
    reportType: 'Comparison Report',
    reportFunctionality: 'Land Cover - Segmentation',
    dates: {
      first: firstDate,
      second: secondDate,
    },
  };

  const screenshots = {
    first: {
      src: firstDataset.screenshotScr,
      label: `Date 1: ${firstDate}`,
    },
    second: {
      src: secondDataset.screenshotScr,
      label: `Date 2: ${secondDate}`,
    },
  };

  const table = {
    tableHeader: 'Land Cover Segmentation Comparison Per Class',
    dataHeader: 'Class Name',
    unit: EXTERNAL_REPORT_SURFACE_AREA_UNIT,
    firstDate,
    secondDate,
    data: validData,
  };

  return (
    <>
      {firstDateSegmentationAreaResults.length && secondDateSegmentationAreaResults.length && (
        <ReportPdfBarChartGenerate
          data={validData}
          header={`Land Cover Segmentation Comparison Per Class Date 1 vs. Date 2`}
          unit={EXTERNAL_REPORT_SURFACE_AREA_UNIT}
          setChartSrc={setChartSrc}
        />
      )}
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} />
          <ReportPdfExtendedContent screenshots={screenshots} table={table} chartSrc={chartSrc} />
        </>
      </ReportPdf>
    </>
  );
};
