import { useMemo } from 'react';

import { buildGradientColormapArray } from 'common/utils/color';
import { getLayerNameWithAoiId } from 'common/utils';
import { useLayerOrderStore } from 'components/layerManager/layersOrderStore';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useSortedNestedLayersData } from 'components/layerManager/useSortedNestedLayersData';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { type ExtendedIndicesResult, type IndicesLayer } from 'domain/exploration/types';
import { useIndicesLayersVisibility } from './useIndicesLayersVisibility';
import { ExplorationMapLayer } from '../ExplorationMapLayer';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

interface IndicesMapContentProps {
  areaId: number;
  indicesResults: ExtendedIndicesResult[];
  indexOffset?: number;
}

export const IndicesMapContent = ({ areaId, indicesResults, indexOffset: _none = 0 }: IndicesMapContentProps) => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();
  const { classesVisibility: layersVisibility } = useIndicesLayersVisibility();
  const { nestedLayersOrderRecord } = useLayerOrderStore();

  const layers = useMemo(
    () =>
      indicesResults
        ? indicesResults
            .map(({ id, name, url, colormap }) => {
              const layerName = getLayerNameWithAoiId(name, areaId);
              const threshold = layersTresholds[layerName];

              if (Array.isArray(threshold) && threshold.length === 2) {
                const expression = `((b1>=${threshold[0]})&(b1<=${threshold[1]}))*b1`;
                const colormapArray = buildGradientColormapArray(colormap, threshold[0], threshold[1]);

                return {
                  id,
                  name,
                  url: getAzureBlobStorageUrl(url),
                  params: { expression, colormap: JSON.stringify(colormapArray) },
                };
              }

              return null;
            })
            .filter((item): item is IndicesLayer => !!item)
        : [],
    [indicesResults, layersTresholds, areaId],
  );

  const sortedIndices = useSortedNestedLayersData(
    nestedLayersOrderRecord,
    EXPLORATION_LAYERS_KEYS.INDICES,
    layers,
    'id',
  );

  return (
    <>
      {sortedIndices.map((layer) => (
        <WithLayerIndex
          key={layer.id}
          Component={ExplorationMapLayer}
          props={{
            ...layer,
            builder: titilerApiAdapter.buildGenericMonocolorCogUrl,
            opacity: 1,
            isVisible: !!layersVisibility[layer.name],
          }}
          sectionId={EXPLORATION_LAYERS_KEYS.INDICES}
          layerId={layer.id}
        />
      ))}
    </>
  );
};
