import { useMemo } from 'react';

import { useGetAoiName } from 'common/hooks/getAoiName';
import { type DraggableSection } from './types';

export const useDraggableLayerManagerContent = (sections: DraggableSection[]) => {
  const { areaName } = useGetAoiName();

  const memoizedSections = useMemo(() => [...(areaName ? [...(sections || [])] : [])], [areaName, sections]);

  const nonNullSections = memoizedSections.filter((section) => section.id);

  return { sections: nonNullSections };
};
