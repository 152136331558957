import { useState } from 'react';
import styled from 'styled-components';

import { Button } from 'ui/atoms/button';
import { Loading } from 'ui/molecules/loading';
import { ShareBlockActionWrapper, ShareWrapper } from './styled';
import { ShareLinkBlock } from './ShareLinkBlock';
import { type DownloadedFile } from '../types';
import { useAppendExtentLink } from '../useAppendExtentLink';
import { getFullFileName, downloadSingleFile, downloadAllFilesWithNamesAsZip } from '../helpers';

interface ShareModalContentProps {
  fileLinks: string[];
  fileType: string;
  fileName: string;
  getReadyLinks?: () => DownloadedFile[] | Promise<DownloadedFile[]>;
}

export const ShareModalContent = ({ fileLinks, fileType, fileName, getReadyLinks }: ShareModalContentProps) => {
  const [isDownloadLinksLoading, setIsDownloadLinksLoading] = useState(false);

  const filteredFileLinks = fileLinks.filter((url) => !!url);

  const sanitizedFileName = fileName.replaceAll(' ', '_');

  const prepareLinksForDownload = (filteredFileLinks: string[]) =>
    filteredFileLinks.map((link, index) => ({
      id: String(index + 1),
      url: link,
      name: `${sanitizedFileName}_${index + 1}`,
      label: `${sanitizedFileName} ${index + 1}`,
      extension: fileType,
    }));

  const { appendExtentLink } = useAppendExtentLink();

  const preparedLinks = appendExtentLink(prepareLinksForDownload(filteredFileLinks));

  const isSingleLink = preparedLinks.length < 2;
  const filenameToDownload = isSingleLink ? preparedLinks[0]?.name : sanitizedFileName;
  const fileTypeToDownload = isSingleLink ? preparedLinks[0]?.extension : 'zip';

  const downloadFile = (fileData: DownloadedFile[]) => {
    if (isSingleLink) {
      return downloadSingleFile(fileData[0]);
    }

    downloadAllFilesWithNamesAsZip(fileData, sanitizedFileName, setIsDownloadLinksLoading);
  };

  const onDownloadHandler = () => {
    if (getReadyLinks) {
      setIsDownloadLinksLoading(true);
      Promise.resolve(getReadyLinks()).then((readyLinks) => downloadFile(appendExtentLink(readyLinks)));
      setIsDownloadLinksLoading(false);
    } else {
      downloadFile(preparedLinks);
    }
  };

  return (
    <>
      {isDownloadLinksLoading && <Loading />}
      <ShareWrapper>
        <ShareLinkBlock />
        {!!preparedLinks.length && (
          <ShareBlockWrapper>
            <div>Get the .{fileTypeToDownload} file</div>
            <ShareBlockActionWrapper>
              <span>{getFullFileName(filenameToDownload, fileTypeToDownload)}</span>
              <Button onClick={onDownloadHandler} squared>
                Download {fileTypeToDownload}
              </Button>
            </ShareBlockActionWrapper>
          </ShareBlockWrapper>
        )}
      </ShareWrapper>
    </>
  );
};

export const ShareBlockWrapper = styled.div`
  margin: 0;
  margin-top: 60px;
`;
