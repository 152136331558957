import { useOverridableFeatureFlag } from 'services/configCat';
import { MarkdownMathPreview } from 'ui/atoms/markdownMathPreview/MarkdownMathPreview';
import { ShareModalControlButton } from 'components/downloadModal/legacyShareModal';
import { InfoArea, useInfoAreaService } from 'ui/infoArea';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { SelectionPanel } from 'components/SelectionPanel';
import { Statistics } from 'features/statistics';
import { MINERALIZATION_INFO_AREA_DESCRIPTION } from 'domain/exploration/configs';
import { useClustersLayerManagerStore } from '../clusters/clustersLayerManagerStore';
import { useLineamentsLayerManagerStore } from '../lineaments';

export const Minerals = () => {
  const enableStatistics = useOverridableFeatureFlag('enableStatistics', false, 'REACT_APP_ENABLE_STATISTICS');

  const infoAreaProps = useInfoAreaService();

  const areReportsActive = useGetAreReportsActiveUseCase();

  const isClustersSelectionMode = useClustersLayerManagerStore.use.isClustersSelectionMode();
  const confirmClustersSelection = useClustersLayerManagerStore.use.confirmClustersSelection();
  const cancelClustersSelection = useClustersLayerManagerStore.use.cancelClustersSelection();
  const isLineamentsSelectionMode = useLineamentsLayerManagerStore.use.isLineamentsSelectionMode();
  const confirmLineamentsSelection = useLineamentsLayerManagerStore.use.confirmLineamentsSelection();
  const cancelLineamentsSelection = useLineamentsLayerManagerStore.use.cancelLineamentsSelection();

  return (
    <AreaDetailsLayout
      toolbarContent={
        <InfoArea {...infoAreaProps}>
          <MarkdownMathPreview content={MINERALIZATION_INFO_AREA_DESCRIPTION} />
        </InfoArea>
      }
      toolboxContent={!areReportsActive && <ShareModalControlButton />}
    >
      {isLineamentsSelectionMode && (
        <SelectionPanel
          description={
            <>
              Click the lineaments on the map to select and deselect them.
              <br />
              The selected lineaments will stay visible and the deselected ones will be hidden on the map.
            </>
          }
          handleConfirm={confirmLineamentsSelection}
          handleCancel={cancelLineamentsSelection}
        />
      )}
      {isClustersSelectionMode && (
        <SelectionPanel
          description={
            <>
              Click the clusters on the map to select and deselect them.
              <br />
              The selected clusters will stay visible and the deselected ones will be hidden on the map.
            </>
          }
          handleConfirm={confirmClustersSelection}
          handleCancel={cancelClustersSelection}
        />
      )}
      {enableStatistics && !isClustersSelectionMode && !isLineamentsSelectionMode && <Statistics />}
    </AreaDetailsLayout>
  );
};
