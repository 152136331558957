import { useEffect } from 'react';

import { layerManagerOptionsBuildHelper } from 'components/layerManager';
import { useAoiNumber } from 'common/navigation/hooks';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { type LayersVisibilityInterface } from 'domain/exploration/types';
import { useLegendOptions } from './useLegendOptions';
import { useExplorationLayerManagerStore } from '../../explorationLayerManagerStore';

export const useLayersDisplayState = (classifierId: number, layersVisibilityInterface: LayersVisibilityInterface) => {
  const areaId = useAoiNumber();

  const isGradientModeOn = useExplorationLayerManagerStore.use.gradientModeState()[classifierId];
  const setGradientMode = useExplorationLayerManagerStore.use.setGradientModeState();

  const { classesQuery, classesVisibility, updateClassesVisibility, toggleMultipleQueries } = layersVisibilityInterface;

  const { minerals } = explorationRepository.useFetchMineralsTypes();

  const { legendOptions, preferredOptions } = useLegendOptions(minerals, classifierId);

  const updateLayerVisibility = isGradientModeOn
    ? (clickedValue: string) => {
        updateClassesVisibility({
          [clickedValue]: true,
        });
      }
    : toggleMultipleQueries;

  const activatePreferredLayer = () => {
    if (legendOptions.length > 0) {
      updateClassesVisibility(layerManagerOptionsBuildHelper(legendOptions, [preferredOptions[0].id]));
    }
  };

  useEffect(() => {
    setGradientMode(classifierId, false);

    const timeout = setTimeout(() => {
      activatePreferredLayer();
      return;
    }, 250);

    return () => clearTimeout(timeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!classesQuery) {
      if (areaId || minerals) {
        activatePreferredLayer();
        return;
      }
    }
  }, [classesQuery, updateClassesVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!areaId) {
      console.log('No areaId provided');
    }

    if (!minerals) {
      console.log('Minerals data is not available');
    }
  }, [areaId, minerals, minerals.length]);

  return { classesVisibility, updateClassesVisibility, updateLayerVisibility };
};
