import { type Position } from 'geojson';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

interface VertexProps {
  vertex: Position;
  color: string;
  onClick: (vertex: Position) => void;
}

export const Vertex = ({ vertex, color, onClick }: VertexProps) => {
  const map = useMap();

  useEffect(() => {
    const point = L.circle(vertex, {
      radius: 24,
      color,
      fillOpacity: 1,
      pane: 'verticesLayer',
    })
      .addTo(map)
      .on('click', () => onClick(vertex));

    return () => {
      point.remove();
    };
  }, [color, map, onClick, vertex]);

  return null;
};
