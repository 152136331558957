/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ExternalReportExtendedTableData } from 'domain/reports/types';

export const isExternalReportExtendedTableDataValid = (data: any): data is ExternalReportExtendedTableData[] => {
  return (
    data &&
    Array.isArray(data) &&
    data.every(
      (item: any) =>
        typeof item.id === 'number' &&
        typeof item.label === 'string' &&
        (typeof item.firstValue === 'string' || item.firstValue === null) &&
        (typeof item.secondValue === 'string' || item.secondValue === null) &&
        (typeof item.difference === 'string' || item.difference === null) &&
        (typeof item.trend === 'boolean' || item.trend === null),
    )
  );
};
