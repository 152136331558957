import { Loading } from 'ui/molecules/loading';
import { segmentationDataService } from 'views/greenery/segmentation/segmentationDataService';
import { BiomassDataLayer } from 'views/greenery/segmentation/BiomassDataLayer';
import { SegmentationDataLayerPreparation } from 'views/greenery/segmentation/SegmentationDataLayer';
import { useBiomass } from 'views/greenery/segmentation/useBiomass';

export const SegmentationSingleDateMapContent = ({ timestamp }: { timestamp: string }) => {
  const { resultId, isResultLoading } = segmentationDataService.useSegmentationResult(timestamp);

  const { biomassUrl, isBiomassVisible } = useBiomass();

  if (isResultLoading) {
    return <Loading />;
  }

  return (
    <>
      {biomassUrl && <BiomassDataLayer url={biomassUrl} isVisible={isBiomassVisible} />}
      {resultId && <SegmentationDataLayerPreparation resultId={resultId} />}
    </>
  );
};
