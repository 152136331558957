import { useEffect } from 'react';

export const useBindAppLifecycleEvents = (version: string) => {
  useEffect(() => {
    console.log('app version:', version);
    console.log('build version:', process.env.REACT_APP_BUILD_VERSION || 'dev');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
