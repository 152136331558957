import { type FormEvent, useState, useEffect } from 'react';

import { userRepository } from 'infrastructure/user/userRepository';
import { InputElementError } from 'domain/user/enums';
import { validateEmail } from 'domain/user/helpers';

export const useHandleResetPasswordUseCase = (captchaToken: string | null) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string>();

  const { mutate, data, isLoading, error } = userRepository.usePasswordReset();

  useEffect(() => {
    setEmailError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (captchaToken) {
      setEmailError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaToken]);

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email.length) {
      setEmailError(InputElementError.NO_EMAIL);
      return;
    }

    const emailValid = validateEmail(email);

    if (!emailValid) {
      setEmailError(InputElementError.INVALID_EMAIL);
      return;
    }

    if (!captchaToken) {
      setEmailError(InputElementError.NO_CAPTCHA);
      return;
    }

    mutate(email);
  };

  return { email, setEmail, emailError, submitForm, isLoading, data, error };
};
