import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { useGetAoiCogs } from 'infrastructure/areas/useGetAoiCogs';
import { WithCumulativeCutoffCogRange } from 'services/titiler/WithCumulativeCutoffCogRange';
import { useCompositesStore } from './compositesStore';
import { CompositesMapLayerBeta } from './CompositesMapLayerBeta';

export const CompositesMapContentBeta = () => {
  const { composites } = explorationRepository.useFetchComposites();

  const { aoiCogs } = useGetAoiCogs();

  const areCompositesBetaVisible = useCompositesStore.use.areCompositesBetaVisible();
  const selectedProviderName = useCompositesStore.use.selectedProviderName();
  const compositesSelection = useCompositesStore.use.compositesSelection();
  const providersOpacityDictionary = useCompositesStore.use.providersOpacityDictionary();

  const selectedComposite = composites.find(
    ({ name }) => selectedProviderName && name === compositesSelection[selectedProviderName],
  );

  const aoiCogUrl = aoiCogs.find(({ provider_name }) => provider_name === selectedProviderName)?.url;

  if (!selectedProviderName || !selectedComposite || !aoiCogUrl) {
    return null;
  }

  const opacity = providersOpacityDictionary[selectedProviderName];

  return (
    <WithCumulativeCutoffCogRange
      Component={CompositesMapLayerBeta}
      props={{
        visible: areCompositesBetaVisible,
        opacity,
      }}
      url={aoiCogUrl}
      bands={selectedComposite.bands}
    />
  );
};
