import { useEffect } from 'react';

import { type LayerObject } from 'common/types/mapData';
import { getRandomlySelectedItemFromArray } from 'common/utils';
import { GREENERY_INITIALLY_AVAILABLE_LAYERS } from 'domain/greenery/configs';
import { useGreeneryLayersVisibility } from '../useGreeneryLayersVisibility';

export const useGreeneryObjectsUpdateUseCase = (layerObjects: LayerObject[], areaId: number) => {
  const { classesQuery, classesVisibility, updateClassesVisibility } = useGreeneryLayersVisibility();

  useEffect(() => {
    // TODO: restore when 'active' property objects will be implemented on backend
    // const greeneryObjectTypes: LayerObject[] | undefined =
    //   layerObjects ? layerObjects.filter(({ status }) => status === 'active') : [];
    const greeneryObjectTypes: LayerObject[] = layerObjects;

    const initiallyAvailableLayers = getRandomlySelectedItemFromArray(GREENERY_INITIALLY_AVAILABLE_LAYERS);

    const areAnyOfChosenLayersAlreadyVisible = GREENERY_INITIALLY_AVAILABLE_LAYERS?.some(
      (name) => classesVisibility[name],
    );

    if (!classesQuery) {
      updateClassesVisibility(
        Object.fromEntries(
          greeneryObjectTypes.map(({ name }) => [
            name,
            classesVisibility[name] ||
              (!areAnyOfChosenLayersAlreadyVisible && initiallyAvailableLayers.includes(name)) ||
              false,
          ]),
        ),
      );
    }
  }, [areaId, layerObjects, classesQuery, classesVisibility, updateClassesVisibility]);
};
