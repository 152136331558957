import { DoubleDateSelect } from 'components';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { useWaterTimestamps } from 'views/water/useWaterTimestamps';
import { useTimelineService } from 'components/Timeline/timelineService';
import { DateSelect } from 'ui';
import {
  REPORTS_CREATOR_COLUMN_INSUFFICIENT_DATA_LABEL,
  REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_SELECT_DATE_LABEL,
  REPORTS_CREATOR_SINGLE_TYPE_COLUMN_DATE_LABEL,
} from 'domain/reports/constants';

export const WaterReportsCreatorDates = () => {
  const { timestamps, areTimestampsLoading } = useWaterTimestamps();
  const { timestamp, secondTimestamp, changeTimestamp, changeSecondTimestamp } = useTimelineService();

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();
  const setIsReportsSecondDateSelected = useReportsStore.use.setIsReportsSecondDateSelected();

  if (areTimestampsLoading) {
    return <LoadingDots />;
  }

  if (!timestamps.length || (timestamps.length <= 1 && isCompareDatesType)) {
    return REPORTS_CREATOR_COLUMN_INSUFFICIENT_DATA_LABEL;
  }

  return isCompareDatesType ? (
    <>
      <div>{REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_SELECT_DATE_LABEL}</div>
      <DoubleDateSelect
        variant="OLD_TO_NEWER"
        {...{ timestamps, timestamp, secondTimestamp, changeTimestamp, changeSecondTimestamp }}
        handleAreBothDatesSelected={(isSelected: boolean) => setIsReportsSecondDateSelected(isSelected)}
      />
    </>
  ) : (
    <>
      <div>{REPORTS_CREATOR_SINGLE_TYPE_COLUMN_DATE_LABEL}</div>
      <DateSelect {...{ timestamps, timestamp, changeTimestamp }} />
    </>
  );
};
