import { type MutableRefObject, useEffect } from 'react';

import { useOnClickOutside } from 'common/hooks';
import { SIDEBAR_TOGGLE_DELAY } from 'common/constants';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSidebarFolding = (sidebarRef: MutableRefObject<any>, isSidebarHidingAllowed: boolean = true) => {
  const isSidebarUnlocked = useSidebarStore.use.isSidebarUnlocked();

  const isSidebarClosingAllowed = isSidebarUnlocked && isSidebarHidingAllowed;
  const showSidebar = useSidebarStore.use.unfoldSidebar();
  const hideSidebar = useSidebarStore.use.foldSidebar();

  const delayedSidebarFold = () => {
    if (isSidebarClosingAllowed) {
      setTimeout(() => {
        hideSidebar();
      }, SIDEBAR_TOGGLE_DELAY);
    }
  };

  useOnClickOutside(sidebarRef, delayedSidebarFold);

  const foldSidebar = delayedSidebarFold;

  useEffect(() => {
    isSidebarClosingAllowed ? hideSidebar() : showSidebar();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarUnlocked, isSidebarClosingAllowed, isSidebarHidingAllowed]);

  return { foldSidebar };
};
