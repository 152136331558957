import { useUserStore } from 'infrastructure/user/userStore';
import { useEffect } from 'react';

export const useCheckoutSessionCleanup = () => {
  const { clearClientSecret, clearSessionId } = useUserStore();

  useEffect(() => {
    clearClientSecret();
    clearSessionId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
