import { useMap } from 'react-leaflet';

import { checkIsOutsideBounds } from 'common/utils/map';
import { isLeafletBoundsValid } from 'common/typeguards';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';

interface MapValueClickProps {
  url: string;
  isForbidden?: boolean;
  handleClick: (no: number) => void;
}

export const MapValueClick = ({ url, isForbidden, handleClick }: MapValueClickProps) => {
  const map = useMap();
  map.off('click');

  const { info } = titilerInfoService.useGetInfoGeneric(getAzureBlobStorageUrl(url));
  const bounds = getBoundsFromCogInfo(info);
  const validBounds = isLeafletBoundsValid(bounds) ? bounds : null;

  if (isForbidden || !validBounds) {
    return null;
  }

  map.on('click', async (event: { originalEvent: PointerEvent }) => {
    const latLng = await map.mouseEventToLatLng(event.originalEvent);

    if (checkIsOutsideBounds(latLng, validBounds)) {
      return;
    }

    const info = await titilerApiService.getPointInfoGeneric(getAzureBlobStorageUrl(url), latLng.lng, latLng.lat);
    const values = info?.values;

    if (values && Array.isArray(values) && values.length) {
      handleClick(values[0]);
    }
  });

  return null;
};
