import { type AxiosResponse } from 'axios';

import { type PointHistoryApiResponse } from 'common/types';
import { type CogResult } from 'common/types/api';
import { getApiData, getExternalData } from 'services/rest/restApiService';
import {
  type WaterMask,
  type WaterAreaResultsApiResponse,
  type WaterBodiesApiResponse,
  type WaterBodyDefaultView,
  type WaterQualityResultsApiResponse,
  type WaterIndicatorsApiResponse,
  type WaterDatesApiResponse,
  type WaterAreaItemApiResponse,
  type SnowCoverResultsApiResponse,
  type SnowCoverIndicatorsApiResponse,
  type ProvidersApiResponse,
} from 'domain/water/types';
import { WATER_API_URLS } from './waterApiUrls';

export const waterApi = {
  getProviders: (): Promise<AxiosResponse<ProvidersApiResponse>> => getExternalData(WATER_API_URLS.getProviders, {}),

  getWaterbodies: ({
    areaId,
    limit,
    offset,
  }: {
    areaId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<WaterBodiesApiResponse>> =>
    getApiData(WATER_API_URLS.getWaterbodies, {
      aoi_id: areaId,
      waterbody_type: 2,
      limit: limit,
      offset: offset,
    }),

  getWaterDates: ({
    areaId,
    waterbodyId,
    limit,
    offset,
  }: {
    areaId: number;
    waterbodyId?: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<WaterDatesApiResponse>> =>
    getApiData(WATER_API_URLS.getDates, {
      aoi_id: areaId,
      waterbody_id: waterbodyId,
      limit,
      offset,
    }),

  getWaterDatesMocked: (): Promise<AxiosResponse<WaterDatesApiResponse>> =>
    getExternalData(WATER_API_URLS.getDatesMocked, {}),

  getWaterQualityIndicators: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<WaterIndicatorsApiResponse>> =>
    getApiData(WATER_API_URLS.getQualityIndicators, { limit, offset }),

  getWaterQualityResults: ({
    date,
    indicatorId,
    waterbodyId,
  }: {
    date: string | undefined;
    indicatorId: number | undefined;
    waterbodyId: number | undefined;
  }): Promise<AxiosResponse<WaterQualityResultsApiResponse>> | undefined =>
    getApiData(WATER_API_URLS.getQualityResults, {
      start_date: date,
      end_date: date,
      indicator_id: indicatorId,
      waterbody_id: waterbodyId,
    }),

  getWaterQualityResultsMocked: (): Promise<AxiosResponse<WaterQualityResultsApiResponse>> =>
    getExternalData(WATER_API_URLS.getQualityResultsMocked, {}),

  getWaterQualityCog: (resultId: number): Promise<AxiosResponse<CogResult>> | undefined =>
    getApiData(WATER_API_URLS.getQualityCog.replace(':resultId', String(resultId)), {}),

  getWaterAreaResults: ({
    areaId,
    date,
    waterbodyId,
    limit,
    offset,
  }: {
    areaId: number;
    date?: string;
    waterbodyId?: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<WaterAreaResultsApiResponse>> | undefined =>
    getApiData(WATER_API_URLS.getAreaResults, {
      start_date: date,
      end_date: date,
      aoi_id: areaId,
      waterbody_id: waterbodyId,
      limit,
      offset,
    }),

  getWaterAreaResultsMocked: (): Promise<AxiosResponse<WaterAreaResultsApiResponse>> =>
    getExternalData(WATER_API_URLS.getAreaResultsMocked, {}),

  getWaterAreaCog: (resultId: number): Promise<AxiosResponse<WaterMask>> | undefined =>
    getApiData(WATER_API_URLS.getAreaCog.replace(':resultId', String(resultId)), {}),

  getWaterAreaComparatorCog: ({
    firstResultId,
    secondResultId,
  }: {
    firstResultId: number;
    secondResultId: number;
  }): Promise<AxiosResponse<WaterMask>> | undefined =>
    getApiData(WATER_API_URLS.getAreaComparatorCog, {
      first_result_id: firstResultId,
      second_result_id: secondResultId,
    }),

  getWaterBodyDefaultView: (waterbodyId: number): Promise<AxiosResponse<WaterBodyDefaultView>> | undefined =>
    getApiData(WATER_API_URLS.getWaterbodyDefaultView.replace(':waterbodyId', String(waterbodyId)), {}),

  getWaterPointHistory: ({
    indicatorId,
    waterbodyId,
    lat,
    lng,
  }: {
    indicatorId: string | number;
    waterbodyId: number;
    lat: number;
    lng: number;
  }): Promise<AxiosResponse<PointHistoryApiResponse>> | undefined =>
    getApiData(WATER_API_URLS.getPointHistory, {
      indicator_id: indicatorId,
      waterbody_id: waterbodyId,
      latitude: lat,
      longitude: lng,
    }),

  getWaterAreaHistory: ({ waterbodyId }: { waterbodyId: number }): Promise<AxiosResponse<WaterAreaItemApiResponse>> =>
    getApiData(WATER_API_URLS.getAreaHistory, {
      waterbody_id: waterbodyId,
    }),

  getMoistureIndicators: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(WATER_API_URLS.getMoistureIndicators, { limit, offset }),

  getMoistureResults: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(WATER_API_URLS.getMoistureResults, { limit, offset }),

  getSnowCoverIndicators: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<SnowCoverIndicatorsApiResponse> | undefined> =>
    getExternalData(WATER_API_URLS.getSnowCoverIndicators, { limit, offset }),

  getSnowCoverResults: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<SnowCoverResultsApiResponse> | undefined> =>
    getExternalData(WATER_API_URLS.getSnowCoverResults, { limit, offset }),
};
