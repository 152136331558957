import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useReplaceQueryParameters } from 'common/navigation/hooks';
import { REPORT_TYPE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { getRouteUrlHelper } from 'common/navigation/utils';
import { type CreatorOptionsColumnOption } from 'ui/creator/types';
import { useUserStore } from 'infrastructure/user/userStore';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { REPORTS_CREATOR_OPTIONS } from 'domain/reports/constants';

export const useReportsCreatorOptionsService = () => {
  const { areaId } = useParams();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const { activeType } = useGetActiveOptionReportsCreatorUseCase();
  const replaceQueryParameter = useReplaceQueryParameters();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(Number(areaId));

  const { userPermissions } = useUserStore();

  const handleTypeOptionChange = (name: string) => {
    replaceQueryParameter([
      {
        key: REPORT_TYPE_QUERY_PARAMETER,
        value: name,
      },
    ]);
  };

  const handleFunctionalityOptionChange = (name: string) => {
    const navigateTo = activeType?.options.find((option) => option.name === name)?.navigateTo;

    if (navigateTo && !pathname.includes(name) && areaId) {
      const currentUrl = getRouteUrlHelper(navigateTo, areaId, ':areaId').concat(search);
      navigate(currentUrl);
    }
  };

  const getTypeOptionsUseCase = (): CreatorOptionsColumnOption[] | undefined => {
    const typeOptions = REPORTS_CREATOR_OPTIONS.map(({ id, name, label }) => ({
      id,
      label,
      selected: activeType?.name === name,
      onClick: () => handleTypeOptionChange(name),
    }));

    return typeOptions.length ? typeOptions : undefined;
  };

  const getFunctionalityOptionsUseCase = (): CreatorOptionsColumnOption[] | undefined => {
    const functionalityOptions = activeType?.options
      .filter(({ environment }) => areaOfInterestDetails?.properties.environments.includes(environment))
      .filter(({ name }) => userPermissions?.granted_permissions?.views.some((item) => item === name))
      .map(({ id, name, label }) => ({
        id,
        label,
        selected: pathname.includes(name),
        onClick: () => handleFunctionalityOptionChange(name),
      }));

    return functionalityOptions?.length ? functionalityOptions : undefined;
  };

  const reportsCreatorOptionsService = {
    typeOptions: getTypeOptionsUseCase(),
    functionalityOptions: getFunctionalityOptionsUseCase(),
  };

  return reportsCreatorOptionsService;
};
