import { useTimeline } from 'components/Timeline/useTimeline';
import { Timeline } from 'components/Timeline/Timeline';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { type MoistureIndicator } from 'domain/water/types';

export const MoistureTimeline = ({ areaName, indicator }: { areaName: string; indicator: MoistureIndicator }) => {
  const { results } = waterRepository.useFetchMoistureResults(areaName);

  const dates = results.filter(({ indicator_id }) => indicator_id === indicator.id).map(({ img_date }) => img_date);

  const timestamps = Array.from(new Set(dates));

  const areReportsActive = useGetAreReportsActiveUseCase();

  const { filteredTimestamps, firstDate, lastDate, rangeValue, selectedTimestamps, setRangeValue } = useTimeline(
    timestamps,
    !areReportsActive,
  );

  return (
    <>
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          visible: !areReportsActive,
        }}
      />
    </>
  );
};
