import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { isDataPoint } from 'common/typeguards';
import { useGraphStore } from 'components/graph/graphStore';
import { useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { COHERENCE_QUERY_PARAMETER, MARKER_INDEX_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useMapStore } from 'components/map/mapStore';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';

export const useDisplacementsQuery = () => {
  const kmzId = useDisplacementsStore.use.kmzId();
  const kmzUrls = useDisplacementsStore.use.kmzUrls();

  const dataPoint = useGraphStore.use.chosenPoint();

  const queryReplace = useReplaceQueryParameters();
  const mapReady = useMapStore.use.mapReady();

  const map = useMap();
  const markerIndex = Number(useQueryParameter(MARKER_INDEX_QUERY_PARAMETER));

  useEffect(() => {
    if (kmzId && mapReady) {
      Promise.resolve(() => {
        const coherenceLevel = kmzId && kmzUrls?.find((kmz) => kmz.id === kmzId)?.coherence;
        queryReplace([
          {
            key: COHERENCE_QUERY_PARAMETER,
            value: coherenceLevel ? String(coherenceLevel) : null,
          },
        ]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmzId, mapReady]);

  useEffect(() => {
    Promise.resolve(() => {
      mapReady &&
        queryReplace([
          {
            key: MARKER_INDEX_QUERY_PARAMETER,
            value: isDataPoint(dataPoint) && dataPoint?.id ? String(dataPoint?.id) : null,
          },
        ]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPoint, mapReady]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openMarkerByQueryId = (event: any) => {
    if (!markerIndex || isNaN(markerIndex) || event.layer?.feature?.id !== markerIndex) return;
    event.layer.fire('click');
    map.off('layeradd', openMarkerByQueryId);
  };

  useEffect(() => {
    map.on('layeradd', openMarkerByQueryId);
    return () => {
      map.off('layeradd', openMarkerByQueryId);
    };

    // eslint-disable-next-line
  }, [map]);
};
