import { ShareModalControlButton } from 'components/downloadModal/legacyShareModal';
import { ColorScale } from 'ui/colorScale';
import { Select } from 'ui/atoms';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useHandleGreneryObjectsSelectUseCase } from 'views/greenery/useCases/handleGreneryObjectsSelectUseCase';
import { useVegetationIndicators } from 'views/greenery/vegetation/indicators/useVegetationIndicators';
import { VegetationTimeline } from 'views/greenery/vegetation/VegetationTimeline';
import { type ValueAndLabel } from 'common/types';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME, HOTSPOTS_OPTION_NAME } from 'common/navigation/constants';
import { VEGETATION_COMPARISON_COLORMAP } from 'domain/greenery/colors';
import {
  FOLIAGE_CONDITION_DESCRIPTION,
  VEGETATION_COMPARISON_COLORSCALE_RANGE_NAMES,
  VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS,
} from 'domain/greenery/configs';
import { VegetationLayerManager } from './layerManager';
import { VegetationViewOptionsSelect } from './VegetationViewOptionsSelect';
import { VegetationIndicatorsSelect } from './indicators/VegetationIndicatorsSelect';
import { greeneryControlsService } from '../services';
import { useGreeneryTimestamps } from '../useGreeneryTimestamps';
import { GreeneryInfoArea } from '../components';
import { useGreeneryDataProvider } from '../useGreeneryDataProvider';
import { GreeneryDataProvidersSelect } from '../GreeneryDataProvidersSelect';

export const Vegetation = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;
  const isHotspotViewActive: boolean = viewQuery === HOTSPOTS_OPTION_NAME;

  const { timestamps, areTimestampsLoading } = useGreeneryTimestamps();

  const { selectGreeneryObject } = useHandleGreneryObjectsSelectUseCase();

  const onGreeneryObjectChange = (option: ValueAndLabel) => {
    selectGreeneryObject(option);
  };

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();

  const { segmentationObjectsSelectData } = greeneryControlsService.useGreeneryObjectsOptions(greeneryObjects, false);

  const filteredGreeneryClasses = greeneryObjects.filter(
    (o) => !VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS.includes(o.name),
  );

  const areReportsActive = useGetAreReportsActiveUseCase();

  const { selectedIndicator } = useVegetationIndicators();

  const { isSentinelProvider } = useGreeneryDataProvider();

  // TODO
  return (
    <AreaDetailsLayout
      legend={
        !isHotspotViewActive &&
        !areReportsActive &&
        (isComparisonActive ? (
          <ColorScale
            colormap={VEGETATION_COMPARISON_COLORMAP}
            labels={VEGETATION_COMPARISON_COLORSCALE_RANGE_NAMES}
            segmented
          />
        ) : (
          selectedIndicator && (
            <ColorScale colormap={selectedIndicator.colormap} labels={selectedIndicator.labels} segmented />
          )
        ))
      }
      toolbarContent={
        <>
          <GreeneryInfoArea>
            {FOLIAGE_CONDITION_DESCRIPTION}
            {selectedIndicator?.description && (
              <div>
                <br />
                {selectedIndicator.description}
              </div>
            )}
          </GreeneryInfoArea>
          {!areReportsActive && (
            <>
              <GreeneryDataProvidersSelect />
              <VegetationIndicatorsSelect />
              <VegetationViewOptionsSelect />
            </>
          )}
          {isComparisonActive && !areReportsActive && (
            <Select
              {...{
                ...segmentationObjectsSelectData,
                options: filteredGreeneryClasses.map(({ name, label_name }) => ({ value: name, label: label_name })),
              }}
              name="greenery object selection"
              onChange={onGreeneryObjectChange}
              minWidth={210}
            />
          )}
        </>
      }
      toolboxContent={
        <>
          {!isComparisonActive && !areReportsActive && <LayerManagerControlButton />}
          {!isComparisonActive && !areReportsActive && !isHotspotViewActive && isSentinelProvider && (
            <ShareModalControlButton />
          )}
        </>
      }
    >
      <VegetationTimeline
        {...{
          timestamps,
          areTimestampsLoading,
          isComparisonActive,
          areReportsActive,
          lackOfSelectedOption: !segmentationObjectsSelectData.selectedOption?.value || !selectedIndicator,
        }}
      />
      {!isComparisonActive && !areReportsActive && <VegetationLayerManager />}
    </AreaDetailsLayout>
  );
};
