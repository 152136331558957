import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import { type MapPoint } from 'common/types/mapData';
import { isMapPointValid } from 'common/typeguards';
import { buildCoordinatesOnlyTooltipStringHelper } from 'common/utils/map';
import { MapTooltip } from 'ui/map/tooltip';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { type PointInfo, type SingleBandPointInfo } from 'services/titiler/types';

interface Props<T> {
  layersWithUrls?: (T & { url: string })[];
  setLayersPointInfo?: (array: PointInfo<T, SingleBandPointInfo>[]) => void;
  tooltipStringBuilder?: (tooltip: MapPoint) => string;
}

export const MapClick = <T,>({
  layersWithUrls,
  setLayersPointInfo,
  tooltipStringBuilder = buildCoordinatesOnlyTooltipStringHelper,
}: Props<T>) => {
  const [mapPoint, setMapPoint] = useState<MapPoint | null>(null);

  const map = useMap();
  const currentZoom = map.getZoom();

  useEffect(() => {
    map.off('click');
    map.off('mousemove');

    map.on('click', async (event: { originalEvent: MouseEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);
      const point = latLng && isMapPointValid(latLng) ? latLng : null;

      setMapPoint(point);
    });

    return () => {
      map.off('click');
      map.off('mousemove');
    };
  }, [map, currentZoom]);

  useEffect(() => {
    const fetchPointInfo = async () => {
      try {
        if (!mapPoint || !layersWithUrls || !setLayersPointInfo) {
          return;
        }

        const pointInfo = await titilerApiService.getPointInfoArray(layersWithUrls, mapPoint.lng, mapPoint.lat);
        setLayersPointInfo(pointInfo);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPointInfo();
  }, [mapPoint, layersWithUrls, setLayersPointInfo]);

  if (mapPoint && tooltipStringBuilder) {
    return <MapTooltip tooltipData={{ id: 'generic-tooltip', ...mapPoint }} content={tooltipStringBuilder(mapPoint)} />;
  }

  return null;
};
