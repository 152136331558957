import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useQueryParameter } from 'common/navigation/hooks';
import { useTimelineService } from 'components/Timeline/timelineService';
import { GREENERY_OBJECT_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { vegetationDataService } from 'views/greenery/vegetation/vegetationDataService';

export const useGetVegetationComparatorCogUseCase = () => {
  const segmentationClass = useQueryParameter(GREENERY_OBJECT_QUERY_PARAMETER);

  const { timestamp, secondTimestamp } = useTimelineService();
  const { greeneryResults: firstDateResults } = vegetationDataService.useFetchVegetationResults(timestamp);
  const { greeneryResults: secondDateResults } = vegetationDataService.useFetchVegetationResults(secondTimestamp);

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();
  const segmentationClassId = greeneryObjects.find(({ name }) => name === segmentationClass)?.id;

  const firstResultId = firstDateResults.find(
    ({ greenery_object_id }) => greenery_object_id === segmentationClassId,
  )?.id;

  const secondResultId = secondDateResults.find(
    ({ greenery_object_id }) => greenery_object_id === segmentationClassId,
  )?.id;

  const { comparatorCogUrl, isComparatorCogLoading, setComparatorCogLoadingEnabled } =
    greeneryRepository.useGetVegetationComparatorCog(firstResultId, secondResultId, segmentationClass);

  const isCompareCogsDisabled =
    !!comparatorCogUrl || isComparatorCogLoading || !firstResultId || !secondResultId || !segmentationClass;

  const updateComparatorCog = () => {
    setComparatorCogLoadingEnabled(true);
    setTimeout(() => setComparatorCogLoadingEnabled(false));
  };

  return { comparatorCogUrl, isComparatorCogLoading, isCompareCogsDisabled, updateComparatorCog };
};
