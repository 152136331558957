import { useEffect, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import * as map from 'components/map';

interface MarkerProps {
  center: number[];
  color: string;
}

export const Marker = ({ center, color }: MarkerProps) => {
  const MarkerCore = () => {
    const map = useMap();

    const backdrop = L.circle(center, {
      radius: 12,
      fillColor: '#252525',
      fillOpacity: 0.5,
      weight: 0,
      pane: 'backdropLayer',
    });

    const border = L.circle(center, {
      radius: 6,
      fillColor: '#fff',
      fillOpacity: 1,
      weight: 0,
      pane: 'borderLayer',
    });

    const point = L.circle(center, {
      radius: 5,
      fillColor: color,
      fillOpacity: 1,
      weight: 0,
      pane: 'pointLayer',
    });

    const layers = useMemo(() => [backdrop, border, point], [backdrop, border, point]);

    useEffect(() => {
      layers.forEach((layer) => layer.addTo(map));

      return () => {
        layers.forEach((layer) => layer.remove());
      };
    }, [map, point, layers]);

    return null;
  };

  return (
    <map.Layer name={'backdropLayer'} zIndex={550}>
      <map.Layer name={'borderLayer'} zIndex={551}>
        <map.Layer name={'pointLayer'} zIndex={552}>
          <MarkerCore />
        </map.Layer>
      </map.Layer>
    </map.Layer>
  );
};
