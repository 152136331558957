import { type ChangeEvent, useEffect, useRef } from 'react';
import env from 'react-dotenv';

import { useUserStore } from 'infrastructure/user/userStore';
import { useCaptchaValidationService } from 'services/captchaValidationService';
import { useHandleResetPasswordUseCase } from 'views/user/handleResetPasswordUseCase';
import { type LabeledInputVariant } from 'ui/molecules/labeledInput/LabeledInput';
import { useGetIssueProps } from 'views/user/useGetIssueProps';

export const useForgotPasswordService = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const captchaKey = env.REACT_APP_RECAPTCHA_KEY;

  if (!captchaKey) {
    throw new Error('REACT_APP_RECAPTCHA_KEY is not provided');
  }

  const { resetPasswordRequestStatus, setResetPasswordRequestStatus } = useUserStore();

  const { captchaRef, captchaToken, onCaptchaValidate, onCaptchaExpiry } = useCaptchaValidationService();

  const { email, setEmail, emailError, submitForm, isLoading, data, error } =
    useHandleResetPasswordUseCase(captchaToken);

  const disableSubmitButton = !email.length || !captchaToken;

  const handleCaptchaReset = () => {
    captchaRef.current?.reset();
    onCaptchaValidate();
  };

  useEffect(() => {
    if (data) {
      setResetPasswordRequestStatus('SUCCESS');
      handleCaptchaReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      if (error.toString().includes('No internet connection')) {
        setResetPasswordRequestStatus('OFFLINE');
        return;
      }

      setResetPasswordRequestStatus('ERROR');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const { getIssueProps } = useGetIssueProps(formRef, email, resetPasswordRequestStatus);

  const inputElementProps = {
    variant: 'PRIMARY' as LabeledInputVariant,
    value: email,
    name: 'email',
    label: undefined,
    placeholder: 'Enter e-mail here',
    error: emailError,
    width: 240,
    setValue: (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
  };

  const formElement = {
    captchaKey,
    ref: formRef,
    captchaRef,
    onCaptchaValidate,
    onCaptchaExpiry,
    handleSubmit: submitForm,
  };

  const forgotPasswordService = {
    isLoading,
    resetPasswordRequestStatus,
    issueProps: getIssueProps(),
    inputElementProps,
    formElement,
    disableSubmitButton,
    setDefaultEmail: setEmail,
    handleTryAgainButtonClick: () => {
      setResetPasswordRequestStatus('IDLE');
      handleCaptchaReset();
    },
    handleCaptchaReset,
  };

  return forgotPasswordService;
};
