/* eslint-disable @typescript-eslint/no-explicit-any */
import 'dygraphs/src/extras/smooth-plotter.js';

import { DEFAULT_MAIN_GRAPH_PRECISION } from 'common/constants';

export const mainGraphConfig = {
  axes: {
    x: {
      drawGrid: false,
      axisLabelFormatter: (dateNumber: number) => {
        const date = new Date(dateNumber);
        return `${date.getMonth() + 1}.${date.getFullYear()}`;
      },
    },
    y: {
      axisLabelWidth: 40,
    },
    y2: {
      drawAxis: false,
    },
  },
  height: 160,
  width: '100%',
  color: 'white',
  hideOverlayOnMouseOut: true,
  legend: 'follow',
  legendFollowOffsetX: 10,
  legendFollowOffsetY: -50,
  axisLineColor: 'transparent',
  labelsSeparateLines: true,
  resizable: 'horizontal',
  drawPoints: true,
  strokeWidth: 0.0,
  digitsAfterDecimal: DEFAULT_MAIN_GRAPH_PRECISION,
  yRangePad: 10,
};
