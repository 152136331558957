import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { type ValueAndLabel } from 'common/types';
import {
  getDefaultRouteByAreaType,
  useAoiNumber,
  useHandleQueryParameterByPrimitiveValue,
} from 'common/navigation/hooks';
import { DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useMapStore } from 'components/map/mapStore';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { parseEnvironmentNameToViewUrl } from 'domain/areas/helpers';
import { AREA_PATH_NAME } from 'domain/areas/constants';

export const useChangeAreaUseCase = () => {
  const areaId = useAoiNumber();

  const [nextAreaView, setNextAreaView] = useState<{
    areaId: number;
    url: string;
  }>({ areaId, url: '' });

  const location = useLocation();
  const navigate = useNavigate();

  const setMapInOperation = useMapStore.use.setMapInOperation();

  const isSidebarUnlocked = useSidebarStore.use.isSidebarUnlocked();
  const hideSidebar = useSidebarStore.use.foldSidebar();

  const { areaOfInterestDetails, areaOfInterestDetailsLoading: isNextAreaDetailsLoading } =
    areasRepository.useFetchAreaOfInterestDetails(Number(nextAreaView.areaId));

  const clearReports = useReportsStore.use.clearReports();

  const [_, setSelectedDepositSite] = useHandleQueryParameterByPrimitiveValue(
    DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER,
  );

  const changeArea = (option: ValueAndLabel) => {
    setMapInOperation();
    clearReports();
    setSelectedDepositSite(undefined);

    setNextAreaView({
      areaId: Number(option.value),
      url: location.pathname.replace(String(areaId), ':areaId'),
    });

    isSidebarUnlocked && location.pathname.startsWith(`/${AREA_PATH_NAME}`) && hideSidebar();
  };

  useEffect(() => {
    if (areaOfInterestDetails && nextAreaView.url) {
      const url = areaOfInterestDetails.properties.environments.some((environment) =>
        nextAreaView.url.includes(parseEnvironmentNameToViewUrl(environment)),
      )
        ? nextAreaView.url
        : getDefaultRouteByAreaType(areaOfInterestDetails?.properties.type);

      navigate(url.replace(':areaId', String(nextAreaView.areaId)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaOfInterestDetails, nextAreaView]);

  return { changeArea, isNextAreaDetailsLoading };
};
