import { type ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from 'common/navigation/routes';

type PrivateRouteProps = {
  isAllowed: boolean;
  redirectPath: string;
  children: ReactNode;
};

export const PrivateRoute = ({ isAllowed = false, redirectPath = ROUTES.login, children }: PrivateRouteProps) => {
  const location = useLocation();

  if (!isAllowed) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
