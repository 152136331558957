export const RESEARCH_ML_STORAGE_URL = process.env.REACT_APP_RESEARCH_ML_STORAGE_URL || '';
export const PUBLIC_CONTAINER_URL = process.env.REACT_APP_PUBLIC_CONTAINER_URL;

export const LOCAL_STORAGE_REFRESH_TOKEN_NAME = 'RSOM_refresh_token';
export const LOCAL_STORAGE_ACCESS_TOKEN_NAME = 'RSOM_access_token';
export const LOCAL_STORAGE_ACCEPT_COOKIE = 'RSOM_cookies_allowed';

export const LOCAL_STORAGE_SIDEBAR_UNLOCK_NAME = 'RSOM_sidebar_unlocked';
export const LOCAL_STORAGE_INFO_AREA_VISIBILITY_NAME = 'RSOM_info_area_visible';
export const LOCAL_STORAGE_MAP_CENTER_NAME = 'last_map_center';

export const LOCAL_STORAGE_IS_SUBSCRIPTION_STATUS_PENDING = 'RSOM_isSubscriptionStatusPending';
export const LOCAL_STORAGE_IS_CANCELING_SUBSCRIPTION = 'RSOM_isCancelingSubscription';

export const SUBSCRIPTION_CANCEL_CLEANUP_TIMEOUT = 1_000 * 60 * 2;

export const TOKEN_REFRESH_MAX_AGE = 10_000;

export const BACKEND_PREFIX = '/api/v1';

export const DEFAULT_RESULTS_PER_PAGE = 500;
