import { useCallback, useEffect, useState } from 'react';

import { type AreaOfInterest } from 'domain/areas/types';

export const useCardVisibleSpaceIntersectUseCase = (
  area: AreaOfInterest,
  observerElement: React.MutableRefObject<HTMLDivElement | null>,
) => {
  const [areaId, setAreaId] = useState<number | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleObserver = useCallback((entries: any) => {
    const [target] = entries;
    if (target.isIntersecting) {
      setAreaId(area.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    const element = 'current' in observerElement ? observerElement.current : null;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);

    element && observer.observe(element);

    return () => element && observer.unobserve(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { areaId };
};
