import { type ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/svg/infoIcon.svg';

import { Tooltip, type TooltipPlacement } from '../tooltip';

export type InfoPointColor = 'mineShaft' | 'selectGray' | 'midGray';

interface InfoPointProps {
  name: string;
  tooltipContent: ReactNode;
  color?: InfoPointColor;
  tooltipPlacement?: TooltipPlacement;
}

export const InfoPoint = ({ name, tooltipContent, color = 'mineShaft', tooltipPlacement }: InfoPointProps) => (
  <InfoPointWrapper>
    <Tooltip content={tooltipContent} interactive placement={tooltipPlacement}>
      <InfoPointBody data-testid={`info-point-${name || 'generic'}`} $color={color}>
        <InfoIcon />
      </InfoPointBody>
    </Tooltip>
  </InfoPointWrapper>
);

const InfoPointWrapper = styled.div`
  color: ${({ theme }) => theme.color.white};
  opacity: 1;
`;

const InfoPointBody = styled.div<{ $color?: InfoPointColor }>`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.color[props.$color!]};
  border-radius: 50%;
  display: grid;
  place-items: center;

  svg {
    height: 12px;
  }
`;
