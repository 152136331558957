import styled from 'styled-components';
import { Button } from 'ui';

interface SelectionPanelProps {
  description: React.ReactNode;
  handleConfirm: () => void;
  handleCancel: () => void;
}

export const SelectionPanel = ({ description, handleConfirm, handleCancel }: SelectionPanelProps) => {
  return (
    <Wrapper>
      <DescriptionWrapper>{description}</DescriptionWrapper>
      <Button onClick={handleConfirm} minWidth={210}>
        Confirm Selection
      </Button>
      <Button onClick={handleCancel} variant="SECONDARY">
        Cancel
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.color.mineShaftDarkBlur};
  backdrop-filter: blur(10px);
  padding: 20px;
`;

const DescriptionWrapper = styled.p`
  text-align: right;
  padding: 0;
  margin: 0;
  margin-right: 14px;

  @media (max-width: 1320px) {
    text-align: start;

    br {
      display: none;
    }
  }
`;
