import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { type Feature } from 'geojson';

import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';
import { InfoArea } from 'ui/infoArea';

interface AreaRequestPreviewProps {
  features: Feature[];
  initialVisible?: boolean;
}

export const AreaRequestPreview = ({ features, initialVisible = true }: AreaRequestPreviewProps) => {
  const [previewVisible, setPreviewVisible] = useState(false);

  const togglePreviewVisible = () => setPreviewVisible(!previewVisible);

  useEffect(() => {
    setPreviewVisible(features.length ? initialVisible : false);
  }, [features, initialVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PositoningWrapper>
      <InfoArea
        geometry={{ width: 270, topOffset: 40, zIndex: 500, lowerClearance: 20 }}
        isInfoAreaVisible={previewVisible}
        toggleInfoAreaVisibility={togglePreviewVisible}
        errors={undefined}
      >
        <PreviewTitle>about selection</PreviewTitle>
        <PreviewWrapper>
          <pre>{JSON.stringify(features[0] || {}, null, 2)}</pre>
        </PreviewWrapper>
      </InfoArea>
    </PositoningWrapper>
  );
};

const PositoningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const PreviewTitle = styled.h3`
  color: ${({ theme }) => theme.color.white};
  font-family: Barlow;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const PreviewWrapper = styled(ScrollbarWrapper)`
  max-height: calc(100vh - 400px);
  min-height: 100px;
  margin: 0 0 -12px;

  ::-webkit-scrollbar-track {
    margin: 4px 0 12px;
    background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  }
`;
