import { useGraphStore } from 'components/graph/graphStore';
import { usePointHistory } from 'components/graph/usePointHistory';
import { useReportWeatherDataError } from 'components/graph/useReportWeatherDataError';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { type OpenedMarkerData } from 'domain/displacements/types';
import {
  parseGraphDataFromCSV,
  getDygraphData,
  getDygraphInfo,
  getTemporalCoherence,
  getVelocity,
  getCumulativeDisplacement,
} from 'domain/displacements/helpers/parserGeoJsonValue';
import { DISPLACEMENTS_PATH_NAME } from 'domain/displacements/constants';

export const useDisplacementsGraphData = () => {
  const setChosenPoint = useGraphStore.use.setChosenPoint();
  const setGraphInfoJsx = useDisplacementsStore.use.setGraphInfoJsx();

  const { setPointHistoryUseCase } = usePointHistory();
  const { reportError } = useReportWeatherDataError(DISPLACEMENTS_PATH_NAME);

  const setGraphData = (openedMarkerData: OpenedMarkerData) => {
    const { dygraph, dygraphInfo, coordinateString } = getDygraphInfo(openedMarkerData);
    const diagGeoData = getDygraphData(dygraph);
    const velocity = getVelocity(dygraphInfo);
    const temporalCoherence = getTemporalCoherence(dygraphInfo);

    const graphData = parseGraphDataFromCSV(diagGeoData);
    setPointHistoryUseCase(graphData, reportError);
    setGraphInfoJsx(() => (
      <>
        <span>{coordinateString}</span>
        <span>{velocity}</span>
        <span>Spatial coherence: {temporalCoherence}</span>
      </>
    ));
  };

  const setPointData = (openedMarkerData: OpenedMarkerData) => {
    const { dygraphInfo } = getDygraphInfo(openedMarkerData as OpenedMarkerData);
    const cumulativeDisplacement = Number(getCumulativeDisplacement(dygraphInfo));

    setChosenPoint({
      value: cumulativeDisplacement,
      lat: openedMarkerData.geometry.coordinates[0],
      lng: openedMarkerData.geometry.coordinates[1],
      id: openedMarkerData?.id,
    });
  };

  return { setGraphData, setPointData };
};
