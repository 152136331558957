import { type ColormapDictionary } from 'common/types';

export const WATER_QUALITY_COLORMAP: ColormapDictionary = {
  0: '#85acd8',
  0.17: '#8587ec',
  0.33: '#9c68e3',
  0.5: '#c768ad',
  0.67: '#fe6869',
  0.83: '#fea768',
  1: '#fcf868',
};

export const WATER_QUALITY_NORMALIZED_COLORMAP: ColormapDictionary = {
  0: '#2cb3fa',
  0.17: '#7dd0b7',
  0.33: '#b4e58a',
  0.5: '#fbfd4e',
  0.67: '#fccb33',
  0.83: '#fda01c',
  1: '#ff7103',
};

export const IRON_OXIDE_COLORMAP: ColormapDictionary = {
  0: '#add8e6',
  0.1: '#c7eae5',
  0.2: '#f7eff7',
  0.4: '#ffbebe',
  0.6: '#ff7f7f',
  0.8: '#ff0000',
  1: '#e60000',
};

export const WATER_AREA_COLORMAP: ColormapDictionary = {
  0: '#07BAFF',
  1: '#07BAFF',
};

export const WATER_AREA_COMPARISON_COLORMAP: ColormapDictionary = {
  0: '#FF6868',
  0.5: '#07BAFF',
  1: '#A3FF5B',
};

export const WATER_AREA_REPORT_COLORMAP: ColormapDictionary = {
  0: '#0045F8',
  1: '#0045F8',
};

export const WATERMASKS_MAIN_COLOR: string = '#07BAFF';
export const WATERMASKS_REPORT_COLOR: string = '#0045F8';

export const WATER_AREA_GAIN_COLOR: string = '#A3FF5B';
export const WATER_AREA_LOSS_COLOR: string = '#FF6868';

export const SNOW_COVER_COLOR: string = '#09F2D2';
