import styled from 'styled-components';

import { areasRepository } from 'infrastructure/areas/areasRepository';
import { LoadingWave } from 'ui/atoms/loadingWave';
import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';
import { RequestSuccessModal } from 'ui/modal';
import { Loading } from 'ui/molecules/loading';
import { AddNewAreaCard } from 'views/areas/areasList/AddNewAreaCard';
import { AreaCard } from 'views/areas/areasList/AreaCard';
import { useAreasLifecycleService } from 'views/areas/areasList/areasLifecycleService';
import { areasListQueryService } from 'views/areas/areasList/areasListQueryService';
import { useGetAreasListErrors } from 'views/areas/areasList/getAreasListErrors';
import { getLoadingCardsArray } from 'views/areas/areasList/getLoadingCardsArray';
import {
  AREAS_LIST_COLUMNS_BREAKPOINT,
  AREAS_LIST_OFFSET_TOP,
  AREA_CARD_GAP,
  AREA_CARD_MIN_HEIGHT,
} from 'domain/areas/constants';
import { useRequestedArea } from './useRequestedArea';

export const AreasList = () => {
  const { areasList, areasListLoading } = areasRepository.useFetchAreasOfInterestList();
  const { filteredAreasList } = areasListQueryService.useGetFilteredList(areasList);

  const areasListErrors = useGetAreasListErrors();
  const loadingCards = getLoadingCardsArray();

  useAreasLifecycleService();

  const { isRequestLoading, isCalculationLoading, isSummaryModalOpen, modalType, closeSummaryModal } =
    useRequestedArea();

  return (
    <>
      <Wrapper>
        <StyledAreasList>
          <NoResults>
            {!areasListLoading &&
              (areasListErrors.length
                ? areasListErrors.map((error, key) => <Error key={key}>{error}</Error>)
                : filteredAreasList.length === 0 && <span>No results...</span>)}
          </NoResults>
          <List aria-label="areas list">
            <li key="add-new">
              <AddNewAreaCard />
            </li>
            {areasListLoading
              ? loadingCards.map((index) => (
                  <LoaderWrapper key={index} aria-busy="true">
                    <LoadingWave variant="CARD" />
                  </LoaderWrapper>
                ))
              : filteredAreasList.map((area) => (
                  <li key={area.id}>
                    <AreaCard key={area.id} area={area} />
                  </li>
                ))}
          </List>
        </StyledAreasList>
      </Wrapper>
      {isRequestLoading && <Loading zIndex={1250} customMessage="Uploading..." />}
      {isCalculationLoading && <Loading zIndex={1250} customMessage="Analyzing..." />}
      <RequestSuccessModal variant={modalType} isOpen={isSummaryModalOpen} closeModal={closeSummaryModal} />
    </>
  );
};

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  padding-right: 20px;
`;

const StyledAreasList = styled(ScrollbarWrapper)`
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    margin: 110px 0 20px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  @-moz-document url-prefix() {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.color.midGray} transparent;
    scrollbar-gutter: stable;
  }
`;

const List = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${AREA_CARD_GAP}px;
  margin: 0;
  padding: 20px 34px 20px 10px;

  @media (max-width: ${AREAS_LIST_COLUMNS_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const NoResults = styled.div`
  padding: ${AREAS_LIST_OFFSET_TOP - 22}px 0 0 10px;
  min-height: ${AREAS_LIST_OFFSET_TOP}px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.color.errorPink};
`;

const LoaderWrapper = styled.div`
  min-height: ${AREA_CARD_MIN_HEIGHT}px;
`;
