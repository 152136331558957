import {
  DARK_DARKEST_GRAYSCALE_SATELLITE_URL_MODIFIER,
  DARK_DARKER_GRAYSCALE_SATELLITE_URL_MODIFIER,
  DARK_NORMAL_GRAYSCALE_SATELLITE_URL_MODIFIER,
  DARK_LIGHTER_GRAYSCALE_SATELLITE_URL_MODIFIER,
  EARTH_RADIUS_KM,
  COORDINATES_PRECISION,
  NORMAL_SATELLITE_URL_MODIFIER,
  DARKER_SATELLITE_URL_MODIFIER,
  DARKEST_SATELLITE_URL_MODIFIER,
  LIGHTER_SATELLITE_URL_MODIFIER,
  DEFAULT_PIXEL_VALUE_PRECISION,
} from 'common/constants';
import {
  type BoundsInterface,
  type BoundsArray,
  type TooltipData,
  type MapPoint,
  type LeafletBounds,
  type DisplayableMapPoint,
  type MultilayerTooltipData,
} from 'common/types/mapData';
import {
  alterArrayElements,
  degreesToRadians,
  formatPayloadAsString,
  radiansToDegrees,
  simpleRound,
} from 'common/utils';

export const standarizeLatLng = <T extends [number, number]>([lng, lat]: T) => [lat, lng] as T;
export const standarizeLatLngArray = <T extends [number, number]>(latLngs: Array<T>) =>
  latLngs.map(standarizeLatLng<T>) as Array<T>;

export const calculatePolygonCenter = (polygon: number[][]): [number, number] => {
  const latsArray = polygon.map(([lat, _lng]) => lat);
  const lngsArray = polygon.map(([_lat, lng]) => lng);

  const average = (arr: number[]) => arr.reduce((a, b) => a + b, 0) / arr.length;

  const latAverage = average(latsArray);
  const lngAverage = average(lngsArray);

  return [latAverage, lngAverage];
};

export const buildCoordinatesOnlyTooltipStringHelper = (tooltip: MapPoint) => {
  return `
    <h4>coordinates</h4>
    <p>${tooltip.lat.toFixed(COORDINATES_PRECISION)}</p>
    <p>${tooltip.lng.toFixed(COORDINATES_PRECISION)}</p>
  `;
};

export const buildSingleViewTooltipStringHelper = (tooltip: TooltipData) => {
  return `
    <h4>coordinates</h4>
    <p>${tooltip.lat.toFixed(COORDINATES_PRECISION)}</p>
    <p>${tooltip.lng.toFixed(COORDINATES_PRECISION)}</p>
    ${
      tooltip.value !== undefined
        ? `
        <h4>value</h4>
        <p>${tooltip.value}</p>
      `
        : ''
    }
  `;
};

export const buildMultilayerViewTooltipStringHelper = (tooltip: MultilayerTooltipData) => {
  return `
    <h4>coordinates</h4>
    <p>${tooltip?.lat?.toFixed(COORDINATES_PRECISION)}</p>
    <p>${tooltip?.lng?.toFixed(COORDINATES_PRECISION)}</p>
    ${tooltip?.series
      ?.map(
        (serie) => `
        <h4>${serie.label}</h4>
        <p>${formatPayloadAsString(serie.value, DEFAULT_PIXEL_VALUE_PRECISION)}</p>
      `,
      )
      .join('')}
  `;
};

export const checkIsOutsideBounds = (latLng: MapPoint, bounds: LeafletBounds) =>
  !latLng ||
  !bounds ||
  latLng.lat < bounds._southWest.lat ||
  latLng.lat > bounds._northEast.lat ||
  latLng.lng < bounds._southWest.lng ||
  latLng.lng > bounds._northEast.lng;

export const calculateDistance = (point1: number[], point2: number[]): number => {
  const lat1 = degreesToRadians(point1[0]);
  const lon1 = degreesToRadians(point1[1]);
  const lat2 = degreesToRadians(point2[0]);
  const lon2 = degreesToRadians(point2[1]);

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceKM = EARTH_RADIUS_KM * c;

  return distanceKM;
};

export const calculateRectangleArea = (rectangle: number[][]) => {
  const [bottomLeft, topLeft, topRight] = rectangle;
  const distanceLatKM = calculateDistance(topLeft, bottomLeft);
  const distanceLngKM = calculateDistance(topLeft, topRight);
  return distanceLngKM * distanceLatKM;
};

export const getSquareBoundsAroundPoint = (point: [number, number], sideLengthKM: number) => {
  const dLat = radiansToDegrees(sideLengthKM / EARTH_RADIUS_KM);
  const dLng = radiansToDegrees(sideLengthKM / (EARTH_RADIUS_KM * Math.cos(degreesToRadians(point[0]))));

  const latMin = point[0] - dLat / 2;
  const latMax = point[0] + dLat / 2;

  const lngMin = point[1] - dLng / 2;
  const lngMax = point[1] + dLng / 2;

  const bounds = {
    nordWest: [latMax, lngMin],
    nordEast: [latMax, lngMax],
    southWest: [latMin, lngMin],
    southEast: [latMin, lngMax],
  };

  return {
    bounds: bounds as BoundsInterface,
    corners: [bounds.nordWest, bounds.southEast] as BoundsArray,
  };
};

export const getDarkGrayscaleSatelliteUrlModifier = (brightness: number) => {
  switch (true) {
    case brightness === 0:
      return DARK_DARKEST_GRAYSCALE_SATELLITE_URL_MODIFIER;
    case brightness === 1:
      return DARK_DARKER_GRAYSCALE_SATELLITE_URL_MODIFIER;
    case brightness === 2:
      return DARK_NORMAL_GRAYSCALE_SATELLITE_URL_MODIFIER;
    case brightness === 3:
      return DARK_LIGHTER_GRAYSCALE_SATELLITE_URL_MODIFIER;
    default:
      return DARK_NORMAL_GRAYSCALE_SATELLITE_URL_MODIFIER;
  }
};

export const getSatelliteUrlModifier = (brightness: number) => {
  switch (true) {
    case brightness === 0:
      return DARKEST_SATELLITE_URL_MODIFIER;
    case brightness === 1:
      return DARKER_SATELLITE_URL_MODIFIER;
    case brightness === 2:
      return NORMAL_SATELLITE_URL_MODIFIER;
    case brightness === 3:
      return LIGHTER_SATELLITE_URL_MODIFIER;
    default:
      return NORMAL_SATELLITE_URL_MODIFIER;
  }
};

export const alterFeatureCoordinates = (feature: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>) => {
  const coordinates = feature.geometry.coordinates;
  const alteredCoordinates = alterArrayElements(coordinates);

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: alteredCoordinates,
    },
  };
};

export const getDisplayableMapPoint = (
  lngLat: [number, number],
  precision = COORDINATES_PRECISION,
): DisplayableMapPoint => {
  const lat = simpleRound(lngLat[1], precision);
  const lng = simpleRound(lngLat[0], precision);

  return { lat, lng };
};
