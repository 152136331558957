import { DataLayer } from 'components/map';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { type ColormapDictionary } from 'common/types';
import { Loading } from 'ui/molecules/loading';

export const WaterQualityDataLayer = ({
  url,
  colormap,
  range,
}: {
  url: string;
  colormap: ColormapDictionary;
  range: number[];
}) => {
  const { cogUrl, extentLatLngBounds, isInfoLoading } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildDiscreteColorScaleFloatCogUrl,
    url,
    {
      range,
      colormapObject: colormap,
      noDataValue: 'nan',
    },
  );

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!cogUrl || !extentLatLngBounds) {
    return null;
  }

  return (
    <DataLayer
      isVisible={true}
      layerName={'waterQualityLayer'}
      zIndex={490}
      cogUrl={cogUrl}
      bounds={extentLatLngBounds}
    />
  );
};
