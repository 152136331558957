import { type DoubleDateSelectVariant } from '../../types';

export const getFirstSelectTimestamps = (timestamps: string[], variant: DoubleDateSelectVariant): string[] => {
  switch (variant) {
    case 'NEW_TO_OLDER':
      return timestamps.slice(0, -1);
    case 'OLD_TO_NEWER':
      return timestamps.slice(1);
    default:
      return timestamps;
  }
};

export const getSecondSelectTimestamps = (
  timestamps: string[],
  timestamp: string,
  variant: DoubleDateSelectVariant,
): string[] => {
  switch (variant) {
    case 'NEW_TO_OLDER':
      return timestamps.filter((item) => item < timestamp);
    case 'OLD_TO_NEWER':
      return timestamps.filter((item) => item > timestamp);
    default:
      return timestamps.filter((item) => item !== timestamp);
  }
};

export const isSecondSelectTimestampForbidden = (
  timestamp: string,
  secondTimestamp: string,
  variant: DoubleDateSelectVariant,
): boolean => {
  switch (variant) {
    case 'NEW_TO_OLDER':
      return secondTimestamp >= timestamp;
    case 'OLD_TO_NEWER':
      return secondTimestamp <= timestamp;
    default:
      return timestamp === secondTimestamp;
  }
};
