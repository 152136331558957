import { type ReactNode } from 'react';
import styled from 'styled-components';

interface TimelineWrapperProps {
  children: ReactNode;
}

export const TimelineWrapper = ({ children }: TimelineWrapperProps) => (
  <>
    <StyledTimelineWrapper>{children}</StyledTimelineWrapper>
    <TimelineDropShadow />
  </>
);

export const StyledTimelineWrapper = styled.div`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0px 25px 25px 35px;
  text-align: right;
  z-index: 1002;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-start;
  transition: left 500ms ease-in-out;
`;

const TimelineDropShadow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 990;
  -webkit-box-shadow: 0px 36px 64px 64px ${({ theme }) => theme.color.blackTransparent78};
  -moz-box-shadow: 0px 36px 64px 64px ${({ theme }) => theme.color.blackTransparent78};
  box-shadow: 0px 36px 64px 64px ${({ theme }) => theme.color.blackTransparent78};
`;
