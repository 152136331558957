import { useLocation } from 'react-router-dom';

import { WarningErrorItem, WarningErrorList } from 'components';
import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { ErrorsModel } from 'domain/@errors/ErrorsModel';
import { initialWarningErrors } from 'domain/@errors/constants';

export const useInfoAreaWarningErrorsService = () => {
  const { pathname } = useLocation();

  const warningErrors = useErrorsStore.use.warningErrors();
  const isListVisible = useErrorsStore.use.isWarningErrorListVisible();
  const toggleListVisibility = useErrorsStore.use.toggleWarningErrorListVisibility();

  const getErrorsUseCase = () => {
    const errors = Object.values(warningErrors);
    const activeErrors = ErrorsModel.getAvailableCombinedWarningErrors(initialWarningErrors, errors, pathname);

    const { displayableErrors, infoAreaIconColor, isInfoAreaToShow } =
      ErrorsModel.getDisplayableWarningErrors(activeErrors);

    if (!displayableErrors.length) {
      return undefined;
    }

    const errorItems = displayableErrors.map(({ priority, content }, key) =>
      WarningErrorItem({ priority, content, key }),
    );

    const errorList = WarningErrorList({
      errorItems,
      isListVisible,
      toggleListVisibility,
    });

    return { errorList, infoAreaIconColor, isInfoAreaToShow };
  };

  const errors = getErrorsUseCase();

  return errors;
};
