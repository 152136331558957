import styled, { css } from 'styled-components';

import { ReactComponent as CrossIcon } from 'assets/svg/cross-icon-thin.svg';
import { ReactComponent as MaximizeIcon } from 'assets/svg/maximize.svg';
import { SIDEBAR_MIN_WIDTH, SIDEBAR_PANEL_MAX_WIDTH } from 'common/constants';
import { theme } from 'common/theme';
import { Backdrop as BaseBackdrop } from 'ui/atoms/backdrop';
import { IconButton } from 'ui/atoms/iconButton';
import type { SidebarVariants } from './types';
import { useSidebarStore } from './sidebarStore';
import { useChatStore } from '../chat';

interface SidebarPanelLayoutProps {
  sidebarVariant: SidebarVariants;
  content?: {
    title: string;
    icon: JSX.Element;
    component: JSX.Element;
  };
}

export const SidebarPanelLayout = ({ sidebarVariant, content }: SidebarPanelLayoutProps) => {
  const isSidebarPanelOpen = useSidebarStore.use.isSidebarPanelOpen();
  const closeSidebarPanel = useSidebarStore.use.closeSidebarPanel();
  const sidebarPanelType = useSidebarStore((state) => state.sidebarPanelType);

  const openChatFullscreen = useChatStore.use.openChatFullscreen();

  return (
    <>
      <SidebarPanelLayoutWrapper isOpen={isSidebarPanelOpen} sidebarVariant={sidebarVariant}>
        <Header>
          <Icon>{content?.icon}</Icon>
          <Title>{content?.title}</Title>
        </Header>

        <CloseButtonWrapper>
          <IconButton onClick={closeSidebarPanel} $iconSize={18} icon={<CrossIcon />} />
        </CloseButtonWrapper>

        {sidebarPanelType === 'ASSISTANT_PANEL' && (
          <MaximizeButtonWrapper>
            <IconButton icon={<MaximizeIcon />} $iconSize={26} onClick={openChatFullscreen} />
          </MaximizeButtonWrapper>
        )}

        {content?.component}
      </SidebarPanelLayoutWrapper>

      <Backdrop onClick={closeSidebarPanel} zIndex={-2} isOpen={isSidebarPanelOpen} />
    </>
  );
};

const transitionFunction = theme.transitionFunction.main;

interface SidebarPanelLayoutWrapper {
  isOpen: boolean;
  sidebarVariant: SidebarVariants;
}

const SidebarPanelLayoutWrapper = styled.section<SidebarPanelLayoutWrapper>`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: -${SIDEBAR_PANEL_MAX_WIDTH}px;
  width: ${SIDEBAR_PANEL_MAX_WIDTH}px;
  min-height: 100vh;
  padding: 12px 0 0;
  word-break: break-word;
  background-color: ${({ theme }) => theme.color.mineShaftDark};
  transition: left ${transitionFunction}, padding ${transitionFunction};

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    left: ${SIDEBAR_MIN_WIDTH}px;
    top: 0;
    bottom: 0;
    width: 1px;
    background: ${({ theme }) => theme.color.selectGray};
    opacity: 0;
    visibility: hidden;
    transition: all ${transitionFunction};
  }

  ${({ sidebarVariant }) =>
    sidebarVariant === 'BARE' &&
    css`
      width: ${SIDEBAR_MIN_WIDTH + SIDEBAR_PANEL_MAX_WIDTH}px;
      left: -${SIDEBAR_MIN_WIDTH + SIDEBAR_PANEL_MAX_WIDTH}px;
    `}

  ${({ isOpen, sidebarVariant }) =>
    isOpen &&
    sidebarVariant !== 'BARE' &&
    css`
      left: 100%;
    `}

  ${({ isOpen, sidebarVariant }) =>
    sidebarVariant === 'BARE' &&
    isOpen &&
    css`
      left: 0;
      padding: 12px 0 0 ${SIDEBAR_MIN_WIDTH}px;

      &::before {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 60px 0 20px;
`;

const Icon = styled.div`
  button {
    cursor: auto;
    pointer-events: none;
    word-break: normal;
  }
`;

const Title = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  width: 100%;
  word-wrap: break-word;
  color: ${({ theme }) => theme.color.white};
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const MaximizeButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 50px;
`;

const Backdrop = styled(BaseBackdrop)<{ isOpen: boolean }>`
  animation: none;
  opacity: 0;
  visibility: hidden;
  background-color: ${({ theme }) => theme.color.blackTransparent60};
  transition: opacity ${transitionFunction}, visibility ${transitionFunction};

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;
