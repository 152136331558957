import { useAoiNumber } from 'common/navigation/hooks';
import { useWaterAreaResults } from 'views/water/surface/useWaterAreaResults';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { ProviderType, type WaterAreaRemappedResult } from 'domain/water/types';

export const useGetWaterAreaRemappedResults = () => {
  const areaId = useAoiNumber();

  const { waterBodiesList } = waterRepository.useFetchWaterbodies(areaId);

  const { waterAreaResults, waterAreaCogsResponse, areWaterAreaResultsLoading } = useWaterAreaResults();

  const remappedResults: WaterAreaRemappedResult[] = waterAreaResults.map((result) => {
    return {
      ...result,
      cog_url: waterAreaCogsResponse.find((item) => item?.id === result.id)?.cog_url || '',
      label: waterBodiesList.find((item) => item.id === result.waterbody_id)?.name || '',
      value: result.waterbody_id,
      provider: result.provider || ProviderType.sentinel_2,
    };
  });

  return { remappedResults, areRemappedResultsLoading: areWaterAreaResultsLoading };
};
