import { useExtractionTimestamps } from 'views/mine/useExtractionTimestamps';
import { useTimeline } from 'components/Timeline/useTimeline';
import { Timeline } from 'components/Timeline/Timeline';
import { Graph } from 'components/graph';

interface ExtractionTimelineProps {
  areReportsActive: boolean;
}

export const ExtractionTimeline = ({ areReportsActive }: ExtractionTimelineProps) => {
  const { timestamps } = useExtractionTimestamps();

  const {
    filteredTimestamps,
    firstDate,
    lastDate,
    rangeValue,
    selectedTimestamps,
    setRangeValue,
    isTimelineShowed,
    firstTimestamp,
    lastTimestamp,
  } = useTimeline(timestamps, !areReportsActive);

  return (
    <>
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          visible: !areReportsActive,
        }}
      />
      {!areReportsActive && (
        <Graph
          isTimelineVisible={isTimelineShowed}
          dateRange={{ firstDate: firstTimestamp, lastDate: lastTimestamp }}
        />
      )}
    </>
  );
};
