import styled from 'styled-components';

import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';

interface Props {
  url: string;
  bidxArray: string[];
  rescaleArray: number[][];
}

export const AreaDetailsSidebarImage = ({ url, bidxArray, rescaleArray }: Props) => {
  const aoiImageUrl = titilerApiAdapter.buildGrayscaleTitilerImageUrl(url, {
    bidx: bidxArray[0],
    rescale: rescaleArray[0],
    maxSize: 512,
    imageHeight: 300,
    imageWidth: 220,
    colorMap: 'gray',
  });

  return <StyledAreaPreview src={aoiImageUrl} alt="Area preview" />;
};

const StyledAreaPreview = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
