import { DataLayer } from 'components/map';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { BiomassTitilerTooltip } from 'views/greenery/components/BiomassTitilerTooltip';
import { BIOMASS_COLORMAP } from 'domain/greenery/colors';
import { BIOMASS_OPACITY } from 'domain/greenery/configs';

interface BiomassDataLayerProps {
  url: string;
  isVisible: boolean;
}

export const BiomassDataLayer = ({ url, isVisible }: BiomassDataLayerProps) => {
  const cogUrl = titilerApiAdapter.buildDefinedColorScaleFloatCogUrl(url, {
    range: [0, 250],
    colormapObject: BIOMASS_COLORMAP,
    noDataValue: 'nan',
  });

  const { info } = titilerInfoService.useGetInfoGeneric(getAzureBlobStorageUrl(url));

  const bounds = getBoundsFromCogInfo(info);

  return (
    <>
      <DataLayer
        isVisible={isVisible}
        layerName={'biomassLayer-100'}
        zIndex={440}
        cogUrl={cogUrl}
        bounds={bounds}
        opacity={BIOMASS_OPACITY}
      />
      {isVisible && <BiomassTitilerTooltip url={url} />}
    </>
  );
};
