import dayjs from 'dayjs';
import { type ValueAndLabel } from 'common/types';
import { DEFAULT_DATE_FORMAT_DOT } from 'common/constants/datetime';

export const convertTimestampToSelectOption = (timestamp: string): ValueAndLabel => {
  return {
    value: timestamp,
    label: dayjs(timestamp).format(DEFAULT_DATE_FORMAT_DOT),
  };
};

export const mapTimestampsToSelectOptions = (timestamps: string[]): ValueAndLabel[] => {
  return timestamps.map((timestamp) => ({
    value: timestamp,
    label: dayjs(timestamp).format(DEFAULT_DATE_FORMAT_DOT),
  }));
};
