import { Button, Container } from 'ui';
import { useLineamentsLayerManagerStore } from 'views/exploration/lineaments/layerManager/lineamentsLayerManagerStore';

export const LineamentsSelectionButtons = () => {
  const isLineamentsSelectionMode = useLineamentsLayerManagerStore.use.isLineamentsSelectionMode();
  const enableLineamentsSelectionMode = useLineamentsLayerManagerStore.use.enableLineamentsSelectionMode();
  const clearSelectedLineaments = useLineamentsLayerManagerStore.use.clearSelectedLineaments();
  const selectedLineamentsSaved = useLineamentsLayerManagerStore.use.selectedLineamentsSaved();

  return (
    <Container gap={10} mt={10} pt={4}>
      <Button
        variant="SECONDARY_SMALL_DARK"
        onClick={enableLineamentsSelectionMode}
        disabled={isLineamentsSelectionMode}
        minWidth={140}
      >
        {selectedLineamentsSaved.length ? 'Change Selection' : 'Select Lineaments'}
      </Button>
      <Button
        variant="SECONDARY_SMALL_DARK"
        onClick={clearSelectedLineaments}
        disabled={isLineamentsSelectionMode || !selectedLineamentsSaved.length}
        minWidth={65}
      >
        Reset
      </Button>
    </Container>
  );
};
