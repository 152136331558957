import { useEffect } from 'react';

import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { Loading } from 'ui/molecules/loading';
import { VHR_INITIALY_AVAILABLE_LAYERS } from 'domain/greenery/configs';
import { vhrDataService } from './vhrDataService';
import { VhrMapDataLayer } from './VHRMapDataLayer';

export const VHRMapContent = () => {
  const { vhrObjectsData, isLoading: isVhrObjectsLoading } = greeneryRepository.useFetchVhrObjects();

  const { vhrCogUrl, isLoading } = vhrDataService.useFetchVhrCogUrl();

  const layersVisibility = useLayerManagerStore.use.layersVisibility();
  const updateLayersVisibility = useLayerManagerStore.use.updateLayersVisibility();

  useEffect(() => {
    updateLayersVisibility(
      Object.fromEntries(
        vhrObjectsData.map(({ name }) => [name, VHR_INITIALY_AVAILABLE_LAYERS.includes(name) ? true : false]),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vhrObjectsData.length]);

  if (isLoading && isVhrObjectsLoading) {
    return <Loading />;
  }

  if (vhrCogUrl) {
    return <VhrMapDataLayer url={vhrCogUrl} vhrObjects={vhrObjectsData} layersVisibility={layersVisibility} />;
  }

  return null;
};
