export const LOGOUT_DELAY = 500;
export const LOADING_DELAY = 1_500;
export const DEFAULT_BACKDROP_Z_INDEX = 1001;
export const VIEW_UPDATER_ANIMATION_DEFAULT_DURATION = 3;
export const VIEW_UPDATER_ANIMATION_DEFAULT_LINEARITY = 0.75;

export const MAXIMUM_RESULTS_DATE_SEARCHING_PERIOD = 3_600_000 * 24 * 31;

export const TOOLTIP_DISPLAY_DELAY = 250;

export const GEOJSON_OBJECT_DEFAULT_COLOR = '#8E1600';

export const GEOTIFF_DEFAULT_RESOLUTION = 512;
export const GEOTIFF_DEFAULT_OPACITY = 1;
export const GEOTIFF_SCALING_BOUNDARY = 10;
export const GEOTIFF_SCALING_FACTOR = 100;
export const GEOTIFF_SCALING_EXPONENT = 2;

export const GEOTIFF_SATELLITE_IMAGE_LIMITS = {
  min: 0,
  max: 10000,
};

export const MAP_DEFAULT_ZOOMING_TIME = 4;

export const MAP_DATA_LAYER = 'dataLayer';

export const DARKEST_SATELLITE_URL_MODIFIER = 'sigmoidal rgb 4 0.7 saturation 0.8';
export const DARKER_SATELLITE_URL_MODIFIER = 'sigmoidal rgb 1 1 saturation 1';
export const NORMAL_SATELLITE_URL_MODIFIER = 'sigmoidal rgb 2 0.2 saturation 1.1';
export const LIGHTER_SATELLITE_URL_MODIFIER = 'sigmoidal rgb 4 0.2 saturation 1.2';

export const DARK_DARKEST_GRAYSCALE_SATELLITE_URL_MODIFIER = '{"0": "#000", "255": "#555"}';
export const DARK_DARKER_GRAYSCALE_SATELLITE_URL_MODIFIER = '{"0": "#111", "255": "#777"}';
export const DARK_NORMAL_GRAYSCALE_SATELLITE_URL_MODIFIER = '{"0": "#222", "255": "#999"}';
export const DARK_LIGHTER_GRAYSCALE_SATELLITE_URL_MODIFIER = '{"0": "#333", "255": "#BBB"}';

export const LIGHT_GRAYSCALE_SATELLITE_URL_MODIFIER = '{"0": "#ADADAD", "255": "#FFF"}';

export const BACKGROUND_LAYER_NAME = 'background';

export const SCALE_DISTANCES = [
  200000, 100000, 50000, 30000, 20000, 10000, 5000, 3000, 2000, 1000, 500, 300, 200, 100, 50, 30, 20, 10, 5, 3, 2, 1,
];
export const APP_SCALE_MAX_HEIGHT = 100;
export const EXTERNAL_SCALE_MAX_HEIGHT = 200;

export const AVATAR_NAME_FONT_SIZE_FACTOR = 1.7;
export const AVATAR_NAME_FONT_SIZE_FALLBACK = 24;
export const AVATAR_INITIALS_FONT_SIZE_FACTOR = 1.3;
export const AVATAR_INITIALS_FONT_SIZE_FALLBACK = 72;

export const SIDEBAR_MIN_WIDTH = 50;
export const SIDEBAR_MAX_WIDTH = 300;
export const SIDEBAR_TOGGLE_DELAY = 500;
export const SIDEBAR_IMAGE_RATIO = 300 / 220;

export const SIDEBAR_PANEL_MAX_WIDTH = 350;
export const SIDEBAR_PANEL_CLOSE_TIMEOUT = 300;

export const AI_ASSISTANT_MESSAGES_SESSION_STORAGE_KEY = 'AI_assistant_messages';

export const EARTH_RADIUS_KM = 6_371;

export const CREATOR_HEIGHT = 405;

export const MAX_PIN_NOTE_LETTERS = 500;

export const COORDINATES_PRECISION = 6;
export const DEFAULT_PIXEL_VALUE_PRECISION = 2;
export const SURFACE_PRECISION_HA = 1;
export const PERCENTAGES_PRECISION = 2;
export const DEFAULT_MAIN_GRAPH_PRECISION = 2;

export const NANOMETER_TO_METER_CONVERSION_FACTOR = 0.000000001;
