import { mapIndicatorsToOptions } from 'common/utils';
import { areExtendedIndicatorsValid } from 'common/typeguards';
import { withIndicatorConfig, withIndicatorLabels } from 'common/decorators';
import { useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { WATER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { WATERBODY_INDICATORS_CONFIGURATION } from 'domain/water/configs';

export const useWaterIndicators = () => {
  const { areaName } = useGetAoiName();
  const { waterIndicators, areWaterIndicatorsLoading } = waterRepository.useFetchWaterQualityIndicators();

  const allowedIndicators = ['Absorbance 420 nm', 'Solid content', 'Colour', 'TURBIDITY', 'NDCI', 'NDTI'];

  // TODO: move indicators filtering based on AOI to backend
  const filteredWaterIndicators = waterIndicators.filter(({ indicator_name }) =>
    areaName === 'viiankiaapa' ? allowedIndicators.includes(indicator_name) : true,
  );

  const indicatorsWithConfig = withIndicatorConfig(filteredWaterIndicators, WATERBODY_INDICATORS_CONFIGURATION);
  const indicatorsWithLabels: unknown = withIndicatorLabels(indicatorsWithConfig);
  const extendedIndicators = areExtendedIndicatorsValid(indicatorsWithLabels) ? indicatorsWithLabels : [];

  const selectedIndicatorId = useQueryParameter(WATER_INDICATOR_QUERY_PARAMETER);
  const selectedIndicator = extendedIndicators.find(({ id }) => selectedIndicatorId === String(id));

  const indicatorOptions = mapIndicatorsToOptions(extendedIndicators);

  const replaceQueryParameter = useReplaceQueryParameters();

  const changeWaterIndicator = (value: string | number): void => {
    if (value) {
      replaceQueryParameter([
        {
          key: WATER_INDICATOR_QUERY_PARAMETER,
          value: String(value),
        },
      ]);
    }
  };

  return {
    indicators: extendedIndicators,
    selectedIndicator,
    indicatorOptions,
    changeWaterIndicator,
    areIndicatorsLoading: areWaterIndicatorsLoading,
  };
};
