import { type ChangeEvent, useState, useEffect, type FormEvent } from 'react';
import { AxiosError } from 'axios';

import { type SessionHandlingData } from 'ui/organisms/loginSidebar/types';
import { type ForgotPasswordServiceData } from 'ui/organisms/forgotPassword/types';
import { validateEmail } from 'domain/user/helpers';
import { InputElementError } from 'domain/user/enums';

export const useLoginFormService = (
  sessionHandlingData: SessionHandlingData,
  forgotPasswordServiceData: ForgotPasswordServiceData,
) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [credentialsError, setCredentialsError] = useState<string>();
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);

  const errorMessage = usernameError || passwordError || credentialsError;

  const { loginUser, error, isLoading, setResetPasswordRequestStatus } = sessionHandlingData;

  const closeForgotPasswordPopup = () => {
    setResetPasswordRequestStatus('IDLE');
    setIsForgotPasswordOpen(false);
  };

  useEffect(() => {
    if (error instanceof AxiosError && error?.response?.data?.non_field_errors) {
      setCredentialsError(error?.response?.data?.non_field_errors.toString());
    }
  }, [error]);

  const handleFormSubmitUseCase = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const usernameExist = !!username.length;
    const usernameValid = validateEmail(username);
    const passwordExist = !!password.length;

    if (usernameValid && passwordExist) {
      loginUser({ username, password });
      return;
    }

    if (!passwordExist) {
      setPasswordError(InputElementError.NO_PASSWORD);
    }

    if (!usernameExist) {
      setUsernameError(InputElementError.NO_EMAIL);
      return;
    }

    if (!usernameValid) {
      setUsernameError(InputElementError.INVALID_EMAIL);
    }
  };

  const usernameInputElementProps = {
    value: username,
    name: 'username',
    label: username ? '' : 'Username',
    error: errorMessage,
    setValue: (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value),
  };

  const passwordInputElementProps = {
    value: password,
    name: 'password',
    type: 'password',
    label: password ? '' : 'Password',
    error: errorMessage,
    setValue: (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
  };

  const forgotPasswordPopup = {
    isOpen: isForgotPasswordOpen,
    handleOpen: () => setIsForgotPasswordOpen(true),
    handleClose: closeForgotPasswordPopup,
  };

  const useGetPrivacyPolicyCheckbox = () => {
    const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);

    return {
      isChecked: isPrivacyPolicyChecked,
      handleCheck: () => setIsPrivacyPolicyChecked((prev) => !prev),
    };
  };

  useEffect(() => {
    setUsernameError(undefined);
    setCredentialsError(undefined);
  }, [username]);

  useEffect(() => {
    setPasswordError(undefined);
    setCredentialsError(undefined);
  }, [password]);

  return {
    username,
    handleFormSubmit: handleFormSubmitUseCase,
    isLoggingIn: isLoading,
    usernameInputElementProps,
    passwordInputElementProps,
    forgotPasswordPopup,
    forgotPasswordServiceData,
    privacyPolicyCheckbox: useGetPrivacyPolicyCheckbox(),
    errorText: errorMessage,
  };
};
