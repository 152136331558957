import { type AxiosResponse } from 'axios';

import { restAPI } from 'services/rest/restApiService';
import { type DisplacementsApiResponse, type SignedKmzUrlsApiResponse } from 'domain/displacements/types';
import { DISPLACEMENTS_API_URLS } from './displacementsApiUrls';

export const displacementsAPI = {
  getResults: (areaId: number): Promise<AxiosResponse<DisplacementsApiResponse>> =>
    restAPI.private.get(DISPLACEMENTS_API_URLS.GETResults, {
      params: { aoi_id: areaId },
    }),

  getSignedKmzUrl: (urlId: number): Promise<AxiosResponse<SignedKmzUrlsApiResponse>> =>
    restAPI.private.get(DISPLACEMENTS_API_URLS.GETSignedKmzUrl.replace(':id', String(urlId))),
};
