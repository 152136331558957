import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type Datetime } from 'common/types/time';
import { type WaterBodyDefaultView } from 'domain/water/types';

interface WaterSliceState {
  waterBodyDefaultView: WaterBodyDefaultView | undefined;
  setWaterBodyDefaultView: (waterBodyDefaultView: WaterBodyDefaultView) => void;
  clearWaterBodyDefaultView: () => void;
  previousTimestamp: Datetime | undefined;
  setPreviousTimestamp: (timestamp: Datetime) => void;
  clearWaterData: () => void;
}

const initialState = {
  waterBodyDefaultView: undefined,
  previousTimestamp: undefined,
};

const useWaterStoreBase = create<WaterSliceState>()(
  devtools(
    (set) => ({
      waterBodyDefaultView: initialState.waterBodyDefaultView,
      setWaterBodyDefaultView: (waterBodyDefaultView) =>
        set({ waterBodyDefaultView }, false, 'water/setWaterBodyDefaultView'),
      clearWaterBodyDefaultView: () =>
        set({ waterBodyDefaultView: initialState.waterBodyDefaultView }, false, 'water/clearWaterBodyDefaultView'),
      previousTimestamp: initialState.previousTimestamp,
      setPreviousTimestamp: (previousTimestamp) => set({ previousTimestamp }, false, 'water/setPreviousTimestamp'),
      clearWaterData: () => set(initialState, false, 'water/clearWaterData'),
    }),
    { name: 'waterStore' },
  ),
);

export const useWaterStore = createSelectors(useWaterStoreBase);
