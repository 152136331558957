interface FoldableSectionStatusProps {
  areResultsAvailable: boolean;
  noDataMessage: string;
  defaultDescription: string;
}

export const useFoldableSectionStatus = ({
  areResultsAvailable,
  noDataMessage,
  defaultDescription,
}: FoldableSectionStatusProps) => {
  return {
    description: areResultsAvailable ? defaultDescription : noDataMessage,
    areResultsAvailable,
  };
};
