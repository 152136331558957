import { useState } from 'react';
import styled from 'styled-components';

import { Button } from 'ui';
import { type DoubleDateSelectVariant } from 'ui/types';
import { useTimelineService } from '../timelineService';
import { DoubleDateSelect } from '../../../ui/molecules/doubleDateSelect';

interface ComparatorProps {
  variant?: DoubleDateSelectVariant;
  timestamps: string[];
  areTimestampsLoading?: boolean;
  isCompareDisabled: boolean;
  noButton?: boolean;
  handleCompare?: () => void;
}

export const Comparator = ({
  timestamps,
  areTimestampsLoading,
  variant,
  isCompareDisabled,
  noButton = false,
  handleCompare,
}: ComparatorProps) => {
  const [areBothDatesSelected, setAreBothDatesSelected] = useState(false);

  const { timestamp, secondTimestamp, changeTimestamp, changeSecondTimestamp } = useTimelineService();

  return (
    <ComparatorWrapper>
      <DoubleDateSelect
        {...{
          variant,
          timestamps,
          timestamp,
          secondTimestamp,
          changeTimestamp,
          changeSecondTimestamp,
          areTimestampsLoading,
        }}
        handleAreBothDatesSelected={(areSelected: boolean) => setAreBothDatesSelected(areSelected)}
      />
      {!noButton && (
        <Button variant="LIGHT" onClick={handleCompare} disabled={isCompareDisabled || !areBothDatesSelected}>
          Generate
        </Button>
      )}
    </ComparatorWrapper>
  );
};

const ComparatorWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 54px;
  margin-right: auto;
  margin-left: 74px;
  animation: fadeIn 0.5s ease-out;
  transition: margin-top ${({ theme }) => theme.transitionFunction.main};
  z-index: 1001;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
