import { areasRepository } from 'infrastructure/areas/areasRepository';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayerManager/draggableLayersKeys';
import { useInfrastructureLayersVisibility } from 'components/layerManager/commonContent/infrastructure/useInfrastructureLayersVisibility';
import { Shape } from './Shape';
import { useGetInfrastructureLayerColor } from './useGetInfrastructureLayerColor';

interface ShapesCollectionProps {
  areaName: string;
}

export const ShapesCollection = ({ areaName }: ShapesCollectionProps) => {
  const { classesVisibility: geoJSONsVisibility } = useInfrastructureLayersVisibility();

  const { shapes } = areasRepository.useFetchInfrastructureShapes(areaName);

  const getInfrastructureLayerColor = useGetInfrastructureLayerColor();

  return (
    <>
      {shapes.map(({ id, name, shape_url, colors }) => (
        <WithLayerIndex
          key={id}
          Component={Shape}
          props={{
            id,
            url: shape_url,
            name,
            color: getInfrastructureLayerColor(colors),
            isVisible: !!geoJSONsVisibility[name],
          }}
          sectionId={LAYERS_KEYS.INFRASTRUCTURE}
        />
      ))}
    </>
  );
};
