import { useEffect } from 'react';

import { type ValueAndLabel } from 'common/types';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { useClustersLayerManagerStore } from './clustersLayerManagerStore';

export const useClusters = () => {
  const { areaName } = useGetAoiName();

  const clustersSupplierId = useClustersLayerManagerStore.use.clustersSupplierId();
  const setClustersSupplierId = useClustersLayerManagerStore.use.setClustersSupplierId();
  const clustersAmount = useClustersLayerManagerStore.use.clustersAmount();
  const setClustersAmount = useClustersLayerManagerStore.use.setClustersAmount();

  const { clustersResults, areClustersResultsLoading } = explorationRepository.useFetchClustersResults(areaName);
  const { clustersSuppliers, areClustersSuppliersLoading } = explorationRepository.useFetchClustersDataSuppliers();

  const suppliersWithResults = clustersSuppliers.filter((supplier) =>
    clustersResults.some(({ data_supplier_id }) => data_supplier_id === supplier.id),
  );
  const selectedClustersSupplier = suppliersWithResults.find(({ id }) => id === clustersSupplierId);

  const supplierOptions: ValueAndLabel[] = suppliersWithResults.map((item) => ({
    value: item.id,
    label: item.label,
  }));
  const selectedSupplierOption = supplierOptions.find(({ value }) => value === clustersSupplierId);

  const clustersAmountArray = clustersResults
    .filter(({ data_supplier_id }) => data_supplier_id === selectedClustersSupplier?.id)
    .map(({ amount }) => amount)
    .sort((a, b) => a - b);
  const validClustersAmount = clustersAmountArray.find((amount) => amount === clustersAmount);

  const currentClustersResult = clustersResults.find(
    ({ data_supplier_id, amount }) =>
      data_supplier_id === selectedClustersSupplier?.id && amount === validClustersAmount,
  );

  const handleClustersSupplierChange = (option: ValueAndLabel) => setClustersSupplierId(Number(option.value));

  useEffect(() => {
    if (!selectedSupplierOption && supplierOptions.length) {
      handleClustersSupplierChange(supplierOptions[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplierOption, supplierOptions]);

  useEffect(() => {
    if (!validClustersAmount && clustersAmountArray.length) {
      setClustersAmount(clustersAmountArray[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validClustersAmount, clustersAmountArray]);

  return {
    selectedClustersSupplier,
    clustersSuppliersSelectData: {
      options: supplierOptions,
      selectedOption: selectedSupplierOption,
      initialStateLabel: 'Select Provider',
      noOptionsLabel: 'No Providers',
      name: 'clusters data provider selection',
      title: '',
      isFetching: areClustersSuppliersLoading || areClustersResultsLoading,
    },
    handleClustersSupplierChange,
    clustersResults,
    clustersAmountArray,
    currentClustersResult,
  };
};
