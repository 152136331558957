import styled from 'styled-components';

import { formatDate } from 'common/utils/datetime';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { extractionRepository } from 'infrastructure/extraction/extractionRepository';
import { MINE_ACTIVITY_INDEX_INFO_AREA_DESCRIPTION } from 'domain/extraction/constants';

interface ExtractionInfoAreaProps {
  timestamp: string;
}

export const ExtractionInfoArea = ({ timestamp }: ExtractionInfoAreaProps) => {
  const { areaName } = useGetAoiName();
  const date = formatDate(timestamp);

  const { result } = extractionRepository.useFetchMineActivityIndexResults(areaName, date);

  return result ? (
    <StyledArea>
      <div>{MINE_ACTIVITY_INDEX_INFO_AREA_DESCRIPTION}</div>
      <div>{result.description}</div>
    </StyledArea>
  ) : null;
};

const StyledArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
`;
