import { useQueryParameter } from 'common/navigation/hooks';
import { LINEAMENT_THRESHOLD_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';

export const useLineamentDensity = (areaName: string) => {
  const lengthRange = useQueryParameter(LINEAMENT_THRESHOLD_QUERY_PARAMETER);
  const { densityResults } = explorationRepository.useFetchLineamentDensityResults(areaName, lengthRange);

  const densityResult = densityResults[0] ? densityResults[0] : undefined;

  return { densityResult };
};
