import { type ValueAndLabel } from 'common/types';
import { useQueryParameter, useReplaceQueryParameters, useDeleteQueryParameters } from 'common/navigation/hooks';
import { SECOND_DATE_QUERY_PARAMETER, VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const useViewOptions = (onChangeCallback?: () => void) => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const mapViewOption = viewQuery ? { value: viewQuery, label: viewQuery } : undefined;

  const replaceQueryParameter = useReplaceQueryParameters();

  const changeViewOption = (option: ValueAndLabel): void => {
    const chosenOption = option.value;

    chosenOption &&
      replaceQueryParameter([
        {
          key: VIEW_MODE_QUERY_PARAMETER,
          value: String(chosenOption).replaceAll(' ', '-'),
        },
      ]);

    onChangeCallback && onChangeCallback();
  };

  const deleteQueryParameters = useDeleteQueryParameters();

  const resetViewOption = (): void => {
    deleteQueryParameters([VIEW_MODE_QUERY_PARAMETER, SECOND_DATE_QUERY_PARAMETER]);

    onChangeCallback && onChangeCallback();
  };

  return { mapViewOption, changeViewOption, resetViewOption };
};
