import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { type BoundsArray, type DefaultView } from 'common/types/mapData';
import { VIEW_UPDATER_ANIMATION_DEFAULT_DURATION, VIEW_UPDATER_ANIMATION_DEFAULT_LINEARITY } from 'common/constants';
import { isBoundsArray, isDefaultPointView } from 'common/typeguards';

interface ViewUpdaterProps {
  defaultView?: DefaultView | BoundsArray;
  animate?: boolean;
  cleanupAction?: () => void;
}

export const ViewUpdater = ({ defaultView, animate = false, cleanupAction }: ViewUpdaterProps) => {
  const duration = VIEW_UPDATER_ANIMATION_DEFAULT_DURATION;
  const linearity = VIEW_UPDATER_ANIMATION_DEFAULT_LINEARITY;
  const map = useMap();

  useEffect(() => {
    if (isDefaultPointView(defaultView)) {
      const center = defaultView && [defaultView.lat, defaultView.lon];
      const zoom = defaultView.zoom;

      if (center && zoom) {
        map.flyTo(center, zoom, {
          animate: animate,
          duration: duration,
          easeLinearity: linearity,
        });
      }
    } else {
      !!defaultView &&
        isBoundsArray(defaultView) &&
        map.flyToBounds(defaultView, {
          animate: animate,
          duration: duration,
          easeLinearity: linearity,
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultView]);

  useEffect(() => {
    map.on('zoomend', () => cleanupAction && cleanupAction());
  }, [cleanupAction, map]);

  return null;
};
