import { useEffect } from 'react';

import { Select } from 'ui';
import { type ValueAndLabel } from 'common/types';
import { useDataProviders } from './useDataProviders';

interface DataProvidersSelectProps<T> {
  results: T extends { provider: string } ? T[] : never;
  areResultsLoading: boolean;
}

export const DataProvidersSelect = <T,>({ results, areResultsLoading }: DataProvidersSelectProps<T>) => {
  const { providers, selectedProvider, areProvidersLoading, changeWaterProvider } = useDataProviders();

  const selectedProviderOption: ValueAndLabel | undefined = selectedProvider
    ? { value: selectedProvider.id, label: selectedProvider.label }
    : undefined;

  const onWaterProviderChange = (option: ValueAndLabel): void => {
    changeWaterProvider(option.value);
  };

  const filteredWaterDataProviders = providers.filter(({ name }) => {
    return results.some(({ provider }) => provider === name);
  });

  const providersOptionsList: ValueAndLabel[] = filteredWaterDataProviders.map(({ id, label }) => ({
    value: id,
    label,
  }));

  useEffect(() => {
    if (!results.length && !areResultsLoading) {
      setTimeout(() => {
        changeWaterProvider(null);
      }, 500);
      return;
    }

    if (
      filteredWaterDataProviders.length &&
      providers.length &&
      (!selectedProvider || filteredWaterDataProviders.every(({ id }) => id !== selectedProvider.id))
    ) {
      setTimeout(() => {
        changeWaterProvider(filteredWaterDataProviders[0].id);
      }, 500);
    }
  }, [!!selectedProvider, JSON.stringify(filteredWaterDataProviders), results.length, areResultsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      {...{
        name: 'provider',
        options: providersOptionsList,
        selectedOption: selectedProviderOption,
        isFetching: areProvidersLoading || areResultsLoading,
        initialStateLabel: 'Select provider',
      }}
      onChange={onWaterProviderChange}
    />
  );
};
