import { type ReactNode } from 'react';

import { OptionButton } from 'ui/creator/optionButton';
import { ContentFoldable } from 'ui/creator/optionButton/OptionButtonContent';
import { type CreatorOptionsColumnOption } from 'ui/creator/types';

interface ReportsCreatorOptionsColumnContentProps {
  children?: ReactNode;
  options: CreatorOptionsColumnOption[];
  small?: boolean;
}

export const ReportsCreatorOptionsColumnContent = ({
  children,
  options,
  small,
}: ReportsCreatorOptionsColumnContentProps) => (
  <>
    {options.map(({ id, label, selected, onClick }) => (
      <OptionButton key={id} {...{ label, selected, onClick }} small={small}>
        <ContentFoldable label={label} small={small} selected={selected}>
          {children}
        </ContentFoldable>
      </OptionButton>
    ))}
  </>
);
