import { useEffect, useState, useRef, type Dispatch, type SetStateAction } from 'react';

import { divIcon, Marker } from 'leaflet';
import { type GeoJSONFeature, type LeafletLatLng, type LeafletLayer } from 'common/types/mapData';
import { type GeoJSONCollection, type InitGeoData, type OpenedMarkerData } from 'domain/displacements/types';
import { getGeoDataBasedOnZoom } from 'domain/displacements/helpers/getGeoDataBasedOnZoom';
import { unhoverMarkerStyle } from 'domain/displacements/constants';
import { useDisplacementsMarkersService } from './useDisplacementsMarkersService';

export const useGeoJson = (
  geoData: GeoJSONCollection,
  levelsOfDetail: number[],
  selectedGeoJSONData: InitGeoData,
  setSelectedGeoJSONData: Dispatch<SetStateAction<InitGeoData>>,
  openedMarkerData: string | undefined,
  pane: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const geoJsonLayer = useRef<any>(null);
  const [currentLevelOfDetail, setCurrentLevelOfDetail] = useState<number>(1500);
  const { onMarkerClick, onMarkerMouseIn, onMarkerMouseOut, createCircleMarker, getMarkerColor, detailedTooltip } =
    useDisplacementsMarkersService(openedMarkerData);

  useEffect(() => {
    const newData = getGeoDataBasedOnZoom(geoData, currentLevelOfDetail, levelsOfDetail);

    if (newData && geoJsonLayer.current) {
      geoJsonLayer.current.clearLayers().addData(newData);

      if (!selectedGeoJSONData) {
        setSelectedGeoJSONData(newData);
      }
    }
  }, [geoData, currentLevelOfDetail, levelsOfDetail, selectedGeoJSONData, setSelectedGeoJSONData]);

  const pointToLayerHandler = (feature: OpenedMarkerData, latlng: LeafletLatLng) => {
    let markerColor = feature.properties['icon-color'];
    const isReferencePoint = feature.properties['icon'] === 'star.png';

    if (isReferencePoint) {
      const starIcon = divIcon({
        html: `
      <i class="fa fa-star" style="font-size:30px;color:${markerColor};text-shadow:2px 2px 4px #000000;"></i>`,
        className: '',
        iconSize: [30, 30],
        iconAnchor: [15, 15],
      });

      return new Marker(latlng, { icon: starIcon, pane });
    } else {
      markerColor = getMarkerColor(feature) || markerColor;
    }

    return createCircleMarker(latlng, { ...unhoverMarkerStyle, fillColor: markerColor, pane });
  };

  const onEachFeatureHandler = (feature: GeoJSONFeature, layer: LeafletLayer) => {
    if (feature.geometry.type !== 'Point') return;

    layer.on('click', (event) => onMarkerClick(event, feature, layer));
    layer.on('mouseover', (event) => onMarkerMouseIn(event, feature, layer));
    layer.on('mouseout', (event) => onMarkerMouseOut(event, layer));
  };

  return { geoJsonLayer, detailedTooltip, pointToLayerHandler, onEachFeatureHandler, setCurrentLevelOfDetail };
};
