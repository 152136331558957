import { Outlet } from 'react-router-dom';

import { useTreeShakeQueryParams } from 'common/navigation/hooks';
import { EXPLORATION_VIEW_CONFIG } from 'domain/exploration/configs';

export const Exploration = () => {
  useTreeShakeQueryParams(EXPLORATION_VIEW_CONFIG);

  return <Outlet />;
};
