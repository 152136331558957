import { type Dispatch, type SetStateAction } from 'react';
import { GeoJSON } from 'react-leaflet';
import { type GeoJsonObject } from 'geojson';
import { MapTooltip } from 'ui/map/tooltip';
import { useMapStore } from 'components/map/mapStore';
import { type GeoJSONCollection, type InitGeoData } from 'domain/displacements/types';
import { buildDisplacementsTooltipStringHelper } from 'domain/displacements/helpers/buildDisplacementsTooltipHelper';
import { ZoomTracker } from './ZoomTracker';
import { MapCenterer } from './MapCenterer';
import { useGeoJson } from './useGeoJson';

interface GeoKmzLayerProps {
  geoData: GeoJSONCollection;
  isGeoDataAvailable: boolean;
  levelsOfDetail: number[];
  selectedGeoJSONData: InitGeoData;
  setSelectedGeoJSONData: Dispatch<SetStateAction<InitGeoData>>;
  pane: string;
  openedMarkerData: string | undefined;
}

export const GeoKmzLayer = ({
  geoData,
  isGeoDataAvailable,
  levelsOfDetail,
  selectedGeoJSONData,
  setSelectedGeoJSONData,
  pane,
  openedMarkerData,
}: GeoKmzLayerProps) => {
  const { geoJsonLayer, detailedTooltip, pointToLayerHandler, onEachFeatureHandler, setCurrentLevelOfDetail } =
    useGeoJson(geoData, levelsOfDetail, selectedGeoJSONData, setSelectedGeoJSONData, openedMarkerData, pane);

  const mapReady = useMapStore.use.mapReady();

  return (
    <>
      <ZoomTracker setCurrentLevelOfDetail={setCurrentLevelOfDetail} />
      <MapCenterer isGeoDataAvailable={isGeoDataAvailable} selectedGeoJSONData={selectedGeoJSONData} />
      {isGeoDataAvailable && mapReady && (
        <>
          <GeoJSON
            ref={geoJsonLayer}
            data={undefined as unknown as GeoJsonObject}
            pointToLayer={pointToLayerHandler}
            onEachFeature={onEachFeatureHandler}
            pane={pane}
          />
          {detailedTooltip && (
            <MapTooltip
              tooltipData={detailedTooltip}
              content={buildDisplacementsTooltipStringHelper(detailedTooltip)}
            />
          )}
        </>
      )}
    </>
  );
};
