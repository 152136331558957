import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type Polygon, type Feature } from 'geojson';
import { type BoundsArray, type Position } from 'common/types/mapData';
import { type Datetime } from 'common/types/time';
import { type AoiData, type AreaRequestStatus, type AreaSelectionStatus } from 'domain/areas/types';
import { type SubscriptionProductDTO } from 'domain/subscription/types';

interface AreaRequestState {
  features: Feature<Polygon>[];
  setFeatures: (features: Feature<Polygon>[]) => void;
  resetFeatures: () => void;
  searchResult: BoundsArray | Position | undefined;
  setSearchResult: (searchBounds: BoundsArray | Position) => void;
  resetSearchResult: () => void;
  preview: AoiData | undefined;
  setPreview: (preview: AoiData) => void;
  drawMode: 'rectangle' | 'polygon' | null;
  setDrawMode: (mode: 'rectangle' | 'polygon') => void;
  toggleDrawMode: (mode: 'rectangle' | 'polygon') => void;
  disableDrawMode: () => void;
  polygonPoints: number[][];
  setPolygonPoints: (points: number[][]) => void;
  // editMode: boolean;
  // setEditMode: (editMode: boolean) => void;
  deleteMode: boolean;
  toggleDeleteMode: () => void;
  disableDeleteMode: () => void;
  areaName: string;
  setAreaName: (areaName: string) => void;
  currentDeposit: string | undefined;
  setCurrentDeposit: (deposit: string) => void;
  suppliersSelection: { [name: string]: boolean };
  setSuppliersSelection: (name: string, mode?: boolean) => void;
  requestedQuarterEnd: Datetime | undefined;
  setRequestedQuarterEnd: (date: Datetime) => void;
  area: number | undefined;
  setArea: (area: number) => void;
  resetArea: () => void;
  selectionStatus: AreaSelectionStatus;
  setSelectionStatus: (status: AreaSelectionStatus) => void;
  requestStatus: AreaRequestStatus;
  setRequestStatus: (status: AreaRequestStatus) => void;
  isPropertiesColumnValid: boolean;
  setPropertiesColumnValidity: (valid: boolean) => void;
  isDepositsColumnValid: boolean;
  setDepositsColumnValidity: (valid: boolean) => void;
  isSuppliersColumnValid: boolean;
  setSuppliersColumnValidity: (valid: boolean) => void;
  selectedSubscription: SubscriptionProductDTO | null;
  setSelectedSubscription: (subscription: SubscriptionProductDTO | null) => void;
  error: string | undefined;
  setError: (error: string) => void;
  clearError: () => void;
  resetStatus: () => void;
  clearCreatorData: () => void;
  clearAreaRequestData: () => void;
}

const initialState = {
  features: [],
  searchResult: undefined,
  preview: undefined,
  drawMode: null,
  drawRectangle: false,
  drawPolygon: false,
  polygonPoints: [],
  // editMode: false,
  deleteMode: false,
  areaName: '',
  currentDeposit: undefined,
  suppliersSelection: {
    ['sentinel-2']: true,
  },
  requestedQuarterEnd: undefined,
  selectionStatus: { mode: 'IDLE' } as AreaSelectionStatus,
  requestStatus: { mode: 'DISABLED', message: 'Provide a name for your area.' } as AreaRequestStatus,
  isPropertiesColumnValid: false,
  isDepositsColumnValid: false,
  isSuppliersColumnValid: false,
  selectedSubscription: null,
  error: undefined,
  area: undefined,
};

const useAreaRequestStoreBase = create<AreaRequestState>()(
  devtools(
    (set) => ({
      ...initialState,
      setFeatures: (features) => set({ features }, false, 'areaRequest/setFeatures'),
      resetFeatures: () => set({ features: initialState.features }, false, 'areaRequest/resetFeatures'),
      setSearchResult: (searchBounds) => set({ searchResult: searchBounds }, false, 'areaRequest/setSearchBounds'),
      resetSearchResult: () => set({ searchResult: undefined }, false, 'areaRequest/resetSearchBounds'),
      setPreview: (preview) => set({ preview }, false, 'areaRequest/setPreview'),
      setDrawMode: (drawMode) => set({ drawMode }, false, 'areaRequest/setDrawMode'),
      toggleDrawMode: (drawMode) =>
        set(
          (state) => {
            const newDrawMode = state.drawMode === drawMode ? null : drawMode;
            return { drawMode: newDrawMode };
          },
          false,
          'areaRequest/toggleDrawMode',
        ),
      disableDrawMode: () => set({ drawMode: null }, false, 'areaRequest/disableDrawMode'),
      setPolygonPoints: (points) => set({ polygonPoints: points }, false, 'areaRequest/polygonPoints'),
      // setEditMode: (editMode) => set({ editMode }, false, 'areaRequest/setEditMode'),
      toggleDeleteMode: () =>
        set((state) => ({ deleteMode: !state.deleteMode }), false, 'areaRequest/toggleDeleteMode'),
      disableDeleteMode: () => set({ deleteMode: false }, false, 'areaRequest/disableDeleteMode'),
      setAreaName: (areaName) => set({ areaName }, false, 'areaRequest/setAreaName'),
      setCurrentDeposit: (deposit) => set({ currentDeposit: deposit }, false, 'areaRequest/setCurrentDeposit'),
      setSuppliersSelection: (name, mode) =>
        set(
          (state) => ({
            suppliersSelection: {
              ...state.suppliersSelection,
              [name]: typeof mode === 'boolean' ? mode : !state.suppliersSelection[name],
            },
          }),
          false,
          'areaRequest/setSuppliersSelection',
        ),
      setRequestedQuarterEnd: (date) => set({ requestedQuarterEnd: date }, false, 'areaRequest/setRequestedDate'),
      setArea: (area) => set({ area }, false, 'areaRequest/setArea'),
      resetArea: () => set({ area: initialState.area }, false, 'areaRequest/resetArea'),
      setSelectionStatus: (status) => set({ selectionStatus: status }, false, 'areaRequest/setSelectionStatus'),
      setRequestStatus: (status) => set({ requestStatus: status }, false, 'areaRequest/setRequestStatus'),
      setPropertiesColumnValidity: (valid) =>
        set({ isPropertiesColumnValid: valid }, false, 'areaRequest/setPropertiesColumnValidity'),
      setDepositsColumnValidity: (valid) =>
        set({ isDepositsColumnValid: valid }, false, 'areaRequest/setDepositsColumnValidity'),
      setSuppliersColumnValidity: (valid) =>
        set({ isSuppliersColumnValid: valid }, false, 'areaRequest/setSuppliersColumnValidity'),
      setSelectedSubscription: (subscription) =>
        set({ selectedSubscription: subscription }, false, 'areaRequest/setSelectedSubscription'),
      setError: (error) => set({ error }, false, 'areaRequest/setError'),
      clearError: () => set({ error: initialState.error }, false, 'areaRequest/clearError'),
      resetStatus: () =>
        set(
          { selectionStatus: initialState.selectionStatus, requestStatus: initialState.requestStatus },
          false,
          'areaRequest/resetSelectionStatus',
        ),
      clearCreatorData: () =>
        set(
          {
            areaName: initialState.areaName,
            features: initialState.features,
            selectionStatus: initialState.selectionStatus,
            requestStatus: initialState.requestStatus,
            currentDeposit: initialState.currentDeposit,
            selectedSubscription: null,
          },
          false,
          'areaRequest/clearCreatorData',
        ),
      clearAreaRequestData: () => set(initialState, false, 'areaRequest/clearDisplacementsData'),
    }),
    { name: 'areaRequestStore' },
  ),
);

export const useAreaRequestStore = createSelectors(useAreaRequestStoreBase);
