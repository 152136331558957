import { ReactComponent as DownloadIcon } from 'assets/svg/down-download-data.svg';
import { MapControlButton } from 'ui/map/mapControlButton';
import { useShareModalStore } from './shareModalStore';

export const ShareModalControlButton = () => {
  const openShareModal = useShareModalStore.use.openShareModal();

  const handleClick = () => {
    openShareModal();
  };

  return <MapControlButton icon={<DownloadIcon />} iconWidth={16} active={false} onClick={handleClick} />;
};
