import styled, { css } from 'styled-components';

import { ReactComponent as MonitoringIcon } from 'assets/svg/monitoring-icon.svg';
import { ReactComponent as MineralIcon } from 'assets/svg/target.svg';
import { type AreaType } from 'common/navigation/types';

interface Props {
  type: AreaType;
  rounded?: boolean;
}

export const AreaTypeIcon = ({ type, rounded }: Props) => {
  return (
    <IconWrapper type={type} rounded={rounded} data-testid="area-type-icon">
      {type === 'M' ? (
        <MonitoringIcon role="img" aria-label="Environment monitoring area type icon" />
      ) : type === 'P' ? (
        <MineralIcon role="img" aria-label="Mineral prospecting area type icon" />
      ) : null}
    </IconWrapper>
  );
};

const IconWrapper = styled.div<Props>`
  height: 22px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: ${({ theme }) => theme.color.midGray};
  color: ${({ theme }) => theme.color.lightGray};
  padding: 5px;
  pointer-events: none;
  border-radius: 20px 0 0 20px;

  ${({ type }) =>
    type === 'M' &&
    css`
      padding-left: 8px;
    `}

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50%;
      padding: 5px;
    `}

  svg {
    width: 12px;
    height: 12px;
  }

  span {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-top: 2px;
  }
`;
