import styled from 'styled-components';

import { ROUTES } from 'common/navigation/routes';
import { useNavigateWithQuery } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { ReactComponent as TreeIcon } from 'assets/svg/tree-icon.svg';
import { ReactComponent as WaterdropIcon } from 'assets/svg/water-drop-icon.svg';
import { Container, Button } from 'ui';
import { useMonitoringInfoStore } from 'views/monitoring/store';

export const WaterbodiesReclamationsSection = () => {
  const navigateWithQuery = useNavigateWithQuery();
  const waterbodies = useMonitoringInfoStore.use.waterbodies();
  const reclamationAreas = useMonitoringInfoStore.use.reclamationAreas();

  return (
    <Wrapper>
      {waterbodies && (
        <div>
          <StyledDescription>MONITORED WATERBODIES</StyledDescription>
          <ButtonsWrapper>
            {waterbodies.map((item, index) => (
              <Button
                minWidth={110}
                key={item.properties.id}
                onClick={() =>
                  navigateWithQuery(ROUTES.waterQuality, [
                    { key: WATERBODY_QUERY_PARAMETER, value: String(item.properties.id) },
                  ])
                }
                variant="PRIMARY_SUPER_SMALL"
              >
                <Container gap={10}>
                  <StyledWaterIcon /> Waterbody {++index}
                </Container>
              </Button>
            ))}
          </ButtonsWrapper>
        </div>
      )}

      {reclamationAreas && (
        <div>
          <StyledDescription>RECLAMATION AREAS</StyledDescription>
          <ButtonsWrapper>
            {reclamationAreas.map((item) => (
              <Button
                minWidth={70}
                key={item.properties.id}
                onClick={() => navigateWithQuery(ROUTES.landCover, [])}
                variant="PRIMARY_SUPER_SMALL"
              >
                <Container>
                  <StyledTreeIcon /> {item.properties.name}
                </Container>
              </Button>
            ))}
          </ButtonsWrapper>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  max-width: 240px;
`;

const StyledDescription = styled.p`
  font-weight: 600;
  margin: 0;
  letter-spacing: 1px;
`;

const StyledWaterIcon = styled(WaterdropIcon)`
  width: 7px;
  height: 9px;
`;

const StyledTreeIcon = styled(TreeIcon)`
  width: 7px;
  height: 9px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  column-gap: 4px;
  flex-wrap: wrap;
  margin-top: 10px;
`;
