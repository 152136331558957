import { useMapStore } from 'components/map/mapStore';
import { CustomMapScale } from 'ui/map/customMapScale';
import { type CustomMapScaleVariants } from 'ui/map/customMapScale/CustomMapScale';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';

interface PluggedInCustomMapScaleProps {
  variantOverride?: CustomMapScaleVariants;
}

export const PluggedInCustomMapScale = ({ variantOverride }: PluggedInCustomMapScaleProps) => {
  const scaleLabel = useMapStore.use.scaleLabel();
  const scaleHeight = useMapStore.use.scaleHeight();

  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <CustomMapScale
      label={scaleLabel?.app}
      height={scaleHeight?.app}
      variant={variantOverride || (areReportsActive ? 'DARK' : 'LIGHT')}
    />
  );
};
