import { View } from '@react-pdf/renderer';

import { tableStyles } from 'views/reports/external/pdfGenerator/styles';

export const PdfTableBottomLine = ({ length }: { length: number }) => (
  <View
    style={{
      ...tableStyles.table__column,
      ...tableStyles.table__bottom_borders_wrapper,
    }}
  >
    {Array.from(Array(length)).map((_, index) => (
      <View key={index} style={{ ...tableStyles.table__bottom_border, ...tableStyles.table__value }} />
    ))}
  </View>
);
