/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  FalseColorObject,
  BandCombosData,
  DescriptionData,
  Provider,
  CompositesProvidersApiResponse,
  CompositesResultsApiResponse,
} from './types';

export const isProvider = (data: any): data is Provider => {
  return typeof data.id === 'number' && typeof data.name === 'string' && typeof data.title === 'string';
};

export const isCompositesProvidersApiResponseValid = (data: any): data is CompositesProvidersApiResponse => {
  return (
    typeof data?.data.count === 'number' &&
    (data?.data.next === null || typeof data?.data.next === 'string') &&
    (data?.data.previous === null || typeof data?.data.previous === 'string') &&
    Array.isArray(data?.data.results) &&
    data.data.results.every((provider: any) => isProvider(provider))
  );
};

export const isFalseColorObject = (data: any): data is FalseColorObject => {
  return (
    typeof data === 'object' &&
    data !== null &&
    Object.keys(data).length === 3 &&
    Object.values(data).every((value) => typeof value === 'string' || value === undefined)
  );
};

export const isBandCombosData = (data: any): data is BandCombosData => {
  return (
    typeof data.id === 'number' &&
    typeof data.aoi_name === 'string' &&
    typeof data.provider === 'string' &&
    typeof data.label === 'string' &&
    typeof data.url === 'string' &&
    Array.isArray(data?.limits) &&
    data.limits.every((factor: any) => Array.isArray(factor) && factor.every((value) => typeof value === 'number')) &&
    typeof data.config === 'object' &&
    Object.values(data.config).every((value) => typeof value === 'string' || value === undefined)
  );
};

export const isCompositesResultsApiResponseValid = (data: any): data is CompositesResultsApiResponse => {
  return (
    typeof data?.data.count === 'number' &&
    (data?.data.next === null || typeof data?.data.next === 'string') &&
    (data?.data.previous === null || typeof data?.data.previous === 'string') &&
    Array.isArray(data?.data.results) &&
    data.data.results.every((bandCombo: any) => isBandCombosData(bandCombo))
  );
};

export const isDescriptionData = (data: any): data is DescriptionData => {
  return (
    typeof data.title === 'string' &&
    Array.isArray(data.options) &&
    data.options.every((option: any) => isBandCombosData(option))
  );
};
