import { useMoistureIndicators } from './indicators/useMoistureIndicators';
import { WithMoistureMultiData } from './WithMoistureMultiData';
import { MoistureMapMultiDataLayers } from './MoistureMapMultiDataLayers';
import { WithMoistureData } from './WithMoistureData';
import { MoistureMapDataLayer } from './MoistureMapDataLayer';

export const MoistureMapContent = () => {
  const { selectedIndicator } = useMoistureIndicators();

  return (
    <>
      {selectedIndicator?.is_multi_layers ? (
        <WithMoistureMultiData Component={MoistureMapMultiDataLayers} />
      ) : (
        <WithMoistureData Component={MoistureMapDataLayer} enableLoading />
      )}
    </>
  );
};
