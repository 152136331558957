import { useLocation, useParams } from 'react-router-dom';

import { areasRepository } from 'infrastructure/areas/areasRepository';
import { DisplacementsActiveTab } from 'domain/displacements/enums';
import { MineralizationActiveTab } from 'domain/exploration/enums';
import { ExtractionActiveTab } from 'domain/extraction/enums';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { MONITORING_PATH_NAME } from 'domain/monitoring/constants';
import { PROSPECTING_PATH_NAME } from 'domain/prospecting/constants';
import { WATER_PATH_NAME } from 'domain/water/constants';
import { WaterActiveTab } from 'domain/water/enums';
import { getPagePromptText } from './helpers';
import { promptIndicatorService } from './promptIndicatorService';

export const useChatPromptService = () => {
  const { areaId } = useParams();
  const { pathname } = useLocation();

  if (!areaId) return null;

  const { areaOfInterestDetails, areaOfInterestDetailsLoading } = areasRepository.useFetchAreaOfInterestDetails(
    Number(areaId),
  );

  if (!areaOfInterestDetails || areaOfInterestDetailsLoading) return null;

  const { display_name: areaDisplayName } = areaOfInterestDetails.properties;

  let prompt = `The displayed area is ${areaDisplayName}`;

  switch (true) {
    case pathname.includes(MONITORING_PATH_NAME):
      prompt = `${getPagePromptText(MONITORING_PATH_NAME)} ${prompt}.`;
      break;

    case pathname.includes(PROSPECTING_PATH_NAME):
      prompt = `${getPagePromptText(PROSPECTING_PATH_NAME)} ${prompt}.`;
      break;

    case pathname.includes(ExtractionActiveTab.TERRAIN):
      prompt = `${getPagePromptText(ExtractionActiveTab.TERRAIN)} ${prompt}.`;
      break;

    case pathname.includes(WATER_PATH_NAME):
      {
        const { selectedWaterbodyIndicator } = promptIndicatorService.useWaterIndicator();

        const waterBodyPrompt = selectedWaterbodyIndicator
          ? ` and selected waterbody is ${selectedWaterbodyIndicator.label}`
          : '';

        if (pathname.includes(WaterActiveTab.QUALITY)) {
          prompt = `${getPagePromptText(`water ${WaterActiveTab.QUALITY}`)} ${prompt}${waterBodyPrompt}.`;
        }

        if (pathname.includes(WaterActiveTab.SURFACE)) {
          prompt = `${getPagePromptText(`water ${WaterActiveTab.SURFACE}`)} ${prompt}${waterBodyPrompt}.`;
        }
      }
      break;

    case pathname.includes(GreeneryActiveTab.SEGMENTATION):
      prompt = `${getPagePromptText(GreeneryActiveTab.SEGMENTATION)} ${prompt}.`;
      break;

    case pathname.includes(GreeneryActiveTab.VEGETATION): {
      const { selectedGreeneryIndicator } = promptIndicatorService.useGreeneryIndicator();

      prompt = `${getPagePromptText(GreeneryActiveTab.VEGETATION)} ${prompt} and selected indicator is ${
        selectedGreeneryIndicator?.label
      }.`;
      break;
    }

    case pathname.includes(GreeneryActiveTab.VCI):
      prompt = `${getPagePromptText(GreeneryActiveTab.VCI)} ${prompt}.`;
      break;

    case pathname.includes(GreeneryActiveTab.QUARTERLY):
      prompt = `${getPagePromptText(GreeneryActiveTab.QUARTERLY)} ${prompt}.`;
      break;

    case pathname.includes(DisplacementsActiveTab.SUBSIDENCE):
      prompt = `${getPagePromptText(DisplacementsActiveTab.SUBSIDENCE)} ${prompt}.`;
      break;

    case pathname.includes(MineralizationActiveTab.SURFACE):
      {
        const { selectedSpectralClassifierIndicator } = promptIndicatorService.useSpectralClassifierIndicator();

        prompt = selectedSpectralClassifierIndicator
          ? `${getPagePromptText(MineralizationActiveTab.SURFACE)} ${prompt} and selected indicator is ${
              selectedSpectralClassifierIndicator.label
            }.`
          : `${getPagePromptText(MineralizationActiveTab.SURFACE)} ${prompt}.`;
      }
      break;

    default:
      break;
  }

  return prompt;
};
