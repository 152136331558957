import { useEffect } from 'react';

import { LabeledCheckbox, Slider } from 'ui';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useGraphStore } from 'components/graph/graphStore';
import { AuxiliaryControlsWrapper, LabeledSliderWrapper, LegendItemWraper } from './styled';
import { useLayerManagerStore } from '../layerManagerStore';

interface LayerManagerCommonContentProps {
  areaName: string;
  disableInfrastructureLayer: boolean;
  mainLayerOpacityAdjustable?: boolean;
  mainLayerLabel?: string | JSX.Element;
}

export const LayerManagerCommonContent = ({
  areaName,
  mainLayerOpacityAdjustable,
  disableInfrastructureLayer,
  mainLayerLabel,
}: LayerManagerCommonContentProps) => {
  const isExtentVisible = useLayerManagerStore.use.isExtentVisible();
  const toggleExtentVisibility = useLayerManagerStore.use.toggleExtentVisibility();
  const geoJSONVisibility = useLayerManagerStore.use.geoJSONsVisibility();
  const updateGeoJSONsVisibility = useLayerManagerStore.use.updateGeoJSONsVisibility();
  const toggleGeoJSONVisibility = useLayerManagerStore.use.toggleGeoJSONVisibility();
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const toggleDataLayerVisibility = useLayerManagerStore.use.toggleDataLayerVisibility();
  const dataLayerOpacity = useLayerManagerStore.use.dataLayerOpacity();
  const setDataLayerOpacity = useLayerManagerStore.use.setDataLayerOpacity();
  const resetPointHistory = useGraphStore.use.resetGraphData();
  const { shapes } = areasRepository.useFetchInfrastructureShapes(areaName);

  const onDataLayerToggle = () => {
    toggleDataLayerVisibility();
    resetPointHistory();
  };

  const onOpacityChange = (value: number | number[]) => {
    setDataLayerOpacity(Array.isArray(value) ? value[0] : value);
  };

  useEffect(() => {
    if (!disableInfrastructureLayer) {
      updateGeoJSONsVisibility(shapes.reduce((acc, { name }) => ({ ...acc, [name]: true }), {}));
    }
  }, [shapes?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LegendItemWraper>
        <LabeledCheckbox
          children="Extent"
          variant="WIDE"
          onChange={toggleExtentVisibility}
          checked={isExtentVisible || false}
        />
      </LegendItemWraper>
      {disableInfrastructureLayer
        ? null
        : shapes.map(({ id, name, label_name }) => (
            <LegendItemWraper key={id}>
              <LabeledCheckbox
                children={label_name}
                variant="WIDE"
                onChange={() => toggleGeoJSONVisibility(name)}
                checked={geoJSONVisibility[name] || false}
              />
            </LegendItemWraper>
          ))}
      {mainLayerLabel ? (
        <LegendItemWraper>
          <LabeledCheckbox
            children={mainLayerLabel}
            variant="WIDE"
            onChange={onDataLayerToggle}
            checked={isDataLayerVisible || false}
          />
          {mainLayerOpacityAdjustable && (
            <AuxiliaryControlsWrapper disabled={!isDataLayerVisible}>
              <LabeledSliderWrapper>
                Opacity
                <Slider
                  ariaLabel="Opacity slider"
                  marks={{
                    '0': '',
                    '1': '',
                  }}
                  value={dataLayerOpacity || 1}
                  onChange={onOpacityChange}
                  defaultValue={1}
                  min={0}
                  max={1}
                  step={0.01}
                />
              </LabeledSliderWrapper>
            </AuxiliaryControlsWrapper>
          )}
        </LegendItemWraper>
      ) : null}
    </>
  );
};
