import { useState } from 'react';
import styled from 'styled-components';

import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';
import { type SpecialSelectProps } from 'ui/atoms/select/types';
import { type ValueAndLabel, type StateValueAndLabel } from 'common/types';
import { DropdownItem } from './DropdownItem';
import { SearchElement } from './SearchElement';
import { selectComponentService } from '../selectComponentService';
import { REGULAR_SELECT_ROWS, SELECT_DROPDOWN_ROW_HEIGHT, SPECIAL_SELECT_ROWS } from '../constants';
import { Tooltip } from '../../tooltip';

interface SelectDropdownProps {
  isOpen: boolean;
  special: boolean;
  options: StateValueAndLabel[];
  selectedOption: ValueAndLabel | undefined;
  onOptionClicked: (option: ValueAndLabel) => void;
  onResetOptionClicked: () => void;
  reset?: () => void;
  initialStateLabel: string;
  haveOptions: boolean;
  maxHeight?: number;
  searchable?: boolean;
}

export const SelectDropdown = ({
  isOpen,
  special,
  options,
  selectedOption,
  onOptionClicked,
  onResetOptionClicked,
  reset,
  initialStateLabel,
  haveOptions,
  maxHeight,
  searchable,
}: SelectDropdownProps) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const { resultOptions, isSearchNeeded, query, onQueryChange } = selectComponentService.useFilterResults(
    options,
    special,
    isOpen,
  );

  const handleDropdownScroll = () => {
    setIsScrolled(true);

    setTimeout(() => {
      setIsScrolled(false);
    }, 500);
  };

  return (
    <DropdownWrapper>
      <StyledDropdown onScroll={handleDropdownScroll} special={special} maxHeight={maxHeight} data-testid="dropdown">
        {searchable && (
          <SearchElement
            isSearchNeeded={isSearchNeeded}
            rowHeight={SELECT_DROPDOWN_ROW_HEIGHT}
            query={query}
            onQueryChange={onQueryChange}
          />
        )}
        {!!reset && selectedOption && (
          <DropdownItem
            role="option"
            aria-label={`${initialStateLabel} option`}
            special={false}
            onClick={onResetOptionClicked}
            selected={false}
          >
            {initialStateLabel}
          </DropdownItem>
        )}
        {haveOptions &&
          resultOptions.map((option, index) => (
            <Tooltip key={index} disabled={!option.disabled || isScrolled} content="No data available">
              <DropdownItem
                role="option"
                aria-label={`${option.label} option`}
                onClick={option.disabled ? undefined : () => onOptionClicked(option)}
                $disabled={option.disabled}
                special={special}
                selected={selectedOption ? String(option.value) === String(selectedOption.value) : false}
              >
                {option.label}
              </DropdownItem>
            </Tooltip>
          ))}
      </StyledDropdown>
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div<{ maxHeight?: number }>`
  overflow-y: hidden;
  max-height: ${({ maxHeight }) => maxHeight}px;
  border-bottom-left-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;
  border-bottom-right-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;
`;

const StyledDropdown = styled(ScrollbarWrapper)<SpecialSelectProps & { maxHeight?: number }>`
  max-height: ${({ special, maxHeight }) =>
    maxHeight
      ? maxHeight
      : special
      ? SPECIAL_SELECT_ROWS * SELECT_DROPDOWN_ROW_HEIGHT
      : REGULAR_SELECT_ROWS * SELECT_DROPDOWN_ROW_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  border-bottom-right-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;
  border-bottom-left-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  overflow-x: hidden;

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
`;
