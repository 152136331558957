import { type ReactNode, type ComponentProps } from 'react';
import styled from 'styled-components';

import { BaseModal } from 'ui/modal';
import { IconWrapper } from 'ui/atoms/iconWrapper';

interface SummaryModalProps extends Omit<ComponentProps<typeof BaseModal>, 'variant' | 'padding'> {
  icon: JSX.Element;
  headerText: string;
  paragraphText: string;
  children: ReactNode;
}

export const SummaryModal = ({
  isOpen,
  closeModal,
  maxWidth = 360,
  icon,
  headerText,
  paragraphText,
  children,
}: SummaryModalProps) => {
  return (
    <BaseModal {...{ isOpen, closeModal, maxWidth }}>
      <ContentWrapper>
        <IconWrapper iconWidth={70} iconColor="#B6722F">
          {icon}
        </IconWrapper>
        <Title>{headerText}</Title>
        <Paragraph>{paragraphText}</Paragraph>
        {children}
      </ContentWrapper>
    </BaseModal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
`;

const Title = styled.h3`
  padding: 0 12px;
`;

const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
`;
