import styled from 'styled-components';

import { RangeSlider } from '.';

export interface RangeSelectorGeometry {
  top?: number;
  right?: number;
  bottom?: number;
}

export interface RangeSelectorProps {
  endValues: number[];
  selectedValues: number[];
  setSelectedValues: (value: number[]) => void;
  firstValueLabel: string;
  lastValueLabel: string;
  visible?: boolean;
  header?: string;
  geometry?: RangeSelectorGeometry;
  minLabel?: string;
  maxLabel?: string;
  step?: number;
}

export const RangeSelector = ({
  endValues,
  selectedValues,
  setSelectedValues,
  visible = false,
  header = 'Select Range',
  geometry,
  minLabel,
  maxLabel,
  firstValueLabel,
  lastValueLabel,
  step,
}: RangeSelectorProps) => {
  const firstLabelOffset = ((selectedValues[0] - endValues[0]) / (endValues[1] - endValues[0])) * 100;
  const lastLabelOffset = 100 - ((selectedValues[1] - endValues[0]) / (endValues[1] - endValues[0])) * 100;

  return (
    <RangeSelectorWrapper visible={visible} geometry={geometry}>
      <RangeSelectorContent>
        <Header>{header}</Header>
        <LabelsContainer>
          <div>{minLabel || endValues[0]}</div>
          <div>{maxLabel || endValues[1]}</div>
        </LabelsContainer>
        <RangeSlider
          value={selectedValues}
          setValue={setSelectedValues}
          min={endValues[0]}
          max={endValues[1]}
          step={step}
        />
        <LowerLabelsWrapper>
          <FillBox percentageSize={firstLabelOffset} />
          <LabelsContainer light>
            <div>{firstValueLabel}</div>
            <div>{lastValueLabel}</div>
          </LabelsContainer>
          <FillBox percentageSize={lastLabelOffset} />
        </LowerLabelsWrapper>
      </RangeSelectorContent>
    </RangeSelectorWrapper>
  );
};

interface RangeSelectorWrapperProps {
  visible: boolean;
  geometry?: RangeSelectorGeometry;
}

const RangeSelectorWrapper = styled.div<RangeSelectorWrapperProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  top: ${({ geometry }) => geometry?.top || 40}px;
  right: ${({ geometry }) => geometry?.right || 0}px;
`;

const RangeSelectorContent = styled.div`
  width: 300px;
  min-height: 100px;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  padding: 10px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
`;

const Header = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  text-align: left;
`;

const LabelsContainer = styled.div<{ light?: boolean }>`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: ${({ theme, light }) => (light ? theme.color.white : theme.color.lightGray)};
`;

const LowerLabelsWrapper = styled.div`
  display: flex;
`;

const FillBox = styled.div<{ percentageSize: number }>`
  flex-basis: ${({ percentageSize }) => `${percentageSize - 2}`}%;
`;
