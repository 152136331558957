import { Layer } from 'components/map';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { GeoJSONLineamentLayer } from './GeoJSONLineamentLayer';

interface LineamentProps {
  id: number;
  url: string;
  name: string;
  zIndex: number;
  isVisible: boolean;
  opacity?: number;
  isSelectionMode?: boolean;
}

export const Lineament = ({ id, url, name, zIndex, isVisible, opacity, isSelectionMode }: LineamentProps) => {
  const { geoJsonShape } = explorationRepository.useFetchGeoJsonShape(id, url);

  if (!geoJsonShape) return null;

  return (
    <Layer visible={isVisible} name={name} zIndex={zIndex}>
      <GeoJSONLineamentLayer
        pane={name}
        geoJson={geoJsonShape}
        opacity={opacity}
        withAuxiliaryFilling={isSelectionMode}
      />
    </Layer>
  );
};
