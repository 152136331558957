import { type LayerObject } from 'common/types/mapData';
import { useAoiNumber } from 'common/navigation/hooks';
import { useGreeneryObjectsUpdateUseCase } from 'views/greenery/useCases/greeneryObjectsUpdateUseCase';
import { quarterlyDataService } from 'views/greenery/quarterly/quarterlyDataService';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { Container } from 'ui/atoms/container';
import { AppLayerManager } from 'components/layerManager/legacy';
import { timestampToQuarterlyParams } from 'components/Timeline/helpers';
import { QuarterlyLayerManagerLegendWrapper } from './QuarterlyLayerManagerLegendWrapper';

interface QuarterlyLayerManagerProps {
  layerObjects: LayerObject[];
  greeneryObjectsLoading: boolean;
  timestamp: string | null;
  viewSpecificDisabledLayers?: string[];
  noBadges?: boolean;
}

export const QuarterlyLayerManager = ({
  layerObjects,
  greeneryObjectsLoading,
  timestamp,
  viewSpecificDisabledLayers = [],
  noBadges,
}: QuarterlyLayerManagerProps) => {
  const areaId = useAoiNumber();

  const { quarterlyResults } = quarterlyDataService.useFetchQuarterlyResults(areaId);

  const quarterlyDate = timestamp ? timestampToQuarterlyParams(timestamp) : undefined;

  const chosenResult = quarterlyResults.filter(
    ({ year, quarter }) => year === quarterlyDate?.year && quarter === quarterlyDate?.quarter,
  );

  const chosenResultId = chosenResult && chosenResult[0]?.id;

  useGreeneryObjectsUpdateUseCase(layerObjects, areaId);

  return (
    <AppLayerManager>
      {chosenResultId &&
        (greeneryObjectsLoading ? (
          <Container mt={10}>
            <LoadingDots />
          </Container>
        ) : (
          <QuarterlyLayerManagerLegendWrapper
            areaId={areaId}
            layerObjects={layerObjects}
            viewSpecificDisabledLayers={viewSpecificDisabledLayers}
            noBadges={noBadges}
            chosenResultId={chosenResultId}
          />
        ))}
    </AppLayerManager>
  );
};
