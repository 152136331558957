import { type ReactNode } from 'react';
import { InfoArea } from 'ui/infoArea';

import { useInfoAreaService } from 'ui/infoArea/infoAreaService';

interface GreeneryInfoAreaProps {
  children?: ReactNode;
}

export const GreeneryInfoArea = ({ children }: GreeneryInfoAreaProps) => {
  const infoAreaProps = useInfoAreaService();

  return <InfoArea {...infoAreaProps}>{children}</InfoArea>;
};
