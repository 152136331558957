import { type AxiosResponse } from 'axios';

import { USER_API_URLS } from 'infrastructure/user/userApiUrls';
import { restAPI } from 'services/rest/restApiService';
import {
  type UserSubscriptionDetailsDTO,
  type SessionDTO,
  type UserDetails,
  type UserPermissionsDTO,
} from 'domain/user/types';

export const userAPI = {
  signUp: ({ email, company }: { email: string; company: string }) => {
    return restAPI.public.post(USER_API_URLS.signUp, {
      email,
      company_name: company,
    });
  },

  login: ({ username, password }: { username: string; password: string }): Promise<AxiosResponse<SessionDTO>> =>
    restAPI.public.post(USER_API_URLS.login, { username, password }),

  logout: () => restAPI.private.post(USER_API_URLS.logout),

  tokenRefresh: async (bearer: string) => restAPI.private.post(USER_API_URLS.tokenRefresh, { headers: bearer }),

  getUserDetails: (): Promise<AxiosResponse<UserDetails>> => restAPI.private.get(USER_API_URLS.getData),

  getUserPermissions: (userId: number): Promise<AxiosResponse<UserPermissionsDTO>> =>
    restAPI.private.get(USER_API_URLS.getUserPermissions.replace(':userId', userId.toString())),

  resetPassword: (email: string) => restAPI.public.post(USER_API_URLS.passwordReset, { email }),

  changePassword: (data: { newPassword1: string; newPassword2: string; uid: string; token: string }) => {
    const { newPassword1, newPassword2, uid, token } = data;

    return restAPI.public.post(USER_API_URLS.passwordChange.replace(':userId', uid).replace(':token', token), {
      new_password1: newPassword1,
      new_password2: newPassword2,
      uid,
      token,
    });
  },

  getUserSubscriptionDetails: (): Promise<AxiosResponse<UserSubscriptionDetailsDTO>> =>
    restAPI.private.get(USER_API_URLS.subscription),
};
