import { useGetAoiName } from 'common/hooks/getAoiName';
import { MapClick } from 'components/map/tooltip/MapClick';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { HabitatsMapPredictionLayer } from './HabitatsMapPredictionLayer';
import { HabitatsMapExpandedAreaLayer } from './HabitatsMapExpandedAreaLayer';
import { HabitatsMapOccurenceLayer } from './HabitatsMapOccurenceLayer';

export const HabitatsMapContent = () => {
  const { areaName } = useGetAoiName();

  const { expandedAreaResult } = greeneryRepository.useFetchHabitatsExpandedAreaResults(areaName);
  const { occurenceResult } = greeneryRepository.useFetchHabitatsOccurenceResults(areaName);
  const { predictionResult } = greeneryRepository.useFetchHabitatsPredictionResults(areaName);

  return (
    <>
      {expandedAreaResult && <HabitatsMapExpandedAreaLayer result={expandedAreaResult} />};
      {occurenceResult && <HabitatsMapOccurenceLayer result={occurenceResult} />};
      {predictionResult && <HabitatsMapPredictionLayer result={predictionResult} />};
      <MapClick />;
    </>
  );
};
