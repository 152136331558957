import axios, { type AxiosResponse } from 'axios';
import { BACKEND_PREFIX } from 'common/constants/services';
import { setupInterceptors } from './interceptors';

export const restAPI = {
  private: setupInterceptors(
    axios.create({
      withCredentials: true,
      baseURL: BACKEND_PREFIX,
    }),
  ),

  public: axios.create({
    withCredentials: true,
    baseURL: BACKEND_PREFIX,
  }),

  services: setupInterceptors(
    axios.create({
      withCredentials: true,
    }),
  ),

  external: axios.create({
    withCredentials: false,
  }),
};

export const getApiData = async <T>(url: string, params: Record<string, unknown>): Promise<AxiosResponse<T>> => {
  return await restAPI.private.get(url, { params });
};

export const getExternalData = async <T>(url: string, params: Record<string, unknown>): Promise<AxiosResponse<T>> => {
  return await restAPI.external.get(url, {
    params,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
};
