import { useMemo } from 'react';

import { type Datetime } from 'common/types/time';
import { type GraphData } from 'components/graph/types';
import { useGraphStore } from 'components/graph/graphStore';
import { getFirstAndLastDateFromGraphData } from 'components/graph/helpers';
import { useRangeSliderService } from './rangeSliderService';

export const useTimeline = (timestamps: Datetime[], visible?: boolean) => {
  const graphData = useGraphStore.use.graphData();

  const filterTimestampsByGraphData = (timestamps: Datetime[], graphData: GraphData | undefined) => {
    const { firstGraphDate, lastGraphDate } = getFirstAndLastDateFromGraphData(graphData);

    const filteredTimestamps = timestamps.filter((dateString) => {
      const date = new Date(dateString).getTime();
      return date - firstGraphDate >= 0 && lastGraphDate - date >= 0;
    });

    return { filteredTimestamps };
  };

  const { filteredTimestamps } = useMemo(
    () => filterTimestampsByGraphData(timestamps, graphData),
    [timestamps, graphData],
  );

  const { rangeValue, setRangeValue, selectedTimestamps, firstDate, lastDate } =
    useRangeSliderService(filteredTimestamps);

  const isTimelineShowed = !!(filteredTimestamps.length && visible && firstDate && lastDate);

  const firstTimestamp = selectedTimestamps ? selectedTimestamps[0] : firstDate;
  const lastTimestamp = selectedTimestamps ? selectedTimestamps[selectedTimestamps.length - 1] : lastDate;

  return {
    filteredTimestamps,
    firstDate,
    lastDate,
    rangeValue,
    selectedTimestamps,
    setRangeValue,
    isTimelineShowed,
    firstTimestamp,
    lastTimestamp,
  };
};
