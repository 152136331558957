import styled from 'styled-components';

import { useCompositesStore } from 'views/exploration/composites/compositesStore';
import { FoldableSectionContentWrapper } from 'components';
import { type BandCombosData, type Provider, type ProviderType } from 'domain/composites/types';
import { ProviderField } from './ProviderField';
import { getBandCombosDataByProvider } from './helpers';

interface CompositesProvidersListProps {
  selectedProvider: ProviderType;
  setSelectedProvider: (provider: ProviderType) => void;
  providersWithData: Provider[];
  resultsForCurrentArea: BandCombosData[];
}

export const CompositesProvidersList = ({
  providersWithData,
  selectedProvider,
  setSelectedProvider,
  resultsForCurrentArea,
}: CompositesProvidersListProps) => {
  const areCompositesVisible = useCompositesStore.use.areCompositesVisible();
  const toggleCompositesVisibility = useCompositesStore.use.toggleCompositesVisibility();
  const providersDataOpacityDict = useCompositesStore.use.dataOpacityDictionary();
  const setProvidersDataOpacityDict = useCompositesStore.use.setDataOpacity();

  const handleProviderSelect = (option: ProviderType) => {
    setSelectedProvider(option);
  };

  const onOptionSelect = (option: ProviderType) => {
    handleProviderSelect(option);
    !areCompositesVisible && toggleCompositesVisibility();
  };

  const handleProviderDataOpacityChange = (provider: ProviderType, value: number | number[]) => {
    if (typeof value === 'number') {
      setProvidersDataOpacityDict(provider, value);
    }
  };

  return (
    <FoldableSectionContentWrapper>
      <ProvidersList>
        {providersWithData.map((provider) => (
          <ProviderField
            key={provider.name}
            name={provider.name as ProviderType}
            title={provider.title}
            onOptionSelect={onOptionSelect}
            isSelected={areCompositesVisible && provider.name === selectedProvider}
            bandCombosData={getBandCombosDataByProvider(resultsForCurrentArea, provider.name)}
            opacity={providersDataOpacityDict[provider.name as keyof typeof providersDataOpacityDict]}
            onOpacityChange={handleProviderDataOpacityChange}
          />
        ))}
      </ProvidersList>
    </FoldableSectionContentWrapper>
  );
};

const ProvidersList = styled.ul`
  list-style: none;
  padding: 0 14px;
`;
