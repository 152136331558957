import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { type Position } from 'geojson';
import L from 'leaflet';
import 'leaflet-draw';

import { Vertex } from 'ui/map/areaSelector/Vertex';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';

interface PolygonProps {
  coordinates: Position[];
  minLength: number;
  onVertexClick: (vertex: Position) => void;
  alert: boolean;
}

export const Polygon = ({ coordinates, alert, minLength, onVertexClick }: PolygonProps) => {
  const map = useMap();
  const warning = alert;
  const color = warning ? '#E07C5C' : '#fff';

  const updateArea = useAreaRequestStore.use.setArea();

  useEffect(() => {
    if (!coordinates || coordinates.length < minLength || coordinates[0] === coordinates[1]) return;

    const polygon = L.polygon(coordinates, {
      color,
      fill: false,
      opacity: 0.7,
      weight: 2,
      fillOpacity: 0.35,
      pane: 'verticesLayer',
    }).addTo(map);

    const area = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0]);

    const readableArea = area && L.GeometryUtil.readableArea(area, 'km', 3);
    updateArea(area);

    readableArea &&
      polygon.bindTooltip(readableArea, {
        permanent: true,
        direction: 'center',
        className: `polygon-area-label ${warning ? 'polygon-area-label-warning' : ''}`,
      });

    return () => {
      polygon.remove();
    };
  }, [color, coordinates, map, warning, minLength, updateArea]);

  return coordinates.map((vertex, index) =>
    vertex.length === 2 ? <Vertex key={index} {...{ vertex, color }} onClick={onVertexClick} /> : null,
  );
};
