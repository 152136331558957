import { ROUTES } from 'common/navigation/routes';
import { PrivateRoute } from 'common/navigation/PrivateRoute';
import { Segmentation } from 'views/greenery/segmentation/Segmentation';
import { Greenery } from 'views/greenery/Greenery';
import { Quarterly } from 'views/greenery/quarterly/Quarterly';
import { Vegetation } from 'views/greenery/vegetation/Vegetation';
import { VHR } from 'views/greenery/vhr/VHR';
import { VCI } from 'views/greenery/vci/VCI';
import { Habitats } from 'views/greenery/habitats/Habitats';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useUserStore } from 'infrastructure/user/userStore';
import { GreeneryActiveTab } from 'domain/greenery/enums';

export const useBuildGreeneryRouteObject = () => {
  const { userPermissions } = useUserStore();

  return {
    path: ROUTES.greenery,
    loader: greeneryRepository.greeneryObjectsLoader,
    element: <Greenery />,
    children: [
      {
        path: ROUTES.landCover,
        index: true,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(GreeneryActiveTab.SEGMENTATION)}
            redirectPath={ROUTES.areasList}
          >
            <Segmentation />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.quarterlyAnalysis,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(GreeneryActiveTab.QUARTERLY)}
            redirectPath={ROUTES.areasList}
          >
            <Quarterly />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.vegetation,
        loader: greeneryRepository.greeneryIndicatorsLoader,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(GreeneryActiveTab.VEGETATION)}
            redirectPath={ROUTES.areasList}
          >
            <Vegetation />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.vhrSegmentation,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(GreeneryActiveTab.VHR)}
            redirectPath={ROUTES.areasList}
          >
            <VHR />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.vci,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(GreeneryActiveTab.VCI)}
            redirectPath={ROUTES.areasList}
          >
            <VCI />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.habitats,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(GreeneryActiveTab.HABITATS)}
            redirectPath={ROUTES.areasList}
          >
            <Habitats />
          </PrivateRoute>
        ),
      },
    ],
  };
};
