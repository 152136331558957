import { getColorScaleValuesHelper } from 'common/utils/color';

export const withIndicatorConfig = <I extends { indicator_name: string }, C>(
  indicators: I[],
  configuration: { [name: string]: C },
) =>
  indicators.map((indicator) => {
    const inficatorConfig = configuration[indicator.indicator_name] || configuration.default_indicator;

    return {
      ...indicator,
      ...inficatorConfig,
    };
  });

export const withIndicatorLabels = <I extends { min_value: number; max_value: number; precision: number }>(
  indicators: I[],
  areBetweenValues?: boolean,
) =>
  indicators.map((indicator) => {
    return {
      ...indicator,
      labels: getColorScaleValuesHelper(
        indicator.min_value,
        indicator.max_value,
        indicator.precision,
        areBetweenValues,
      ).map(String),
    };
  });
