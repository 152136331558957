export const USER_API_URLS = {
  signUp: '/users/rest-auth/registration/',
  login: '/users/rest-auth/login/',
  logout: '/users/rest-auth/logout/',
  tokenRefresh: '/users/rest-auth/token/refresh/',
  getData: '/users/rest-auth/user/',
  getUserPermissions: '/users/:userId/permissions/',
  passwordReset: '/users/rest-auth/password/reset/',
  passwordChange: '/users/rest-auth/password/reset/confirm/:userId/:token/',
  subscription: '/users/me/subscription/',
};
