import { extractionRepository } from 'infrastructure/extraction/extractionRepository';
import { useTimelineService } from 'components/Timeline/timelineService';
import { SEGMENTATION_OBJECTS_OPTIONS } from 'domain/extraction/configs';
import { useGetExtractionResultsUseCase } from './useGetExtractionResultsUseCase';

export const useGetExtractionPolygonsUseCase = () => {
  const { timestamp } = useTimelineService();
  const { mineResults } = useGetExtractionResultsUseCase();

  const resultsForSelectedDate = mineResults.filter((result) => result.img_date === timestamp);
  const activeResultIdsForSelectedDate = resultsForSelectedDate
    .filter(
      (result) =>
        SEGMENTATION_OBJECTS_OPTIONS.find((option) => option.id === result.mine_object.id)?.status === 'active',
    )
    .map(({ id }) => id);

  const { minePolygons, minePolygonsLoading } = extractionRepository.useFetchMinePolygons(
    activeResultIdsForSelectedDate,
    resultsForSelectedDate.length,
  );

  return { minePolygons, minePolygonsLoading };
};
