import { ReactComponent as TreeIcon } from 'assets/svg/tree-icon.svg';
import { AreaPages } from 'common/navigation/constants';
import { useBuildNavigationItem } from 'views/areas/areaDetails/useBuildNavigationItem';
import { GREENERY_VIEW_CONFIG } from 'domain/greenery/configs';

export const useBuildGreeneryNavItem = () => {
  return useBuildNavigationItem({
    config: GREENERY_VIEW_CONFIG,
    label: 'land cover',
    icon: <TreeIcon />,
    iconSize: 12,
    type: AreaPages.LAND_COVER,
  });
};
