import { type ExtendedIndicator } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { useReportsExternalInitialDataService } from 'views/reports/external/data/services/reportsExternalInitialDataService';
import { VegetationDoubleDateReportsExternalData } from 'views/reports/external/data/VegetationDoubleDateReportsExternalData';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface VegetationDobuleDateReportsExternalInitialDataProps {
  areaId: number;
  timestamp: string;
  selectedIndicator: ExtendedIndicator;
  selectedSegmentationClass: LayerObject;
  reportInfo: ExternalReportHeaderCommon;
}

export const VegetationDobuleDateReportsExternalInitialData = ({
  areaId,
  timestamp,
  selectedIndicator,
  selectedSegmentationClass,
  reportInfo,
}: VegetationDobuleDateReportsExternalInitialDataProps) => {
  const initialDataset = useReportsExternalInitialDataService(timestamp);

  return (
    initialDataset && (
      <VegetationDoubleDateReportsExternalData
        areaId={areaId}
        initialDataset={initialDataset}
        selectedIndicator={selectedIndicator}
        selectedSegmentationClass={selectedSegmentationClass}
        reportInfo={reportInfo}
      />
    )
  );
};
