import { Circle } from 'react-leaflet';
import { type Position } from 'geojson';

import { HABITATS_MAP_OCCURENCE_POINT_CONFIG } from 'domain/greenery/configs';

interface Props {
  position: Position;
}

export const HabitatsOccurencePointMarker = ({ position }: Props) => {
  return <Circle center={[position[1], position[0]]} pathOptions={HABITATS_MAP_OCCURENCE_POINT_CONFIG} />;
};
