export const REFRESH_STATE_CHECK_INTERVAL = 15 * 60_000;
export const LOGIN_REFRESH_ADVANCE = 5 * 60_000;

export const USER_LOGIN_QUERY_KEY = 'users_login';
export const USER_DETAILS_QUERY_KEY = 'users_details';
export const USER_PERMISSIONS_QUERY_KEY = 'user_permissions';
export const USERS_PERMISSIONS_QUERY_KEY = 'users_permissions';
export const USER_SUBSCRIPTION_DETAILS_QUERY_KEY = 'user_subscription_details';

export const ACTIVE_SUBSCRIPTION_LOCAL_STORAGE_KEY = 'RSOM_active_subscription';
export const AREA_QUOTA_LOCAL_STORAGE_KEY = 'RSOM_area_quota';
export const SUBSCRIPTION_CART_LOCAL_STORAGE_KEY = 'RSOM_subscription_cart';
export const CLIENT_SECRET_LOCAL_STORAGE_KEY = 'RSOM_client_secret';
export const SESSION_ID_LOCAL_STORAGE_KEY = 'RSOM_session_id';
