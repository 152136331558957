import styled, { css } from 'styled-components';

import { MapControlButton } from 'ui/map/mapControlButton';
import { type InfoAreaGeometry, type InfoAreaProps } from 'ui/infoArea/types';
import { ReactComponent as InfoIcon } from 'assets/svg/infoIcon.svg';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { REPORTS_INFO_AREA_DESCRIPTION } from 'domain/reports/constants';

export const InfoArea = ({
  geometry: { topOffset, width, zIndex, lowerClearance },
  isInfoAreaVisible,
  toggleInfoAreaVisibility,
  errors,
  iconColor,
  disableExtraContent,
  children,
}: InfoAreaProps) => {
  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <>
      <MapControlButtonWrapper>
        <MapControlButton
          onClick={toggleInfoAreaVisibility}
          icon={<InfoIcon />}
          iconWidth={4}
          iconColor={iconColor}
          active={isInfoAreaVisible && (!!children || !!errors)}
        />
      </MapControlButtonWrapper>
      <PositionResetWrapper exist={disableExtraContent}>
        <InfoAreaBody
          visible={isInfoAreaVisible && (!!children || !!errors)}
          width={width || 300}
          topOffset={topOffset}
          zIndex={zIndex}
          lowerClearance={lowerClearance}
        >
          {errors}
          <InfoAreaContent>{areReportsActive ? REPORTS_INFO_AREA_DESCRIPTION : children}</InfoAreaContent>
        </InfoAreaBody>
      </PositionResetWrapper>
    </>
  );
};

InfoArea.defaultProps = {
  disableExtraContent: false,
  children: null,
};
InfoArea.displayName = 'InfoArea';

const PositionResetWrapper = styled.span<{ exist?: boolean }>`
  ${({ exist }) =>
    exist &&
    css`
      display: block;
      position: relative;
      width: 0;
    `}
`;

interface InfoAreaBodyProps extends InfoAreaGeometry {
  visible: boolean;
}

const InfoAreaBody = styled.div<InfoAreaBodyProps>`
  position: absolute;
  width: ${({ width }) => (width ? `${width}px` : 'none')};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  line-height: 2;
  top: ${({ topOffset }) => topOffset || 0}px;
  left: 0;
  transition: left 500ms ease-out, top 200ms ease-out;
  z-index: ${({ zIndex }) => zIndex};
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  border-radius: 8px;
  font-weight: 500;
  line-height: 1.35;
  overflow: hidden;
  max-height: calc(100vh - 200px - ${({ lowerClearance }) => lowerClearance || 0}px);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
`;

const InfoAreaContent = styled.div`
  padding: 10px 10px 18px;
  font-size: 12px;
`;

const MapControlButtonWrapper = styled.div`
  z-index: 1010;
`;
