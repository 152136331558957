import styled from 'styled-components';

import loginBackgroundUSrc from 'assets/img/login-background.jpg';
import { LOCAL_STORAGE_ACCESS_TOKEN_NAME } from 'common/constants/services';
import { LoginSidebarConnected } from 'views/user/LoginSidebarConnected';
import { localStorageService } from 'services/localStorageService';
import { LoginAuto } from './LoginAuto';

export const Login = () => {
  const localStorageAccessToken = localStorageService.getValue(LOCAL_STORAGE_ACCESS_TOKEN_NAME);

  return (
    <>
      {localStorageAccessToken && <LoginAuto />}
      <LoginSidebarConnected />
      <LoginBackground />
    </>
  );
};

export const LoginBackground = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  overflow-y: auto;
  padding: 50px;
  padding-top: 120px;
  background: ${({ theme }) => theme.color.masala};
  background: url(${loginBackgroundUSrc});
  background-size: cover;
  color: ${({ theme }) => theme.color.white};
`;
