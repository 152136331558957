import { useQuery } from '@tanstack/react-query';

import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { areProductProviderResultsValid } from 'domain/registry/typeguards';
import { REGISTRY_PRODUCT_PROVIDERS_QUERY_KEY } from './constants';
import { reqistryAPI } from './registryApiAdapter';

export const registryRepository = {
  useFetchProductProviders: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data } = useQuery([REGISTRY_PRODUCT_PROVIDERS_QUERY_KEY], () =>
      reqistryAPI.getProductProviders({ limit, offset }),
    );

    const results = data?.data?.results;
    const providers = areProductProviderResultsValid(results) ? results : [];

    return { providers };
  },
};
