import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
  LOCAL_STORAGE_IS_CANCELING_SUBSCRIPTION,
  LOCAL_STORAGE_IS_SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_CANCEL_CLEANUP_TIMEOUT,
} from 'common/constants/services';
import { SUBSCRIPTION_STATUS_QUERY_KEY } from 'infrastructure/subscription/constants';
import { useUserStore } from 'infrastructure/user/userStore';
import { localStorageService } from 'services/localStorageService';

export const useBindSubscriptionState = () => {
  const { isSubscriptionStatusPending, isWaitingForSubscriptionCancel, setIsWaitingForSubscriptionCancel } =
    useUserStore();

  const queryClient = useQueryClient();

  useEffect(() => {
    localStorageService.setValue(LOCAL_STORAGE_IS_SUBSCRIPTION_STATUS_PENDING, isSubscriptionStatusPending);
  }, [isSubscriptionStatusPending]);

  useEffect(() => {
    localStorageService.setValue(LOCAL_STORAGE_IS_CANCELING_SUBSCRIPTION, isWaitingForSubscriptionCancel);
  }, [isWaitingForSubscriptionCancel]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isWaitingForSubscriptionCancel) {
        setIsWaitingForSubscriptionCancel(false);
        localStorageService.removeValue(LOCAL_STORAGE_IS_CANCELING_SUBSCRIPTION);
        queryClient.invalidateQueries({ queryKey: [SUBSCRIPTION_STATUS_QUERY_KEY] });
      }
    }, SUBSCRIPTION_CANCEL_CLEANUP_TIMEOUT);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaitingForSubscriptionCancel, setIsWaitingForSubscriptionCancel]);
};
