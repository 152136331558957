import { CompositesMapLayer } from './CompositesMapLayer';
import { useCompositesStore } from './compositesStore';

export const CompositesMapContent = () => {
  const areCompositesVisible = useCompositesStore.use.areCompositesVisible();
  const selectedBandCombos = useCompositesStore.use.selectedBandCombos();
  const providersDataOpacityDict = useCompositesStore.use.dataOpacityDictionary();

  if (!selectedBandCombos) {
    return null;
  }

  const opacity = providersDataOpacityDict[selectedBandCombos.provider as keyof typeof providersDataOpacityDict];

  return (
    <CompositesMapLayer
      url={selectedBandCombos.url}
      rescaleFactors={selectedBandCombos.limits}
      visible={areCompositesVisible}
      opacity={opacity}
    />
  );
};
