import { useLocation } from 'react-router-dom';

import { useQueryParameter } from 'common/navigation/hooks';
import { REPORT_TYPE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { REPORTS_CREATOR_OPTIONS } from 'domain/reports/constants';
import { ReportType } from 'domain/reports/enums';

export const useGetActiveOptionReportsCreatorUseCase = () => {
  const { pathname } = useLocation();

  const reportTypeQuery = useQueryParameter(REPORT_TYPE_QUERY_PARAMETER);
  const activeType = REPORTS_CREATOR_OPTIONS.find(({ name }) => reportTypeQuery === name);
  const activeFunctionality = activeType?.options.find((option) => pathname.includes(option.name));
  const isCompareDatesType = activeType?.name === ReportType.COMPARE_DATES;

  return { activeType, activeFunctionality, isCompareDatesType };
};
