import { type AreaEnvironmentsTypes } from 'common/navigation/types';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { isAreaEnvironmentValid } from 'domain/areas/typeguards';

export const useGetAllAreasEnvironments = (): AreaEnvironmentsTypes[] => {
  const { areasList } = areasRepository.useFetchAreasOfInterestList();

  const areaEnvironments = areasList
    .map(({ environments }) => environments)
    .flat()
    .filter((environment) => isAreaEnvironmentValid(environment));

  const uniqueAreaEnvironments = Array.from(new Set(areaEnvironments));

  return uniqueAreaEnvironments;
};
