import { useLocation } from 'react-router';

import { GreeneryReportsCreatorDates } from 'views/reports/creator/dates/views/GreeneryReportsCreatorDates';
import { WaterReportsCreatorDates } from 'views/reports/creator/dates/views/WaterReportsCreatorDates';
import { ExtractionReportsCreatorDates } from 'views/reports/creator/dates/views/ExtractionReportsCreatorDates';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { WaterActiveTab } from 'domain/water/enums';
import { MINE_EXTRACTION_PATH_NAME } from 'domain/extraction/constants';

export const ReportsCreatorDates = () => {
  const { pathname } = useLocation();

  switch (true) {
    case pathname.includes(GreeneryActiveTab.SEGMENTATION) || pathname.includes(GreeneryActiveTab.VEGETATION):
      return <GreeneryReportsCreatorDates />;

    case pathname.includes(WaterActiveTab.SURFACE) || pathname.includes(WaterActiveTab.QUALITY):
      return <WaterReportsCreatorDates />;

    case pathname.includes(MINE_EXTRACTION_PATH_NAME):
      return <ExtractionReportsCreatorDates />;

    default:
      return null;
  }
};
