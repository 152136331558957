import { type AxiosResponse } from 'axios';

import { getApiData, getExternalData } from 'services/rest/restApiService';
import { EXTRACTION_API_URLS } from 'infrastructure/extraction/extractionApiUrls';
import {
  type MineResultsApiResponse,
  type MineActivityIndexResultsApiResponse,
  type MineObjectsApiResponse,
  type MinePolygon,
} from 'domain/extraction/types';

export const extractionAPI = {
  getMineObjects: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<MineObjectsApiResponse>> => getApiData(EXTRACTION_API_URLS.getObjects, { limit, offset }),

  getMineResults: ({
    areaId,
    limit,
    offset,
  }: {
    areaId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<MineResultsApiResponse> | undefined> =>
    getApiData(EXTRACTION_API_URLS.getResults, {
      aoi_id: areaId,
      limit,
      offset,
    }),

  getMineResultsWithLimit: ({
    areaId,
    limit,
    offset,
  }: {
    areaId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<MineResultsApiResponse> | undefined> =>
    getApiData(EXTRACTION_API_URLS.getResults, {
      aoi_id: areaId,
      limit,
      offset,
    }),

  getMinePolygon: (id: number): Promise<AxiosResponse<MinePolygon>> =>
    getApiData(EXTRACTION_API_URLS.getPolygonById.replace(':objectId', String(id)), {}),

  getMineActivityIndexResults: async ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<MineActivityIndexResultsApiResponse>> =>
    getExternalData(EXTRACTION_API_URLS.getMineActivityIndexResults, {
      limit,
      offset,
    }),
};
