import { useEffect, useRef, type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { SidebarContainer } from 'ui/sidebar/SidebarContainer';
import { SidebarFooter } from 'ui/sidebar/SidebarFooter';
import { SidebarHeader } from 'ui/sidebar/SidebarHeader';
import { SidebarPanelLayout } from 'ui/sidebar/SidebarPanelLayout';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { useGetUserInfo } from 'ui/sidebar/useGetUserInfo';
import { useSidebarFolding } from 'ui/sidebar/useSidebarFolding';
import { useGetSidebarPanelContent } from 'ui/sidebar/useGetSidebarPanelContent';
import type { SidebarVariants } from 'ui/sidebar/types';

interface SidebarProps {
  variant: SidebarVariants;
  children?: ReactNode;
  allowHiding?: boolean;
}

export const Sidebar = ({ variant, children, allowHiding }: SidebarProps) => {
  const sidebarRef = useRef<HTMLElement>(null);

  const { pathname } = useLocation();

  const { foldSidebar } = useSidebarFolding(sidebarRef, allowHiding);

  const closeSidebarPanel = useSidebarStore.use.closeSidebarPanel();
  const isSidebarPanelOpen = useSidebarStore.use.isSidebarPanelOpen();
  const sidebarPanelType = useSidebarStore((state) => state.sidebarPanelType);

  const { userName } = useGetUserInfo();

  const isFooterFolded = variant !== 'UNFOLDED';

  const { SIDEBAR_PANEL_CONTENT } = useGetSidebarPanelContent();

  useEffect(() => {
    closeSidebarPanel();
  }, [pathname, closeSidebarPanel]);

  return (
    <SidebarContainer
      ref={sidebarRef}
      $bare={variant === 'BARE'}
      $folded={isFooterFolded}
      isSidebarPanelOpen={isSidebarPanelOpen}
      onPointerLeave={foldSidebar}
      data-testid="sidebar"
    >
      {variant !== 'BARE' && <SidebarHeader userName={userName} isFolded={variant === 'FOLDED'} />}
      {children}
      <SidebarFooter vertical={isFooterFolded} />
      <SidebarPanelLayout
        sidebarVariant={variant}
        content={sidebarPanelType ? SIDEBAR_PANEL_CONTENT[sidebarPanelType] : undefined}
      />
    </SidebarContainer>
  );
};
