import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layer } from 'components/map';
import { type NotificationType } from 'domain/monitoring/types';
import { MapNoteMarker } from './MapNoteMarker';
import { NotePopup } from './MapNotePopup';
import { useMonitoringInfoStore } from '../store';

export const MapNoteMarkers = () => {
  const [openedItem, setOpenedItem] = useState<NotificationType | null>(null);
  const notifications = useMonitoringInfoStore.use.notifications();
  const navigate = useNavigate();

  return (
    <>
      {notifications && (
        <Layer visible={true} name="pin-layer" zIndex={501} className={'pin-layer-popup'}>
          {notifications.properties.notifications.map((item) => (
            <MapNoteMarker
              item={item}
              key={item.properties.id}
              active={item.properties.id === openedItem?.properties.id}
            >
              <NotePopup
                item={item}
                key={item.properties.id}
                staticText
                onOpen={(pin: NotificationType) => setOpenedItem(pin)}
                onClose={() => setOpenedItem(null)}
                onSubmit={(item: NotificationType) => navigate(item.properties.route)}
                openedItem={openedItem}
              />
            </MapNoteMarker>
          ))}
        </Layer>
      )}
    </>
  );
};
