import { type ComponentProps, type CSSProperties, type ReactNode } from 'react';
import Spinner from 'react-spinner-material';
import styled from 'styled-components';
import { type Palette } from 'ui/types';

type ButtonVariants =
  | 'PRIMARY_BIG'
  | 'PRIMARY'
  | 'PRIMARY_MID'
  | 'PRIMARY_SMALL'
  | 'PRIMARY_SMALL_UPPERCASE'
  | 'PRIMARY_SUPER_SMALL'
  | 'PRIMARY_REVERSE'
  | 'SECONDARY'
  | 'SECONDARY_SMALL'
  | 'SECONDARY_SMALL_DARK'
  | 'SECONDARY_SUPER_SMALL'
  | 'TERTIARY'
  | 'LIGHT';

type ButtonStyles = Required<
  Pick<
    CSSProperties,
    'color' | 'backgroundColor' | 'fontSize' | 'fontWeight' | 'textTransform' | 'letterSpacing' | 'padding'
  > & {
    height: number;
    hoverColor?: CSSProperties['color'];
    hoverBackgroundColor?: CSSProperties['backgroundColor'];
    disabledBackgroundColor?: CSSProperties['backgroundColor'];
  }
>;

const styles: Palette<ButtonVariants, ButtonStyles> = {
  PRIMARY_BIG: {
    color: 'white',
    backgroundColor: 'primary',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
    disabledBackgroundColor: 'selectGray',
    height: 50,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 25px',
  },
  PRIMARY: {
    color: 'white',
    backgroundColor: 'primary',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
    disabledBackgroundColor: 'selectGray',
    height: 40,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 20px',
  },
  PRIMARY_MID: {
    color: 'white',
    backgroundColor: 'primary',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
    disabledBackgroundColor: 'selectGray',
    height: 36,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 20px',
  },
  PRIMARY_SMALL: {
    color: 'white',
    backgroundColor: 'primary',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
    disabledBackgroundColor: 'selectGray',
    height: 30,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: 0,
    padding: '0 15px',
  },
  PRIMARY_SMALL_UPPERCASE: {
    color: 'white',
    backgroundColor: 'primary',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
    disabledBackgroundColor: 'selectGray',
    height: 30,
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 15px',
  },
  PRIMARY_SUPER_SMALL: {
    color: 'white',
    backgroundColor: 'primary',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
    disabledBackgroundColor: 'selectGray',
    height: 20,
    fontSize: 10,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 8px',
  },
  PRIMARY_REVERSE: {
    color: 'primary',
    backgroundColor: 'white',
    hoverColor: 'primaryDark',
    hoverBackgroundColor: 'white',
    disabledBackgroundColor: 'selectGray',
    height: 36,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 20px',
  },
  SECONDARY: {
    color: 'white',
    backgroundColor: 'selectGray',
    hoverColor: 'white',
    hoverBackgroundColor: 'mineShaftLight',
    disabledBackgroundColor: 'selectGray',
    height: 40,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 20px',
  },
  SECONDARY_SMALL: {
    color: 'white',
    backgroundColor: 'selectGray',
    hoverColor: 'white',
    hoverBackgroundColor: 'mineShaftLight',
    disabledBackgroundColor: 'selectGray',
    height: 30,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: 0,
    padding: '0 15px',
  },
  SECONDARY_SMALL_DARK: {
    color: 'white',
    backgroundColor: 'mineShaftABitLighter',
    hoverColor: 'white',
    hoverBackgroundColor: 'mineShaftDark',
    disabledBackgroundColor: 'mineShaftABitLighter',
    height: 30,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: 0,
    padding: '0 15px',
  },
  SECONDARY_SUPER_SMALL: {
    color: 'white',
    backgroundColor: 'selectGray',
    hoverColor: 'white',
    hoverBackgroundColor: 'mineShaftLight',
    disabledBackgroundColor: 'selectGray',
    height: 20,
    fontSize: 10,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 8px',
  },
  TERTIARY: {
    color: 'white',
    backgroundColor: 'donkeyBrown',
    hoverColor: 'white',
    hoverBackgroundColor: 'shadow',
    disabledBackgroundColor: 'selectGray',
    height: 40,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 20px',
  },
  LIGHT: {
    color: 'black',
    backgroundColor: 'white',
    hoverColor: 'black',
    hoverBackgroundColor: 'silverDark',
    disabledBackgroundColor: 'selectGray',
    height: 30,
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    padding: '0 20px',
  },
};

export interface ButtonProps extends ComponentProps<'button'> {
  children: ReactNode;
  variant?: ButtonVariants;
  minWidth?: number;
  fullWidth?: boolean;
  squared?: boolean;
  isLoading?: boolean;
  spinnerSize?: number;
  spinnerStroke?: number;
}

export const Button = ({
  children,
  variant = 'PRIMARY',
  type = 'button',
  name,
  disabled = false,
  minWidth = 100,
  fullWidth = false,
  squared = false,
  isLoading = false,
  spinnerSize = 28,
  spinnerStroke = 2,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: ButtonProps) => {
  return (
    <StyledButton
      disabled={disabled || isLoading}
      {...{ variant, type, name, minWidth, fullWidth, squared, onClick, onMouseEnter, onMouseLeave }}
      aria-label={`${name || children?.toString().toLowerCase() || 'generic button'}`}
    >
      {isLoading ? <Spinner stroke={spinnerStroke} radius={spinnerSize} aria-busy="true" /> : children}
    </StyledButton>
  );
};

interface StyledButtonProps {
  variant: ButtonVariants;
  disabled: boolean;
  minWidth: number;
  fullWidth: boolean;
  squared: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  color: ${(props) => props.theme.color[styles[props.variant!].color]};
  background-color: ${(props) => props.theme.color[styles[props.variant!].backgroundColor]};
  height: ${({ variant }) => styles[variant!].height}px;
  min-width: ${({ minWidth }) => minWidth}px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  border-radius: ${({ variant, squared }) => (squared ? 0 : styles[variant!].height / 2)}px;
  outline: none;
  border: none;
  padding: ${({ variant }) => styles[variant!].padding};
  cursor: pointer;
  font-weight: ${({ variant }) => styles[variant!].fontWeight};
  font-size: ${({ variant }) => styles[variant!].fontSize}px;
  font-family: 'Barlow', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: ${({ variant }) => styles[variant!].letterSpacing}em;
  text-transform: ${({ variant }) => styles[variant!].textTransform};
  white-space: nowrap;
  transition: background-color ${({ theme }) => theme.transitionFunction.main};
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:hover {
    color: ${(props) => props.theme.color[styles[props.variant!].hoverColor]};
    background-color: ${(props) => props.theme.color[styles[props.variant!].hoverBackgroundColor]};
  }

  &:disabled {
    color: ${({ theme }) => theme.color.lightGray};
    background-color: ${(props) => props.theme.color[styles[props.variant!].disabledBackgroundColor]};
    pointer-events: none;
  }

  .react-spinner-material {
    border-color: ${(props) => props.theme.color[styles[props.variant!].color]} !important;
  }
`;

Button.defaultProps = {
  variant: 'PRIMARY' as ButtonVariants,
  type: 'button' as ComponentProps<typeof Button>['type'],
  disabled: false,
  squared: false,
};

Button.displayName = 'Button';
