import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { userRepository } from 'infrastructure/user/userRepository';
import { useUserStore } from 'infrastructure/user/userStore';

export const useSubscriptionColumnLoading = () => {
  const { isActiveSubscriptionLoading } = subscriptionRepository.useGetStatus();
  const { areUserSubscriptionDetailsLoading } = userRepository.useFetchUserSubscriptionDetails();
  const isSubscriptionStatusPending = useUserStore().isSubscriptionStatusPending;
  const { isSubscriptionProductsListLoading } = subscriptionRepository.useGetProductsList();

  const isLoading =
    isActiveSubscriptionLoading ||
    areUserSubscriptionDetailsLoading ||
    isSubscriptionStatusPending ||
    isSubscriptionProductsListLoading;

  return { isLoading };
};
