import { buildLinearGraphConfig } from 'components/graph/linearGraphConfig';
import { useTimeline } from 'components/Timeline/useTimeline';
import { Timeline } from 'components/Timeline/Timeline';
import { Graph } from 'components/graph';
import { useGraphStore } from 'components/graph/graphStore';
import { getCoordinateString } from 'components/graph/helpers';
import { GraphWeatherControls } from 'components/graph/GraphWeatherControls';
import { useReportWeatherDataError } from 'components/graph/useReportWeatherDataError';
import { GraphWaterInfoTitleWrapper, GraphWaterInfoWrapper } from 'components/graph/styled';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { simpleRound } from 'common/utils';
import { SURFACE_PRECISION_HA } from 'common/constants';
import { useGetIsComparisonActive } from 'common/navigation/hooks';
import { WATER_PATH_NAME } from 'domain/water/constants';
import { WATERBODY_INDICATORS_CONFIGURATION } from 'domain/water/configs';
import { useWaterTimestamps } from '../useWaterTimestamps';
import { useWaterbodies } from '../waterbodies';

export const WaterAreaTimeline = () => {
  const { timestamps } = useWaterTimestamps();
  const { isComparisonActive } = useGetIsComparisonActive();
  const areReportsActive = useGetAreReportsActiveUseCase();
  const { selectedWaterbody } = useWaterbodies();
  const waterbodyLabel = selectedWaterbody?.label;

  const dataPoint = useGraphStore.use.chosenPoint();

  const { reportError } = useReportWeatherDataError(WATER_PATH_NAME);

  const coordinateString = dataPoint ? getCoordinateString('Point', dataPoint) : '';

  const {
    filteredTimestamps,
    firstDate,
    lastDate,
    rangeValue,
    selectedTimestamps,
    setRangeValue,
    isTimelineShowed,
    firstTimestamp,
    lastTimestamp,
  } = useTimeline(timestamps, !isComparisonActive && !areReportsActive);

  const waterGraphValueFormatter = (num_or_millis: number, _opts: never, seriesName: string) => {
    const precision = WATERBODY_INDICATORS_CONFIGURATION[seriesName]?.precision;
    const decimalPlaces = typeof precision === 'number' ? precision : SURFACE_PRECISION_HA;

    return simpleRound(num_or_millis, decimalPlaces);
  };

  const graphConfigExtension = {
    ...buildLinearGraphConfig(['surface'], waterGraphValueFormatter),
    labelsKMB: true,
  };

  return (
    <>
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          visible: !isComparisonActive && !areReportsActive,
        }}
      />
      {!areReportsActive && (
        <Graph
          isTimelineVisible={isTimelineShowed}
          dateRange={{ firstDate: firstTimestamp, lastDate: lastTimestamp }}
          graphConfigExtension={graphConfigExtension}
          label={'surface'}
        >
          <GraphWaterInfoWrapper>
            <GraphWaterInfoTitleWrapper>
              <span>{waterbodyLabel}</span>
              <span>{coordinateString}</span>
              <span>Surface area [ha]</span>
            </GraphWaterInfoTitleWrapper>
            <GraphWeatherControls {...{ reportError }} />
          </GraphWaterInfoWrapper>
        </Graph>
      )}
    </>
  );
};
