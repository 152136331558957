import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLayerOrderStore } from 'components';

export const usePrepareSortedLayersList = () => {
  const setLayersOrderTable = useLayerOrderStore.use.setLayersOrderTable();

  const sectionsOrderRecord = useLayerOrderStore.use.sectionsOrderRecord();
  const nestedLayersOrderRecord = useLayerOrderStore.use.nestedLayersOrderRecord();

  const { pathname } = useLocation();

  useEffect(() => {
    const order: [string, number][] = [];

    const sortedNestedLayersOrderRecord: [string, number[]][] | null =
      sectionsOrderRecord && nestedLayersOrderRecord
        ? sectionsOrderRecord[pathname]?.map((section) => [section, nestedLayersOrderRecord[pathname]?.[section]])
        : null;

    sortedNestedLayersOrderRecord &&
      sortedNestedLayersOrderRecord.forEach(([key, array]) => {
        array?.forEach((id) => order.push([key, id]));
      });

    setLayersOrderTable(order);
  }, [nestedLayersOrderRecord, pathname, setLayersOrderTable, sectionsOrderRecord]);
};
