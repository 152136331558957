import { AppLayerManager } from 'components/layerManager/legacy';
import { ColorScaleBadge } from 'ui/colorScale';
import { WATER_AREA_COLORMAP, WATER_AREA_COMPARISON_COLORMAP, WATER_AREA_REPORT_COLORMAP } from 'domain/water/colors';
import { useWaterAreaLayerManagerSpecialContent } from './waterAreaLayerManagerSpecialContent';

interface WaterQualityLayerManagerProps {
  isComparisonActive: boolean;
  isReportLayerManager: boolean;
}

export const WaterAreaLayerManager = ({ isComparisonActive, isReportLayerManager }: WaterQualityLayerManagerProps) => {
  const { WaterAreaLayerManagerSpecialContent } = useWaterAreaLayerManagerSpecialContent();

  const colorScaleColormap = isComparisonActive
    ? WATER_AREA_COMPARISON_COLORMAP
    : isReportLayerManager
    ? WATER_AREA_REPORT_COLORMAP
    : WATER_AREA_COLORMAP;

  const colorScaleLabel = isComparisonActive ? 'water change' : 'water surface';

  return (
    <AppLayerManager
      mainLayerLabel={
        <ColorScaleBadge colormap={colorScaleColormap} label={colorScaleLabel} segmented={isComparisonActive} />
      }
      // TODO: remove when mercedes data will be handled
    >
      {<WaterAreaLayerManagerSpecialContent />}
      {/* // TODO */}
    </AppLayerManager>
  );
};
