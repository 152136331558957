import { useAoiNumber } from 'common/navigation/hooks';
import { type ExtendedIndicator } from 'common/types';
import { LayerManagerLegend } from 'components/layerManager/legacy';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useVegetationOtherProviderResults } from '../useVegetationOtherProviderResults';
import { useGreeneryLayersVisibility } from '../../useGreeneryLayersVisibility';

interface Props {
  timestamp: string;
  indicator: ExtendedIndicator;
}

export const VegetationOtherProvidersLayerManagerContent = ({ timestamp, indicator }: Props) => {
  const areaId = useAoiNumber();

  const { results } = useVegetationOtherProviderResults(timestamp, indicator.id);

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();
  const { classesVisibility, updateClassesVisibility, toggleMultipleQueries } = useGreeneryLayersVisibility();

  const options = greeneryObjects.filter(({ id }) => results.some(({ class_id }) => class_id === id));

  return (
    <LayerManagerLegend
      areaId={areaId}
      options={options}
      objectsVisibility={classesVisibility}
      toggleLayerVisibility={toggleMultipleQueries}
      updateLayersVisibility={updateClassesVisibility}
      noBadges
    />
  );
};
