export enum TokenType {
  ACCESS = 'access_token',
  REFRESH = 'refresh_token',
}

export enum InputElementError {
  NO_EMAIL = 'Please enter an email',
  INVALID_EMAIL = 'Please enter correct email',
  NO_PASSWORD = 'Please enter a password',
  NO_CAPTCHA = 'Please verify you are not a robot',
  CONSENT = 'Please accept the Privacy Policy and Terms and Conditions',
}

export enum ResetPasswordRequestStatusMessages {
  IDLE = 'If you forgot your password we recommend making a new one. Please enter email used with your account and we will send you a special link.',
  SUCCESS = 'We sent an email to:',
  ERROR = 'Oops! We have a problem sending you email. Please try again later.',
  OFFLINE = 'Oops! You are offline. Please check your internet connection.',
}
