import { useQueryParameter, useQueryMultipleToggle } from 'common/navigation/hooks';
import { FILTERS_TYPE_QUERY_PARAM_NAME } from 'common/navigation/queryParams';
import { type AreaType } from 'common/navigation/types';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { type TagData } from 'ui/molecules/tagList/TagList';
import { FILTERS_AREA_TYPE_SET } from 'domain/areas/configs';
import { isAreaTypeValid } from 'domain/areas/typeguards';

export const useGetAreasTypeSet = (): TagData[] => {
  const { areasList } = areasRepository.useFetchAreasOfInterestList();

  const areaTypeQuery = useQueryParameter(FILTERS_TYPE_QUERY_PARAM_NAME);
  const { toggleMultipleQueries } = useQueryMultipleToggle(FILTERS_TYPE_QUERY_PARAM_NAME);

  const areaTypes = areasList.map(({ type }) => type).filter((type): type is AreaType => isAreaTypeValid(type));

  const uniqueAreaTypes = Array.from(new Set(areaTypes));

  const areaTypeSet = uniqueAreaTypes.map((type) => {
    const { id, variant, text } = FILTERS_AREA_TYPE_SET[type];

    return {
      id,
      variant,
      text,
      checked: !areaTypeQuery?.includes(type),
      onClick: () => toggleMultipleQueries(type),
    };
  });

  return areaTypeSet;
};
