import { type ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from 'ui/atoms/tooltip';

interface NavigationItemProps {
  label_name: string;
  icon: ReactNode;
  iconSize: number;
  isNarrowed?: boolean;
  active?: boolean;
  alert?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  disabledStatusText?: string;
  betaStatus?: boolean;
}

export const NavigationItem = ({
  label_name,
  icon,
  iconSize,
  isNarrowed,
  active,
  alert,
  onClick,
  disabled,
  disabledStatusText,
  betaStatus,
}: NavigationItemProps) => {
  const NavigationItemContent = (
    <NavItem
      $active={active}
      $disabled={disabled}
      role="link"
      aria-label={`goto ${label_name || 'generic navigation item'}`}
      onClick={!disabled ? onClick : undefined}
    >
      {alert && <AlertDot />}
      <NavIconWrapper $active={active} $iconSize={iconSize} $disabled={disabled}>
        {icon}
      </NavIconWrapper>
      <LabelWrapper isNarrowed={isNarrowed}>
        {label_name}
        {betaStatus && <BetaStatusSuperfix>beta</BetaStatusSuperfix>}
      </LabelWrapper>
    </NavItem>
  );

  return (
    <>
      {disabledStatusText && disabled ? (
        <Tooltip content={disabledStatusText} placement="top">
          {NavigationItemContent}
        </Tooltip>
      ) : (
        NavigationItemContent
      )}
    </>
  );
};

const NavItem = styled.div<{
  $active?: boolean;
  $disabled?: boolean;
}>`
  background-color: ${({ theme }) => theme.color.mineShaftDark};
  color: ${({ theme, $disabled }) => ($disabled ? theme.color.midGray : theme.color.white)};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  &:hover {
    background-color: ${({ theme, $disabled }) => !$disabled && theme.color.selectGray};
  }

  ${({ $active, $disabled }) =>
    $active &&
    !$disabled &&
    css`
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.primary};
      font-weight: 600;
      pointer-events: none;
    `};
`;

const NavIconWrapper = styled.div.attrs(
  ({ $active, $disabled }: { $active?: boolean; $disabled?: boolean; $iconSize?: number }) => ({
    contentColor: () => {
      switch (true) {
        case $disabled:
          return 'midGray';
        case $active:
          return 'white';
        default:
          return 'lightGray';
      }
    },
  }),
)<{ contentColor: string; $iconSize?: number; $active?: boolean; $disabled?: boolean }>`
  color: ${({ contentColor, theme }) => theme.color[contentColor()]};
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  padding: ${({ $iconSize }) => ($iconSize ? (50 - $iconSize) / 2 : 0)}px;
  z-index: 1;
`;

const LabelWrapper = styled.div<{ isNarrowed?: boolean }>`
  margin-left: ${({ isNarrowed }) => (isNarrowed ? '-250px' : '0')};
  transition: all ${({ theme }) => theme.transitionFunction.main};
`;

const AlertDot = styled.div`
  position: relative;
  top: 0;
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: -4px;
  background-color: ${({ theme }) => theme.color.red};
`;

const BetaStatusSuperfix = styled.sup`
  font-size: 7px;
  text-transform: uppercase;
  margin-left: 4px;
`;
