import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { type UrlWithProps, type UrlWithNameAndLabel } from 'services/titiler/types';
import { isPointInfoArrayValid } from './typeguards';

export const titilerApiService = {
  getInfoGeneric: async (url: string) => {
    const geotiffInfoUrl = titilerApiAdapter.buildGenericGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();

    return await geotiffInfo;
  },

  getRichInfo: async (url: string, id: number) => {
    const geotiffInfoUrl = titilerApiAdapter.buildGenericGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();
    const info = await geotiffInfo;

    return { geotiffInfo: info, id: id };
  },

  getExternalInfo: async (url: string) => {
    const geotiffInfoUrl = titilerApiAdapter.buildExternalGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();

    return await geotiffInfo;
  },

  getStatsGeneric: async (
    url: string,
    props: { expression?: string; range?: number[]; bins?: number; bidx?: number },
  ) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGeorasterStatisticsUrl(url, props);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();

    return await geotiffStats;
  },

  useGetCogWithInfo: async <T>(
    cog: T & { url: string },
  ): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    info: any;
    cog: T;
  }> => {
    const geotiffInfoUrl = titilerApiAdapter.buildGenericGeorasterInfoUrl(cog.url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = response.json();
    const info = await geotiffInfo;

    return { info, cog };
  },

  getPointInfoGeneric: async (url: string, lat: number, lng: number, layer?: number) => {
    const pointInfoUrl = titilerApiAdapter.buildGenericGeorasterPointInfoUrl(url, lat, lng, layer);
    const response = await fetch(pointInfoUrl);
    const pointInfo = response.json();

    return await pointInfo;
  },

  getPointsArrayInfo: async (urlsWithProps: UrlWithProps[], lat: number, lng: number, layer?: number) => {
    const requests = urlsWithProps.map(async ({ url, ...rest }) => ({
      response: await fetch(titilerApiAdapter.buildGenericGeorasterPointInfoUrl(url, lat, lng, layer)),
      ...rest,
    }));
    const responses = await Promise.all(requests);
    const resolvedResponses = responses.filter(({ response }) => response.ok);
    const json = resolvedResponses.map(async ({ response, ...rest }) => ({ info: await response.json(), ...rest }));
    const data = Promise.all(json);

    return await data;
  },

  getPointInfoArray: async <T>(urlsWithProps: (T & { url: string })[], lng: number, lat: number, band = 1) => {
    const requests = urlsWithProps.map(async (item) => ({
      response: await fetch(titilerApiAdapter.buildGenericSwappedCogPointUrl(`/vsiaz${item.url}`, lng, lat, band)),
      ...item,
    }));
    const responses = await Promise.all(requests);
    const resolvedResponses = responses.filter(({ response }) => response.ok);
    const json = resolvedResponses.map(async ({ response, ...rest }) => ({ info: await response.json(), ...rest }));
    const data = await Promise.all(json);

    const pointInfoArray = data && isPointInfoArrayValid(data) ? data : [];

    return pointInfoArray;
  },

  getPointsArrayInfoWithLabel: async (
    urlsWithNameAndLabel: UrlWithNameAndLabel[],
    lat: number,
    lng: number,
    layer?: number,
  ) => {
    const requests = urlsWithNameAndLabel.map(async ({ url, ...rest }) => ({
      response: await fetch(titilerApiAdapter.buildGenericGeorasterPointInfoUrl(url, lat, lng, layer)),
      ...rest,
    }));
    const responses = await Promise.all(requests);
    const resolvedResponses = responses.filter(({ response }) => response.ok);
    const json = resolvedResponses.map(async ({ response, ...rest }) => ({ info: await response.json(), ...rest }));
    const data = Promise.all(json);

    return await data;
  },
};
