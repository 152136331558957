import { type LayerObject } from 'common/types/mapData';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { Container } from 'ui/atoms/container';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { LayerManagerLegend } from 'components/layerManager/legacy';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { useAvailableLayers } from 'views/greenery/segmentation/useAvailableLayers';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { mapGreeneryObjectsReportColorHelper } from 'domain/greenery/helpers';

interface GreeneryLayerManagerLegendProps {
  areaId: number;
  layerObjects: LayerObject[];
  viewSpecificDisabledLayers?: string[];
  cogUrl: string;
  noBadges?: boolean;
}

export const GreeneryLayerManagerLegend = ({
  areaId,
  layerObjects,
  viewSpecificDisabledLayers = [],
  cogUrl,
  noBadges,
}: GreeneryLayerManagerLegendProps) => {
  const areReportsActive = useGetAreReportsActiveUseCase();

  const { isStatsLoading } = titilerInfoService.useGetStatsGeneric(getAzureBlobStorageUrl(cogUrl));

  const mappedGreeneryObjects = areReportsActive ? mapGreeneryObjectsReportColorHelper(layerObjects) : layerObjects;

  const { availableLayers } = useAvailableLayers(cogUrl, layerObjects);

  const legendOptions: LayerObject[] = mappedGreeneryObjects
    ? mappedGreeneryObjects
        .filter(({ name }) => availableLayers?.includes(name))
        .filter(({ name }) => !viewSpecificDisabledLayers.includes(name))
    : [];

  const { classesVisibility, updateClassesVisibility, toggleMultipleQueries } = useGreeneryLayersVisibility();

  return (
    <>
      {isStatsLoading ? (
        <Container mt={10}>
          <LoadingDots />
        </Container>
      ) : (
        <LayerManagerLegend
          areaId={areaId}
          options={legendOptions}
          objectsVisibility={classesVisibility}
          toggleLayerVisibility={toggleMultipleQueries}
          updateLayersVisibility={updateClassesVisibility}
          noBadges={noBadges}
        />
      )}
    </>
  );
};
