import { useEffect, useState } from 'react';

import { Container } from 'ui/atoms/container';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { LayerManagerLegend } from 'components/layerManager/legacy/Legend';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { type TitilerStats } from 'services/titiler/types';
import { GREENERY_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useQueryParameter } from 'common/navigation/hooks';
import { type LayerObject } from 'common/types/mapData';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { type HotspotType } from 'domain/hotspots/types';
import { useGreeneryLayersVisibility } from '../../useGreeneryLayersVisibility';

interface Props {
  areaId: number;
  hotspotTypes: HotspotType[];
  cogUrl: string;
  isLoading: boolean;
}

export const VegetationHotspotsLayerManagerLegend = ({ areaId, hotspotTypes, cogUrl, isLoading }: Props) => {
  const indicatorQuery = useQueryParameter(GREENERY_INDICATOR_QUERY_PARAMETER);

  const [availableLayers, setAvailableLayers] = useState<LayerObject[] | undefined>(undefined);

  const { stats, isStatsLoading } = titilerInfoService.useGetStatsGeneric(`${getAzureBlobStorageUrl(`/${cogUrl}`)}`);

  const getFilteredObjectsFromStats = (stats: TitilerStats | undefined, objects: LayerObject[]) => {
    if (!stats) {
      setAvailableLayers(undefined);
      return;
    }

    setAvailableLayers(undefined);
    const getActiveClassesNumbersFromCog = (stats: TitilerStats) => {
      return Object.entries(stats)
        .filter((item) => item[1].mean > 0)
        .map((item) => item[0].slice(1));
    };

    const filterActiveObjects = (objects: LayerObject[], activeClassesNumbers: string[]) => {
      return objects.filter(({ id }) => activeClassesNumbers.includes(id.toString()));
    };

    const filteredObjects = filterActiveObjects(objects, getActiveClassesNumbersFromCog(stats));

    return filteredObjects;
  };

  useEffect(() => {
    const filteredObjects = getFilteredObjectsFromStats(
      stats,
      hotspotTypes.map((type) => ({ ...type, status: 'active' })),
    );
    const filteredObjectNames = filteredObjects;

    setAvailableLayers(filteredObjectNames);
  }, [stats, indicatorQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const { classesVisibility, toggleMultipleQueries, updateClassesVisibility } = useGreeneryLayersVisibility();

  useEffect(() => {
    if (
      (Object.keys(classesVisibility).length === 0 ||
        !Object.keys(classesVisibility).some((key) => availableLayers?.map(({ name }) => name).includes(key))) &&
      availableLayers
    ) {
      updateClassesVisibility({ [availableLayers[0].name]: true });
    }
  }, [availableLayers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {availableLayers ? (
        isLoading || isStatsLoading ? (
          <Container mt={10}>
            <LoadingDots />
          </Container>
        ) : (
          <LayerManagerLegend
            areaId={areaId}
            options={availableLayers}
            objectsVisibility={classesVisibility}
            toggleLayerVisibility={toggleMultipleQueries}
            updateLayersVisibility={updateClassesVisibility}
          />
        )
      ) : null}
    </>
  );
};
