import { FoldableSection, FoldableSectionCheckbox } from 'components/layerManager';
import { useInitializeLayersOrderRecord } from 'components/layerManager/draggableLayerManager/useInitializeLayersOrderRecord';
import { COMPOSITES_INFO_POINT_DESCRIPTION } from 'domain/exploration/configs';
import { CompositesProvidersListBeta } from './CompositesProvidersListBeta';
import { useCompositesStore } from '../compositesStore';
import { useCompositesBeta } from '../useCompositesBeta';
import { EXPLORATION_LAYERS_KEYS } from '../../explorationDraggableLayersKeys';

interface Props {
  id: string;
}

export const CompositesLayerManagerBeta = ({ id }: Props) => {
  const areCompositesBetaVisible = useCompositesStore.use.areCompositesBetaVisible();
  const toggleCompositesBetaVisibility = useCompositesStore.use.toggleCompositesBetaVisibility();

  const { providersWithOptions } = useCompositesBeta();

  useInitializeLayersOrderRecord(EXPLORATION_LAYERS_KEYS.COMPOSITES_BETA);

  // TODO: remove below when feature no longer Beta
  if (!providersWithOptions.length) {
    return null;
  }

  return (
    <FoldableSection
      id={id}
      headerContent={
        <FoldableSectionCheckbox
          variant="NORMAL"
          title="false color composites"
          description={COMPOSITES_INFO_POINT_DESCRIPTION} // TODO: use description from useFoldableSectionStatus hook when feature no longer Beta
          isCheckboxChecked={areCompositesBetaVisible}
          onCheckboxToggle={toggleCompositesBetaVisibility}
        />
      }
    >
      <CompositesProvidersListBeta {...{ providersWithOptions }} />
    </FoldableSection>
  );
};
