import { type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import MDEditor from '@uiw/react-md-editor';

import { Container, InfoPoint } from 'ui';

interface ContentFoldableProps {
  label: string;
  labelSup?: string;
  small?: boolean;
  selected?: boolean;
  children?: ReactNode;
}

export const ContentFoldable = ({ label, labelSup, small = false, selected, children }: ContentFoldableProps) => (
  <>
    <Label small={small}>
      {label}
      {labelSup && <LabelSup>{labelSup}</LabelSup>}
    </Label>
    {selected && children && <Container mt={10}>{children}</Container>}
  </>
);

interface ContentDescriptiveProps {
  label: string;
  labelSup?: string;
  small?: boolean;
  children?: ReactNode;
}

export const ContentDescriptive = ({ label, labelSup, small = false, children }: ContentDescriptiveProps) => (
  <>
    <Label small={small}>
      {label}
      {labelSup && <LabelSup>{labelSup}</LabelSup>}
    </Label>
    {children && <Container mt={10}>{children}</Container>}
  </>
);

interface ContentInteractiveProps {
  label: string;
  annotation?: string;
  warning: boolean;
  topInfo?: string;
  info?: string;
  small?: boolean;
  children?: ReactNode;
  selected?: boolean;
}

export const ContentInteractive = ({
  label,
  annotation,
  warning,
  topInfo,
  info,
  small = false,
  children,
  selected,
}: ContentInteractiveProps) => (
  <>
    <Label stretched small={small}>
      <Container variant="CENTERING">
        {topInfo && <TopInfo>{topInfo}</TopInfo>}
        {label}
      </Container>
      {info && (
        <InfoPoint
          name={label}
          tooltipContent={<StyledMarkdownEditor source={info} />}
          color={selected ? 'mineShaft' : 'selectGray'}
        />
      )}
    </Label>
    <Container mt={10}>{warning ? <WarningAnnotation>{annotation}</WarningAnnotation> : annotation}</Container>
    {children && <Container mt={10}>{children}</Container>}
  </>
);

const Label = styled.div<{ small: boolean; stretched?: boolean }>`
  font-size: ${({ small }) => (small ? 14 : 18)}px;
  font-weight: ${({ small }) => (small ? 600 : 700)};
  text-transform: capitalize;

  ${({ stretched }) =>
    stretched &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
`;

const TopInfo = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
`;

const LabelSup = styled.sup`
  font-size: 9px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-left: 4px;
`;

const WarningAnnotation = styled.span`
  color: ${({ theme }) => theme.color.errorPink};
`;

const StyledMarkdownEditor = styled(MDEditor.Markdown)`
  background-color: transparent;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-transform: none;

  ul {
    padding: 0;
    list-style-position: inside;
  }
`;
