import { type ReactNode } from 'react';
import styled from 'styled-components';

import { Container } from 'ui';
import { LoadingDots } from 'ui/atoms/loadingDots';
import { ModalHeader } from 'ui/modal';
import { ShareModalBody, useShareModalStore } from 'components/downloadModal/legacyShareModal';
import { NoSubscriptionModalContent } from 'views/subscriptions/NoSubscriptionModalContent';
import { useGetActiveSubscription } from 'views/subscriptions/useGetActiveSubscription';

interface Props {
  children: ReactNode;
  maxWidth?: number;
  title?: string;
}

export const DownloadModal = ({ children, maxWidth = 500, title = 'Download Data Layers' }: Props) => {
  const { activeSubscriptionData, isActiveSubscriptionLoading } = useGetActiveSubscription();

  const closeModal = useShareModalStore.use.closeShareModal();

  return (
    <ShareModalBody maxWidth={maxWidth}>
      <ModalWrapper>
        <ModalHeader>{title}</ModalHeader>
        <Container mt={40}>
          {isActiveSubscriptionLoading ? (
            <Container gap={5}>
              Subscription checking <LoadingDots />
            </Container>
          ) : (
            <Container variant="VERTICAL_START" gap={35}>
              {!activeSubscriptionData || activeSubscriptionData.status === 'trialing' ? (
                <NoSubscriptionModalContent
                  message="To unlock this function you need a subscription plan and you can't be on a trial period."
                  handleClose={closeModal}
                />
              ) : (
                children
              )}
            </Container>
          )}
        </Container>
      </ModalWrapper>
    </ShareModalBody>
  );
};

const ModalWrapper = styled.div`
  min-width: 240px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
`;
