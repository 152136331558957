import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { getDatesFromDygraphStringValue } from 'domain/displacements/helpers/parserGeoJsonValue';
import { type GeoJSONCollection } from 'domain/displacements/types';

export const useUpdateDisplacementsTimestamps = () => {
  const setTimestamps = useDisplacementsStore.use.setTimestamps();

  const updateDisplacementTimeStamps = (geoData: GeoJSONCollection) => {
    let dates = null;

    for (const key in geoData) {
      if (Object.prototype.hasOwnProperty.call(geoData, key)) {
        const zoomLevel = geoData[key as keyof GeoJSONCollection];
        for (const collection of zoomLevel) {
          for (const feature of collection.features) {
            const value = feature?.properties?.description?.value;
            if (value || !dates || !dates.length) {
              dates = getDatesFromDygraphStringValue(value);
              break;
            }
          }
        }
      }
    }

    if (dates) {
      setTimestamps(dates);
    }
  };

  return { updateDisplacementTimeStamps };
};
