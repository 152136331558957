import { type LayerObject } from 'common/types/mapData';
import { useWithVisibleLayersOnly, useWithProperLayerColor } from 'domain/extraction/decorators';
import { SEGMENTATION_OBJECTS_OPTIONS } from 'domain/extraction/configs';

export const useExtractionObjectTypesService = () => {
  const filteredLayers: LayerObject[] = useWithVisibleLayersOnly(SEGMENTATION_OBJECTS_OPTIONS);

  const segmentationLegendOptions: LayerObject[] = useWithProperLayerColor(filteredLayers);

  return { segmentationLegendOptions };
};
