import { useEffect } from 'react';

import { type ValueAndLabel } from 'common/types';
import { WATERBODY_QUERY_PARAMETER, WATER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useQueryParameter } from 'common/navigation/hooks';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useGraphStore } from 'components/graph/graphStore';
import { useWaterbodies } from '../waterbodies';

export const useWaterPointHistory = () => {
  const waterbodyId = useQueryParameter(WATERBODY_QUERY_PARAMETER);
  const waterIndicator = useQueryParameter(WATER_INDICATOR_QUERY_PARAMETER);

  const { changeWaterbody } = useWaterbodies();

  const onWaterBodySelect = (waterbodyOption: ValueAndLabel) => {
    changeWaterbody(waterbodyOption);
    return waterbodyOption;
  };

  const selectedPoint = useGraphStore.use.chosenPoint();
  const handleDataPointSelect = useGraphStore.use.setChosenPoint();

  const setPointHistory = useGraphStore.use.setGraphData();
  const resetPointHistory = useGraphStore.use.resetGraphData();

  const { fetchWaterPointHistory, isHistoryLoading } = waterRepository.useFetchWaterPointHistory();

  const getHistoryForChosenPoint = async (lat: number, lng: number, value: number, bodyId: number) => {
    if (bodyId) {
      const history = await fetchWaterPointHistory(bodyId, Number(waterIndicator), lat, lng);

      if (history) {
        setPointHistory(history);
      } else {
        resetPointHistory();
      }
    }
    handleDataPointSelect({ lat, lng, value });
  };

  const handleWaterBodyPointClick = (
    latitude: number,
    longitude: number,
    value: number,
    currentWaterbody: ValueAndLabel,
  ) => {
    if (!waterbodyId) {
      const waterbodyOption = onWaterBodySelect(currentWaterbody);
      getHistoryForChosenPoint(latitude, longitude, value, +waterbodyOption.value);
    } else {
      getHistoryForChosenPoint(latitude, longitude, value, +waterbodyId);
    }
  };

  useEffect(() => {
    if (waterbodyId && selectedPoint) {
      getHistoryForChosenPoint(selectedPoint.lat, selectedPoint.lng, selectedPoint.value, +waterbodyId);
    }
  }, [waterIndicator]); // eslint-disable-line react-hooks/exhaustive-deps

  return { handleWaterBodyPointClick, isHistoryLoading };
};
