import { DataLayer } from 'components/map';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { Loading } from 'ui/molecules/loading';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import {
  GREENERY_COMPARATOR_GAIN_COLOR,
  GREENERY_COMPARATOR_LOSS_COLOR,
  GREENERY_COMPARATOR_NO_CHANGE_COLOR,
} from 'domain/greenery/colors';

interface QuarterlyComparatorDataLayerProps {
  quarterlyComparatorCogUrl: string;
  isDataLayerVisible: boolean;
  currentObjectId: number;
}

export const QuarterlyComparatorDataLayer = ({
  quarterlyComparatorCogUrl,
  isDataLayerVisible,
  currentObjectId,
}: QuarterlyComparatorDataLayerProps) => {
  const colormap = `{"${currentObjectId}": "${GREENERY_COMPARATOR_NO_CHANGE_COLOR}"}`;
  const expression = `${currentObjectId}*b${currentObjectId}`;

  const {
    cogUrl: comparatorMaskUrl,
    extentLatLngBounds: comparatorMaskBounds,
    isInfoLoading: isComparatorMaskLoading,
  } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildGenericMonocolorCogUrl,
    getAzureBlobStorageUrl(quarterlyComparatorCogUrl),
    {
      colormap,
      expression,
    },
  );

  const {
    cogUrl: comparatorCogUrl,
    extentLatLngBounds: comparatorCogBounds,
    isInfoLoading,
  } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildComparatorImageUrl,
    getAzureBlobStorageUrl(quarterlyComparatorCogUrl),
    {
      currentLayerId: currentObjectId,
      gainColor: GREENERY_COMPARATOR_GAIN_COLOR,
      lossColor: GREENERY_COMPARATOR_LOSS_COLOR,
      noChangeColor: GREENERY_COMPARATOR_NO_CHANGE_COLOR,
    },
  );

  if (isComparatorMaskLoading || isInfoLoading) {
    return <Loading />;
  }

  return (
    <>
      <DataLayer
        isVisible={isDataLayerVisible}
        layerName={'quarterlyComparatorBaseLayer'}
        zIndex={490}
        cogUrl={comparatorMaskUrl}
        bounds={comparatorMaskBounds}
      />
      <DataLayer
        isVisible={isDataLayerVisible}
        layerName={'quarterlyComparatorLayer'}
        zIndex={495}
        cogUrl={comparatorCogUrl}
        bounds={comparatorCogBounds}
      />
    </>
  );
};
