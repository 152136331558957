import { useEffect, useState, type ChangeEvent } from 'react';
import styled from 'styled-components';

import { type ValueAndLabel } from 'common/types';
import { type Datetime } from 'common/types/time';
import { mapTimeframesToOptions, timestampToOption } from 'components/Timeline/helpers';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { NewAreaNameTile } from 'ui/creator/newAreaNameTile';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';

export const timeframes = [
  { start: '2024-07-01T00:00:00Z', end: '2024-09-30T00:00:00Z' },
  { start: '2024-03-31T00:00:00Z', end: '2024-06-30T00:00:00Z' },
  { start: '2023-12-31T00:00:00Z', end: '2024-03-30T00:00:00Z' },
  { start: '2023-10-01T00:00:00Z', end: '2023-12-30T00:00:00Z' },
  { start: '2023-07-01T00:00:00Z', end: '2023-09-30T00:00:00Z' },
  { start: '2023-03-31T00:00:00Z', end: '2023-06-30T00:00:00Z' },
  { start: '2022-12-31T00:00:00Z', end: '2023-03-30T00:00:00Z' },
];

interface PropertiesColumnProps {
  setPropertiesColumnValidity: (valid: boolean) => void;
}

export const PropertiesColumn = ({ setPropertiesColumnValidity }: PropertiesColumnProps) => {
  const areaName = useAreaRequestStore.use.areaName();
  const setAreaName = useAreaRequestStore.use.setAreaName();
  const isAreaNameProvided = !!areaName;

  const requestedDate = useAreaRequestStore.use.requestedQuarterEnd();
  const setRequestedDate = useAreaRequestStore.use.setRequestedQuarterEnd();
  const currentOption = requestedDate ? timestampToOption(requestedDate) : undefined;

  const [error, setError] = useState<string | undefined>(undefined);
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);

  const { areasList } = areasRepository.useFetchAreasOfInterestList();
  const areasNames = areasList.map((area) => area.name.toLowerCase());
  const isNameProvided = !!areaName;
  const nameOccupied = areasNames.includes(areaName.toLowerCase().trim());

  useEffect(() => {
    if (nameOccupied) {
      setError('Name is already taken.');
    } else if (requestedDate && !isNameProvided) {
      setError('Provide a name for your area.');
    } else {
      setError(undefined);
    }
  }, [isNameProvided, nameOccupied, requestedDate]);

  useEffect(() => {
    if (isAreaNameProvided && !error) {
      setDropdownActive(true);
    }
  }, [isAreaNameProvided, error]);

  useEffect(() => {
    setPropertiesColumnValidity(!!requestedDate && isAreaNameProvided && !error);
  }, [isAreaNameProvided, error, requestedDate, setPropertiesColumnValidity]);

  const handleSetAreaName = (event: ChangeEvent<HTMLInputElement>) => {
    const newAreaName = event.target.value;
    setAreaName(newAreaName);
  };

  const handleSetRequestedDate = (option: ValueAndLabel) => {
    const newRequestedDateOption = option.value as unknown;
    setRequestedDate(newRequestedDateOption as Datetime);
  };

  return (
    <NewAreaNameTile
      areaName={areaName}
      header="Name new prospecting area"
      setAreaName={handleSetAreaName}
      error={error}
      selectOptions={mapTimeframesToOptions(timeframes)}
      selectedOption={currentOption}
      onOptionChange={handleSetRequestedDate}
      tooltipContent={
        <TooltipContentWrapper>
          Taking seasonality into account, please select the quarter when you expect the most bare earth exposed in the
          area to maximize mineral mapping possibilities.
        </TooltipContentWrapper>
      }
      initialStatePrompt="Select quarter"
      selectMinWidth={140}
      isDropdownDisabled={!dropdownActive}
    />
  );
};

const TooltipContentWrapper = styled.div`
  max-width: 200px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
