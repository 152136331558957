import {
  useHandleQueryParameterByPrimitiveValue,
  useQueryMultipleToggle,
  useReplaceQueryParameters,
} from 'common/navigation/hooks';
import { type LayersVisibilityDictionary } from 'common/types';

const IDLE_CLASS = 'idle';

export const useQueryParamsClasses = (queryKey: string) => {
  const [classesQuery, changeClassQuery] = useHandleQueryParameterByPrimitiveValue(queryKey);
  const replaceClassQuery = useReplaceQueryParameters();

  const { toggleMultipleQueries } = useQueryMultipleToggle(queryKey, IDLE_CLASS);

  const classes = classesQuery ? classesQuery.split('-') : [];
  const classesVisibility: LayersVisibilityDictionary = Object.fromEntries(classes.map((c) => [c, true]));

  const updateClassesVisibility = (classesVisibility: LayersVisibilityDictionary) => {
    const queryString = Object.keys(classesVisibility)
      .filter((visibilityKey) => !!classesVisibility[visibilityKey])
      .join('-');

    changeClassQuery(queryString ? queryString : IDLE_CLASS);
  };

  const clearClassesVisibility = () => {
    replaceClassQuery([{ key: queryKey, value: '' }]);
  };

  return {
    classesQuery,
    classes,
    classesVisibility,
    changeClassQuery,
    updateClassesVisibility,
    toggleMultipleQueries,
    clearClassesVisibility,
  };
};
