import { useNavigate } from 'react-router-dom';

import { useDeleteQueryParameters, useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { ROUTES } from 'common/navigation/routes';
import { type SummaryVariant } from 'domain/areas/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isQueryOfSummaryVariantType = (key: any): key is SummaryVariant => {
  return ['true', 'request', 'custom'].includes(key);
};

export const useSummaryPopupState = () => {
  const navigate = useNavigate();

  const summaryType = useQueryParameter('summary');
  const isSummaryVisible = isQueryOfSummaryVariantType(summaryType);

  const replaceQueryParameters = useReplaceQueryParameters();
  const showSummary = (variant: SummaryVariant) => replaceQueryParameters([{ key: 'summary', value: variant }]);
  const deleteQueryParameters = useDeleteQueryParameters();
  const hideSummary = () => deleteQueryParameters(['summary']);

  const navigateToList = () => {
    hideSummary();
    navigate(ROUTES.areasList);
  };

  return { isSummaryVisible, summaryType, showSummary, hideSummary, navigateToList };
};
