import { useLocation } from 'react-router-dom';

import { ReactComponent as MineralizationIcon } from 'assets/svg/mineralization-icon.svg';
import { useBuildNavigationItem } from 'views/areas/areaDetails/useBuildNavigationItem';
import { AreaEnvironments } from 'common/navigation/constants';
import { EXPLORATION_VIEW_CONFIG } from 'domain/exploration/configs';
import { ExplorationLayerManager } from './ExplorationLayerManager';

export const useBuildExplorationNavItem = () => {
  const { pathname } = useLocation();

  const isMineralsView = pathname.includes('minerals');

  return useBuildNavigationItem({
    config: EXPLORATION_VIEW_CONFIG,
    label: 'mineralization',
    icon: <MineralizationIcon />,
    iconSize: 26,
    type: AreaEnvironments.MINERALIZATION,
    betaStatus: true,
    children: isMineralsView ? <ExplorationLayerManager /> : null,
  });
};
