import { Fragment } from 'react';

import { areasRepository } from 'infrastructure/areas/areasRepository';
import { Loading } from 'ui/molecules/loading';
import { HillshadeLayer } from './HillshadeLayer';

interface HillshadeMapContentProps {
  areaName: string;
}

export const HillshadeMapContent = ({ areaName }: HillshadeMapContentProps) => {
  const { layersList, layersLoading } = areasRepository.useFetchTopographyLayers(areaName);

  if (!layersList.length) {
    return null;
  }

  return (
    <>
      {layersLoading && <Loading />}
      {layersList.map((layer) => (
        <Fragment key={layer.id}>
          <HillshadeLayer url={layer.hillshadeUrl} />
        </Fragment>
      ))}
    </>
  );
};
