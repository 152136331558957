import styled from 'styled-components';

import { type ColormapDictionary } from 'common/types';
import { Container } from 'ui/atoms/container';
import { ColorScaleBar } from './ColorScaleBar';

interface ColorScaleBadgeProps {
  colormap: ColormapDictionary;
  label: string;
  unit?: string;
  segmented?: boolean;
}

export const ColorScaleBadge = ({ colormap, label, unit, segmented = false }: ColorScaleBadgeProps) => (
  <Container>
    {colormap && (
      <Wrapper data-testid={`${label.replace(' ', '-')}-color-scale`}>
        <ColorScaleBar colormap={colormap} segmented={segmented} />
      </Wrapper>
    )}
    {label}
    {unit && <Unit>{unit}</Unit>}
  </Container>
);

const Wrapper = styled.div`
  margin-right: 10px;
`;

const Unit = styled.span`
  font-size: 10px;
  text-transform: initial;
  margin-left: 4px;
  margin-right: auto;
  vertical-align: middle;
  flex-shrink: 0;
`;
