import { type ExtendedIndicator } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { capitalizeAllFirstLetters } from 'common/utils';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { reportsQueryService } from 'views/reports/reportsQueryService';
import { ReportPdfExtendedContent } from 'views/reports/external/pdfGenerator/extended/ReportPdfExtendedContent';
import { useGetGreeneryIndicatorStatsUseCase } from 'views/greenery/useCases/getGreeneryIndicatorsStatsUseCase';
import { type ReportsInitialDataset, type ExternalReportHeaderCommon } from 'domain/reports/types';
import { REPORTS_INDICATOR_STATS } from 'domain/reports/constants';
import { getExtendedTableData } from 'domain/reports/helpers';
import { isExternalReportExtendedTableDataValid } from 'domain/reports/typeguards';

interface VegetationDoubleDateReportsExternalDataProps {
  areaId: number;
  initialDataset: ReportsInitialDataset;
  selectedIndicator: ExtendedIndicator;
  selectedSegmentationClass: LayerObject;
  reportInfo: ExternalReportHeaderCommon;
}

export const VegetationDoubleDateReportsExternalData = ({
  initialDataset,
  selectedIndicator,
  selectedSegmentationClass,
  reportInfo,
}: VegetationDoubleDateReportsExternalDataProps) => {
  const { firstTimestamp, firstDate, firstScreenshotSrc, secondTimestamp, secondDate, secondScreenshotSrc } =
    initialDataset;

  const { indicatorStats: firstDateIndicatorStats } = useGetGreeneryIndicatorStatsUseCase(firstTimestamp);
  const { indicatorStats: secondDateIndicatorStats } = useGetGreeneryIndicatorStatsUseCase(secondTimestamp);

  const { indicatorStatsQuery } = reportsQueryService.useReportsIndicatorStats();

  const firstDateSelectedIndicatorStats = firstDateIndicatorStats.find(
    ({ greenery_object_id }) => greenery_object_id === selectedSegmentationClass.id,
  );
  const secondDateSelectedIndicatorStats = secondDateIndicatorStats.find(
    ({ greenery_object_id }) => greenery_object_id === selectedSegmentationClass.id,
  );

  const data = REPORTS_INDICATOR_STATS.filter(({ id }) => indicatorStatsQuery?.includes(String(id))).map(
    ({ id, name, label }) => {
      const first = firstDateSelectedIndicatorStats ? firstDateSelectedIndicatorStats[name] : null;
      const second = secondDateSelectedIndicatorStats ? secondDateSelectedIndicatorStats[name] : null;

      const { firstValue, secondValue, difference, trend } = getExtendedTableData(
        first,
        second,
        selectedIndicator.precision,
      );

      return {
        id,
        label: capitalizeAllFirstLetters(label),
        firstValue,
        secondValue,
        difference,
        trend,
      };
    },
  );

  const validData = isExternalReportExtendedTableDataValid(data) ? data : [];

  const selectedIndicatorLabel = selectedIndicator.indicator_name;
  const selectedSegmentationClassLabel = selectedSegmentationClass.label_name;

  const header = {
    ...reportInfo,
    reportType: 'Comparison Report',
    reportFunctionality: `Vegetation: ${selectedSegmentationClassLabel}`,
    reportAdditionalLabel: selectedIndicatorLabel,
    dates: {
      first: firstDate,
      second: secondDate,
    },
  };

  const colorScale = {
    labels: selectedIndicator.labels,
    colormap: selectedIndicator.colormap,
    unit: selectedIndicator.unit,
  };

  const table = {
    tableHeader: `${selectedIndicatorLabel} Statistics For ${selectedSegmentationClassLabel}`,
    dataHeader: 'Parameter Name',
    unit: selectedIndicator.unit,
    firstDate,
    secondDate,
    data: validData,
  };

  const screenshots = {
    first: {
      src: firstScreenshotSrc,
      label: `Date 1: ${firstDate}`,
    },
    second: {
      src: secondScreenshotSrc,
      label: `Date 2: ${secondDate}`,
    },
  };

  return (
    <>
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} colorScale={colorScale} />
          <ReportPdfExtendedContent screenshots={screenshots} table={table} />
        </>
      </ReportPdf>
    </>
  );
};
