import { useParams } from 'react-router-dom';

import { GREENERY_API_URLS } from 'infrastructure/greenery/greeneryApiUrls';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';

export const useBiomass = () => {
  const { areaId } = useParams();
  const { classesVisibility, toggleMultipleQueries } = useGreeneryLayersVisibility();

  const biomassUrl = areaId === '522' ? GREENERY_API_URLS.getBiomassCog : null;

  const isBiomassVisible = classesVisibility['biomass'];
  const isBiomassActive = !!biomassUrl && isBiomassVisible;

  return { biomassUrl, isBiomassActive, isBiomassVisible, toggleBiomassVisibility: toggleMultipleQueries };
};
