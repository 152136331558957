export const MINERAL_DEPOSITS_QUERY_KEY = 'mineral_deposits';
export const MINERALS_TYPES_QUERY_KEY = 'minerals_types';
export const SPECTRAL_CLASSIFIERS_QUERY_KEY = 'spectral_classifiers';
export const MINERALS_RESULTS_QUERY_KEY = 'minerals_results';

export const LINEAMENTS_QUERY_KEY = 'lineaments_shapes';
export const LINEAMENT_SHAPE_QUERY_KEY = 'lineaments_shape';
export const LINEAMENTS_RANGE_RESULTS_QUERY_KEY = 'lineaments_range_results';
export const LINEAMENT_DENSITY_RESULTS_QUERY_KEY = 'lineament_density_results';

export const INDICES_QUERY_KEY = 'indices';
export const INDICES_RESULTS_QUERY_KEY = 'indices_results';
export const INDICATOR_DETAILS_QUERY_KEY = 'indicator_details';
export const SAMPLING_POINTS_QUERY_KEY = 'sampling_points';

export const DEPOSIT_CLASSIFICATION_SITES_QUERY_KEY = 'deposit_classification_sites';

export const CLUSTERS_DATA_SUPPLIERS_QUERY_PARAMETER = 'clusters_data_suppliers';
export const CLUSTERS_RESULTS_QUERY_PARAMETER = 'clusters_results';

export const COMPOSITES_QUERY_KEY = 'composites';

export const EXPLORATION_INITIAL_DATA_STALE_TIME = 1000 * 60 * 60;
export const EXPLORATION_INITIAL_DATA_CACHE_TIME = 1000 * 60 * 75;
