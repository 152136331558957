import styled from 'styled-components';

import { BasemapSelector } from './BasemapSelector';
import { FoldableSectionContentWrapper, IndentedContentWraper } from '../../styled';
import { type LAYERS_KEYS } from '../../draggableLayerManager/draggableLayersKeys';
import { FoldableSection } from '../../foldableSection';

interface SatelliteItemProps {
  id: LAYERS_KEYS;
}

export const BasemapItem = ({ id }: SatelliteItemProps) => {
  return (
    <FoldableSection headerContent={<Header>Basemap</Header>} id={id}>
      <FoldableSectionContentWrapper>
        <IndentedContentWraper>
          <ContentWrapper>
            <BasemapSelector />
          </ContentWrapper>
        </IndentedContentWraper>
      </FoldableSectionContentWrapper>
    </FoldableSection>
  );
};

const Header = styled.div`
  color: ${({ theme }) => theme.color.white};
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  word-break: break-word;
`;

const ContentWrapper = styled.div`
  padding: 8px 0;
`;
