import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT_DOT } from 'common/constants/datetime';
import { formatDateTimeZone } from 'common/utils/datetime';
import { SUBSIDENCE_DESCRIPTION } from 'domain/displacements/constants';
import { useDisplacementsTimeline } from '../timeline/useDisplacementsTimeline';

export function SubsidenceDescription() {
  const { timelineData } = useDisplacementsTimeline();

  return (
    <>
      {SUBSIDENCE_DESCRIPTION}
      {timelineData?.timestamps?.length > 0 && (
        <DescriptionLabel>
          <div>Timeframe:</div>
          {formatDateTimeZone(timelineData.timestamps[0], DEFAULT_DATE_FORMAT_DOT)} -{' '}
          {formatDateTimeZone(timelineData.timestamps[timelineData.timestamps.length - 1], DEFAULT_DATE_FORMAT_DOT)}
        </DescriptionLabel>
      )}
    </>
  );
}

const DescriptionLabel = styled.div`
  margin-top: 10px;
`;
