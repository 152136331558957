import { AxiosError, type AxiosResponse } from 'axios';
import { useEffect } from 'react';

import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { type WarningErrorType } from 'domain/@errors/enums';
import { ErrorsModel } from 'domain/@errors/ErrorsModel';
import { initialWarningErrors } from 'domain/@errors/constants';

export const useHandleWarningErrorUseCase = (
  errorType: WarningErrorType,
  data: AxiosResponse | undefined,
  error: unknown,
  isSuccess: boolean,
  dataType = 'count',
  key?: number,
  allDataQty?: number,
) => {
  const errorKey = key === undefined ? String(errorType) : `${errorType}-${key}`;

  const removeWarningErrorByKey = useErrorsStore.use.removeWarningErrorByKey();
  const updateWarningError = useErrorsStore.use.updateWarningError();

  const updateError = (errorCause?: string, customContent?: string) => {
    const viewPath = initialWarningErrors.find((error) => error.type === errorType)?.viewPath as string;
    updateWarningError({
      [errorKey]: {
        type: errorType,
        viewPath,
        allDataQty,
        errorCause,
        customContent,
      },
    });
  };

  useEffect(() => {
    if (error instanceof AxiosError) {
      const errorCause = ErrorsModel.getErrorCause(String(error.response?.status));
      updateError(errorCause);
      return;
    }

    if (isSuccess && !data?.data[dataType]) {
      const emptyResponseInfo = initialWarningErrors.find((error) => error.type === errorType)?.emptyResponseInfo;
      updateError(undefined, emptyResponseInfo);
      return;
    }

    removeWarningErrorByKey(errorKey as WarningErrorType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data, error]);
};
