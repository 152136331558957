import { type ComponentProps } from 'react';
import styled from 'styled-components';

import { BaseModal, ModalButtons } from 'ui/modal';

interface ConfirmationModalProps extends Omit<ComponentProps<typeof BaseModal>, 'maxWidth' | 'padding' | 'variant'> {
  consentAction: () => void;
  cancelAction: () => void;
  consentText?: string;
  cancelText?: string;
}

export const ConfirmationModal = ({
  isOpen,
  closeModal,
  children,
  consentAction,
  cancelAction,
  consentText,
  cancelText,
}: ConfirmationModalProps) => {
  return (
    <BaseModal {...{ isOpen, closeModal }} maxWidth={354}>
      <ModalContentHeader>{children}</ModalContentHeader>
      <ModalButtons {...{ consentAction, cancelAction, consentText, cancelText }} />
    </BaseModal>
  );
};

const ModalContentHeader = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  text-align: center;
  padding: 0 16px;
  margin-top: 36px;
`;
