import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { type TooltipData } from 'common/types/mapData';
import { debounce } from 'common/utils';
import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { buildSingleViewTooltipStringHelper, checkIsOutsideBounds } from 'common/utils/map';
import { MapTooltip } from 'ui/map/tooltip';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { Loading } from 'ui/molecules/loading';

interface SingleLayerValueTitilerTooltipProps {
  url: string;
  unit: string;
  precision: number;
}

export const SingleLayerClickToValueTitilerTooltip = ({
  url,
  unit,
  precision,
}: SingleLayerValueTitilerTooltipProps) => {
  const [tooltip, setTooltip] = useState<TooltipData | undefined>();

  const map = useMap();

  const { extentLatLngBounds, isInfoLoading } = useGetExtentPolygon(url);

  map.off('click');

  map.on(
    'click',
    debounce(async (event: { originalEvent: PointerEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      if (!url || checkIsOutsideBounds(latLng, extentLatLngBounds)) {
        return;
      }

      const info = await titilerApiService.getPointInfoGeneric(url, latLng?.lng, latLng?.lat);

      const values = info?.values;

      if (info.detail === 'Point is outside dataset bounds') {
        setTooltip(undefined);
      }

      if (values && Array.isArray(values) && values.length > 0 && values.every(Number)) {
        console.log(values);
        values.forEach((value: number) => {
          setTooltip({
            id: 500,
            lat: latLng?.lat,
            lng: latLng?.lng,
            value: `${value.toFixed(precision)} ${unit}`,
          });
        });
      }
    }, TOOLTIP_DISPLAY_DELAY),
  );

  if (isInfoLoading) {
    return <Loading />;
  }

  return tooltip ? <MapTooltip tooltipData={tooltip} content={buildSingleViewTooltipStringHelper(tooltip)} /> : null;
};
