import { Polygon } from 'react-leaflet';
import { Layer } from 'components/map';
import { ROUTES } from 'common/navigation/routes';
import { useNavigateWithQuery } from 'common/navigation/hooks';
import { useMonitoringInfoStore } from '../store';

export const MapReclamationAreas = () => {
  const reclamationAreas = useMonitoringInfoStore.use.reclamationAreas();
  const navigateWithQuery = useNavigateWithQuery();

  return (
    <>
      {reclamationAreas && (
        <Layer visible={true} name="reclamations-ovewview" zIndex={500}>
          {reclamationAreas.map((item) => (
            <Polygon
              pathOptions={{
                fillColor: item.properties.color,
                color: 'white',
                dashArray: '5 10',
              }}
              positions={item.geometry.coordinates}
              key={item.properties.id}
              eventHandlers={{
                click: () => navigateWithQuery(ROUTES.landCover, []),
              }}
            />
          ))}
        </Layer>
      )}
    </>
  );
};
