import { Text, View } from '@react-pdf/renderer';

import { PdfTableColorColumn } from 'views/reports/external/pdfGenerator/components/PdfTableColorColumn';
import { PdfTableValueColumn } from 'views/reports/external/pdfGenerator/components/PdfTableValueColumn';
import { PdfTableBottomLine } from 'views/reports/external/pdfGenerator/components/PdfTableBottomLine';
import { commonStyles, tableStyles } from 'views/reports/external/pdfGenerator/styles';
import { type ExternalReportSimpleTable } from 'domain/reports/types';

export const ReportPdfSimpleTable = ({
  table: { tableHeader, dataHeader, unit, data },
}: {
  table: ExternalReportSimpleTable;
}) => (
  <View style={tableStyles.terrain_pie}>
    <View style={tableStyles.table}>
      <Text style={commonStyles.subheader}>{tableHeader}</Text>
      <View style={tableStyles.column_wrapper}>
        {data.some((item) => item.color) && <PdfTableColorColumn data={data} dataKey="color" />}
        <PdfTableValueColumn headerText={dataHeader} data={data} dataKey="label" fg1 />
        <PdfTableValueColumn headerText={`Value ( ${unit ? unit : '—'} )`} data={data} dataKey="value" />
        {data.some((item) => item.percentage) && (
          <PdfTableValueColumn headerText="%" data={data} dataKey="percentage" alignRight />
        )}
        <PdfTableBottomLine length={data.length + 1} />
      </View>
    </View>
  </View>
);
