import styled, { css } from 'styled-components';

import { type ColormapDictionary } from 'common/types';
import { ColorScaleBar } from './ColorScaleBar';

interface ColorScaleProps {
  colormap: ColormapDictionary;
  labels?: string[];
  tooltipLabels?: string[];
  unit?: string;
  segmented?: boolean;
  divided?: boolean;
  opacity?: number;
}

export const ColorScale = ({
  colormap,
  labels = [],
  tooltipLabels,
  unit,
  segmented = false,
  divided = false,
  opacity,
}: ColorScaleProps) => (
  <StyledColorScale>
    {!!labels.length && (
      <UpperSection>
        <Labels centered={divided}>
          {labels.map((label, index) => (
            <span key={index}>
              {label} {unit && index === labels.length - 1 && <sup>{unit}</sup>}
            </span>
          ))}
        </Labels>
      </UpperSection>
    )}
    <ColorScaleBar colormap={colormap} segmented={segmented} tooltipLabels={tooltipLabels} opacity={opacity} />
    {divided && (
      <LinesWrapper>
        {labels.map((_, index) => (
          <Line key={index} />
        ))}
      </LinesWrapper>
    )}
  </StyledColorScale>
);

const StyledColorScale = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 150px;
  max-width: 500px;
`;

const UpperSection = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.white};
`;

const Labels = styled.div<{ centered: boolean }>`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ centered }) => (centered ? 10 : 12)}px;
  font-weight: 500;
  height: 24px;

  ${({ centered }) =>
    centered &&
    css`
      justify-content: normal;

      > span {
        flex: 1;
        text-align: center;
      }
    `};
`;

const LinesWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
`;

const Line = styled.div`
  flex-basis: 100%;
  border-right: 1px solid ${({ theme }) => theme.color.white};

  :last-of-type {
    border: none;
  }
`;

ColorScale.displayName = 'ColorScale';
