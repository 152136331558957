import { useQuery } from '@tanstack/react-query';

import { hotspotsApi } from 'infrastructure/hotspots/hotspotsApiAdapter';
import { useHandleWarningErrorUseCase } from 'views/@errors/handleTypicalWarningErrorUseCase';
import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { areGreeneryHotspotTypesValid } from 'domain/greenery/typeguards';
import { WarningErrorType } from 'domain/@errors/enums';
import {
  HOTSPOTS_INITIAL_DATA_CACHE_TIME,
  HOTSPOTS_INITIAL_DATA_STALE_TIME,
  HOTSPOTS_OBJECT_TYPES_QUERY_KEY,
} from './constants';

export const hotspotsRepository = {
  useFetchHotspotTypes: () => {
    const { data, isLoading, isSuccess, error } = useQuery(
      [HOTSPOTS_OBJECT_TYPES_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }],
      () => hotspotsApi.getObjectTypes({ limit: DEFAULT_RESULTS_PER_PAGE }),
      {
        staleTime: HOTSPOTS_INITIAL_DATA_STALE_TIME,
        cacheTime: HOTSPOTS_INITIAL_DATA_CACHE_TIME,
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.HOTSPOT_TYPES, data, error, isSuccess);

    const hotspotTypes = areGreeneryHotspotTypesValid(data) ? data.data.results : [];

    return {
      hotspotTypes,
      isLoading,
      isSuccess,
      error,
    };
  },
};
