import { useMemo } from 'react';

import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';

export const useDepositOptions = () => {
  const currentDeposit = useAreaRequestStore.use.currentDeposit();
  const setCurrentDeposit = useAreaRequestStore.use.setCurrentDeposit();

  const handleTypeOptionChange = (name: string) => setCurrentDeposit(name);

  const { deposits } = explorationRepository.useFetchDeposits();

  const depositOptions = deposits
    .map(({ id, name, label_name, description, status }) => {
      return {
        id,
        name,
        label: label_name,
        labelSup: status === 'active' ? undefined : status,
        content: description,
        selected: currentDeposit === name,
        onClick: () => handleTypeOptionChange(name),
        disabled: status !== 'active',
      };
    })
    .sort((a, b) => a.id - b.id)
    .sort((a, b) => +a.disabled - +b.disabled);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedDepositOptions = useMemo(() => depositOptions, [JSON.stringify(depositOptions)]);

  return {
    currentDeposit,
    setCurrentDeposit,
    depositOptions: memoizedDepositOptions,
  };
};
