import { type CSSProperties, type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { type ColormapDictionary } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { Container, ColorScaleBadge, LabeledCheckbox } from 'ui';
import { LegendItemWraper } from './styled';

interface LayerManagerLegendItemProps {
  option: LayerObject;
  handleObjectsVisibility: (name: string) => void;
  objectVisibility: boolean;
  noBadges?: boolean;
  radio?: boolean;
  children?: ReactNode;
  iconColormap?: ColormapDictionary;
  isDndEnabled?: boolean;
}

export const LayerManagerLegendItem = ({
  option,
  handleObjectsVisibility,
  objectVisibility,
  noBadges,
  radio,
  children,
  iconColormap,
  isDndEnabled,
}: LayerManagerLegendItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, active } = useSortable({
    id: option.id,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString({
      x: 0,
      y: transform?.y || 0,
      scaleX: transform?.scaleX || 1,
      scaleY: transform?.scaleY || 1,
    }),
    transition,
  };

  return (
    <DraggableLegendItem
      data-testid={`legend-item-${option.name}`}
      ref={setNodeRef}
      style={style}
      active={!!active}
      isDragged={active?.id === option.id}
    >
      {isDndEnabled && (
        <DragIconWrapper ref={setActivatorNodeRef} {...attributes} {...listeners}>
          <DragIcon>
            <span />
            <span />
            <span />
          </DragIcon>
        </DragIconWrapper>
      )}
      <LabeledCheckbox
        variant="WIDE"
        onChange={() => handleObjectsVisibility(option.name)}
        checked={objectVisibility || false}
        radio={radio}
      >
        <Container>
          {iconColormap ? (
            <ColorScaleBadge colormap={iconColormap} label={option.label_name} />
          ) : (
            <>
              {option.color && !noBadges && (
                <LegendIcon data-testid="color-badge" color={option.color} variant={option.variant} />
              )}
              {option.label_name}
            </>
          )}
        </Container>
      </LabeledCheckbox>
      {children}
    </DraggableLegendItem>
  );
};

const DraggableLegendItem = styled(LegendItemWraper)<{ active: boolean; isDragged: boolean }>`
  padding: 10px 0;
  position: relative;

  ${({ active, isDragged }) =>
    active &&
    !isDragged &&
    css`
      opacity: 0.6;
    `};

  ${({ active }) =>
    active &&
    css`
      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        cursor: move;
        z-index: 2;
      }
    `};
`;

const DragIconWrapper = styled.div`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: move;
`;

const DragIcon = styled.div`
  position: relative;
  width: 6px;
  height: 9px;
  margin-right: 2px;
  background-color: transparent;

  span {
    position: absolute;
    background-color: ${({ theme }) => theme.color.lightGray};
    width: 100%;
    height: 1px;
    border-radius: 1px;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  span:nth-child(3) {
    bottom: 0;
  }
`;

const LegendIcon = styled.div<{ color: string; variant?: string }>`
  flex-shrink: 0;
  width: 22px;
  height: 16px;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  margin-right: 8px;

  ${({ variant }) =>
    variant === 'bordered' &&
    css`
      border: 3px solid ${({ theme }) => theme.color.white};
    `};
`;
