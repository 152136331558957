import { type AxiosResponse } from 'axios';

import { getApiData, getExternalData } from 'services/rest/restApiService';
import { type ShapeResultsApiResponse } from 'common/types/api';
import {
  type MineralTypesApiResponse,
  type DepositsApiResponse,
  type MineralizationResultsApiResponse,
  type IndicesApiResponse,
  type IndicesResultsApiResponse,
  type IndicatorDetails,
  type SpectralClassifiersApiResponse,
  type DepositClassificationSitesApiResponse,
  type SamplingPointsApiResponse,
} from 'domain/exploration/types';
import { EXPLORATION_API_URLS } from './explorationApiUrls';

export const explorationAPI = {
  getDeposits: ({ limit, offset }: { limit?: number; offset?: number }): Promise<AxiosResponse<DepositsApiResponse>> =>
    getApiData(EXPLORATION_API_URLS.getDeposits, {
      limit,
      offset,
    }),

  getMinerals: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<MineralTypesApiResponse>> =>
    getApiData(EXPLORATION_API_URLS.getMinerals, {
      limit,
      offset,
    }),

  getLineamentsShapes: async ({ area_name }: { area_name?: string }): Promise<AxiosResponse<ShapeResultsApiResponse>> =>
    getExternalData(
      // return await restAPI.private.get(AREAS_API_URLS.getLineamentsShapes, // TODO: restore when the API is ready
      EXPLORATION_API_URLS.getLineamentsShapes,
      { area_name },
    ),

  getLineamentsRangeResults: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(EXPLORATION_API_URLS.getLineamentsRangeResults, { limit, offset }),

  getLineamentDensityResults: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(EXPLORATION_API_URLS.getLineamentDensityResults, { limit, offset }),

  getSpectralClassifiers: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<SpectralClassifiersApiResponse>> =>
    getApiData(EXPLORATION_API_URLS.getClassifiers, {
      limit,
      offset,
    }),

  getMineralizationResults: ({
    areaId,
    classifierId,
    limit,
    offset,
  }: {
    areaId: number;
    classifierId?: string;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<MineralizationResultsApiResponse>> =>
    getApiData(EXPLORATION_API_URLS.getMineralsResults, {
      aoi_id: areaId,
      classifier_id: classifierId,
      limit,
      offset,
    }),

  getIndices: ({ limit, offset }: { limit?: number; offset?: number }): Promise<AxiosResponse<IndicesApiResponse>> =>
    getApiData(EXPLORATION_API_URLS.getIndices, {
      limit,
      offset,
    }),

  getIndicesResults: ({
    areaId,
    limit,
    offset,
  }: {
    areaId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<IndicesResultsApiResponse>> =>
    getApiData(EXPLORATION_API_URLS.getIndicesResults, {
      aoi_id: areaId,
      limit,
      offset,
    }),

  getIndicatorResultById: (indicatorId: number): Promise<AxiosResponse<IndicatorDetails>> =>
    getApiData(EXPLORATION_API_URLS.getIndicatorResultById.replace(':indicatorId', indicatorId.toString()), {}),

  getDepositClassificationSites: ({
    areaId,
    depositId,
    limit,
    offset,
  }: {
    areaId: number;
    depositId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<DepositClassificationSitesApiResponse>> =>
    getApiData(EXPLORATION_API_URLS.getDepositClassificationSites, {
      aoi_id: areaId,
      deposit_id: depositId,
      limit: limit,
      offset: offset,
    }),

  getSamplingPoints: ({
    areaId,
    depositId,
    mineralId,
    limit,
    offset,
  }: {
    areaId: number;
    depositId: number;
    mineralId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<SamplingPointsApiResponse>> =>
    getApiData(EXPLORATION_API_URLS.getSamplingPoints, {
      aoi_id: areaId,
      deposit_id: depositId,
      mineral_id: mineralId,
      limit,
      offset,
    }),

  getClustersDataSuppliers: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(EXPLORATION_API_URLS.getClustersDataSuppliers, { limit, offset }),

  getClustersResults: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(EXPLORATION_API_URLS.getClustersResults, { limit, offset }),

  getComposites: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getApiData(EXPLORATION_API_URLS.getComposites, {
      limit,
      offset,
    }),
};
