import chroma from 'chroma-js';

import { COORDINATES_PRECISION } from 'common/constants';
import { type ValueAndLabel } from 'common/types';
import { type ExtendedTooltipData, type TooltipData } from 'common/types/mapData';
import { type IndicesResult, type SpectralClassifierDTO } from 'domain/exploration/types';

export const buildMLSurfaceMineralizationTooltipStringHelper = (tooltip: ExtendedTooltipData) => {
  return `
    <h4>coordinates</h4>
    <p>${tooltip ? tooltip.lat.toFixed(COORDINATES_PRECISION) : undefined}</p>
    <p>${tooltip ? tooltip.lng.toFixed(COORDINATES_PRECISION) : undefined}</p>
    ${
      Array.isArray(tooltip.values) && !!tooltip.values.length
        ? `
      <h4 style="margin:0;margin-top:4px">Presented minerals</h4>
      ${tooltip.values
        .map((item) => `<p style="margin:0;margin-top:4px;text-transform:capitalize">${item.label}</p>`)
        .join('')}
    `
        : ''
    }
  `;
};

export const buildExplorationTooltipStringHelper = (tooltip: ExtendedTooltipData) => {
  return `
  <h4>coordinates</h4>
  <p>${tooltip ? tooltip.lat.toFixed(COORDINATES_PRECISION) : undefined}</p>
  <p>${tooltip ? tooltip.lng.toFixed(COORDINATES_PRECISION) : undefined}</p>
  ${
    Array.isArray(tooltip?.values)
      ? `
    ${tooltip.values
      .map(
        (item) =>
          `<h4 style="margin:0;margin-top:4px">${item.label} value</h4>
      <p style="margin:0">${item.value.toFixed(2)}</p>`,
      )
      .join('')}
  `
      : ''
  }
`;
};

export const buildSamplingPointsTooltipStringHelper = (tooltip: TooltipData, classifier: string | null) => {
  if (classifier === '3') {
    return `
    <h4>coordinates</h4>
    <p>${tooltip ? tooltip.lat.toFixed(COORDINATES_PRECISION) : undefined}</p>
    <p>${tooltip ? tooltip.lng.toFixed(COORDINATES_PRECISION) : undefined}</p>
    ${
      tooltip.value
        ? `<h4 style="margin:0;margin-top:4px">Presented mineral</h4>
          <p style="margin:0;margin-top:4px;text-transform:capitalize">${tooltip.label}</p>`
        : ''
    }
  `;
  }

  return `
    <h4>coordinates</h4>
    <p>${tooltip ? tooltip.lng.toFixed(COORDINATES_PRECISION) : undefined}</p>
    <p>${tooltip ? tooltip.lat.toFixed(COORDINATES_PRECISION) : undefined}</p>
    ${
      tooltip?.value
        ? `
      <h4>${tooltip.label} value</h4>
      ${typeof tooltip.value === 'number' && `<p>${tooltip.value.toFixed(2)}</p>`}
    `
        : ''
    }
  `;
};

export const buildIndicesTooltipStringHelper = (tooltip: TooltipData) => {
  return `
    <h4>coordinates</h4>
    <p>${tooltip ? tooltip.lat.toFixed(COORDINATES_PRECISION) : undefined}</p>
    <p>${tooltip ? tooltip.lng.toFixed(COORDINATES_PRECISION) : undefined}</p>
    ${
      tooltip?.value
        ? `
      <h4>value</h4>
      ${typeof tooltip.value === 'number' && `<p>${tooltip.value.toFixed(2)}</p>`}
    `
        : ''
    }
  `;
};

export const mapSpectralClassifiersObjectsToOptionsHelper = (indicators: SpectralClassifierDTO[]): ValueAndLabel[] => {
  return indicators.map((i) => ({
    value: i.id,
    label: i.label_name,
  }));
};

export const mapIndicesResultObjectsToOptionsHelper = (indicators: IndicesResult[]): ValueAndLabel[] => {
  return indicators.map((i) => ({
    value: i.id,
    label: i.name,
  }));
};

export const buildCubehelixDiscreteValuesColormapHelper = (values: number[], scaleMax: number) => {
  const cubehelixScale = chroma.cubehelix().scale().domain([0, scaleMax]);

  const colormapArray = values.map((value) => {
    const rgba = cubehelixScale(value).rgba();

    rgba[rgba.length - 1] *= 255;

    return [[value, value + 0.001], rgba];
  });

  return JSON.stringify(colormapArray);
};

export const getClassifierPrefix = (classifierQuery: string | null | undefined, areaId: number) => {
  switch (classifierQuery) {
    case '1':
      return `${areaId}_sam_`;
    case '2':
    case '4':
      return `${areaId}_mtmf_`;
    case '3':
      return `${areaId}_ml_`;
    default:
      return `${areaId}_`;
  }
};
