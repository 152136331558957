import styled from 'styled-components';

interface IconWrapperProps {
  active?: boolean;
  disabled?: boolean;
  iconWidth?: number;
  iconColor?: string;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  color: ${({ active, disabled, theme, iconColor }) => {
    switch (true) {
      case active:
        return theme.color.black;
      case disabled:
        return theme.color.midGray;
      default:
        return iconColor ? iconColor : theme.color.white;
    }
  }};
  width: ${({ iconWidth }) => iconWidth}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

IconWrapper.defaultProps = {
  iconWidth: 20,
};
