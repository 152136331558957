import { SHORT_ISO_DATE_FORMAT } from 'common/constants/datetime';
import { type DataPoint } from 'common/types/mapData';
import dayjs from 'dayjs';
import { weatherApi } from 'services/weather/weatherApiClient';
import { useGraphStore } from 'components/graph/graphStore';
import { type GraphData } from 'components/graph/types';

export const useWeatherData = () => {
  const setGraphFetching = useGraphStore.use.setGraphFetching();
  const resetGraphFetching = useGraphStore.use.resetGraphFetching();

  const getWeatherData = async (dataPoint: DataPoint, graphData: GraphData) => {
    setGraphFetching();

    const startDate = dayjs(graphData[0][0]);
    const endDate = dayjs(graphData[graphData.length - 1][0]);
    const daysDiff = endDate.diff(startDate, 'days');

    if (daysDiff <= 30) {
      const response = await weatherApi.getDaily(dataPoint, {
        start: startDate.format(SHORT_ISO_DATE_FORMAT),
        end: endDate.format(SHORT_ISO_DATE_FORMAT),
      });

      resetGraphFetching();
      return response.data.results;
    } else {
      const weatherData = [];
      const cycles = daysDiff / 30;

      for (let index = 0; index < cycles; index++) {
        const calculatedStartDate = startDate.clone().add(index * 31, 'days');
        const calculatedEndDate = calculatedStartDate.clone().add(30, 'days');
        if (endDate.isBefore(calculatedStartDate)) break;

        const response = await weatherApi.getDaily(dataPoint, {
          start: calculatedStartDate.format(SHORT_ISO_DATE_FORMAT),
          end: (calculatedEndDate.isBefore(endDate) ? calculatedEndDate : endDate).format(SHORT_ISO_DATE_FORMAT),
        });
        weatherData.push(response.data.results);
      }

      resetGraphFetching();
      return weatherData.flat();
    }
  };

  return { getWeatherData };
};
