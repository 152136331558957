import {
  type DepositClassificationSite,
  type IndicesResult,
  type IndicatorDTO,
  type IndicesResultDTO,
} from 'domain/exploration/types';

export const withSwappedCoordinatesOrder = (sites: DepositClassificationSite[]): DepositClassificationSite[] => {
  return sites.map((site) => ({
    ...site,
    geometry: { ...site.geometry, coordinates: [site.geometry.coordinates[1], site.geometry.coordinates[0]] },
  }));
};

export const withMissingFieldsIndices = (results: IndicesResultDTO[], indices: IndicatorDTO[]): IndicesResult[] => {
  const indicesResults = results
    .filter(({ indicator_id }) => indices.some(({ id }) => id === indicator_id))
    .map((result) => {
      const currentIndicator = indices.find(({ id }) => result.indicator_id === id);

      return {
        ...result,
        description: currentIndicator?.description,
        name: currentIndicator?.name || '',
      };
    });

  return indicesResults;
};
