import { type SimpleIndicatorStats, type ExtendedIndicator } from 'common/types';
import { simpleRound } from 'common/utils';
import { type WaterQualityResult } from 'domain/water/types';

export const getExtendedTableData = (first: number | null, second: number | null, precision: number) => {
  const firstValue = typeof first === 'number' ? simpleRound(first, precision) : null;
  const secondValue = typeof second === 'number' ? simpleRound(second, precision) : null;

  const diff = firstValue === null || secondValue === null ? null : +secondValue - +firstValue;
  const trend = diff ? (diff > 0 ? true : false) : null;

  const difference = typeof diff === 'number' ? simpleRound(diff, precision) : null;

  return {
    firstValue,
    secondValue,
    difference,
    trend,
  };
};

export const getAllWaterbodiesIndicatorStats = (
  results: WaterQualityResult[],
  indicator: ExtendedIndicator,
): { [K in keyof SimpleIndicatorStats]: number | null } => {
  const validMinValues = results.filter((result) => typeof result.min_value === 'number');
  const validMaxValues = results.filter((result) => typeof result.max_value === 'number');

  const theLowestMinValue = validMinValues.length
    ? validMinValues.reduce((acc, item) => Math.min(acc, item.min_value), indicator.max_value)
    : null;
  const theHighestMaxValue = validMaxValues.length
    ? validMaxValues.reduce((acc, item) => Math.max(acc, item.max_value), indicator.min_value)
    : null;

  return {
    min_value: theLowestMinValue,
    max_value: theHighestMaxValue,
  };
};
