import { unsanitizeDate } from 'common/utils/datetime';
import { useQueryParameter } from 'common/navigation/hooks';
import { DATE_QUERY_PARAMETER, SECOND_DATE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useTimestampChange } from 'components/Timeline/useTimestampChange';
import { useSecondTimestampChange } from 'components/Timeline/useSecondTimestampChange';

export const useTimelineService = () => {
  const dateQuery = useQueryParameter(DATE_QUERY_PARAMETER);
  const secondDateQuery = useQueryParameter(SECOND_DATE_QUERY_PARAMETER);

  const timestamp = dateQuery && dateQuery !== 'null' ? unsanitizeDate(dateQuery) : null;
  const secondTimestamp = secondDateQuery && secondDateQuery !== 'null' ? unsanitizeDate(secondDateQuery) : null;

  const { changeTimestamp } = useTimestampChange();
  const { changeSecondTimestamp } = useSecondTimestampChange();

  const timelineService = {
    timestamp,
    secondTimestamp,
    changeTimestamp,
    changeSecondTimestamp,
  };

  return timelineService;
};
