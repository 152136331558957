import { COMMON_QUERY_PARAMETERS, SPATIAL_RESOLUTION_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { TEMPERATURE_PATH_NAME } from 'domain/temperature/constants';

export const TEMPERATURE_VIEW_CONFIG = {
  name: TEMPERATURE_PATH_NAME,
  path: ROUTES.temperature,
  params: [...COMMON_QUERY_PARAMETERS, SPATIAL_RESOLUTION_QUERY_PARAMETER],
  tabs: {},
};
