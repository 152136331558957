import styled from 'styled-components';
import { Slider as MuiSlider, sliderClasses, type SliderOwnProps } from '@mui/base';
import { type ReactNode, type CSSProperties } from 'react';

export type SliderVariant = 'DISCRETE' | 'DISCRETE_BIG' | 'RANGE' | 'RANGE_INVERTED';

type SliderStyles = Required<
  Pick<CSSProperties, 'color' | 'opacity'> & {
    railHeight: number;
    railEndMargin: number;
    thumbWidth: number;
    thumbHeight: number;
    track: SliderOwnProps['track'];
  }
>;

const styles: Record<SliderVariant, SliderStyles> = {
  DISCRETE: {
    color: 'silverDark',
    opacity: 1,
    railHeight: 4,
    railEndMargin: 3,
    thumbWidth: 4,
    thumbHeight: 8,
    track: false,
  },
  DISCRETE_BIG: {
    color: 'white',
    opacity: 0.5,
    railHeight: 8,
    railEndMargin: 15,
    thumbWidth: 18,
    thumbHeight: 18,
    track: false,
  },
  RANGE: {
    color: 'silverDark',
    opacity: 1,
    railHeight: 4,
    railEndMargin: 3,
    thumbWidth: 4,
    thumbHeight: 8,
    track: 'normal',
  },
  RANGE_INVERTED: {
    color: 'silverDark',
    opacity: 1,
    railHeight: 4,
    railEndMargin: 3,
    thumbWidth: 4,
    thumbHeight: 8,
    track: 'inverted',
  },
};

export interface MarksInterface {
  [key: string]: string;
}

const SliderThumbLabel = ({ children }: { children: ReactNode }) => <ThumbLabel>{children}</ThumbLabel>;

interface SliderProps {
  ariaLabel: string;
  variant?: SliderVariant;
  marks: MarksInterface;
  value: number | number[];
  onChange: (value: number | number[]) => void;
  defaultValue: number | number[];
  min: number;
  max: number;
  step: number | null;
  isThumbLabel?: boolean;
}

export const Slider = ({
  ariaLabel,
  variant,
  marks,
  value,
  onChange,
  defaultValue,
  min,
  max,
  step,
  isThumbLabel,
}: SliderProps) => (
  <StyledMuiSlider
    variant={variant}
    getAriaLabel={() => ariaLabel}
    min={min}
    max={max}
    step={step}
    track={styles[variant!].track || false}
    marks={Object.entries(marks).map((e) => ({ value: Number(e[0]), label: e[1] }))}
    value={value}
    defaultValue={defaultValue}
    onChange={(_: Event, value: number | number[]) => onChange(value)}
    slots={{ valueLabel: isThumbLabel ? SliderThumbLabel : null }}
  />
);

const StyledMuiSlider = styled(MuiSlider).attrs<{ variant: SliderVariant }>((props) => ({
  variant: props.variant,
  $railBackground: () => (styles[props.variant].track === 'inverted' ? '#fff' : 'currentColor'),
  $railOpacity: () => (styles[props.variant].track === 'inverted' ? 1 : 0.3),
  $trackBackground: () => {
    switch (styles[props.variant].track) {
      case 'normal':
        return '#fff';
      case 'inverted':
        return '#666464';
      default:
        return 'transparent';
    }
  },
  $trackOpacity: () => {
    switch (styles[props.variant].track) {
      case 'normal':
      case 'inverted':
        return 1;
      default:
        return 0;
    }
  },
}))<{ variant: SliderVariant; railBackground: string }>`
  color: ${({ theme, variant }) => theme.color[styles[variant].color]};
  height: 8px;
  width: 100%;
  padding: 16px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} {
    pointer-events: none;
    cursor: default;
    color: ${({ theme }) => theme.color.white};
    opacity: 0.3;
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    left: ${({ variant }) => -styles[variant].railEndMargin}px;
    width: calc(100% + ${({ variant }) => styles[variant].railEndMargin * 2}px);
    height: ${({ variant }) => styles[variant].railHeight}px;
    border-radius: ${({ variant }) => styles[variant].railHeight / 2}px;
    background-color: ${({ $railBackground }) => $railBackground};
    opacity: ${({ $railOpacity }) => $railOpacity};
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    left: ${({ variant }) => -styles[variant].railEndMargin}px;
    width: calc(100% + ${({ variant }) => styles[variant].railEndMargin * 2}px);
    height: ${({ variant }) => styles[variant].railHeight}px;
    border-radius: ${({ variant }) => styles[variant].railHeight / 2}px;
    background-color: ${({ $trackBackground }) => $trackBackground};
    opacity: ${({ $trackOpacity }) => $trackOpacity};
  }

  & .${sliderClasses.markLabel} {
    display: block;
    position: absolute;
    top: 24px;
    font-size: 10px;
    font-weight: 500;
    color: currentColor;
    transform: translate(-8px, -26px);
    margin-left: 0.4em;
  }

  & .${sliderClasses.mark} {
    display: block;
    position: absolute;
    top: 16px;
    width: 1px;
    height: ${({ variant }) => styles[variant].railHeight}px;
    background-color: ${({ theme }) => theme.color.white};
    opacity: ${({ variant }) => styles[variant].opacity};
  }

  & .${sliderClasses.thumb} {
    position: absolute;
    width: ${({ variant }) => styles[variant].thumbWidth}px;
    height: ${({ variant }) => styles[variant].thumbHeight}px;
    transform: translate(
      -${({ variant }) => styles[variant].thumbWidth / 2}px,
      -${({ variant }) => (styles[variant].thumbHeight - styles[variant].railHeight) / 2}px
    );
    box-sizing: border-box;
    border-radius: ${({ variant }) => styles[variant].thumbWidth / 2}px;
    outline: 0;
    background-color: ${({ theme }) => theme.color.white};
  }

  & .${sliderClasses.thumb}[style="left: 0%;"] {
    > :last-child {
      margin-left: 0;
    }
  }
`;

const ThumbLabel = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.white};
  margin-top: 10px;
  margin-left: -0.6em;
`;

Slider.defaultProps = {
  variant: 'DISCRETE' as SliderVariant,
};
