import { useEffect } from 'react';

import { useGraphStore } from 'components/graph/graphStore';
import { useWaterbodies } from '../waterbodies';

export const useWaterQualityLifeCycle = () => {
  const resetGraphStore = useGraphStore.use.resetGraphStore();
  const graphData = useGraphStore.use.graphData();
  const { selectedWaterbody } = useWaterbodies();
  const waterbodyId = selectedWaterbody?.value;

  useEffect(() => {
    if (graphData && !waterbodyId) {
      resetGraphStore();
    }
  }, [graphData, waterbodyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      resetGraphStore();
    };
  }, [waterbodyId]); // eslint-disable-line react-hooks/exhaustive-deps
};
