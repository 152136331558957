import { LoginForm } from 'ui/organisms/loginSidebar/LoginForm';
import { LoginSidebar } from 'ui/organisms/loginSidebar/LoginSidebar';
import { useForgotPasswordService } from 'views/user/forgotPasswordService';
import { useLoginFormService } from 'views/user/loginFormService';
import { useLoginService } from 'views/user/loginService';

interface LoginSidebarConnectedProps {
  refresh?: boolean;
  redirect?: boolean;
}

export const LoginSidebarConnected = ({ refresh, redirect }: LoginSidebarConnectedProps) => {
  const { useHandleSessionDataSaveUseCase: handleSessionDataSaveUseCase } = useLoginService();

  const forgotPasswordServiceData = useForgotPasswordService();

  const sessionHandlingData = handleSessionDataSaveUseCase(refresh, redirect);

  const loginFormServiceData = useLoginFormService(sessionHandlingData, forgotPasswordServiceData);

  return (
    <LoginSidebar>
      <LoginForm {...loginFormServiceData} />
    </LoginSidebar>
  );
};
