import { Navigate, RouterProvider, createHashRouter } from 'react-router-dom';

import { ROUTES } from 'common/navigation/routes';
import { useWatchTokenValidityWorker } from 'services/tokenValidationService';

import { useBuildDisplacementsRouteObject } from 'views/displacements/useBuildDisplacementsRouteObject';
import { useBuildExplorationRouteObject } from 'views/exploration/useBuildExplorationRouteObject';
import { useBuildGreeneryRouteObject } from 'views/greenery/useBuildGreeneryRouteObject';
import { useBuildExtractionRouteObject } from 'views/mine/useBuildExtractionRouteObject';

import { useBuildWaterRouteObject } from 'views/water/useBuildWaterRouteObject';

import { useBindAppLifecycleEvents } from 'common/hooks/bindAppLifecycleEvents';
import { useUpdateTelemetryStatus } from 'common/hooks/updateTelemetryStatus';
import { RequireAuth } from 'common/navigation/RequireAuth';
import 'dygraphs/dist/dygraph.css';
import 'tippy.js/dist/tippy.css';
import { AreaRequestNew } from 'views/areas/areaRequestNew/AreaRequestNew';
import { Areas } from 'views/areas/areasList/Areas';
import { Area } from 'views/areas/areaDetails/Area';
import { AreasList } from 'views/areas/areasList/AreasList';
import { CheckoutPage } from 'views/payments/CheckoutPage';
import { CheckoutPageLayout } from 'views/payments/CheckoutPageLayout';
import { SignUp } from 'views/user/SignUp';
import { ChangePassword } from 'views/user/ChangePassword';
import { Login } from 'views/user/Login';
import { Logout } from 'views/user/Logout';
import { buildProspectingRouteObject } from 'views/prospecting/buildProspectingRouteObject';
import { buildMonitoringRouteObject } from 'views/monitoring/buildMonitoringRouteObject';
import { useBindSubscriptionState } from 'useBindSubscriptionState';
import { buildTemperatureRouteObject } from 'views/temperature/buildTemperatureRouteObject';
import { useBuildSubscriptionsRouteObject } from 'views/subscriptions/useBuildSubscriptionsRouteObject';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../package.json');

function App() {
  useUpdateTelemetryStatus();

  useBindAppLifecycleEvents(version);

  useWatchTokenValidityWorker();

  const currentPathname = window.location.pathname;
  const isLoginPath = currentPathname === ROUTES.login;

  const monitoringRouteObject = buildMonitoringRouteObject();
  const prospectingRouteObject = buildProspectingRouteObject();
  const extractionRouteObject = useBuildExtractionRouteObject();
  const mineralsRouteObject = useBuildExplorationRouteObject();
  const waterRouteObject = useBuildWaterRouteObject();
  const greeneryRouteObject = useBuildGreeneryRouteObject();
  const displacementsRouteObject = useBuildDisplacementsRouteObject();
  const temperatureRouteObject = buildTemperatureRouteObject();
  const buildSubscriptionsRouteObject = useBuildSubscriptionsRouteObject();

  useBindSubscriptionState();

  return (
    <RouterProvider
      router={createHashRouter([
        {
          index: true,
          element: <Navigate to={ROUTES.login} state={{ from: isLoginPath ? undefined : currentPathname }} />,
        },
        { path: ROUTES.login, element: <Login /> },
        {
          path: ROUTES.logout,
          element: <Logout />,
        },
        { path: ROUTES.signup, element: <SignUp /> },
        { path: ROUTES.changePassword, element: <ChangePassword /> },
        {
          path: ROUTES.payment,
          element: (
            <RequireAuth>
              <CheckoutPageLayout />
            </RequireAuth>
          ),
          children: [
            {
              path: ROUTES.paymentProcess,
              element: <CheckoutPage />,
            },
          ],
        },
        buildSubscriptionsRouteObject,
        {
          path: ROUTES.areasList,
          element: (
            <RequireAuth>
              <Areas />
            </RequireAuth>
          ),
          errorElement: <Navigate to={ROUTES.login} />,
          children: [
            {
              path: ROUTES.areasList,
              element: <AreasList />,
            },
          ],
        },
        {
          path: ROUTES.areaRequestNew,
          element: (
            <RequireAuth>
              <AreaRequestNew />
            </RequireAuth>
          ),
        },
        {
          path: ROUTES.areaDetails,
          element: (
            <RequireAuth>
              <Area />
            </RequireAuth>
          ),
          errorElement: <Navigate to={ROUTES.login} />,
          children: [
            monitoringRouteObject,
            prospectingRouteObject,
            extractionRouteObject,
            mineralsRouteObject,
            waterRouteObject,
            greeneryRouteObject,
            displacementsRouteObject,
            temperatureRouteObject,
          ],
        },
        { path: '*', element: <Navigate to={ROUTES.areasList} /> },
      ])}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, import/no-default-export
export default App;
