import styled, { css } from 'styled-components';

import { debounce } from 'common/utils';
import { type MarksInterface, Slider } from 'ui/slider/Slider';
import { usePointHistory } from 'components/graph/usePointHistory';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { ASCENDING, COHERENCE_TITLE, DESCENDING } from 'domain/displacements/constants';

export const CoherenceSlider = () => {
  const { clearPointHistoryUseCase } = usePointHistory();
  const kmzUrls = useDisplacementsStore.use.kmzUrls();
  const kmzId = useDisplacementsStore.use.kmzId();
  const setKmzId = useDisplacementsStore.use.setKmzId();

  if (!kmzUrls || !kmzId || kmzUrls.length <= 1) return null;

  const currentIndex = kmzUrls.findIndex((kmz) => kmz.id === kmzId);
  const currentUrl = kmzUrls[currentIndex].url;

  const isAscending = currentUrl.match(ASCENDING);
  const isDescending = currentUrl.match(DESCENDING);
  const isSwitch = !!(isAscending || isDescending);

  const marks = kmzUrls.reduce((acc: MarksInterface, kmz, i) => {
    acc[i] = String(kmz.coherence);
    return acc;
  }, {});

  const onChangeHandler = (value: number | number[]) => {
    setKmzId(kmzUrls[+value].id);
    clearPointHistoryUseCase();
  };
  const debouncedOnChangeHandler = debounce(onChangeHandler, 500);

  return (
    <CoherenceSliderWrapper $switch={isSwitch}>
      <CoherenceSliderTitle>{isSwitch ? ASCENDING : COHERENCE_TITLE}</CoherenceSliderTitle>
      <CoherenceSliderLabel width={kmzUrls.length * 50} $switch={isSwitch}>
        <Slider
          ariaLabel="coherence-slider"
          variant="DISCRETE_BIG"
          marks={marks}
          value={currentIndex}
          onChange={debouncedOnChangeHandler}
          defaultValue={currentIndex}
          min={0}
          max={kmzUrls.length - 1}
          step={1}
        />
      </CoherenceSliderLabel>
      {isSwitch && <CoherenceSliderTitle>{DESCENDING}</CoherenceSliderTitle>}
    </CoherenceSliderWrapper>
  );
};

const CoherenceSliderWrapper = styled.div<{ $switch: boolean }>`
  height: 100%;
  display: flex;
  gap: ${(props) => (props.$switch ? '10px' : '20px')};
  margin-left: 10px;
  z-index: 1010;
  align-items: center;
`;
const CoherenceSliderTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const CoherenceSliderLabel = styled.div<{ width: number; $switch: boolean }>`
  width: ${({ width }) => width + 'px'};
  padding-left: 5%;
  margin: 0 5px;

  ${({ $switch }) =>
    $switch &&
    css`
      width: 20px;
      padding-left: 0;
    `}
`;
