import { Loading } from 'ui/molecules/loading';
import { segmentationDataService } from 'views/greenery/segmentation/segmentationDataService';
import { SegmentationComparatorDataLayerWrapper } from 'views/greenery/segmentation/SegmentationComparatorDataLayerWrapper';

interface SegmentationComparatorMapContentProps {
  timestamp: string;
  secondTimestamp: string;
  currentObjectId: number;
}

export const SegmentationComparatorMapContent = ({
  timestamp,
  secondTimestamp,
  currentObjectId,
}: SegmentationComparatorMapContentProps) => {
  const { firstResultId, secondResultId, areResultsLoading } = segmentationDataService.useSegmentationDoubleResults(
    timestamp,
    secondTimestamp,
  );

  if (areResultsLoading) {
    return <Loading />;
  }

  if (!firstResultId || !secondResultId) {
    return null;
  }

  return (
    <SegmentationComparatorDataLayerWrapper
      firstResultId={firstResultId}
      secondResultId={secondResultId}
      currentObjectId={currentObjectId}
    />
  );
};
