import { Outlet } from 'react-router-dom';

import { Loading } from 'ui/molecules/loading';
import { TabSwitcher, TopButtonsWrapper } from 'components';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useUserStore } from 'infrastructure/user/userStore';
import { useTreeShakeQueryParams } from 'common/navigation/hooks';
import { getAvailableTabSwitcherOptions } from 'common/navigation/utils';
import { GREENERY_TAB_SWITCHER_BUTTONS, GREENERY_VIEW_CONFIG } from 'domain/greenery/configs';
import { useGreeneryLifecycleService } from './services';

export const Greenery = () => {
  const { greeneryObjectsLoading } = greeneryRepository.useFetchGreeneryObjects();
  const { userPermissions } = useUserStore();

  useGreeneryLifecycleService();

  useTreeShakeQueryParams(GREENERY_VIEW_CONFIG);

  if (!userPermissions) return null;

  const greeneryTabSwitcherOptions = getAvailableTabSwitcherOptions(
    GREENERY_TAB_SWITCHER_BUTTONS,
    userPermissions.granted_permissions,
  );

  if (greeneryObjectsLoading) {
    return <Loading />;
  }

  return (
    <>
      <TopButtonsWrapper>
        <TabSwitcher options={greeneryTabSwitcherOptions} />
      </TopButtonsWrapper>
      <Outlet />
    </>
  );
};
