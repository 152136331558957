import styled from 'styled-components';

import { Button } from 'ui/atoms/button';

interface FormButtonsProps {
  isLoading?: boolean;
  handleCancel: () => void;
}

export const FormButtons = ({ isLoading = false, handleCancel }: FormButtonsProps) => (
  <ButtonsWrapper>
    <Button variant="SECONDARY" onClick={handleCancel} minWidth={160} squared>
      cancel
    </Button>
    <Button type="submit" minWidth={160} squared isLoading={isLoading}>
      save
    </Button>
  </ButtonsWrapper>
);

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 30px;
`;
