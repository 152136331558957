import { useGetBandRange } from 'services/titiler/hooks';
import { sanitizeMlStorageUrl } from 'services/titiler/utils';
import { useQueryParameter } from 'common/navigation/hooks';
import { temperatureRepository } from 'infrastructure/temperature/temperatureRepository';
import { DATE_QUERY_PARAMETER, SPATIAL_RESOLUTION_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGetAoiName } from 'common/hooks/getAoiName';

export const useCurrentCog = () => {
  const { areaName } = useGetAoiName();
  const timestamp = useQueryParameter(DATE_QUERY_PARAMETER);
  const resolutionQuery = useQueryParameter(SPATIAL_RESOLUTION_QUERY_PARAMETER);
  const resolution = resolutionQuery ? parseInt(resolutionQuery) : undefined;

  const { results } = temperatureRepository.useFetchTemperatureResults(areaName);
  const url = results.find((result) => result.img_date === timestamp && result.resolution === resolution)?.url;

  const fileUrl = url ? sanitizeMlStorageUrl(url) : '';

  const { range } = useGetBandRange(fileUrl, 'b1');

  return { fileUrl, range };
};
