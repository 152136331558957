import { type FormEventHandler } from 'react';
import styled, { css, type CSSProperties } from 'styled-components';

import { type Palette } from 'ui/types';
import { Checkbox, type CheckboxVariant } from 'ui/atoms/checkbox/Checkbox';

export type LabeledCheckboxVariant = 'BASIC' | 'WIDE' | 'WIDE_TRANSITIONAL' | 'WIDE_FILLED' | 'LOGIN';

type CheckboxStyle = Required<
  Pick<CSSProperties, 'justifyContent'> & {
    checkboxVariant: CheckboxVariant;
  }
>;

const styles: Palette<LabeledCheckboxVariant, CheckboxStyle> = {
  BASIC: {
    checkboxVariant: 'PRIMARY',
    justifyContent: 'start',
  },
  WIDE: {
    checkboxVariant: 'PRIMARY',
    justifyContent: 'space-between',
  },
  WIDE_TRANSITIONAL: {
    checkboxVariant: 'TRANSITIONAL',
    justifyContent: 'space-between',
  },
  WIDE_FILLED: {
    checkboxVariant: 'PRIMARY',
    justifyContent: 'space-between',
  },
  LOGIN: {
    checkboxVariant: 'LOGIN',
    justifyContent: 'start',
  },
};

export interface LabeledCheckboxProps {
  children: React.ReactNode;
  variant?: LabeledCheckboxVariant;
  checked: boolean;
  disabled?: boolean;
  radio?: boolean;
  reversed?: boolean;
  isBoxHidden?: boolean;
  onChange?: FormEventHandler<HTMLLabelElement>;
}

export const LabeledCheckbox = ({
  children,
  variant,
  checked,
  disabled = false,
  radio = false,
  reversed = false,
  isBoxHidden = false,
  onChange,
}: LabeledCheckboxProps) => (
  <StyledLabel
    onChange={isBoxHidden ? undefined : onChange}
    $variant={variant!}
    $disabled={disabled}
    $reversed={reversed}
    $defaultCursor={isBoxHidden}
  >
    {children}
    {!isBoxHidden && (
      <Checkbox checked={checked} variant={styles[variant!].checkboxVariant} radio={radio} disabled={disabled} />
    )}
  </StyledLabel>
);

interface StyledLabelProps {
  $variant: LabeledCheckboxVariant;
  $disabled: boolean;
  $reversed: boolean;
  $defaultCursor: boolean;
}

const StyledLabel = styled.label<StyledLabelProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ $reversed }) => ($reversed ? 'row-reverse' : 'row')};
  justify-content: ${({ $variant }) => styles[$variant].justifyContent};
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.color.white};
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  word-break: break-word;
  cursor: ${({ $defaultCursor }) => ($defaultCursor ? 'default' : 'pointer')};

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.color.whiteTransparent40};
      pointer-events: none;
    `}
`;

LabeledCheckbox.defaultProps = {
  variant: 'BASIC' as LabeledCheckboxVariant,
  disabled: false,
  radio: false,
  isBoxHidden: false,
};

LabeledCheckbox.displayName = 'LabeledCheckbox';
