import { useEffect } from 'react';

import { useQueryParameter, useAoiNumber } from 'common/navigation/hooks';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { hotspotsRepository } from 'infrastructure/hotspots/hotspotsRepository';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { GREENERY_INDICATOR_QUERY_PARAMETER, VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const useGetVegetationHotspotsMapContentUseCase = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const indicatorQuery = useQueryParameter(GREENERY_INDICATOR_QUERY_PARAMETER);

  const layersVisibility = useLayerManagerStore.use.layersVisibility();
  const updateLayersVisibility = useLayerManagerStore.use.updateLayersVisibility();

  const areaId = useAoiNumber();

  const { hotspotTypes, isLoading } = hotspotsRepository.useFetchHotspotTypes();

  useEffect(() => {
    updateLayersVisibility(Object.fromEntries(hotspotTypes.map(({ name }) => [name, layersVisibility[name] || true])));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewQuery, indicatorQuery, hotspotTypes.length]);

  const { hotspotsData, areaHotspotsLoading } = greeneryRepository.useFetchVegetationHotspotResults(areaId);

  return { hotspots: hotspotsData, isLoading: areaHotspotsLoading || isLoading };
};
