import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type DataRequestTickDictionary } from 'domain/dataRequest/types';

interface DataRequestState {
  isDataRequestModalOpen: boolean;
  openDataRequestModal: () => void;
  closeDataRequestModal: () => void;
  dataTypesSelection: DataRequestTickDictionary;
  toggleDataTypeSelection: (dataTypeId: number) => void;
  analysisTypesSelection: DataRequestTickDictionary;
  toggleAnalysisTypeSelection: (analysisTypeId: number) => void;
  customReport: string;
  setCustomReport: (report: string) => void;
  clearDataRequestStore: () => void;
}

const initialState = {
  isDataRequestModalOpen: false,
  dataTypesSelection: {},
  analysisTypesSelection: {},
  customReport: '',
};

const useDataRequestStoreBase = create<DataRequestState>()(
  devtools(
    (set) => ({
      isDataRequestModalOpen: initialState.isDataRequestModalOpen,
      openDataRequestModal: () => set({ isDataRequestModalOpen: true }, false, 'dataRequest/openModal'),
      closeDataRequestModal: () => set({ isDataRequestModalOpen: false }, false, 'dataRequest/closeModal'),
      dataTypesSelection: initialState.dataTypesSelection,
      toggleDataTypeSelection: (dataTypeId: number) =>
        set(
          (state) => ({
            dataTypesSelection: {
              ...state.dataTypesSelection,
              [dataTypeId]: !state.dataTypesSelection[dataTypeId],
            },
          }),
          false,
          'dataRequest/toggleDataTypeSelection',
        ),
      analysisTypesSelection: initialState.analysisTypesSelection,
      toggleAnalysisTypeSelection: (analysisTypeId: number) =>
        set(
          (state) => ({
            analysisTypesSelection: {
              ...state.analysisTypesSelection,
              [analysisTypeId]: !state.analysisTypesSelection[analysisTypeId],
            },
          }),
          false,
          'dataRequest/toggleAnalysisTypeSelection',
        ),
      customReport: initialState.customReport,
      setCustomReport: (report: string) => set({ customReport: report }, false, 'dataRequest/setCustomReport'),
      clearDataRequestStore: () => set(initialState, false, 'dataRequest/clearDataRequestStore'),
    }),
    { name: 'dataRequestStore' },
  ),
);

export const useDataRequestStore = createSelectors(useDataRequestStoreBase);
