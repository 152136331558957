import { useState } from 'react';

import { FoldableSectionCheckbox } from 'components/layerManager/foldableSection';
import { type LayersVisibilityDictionary } from 'common/types';

interface SingleOptionListCheckboxProps {
  title: string;
  description?: string;
  classesVisibility: LayersVisibilityDictionary;
  firstAvailableLayer: string;
  updateClassesVisibility: (classesVisibility: LayersVisibilityDictionary) => void;
}

export const SingleOptionListCheckbox = ({
  title,
  description,
  classesVisibility,
  firstAvailableLayer,
  updateClassesVisibility,
}: SingleOptionListCheckboxProps) => {
  const [previouslyVisibleLayer, setPreviouslyVisibleLayer] = useState<string | null>(null);

  const isAnyLayerVisible = Object.entries(classesVisibility)
    .filter(([key, _]) => key !== 'idle')
    .some(([_, value]) => value);

  const toggleAllLayersVisibility = () => {
    if (isAnyLayerVisible) {
      setPreviouslyVisibleLayer(Object.keys(classesVisibility).find((key) => classesVisibility[key]) || null);
      updateClassesVisibility({});
    } else {
      updateClassesVisibility(
        previouslyVisibleLayer ? { [previouslyVisibleLayer]: true } : { [firstAvailableLayer]: true },
      );
    }
  };

  return (
    <FoldableSectionCheckbox
      variant={'NORMAL'}
      title={title}
      description={description || ''}
      isCheckboxChecked={isAnyLayerVisible}
      onCheckboxToggle={toggleAllLayersVisibility}
    />
  );
};
