/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import { Container } from 'ui';
import { SectionLayout } from './SectionLayout';

export const CommonCoverageSection = () => {
  return (
    <section>
      <Container pt={20} variant="VERTICAL_STRETCH">
        <SectionLayout title="degree of common coverage" description="">
          Available soon
          {/* <ContentWrapper>
            <CoverageCount>3.45%</CoverageCount>
            <div>
              <Description>
                of total area is overlapping:
                <br />
                Geothite, Kaolinite, Hematite, Epidote, Alunite, Illite, Jarosite, chlorite, sericite, smectite,
                montmorillonite, Magnetite
              </Description>
              <Metrics>
                <li>Total Area: 2 345.43 km2</li>
                <li>Common Area: 0.56 km2</li>
              </Metrics>
            </div>
          </ContentWrapper> */}
        </SectionLayout>
      </Container>
    </section>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;
`;

const CoverageCount = styled.span`
  font-family: Barlow;
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
`;

const Description = styled.p`
  margin-top: 0;
`;

const Metrics = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  gap: 12px;
`;
