export const MINERALS_DOMAIN_NAME = 'minerals';
export const MINERALIZATION_PATH_NAME = 'exploration';

export const PROSPECTING_TARGETS_LOCAL_STORAGE_KEY = 'RSOM_prospecting_targets';

export const LINEAMENT_DENSITY_LAYER_NAME = 'lineament_density';

export const PROSPECTING_OVERVIEW_LINES_OPACITY = 0.7;

export const HIDE_THRESHOLD_INPUT_TIMEOUT = 10_000;

export const HISTOGRAM_GRAPH_DEGREES_RANGE = [0, 30];
