import { useEffect, useState } from 'react';

import { formatDate } from 'common/utils/datetime';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { useTimelineService } from 'components/Timeline/timelineService';
import { REPORTS_SECOND_SCREENSHOT_DELAY } from 'domain/reports/constants';

interface InitialSingleDataset {
  screenshotSrc: string;
  timestamp: string;
  date: string;
}

export const useReportsExternalInitialDataService = (timestamp: string) => {
  const [firstInitialDataset, setFirstInitialDataset] = useState<InitialSingleDataset>();
  const [secondInitialDataset, setSecondInitialDataset] = useState<InitialSingleDataset>();

  const { secondTimestamp, changeTimestamp } = useTimelineService();

  const reportScreenshotSrc = useReportsStore.use.reportScreenshotSrc();
  const setTakeScreenshotFlag = useReportsStore.use.setTakeScreenshotFlag();

  useEffect(() => {
    setTakeScreenshotFlag(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportScreenshotSrc) {
      const dataset = {
        screenshotSrc: reportScreenshotSrc,
        timestamp,
        date: formatDate(timestamp),
      };

      if (firstInitialDataset) {
        setSecondInitialDataset(dataset);
      } else {
        setFirstInitialDataset(dataset);
        changeTimestamp(secondTimestamp);

        const timerId = setTimeout(() => {
          setTakeScreenshotFlag(true);
        }, REPORTS_SECOND_SCREENSHOT_DELAY);

        return () => {
          clearTimeout(timerId);
        };
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportScreenshotSrc]);

  const initialDataset =
    firstInitialDataset && secondInitialDataset
      ? {
          firstTimestamp: firstInitialDataset.timestamp,
          firstDate: firstInitialDataset.date,
          firstScreenshotSrc: firstInitialDataset.screenshotSrc,
          secondTimestamp: secondInitialDataset.timestamp,
          secondDate: secondInitialDataset.date,
          secondScreenshotSrc: secondInitialDataset.screenshotSrc,
        }
      : null;

  return initialDataset;
};
