import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { DraggableLayerManager } from 'components/layerManager/draggableLayerManager/DraggableLayerManager';
import { useDraggableLayerManagerContent } from 'components/layerManager/draggableLayerManager/useDraggableLayerManagerContent';
import { useLayerOrderStore } from 'components/layerManager/layersOrderStore';
import { useCommonSections } from 'components/layerManager/commonContent/useCommonSections';
import { LineamentsLayerManager } from './lineaments';
import { IndicesLayerManager } from './indices';
import { MineralsLayerManagerMtmf, MineralsLayerManagerSam, MineralsLayerManagerMl } from './minerals';
import { CompositesLayerManager, CompositesLayerManagerBeta } from './composites';
import { ClustersLayerManager } from './clusters';
import { EXPLORATION_LAYERS_KEYS } from './explorationDraggableLayersKeys';

export const ExplorationLayerManager = () => {
  const sectionsOrderRecord = useLayerOrderStore.use.sectionsOrderRecord();
  const setSectionsOrderRecord = useLayerOrderStore.use.setSectionsOrderRecord();

  const { spectralClassifiers: classifiers } = explorationRepository.useFetchSpectralClassifiers();

  const { commonSections } = useCommonSections();

  const { sections } = useDraggableLayerManagerContent([
    {
      id: EXPLORATION_LAYERS_KEYS.LINEAMENTS,
      name: 'lineaments',
      content: <LineamentsLayerManager id={EXPLORATION_LAYERS_KEYS.LINEAMENTS} />,
    },
    {
      id: EXPLORATION_LAYERS_KEYS.INDICES,
      name: 'indices',
      content: <IndicesLayerManager id={EXPLORATION_LAYERS_KEYS.INDICES} />,
    },
    {
      id: EXPLORATION_LAYERS_KEYS.SAM,
      name: 'sam',
      content: <MineralsLayerManagerSam {...{ classifiers }} id={EXPLORATION_LAYERS_KEYS.SAM} />,
    },
    {
      id: EXPLORATION_LAYERS_KEYS.MTMF,
      name: 'mtmf',
      content: <MineralsLayerManagerMtmf {...{ classifiers }} id={EXPLORATION_LAYERS_KEYS.MTMF} />,
    },
    {
      id: EXPLORATION_LAYERS_KEYS.ML,
      name: 'ml',
      content: <MineralsLayerManagerMl {...{ classifiers }} id={EXPLORATION_LAYERS_KEYS.ML} />,
    },
    {
      id: EXPLORATION_LAYERS_KEYS.CLUSTERS,
      name: 'clusters',
      content: <ClustersLayerManager id={EXPLORATION_LAYERS_KEYS.CLUSTERS} />,
    },
    {
      id: EXPLORATION_LAYERS_KEYS.COMPOSITES,
      name: 'composites',
      content: <CompositesLayerManager id={EXPLORATION_LAYERS_KEYS.COMPOSITES} />,
    },
    {
      id: EXPLORATION_LAYERS_KEYS.COMPOSITES_BETA,
      name: 'composites_beta',
      content: <CompositesLayerManagerBeta id={EXPLORATION_LAYERS_KEYS.COMPOSITES_BETA} />,
    },

    ...commonSections,
  ]);

  return <DraggableLayerManager {...{ sections, sectionsOrderRecord, setSectionsOrderRecord }} />;
};
