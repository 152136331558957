import styled from 'styled-components';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import {
  type LayersStatsDictionary,
  type LayerManagerSliderConfigLegacy,
  type LayersTresholdsDictionary,
  type LayersVisibilityDictionary,
} from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { ScrollbarWrapper } from 'ui';
import { SPECTRAL_CLASSIFIER_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useQueryParameter } from 'common/navigation/hooks';
import { getClassifierPrefix } from 'domain/exploration/helpers';
import { LayerManagerLegendItem } from './LegendItem';
import { LayerManagerThresholdSlider } from './ThresholdSlider';
import { useLayerManagerLegendOptionsService } from './LegendOptionsService';

interface LayerManagerLegendProps {
  areaId: number;
  options: LayerObject[];
  objectsVisibility: LayersVisibilityDictionary;
  toggleLayerVisibility: (name: string) => void;
  sliderConfig?: LayerManagerSliderConfigLegacy;
  isSliderWithInput?: boolean;
  mineralStats?: LayersStatsDictionary;
  layersTresholds?: LayersTresholdsDictionary;
  changeLayerTreshold?: (layerState: LayersTresholdsDictionary) => void;
  noBadges?: boolean;
  radio?: boolean;
  lowerClearance?: number;
  isDndView?: boolean;
  layersOrderRecord?: Record<string, number[]> | null;
  setLayersOrderRecord?: (key: string, layersOrder: number[]) => void;
}

export const LayerManagerLegendDndList = ({
  areaId,
  options,
  objectsVisibility,
  toggleLayerVisibility,
  sliderConfig,
  isSliderWithInput,
  mineralStats,
  layersTresholds,
  changeLayerTreshold,
  noBadges,
  radio,
  lowerClearance,
  isDndView,
  layersOrderRecord,
  setLayersOrderRecord,
}: LayerManagerLegendProps) => {
  const classifierQuery = useQueryParameter(SPECTRAL_CLASSIFIER_QUERY_PARAMETER);
  const classifierPrefix = getClassifierPrefix(classifierQuery, areaId);
  const { dndOptions, handleDragEnd } = useLayerManagerLegendOptionsService(
    options,
    layersOrderRecord,
    setLayersOrderRecord,
    isDndView,
  );

  const layerManagerLegendOptions = isDndView ? dndOptions : options;

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
    >
      <SortableContext items={dndOptions} strategy={verticalListSortingStrategy}>
        <StyledScrollbar lowerClearance={lowerClearance}>
          {layerManagerLegendOptions.map((option) => (
            <LayerManagerLegendItem
              key={option.id}
              option={option}
              handleObjectsVisibility={toggleLayerVisibility}
              objectVisibility={objectsVisibility[`${option.name}`]}
              noBadges={noBadges}
              radio={radio}
              isDndEnabled={isDndView && dndOptions.length > 1}
            >
              {sliderConfig && layersTresholds && changeLayerTreshold && (
                <LayerManagerThresholdSlider
                  layerObjectName={`${classifierPrefix}${option.name}`}
                  thresholds={layersTresholds}
                  changeLayerTreshold={changeLayerTreshold}
                  config={sliderConfig}
                  isSliderWithInput={isSliderWithInput}
                  layersStats={mineralStats}
                />
              )}
            </LayerManagerLegendItem>
          ))}
        </StyledScrollbar>
      </SortableContext>
    </DndContext>
  );
};

const StyledScrollbar = styled(ScrollbarWrapper)<{ lowerClearance?: number }>`
  max-height: ${({ lowerClearance }) =>
    lowerClearance ? `calc(100vh - ${lowerClearance}rem)` : `calc(100vh - 30.3rem)`};
  min-height: 39px;
  padding: 0 1rem;
  max-width: 248px;

  ::-webkit-scrollbar-track {
    margin: 4px 0;
  }
`;
