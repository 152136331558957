import { ROUTES } from 'common/navigation/routes';
import { PrivateRoute } from 'common/navigation/PrivateRoute';
import { Subsidence } from 'views/displacements/Subsidence';
import { useUserStore } from 'infrastructure/user/userStore';
import { Displacements } from 'views/displacements/Displacements';
import { DisplacementsActiveTab } from 'domain/displacements/enums';

export const useBuildDisplacementsRouteObject = () => {
  const { userPermissions } = useUserStore();

  return {
    path: ROUTES.displacements,
    element: <Displacements />,
    children: [
      {
        path: ROUTES.subsidence,
        index: true,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(DisplacementsActiveTab.SUBSIDENCE)}
            redirectPath={ROUTES.areasList}
          >
            <Subsidence />
          </PrivateRoute>
        ),
      },
    ],
  };
};
