import { useLocation } from 'react-router';

import { SegmentationReportsCreatorContent } from 'views/reports/creator/content/views/SegmentationReportsCreatorContent';
import { WaterAreaReportsCreatorContent } from 'views/reports/creator/content/views/WaterAreaReportsCreatorContent';
import { ExtractionReportsCreatorContent } from 'views/reports/creator/content/views/ExtractionReportsCreatorContent';
import { WaterQualityReportsCreatorContent } from 'views/reports/creator/content/views/WaterQualityReportsCreatorContent';
import { VegetationReportsCreatorContent } from 'views/reports/creator/content/views/VegetationReportsCreatorContent';
import { useTimelineService } from 'components/Timeline/timelineService';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { WaterActiveTab } from 'domain/water/enums';
import { ExtractionActiveTab } from 'domain/extraction/enums';

export const ReportsCreatorContent = ({ areaId }: { areaId: number }) => {
  const { pathname } = useLocation();
  const { timestamp, changeTimestamp } = useTimelineService();

  switch (true) {
    case pathname.includes(GreeneryActiveTab.SEGMENTATION):
      return <SegmentationReportsCreatorContent areaId={areaId} />;

    case pathname.includes(GreeneryActiveTab.VEGETATION):
      return timestamp && <VegetationReportsCreatorContent areaId={areaId} timestamp={timestamp} />;

    case pathname.includes(WaterActiveTab.SURFACE):
      return <WaterAreaReportsCreatorContent areaId={areaId} timestamp={timestamp} />;

    case pathname.includes(WaterActiveTab.QUALITY):
      return <WaterQualityReportsCreatorContent areaId={areaId} timestamp={timestamp} />;

    case pathname.includes(ExtractionActiveTab.TERRAIN):
      return <ExtractionReportsCreatorContent {...{ timestamp, changeTimestamp }} />;

    default:
      return null;
  }
};
