import { useExtractionObjectTypesService } from 'views/mine/extractionLayersService';
import { useExtractionLayersVisibility } from 'views/mine/useExtractionLayersVisibility';
import { useGetExtractionPolygonsUseCase } from 'views/mine/useGetExtractionPolygonsUseCase';
import { SingleDateReportsCreatorContent } from 'views/reports/creator/content/SingleDateReportsCreatorContent';

export const ExtractionSingleDateReportsCreatorContent = () => {
  const { segmentationLegendOptions } = useExtractionObjectTypesService();
  const { minePolygonsLoading: isLoading } = useGetExtractionPolygonsUseCase();

  const { classesVisibility: layersVisibility, toggleMultipleQueries: toggleLayerVisibility } =
    useExtractionLayersVisibility();

  const data = segmentationLegendOptions.map(({ id, name, label_name }) => ({
    id,
    label: label_name,
    checked: !!layersVisibility[name],
    onChange: () => toggleLayerVisibility(name),
  }));

  return <SingleDateReportsCreatorContent data={data} isLoading={isLoading} />;
};
