import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { type ValueAndLabel } from 'common/types';
import { useReplaceQueryParameters } from 'common/navigation/hooks';
import { WATER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { Select } from 'ui';
import { WaterActiveTab } from 'domain/water/enums';
import { useWaterIndicators } from './useWaterIndicators';

export const WaterIndicatorsSelect = () => {
  const { indicatorOptions, selectedIndicator, areIndicatorsLoading, changeWaterIndicator } = useWaterIndicators();

  const { pathname } = useLocation();
  const isQualityTabActive = pathname.includes(WaterActiveTab.QUALITY);
  const selectedIndicatorOption = indicatorOptions.find(({ value }) => value === selectedIndicator?.id);

  const replaceQueryParameter = useReplaceQueryParameters();

  const onWaterIndicatorChange = (option: ValueAndLabel): void => {
    if (option?.value) {
      changeWaterIndicator(option.value);
    }
  };

  useEffect(() => {
    if (!selectedIndicator && isQualityTabActive && indicatorOptions[0]?.value) {
      setTimeout(() => {
        replaceQueryParameter([
          {
            key: WATER_INDICATOR_QUERY_PARAMETER,
            value: String(indicatorOptions[0].value),
          },
        ]);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, JSON.stringify(indicatorOptions.map(({ value }) => value))]);

  return (
    <Select
      options={indicatorOptions}
      selectedOption={selectedIndicatorOption}
      initialStateLabel="Select Water Indicator"
      noOptionsLabel="No Water Indicators"
      name="water indicator selection"
      isFetching={areIndicatorsLoading}
      onChange={onWaterIndicatorChange}
    />
  );
};
