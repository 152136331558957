import { ReactComponent as MineIcon } from 'assets/svg/mine-icon.svg';
import { ReactComponent as TreeIcon } from 'assets/svg/tree-icon.svg';
import { ReactComponent as MountainsIcon } from 'assets/svg/mountains-icon.svg';
import { ReactComponent as WaterdropIcon } from 'assets/svg/water-drop-icon.svg';
import { ReactComponent as MineralizationIcon } from 'assets/svg/mineralization-icon.svg';
import { ReactComponent as TemperatureIcon } from 'assets/svg/thermometer-icon.svg';
import { AreaEnvironments } from 'common/navigation/constants';

export const AreaInfoEnvironmentsIcons = {
  [AreaEnvironments.MINE]: {
    icon: <MineIcon />,
    description: 'Mine',
    $iconSize: 12,
  },
  [AreaEnvironments.LAND_COVER]: {
    icon: <TreeIcon />,
    description: 'Land Cover',
    $iconSize: 11,
  },
  [AreaEnvironments.WATER]: {
    icon: <WaterdropIcon />,
    description: 'Water',
    $iconSize: 11,
  },
  [AreaEnvironments.DISPLACEMENT]: {
    icon: <MountainsIcon />,
    description: 'Displacement',
    $iconSize: 14,
  },
  [AreaEnvironments.MINERALIZATION]: {
    icon: <MineralizationIcon />,
    description: 'Surface mineralization',
    $iconSize: 20,
  },
  [AreaEnvironments.TEMPERATURE]: {
    icon: <TemperatureIcon />,
    description: 'Temperature',
    $iconSize: 7,
  },
};
