import { Select } from 'ui';
import { useWaterbodies } from './useWaterbodies';

export const WaterbodiesSelect = () => {
  const { waterBodyOptions, selectedWaterbody, waterBodiesLoading, changeWaterbody, resetWaterbody } = useWaterbodies();

  return (
    <Select
      options={waterBodyOptions}
      selectedOption={selectedWaterbody}
      initialStateLabel="all water bodies"
      noOptionsLabel="no waterbodies"
      name="waterbody selection"
      isFetching={waterBodiesLoading}
      onChange={changeWaterbody}
      reset={resetWaterbody}
    />
  );
};
