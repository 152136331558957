export const getDataRequestTooltipInfo = (
  information: string,
  isFeaturePremium: boolean,
  isSubscriptionLoading: boolean,
  isUserPremium: boolean,
): string => {
  const additionalInformation = `${
    isFeaturePremium
      ? isSubscriptionLoading
        ? 'Checking availability...\n\n'
        : isUserPremium
        ? ''
        : 'Available in Extended Exploration Subscription.\n\n'
      : ''
  }`;
  return `${additionalInformation}${information}`;
};

export const getDataRequestSpatialResolutionValue = (
  min: number,
  max: number,
  converter = 1,
  precision = 0,
): string => {
  return `${min === 0 ? '' : `${(min * converter).toFixed(precision)}-`}${(max * converter).toFixed(precision)}`;
};

export const getDataRequestModalSectionDescription = (isLoading: boolean, resultsQty: number): string => {
  return isLoading ? '' : `${!resultsQty ? 'No results' : `${resultsQty > 1 ? 'You can select more than one.' : ''}`}`;
};
