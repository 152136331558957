import { getColorScaleValuesHelper } from 'common/utils/color';
import { DateSelect } from 'ui';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { AppLayerManager } from 'components/layerManager/legacy';
import { ColorScale } from 'ui/colorScale';
import { GreeneryInfoArea } from 'views/greenery/components';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useVciResults } from 'views/greenery/vci/useVciResults';
import { useTimelineService } from 'components/Timeline/timelineService';
import { VCI_COLORMAP } from 'domain/greenery/colors';
import { VCI_DESCRIPTION, VCI_LIMITS } from 'domain/greenery/configs';

export const VCI = () => {
  const { timestamps, resultsLoading: areTimestampsLoading } = useVciResults();
  const { timestamp, changeTimestamp } = useTimelineService();

  const areReportsActive = useGetAreReportsActiveUseCase();

  const colorScaleLabels = getColorScaleValuesHelper(VCI_LIMITS.min, VCI_LIMITS.max, 2).map(String);

  return (
    <AreaDetailsLayout
      legend={<ColorScale colormap={VCI_COLORMAP} labels={colorScaleLabels} unit="%" segmented />}
      toolbarContent={
        <>
          <GreeneryInfoArea>
            {VCI_DESCRIPTION} <br />
            <br />
            Current selection:
            <br />
            05.2023 compared to 2015-2022
          </GreeneryInfoArea>
          {!areReportsActive && (
            <DateSelect {...{ timestamps, timestamp, changeTimestamp }} areTimestampsLoading={areTimestampsLoading} />
          )}
        </>
      }
      toolboxContent={!areReportsActive && <LayerManagerControlButton />}
    >
      {!areReportsActive && <AppLayerManager />}
    </AreaDetailsLayout>
  );
};
