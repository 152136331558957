import { useEffect, useState } from 'react';
import { circleMarker } from 'leaflet';
import { useMap } from 'react-leaflet';
import { type Geometry } from 'esri-leaflet';
import { type LeafletMouseEvent, type CircleMarker, type Map } from 'leaflet/index';

import { standarizeLatLng } from 'common/utils/map';
import { simpleRound } from 'common/utils';
import { type TooltipData, type GeoJSONFeature, type LeafletLatLng, type LeafletLayer } from 'common/types/mapData';
import { DEFAULT_MAIN_GRAPH_PRECISION } from 'common/constants';
import { useGraphStore } from 'components/graph/graphStore';
import { type OpenedMarkerData } from 'domain/displacements/types';
import { unhoverMarkerStyle, hoverMarkerStyle, DISPLACEMENTS_COLORMAP } from 'domain/displacements/constants';
import { getCumulativeDisplacement, getDygraphInfo } from 'domain/displacements/helpers/parserGeoJsonValue';
import { buildColorScaleHelper } from 'domain/displacements/helpers/buildColorscaleHelper';
import { useDisplacementsGraphData } from './useDisplacementsGraphData';

const domainLevel = 15;
const gradientChroma = buildColorScaleHelper(DISPLACEMENTS_COLORMAP, domainLevel);

export const useDisplacementsMarkersService = (openedMarkerData: string | undefined) => {
  const [detailedTooltip, setDetailedTooltip] = useState<TooltipData | undefined>();
  const { setGraphData, setPointData } = useDisplacementsGraphData();
  const map: Map = useMap();
  const chosenPoint = useGraphStore.use.chosenPoint();

  const getMarkerColor = (feature: OpenedMarkerData): string | undefined => {
    const { dygraphInfo } = getDygraphInfo(feature);
    const cumulativeDisplacement = getCumulativeDisplacement(dygraphInfo);
    if (cumulativeDisplacement || cumulativeDisplacement === 0) {
      return gradientChroma(cumulativeDisplacement).toString();
    }
  };

  const onMarkerClick = (event: LeafletMouseEvent, feature: GeoJSONFeature, _layer: LeafletLayer): void => {
    const latlng = event?.latlng || standarizeLatLng((feature?.geometry as Geometry)?.coordinates);

    if (!latlng) {
      return;
    }

    map.flyTo(latlng);
    setGraphData(feature as OpenedMarkerData);
    setPointData(feature as OpenedMarkerData);

    clearClickedMarkers();

    const clickedMarkerShadow = circleMarker(latlng, {
      radius: 16,
      fillColor: 'black',
      color: 'black',
      fillOpacity: 0.4,
      weight: 0,
      zIndexOffset: 1,
      pane: 'clickedMarkerPane',
    }) as CircleMarker;
    clickedMarkerShadow.addTo(map);

    const clickedMarker = createCircleMarker(latlng, {
      ...hoverMarkerStyle,
      radius: 8,
      riseOnHover: true,
      fillColor: event.target.options.fillColor,
      pane: 'clickedMarkerPane',
    });
    clickedMarker.addTo(map);
  };

  const createCircleMarker = (latlng: LeafletLatLng, options: {}): CircleMarker => {
    return circleMarker(latlng, {
      radius: 4,
      fillOpacity: 1,
      fillColor: 'black',
      color: 'white',
      ...options,
    });
  };

  const clearClickedMarkers = (): void => {
    map.eachLayer((layer) => {
      if (layer.options.pane === 'clickedMarkerPane') {
        map.removeLayer(layer);
      }
    });
  };

  const onMarkerMouseIn = (event: LeafletMouseEvent, _feature: GeoJSONFeature, _layer: LeafletLayer): void => {
    const openedMarkerData: OpenedMarkerData = event.target.feature;
    const { dygraphInfo } = getDygraphInfo(openedMarkerData);
    const cumulativeDisplacement = getCumulativeDisplacement(dygraphInfo);
    event?.target?.setStyle && event.target.setStyle({ ...event.target.options, ...hoverMarkerStyle });

    setDetailedTooltip({
      lat: event.latlng.lat,
      lng: event.latlng.lng,
      id: event.latlng.lat + event.latlng.lng,
      label: '',
      value:
        typeof cumulativeDisplacement === 'number'
          ? simpleRound(cumulativeDisplacement, DEFAULT_MAIN_GRAPH_PRECISION)
          : undefined,
    });
  };

  const onMarkerMouseOut = (event: LeafletMouseEvent, layer: LeafletLayer): void => {
    layer.closePopup();
    event?.target?.setStyle && event.target.setStyle({ ...event.target.options, ...unhoverMarkerStyle });
    setDetailedTooltip(undefined);
  };

  useEffect(() => {
    if (!openedMarkerData || !chosenPoint) {
      clearClickedMarkers();
    }
    // eslint-disable-next-line
  }, [openedMarkerData, chosenPoint]);

  return { onMarkerClick, onMarkerMouseIn, onMarkerMouseOut, createCircleMarker, getMarkerColor, detailedTooltip };
};
