import { useQueryParameter, useAoiNumber } from 'common/navigation/hooks';
import { DATE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { DEFAULT_DATE_FORMAT_DASH } from 'common/constants/datetime';
import { formatDate } from 'common/utils/datetime';
import { ShareModalContent } from 'components/downloadModal/legacyShareModal';
import { displacementsRepository } from 'infrastructure/displacements/displacementsRepository';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useLayerManagerStore } from 'components/layerManager';
import { DownloadModal } from 'components/downloadModal/DownloadModal';
import { DisplacementsActiveTab } from 'domain/displacements/enums';

export const DisplacementsShareModal = () => {
  const areaId = useAoiNumber();

  const date = useQueryParameter(DATE_QUERY_PARAMETER);

  const kmzId = useDisplacementsStore.use.kmzId();
  const kmzUrl = useDisplacementsStore.use.kmzUrls()?.find((kmz) => kmz.id === kmzId)?.url;

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const fileName = `${areaOfInterestDetails?.id}_${DisplacementsActiveTab.SUBSIDENCE}${
    date ? `_${formatDate(date, DEFAULT_DATE_FORMAT_DASH)}` : ''
  }`;

  const { getSignedKmzUrl } = displacementsRepository.useGetSignedKmzUrl();

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();

  const getReadyLinks = async () => {
    if (kmzId) {
      const signedUrl = await getSignedKmzUrl(kmzId);
      return [{ id: '1', url: signedUrl!, name: fileName, label: fileName.replaceAll('_', ' '), extension: 'kmz' }];
    } else {
      throw new Error('No kmz id');
    }
  };

  return (
    <DownloadModal maxWidth={1000} title="Share & Download">
      <ShareModalContent
        fileLinks={isDataLayerVisible ? [kmzUrl!] : []}
        fileType="kmz"
        fileName={fileName}
        getReadyLinks={getReadyLinks}
      />
    </DownloadModal>
  );
};
