import { useQueryParameter, useReplaceQueryParameters, useDeleteQueryParameters } from 'common/navigation/hooks';
import { WATER_DATA_PROVIDER_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { waterRepository } from 'infrastructure/water/waterRepository';

export const useDataProviders = () => {
  const { providers, areProvidersLoading } = waterRepository.useFetchProviders();

  const selectedProviderId = useQueryParameter(WATER_DATA_PROVIDER_QUERY_PARAMETER);
  const selectedProvider = providers.find(({ id }) => selectedProviderId === String(id));

  const replaceQueryParameter = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeWaterProvider = (value: string | number | null): void => {
    if (value) {
      replaceQueryParameter([
        {
          key: WATER_DATA_PROVIDER_QUERY_PARAMETER,
          value: String(value),
        },
      ]);
    } else {
      deleteQueryParameters([WATER_DATA_PROVIDER_QUERY_PARAMETER]);
    }
  };

  return {
    providers,
    selectedProvider,
    areProvidersLoading,
    changeWaterProvider,
  };
};
