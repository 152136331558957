import { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Cross } from 'assets/svg/rounded-cross.svg';
import { ReactComponent as Check } from 'assets/svg/check-icon.svg';

export const SELECTION_BUTTON_HEIGHT = 40;
type SelectionButtonVariant = 'DEFAULT' | 'SAVING' | 'SAVED';

interface SelectionButtonProps {
  variant?: SelectionButtonVariant;
  disabled?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
}

export const SelectionButton = ({ variant = 'DEFAULT', disabled = false, onClick }: SelectionButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Wrapper
      selected={variant !== 'DEFAULT'}
      disabled={disabled}
      onClick={onClick}
      allowClicking={variant === 'SAVED'}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      {isHovering && variant === 'SAVED'
        ? 'remove selection'
        : variant === 'DEFAULT'
        ? 'confirm selection'
        : 'selection made'}
      {variant === 'SAVED' && (isHovering ? <CrossIcon /> : <CheckIcon />)}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ selected: boolean; disabled: boolean; allowClicking: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  height: ${SELECTION_BUTTON_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  color: ${({ theme }) => theme.color.white};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.midGray};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}

  ${({ selected, allowClicking }) =>
    selected &&
    !allowClicking &&
    css`
      background-color: ${({ theme }) => theme.color.selectGray};
      pointer-events: none;
    `}
`;

const CheckIcon = styled(Check)`
  width: 16px;
  height: 16px;
`;

const CrossIcon = styled(Cross)`
  width: 14px;
  height: 14px;
`;

SelectionButton.displayName = 'SelectionButton';
