import { useDeleteQueryParameters, useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { MAP_STYLE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { BaseMapVariant } from 'ui/map/baseMap/enums';
import { MAP_STYLE_DEFAULT } from './constants';

export const useMapStyle = () => {
  const mapStyleParam = useQueryParameter(MAP_STYLE_QUERY_PARAMETER);
  const replaceQueryParameters = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeMapStyle = (mapStyle: BaseMapVariant | null): void => {
    setTimeout(() => {
      mapStyle
        ? replaceQueryParameters([{ key: MAP_STYLE_QUERY_PARAMETER, value: mapStyle }])
        : deleteQueryParameters([MAP_STYLE_QUERY_PARAMETER]);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isMapStyleValid = (mapStyle: any): mapStyle is BaseMapVariant => {
    return Object.values(BaseMapVariant).includes(mapStyle);
  };

  const mapStyle = isMapStyleValid(mapStyleParam) ? mapStyleParam : MAP_STYLE_DEFAULT;

  return {
    changeMapStyle,
    mapStyle,
  };
};
