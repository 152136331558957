import { ReactComponent as MountainsIcon } from 'assets/svg/mountains-icon.svg';
import { AreaPages } from 'common/navigation/constants';
import { useBuildNavigationItem } from 'views/areas/areaDetails/useBuildNavigationItem';
import { DISPLACEMENTS_VIEW_CONFIG } from 'domain/displacements/constants';

export const useBuildDisplacementsNavItem = () => {
  return useBuildNavigationItem({
    config: DISPLACEMENTS_VIEW_CONFIG,
    label: 'ground deformation',
    icon: <MountainsIcon />,
    iconSize: 18,
    type: AreaPages.DISPLACEMENT,
  });
};
