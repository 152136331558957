import styled from 'styled-components';

import { type ColormapDictionary } from 'common/types';
import { Tooltip } from 'ui/atoms/tooltip';
import { parseColormap } from './helpers';

interface ColorScaleBarProps {
  colormap: ColormapDictionary;
  segmented?: boolean;
  tooltipLabels?: string[];
  height?: number;
  opacity?: number;
}

export const ColorScaleBar = ({ colormap, segmented, tooltipLabels, height = 16, opacity }: ColorScaleBarProps) => {
  const { colors, linearGradient } = parseColormap(colormap);

  return (
    <StyledColorScaleBar $opacity={opacity}>
      {segmented ? (
        <SegmentsScale>
          {colors.map((color, index) => (
            <Tooltip
              key={index}
              content={
                tooltipLabels ? <h4 style={{ fontSize: '12px', fontWeight: 400 }}>{tooltipLabels[index]}</h4> : null
              }
              placement="bottom"
              offset={[0, 0]}
              disabled={!tooltipLabels}
            >
              <Segment color={color} height={height} />
            </Tooltip>
          ))}
        </SegmentsScale>
      ) : (
        <GradientScale linearGradient={linearGradient} height={height} />
      )}
    </StyledColorScaleBar>
  );
};

const StyledColorScaleBar = styled.div<{ $opacity?: number }>`
  min-width: 22px;
  border-radius: 4px;
  overflow: hidden;
  opacity: ${({ $opacity }) => $opacity};
`;

const SegmentsScale = styled.div`
  display: flex;
`;

const Segment = styled.div<{ color: string; height: number }>`
  flex-grow: 1;
  height: ${({ height }) => height}px;
  background-color: ${({ color }) => color};
`;

const GradientScale = styled.div<{ height: number; linearGradient: string }>`
  ${({ height, linearGradient }) => {
    return `
      height: ${height}px;
      background: linear-gradient(90deg, ${linearGradient});
    `;
  }};
`;

ColorScaleBar.displayName = 'ColorScaleBar';
