import { useQueryParameter, useQueryMultipleToggle } from 'common/navigation/hooks';
import { FILTERS_TAG_QUERY_PARAM_NAME } from 'common/navigation/queryParams';
import { type TagData } from 'ui/molecules/tagList/TagList';
import { useGetAllAreasTags } from 'views/areas/areasList/getAllAreasTags';

export const useGetAreasTagSet = (): TagData[] => {
  const { toggleMultipleQueries } = useQueryMultipleToggle(FILTERS_TAG_QUERY_PARAM_NAME);
  const tagQuery = useQueryParameter(FILTERS_TAG_QUERY_PARAM_NAME);

  const uniqueTags = useGetAllAreasTags();

  const tagSet = uniqueTags.map((tag, index) => {
    return {
      id: index,
      text: tag,
      checked: !tagQuery?.split('-').includes(tag),
      onClick: () => toggleMultipleQueries(tag),
    };
  });

  return tagSet;
};
