import { useEffect } from 'react';

import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { VHR_INITIALY_AVAILABLE_LAYERS } from 'domain/greenery/configs';

export const vhrDataService = {
  useFetchVhrObjects: () => {
    const { vhrObjectsData, isLoading } = greeneryRepository.useFetchVhrObjects();

    // TODO: restore when 'active' property objects will be implemented on backend
    const layersVisibility = useLayerManagerStore.use.layersVisibility();
    const updateLayersVisibility = useLayerManagerStore.use.updateLayersVisibility();

    useEffect(() => {
      updateLayersVisibility(
        Object.fromEntries(
          vhrObjectsData.map(({ name }) => [
            name,
            layersVisibility[name] || VHR_INITIALY_AVAILABLE_LAYERS.includes(name) || false,
          ]),
        ),
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vhrObjectsData]);
    return { vhrObjectsData, isLoading };
  },

  useFetchVhrCogUrl: () => {
    const { vhrCogUrl, isLoading } = greeneryRepository.useGetVhrSegmentationCogUrl();

    return { vhrCogUrl, isLoading };
  },
};
