import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { SIDEBAR_MAX_WIDTH, SIDEBAR_MIN_WIDTH, CREATOR_HEIGHT } from 'common/constants';
import { formatDate } from 'common/utils/datetime';
import { useDeleteQueryParameters } from 'common/navigation/hooks';
import { REPORT_TYPE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { Creator } from 'ui/creator';
import { CreatorOptionsColumn } from 'ui/creator/creatorOptionsColumn';
import { InfiniteProgressBar } from 'ui/atoms/infiniteProgressBar';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { Button } from 'ui/atoms/button';
import { HideShowButton } from 'ui/atoms/hideShowButton';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useReportsCreatorOptionsService } from 'views/reports/creator/services/reportsCreatorOptionsService';
import { ReportsCreatorDates } from 'views/reports/creator/dates/ReportsCreatorDates';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { ReportsCreatorContent } from 'views/reports/creator/content/ReportsCreatorContent';
import { ReportsExternalData } from 'views/reports/external/data/ReportsExternalData';
import { ReportsCreatorMapOptions } from 'views/reports/creator/components/ReportsCreatorMapOptions';
import { ReportsCreatorOptionsColumnContent } from 'views/reports/creator/components/ReportsCreatorOptionsColumnContent';
import { useTimelineService } from 'components/Timeline/timelineService';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { MINE_EXTRACTION_PATH_NAME } from 'domain/extraction/constants';

export const ReportsCreator = ({ areaId }: { areaId: number }) => {
  const { pathname } = useLocation();
  const { timestamp } = useTimelineService();

  const { areaOfInterestDetails: areaDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const { typeOptions, functionalityOptions } = useReportsCreatorOptionsService();
  const { activeFunctionality, isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const [isCreatorOpen, setIsCreatorOpen] = useState(true);

  const secondDateReportsSegmentationDataset = useReportsStore.use.secondDateReportsSegmentationDataset();
  const secondDateReportsExtractionDataset = useReportsStore.use.secondDateReportsExtractionDataset();
  const isGenerateReportButtonEnabled = useReportsStore.use.isGenerateReportButtonEnabled();
  const isReportGenerating = useReportsStore.use.isReportGenerating();
  const isReportsSecondDateSelected = useReportsStore.use.isReportsSecondDateSelected();
  const setIsReportGenerating = useReportsStore.use.setIsReportGenerating();
  const setIsGenerateReportButtonEnabled = useReportsStore.use.setIsGenerateReportButtonEnabled();
  const setTakeScreenshotFlag = useReportsStore.use.setTakeScreenshotFlag();
  const clearReports = useReportsStore.use.clearReports();

  const deleteQueryParameters = useDeleteQueryParameters();

  const isDoubleDataColumnType =
    isCompareDatesType &&
    (pathname.includes(GreeneryActiveTab.SEGMENTATION) || pathname.includes(MINE_EXTRACTION_PATH_NAME));

  const isGenerateButtonEnabled = isCompareDatesType
    ? (secondDateReportsSegmentationDataset && pathname.includes(GreeneryActiveTab.SEGMENTATION)) ||
      (secondDateReportsExtractionDataset && pathname.includes(MINE_EXTRACTION_PATH_NAME)) ||
      (isGenerateReportButtonEnabled && isReportsSecondDateSelected)
    : isGenerateReportButtonEnabled;

  const isAnyFunctionalitySelected = functionalityOptions?.some((item) => item.selected);

  const isCreatorFullWidth = useSidebarStore.use.isSidebarFolded();

  const handleGenerateButtonClick = () => {
    setIsReportGenerating(true);
    setTakeScreenshotFlag(true);
  };

  const handleResetButtonClick = () => {
    deleteQueryParameters([REPORT_TYPE_QUERY_PARAMETER]);
    clearReports();
  };

  useEffect(() => {
    setIsGenerateReportButtonEnabled(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFunctionality]);

  return (
    <>
      <Wrapper isOpen={isCreatorOpen} fullWidth={isCreatorFullWidth}>
        <UpperLabelsWrapper>
          {!isReportGenerating && (
            <>
              <UpperLabel bold>Select a report type to see the next steps.</UpperLabel>
              {timestamp && <UpperLabel>Current map preview for: {formatDate(timestamp)}</UpperLabel>}
            </>
          )}
        </UpperLabelsWrapper>
        <Creator>
          {isReportGenerating ? (
            <GeneratingLoader>
              Generating Report ...
              <ProgressBarWrapper>
                <InfiniteProgressBar />
              </ProgressBarWrapper>
            </GeneratingLoader>
          ) : (
            <>
              {typeOptions && (
                <CreatorOptionsColumn width={250}>
                  <ReportsCreatorOptionsColumnContent options={typeOptions} />
                </CreatorOptionsColumn>
              )}
              {functionalityOptions && (
                <CreatorOptionsColumn>
                  <ReportsCreatorOptionsColumnContent options={functionalityOptions}>
                    <DatesWrapper>
                      <ReportsCreatorDates />
                    </DatesWrapper>
                  </ReportsCreatorOptionsColumnContent>
                </CreatorOptionsColumn>
              )}
              {isAnyFunctionalitySelected && <ReportsCreatorContent areaId={areaId} />}
              {isGenerateButtonEnabled && !isDoubleDataColumnType && <ReportsCreatorMapOptions />}
            </>
          )}
        </Creator>
        <ButtonsWrapper>
          <Button variant="SECONDARY" onClick={handleResetButtonClick}>
            reset selection
          </Button>
          <Button onClick={handleGenerateButtonClick} disabled={!isGenerateButtonEnabled || isReportGenerating}>
            generate report
          </Button>
        </ButtonsWrapper>
        <HideShowButtonWrapper>
          <HideShowButton
            isShown={isCreatorOpen}
            showLabel="show selection"
            hideLabel="hide selection"
            onClick={() => setIsCreatorOpen((prev) => !prev)}
          />
        </HideShowButtonWrapper>
      </Wrapper>
      {isReportGenerating && areaDetails && <ReportsExternalData areaDetails={areaDetails} />}
    </>
  );
};

const Wrapper = styled.div<{ isOpen: boolean; fullWidth: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) =>
    fullWidth ? `calc(100vw - ${SIDEBAR_MIN_WIDTH}px)` : `calc(100vw - ${SIDEBAR_MAX_WIDTH}px)`};
  min-width: 600px;
  height: ${CREATOR_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.mineShaftDarkBlur};
  color: ${({ theme }) => theme.color.white};
  padding: 22px 30px;
  z-index: 500;
  margin-top: auto;
  margin-bottom: ${({ isOpen }) => (isOpen ? 0 : -CREATOR_HEIGHT)}px;
  transition: ${({ theme }) => theme.transitionFunction.main};
  transition-property: margin-bottom, width;
`;

const UpperLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 18px;
`;

const UpperLabel = styled.div<{ bold?: boolean }>`
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  line-height: 18px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  margin-left: auto;
`;

const HideShowButtonWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 50px;
`;

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GeneratingLoader = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
`;

const ProgressBarWrapper = styled.div`
  margin-top: 50px;
  width: 800px;
  max-width: 80%;
`;
