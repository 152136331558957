import { type IndicatorStats, type ValueAndLabel, type ExtendedIndicator } from 'common/types';
import { type BaseApiListResponse, type BaseQueryResponse, type CogResult } from 'common/types/api';
import {
  type GenericTooltipInputResult,
  type Boundary,
  type CenterOfImageCoords,
  type DefaultView,
} from 'common/types/mapData';
import { type Datetime } from 'common/types/time';

export interface WaterIndicator {
  id: number;
  indicator_name: string;
  min_value: number;
  max_value: number;
  unit: string;
  colormap: { [key: string]: string };
}

export interface WaterbodySelectData {
  options: ValueAndLabel[] | undefined;
  selectedOption: ValueAndLabel | undefined;
  initialStateLabel: string;
  noOptionsLabel: string;
  title: string;
  isFetching: boolean;
}

export interface WaterAreaItem {
  img_date: string;
  id: number;
  area: number;
}
export interface WaterAreaItemApiResponse extends BaseApiListResponse {
  results: WaterAreaItem[];
}

export interface WaterViewOptionsSelectData {
  options: ValueAndLabel[];
  selectedOption: ValueAndLabel;
}

export interface WaterTimelineData {
  timestamps: string[];
  timestamp: string | null | undefined;
  visible: boolean;
}

export interface WaterGraphResult {
  date: string;
  id: number;
  value: number;
}

export interface WaterComparatorData {
  comparedDatesOptions: string[];
  firstComparedDate: string | null | undefined;
  isFetching: boolean;
}

export interface WaterAreaResult {
  id: number;
  img_date: string;
  area: number;
  waterbody_id: number;
  aoi_id: number;
  provider?: ProviderType;
  cog_url?: string;
}

export interface WaterAreaRemappedResult extends WaterAreaResult {
  provider: ProviderType;
}

export interface WaterMask extends WaterAreaResult {
  url: string;
}

export interface WaterAreaResultsApiResponse extends BaseApiListResponse {
  results: WaterAreaResult[];
}

export interface WaterBody {
  id: number;
  name: string;
  type_id: number;
}

export interface WaterBodiesApiResponse extends BaseApiListResponse {
  results: WaterBody[];
}

export interface WaterQualityResult extends IndicatorStats {
  id: number;
  img_date: string;
  indicator_id: number;
  waterbody_id: number;
  indicator_file_path: string;
  provider?: ProviderType;
}

export interface WaterQualityReamappedResult extends WaterQualityResult, GenericTooltipInputResult {
  url: string;
  label: string;
  provider: ProviderType;
}

export interface WaterQualityResultsApiResponse extends BaseApiListResponse {
  results: WaterQualityResult[];
}

export interface WaterBodyResultRawApiResponse extends BaseApiListResponse {
  results: number[][];
}

export interface WaterBodyResultCogApiResponse extends BaseApiListResponse {
  data: CogResult;
}

export interface WaterBodyMaskCogApiResponse extends BaseApiListResponse {
  data: WaterMask;
}

export interface WaterBodyResultMetaResponse {
  coords_reference_system: string;
  boundary_box: Boundary;
  center_of_image_coords: CenterOfImageCoords;
}

export interface WaterIndicatorsApiResponse extends BaseApiListResponse {
  results: WaterIndicator[];
}

export interface WaterDate {
  id: number;
  img_date: Datetime;
  aoi_id: number;
  waterbody_id: number;
}

export interface WaterDatesApiResponse extends BaseApiListResponse {
  results: WaterDate[];
}

export interface WaterDatesQueryApiResponse extends BaseQueryResponse {
  data: WaterDatesApiResponse;
}

export interface WaterBodyDefaultView extends DefaultView {
  waterbody_id: number;
}

export interface IWaterBodyCog extends CogResult {
  waterBodyId: number;
}

export interface WaterQualityResultsDictionary {
  [index: string]: WaterQualityResult;
}

export interface WaterAreaResultsDictionary {
  [index: string]: WaterAreaResult;
}

export interface WaterMaskUrl {
  url: string;
}

export interface CogUrlQueryApiResponse extends BaseQueryResponse {
  data: WaterMaskUrl;
}

export interface WaterAreaComparatorResultIds {
  firstResultId: number | undefined;
  secondResultId: number | undefined;
}

export interface MoistureResult {
  id: number;
  aoi_id: number;
  aoi_name: string;
  indicator_id: number;
  img_date: string;
  url: string;
  name: string;
  label_name: string;
  color: string;
}

export interface MoistureResultsApiResponse extends BaseApiListResponse {
  results: MoistureResult[];
}

export interface MoistureIndicator {
  id: number;
  indicator_name: string;
  min_value: number | null;
  max_value: number | null;
  unit: string;
  colormap: { [key: string]: string };
  custom_labels: string[] | null;
  precision: number;
  description: string;
  is_multi_layers: boolean;
}

export type MoistureCommonProps = {
  indicator: MoistureIndicator;
  layerName: string;
  min: number;
  max: number;
  sourceUrl: string;
};

export interface SnowCoverIndicator extends ExtendedIndicator {
  indicator_label: string;
  source_url: string;
}

export interface SnowCoverIndicatorsApiResponse extends BaseApiListResponse {
  results: SnowCoverIndicator[];
}

export interface SnowCoverResult {
  id: number;
  aoi_name: string;
  img_date: string;
  img_url: string;
}

export interface SnowCoverResultsApiResponse extends BaseApiListResponse {
  results: SnowCoverResult[];
}

export enum ProviderType {
  sentinel_2 = 'sentinel_2',
  planet_scope = 'planet_scope',
}

export interface Provider {
  id: number;
  name: ProviderType;
  label: string;
}

export interface ProvidersApiResponse extends BaseApiListResponse {
  results: Provider[];
}
