import { type ReactNode, type ChangeEventHandler, type FocusEventHandler } from 'react';
import styled from 'styled-components';

import { type ValueAndLabel } from 'common/types';
import { InfoPoint, Input, Select } from 'ui/atoms';

interface NewAreaNameTileProps {
  header?: string;
  areaName: string;
  setAreaName: ChangeEventHandler<HTMLInputElement>;
  onAreaNameBlur?: FocusEventHandler<HTMLInputElement>;
  error?: string;
  selectMinWidth?: number;
  selectOptions?: ValueAndLabel[];
  selectedOption?: ValueAndLabel;
  initialStatePrompt?: string;
  onOptionChange?: (value: ValueAndLabel) => void;
  tooltipContent?: ReactNode;
  disabled?: boolean;
  isDropdownDisabled?: boolean;
}

export const NewAreaNameTile = ({
  header,
  areaName,
  setAreaName,
  onAreaNameBlur,
  error,
  selectMinWidth,
  selectOptions,
  selectedOption,
  initialStatePrompt,
  onOptionChange,
  tooltipContent,
  disabled,
  isDropdownDisabled,
}: NewAreaNameTileProps) => (
  <Wrapper>
    {header && <Header>{header}</Header>}
    <InputWrapper>
      <Input
        $variant="SECONDARY"
        data-testid="input-area-name"
        value={areaName}
        onChange={setAreaName}
        onBlur={onAreaNameBlur}
        placeholder="Enter Area Name Here"
        $error={!!error}
        disabled={disabled}
      />
      <Error>{error || ''}</Error>
    </InputWrapper>
    {selectOptions && (
      <SelectWrapper>
        <Select
          options={selectOptions}
          selectedOption={selectedOption}
          onChange={onOptionChange}
          initialStateLabel={initialStatePrompt}
          minWidth={selectMinWidth}
          maxHeight={80}
          disabled={isDropdownDisabled || disabled}
          name="prospecting timeframe selection"
        />
        <InfoPoint name="timeframe" tooltipContent={tooltipContent} />
      </SelectWrapper>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  max-width: 230px;
  background-color: ${({ theme }) => theme.color.selectGray};
  padding: 20px;
  border-right: 1px solid ${({ theme }) => theme.color.mineShaftDark};
`;

const Header = styled.div`
  font-family: 'Barlow Condensed';
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
`;

const InputWrapper = styled.div`
  margin: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
`;

const Error = styled.p`
  color: ${({ theme }) => theme.color.errorPink};
  font-size: 12px;
  line-height: 1.3;
  margin-top: 2px;
  height: 16px;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  gap: 8px;
`;

NewAreaNameTile.displayName = 'NewAreaNameTile';
