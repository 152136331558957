/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDOMServer from 'react-dom/server';
import { cloneElement, type ReactElement } from 'react';

import { type ValueAndLabel } from 'common/types';

import { SCALE_DISTANCES } from 'common/constants';
import { type MarksInterface } from 'ui/slider/Slider';

export const alterArrayElements = (array: any) => {
  const alterSubArrayElements = (item: any): any => {
    return Array.isArray(item) && Array.isArray(item[0])
      ? item.map((sub) => alterSubArrayElements(sub))
      : [item[1], item[0]];
  };

  return array.map(alterSubArrayElements);
};

export const reorderArray = (array: any[], from: number, to: number): any[] => {
  const array_ = [...array];
  array_.splice(to, 0, array_.splice(from, 1)[0]);
  return array_;
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeAllFirstLetters = (str: string) => {
  const words = str.split(' ');
  const capitalizedWords = words.map(capitalizeFirstLetter);
  return capitalizedWords.join(' ');
};

export const getScaleDistance = (distance: number) => {
  const scaleDistance = SCALE_DISTANCES.find((item) => item <= distance);
  return scaleDistance ? scaleDistance : SCALE_DISTANCES[SCALE_DISTANCES.length - 1];
};

export const getScaleLabel = (distance: number) => (distance < 1000 ? `${distance} m` : `${distance / 1000} km`);

export const replaceSquareMetersWithHectares = (areaInM2: number | undefined | null): number | null => {
  const areaInHa = typeof areaInM2 === 'number' ? areaInM2 * 0.0001 : null;

  return areaInHa;
};

export const restrictValue = (value: number | number[], max: number, min: number, precision: number) => {
  if (typeof value === 'number') {
    const newValue = value > max ? max : value < min ? min : value;

    return Number(newValue.toFixed(precision));
  }

  const newMin = value[0] < min ? min : value[0];
  const newMax = value[1] > max ? max : value[1];

  return [Number(newMin.toFixed(precision)), Number(newMax.toFixed(precision))];
};

export const isValueInsideRange = (
  value: number | number[],
  isValueDouble: boolean,
  rangeMin: number,
  rangeMax: number,
  step: number,
  isMinRestricted: boolean,
  isMaxRestricted: boolean,
) => {
  const realMin = isMinRestricted ? rangeMin + step : rangeMin;
  const realMax = isMaxRestricted ? rangeMax - step : rangeMax;

  return (
    (isValueDouble && Array.isArray(value) && value.length === 2 && value[0] >= realMin && value[1] <= realMax) ||
    (!isValueDouble && typeof value === 'number' && value >= realMin && value <= realMax)
  );
};

export const debounce = <A>(callback: any, wait: number = 0) => {
  let timeoutId: string | number | null = null;

  return (...args: A[]) => {
    window.clearTimeout(timeoutId || undefined);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export const minBy = <OBJ>(arr: OBJ[], func: (v: any) => number): OBJ | undefined => {
  const min = Math.min(...arr.map(func));
  return arr.find((item) => func(item) === min);
};

export const take = <T>(arr: T[], qty = 1): T[] => [...arr].splice(0, qty);

export const mapIndicatorsToOptions = <T>(
  indicators: (T & { id: number; indicator_name: string })[],
): ValueAndLabel[] => {
  return indicators.map((i) => ({
    value: i.id,
    label: i.indicator_name,
  }));
};

export const getRandomlySelectedItemFromArray = <T>(array: T[]): T => {
  const randomIndex = Math.floor(Math.random() * array?.length);
  return array[randomIndex];
};

export const getFullUrl = (url: string, baseUrl: string) => baseUrl + '/' + url;

export const extractArrayFromUrlParam = (param: string) => decodeURIComponent(param).split(':');

export const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180);
export const radiansToDegrees = (radians: number) => radians * (180 / Math.PI);

/** Min and Max included */
export const randomIntFromInterval = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

export const convertComponentToUrl = (component: ReactElement, options?: BlobPropertyBag) => {
  const html = ReactDOMServer.renderToStaticMarkup(component);
  return URL.createObjectURL(new Blob([html], options));
};

export const styleSvgIcon = (Icon: ReactElement, style: React.CSSProperties) =>
  cloneElement(Icon, {
    style,
  });

export const buildSliderMarks = (min: number, max: number, stepsQty: number, accuracy: number) => {
  const step = Number(((max - min) / stepsQty).toFixed(accuracy));
  const marks: MarksInterface = {};

  for (let i = 0; i <= stepsQty; i++) {
    const keyValue = i === 0 ? min : i === stepsQty ? max : Number((min + i * step).toFixed(accuracy));
    marks[keyValue] = i === 0 ? String(+min.toFixed(accuracy)) : i === stepsQty ? String(+max.toFixed(accuracy)) : '';
  }

  return marks;
};

export const updateSemicolonSeparatedSigns = (mergedSigns: string, toggledSign: string, exclude?: string): string => {
  const signs = exclude ? mergedSigns.split('-').filter((o) => o !== exclude) : mergedSigns.split('-');

  const signIndex = signs.findIndex((sign) => sign === toggledSign);
  signIndex === -1 ? signs.push(toggledSign) : signs.splice(signIndex, 1);
  const updatedSigns = signs.filter((o) => o).join('-');

  return updatedSigns;
};

export const isArrayEqual = <T extends Record<string, any>>(obj1: T, obj2: T): boolean => {
  const keys1 = Object.keys(obj1) as Array<keyof T>;
  const keys2 = Object.keys(obj2) as Array<keyof T>;

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!isArrayEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

export const simpleRound = (value: number, decimalPlaces = 0): string => {
  const p = Math.pow(10, decimalPlaces);
  return (Math.round(value * p) / p).toFixed(decimalPlaces);
};

export const roundNumber = (value: number, precision = 0): number => {
  const p = Math.pow(10, precision);
  return Number((Math.round(value * p) / p).toFixed(precision));
};

export const getCelciusFromKelvin = (kelvin: number): number => kelvin - 273.15;

export const getLayerNameWithAoiId = (layerName: string, areaId: number): string => `${areaId}_${layerName}`;

export const limitNumberToNonNegative = (number: number | undefined): number => (number ? number : 0);

export const getPercentageValue = (value: number, range: number[]): number =>
  ((value - range[0]) / (range[1] - range[0])) * 100;

export const roundToNearest = (value: number): number => {
  const magnitude = Math.pow(10, Math.floor(Math.log10(value)));

  if (value / magnitude < 1.5) {
    return 1 * magnitude;
  } else if (value / magnitude < 3.5) {
    return 2 * magnitude;
  } else if (value / magnitude < 7.5) {
    return 5 * magnitude;
  } else {
    return 10 * magnitude;
  }
};

export const getShortenedMultiple = (value: number): string => {
  switch (true) {
    case value >= 1000000:
      return `${(value / 1000000).toFixed(0)}M`;
    case value >= 1000:
      return `${(value / 1000).toFixed(0)}K`;
    default:
      return value.toString();
  }
};

export const formatPayloadAsString = (
  payload: string | number | null | undefined,
  precision: number,
): string | undefined => {
  if (payload === null || payload === undefined) {
    return;
  }

  const result = typeof payload === 'number' ? payload : parseInt(payload);

  return simpleRound(result, precision);
};
