import { TileLayer } from 'react-leaflet';

import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { Loading } from 'ui/molecules/loading';

interface WaterAreaMapLayerProps {
  url: string;
  pane: string;
  color: string;
}

export const WaterAreaMapLayer = ({ url, pane, color }: WaterAreaMapLayerProps) => {
  const { cogUrl, extentLatLngBounds, isInfoLoading } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildMonocolorFloatCogUrl,
    url,
    {
      color,
    },
  );

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!cogUrl || !extentLatLngBounds) {
    return null;
  }

  return <TileLayer pane={pane} url={cogUrl} bounds={extentLatLngBounds} />;
};
