import { useEffect, useState, type RefObject, useCallback } from 'react';

import { type Datetime } from 'common/types/time';
import { debounce } from 'common/utils';
import { getISO8601TimestampFromUnixEpochTime } from 'common/utils/datetime';
import { TIMELINE_SIDE_BORDERS } from 'components/Timeline/constants';
import {
  getClosestPoint,
  getHandlePosition,
  getPositionOnTrack,
  getQuarterlyTrackDates,
  getSelectedDate,
  getStartHandleX,
  getTimelinePoints,
  getTrackDates,
} from 'components/Timeline/helpers';

export const useMainSliderService = (
  selectedDates: number[] | undefined,
  timestamp: Datetime | null | undefined,
  onTimestampChange: (timestamp: Datetime) => void,
  onTimestampClear?: () => void,
  quarterly: boolean = false,
  dontUpdateTimestamp: boolean = false,
) => {
  const [debounceTemporalSelectedDate, setDebounceTemporalSelectedDate] = useState<number | undefined>();
  const [debounceSelectedDate, setDebounceSelectedDate] = useState<number | undefined>();
  const [mouseUpFlag, setMouseUpFlag] = useState(false);

  const selectedDate = debounceTemporalSelectedDate
    ? debounceTemporalSelectedDate
    : timestamp
    ? new Date(timestamp).getTime()
    : undefined;
  const timelinePoints = getTimelinePoints(selectedDates);
  const handlePosition = getPositionOnTrack(selectedDate, timelinePoints);
  const trackDates = quarterly ? getQuarterlyTrackDates(selectedDates) : getTrackDates(selectedDates);

  const request = debounce((value: number | undefined) => setDebounceSelectedDate(value), 1_000);

  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  const debounceRequest = useCallback((value: any) => request(value), []);

  const onTrackClick = (e: MouseEvent) => {
    if (timelinePoints && timelinePoints.length > 1) {
      const { left, width } = (e.target as Element).getBoundingClientRect();
      const positionX = getHandlePosition(
        e.clientX,
        left + width * TIMELINE_SIDE_BORDERS,
        width - width * TIMELINE_SIDE_BORDERS * 2,
      );

      const selectedDate = getClosestPoint(positionX, timelinePoints);
      selectedDate && onTimestampChange(getISO8601TimestampFromUnixEpochTime(selectedDate));
    }
  };

  const handleMouseMove = (
    e: MouseEvent,
    startPageX: number,
    startHandleX: number | undefined,
    timelineWidth: number,
  ) => {
    if (timelinePoints && timelinePoints.length > 1) {
      e = e || window.event;
      if (!startHandleX) {
        return;
      }

      const selectedDate = getSelectedDate(startHandleX, e.pageX, startPageX, timelineWidth, timelinePoints);
      setDebounceTemporalSelectedDate(selectedDate);
    }
  };

  const onHandleMove = (trackRef: RefObject<HTMLDivElement>, e: MouseEvent) => {
    if (timelinePoints && timelinePoints.length > 1 && trackRef.current && selectedDate) {
      const startPageX = Number(e.pageX);
      const timelineWidth = trackRef.current.clientWidth;
      const startHandleX = getStartHandleX(timelinePoints, timelineWidth, selectedDate);
      document.onmousemove = (e) => handleMouseMove(e, startPageX, startHandleX, timelineWidth);
      document.onmouseup = () => {
        setMouseUpFlag(true);
        document.onmousemove = null;
      };
    }
  };

  const fitTimestampInNewRange = () => {
    if (selectedDate && selectedDates && !selectedDates?.includes(selectedDate)) {
      selectedDate < selectedDates[0] && onTimestampChange(getISO8601TimestampFromUnixEpochTime(selectedDates[0]));
      selectedDate > selectedDates[selectedDates.length - 1] &&
        onTimestampChange(getISO8601TimestampFromUnixEpochTime(selectedDates[selectedDates.length - 1]));
    }
  };

  const nullifyDateForEmptyResultsRange = () => {
    if (selectedDates && Array.isArray(selectedDates) && !selectedDates?.length && selectedDate) {
      onTimestampClear && onTimestampClear();
    }

    if (selectedDates?.length && !selectedDate) {
      selectedDate && onTimestampChange(getISO8601TimestampFromUnixEpochTime(selectedDates[selectedDates.length - 1]));
    }
  };

  useEffect(() => {
    if (dontUpdateTimestamp) return;

    selectedDate && onTimestampChange(getISO8601TimestampFromUnixEpochTime(selectedDate));
    setDebounceTemporalSelectedDate(undefined);
    setDebounceSelectedDate(undefined);
    setMouseUpFlag(false);
  }, [selectedDate, dontUpdateTimestamp]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fitTimestampInNewRange();
  }, [selectedDates, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    nullifyDateForEmptyResultsRange();
  }, [selectedDates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (debounceSelectedDate) {
      onTimestampChange(getISO8601TimestampFromUnixEpochTime(debounceSelectedDate));
    }
  }, [debounceSelectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (debounceTemporalSelectedDate) {
      debounceRequest(debounceTemporalSelectedDate);
    }
  }, [debounceTemporalSelectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mouseUpFlag) {
      document.onmouseup = null;

      if (debounceTemporalSelectedDate) {
        onTimestampChange(getISO8601TimestampFromUnixEpochTime(debounceTemporalSelectedDate));
        setMouseUpFlag(false);
      }
    }
  }, [mouseUpFlag]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    trackDates,
    selectedDate,
    timelinePoints,
    handlePosition,
    onTrackClick,
    onHandleMove,
  };
};
