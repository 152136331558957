import { type ReactNode } from 'react';
import { Text, View } from '@react-pdf/renderer';

import { simpleRound } from 'common/utils';
import { SURFACE_PRECISION_HA } from 'common/constants';
import { commonStyles, headerStyles } from 'views/reports/external/pdfGenerator/styles';
import { TerraeyeLogoSvgPDF } from 'views/reports/external/pdfGenerator/components/ReportPdfIcons';
import { ReportPdfSegmentedColorScale } from 'views/reports/external/pdfGenerator/components/ReportPdfSegmentedColorScale';
import { type ExternalReportColorScale, type ExternalReportHeader } from 'domain/reports/types';

interface ReportPdfHeaderProps {
  header: ExternalReportHeader;
  colorScale?: ExternalReportColorScale;
}

export const ReportPdfHeader = ({
  header: { reportType, reportFunctionality, reportAdditionalLabel, areaName, dates, areaLocation, areaSize },
  colorScale,
}: ReportPdfHeaderProps) => (
  <View>
    <View style={headerStyles.wrapper}>
      <TerraeyeLogoSvgPDF style={headerStyles.logo} />
      <View style={headerStyles.labels}>
        <View style={commonStyles.flexGrow}>
          <Text style={commonStyles.bold}>{reportType}</Text>
          <Text style={commonStyles.bold}>{reportFunctionality}</Text>
          {reportAdditionalLabel && <Text style={commonStyles.bold}>{reportAdditionalLabel}</Text>}
        </View>
        <Text style={commonStyles.flexGrow}>{areaName}</Text>
      </View>
    </View>
    <View style={headerStyles.line} />
    <View style={headerStyles.main__wrapper}>
      <View>
        <PageInfoLine head={`Date${dates.second ? 's' : ''}:`}>{`${dates.first}${
          dates.second ? ` vs ${dates.second}` : ''
        }`}</PageInfoLine>
        <PageInfoLine head="Location:">{`Latitude ${areaLocation.lat}     Longitude ${areaLocation.lng}`}</PageInfoLine>
        <PageInfoLine head="AOI Size:">
          {simpleRound(areaSize.value, SURFACE_PRECISION_HA)} {areaSize.unit}
        </PageInfoLine>
      </View>
    </View>
    {colorScale && <ReportPdfSegmentedColorScale colorScale={colorScale} />}
  </View>
);

interface PageInfoLineProps {
  children: ReactNode;
  head: string;
}

const PageInfoLine = ({ children, head }: PageInfoLineProps) => (
  <View style={headerStyles.info__line}>
    <Text style={headerStyles.info__line_head}>{head}</Text>
    <Text>{children}</Text>
  </View>
);
