/* eslint-disable @typescript-eslint/ban-types */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type DisplacementsResult } from 'domain/displacements/types';

interface DisplacementsSliceState {
  timestamps: string[] | [];
  setTimestamps: (timestamps: string[]) => void;
  kmzId: number | undefined;
  setKmzId: (kmzId: number | undefined) => void;
  kmzUrls: DisplacementsResult[] | undefined;
  setKmzUrls: (kmzUrls: DisplacementsResult[] | undefined) => void;
  graphInfoJsx: Function | undefined; // TODO: fix type
  setGraphInfoJsx: (graphInfoJsx: Function | undefined) => void;
  clearDisplacementsData: () => void;
}

const initialState = {
  timestamps: [],
  kmzId: undefined,
  kmzUrls: undefined,
  graphInfoJsx: undefined,
};

const useDisplacementsStoreBase = create<DisplacementsSliceState>()(
  devtools(
    (set) => ({
      timestamps: initialState.timestamps,
      setTimestamps: (timestamps) => set({ timestamps }, false, 'displacements/setTimestamps'),
      kmzId: initialState.kmzId,
      setKmzId: (kmzId) => set({ kmzId }, false, 'displacements/setKmzId'),
      kmzUrls: initialState.kmzUrls,
      setKmzUrls: (kmzUrls) => set({ kmzUrls }, false, 'displacements/setKmzUrls'),
      graphInfoJsx: initialState.graphInfoJsx,
      setGraphInfoJsx: (graphInfoJsx) => set({ graphInfoJsx }, false, 'graph/setGraphInfoJsx'),
      clearDisplacementsData: () => set(initialState, false, 'displacements/clearDisplacementsData'),
    }),
    { name: 'displacementsStore' },
  ),
);

export const useDisplacementsStore = createSelectors(useDisplacementsStoreBase);
