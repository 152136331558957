import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from 'common/navigation/routes';
import { Button } from 'ui/atoms/button';
import { AREA_CARD_MIN_HEIGHT } from 'domain/areas/constants';

export const AddNewAreaCard = () => {
  const navigate = useNavigate();

  return (
    <CardWrapper>
      <h2>Add a new area</h2>
      <ButtonWrapper>
        <Button variant="PRIMARY_BIG" onClick={() => navigate(ROUTES.areaRequestNew)} squared>
          + exploration area
        </Button>
        <Button variant="PRIMARY_BIG" disabled squared>
          + monitored area
        </Button>
      </ButtonWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  min-height: ${AREA_CARD_MIN_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  color: ${({ theme }) => theme.color.white};
  padding: 30px 52px 40px;
  margin: 0;

  h2 {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 66px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;

  * {
    flex-shrink: 0;
  }
`;
