/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect, type RefObject } from 'react';
import { LOADING_DELAY } from '../constants';
import { debounce } from '../utils';

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

interface ElementSize {
  width: number | undefined;
  height: number | undefined;
}

export const useElementSize = (ref: React.RefObject<HTMLElement>): ElementSize => {
  const [elementSize, setElementSize] = useState<ElementSize>({ width: undefined, height: undefined });

  useEffect(() => {
    const onWindowResize = () => {
      if (ref && ref.current) {
        setElementSize({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    };
    window.addEventListener('resize', debounce(onWindowResize, 150));

    onWindowResize();

    return () => window.removeEventListener('resize', onWindowResize);
  }, [ref]);

  return elementSize;
};

export const useLoadingDelay = (param: any) => {
  const [loading, setLoading] = useState(false);

  const execute = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, LOADING_DELAY);
  };

  useEffect(() => {
    execute();
  }, [param]);

  return { loading, execute };
};
