import { useEffect, useState } from 'react';

import { useGetAoiName } from 'common/hooks/getAoiName';
import {
  FoldableSection,
  FoldableSectionCheckbox,
  REQUEST_MORE_DATA_MESSAGE,
  useFoldableSectionStatus,
} from 'components/layerManager';
import { compositesRepository } from 'infrastructure/composites/compositesRepository';
import { useInitializeLayersOrderRecord } from 'components/layerManager/draggableLayerManager/useInitializeLayersOrderRecord';
import { COMPOSITES_INFO_POINT_DESCRIPTION } from 'domain/exploration/configs';
import type { ProviderType } from 'domain/composites/types';
import { CompositesProvidersList } from './CompositesProvidersList';
import { useCompositesStore } from '../compositesStore';
import { EXPLORATION_LAYERS_KEYS } from '../../explorationDraggableLayersKeys';

interface CompositesLayerManagerProps {
  id: string;
}

export const CompositesLayerManager = ({ id }: CompositesLayerManagerProps) => {
  const { areaName } = useGetAoiName();
  const areCompositesVisible = useCompositesStore.use.areCompositesVisible();
  const toggleCompositesVisibility = useCompositesStore.use.toggleCompositesVisibility();

  const [selectedProvider, setSelectedProvider] = useState<ProviderType>('sentinel');

  const clearSelectedBandCombos = useCompositesStore.use.clearSelectedBandCombos();

  useEffect(() => {
    return () => {
      clearSelectedBandCombos();
    };
  }, [areaName, clearSelectedBandCombos]);

  const { compositesProviders } = compositesRepository.useFetchProviders();

  const { compositesResults } = compositesRepository.useFetchResults();

  const resultsForCurrentArea = compositesResults?.filter((result) => result.aoi_name === areaName);

  const providersExtractedFromResults = Array.from(new Set(resultsForCurrentArea?.map(({ provider }) => provider)));

  const providersWithData = compositesProviders?.filter(({ name }) => providersExtractedFromResults.includes(name));

  const providersWithDataNames = providersWithData.map(({ name }) => name);

  useEffect(() => {
    if (!providersWithDataNames.includes(selectedProvider)) {
      setSelectedProvider(providersWithData[0]?.name);
    }
  }, [providersWithData, providersWithDataNames, selectedProvider]);

  const { description, areResultsAvailable } = useFoldableSectionStatus({
    defaultDescription: COMPOSITES_INFO_POINT_DESCRIPTION,
    noDataMessage: REQUEST_MORE_DATA_MESSAGE,
    areResultsAvailable: !!resultsForCurrentArea.length,
  });

  useInitializeLayersOrderRecord(EXPLORATION_LAYERS_KEYS.COMPOSITES);

  if (!areResultsAvailable) {
    return null;
  }

  return (
    <FoldableSection
      id={id}
      headerContent={
        <FoldableSectionCheckbox
          variant="NORMAL"
          title="false color composites"
          description={description}
          isCheckboxChecked={areCompositesVisible}
          onCheckboxToggle={toggleCompositesVisibility}
        />
      }
      disabled={!areResultsAvailable}
    >
      <CompositesProvidersList
        {...{ providersWithData, selectedProvider, setSelectedProvider, resultsForCurrentArea }}
      />
    </FoldableSection>
  );
};
