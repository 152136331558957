import { PUBLIC_CONTAINER_URL } from 'common/constants/services';

export const WATER_API_URLS = {
  getProviders: `${PUBLIC_CONTAINER_URL}/water/providers.json`,

  getWaterbodies: '/water/waterbodies/',
  getDates: '/water/results/dates/',
  getDatesMocked: `${PUBLIC_CONTAINER_URL}/water/dates.json`,

  getQualityIndicators: '/water/indicators/',
  getQualityResults: '/water/results/',
  getQualityResultsMocked: `${PUBLIC_CONTAINER_URL}/water/quality/results.json`,
  getQualityCog: '/water/results/:resultId/get_cog/',

  getAreaResults: '/water/areas/',
  getAreaResultsMocked: `${PUBLIC_CONTAINER_URL}/water/surface/results.json`,
  getAreaCog: '/water/areas/:resultId/get_cog/',
  getAreaComparatorCog: '/water/areas-comparator/get_cog/',

  getWaterbodyDefaultView: '/water/waterbodies/:waterbodyId/get_default_view/',
  getPointHistory: '/water/point-history/',
  getAreaHistory: '/water/area-history/',

  getMoistureIndicators: `${PUBLIC_CONTAINER_URL}/moisture/indicators.json`,
  getMoistureResults: `${PUBLIC_CONTAINER_URL}/moisture/moisture_results.json`,

  getSnowCoverIndicators: `${PUBLIC_CONTAINER_URL}/snow_cover/indicators.json`,
  getSnowCoverResults: `${PUBLIC_CONTAINER_URL}/snow_cover/cover_results.json`,
};
