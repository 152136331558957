import styled from 'styled-components';
import { Link } from 'ui/atoms/link';

interface NewAreaNameTileProps {
  header: string;
  description: string;
  accent?: string;
  url?: string;
}

export const ExplanationTile = ({ header, description, accent, url }: NewAreaNameTileProps) => (
  <Wrapper>
    <InnerWrapper>
      {header && <Header>{header}</Header>}
      {description && <Description>{description}</Description>}
      {accent && <Accent>{accent}</Accent>}
      {url && (
        <Link variant="SECONDARY_SMALL" href={url} sameSite>
          Contact us
        </Link>
      )}
    </InnerWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  max-width: 400px;
  padding: 40px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.mineShaftDark};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid ${({ theme }) => theme.color.primary};
  padding: 0 16px;
`;

const Header = styled.div`
  font-family: 'Barlow Condensed';
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 8px;
`;

const Accent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
`;

const Description = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
`;

ExplanationTile.displayName = 'NewAreaNameTile';
