import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import { type LeafletBounds } from 'common/types/mapData';

interface TileLayerProps {
  layerName: string;
  zIndex: number;
  cogUrl: string | undefined;
  bounds: LeafletBounds | undefined;
  isVisible?: boolean;
  opacity?: number;
  fixed?: boolean;
}

export const TileLayer = ({
  isVisible = true,
  layerName,
  zIndex,
  bounds,
  cogUrl,
  fixed = false,
  opacity,
}: TileLayerProps) => {
  const map = useMap();

  useEffect(() => {
    if (!cogUrl || !isVisible || (!bounds && !fixed)) {
      return;
    }

    let tileLayerPane = map.createPane(layerName);
    tileLayerPane.style.zIndex = zIndex.toString();
    const tileLayer = L.tileLayer(cogUrl, {
      bounds: bounds,
      pane: layerName,
      opacity,
    });

    tileLayer.addTo(map);

    return () => {
      tileLayer.remove();
      tileLayerPane.remove();
      tileLayerPane = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cogUrl, !!bounds, isVisible, opacity]);

  return null;
};
