import { ROUTES } from 'common/navigation/routes';
import { PrivateRoute } from 'common/navigation/PrivateRoute';
import { Minerals } from 'views/exploration/minerals/Minerals';
import { Exploration } from 'views/exploration/Exploration';
import { useUserStore } from 'infrastructure/user/userStore';
import { MineralizationActiveTab } from 'domain/exploration/enums';

export const useBuildExplorationRouteObject = () => {
  const { userPermissions } = useUserStore();

  return {
    path: ROUTES.mineralization,
    element: <Exploration />,
    children: [
      {
        path: ROUTES.mineralsSurface,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(MineralizationActiveTab.SURFACE)}
            redirectPath={ROUTES.areasList}
          >
            <Minerals />
          </PrivateRoute>
        ),
      },
    ],
  };
};
