import { usePointHistory } from 'components/graph/usePointHistory';
import { useViewOptions } from 'common/navigation/useViewOptions';

export const useWaterViewOptions = () => {
  const { clearPointHistoryUseCase } = usePointHistory();

  const props = useViewOptions(clearPointHistoryUseCase);

  return props;
};
