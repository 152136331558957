import { ReturnButton } from 'ui';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { useWaterbodies } from './useWaterbodies';

export const WaterbodiesResetButton = () => {
  const { selectedWaterbody, resetWaterbody } = useWaterbodies();

  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <ReturnButton
      label="Back to all waterbodies"
      visible={!!selectedWaterbody && !areReportsActive}
      onClick={resetWaterbody}
    />
  );
};
