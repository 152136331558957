import { useTimeline } from 'components/Timeline/useTimeline';
import { Timeline } from 'components/Timeline/Timeline';
import { Graph } from 'components/graph';
import { useGraphStore } from 'components/graph/graphStore';
import { getCoordinateString } from 'components/graph/helpers';
import { GraphWeatherControls } from 'components/graph/GraphWeatherControls';
import { buildLinearGraphConfig } from 'components/graph/linearGraphConfig';
import { useReportWeatherDataError } from 'components/graph/useReportWeatherDataError';
import { GraphWaterInfoTitleWrapper, GraphWaterInfoWrapper } from 'components/graph/styled';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { SURFACE_PRECISION_HA } from 'common/constants';
import { simpleRound } from 'common/utils';
import { temperatureRepository } from 'infrastructure/temperature/temperatureRepository';
import { WATER_PATH_NAME } from 'domain/water/constants';

export const TemperatureTimeline = () => {
  const { areaName } = useGetAoiName();
  const { results } = temperatureRepository.useFetchTemperatureResults(areaName);
  const timestamps = results.map((result) => result.img_date);
  const uniqueTimestamps = Array.from(new Set(timestamps));

  const {
    filteredTimestamps,
    firstDate,
    lastDate,
    rangeValue,
    selectedTimestamps,
    setRangeValue,
    isTimelineShowed,
    firstTimestamp,
    lastTimestamp,
  } = useTimeline(uniqueTimestamps, true);

  const dataPoint = useGraphStore.use.chosenPoint();

  const { reportError } = useReportWeatherDataError(WATER_PATH_NAME);

  const coordinateString = dataPoint ? getCoordinateString('Point', dataPoint) : '';

  const graphValueFormatter = (num_or_millis: number, _opts: never, seriesName: string) => {
    const precision = { temperature: { precision: 2 } }[seriesName]?.precision;
    const decimalPlaces = typeof precision === 'number' ? precision : SURFACE_PRECISION_HA;

    return simpleRound(num_or_millis, decimalPlaces);
  };

  const graphConfigExtension = {
    ...buildLinearGraphConfig(['surface-temperature'], graphValueFormatter),
    labelsKMB: true,
  };

  return (
    <>
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          visible: true,
        }}
      />
      <Graph
        isTimelineVisible={isTimelineShowed}
        dateRange={{ firstDate: firstTimestamp, lastDate: lastTimestamp }}
        graphConfigExtension={graphConfigExtension}
        label={'surface-temperature'}
      >
        <GraphWaterInfoWrapper>
          <GraphWaterInfoTitleWrapper>
            <span>{coordinateString}</span>
            <span>Surface temperature [°C]</span>
          </GraphWaterInfoTitleWrapper>
          <GraphWeatherControls {...{ reportError }} />
        </GraphWaterInfoWrapper>
      </Graph>
    </>
  );
};
