import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type ScaleLabel, type ScaleHeight } from 'common/types/mapData';

interface mapState {
  mapReady: boolean;
  setMapReady: () => void;
  setMapInOperation: () => void;
  alignMap: boolean;
  setMapAlign: () => void;
  resetMapAlign: () => void;
  centerOnGeotiffData: boolean;
  setCenteringOnGeotiffData: () => void;
  resetCenteringOnGeotiffData: () => void;
  scaleLabel: ScaleLabel | undefined;
  setScaleLabel: (scaleLabel: ScaleLabel) => void;
  scaleHeight: ScaleHeight | undefined;
  setScaleHeight: (scaleHeight: ScaleHeight) => void;
  pinAdding: boolean;
  setPinAdding: (pinAdding: boolean) => void;
  clearState: () => void;
}

const initialState = {
  mapReady: false,
  pinAdding: false,
  alignMap: false,
  centerOnGeotiffData: false,
  scaleLabel: undefined,
  scaleHeight: undefined,
};

const useMapStoreBase = create<mapState>()(
  devtools(
    (set) => ({
      mapReady: initialState.mapReady,
      setMapReady: () => set({ mapReady: true }, false, 'map/setMapReady'),
      setMapInOperation: () => set({ mapReady: false }, false, 'map/setMapInOperation'),
      alignMap: initialState.alignMap,
      setMapAlign: () => set({ alignMap: true }, false, 'map/setMapAligned'),
      resetMapAlign: () => set({ alignMap: initialState.alignMap }, false, 'map/resetMapAligned'),
      centerOnGeotiffData: initialState.centerOnGeotiffData,
      setCenteringOnGeotiffData: () => set({ centerOnGeotiffData: true }, false, 'map/setCenteringOnGeotiffData'),
      resetCenteringOnGeotiffData: () =>
        set({ centerOnGeotiffData: initialState.centerOnGeotiffData }, false, 'map/resetCenteringOnGeotiffData'),
      scaleLabel: initialState.scaleLabel,
      setScaleLabel: (scaleLabel: ScaleLabel) => set({ scaleLabel }, false, 'map/setScaleLabel'),
      scaleHeight: initialState.scaleHeight,
      setScaleHeight: (scaleHeight: ScaleHeight) => set({ scaleHeight }, false, 'map/setScaleHeight'),
      pinAdding: initialState.pinAdding,
      setPinAdding: (pinAdding: boolean) => set({ pinAdding }, false, 'map/setPinAdding'),
      clearState: () => set(initialState, false, 'map/clearAll'),
    }),
    { name: 'mapStore' },
  ),
);

export const useMapStore = createSelectors(useMapStoreBase);
