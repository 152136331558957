import {
  COHERENCE_QUERY_PARAMETER,
  COMMON_QUERY_PARAMETERS,
  MARKER_INDEX_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { type ColormapDictionary } from 'common/types';
import { DisplacementsActiveTab } from 'domain/displacements/enums';

export const DISPLACEMENTS_PATH_NAME = 'displacements';
export const SUBSIDENCE_PATH_NAME = 'subsidence';

export const SUBSIDENCE_DESCRIPTION =
  'This view represents points providing information on cumulative ground displacements for the shown time period. The results are generated using DinSAR remote sensing technique. Hover over a point to display its value. Click on the point to show its details and displacement velocities over time. The graph shows land displacement velocities over time for the shown time period.';

export const DISPLACEMENTS_GEOTIFF_EXCLUDED_VALUES: number[] = [0];

export const DISPLACEMENTS_GEOTIFF_IMAGE_LIMITS = {
  min: 0,
  max: 10000,
};

export const DISPLACEMENTS_VIEW_CONFIG = {
  name: DISPLACEMENTS_PATH_NAME,
  path: ROUTES.displacements,
  params: [...COMMON_QUERY_PARAMETERS],
  tabs: {
    [DisplacementsActiveTab.SUBSIDENCE]: {
      path: ROUTES.subsidence,
      params: [COHERENCE_QUERY_PARAMETER, MARKER_INDEX_QUERY_PARAMETER],
    },
  },
};

export const DISPLACEMENTS_TAB_SWITCHER_BUTTONS = [
  {
    id: 1,
    label: 'Displacements',
    value: DisplacementsActiveTab.SUBSIDENCE,
  },
];

export const DISPLACEMENTS_COLORMAP: ColormapDictionary = {
  0: '#AF4CFC',
  0.1: '#FC4C4C',
  0.25: '#FFE600',
  0.5: '#378F35',
  0.75: '#25A4FF',
  1: '#1025EA',
};

export const DISPLACEMENTS_COLOR_SCALE_RANGE_NAMES = ['elevation loss', 'no changes', 'elevation gain'];

export const DISPLACEMENTS_INFO_AREA_WIDTH = 242;

export const KMZ_FOLDER_DATA_NAMES = ['kml_data/', 'kml_data_files/'];

export const FILE_NAMES_OF_REFERENCE_POINTS = ['timeseries_root.kml', 'geo_timeseries_ECMWF_ramp_demErr_master.kml'];

export const OVERRIDEN_LEVELS_OF_DETAILS = [4000, 10000];
export const DISPLACEMENTS_QUANTITY_WHEN_SHOW_ALL = 4000;

export const unhoverMarkerStyle = {
  weight: 0,
  zIndexOffset: 0,
};
export const hoverMarkerStyle = {
  weight: 2,
  zIndexOffset: 2,
};

export const endOfDygraphProps = ');';
export const beginningOfDygraphData = 'Date,';
export const endOfDygraphData = '"",';

export const allowedKmzFileTypes = ['application/vnd.google-earth.kmz', 'application/octet-stream', 'application/zip'];
export const COHERENCE_TITLE = 'coherence';
export const ASCENDING = 'ascending';
export const DESCENDING = 'descending';
export const DISPLACEMENTS_OBJECTS_QUERY_KEY = 'displacements_objects';
