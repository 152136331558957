import { useGetAoiName } from 'common/hooks/getAoiName';
import { COMPARISON_OPTION_NAME, TIMELINE_OPTION_NAME } from 'common/navigation/constants';
import { Select } from 'ui';
import { useGreeneryDataProvider } from 'views/greenery/useGreeneryDataProvider';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { useGreeneryViewOptions } from 'views/greenery/useGreeneryViewOptions';

export const VegetationViewOptionsSelect = () => {
  const { mapViewOption, changeViewOption, resetViewOption } = useGreeneryViewOptions();

  const { timestamps } = useGreeneryTimestamps();
  const { areaName } = useGetAoiName();

  const { isSentinelProvider } = useGreeneryDataProvider();

  const vegetationViewOptions = [
    {
      value: COMPARISON_OPTION_NAME,
      label: 'comparison',
      disabled: timestamps.length <= 1 || !isSentinelProvider,
    },
    {
      value: 'hotspots',
      label: 'hot & cold spots',
      //TODO: instead of disabling for specific AOI name, apply generic solution by disabling when no hotspots results, issue: https://github.com/FourPointSPZOO/terraeye-frontend/issues/1882
      disabled: areaName === 'viiankiaapa' || !isSentinelProvider,
    },
  ];

  const vegetationDefaultView = TIMELINE_OPTION_NAME;

  const viewOptionsSelectData = {
    options: vegetationViewOptions,
    selectedOption: mapViewOption,
    initialStateLabel: vegetationDefaultView,
    noOptionsLabel: vegetationDefaultView,
    name: 'view selection',
    minWidth: 148,
  };

  return <Select {...viewOptionsSelectData} onChange={changeViewOption} reset={resetViewOption} />;
};
