import { Button } from 'ui';
import { useDataRequestStore } from 'infrastructure/dataRequest/dataRequestStore';

export const DataRequestButton = () => {
  const openDataRequestModal = useDataRequestStore.use.openDataRequestModal();

  return (
    <Button variant="PRIMARY_SMALL_UPPERCASE" onClick={openDataRequestModal}>
      Request more data
    </Button>
  );
};
