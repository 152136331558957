import { useAoiNumber } from 'common/navigation/hooks';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { useGetWaterAreaRemappedResults } from '../useGetWaterAreaRemappedResults';

export const useGetInfoFromWaterAreaPoint = () => {
  const areaId = useAoiNumber();

  const { waterBodiesList } = waterRepository.useFetchWaterbodies(areaId);

  const { remappedResults } = useGetWaterAreaRemappedResults();

  const getInfoFromWaterAreaPoint = async (url: string, lng: number, lat: number, resultId: number) => {
    const info = await titilerApiService.getPointInfoGeneric(getAzureBlobStorageUrl(url), lng, lat, 1);

    const waterbodyId =
      info?.values && info.values[0] && remappedResults.find(({ id }) => id === Number(resultId))?.waterbody_id;
    const waterbodyName = waterBodiesList && waterBodiesList.find(({ id }) => id === waterbodyId)?.name;

    return { info, waterbodyId, waterbodyName };
  };

  return { getInfoFromWaterAreaPoint };
};
