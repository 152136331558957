import { type LeafletLatLng } from 'common/types/mapData';
import { getBoundsFromCogInfo } from 'services/titiler/utils';
import { titilerApiService } from 'services/titiler/titilerApiService';

export const examinePointInsideBoundsHelper = async (id: number, url: string, latLng: LeafletLatLng) => {
  const response = await titilerApiService.getInfoGeneric(url);

  const cogBounds = getBoundsFromCogInfo(response);

  if (
    !id ||
    !url ||
    !latLng ||
    !latLng?.lat ||
    !latLng?.lng ||
    !cogBounds ||
    latLng.lat < cogBounds._southWest.lat ||
    latLng.lat > cogBounds._northEast.lat ||
    latLng.lng < cogBounds._southWest.lng ||
    latLng.lng > cogBounds._northEast.lng
  ) {
    return;
  }

  const info = await titilerApiService.getPointInfoGeneric(url, latLng?.lng, latLng?.lat, 1);

  if (!info?.values) return;

  return await info.values[0];
};
