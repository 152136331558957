import { useState } from 'react';

import { formatDate } from 'common/utils/datetime';
import { replaceSquareMetersWithHectares, capitalizeAllFirstLetters, simpleRound } from 'common/utils';
import { type LayerObject } from 'common/types/mapData';
import { PERCENTAGES_PRECISION, SURFACE_PRECISION_HA } from 'common/constants';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdfSimpleContent } from 'views/reports/external/pdfGenerator/simple/ReportPdfSimpleContent';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { ReportPdfDonutChartGenerate } from 'views/reports/external/pdfGenerator/simple/ReportPdfDonutChartGenerate';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import {
  EXTERNAL_REPORT_OTHER_TABLE_CLASS,
  EXTERNAL_REPORT_SURFACE_AREA_UNIT,
  EXTERNAL_REPORT_UNCLASSIFIED_TABLE_CLASS,
} from 'domain/reports/constants';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface SegmentationSingleDateReportsExternalDataProps {
  reportInfo: ExternalReportHeaderCommon;
  areaId: number;
  timestamp: string;
  greeneryObjects: LayerObject[];
}

export const SegmentationSingleDateReportsExternalData = ({
  reportInfo,
  areaId,
  timestamp,
  greeneryObjects,
}: SegmentationSingleDateReportsExternalDataProps) => {
  const [chartSrc, setChartSrc] = useState<string | null>(null);

  const { segmentationAreaResults } = greeneryRepository.useFetchSegmentationAreaResults(areaId, timestamp);

  const { classesVisibility } = useGreeneryLayersVisibility();
  const reportScreenshotSrc = useReportsStore.use.reportScreenshotSrc();

  const data = greeneryObjects
    .filter(({ id }) => segmentationAreaResults.some(({ greenery_object_id }) => greenery_object_id === id))
    .filter(({ name }) => classesVisibility[name])
    .map(({ id, label_name, color }) => {
      const surfaceInM2 = segmentationAreaResults.find(({ greenery_object_id }) => greenery_object_id === id)?.area;
      const surfaceInHa = replaceSquareMetersWithHectares(surfaceInM2);
      const value = typeof surfaceInHa === 'number' ? simpleRound(surfaceInHa, SURFACE_PRECISION_HA) : null;
      const percentage = value
        ? simpleRound((+value / reportInfo.areaSize.value) * 100, PERCENTAGES_PRECISION)
        : undefined;

      return {
        id,
        label: capitalizeAllFirstLetters(label_name),
        color,
        value,
        percentage,
      };
    });

  const notSelectedSurfaceInM2 = segmentationAreaResults
    .filter((item) =>
      greeneryObjects.some(({ id, name }) => item.greenery_object_id === id && !classesVisibility[name]),
    )
    .reduce((acc, { area }) => acc + area, 0);
  const notSelectedSurfaceInHa = replaceSquareMetersWithHectares(notSelectedSurfaceInM2);
  const notSelectedSurface =
    typeof notSelectedSurfaceInHa === 'number' ? simpleRound(notSelectedSurfaceInHa, SURFACE_PRECISION_HA) : null;

  if (notSelectedSurface && +notSelectedSurface > 0) {
    data.push({
      id: -1,
      label: EXTERNAL_REPORT_OTHER_TABLE_CLASS.label,
      color: EXTERNAL_REPORT_OTHER_TABLE_CLASS.color,
      value: notSelectedSurface,
      percentage: simpleRound((+notSelectedSurface / reportInfo.areaSize.value) * 100, PERCENTAGES_PRECISION),
    });
  }

  const allSurfaceInM2 = segmentationAreaResults.reduce((acc, { area }) => acc + area, 0);
  const allSurfaceInHa = replaceSquareMetersWithHectares(allSurfaceInM2);
  const unclassifiedSurfaceInHa =
    typeof allSurfaceInHa === 'number'
      ? simpleRound(reportInfo.areaSize.value - allSurfaceInHa, SURFACE_PRECISION_HA)
      : null;

  if (unclassifiedSurfaceInHa && +unclassifiedSurfaceInHa > 0) {
    data.push({
      id: -2,
      label: EXTERNAL_REPORT_UNCLASSIFIED_TABLE_CLASS.label,
      color: EXTERNAL_REPORT_UNCLASSIFIED_TABLE_CLASS.color,
      value: unclassifiedSurfaceInHa,
      percentage: simpleRound((+unclassifiedSurfaceInHa / reportInfo.areaSize.value) * 100, PERCENTAGES_PRECISION),
    });
  }

  const header = {
    ...reportInfo,
    reportType: 'Single Date Report',
    reportFunctionality: 'Land Cover - Segmentation',
    dates: {
      first: formatDate(timestamp),
    },
  };

  const table = {
    tableHeader: 'Land Cover Segmentation Per Class',
    dataHeader: 'Class Name',
    unit: EXTERNAL_REPORT_SURFACE_AREA_UNIT,
    data,
  };

  const chart = chartSrc
    ? {
        header: 'Land Cover Segmentation In %',
        src: chartSrc,
      }
    : undefined;

  if (!reportScreenshotSrc) {
    return null;
  }

  return (
    <>
      <ReportPdfDonutChartGenerate data={data} header={table.tableHeader} setChartSrc={setChartSrc} />
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} />
          <ReportPdfSimpleContent screenshotScr={reportScreenshotSrc} table={table} chart={chart} />
        </>
      </ReportPdf>
    </>
  );
};
