import { Navigate, useParams } from 'react-router-dom';

import { AppLayerManager } from 'components/layerManager/legacy';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { ShareModalControlButton } from 'components/downloadModal/legacyShareModal';
import { Select } from 'ui/atoms';
import { GreeneryInfoArea } from 'views/greenery/components';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { ROUTES } from 'common/navigation/routes';
import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const VHR = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const areReportsActive = useGetAreReportsActiveUseCase();

  const { areaId } = useParams();

  // TODO: remove this when VHR is implemented on backend
  if (!!areaId && areaId !== '236') {
    return <Navigate to={ROUTES.landCover} />;
  }
  // TODO

  return (
    <AreaDetailsLayout
      toolbarContent={
        <>
          <GreeneryInfoArea />
          {!areReportsActive && (
            <Select
              {...{ options: undefined, selectedOption: undefined, noOptionsLabel: 'Timeline' }}
              name="view selection"
              minWidth={148}
              onChange={() => {}}
              reset={() => {}}
            />
          )}
        </>
      }
      toolboxContent={
        <>
          {!isComparisonActive && !areReportsActive && <LayerManagerControlButton />}
          {!areReportsActive && <ShareModalControlButton />}
        </>
      }
    >
      {!areReportsActive && <AppLayerManager />}
    </AreaDetailsLayout>
  );
};
