import { PUBLIC_CONTAINER_URL } from 'common/constants/services';
import { Layer } from 'components/map';
import { Loading } from 'ui/molecules/loading';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { type HabitatsResult } from 'domain/greenery/types';
import { HABITATS_OCCURENCE_LAYER_NAME } from 'domain/greenery/constants';
import { useHabitatsLayersVisibility } from './useHabitatsLayersVisibility';
import { HabitatsOccurencePointMarker } from './HabitatsOccurencePointMarker';

export const HabitatsMapOccurenceLayer = ({ result }: { result: HabitatsResult }) => {
  const { id, url } = result;
  const { classesVisibility } = useHabitatsLayersVisibility();

  const { pointsCollection, isLoading } = greeneryRepository.useFetchHabitatsOccurencePointsCollection(
    id,
    `${PUBLIC_CONTAINER_URL}${url}`,
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!pointsCollection) return null;

  return (
    <Layer
      visible={!!classesVisibility[HABITATS_OCCURENCE_LAYER_NAME]}
      name={HABITATS_OCCURENCE_LAYER_NAME}
      zIndex={479}
    >
      <>
        {pointsCollection.features.map((feature) => (
          <HabitatsOccurencePointMarker key={feature.properties.id} position={feature.geometry.coordinates} />
        ))}
      </>
    </Layer>
  );
};
