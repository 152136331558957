import styled from 'styled-components';

import { Button } from 'ui/atoms/button';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
  isTransparentBg?: boolean;
}

export const Fallback = ({ error, resetErrorBoundary, isTransparentBg = false }: FallbackProps) => (
  <StyledFallbackWrapper isTransparentBg={isTransparentBg}>
    <h2>Oooppssss... something went wrong...</h2>
    <p>{error.message}</p>
    <Button onClick={() => resetErrorBoundary()}>Reload</Button>
  </StyledFallbackWrapper>
);

const StyledFallbackWrapper = styled.div<{ isTransparentBg?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme, isTransparentBg }) => (isTransparentBg ? 'transparent' : theme.color.mineShaftDark)};
`;
