import styled from 'styled-components';

import { type DisplayableWarningError } from 'domain/@errors/types';

interface WarningErrorItemWithKeyProps extends DisplayableWarningError {
  key: number;
}

export const WarningErrorItem = ({ priority, content, key }: WarningErrorItemWithKeyProps) => {
  return (
    <WarningError priority={priority} key={key}>
      {content}
    </WarningError>
  );
};

interface WarningErrorProps {
  priority: 0 | 1 | 2;
}

const WarningError = styled.li<WarningErrorProps>`
  margin-top: 0.3rem;
  color: ${({ theme, priority }) =>
    (priority === 2 && theme.color.errorRed) || (priority === 1 && theme.color.errorYellow)};
`;
