import { useState } from 'react';

import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { ShareModalControlButton } from 'components/downloadModal/legacyShareModal';
import { Select } from 'ui/atoms';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { greeneryControlsService } from 'views/greenery/services';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { GreeneryInfoArea, SegmentationColorScale } from 'views/greenery/components';
import { QuarterlyLayerManager } from 'views/greenery/quarterly/QuarterlyLayerManager';
import { useTimelineService } from 'components/Timeline/timelineService';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { type ValueAndLabel } from 'common/types';
import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { SegmentationViewOptionsSelect } from 'views/greenery/components/SegmentationViewOptionsSelect';
import { useHandleGreneryObjectsSelectUseCase } from 'views/greenery/useCases/handleGreneryObjectsSelectUseCase';
import { QuarterlyTimeline } from 'views/greenery/quarterly/QuarterlyTimeline';

export const Quarterly = () => {
  const { timestamp } = useTimelineService();
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const areReportsActive = useGetAreReportsActiveUseCase();

  const [comparatorLocked, setComparatorLocked] = useState(false);

  const { greeneryObjects, greeneryObjectsLoading } = greeneryRepository.useFetchGreeneryObjects();

  const { segmentationObjectsSelectData } = greeneryControlsService.useGreeneryObjectsOptions(greeneryObjects, false);

  const { selectGreeneryObject } = useHandleGreneryObjectsSelectUseCase();

  const onGreeneryObjectChange = (option: ValueAndLabel) => {
    selectGreeneryObject(option);
    setComparatorLocked(false);
  };

  return (
    <AreaDetailsLayout
      legend={!areReportsActive && <SegmentationColorScale isComparison={isComparisonActive} />}
      toolbarContent={
        <>
          <GreeneryInfoArea />
          {!areReportsActive && (
            <>
              <SegmentationViewOptionsSelect />
              {isComparisonActive && (
                <Select {...segmentationObjectsSelectData} onChange={onGreeneryObjectChange} minWidth={210} />
              )}
            </>
          )}
        </>
      }
      toolboxContent={
        <>
          {!isComparisonActive && !areReportsActive && <LayerManagerControlButton />}
          {!areReportsActive && <ShareModalControlButton />}
        </>
      }
    >
      <QuarterlyTimeline
        isComparisonActive={isComparisonActive}
        areReportsActive={areReportsActive}
        comparatorLocked={comparatorLocked}
        setComparatorLocked={setComparatorLocked}
      />
      {!isComparisonActive && !areReportsActive && (
        <QuarterlyLayerManager
          layerObjects={greeneryObjects}
          greeneryObjectsLoading={greeneryObjectsLoading}
          timestamp={timestamp}
        />
      )}
    </AreaDetailsLayout>
  );
};
