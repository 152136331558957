import styled from 'styled-components';

import { degreesToRadians } from 'common/utils';
import { LoadingWave, Select } from 'ui';
import { useCurrentOptionThreshold } from 'components/layerManager';
import { WithCogStatistics } from 'services/titiler/WithCogStatistics';
import { HISTOGRAM_GRAPH_DEGREES_RANGE } from 'domain/exploration/constants';
import { HistogramGraph } from './HistogramGraph';
import { useSelectedMineral } from './useSelectedMineral';
import { SectionLayout } from '../SectionLayout';

export const HistogramSection = () => {
  const { selectedMineral, setSelectedMineral, options, selectedMineralUrl, areMineralsLoading } = useSelectedMineral();

  const rangeInRadians = HISTOGRAM_GRAPH_DEGREES_RANGE.map(degreesToRadians);

  const range = rangeInRadians.map((value) => Number(Math.cos(value).toFixed(4))).sort((a, b) => a - b);

  const graphAllowance = 10;

  const { threshold } = useCurrentOptionThreshold(selectedMineral);

  return (
    <SectionLayout title="distribution of SAM results">
      <SelectWrapper>
        <Select
          name="mineral selection"
          options={options}
          selectedOption={selectedMineral}
          onChange={setSelectedMineral}
          noOptionsLabel="no minerals available"
          initialStateLabel="select mineral"
        />
      </SelectWrapper>
      <GraphWrapper>
        {areMineralsLoading && <LoadingWave height={400} />}
        {selectedMineralUrl ? (
          <WithCogStatistics
            Component={HistogramGraph}
            LoadingComponent={() => LoadingWave({ height: 400 })}
            url={selectedMineralUrl}
            props={{ range: HISTOGRAM_GRAPH_DEGREES_RANGE, graphAllowance, threshold }}
            range={range}
            bins={30}
          />
        ) : (
          <div>no mineral selected</div>
        )}
      </GraphWrapper>
    </SectionLayout>
  );
};

const SelectWrapper = styled.div`
  width: 192px;
`;

const GraphWrapper = styled.div`
  position: relative;
  z-index: 0;
  margin: 10px auto 32px;
  width: 100%;
  height: 400px;
`;
