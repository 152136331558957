import { useQuery } from '@tanstack/react-query';

import { useHandleWarningErrorUseCase } from 'views/@errors/handleTypicalWarningErrorUseCase';
import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { displacementsAPI } from 'infrastructure/displacements/displacementsApiAdapter';
import { WarningErrorType } from 'domain/@errors/enums';

import { DISPLACEMENTS_OBJECTS_QUERY_KEY, DISPLACEMENTS_PATH_NAME } from 'domain/displacements/constants';
import { ErrorsModel } from 'domain/@errors/ErrorsModel';

export const displacementsRepository = {
  useFetchKMZurls: (areaId: number) => {
    const { isLoading, error, data, isSuccess } = useQuery([DISPLACEMENTS_OBJECTS_QUERY_KEY, areaId], () =>
      displacementsAPI.getResults(areaId),
    );

    useHandleWarningErrorUseCase(WarningErrorType.DISPLACEMENTS_COG, data, error, isSuccess);

    return {
      KMZurlsLoading: isLoading,
      KMZurlsError: error,
      KMZurlsData: data?.data.results,
    };
  },

  useGetSignedKmzUrl: () => {
    const updateWarningError = useErrorsStore.use.updateWarningError();

    const getSignedKmzUrl = async (kmzId: number) => {
      try {
        const responce = await displacementsAPI.getSignedKmzUrl(kmzId);
        return responce.data.url;
      } catch (err) {
        if (err instanceof Error) {
          console.error(err);
          const errorCause = ErrorsModel.getErrorCause(err.message);
          updateWarningError({
            [WarningErrorType.DISPLACEMENTS_KMZ_RESULTS]: {
              type: WarningErrorType.DISPLACEMENTS_KMZ_RESULTS,
              viewPath: DISPLACEMENTS_PATH_NAME,
              errorCause,
            },
          });
        }
      }
    };

    return { getSignedKmzUrl };
  },
};
