import { type QuarterlyResult } from 'common/types';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { mapResultsToTimestamps, timestampToQuarterlyParams } from 'components/Timeline/helpers';

export const quarterlyDataService = {
  useFetchQuarterlyResults: (areaId: number) => {
    const { quarterlyResults, quarterlyResultsLoading } = greeneryRepository.useGetQuarterlySegmentationResults(areaId);

    const quarterlyResultsTimestamps = mapResultsToTimestamps(quarterlyResults);

    return {
      quarterlyResultsTimestamps,
      quarterlyResults,
      quarterlyResultsLoading,
    };
  },

  useFetchQuarterlyCogUrl: (results: QuarterlyResult[], timestamp: string | null) => {
    const params = timestamp && timestampToQuarterlyParams(timestamp);

    const chosenResult = params
      ? results.filter(({ year, quarter }) => year === params?.year && quarter === params?.quarter)
      : undefined;

    const chosenResultId = chosenResult && chosenResult[0]?.id;

    const { quarterlyCogUrl, isQuarterlyCogLoading } =
      greeneryRepository.useGetQuarterlySegmentationCogUrl(chosenResultId);

    return { quarterlyCogUrl, isQuarterlyCogLoading, chosenResultId };
  },
};
