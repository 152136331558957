import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { SegmentationDataLayer } from 'views/greenery/segmentation/SegmentationDataLayer';
import { useSegmentationOtherProviderResult } from 'views/greenery/segmentation/useSegmentationOtherProviderResult';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';

interface Props {
  timestamp: string;
}

export const SegmentationOtherProvidersMapContent = ({ timestamp }: Props) => {
  const { cogUrl } = useSegmentationOtherProviderResult(timestamp);

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();
  const { classesVisibility } = useGreeneryLayersVisibility();

  const selectedObjects = greeneryObjects.filter(({ name }) => classesVisibility[name]);

  if (!cogUrl || !selectedObjects.length) {
    return null;
  }

  return <SegmentationDataLayer url={cogUrl} objects={selectedObjects} />;
};
