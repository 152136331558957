import { type ValueAndLabel } from 'common/types';
import { useReplaceQueryParameters } from 'common/navigation/hooks';
import { WATER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const useHandleWaterIndicatorChangeUseCase = () => {
  const replaceQueryParameter = useReplaceQueryParameters();

  const changeWaterIndicator = (option: ValueAndLabel): void => {
    if (option) {
      replaceQueryParameter([
        {
          key: WATER_INDICATOR_QUERY_PARAMETER,
          value: String(option.value),
        },
      ]);
    }
  };

  return { changeWaterIndicator };
};
