import { type ComponentType } from 'react';

import { titilerInfoService } from './titilerInfoService';

interface Props<T> {
  Component: ComponentType<T & { url: string; rescaleArray: number[][]; bidxArray: string[]; expression: string }>;
  props?: T;
  url: string;
  bands: {
    b1: string;
    b2: string;
    b3: string;
  };
}

export const WithCumulativeCutoffCogRange = <T,>({ Component, props, url, bands }: Props<T>) => {
  const { b1, b2, b3 } = bands;
  const expression = `${b1};${b2};${b3}`;

  const { stats } = titilerInfoService.useGetStatsGeneric(url, { expression });

  if (!stats) {
    return null;
  }

  const rescaleArray = [
    [stats[b1]?.percentile_2, stats[b1]?.percentile_98],
    [stats[b2]?.percentile_2, stats[b2]?.percentile_98],
    [stats[b3]?.percentile_2, stats[b3]?.percentile_98],
  ];

  const bidxArray = Object.values(bands).map((item) => item.replace(/b/g, ''));

  return <Component {...(props as T)} {...{ url, rescaleArray, bidxArray, expression }} />;
};
