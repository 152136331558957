import { type ReactNode } from 'react';
import { default as TippyReact, type TippyProps } from '@tippyjs/react';
import styled from 'styled-components';

export type TooltipPlacement = TippyProps['placement'];

interface TooltipProps {
  content: ReactNode;
  children: ReactNode;
  offset?: number[];
}

export const Tooltip = styled(TippyReact)<TooltipProps>`
  &.tippy-box {
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme }) => theme.color.codGray};
    text-transform: none;
  }

  &[data-placement='top'],
  &[data-placement='bottom'] {
    .tippy-arrow {
      color: ${({ theme }) => theme.color.codGray};
    }
  }
`;

Tooltip.defaultProps = {};

Tooltip.displayName = 'Tooltip';
