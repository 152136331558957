import styled from 'styled-components';

export const getCompositesDescription = (
  provider: string,
  descriptionOptions: {
    id: number;
    description: string;
  }[],
) => {
  return (
    <div>
      <div>Band Combinations available from {provider}:</div>
      <StyledList>
        {descriptionOptions.map(({ id, description }) => (
          <li key={id}>{description}</li>
        ))}
      </StyledList>
    </div>
  );
};

const StyledList = styled.ul`
  margin-top: 6px;
  padding-left: 20px;
`;
