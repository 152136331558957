import { useMemo } from 'react';

import { NANOMETER_TO_METER_CONVERSION_FACTOR } from 'common/constants';
import { useAoiNumber } from 'common/navigation/hooks';
import { useGetUserInfo } from 'ui/sidebar/useGetUserInfo';
import { useGetActiveSubscription } from 'views/subscriptions/useGetActiveSubscription';
import { dataRequestRepository } from 'infrastructure/dataRequest/dataRequestRepository';
import { useDataRequestStore } from 'infrastructure/dataRequest/dataRequestStore';
import { type DataRequestDataType, type DataRequestAnalysisType } from 'domain/dataRequest/types';
import { DATA_REQUEST_DATA_TYPE_HEADERS } from 'domain/dataRequest/configs';
import { getDataRequestSpatialResolutionValue, getDataRequestTooltipInfo } from 'domain/dataRequest/helpers';
import { AREA_REQUEST_EXTENDED_SUBSCRIPTION_NAME } from 'domain/areas/configs';

export const useDataRequest = () => {
  const areaId = useAoiNumber();
  const { email } = useGetUserInfo();
  const { activeSubscriptionData, isActiveSubscriptionLoading } = useGetActiveSubscription();
  const isUserPremium = activeSubscriptionData?.name === AREA_REQUEST_EXTENDED_SUBSCRIPTION_NAME;

  const { dataTypes, areDataTypesLoading } = dataRequestRepository.useFetchDataTypes();
  const { analysisTypes, areAnalysisTypesLoading } = dataRequestRepository.useFetchAnalysisTypes();
  const { handleOrderPost, orderPostStatus } = dataRequestRepository.usePostOrder();

  const dataTypesSelection = useDataRequestStore.use.dataTypesSelection();
  const toggleDataTypeSelection = useDataRequestStore.use.toggleDataTypeSelection();
  const analysisTypesSelection = useDataRequestStore.use.analysisTypesSelection();
  const toggleAnalysisTypeSelection = useDataRequestStore.use.toggleAnalysisTypeSelection();
  const customReport = useDataRequestStore.use.customReport();
  const setCustomReport = useDataRequestStore.use.setCustomReport();

  const isSubmitDataLoading = isActiveSubscriptionLoading;
  const isSubmitDisabled =
    !(
      Object.values(dataTypesSelection).includes(true) ||
      Object.values(analysisTypesSelection).includes(true) ||
      customReport.length
    ) || isSubmitDataLoading;

  const dataTypesArray: DataRequestDataType[] = dataTypes.map(
    ({
      id,
      data_type,
      eo_data_source,
      wavelength_min,
      wavelength_max,
      include_tir,
      number_of_bands,
      spatial_resolution_min,
      spatial_resolution_max,
      extended_feature,
      information,
    }) => {
      const disabled = extended_feature && !isUserPremium;
      const info = getDataRequestTooltipInfo(information, extended_feature, isActiveSubscriptionLoading, isUserPremium);
      const resolution = getDataRequestSpatialResolutionValue(
        spatial_resolution_min,
        spatial_resolution_max,
        NANOMETER_TO_METER_CONVERSION_FACTOR,
        id === 1 ? 2 : 0,
      );

      return {
        id,
        type: data_type,
        source: eo_data_source,
        waveLength: `${wavelength_min}-${wavelength_max}`,
        tir: include_tir ? 'yes' : 'no',
        bands: number_of_bands,
        resolution,
        checked: dataTypesSelection[id],
        disabled,
        info,
        onChange: () => toggleDataTypeSelection(id),
      };
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedDataTypes = useMemo(() => dataTypesArray, [JSON.stringify(dataTypesArray)]);

  const analysisTypesArray: DataRequestAnalysisType[] = analysisTypes.map(
    ({ id, analysis_type, extended_feature, information }) => {
      const disabled = extended_feature && !isUserPremium;
      const info = getDataRequestTooltipInfo(information, extended_feature, isActiveSubscriptionLoading, isUserPremium);

      return {
        id,
        label: analysis_type,
        checked: analysisTypesSelection[id],
        disabled,
        info,
        onChange: () => toggleAnalysisTypeSelection(id),
      };
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAnalysisTypes = useMemo(() => analysisTypesArray, [JSON.stringify(analysisTypesArray)]);

  const onOrderSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleOrderPost({
      aoi_id: areaId,
      user_email: email || '',
      subscription: activeSubscriptionData?.name ?? 'none',
      data_types: memoizedDataTypes
        .filter(({ checked }) => checked)
        .map(
          ({ type, source, waveLength, tir, bands, resolution }) =>
            `${DATA_REQUEST_DATA_TYPE_HEADERS.type}: ${type}, ${DATA_REQUEST_DATA_TYPE_HEADERS.source}: ${source}, ${DATA_REQUEST_DATA_TYPE_HEADERS.waveLength}: ${waveLength}, ${DATA_REQUEST_DATA_TYPE_HEADERS.tir}: ${tir}, ${DATA_REQUEST_DATA_TYPE_HEADERS.bands}: ${bands}, ${DATA_REQUEST_DATA_TYPE_HEADERS.resolution}: ${resolution}`,
        ),
      analysis_types: memoizedAnalysisTypes.filter(({ checked }) => checked).map(({ label }) => label),
      custom_report: customReport,
    });
  };

  return {
    dataTypes: memoizedDataTypes,
    analysisTypes: memoizedAnalysisTypes,
    areDataTypesLoading,
    areAnalysisTypesLoading,
    customReport,
    setCustomReport,
    isSubmitDataLoading,
    isSubmitDisabled,
    onOrderSubmit,
    orderPostStatus,
  };
};
