import { useAoiNumber } from 'common/navigation/hooks';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { Lineament } from './Lineament';
import { buildFileUrlFromName } from './helpers';
import { useLineamentsLayerManagerStore } from './layerManager/lineamentsLayerManagerStore';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

interface LineamentsCollectionProps {
  areaName: string;
  selectedOption: number[] | null;
  rangeResult:
    | {
        id: number;
        aoi_name: string;
        ranges: number[];
      }
    | undefined;
}

export const LineamentsCollection = ({ areaName, selectedOption, rangeResult }: LineamentsCollectionProps) => {
  const areaId = useAoiNumber();
  const areLineamentsVisible = useLineamentsLayerManagerStore.use.areLineamentsVisible();
  const lineamentsOpacity = useLineamentsLayerManagerStore.use.lineamentsOpacity();
  const isLineamentsSelectionMode = useLineamentsLayerManagerStore.use.isLineamentsSelectionMode();

  if (!selectedOption || !rangeResult) {
    return null;
  }

  const id = Number(`${areaId}${selectedOption.join('')}`);
  const name = `${selectedOption[0]}-${selectedOption[1]}`;

  return (
    <WithLayerIndex
      key={id}
      Component={Lineament}
      props={{
        id,
        url: buildFileUrlFromName(areaName, name),
        name,
        zIndex: indexedDB,
        isVisible: areLineamentsVisible,
        opacity: lineamentsOpacity,
        isSelectionMode: isLineamentsSelectionMode,
      }}
      sectionId={EXPLORATION_LAYERS_KEYS.LINEAMENTS}
    />
  );
};
