import { useAoiNumber } from 'common/navigation/hooks';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { DemLayer } from './DemLayer';

export const DemMapContent = () => {
  const areaId = useAoiNumber();
  const { aoiCog: demCog } = areasRepository.useFetchAreaOfInterestCog(areaId, { productType: 'DEM' });

  if (!demCog) {
    return null;
  }

  const { url } = demCog;

  return <WithCogBandRange Component={DemLayer} props={{ url }} url={url} precision={1} />;
};
