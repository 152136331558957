import { useParams } from 'react-router-dom';

import { areasRepository } from 'infrastructure/areas/areasRepository';

export const useAreasOfInterestSelectService = () => {
  const { areaId } = useParams();

  const { areasList, areasListLoading, areasListFetching } = areasRepository.useFetchAreasOfInterestList();

  const currentArea = areaId && areasList?.filter(({ id }) => id === +areaId)[0];

  const options =
    areasList &&
    areasList.map((area) => ({
      value: area.id,
      label: area.display_name,
      disabled: area.status === 'P',
    }));

  const selectedOption = currentArea
    ? {
        value: currentArea?.id,
        label: currentArea?.display_name,
      }
    : undefined;

  const selectData = {
    isFetching: areasListFetching || areasListLoading,
    options,
    selectedOption,
    name: 'area of interest selection',
  };

  return { selectData };
};
