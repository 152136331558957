import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import * as map from 'components/map';
import { Loading } from 'ui/molecules/loading';
import { useVciResults } from 'views/greenery/vci/useVciResults';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { VCI_COLORMAP } from 'domain/greenery/colors';
import { VCI_LIMITS } from 'domain/greenery/configs';

export const VCIMapContent = () => {
  const { currentResult, resultsLoading } = useVciResults();

  if (resultsLoading) {
    return <Loading />;
  }

  if (currentResult?.cog_file_path) {
    return <VciDataLayer url={currentResult.cog_file_path} />;
  }

  return null;
};

interface VhrSegmentationLayerProps {
  url: string;
}

const VciDataLayer = ({ url }: VhrSegmentationLayerProps) => {
  const fileUrl = getAzureBlobStorageUrl(`/${url}`);

  const { cogUrl, extentLatLngBounds, isInfoLoading } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildDiscreteColorScaleFloatCogUrl,
    fileUrl,
    {
      range: [VCI_LIMITS.min, VCI_LIMITS.max],
      colormapObject: VCI_COLORMAP,
      noDataValue: 'nan',
    },
  );

  if (isInfoLoading) {
    return null;
  }

  return (
    <>
      {cogUrl && extentLatLngBounds && (
        <map.DataLayer
          isVisible={true}
          layerName={'vhrSegmentationLayer'}
          zIndex={490}
          cogUrl={cogUrl}
          bounds={extentLatLngBounds}
        />
      )}
    </>
  );
};
