import { useState, type ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { VerticalResizer, HideShowButton } from 'ui';

interface Props {
  children: ReactNode;
  buttonLabelShow?: string;
  buttonLabelHide?: string;
  contentInitialHeight?: number;
}

export const Drawer = ({ children, buttonLabelShow, buttonLabelHide, contentInitialHeight }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <DrawerWrapper isOpen={isDrawerOpen}>
      <ButtonWrapper>
        <HideShowButton
          isShown={isDrawerOpen}
          showLabel={buttonLabelShow}
          hideLabel={buttonLabelHide}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      </ButtonWrapper>
      <VerticalResizer initialHeight={contentInitialHeight}>{children}</VerticalResizer>
    </DrawerWrapper>
  );
};

const DrawerWrapper = styled.aside<{ isOpen: boolean }>`
  position: relative;
  z-index: 1002;
  max-height: 85vh;
  background-color: ${({ theme }) => theme.color.mineShaftDarkBlur};
  backdrop-filter: blur(30px);
  transition: transform ${({ theme }) => theme.transitionFunction.main};
  margin-top: auto;
  transform: none;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: translateY(100%);
    `};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 10px;
`;
