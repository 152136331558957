import { ColorScale } from 'ui';
import { useGetCogRange } from 'views/water/moisture/useGetCogRange';
import { type MoistureCommonProps } from 'domain/water/types';

export const MoistureColorScale = ({ indicator, layerName, min, max }: MoistureCommonProps) => {
  const { max_value, colormap, unit, custom_labels } = indicator;
  const range = useGetCogRange(layerName, min, max);

  const rangeMin = String(range[0]);
  const rangeMax = max_value !== null ? `≥${max_value}` : String(range[1]);

  const labels = custom_labels || [rangeMin, rangeMax];

  return <ColorScale {...{ colormap, unit, labels }} />;
};
