import { Slider, sliderClasses } from '@mui/base';
import styled from 'styled-components';

interface Props {
  value: number[];
  setValue: (value: number[]) => void;
  min: number;
  max: number;
  step?: number;
}

export const RangeSlider = ({ value, setValue, min, max, step }: Props) => (
  <StyledSlider
    getAriaLabel={() => 'slider'}
    getAriaValueText={() => 'range'}
    value={value}
    onChange={(_: Event, value: number[]) => setValue(value)}
    min={min}
    max={max}
    step={step}
  />
);

const StyledSlider = styled(Slider)`
  color: ${({ theme }) => theme.color.white};
  height: 8px;
  width: 100%;
  padding: 16px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} {
    pointer-events: none;
    cursor: default;
    color: ${({ theme }) => theme.color.white};
    opacity: 0.3;
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: currentColor;
    opacity: 0.3;
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 8px;
    border-radius: 4px;
    background-color: currentColor;
    opacity: 0.7;
  }

  & .${sliderClasses.thumb} {
    position: absolute;
    width: 8px;
    height: 20px;
    transform: translate(-4px, -6px);
    box-sizing: border-box;
    border-radius: 4px;
    outline: 0;
    background-color: ${({ theme }) => theme.color.white};
  }
`;
