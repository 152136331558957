import { useEffect } from 'react';
import { useAoiNumber } from 'common/navigation/hooks';
import { type PointArray } from 'common/types/mapData';
import { type ReclamationAreaType } from 'domain/monitoring/types';
import { useMonitoringInfoStore } from './store';

const mockedData = [
  {
    geometry: [
      [-33.46340020468503, 148.9997510135016],
      [-33.46181840969797, 149.0016476216845],
      [-33.46576651609697, 149.00166279455001],
      [-33.46562732309622, 148.99862822145727],
    ] as PointArray[],
    color: 'rgb(146, 255, 61)',
  },
  {
    geometry: [
      [-33.46666936097093, 148.99864454202583],
      [-33.46699203143369, 149.0035984825998],
      [-33.467643695568135, 149.004311607276],
      [-33.46888374194139, 149.0023087890353],
    ] as PointArray[],
    color: 'rgb(146, 255, 61)',
  },
  {
    geometry: [
      [-33.46578782144116, 149.0017],
      [-33.46183, 149.00167],
      [-33.46060096323186, 149.0036308765],
      [-33.465939339720165, 149.003539681434],
    ] as PointArray[],
    color: 'rgb(0, 197, 114)',
  },
];

export const useReclamationsMock = () => {
  const areaId = useAoiNumber();
  const setReclamationAreas = useMonitoringInfoStore.use.setReclamationAreas();

  useEffect(() => {
    if (areaId !== 364) return;

    const mock: ReclamationAreaType[] = mockedData.map(({ geometry, color }, i) => ({
      type: 'Feature',
      properties: {
        id: i,
        name: 'Area ' + ++i,
        color: color,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: geometry,
      },
    }));

    setReclamationAreas(mock);

    return () => {
      setReclamationAreas(undefined);
    };
  }, [areaId, setReclamationAreas]);
};
