import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';

interface chatState {
  isChatFullscreen: boolean;
  openChatFullscreen: () => void;
  closeChatFullscreen: () => void;
  toggleChat: () => void;
}

const initialState = {
  isChatFullscreen: false,
};

const useChatStoreBase = create<chatState>()(
  devtools(
    (set) => ({
      isChatFullscreen: initialState.isChatFullscreen,
      openChatFullscreen: () => set({ isChatFullscreen: true }, false, 'chat/open'),
      closeChatFullscreen: () => set({ isChatFullscreen: false }, false, 'chat/close'),
      toggleChat: () => set((state) => ({ isChatFullscreen: !state.isChatFullscreen }), false, 'chat/toggle'),
    }),
    { name: 'chatStore' },
  ),
);

export const useChatStore = createSelectors(useChatStoreBase);
