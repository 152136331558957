import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { type ValueAndLabel } from 'common/types';
import { type TooltipData, type LeafletLatLng } from 'common/types/mapData';
import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { debounce } from 'common/utils';
import { checkIsOutsideBounds } from 'common/utils/map';
import { MapTooltip } from 'ui/map/tooltip';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';
import { useWaterbodies } from 'views/water/waterbodies';
import { useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { buildWaterBodyTooltipStringHelper } from 'domain/water/helpers';
import { useGetInfoFromWaterAreaPoint } from './useGetInfoFromWaterAreaPoint';
import { useWaterAreaSelectUseCase } from '../waterAreaSelectUseCase';

interface WaterAreaTooltipProps {
  isDataLayerVisible: boolean;
  urlsWithIds: { url: string; id: number }[];
}

export const WaterAreaTitilerTooltip = ({ isDataLayerVisible, urlsWithIds }: WaterAreaTooltipProps) => {
  const map = useMap();
  const { getInfoFromWaterAreaPoint } = useGetInfoFromWaterAreaPoint();
  const buildWaterAreaTooltip = buildWaterBodyTooltipStringHelper();

  const [tooltip, setTooltip] = useState<TooltipData | undefined>();

  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const { changeWaterbody } = useWaterbodies();

  const { selectWaterArea } = useWaterAreaSelectUseCase(waterbodyIdQuery);

  const onWaterAreaClick = async (waterBody: ValueAndLabel, latLng: LeafletLatLng) => {
    if (!waterbodyIdQuery) {
      changeWaterbody(waterBody);
    } else {
      selectWaterArea(latLng.lat, latLng.lng, 0);
    }
  };

  map.off('click');
  map.off('mousemove');

  map.on(
    'mousemove',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debounce(async (event: any) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      urlsWithIds.forEach(async (set) => {
        const cogInfo = await titilerApiService.getInfoGeneric(getAzureBlobStorageUrl(set.url));

        const bounds = cogInfo ? getBoundsFromCogInfo(cogInfo) : undefined;

        if (!set?.id || !set?.url || !set.id || !set.url || !bounds || checkIsOutsideBounds(latLng, bounds)) {
          return;
        }

        const { info, waterbodyName } = await getInfoFromWaterAreaPoint(set.url, latLng?.lng, latLng?.lat, set.id);

        if (info.detail === 'Point is outside dataset bounds') {
          setTooltip(undefined);
        }

        if (info?.values && latLng?.lat && latLng?.lng && info.values[0] > 0 && isDataLayerVisible) {
          setTooltip({
            id: set.id,
            lat: latLng.lat,
            lng: latLng.lng,
            value: info.values[0],
            label: waterbodyName,
          });
        }
      });
    }, TOOLTIP_DISPLAY_DELAY),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map.on('click', async (event: any) => {
    const latLng = await map.mouseEventToLatLng(event.originalEvent);

    urlsWithIds.forEach(async (set) => {
      if (!set?.id || !set?.url) {
        return;
      }

      const { waterbodyId, waterbodyName } = await getInfoFromWaterAreaPoint(set.url, latLng?.lng, latLng?.lat, set.id);

      if (!waterbodyId || !waterbodyName) {
        return;
      }

      return onWaterAreaClick({ value: String(waterbodyId), label: waterbodyName }, latLng);
    });
  });

  return tooltip ? <MapTooltip tooltipData={tooltip} content={buildWaterAreaTooltip(tooltip)} /> : null;
};
