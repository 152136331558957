import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

import { localStorageService } from 'services/localStorageService';
import {
  LOCAL_STORAGE_IS_CANCELING_SUBSCRIPTION,
  LOCAL_STORAGE_IS_SUBSCRIPTION_STATUS_PENDING,
} from 'common/constants/services';
import { type UserPermissionsDTO, type ResetPasswordRequestStatus } from 'domain/user/types';

interface UserState {
  userPermissions: UserPermissionsDTO | null;
  setUserPermissions: (userPermissions: UserPermissionsDTO) => void;
  isLoginPromptVisible: boolean;
  showLoginPrompt: () => void;
  hideLoginPrompt: () => void;
  resetPasswordRequestStatus: ResetPasswordRequestStatus;
  setResetPasswordRequestStatus: (status: ResetPasswordRequestStatus) => void;
  clientSecret: string | null;
  setClientSecret: (clientSecret: string) => void;
  clearClientSecret: () => void;
  sessionId: string | null;
  setSessionId: (sessionId: string) => void;
  clearSessionId: () => void;
  clearAllUserData: () => void;
  isSubscriptionStatusPending: boolean;
  setIsSubscriptionStatusPending: (isPending: boolean) => void;
  isWaitingForSubscriptionCancel: boolean;
  setIsWaitingForSubscriptionCancel: (isCanceling: boolean) => void;
}

const initialState = {
  userPermissions: null,
  isLoginPromptVisible: false,
  resetPasswordRequestStatus: 'IDLE' as ResetPasswordRequestStatus,
  clientSecret: null,
  sessionId: null,
  isSubscriptionStatusPending: localStorageService.getValue(LOCAL_STORAGE_IS_SUBSCRIPTION_STATUS_PENDING, false),
  isWaitingForSubscriptionCancel: localStorageService.getValue(LOCAL_STORAGE_IS_CANCELING_SUBSCRIPTION, false),
};

const userStore = createStore<UserState>()(
  devtools(
    (set) => ({
      ...initialState,
      setUserPermissions: (userPermissions) => set({ userPermissions }, false, 'user/setPermissions'),
      showLoginPrompt: () => set({ isLoginPromptVisible: true }, false, 'user/showLoginPrompt'),
      hideLoginPrompt: () => set({ isLoginPromptVisible: false }, false, 'user/hideLoginPrompt'),
      setResetPasswordRequestStatus: (resetPasswordRequestStatus) =>
        set({ resetPasswordRequestStatus }, false, 'user/setIsResetPasswordEmailSend'),
      setClientSecret: (clientSecret) => set({ clientSecret }, false, 'user/setClientSecret'),
      clearClientSecret: () => set({ clientSecret: null }, false, 'user/clearClientSecret'),
      setSessionId: (sessionId) => set({ sessionId }, false, 'user/setSessionId'),
      clearSessionId: () => set({ sessionId: null }, false, 'user/clearSessionId'),
      clearAllUserData: () => set(initialState, false, 'user/clearAll'),
      setIsSubscriptionStatusPending: (isPending) =>
        set({ isSubscriptionStatusPending: isPending }, false, 'user/setIsSubscriptionStatusPending'),
      setIsWaitingForSubscriptionCancel: (isCanceling) =>
        set({ isWaitingForSubscriptionCancel: isCanceling }, false, 'user/setIsSubscriptionCanceling'),
    }),
    { name: 'userStore' },
  ),
);

export const useUserStore = () => {
  const userPermissions = useStore(userStore, (state) => state.userPermissions);
  const setUserPermissions = useStore(userStore, (state) => state.setUserPermissions);
  const isLoginPromptVisible = useStore(userStore, (state) => state.isLoginPromptVisible);
  const showLoginPrompt = useStore(userStore, (state) => state.showLoginPrompt);
  const hideLoginPrompt = useStore(userStore, (state) => state.hideLoginPrompt);
  const resetPasswordRequestStatus = useStore(userStore, (state) => state.resetPasswordRequestStatus);
  const setResetPasswordRequestStatus = useStore(userStore, (state) => state.setResetPasswordRequestStatus);
  const clientSecret = useStore(userStore, (state) => state.clientSecret);
  const setClientSecret = useStore(userStore, (state) => state.setClientSecret);
  const clearClientSecret = useStore(userStore, (state) => state.clearClientSecret);
  const sessionId = useStore(userStore, (state) => state.sessionId);
  const setSessionId = useStore(userStore, (state) => state.setSessionId);
  const clearSessionId = useStore(userStore, (state) => state.clearSessionId);
  const clearAllUserData = useStore(userStore, (state) => state.clearAllUserData);
  const isSubscriptionStatusPending = useStore(userStore, (state) => state.isSubscriptionStatusPending);
  const setIsSubscriptionStatusPending = useStore(userStore, (state) => state.setIsSubscriptionStatusPending);
  const isWaitingForSubscriptionCancel = useStore(userStore, (state) => state.isWaitingForSubscriptionCancel);
  const setIsWaitingForSubscriptionCancel = useStore(userStore, (state) => state.setIsWaitingForSubscriptionCancel);

  return {
    userPermissions,
    setUserPermissions,
    isLoginPromptVisible,
    showLoginPrompt,
    hideLoginPrompt,
    resetPasswordRequestStatus,
    setResetPasswordRequestStatus,
    clientSecret,
    setClientSecret,
    clearClientSecret,
    sessionId,
    setSessionId,
    clearSessionId,
    clearAllUserData,
    isSubscriptionStatusPending,
    setIsSubscriptionStatusPending,
    isWaitingForSubscriptionCancel,
    setIsWaitingForSubscriptionCancel,
  };
};

// eslint-disable-next-line import/no-default-export
export default userStore;
