import Dygraph from 'dygraphs';

import { type GraphConfig, type GraphData, type MultiGraphData } from 'components/graph/types';
import { GRAPH_SIDE_BORDERS } from './constants';
import { getFirstAndLastDateFromGraphData } from './helpers';
import { mainGraphConfig } from './mainGraphConfig';

interface CreateDygraphProps {
  dygraphContainer: HTMLDivElement;
  dygraphData: GraphData | MultiGraphData;
  labels?: string[];
  isY2hidden: boolean;
  isPrecipitation?: boolean;
  graphConfigExtension?: GraphConfig | undefined;
}

export const createDygraph = ({
  dygraphContainer,
  dygraphData,
  labels,
  isY2hidden,
  isPrecipitation,
  graphConfigExtension,
}: CreateDygraphProps) => {
  if (!dygraphData || !dygraphData.length) return;

  const graphConfig = {
    ...mainGraphConfig,
    ...graphConfigExtension,
  };

  const diagProps = labels ? { ...graphConfig, ...{ labels } } : graphConfig;

  if (diagProps?.axes?.y2) {
    diagProps.axes.y2.drawAxis = !isY2hidden;
    if (isPrecipitation && !isY2hidden) diagProps.includeZero = true;
  }

  const { firstGraphDate, lastGraphDate } = getFirstAndLastDateFromGraphData(dygraphData as GraphData);

  const diff = (lastGraphDate - firstGraphDate) * GRAPH_SIDE_BORDERS;
  const paddingArray = [...dygraphData[0]].fill(null).slice(1);
  const graphDataWithPadding = [
    [new Date(firstGraphDate - diff), ...paddingArray],
    ...dygraphData,
    [new Date(lastGraphDate + diff), ...paddingArray],
  ];

  try {
    new Dygraph(dygraphContainer, graphDataWithPadding, diagProps);
  } catch (error) {
    console.error(error);
  }
};
