import { Outlet } from 'react-router-dom';

import { useAoiNumber } from 'common/navigation/hooks';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { useRefreshLayer } from 'common/hooks/useRefreshLayer';
import { AppLayout } from 'layout/appLayout/AppLayout';
import { ReportsCreator } from 'views/reports/creator/ReportsCreator';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { useNotificationsMock } from 'views/monitoring/useNotificationsMock';
import { DataRequestModal } from 'views/dataRequest/DataRequestModal';
import { useClearClustersData } from 'views/exploration/clusters/useClearClustersData';
import { useClustersLayerManagerStore } from 'views/exploration/clusters/clustersLayerManagerStore';
import { useLineamentsLayerManagerStore } from 'views/exploration/lineaments';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { useDataRequestStore } from 'infrastructure/dataRequest/dataRequestStore';
import { Loading } from 'ui/molecules/loading';
import { Map } from 'components/map/Map';
import { Screenshoter } from 'components/map/Screenshoter';
import { useMapStore } from 'components/map/mapStore';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useMapStyle } from 'components/map/useMapStyle';
import { BaseMapVariant } from 'ui/map/baseMap/enums';
import { CenteringExtent } from './CenteringExtent';
import { MapContent } from './MapContent';
import { SatelliteImage } from './satelliteImage/SatelliteImage';
import { ShapesCollection } from './shapesCollection/ShapesCollection';
import { useChangeAreaUseCase } from './areaDetailsSidebar/changeAreaUseCase';
import { AreaDetailsSidebar } from './areaDetailsSidebar/AreaDetailsSidebar';
import { HillshadeMapContent, DemMapContent } from './topographyMapContent';
import { useInitializeLegacyLayersIndices } from './useInitializeLegacyLayersIndices';
import { PluggedInCustomMapScale } from '../PluggedInCustomMapScale';

export const Area = () => {
  const areaId = useAoiNumber();
  const { areaName } = useGetAoiName();

  const takeScreenshotFlag = useReportsStore.use.takeScreenshotFlag();

  const areReportsActive = useGetAreReportsActiveUseCase();

  const mapReady = useMapStore.use.mapReady();

  const { isNextAreaDetailsLoading } = useChangeAreaUseCase();

  const isDataRequestModalOpen = useDataRequestStore.use.isDataRequestModalOpen();

  useNotificationsMock();
  useClearClustersData();

  const isSatelliteGrayscale = useLayerManagerStore.use.isSatelliteGrayscale();
  const isGrayscaleSatelliteActive = areReportsActive || isSatelliteGrayscale;
  const isClustersSelectionMode = useClustersLayerManagerStore.use.isClustersSelectionMode();
  const isLineamentsSelectionMode = useLineamentsLayerManagerStore.use.isLineamentsSelectionMode();

  useInitializeLegacyLayersIndices();

  const { mapStyle } = useMapStyle();

  const { visible } = useRefreshLayer([mapStyle]);

  return (
    <AppLayout sidebar={<AreaDetailsSidebar {...{ areaId }} />}>
      <Outlet />
      {visible && (
        <Map mapVariant={areReportsActive ? BaseMapVariant.DEFAULT : mapStyle}>
          {takeScreenshotFlag && (
            <>
              <Screenshoter />
              <PluggedInCustomMapScale variantOverride="EXTERNAL" />
            </>
          )}
          <CenteringExtent areaId={areaId} enabled={mapReady} />
          {mapReady && !isClustersSelectionMode && !isLineamentsSelectionMode && (
            <>
              <SatelliteImage areaId={areaId} isGrayscaleSatelliteActive={isGrayscaleSatelliteActive} />
              {areaName && <HillshadeMapContent areaName={areaName} />}
              <DemMapContent />
              {areaName && <ShapesCollection areaName={areaName} />}
            </>
          )}
          <MapContent />
          {isNextAreaDetailsLoading && <Loading />}
        </Map>
      )}
      {areReportsActive && <ReportsCreator areaId={areaId} />}
      {isDataRequestModalOpen && <DataRequestModal />}
    </AppLayout>
  );
};
