import { type ComponentType } from 'react';

import { areasRepository } from 'infrastructure/areas/areasRepository';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { WithCumulativeCutoffCogRange } from 'services/titiler/WithCumulativeCutoffCogRange';

interface CommonProps<T> {
  Component: ComponentType<T & { url: string; rescaleArray: number[][]; bidxArray: string[]; expression: string }>;
  props?: T;
}

interface WithSatelliteCogProps<T> extends CommonProps<T> {
  areaId: number;
  imageDate?: string;
}

export const WithSatelliteCog = <T,>({ Component, props, imageDate, areaId }: WithSatelliteCogProps<T>) => {
  const { aoiCog } = areasRepository.useFetchAreaOfInterestCog(areaId, { imageDate, providerName: 'sentinel-2' });

  if (!aoiCog) {
    return null;
  }

  const url = getAzureBlobStorageUrl(aoiCog.url);

  return <WithSatelliteCogConfig {...{ Component, props, url }} />;
};

interface WithCogInfoProps<T> extends CommonProps<T> {
  url: string;
}

const WithSatelliteCogConfig = <T,>({ Component, props, url }: WithCogInfoProps<T>) => {
  const { info } = titilerInfoService.useGetInfoGeneric(url);

  if (!info) {
    return null;
  }

  if (info.count === 3) {
    const bidxArray = ['1', '2', '3'];

    const rescaleArray = [
      [0, 8192],
      [0, 8192],
      [0, 8192],
    ];

    return <Component {...(props as T)} {...{ url, bidxArray, rescaleArray, expression: bidxArray.join(';') }} />;
  }

  return <WithCumulativeCutoffCogRange {...{ Component, props, url, bands: { b1: 'b4', b2: 'b3', b3: 'b2' } }} />;
};
