import { type ReactNode } from 'react';
import Tippy from '@tippyjs/react/headless';
import styled from 'styled-components';
import { type Placement } from 'tippy.js';

interface PopoverProps {
  children: ReactNode;
  button?: ReactNode;
  placement?: Placement;
  zIndex?: number;
}

export const Popover = ({ children, button, placement = 'bottom-start', zIndex }: PopoverProps) => (
  <Tippy
    zIndex={zIndex}
    trigger="click"
    placement={placement}
    interactive={true}
    offset={[0, 4]}
    render={(attrs) => (
      <StyledArea className="box" {...attrs}>
        {children}
      </StyledArea>
    )}
  >
    <div>{button}</div>
  </Tippy>
);

const StyledArea = styled.div`
  background-color: ${({ theme }) => theme.color.selectGray};
  color: ${({ theme }) => theme.color.white};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
`;
