import { useEffect } from 'react';
import { useLocation, useNavigate, useParams, type Location } from 'react-router-dom';

import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import {
  FALSY_QUERY_PARAMETERS,
  REPORTS_QUERY_PARAMETER,
  REPORT_QUERY_PARAMETERS,
  VIEW_MODE_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { type AreaType, type ViewConfig } from 'common/navigation/types';
import { type QueryStringParams, type ValueAndLabel, type ValueOf } from 'common/types';
import { updateSemicolonSeparatedSigns } from 'common/utils';

export const useRequiredRouteParams = <TRequiredRouteParam extends string>(param: TRequiredRouteParam) => {
  const { [param]: value } = useParams<Record<TRequiredRouteParam, string>>();
  if (!value) {
    throw new Error(`${param} is not defined!`);
  }
  return value;
};

export const useAoiNumber = () => {
  const areaIdParam = useRequiredRouteParams('areaId');
  return parseInt(areaIdParam);
};

export const useQueryParameter = (key: string) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(key);
};

export const useReplaceQueryParameters = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  return (params: QueryStringParams[], currentLocation?: Location) => {
    const location = currentLocation || loc;
    const searchParams = new URLSearchParams(location.search);

    params.forEach(({ key, value }: QueryStringParams) => {
      if (value && !FALSY_QUERY_PARAMETERS.includes(value)) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
};

export const useDeleteQueryParameters = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (keys: string[]) => {
    const searchParams = new URLSearchParams(location.search);
    keys.forEach((key) => searchParams.delete(key));
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
};

export const useHandleQueryParameter = (
  paramName: string,
): [string | null, (value: string | number) => void, () => void] => {
  const deleteQueryParameters = useDeleteQueryParameters();
  const replaceQueryParameter = useReplaceQueryParameters();

  const query = useQueryParameter(paramName);

  const changeQueryUseCase = (value: string | number) => {
    FALSY_QUERY_PARAMETERS.includes(value)
      ? deleteQueryParameters([paramName])
      : replaceQueryParameter([
          {
            key: paramName,
            value: String(value),
          },
        ]);
  };

  const clearQueryparamaeter = () => deleteQueryParameters([paramName]);

  return [query, changeQueryUseCase, clearQueryparamaeter];
};

export const useHandleQueryParameterByPrimitiveValue = (
  paramName: string,
): [string | null, (value: string | null | undefined) => void] => {
  const deleteQueryParameters = useDeleteQueryParameters();
  const replaceQueryParameter = useReplaceQueryParameters();

  const query = useQueryParameter(paramName);

  const setQuery = (value: string | null | undefined) => {
    FALSY_QUERY_PARAMETERS.includes(value)
      ? deleteQueryParameters([paramName])
      : replaceQueryParameter([
          {
            key: paramName,
            value: value!,
          },
        ]);
  };

  return [query, setQuery];
};

export const useSetInitialQueryParameter = (options: ValueAndLabel[], paramName: string) => {
  const replaceQueryParameter = useReplaceQueryParameters();

  const setInitialQueryParameter = () => {
    replaceQueryParameter([
      {
        key: paramName,
        value: String(options[0].value),
      },
    ]);
  };

  return { setInitialQueryParameter };
};

export const useUpdateTimestamp = (
  timestamps: string[],
  timestamp: string | null,
  changeTimestamp: (timestamp: string | null | undefined) => void,
  enabled: boolean = true,
  inverted: boolean = false,
  dontUpdateTimestamp: boolean = false,
) => {
  const { pathname } = useLocation();

  const timestampsLength = timestamps.length;
  const isTimestampInRange = timestamp && timestamps.includes(timestamp);
  const initialIndex = inverted ? timestamps.length - 1 : 0;

  useEffect(() => {
    if (dontUpdateTimestamp) return;

    if ((!timestamp || !isTimestampInRange) && timestamps.length) {
      enabled && changeTimestamp(timestamps[initialIndex]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, timestamp, timestamps, timestampsLength, enabled, dontUpdateTimestamp]);
};

export const useNavigateWithQuery = () => {
  const { areaId } = useParams();
  const navigate = useNavigate();

  return (route: ValueOf<typeof ROUTES>, params: QueryStringParams[]) => {
    if (!areaId) return;

    const searchParams = new URLSearchParams();

    params.forEach(({ key, value }: QueryStringParams) => {
      if (value) {
        searchParams.set(key, value);
      }
    });

    navigate(`${route.replace(':areaId', areaId)}?${searchParams.toString()}`);
  };
};

export const useNavigateToAreaWithQuery = () => {
  const navigate = useNavigate();

  return (route: ValueOf<typeof ROUTES>, areaId: string, params: QueryStringParams[]) => {
    const searchParams = new URLSearchParams();

    params.forEach(({ key, value }: QueryStringParams) => {
      if (value) {
        searchParams.set(key, value);
      }
    });

    navigate(`${route.replace(':areaId', areaId)}?${searchParams.toString()}`);
  };
};

export const useTreeShakeQueryParams = (viewConfig: ViewConfig) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const searchQueryKeys = Object.keys(Object.fromEntries(searchParams));
    const configuredTabs = Object.keys(viewConfig.tabs);

    configuredTabs.length
      ? configuredTabs.forEach((tab) => {
          if (pathname.includes(tab)) {
            searchQueryKeys
              .filter((key) => !viewConfig.tabs[tab].params.includes(key) && !viewConfig.params.includes(key))
              .forEach((key) => searchParams.delete(key));
          }
        })
      : searchQueryKeys.filter((key) => !viewConfig.params.includes(key)).forEach((key) => searchParams.delete(key));

    if (!searchQueryKeys.includes(REPORTS_QUERY_PARAMETER)) {
      REPORT_QUERY_PARAMETERS.forEach((key) => searchParams.delete(key));
    }

    navigate(`${pathname}?${searchParams.toString()}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search]);
};

export const useGetIsComparisonActive = () => {
  const viewModeQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive = viewModeQuery === COMPARISON_OPTION_NAME;

  return { isComparisonActive };
};

export const useQueryMultipleToggle = (param: string, fallback?: string) => {
  const query = useQueryParameter(param);
  const deleteQueryParameters = useDeleteQueryParameters();
  const replaceQueryParameters = useReplaceQueryParameters();

  const toggleMultipleQueries = (clickedValue: string) => {
    const semicolonQuery = updateSemicolonSeparatedSigns(query || '', clickedValue, 'idle');

    const onEmptyClickedValue = fallback
      ? replaceQueryParameters([
          {
            key: param,
            value: fallback,
          },
        ])
      : deleteQueryParameters([param]);

    clickedValue === null || semicolonQuery === ''
      ? onEmptyClickedValue
      : replaceQueryParameters([
          {
            key: param,
            value: semicolonQuery,
          },
        ]);
  };

  return { toggleMultipleQueries };
};

export const getDefaultRouteByAreaType = (type: AreaType | undefined) => {
  switch (true) {
    case type === 'M':
      return ROUTES.monitoring;
    case type === 'P':
      return ROUTES.prospecting;
    default:
      return ROUTES.areasList;
  }
};
