import { useEffect, type PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { userRepository } from 'infrastructure/user/userRepository';
import { useUserStore } from 'infrastructure/user/userStore';
import { ROUTES } from 'common/navigation/routes';

export const RequirePermissions = ({ children, pk }: PropsWithChildren<{ pk: number }>) => {
  const [showInactiveUserInfo, setShowInactiveUserInfo] = useState(false);

  const { userPermissions, isLoading } = userRepository.useGetUserPermissions(pk);
  const { setUserPermissions } = useUserStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (userPermissions) {
      setUserPermissions(userPermissions);
    }
  }, [userPermissions, setUserPermissions]);

  useEffect(() => {
    if (userPermissions && userPermissions.organization === null) {
      setShowInactiveUserInfo(true);

      setTimeout(() => {
        setShowInactiveUserInfo(false);

        navigate(ROUTES.logout);
      }, 5000);
    } else {
      setShowInactiveUserInfo(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissions, navigate]);

  if (isLoading) return <PrivateRoutesLoading />;

  if (showInactiveUserInfo) {
    return (
      <PrivateRoutesLoading>
        <div>
          <h1>Your account is inactive.</h1>
          <p>You will be logged out in a few seconds...</p>
        </div>
      </PrivateRoutesLoading>
    );
  }

  if (userPermissions && !!userPermissions.organization && !isLoading) {
    return children;
  }

  <Navigate to={ROUTES.login} state={{ from: location.pathname }} replace />;
};

export const RequireAuth = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  const { data, isLoading } = userRepository.useGetUserDetails();

  if (isLoading) return <PrivateRoutesLoading />;

  if (data && !isLoading) {
    return <RequirePermissions pk={data.data.pk}>{children}</RequirePermissions>;
  }

  return <Navigate to={ROUTES.login} state={{ from: location.pathname }} replace />;
};

const PrivateRoutesLoading = styled.div`
  background-color: ${({ theme }) => theme.color.mineShaftDark};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  font-size: 28px;
  text-align: center;
`;
