import { useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Sidebar } from 'ui/sidebar';
import { AppLayout } from 'layout/appLayout/AppLayout';
import { useUserStore } from 'infrastructure/user/userStore';

export const CheckoutPageLayout = () => {
  const { clientSecret } = useUserStore();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!clientSecret) {
      navigate(-1);
    }
  }, [clientSecret, navigate]);

  if (!clientSecret) return null;

  return (
    <AppLayout sidebar={<Sidebar variant="FOLDED" />} topBarless>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </AppLayout>
  );
};

const Wrapper = styled.main`
  background-color: ${({ theme }) => theme.color.primary};
  height: 100vh;
  overflow-y: auto;
`;
