import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html, #root {
        width: 100%;
        height: 100%;
    }

    html {
       box-sizing: border-box;
    }

    *, ::after, ::before {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        overflow: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Barlow', sans-serif;
        font-weight: 400;
        letter-spacing: 0.01rem;

        width: 100%;
        height: 100%;
    }

    #root {
        position: relative;
        overflow: hidden;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: all 0s 10000s;
    }
`;
