import { useLocation } from 'react-router-dom';
import { useInfoAreaService } from 'ui/infoArea/infoAreaService';
import { SUBSIDENCE_PATH_NAME } from 'domain/displacements/constants';
import { SubsidenceDescription } from './SubsidenceDescription';

export const useDisplacementsInfoArea = () => {
  const { pathname } = useLocation();
  const infoAreaProps = useInfoAreaService();

  const getContentUseCase = () => {
    const isSubsidenceTabActive: boolean = pathname.endsWith(SUBSIDENCE_PATH_NAME);

    switch (true) {
      case isSubsidenceTabActive:
        return <SubsidenceDescription />;

      default:
        return null;
    }
  };

  return {
    content: getContentUseCase(),
    infoAreaProps,
  };
};
