import { HOTSPOTS_OPTION_NAME } from 'common/navigation/constants';
import { useAoiNumber, useQueryParameter } from 'common/navigation/hooks';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { AppLayerManager } from 'components/layerManager/legacy';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useGreeneryDataProvider } from 'views/greenery/useGreeneryDataProvider';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { useVegetationIndicators } from 'views/greenery/vegetation/indicators/useVegetationIndicators';
import { VegetationHotspotsLayerManagerContent } from 'views/greenery/vegetation/layerManager/VegetationHotspotsLayerManagerContent';
import { VegetationLayerManagerContent } from 'views/greenery/vegetation/layerManager/VegetationLayerManagerContent';
import { VegetationOtherProvidersLayerManagerContent } from 'views/greenery/vegetation/layerManager/VegetationOtherProvidersLayerManagerContent';
import { VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS } from 'domain/greenery/configs';

export const VegetationLayerManager = () => {
  const areaId = useAoiNumber();
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isTimelineActive: boolean = viewQuery === null;
  const isHotspotViewActive: boolean = viewQuery === HOTSPOTS_OPTION_NAME;

  const { validTimestamp } = useGreeneryTimestamps();

  const { greeneryObjects, greeneryObjectsLoading } = greeneryRepository.useFetchGreeneryObjects();

  const { selectedIndicator } = useVegetationIndicators();

  const { isSentinelProvider } = useGreeneryDataProvider();

  return (
    <AppLayerManager>
      {isSentinelProvider ? (
        <>
          {isTimelineActive && validTimestamp && (
            <VegetationLayerManagerContent
              layerObjects={greeneryObjects}
              greeneryObjectsLoading={greeneryObjectsLoading}
              viewSpecificDisabledLayers={VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS}
              noBadges={!isHotspotViewActive}
              timestamp={validTimestamp}
            />
          )}
          {isHotspotViewActive && <VegetationHotspotsLayerManagerContent {...{ areaId }} />}
        </>
      ) : (
        <>
          {validTimestamp && selectedIndicator && (
            <VegetationOtherProvidersLayerManagerContent timestamp={validTimestamp} indicator={selectedIndicator} />
          )}
        </>
      )}
    </AppLayerManager>
  );
};
