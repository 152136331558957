import { Outlet } from 'react-router-dom';

import { TabSwitcher, TopButtonsWrapper } from 'components';
import { getAvailableTabSwitcherOptions } from 'common/navigation/utils';
import { useUserStore } from 'infrastructure/user/userStore';
import { useTreeShakeQueryParams } from 'common/navigation/hooks';
import { EXTRACTION_TAB_SWITCHER_BUTTONS, EXTRACTION_VIEW_CONFIG } from 'domain/extraction/configs';
import { useExtractionLifecycleEvents } from './extractionLifecycleEvents';

export const Mine = () => {
  const { userPermissions } = useUserStore();

  useExtractionLifecycleEvents();

  useTreeShakeQueryParams(EXTRACTION_VIEW_CONFIG);

  if (!userPermissions) return null;

  const mineralizationTabSwitcherOptions = getAvailableTabSwitcherOptions(
    EXTRACTION_TAB_SWITCHER_BUTTONS,
    userPermissions.granted_permissions,
  );

  return (
    <>
      <TopButtonsWrapper>
        <TabSwitcher options={mineralizationTabSwitcherOptions} />
      </TopButtonsWrapper>
      <Outlet />
    </>
  );
};
