import { waterRepository } from 'infrastructure/water/waterRepository';
import { useAoiNumber } from 'common/navigation/hooks';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { ProviderType, type WaterQualityReamappedResult } from 'domain/water/types';
import { useWaterQualityResults } from './useWaterQualityResults';

export const useGetWaterQualityRemappedResults = () => {
  const areaId = useAoiNumber();

  const { waterBodiesList } = waterRepository.useFetchWaterbodies(areaId);

  const { waterQualityMergedResults, waterQualityMergedCogsResponse, waterQualityResultsLoading } =
    useWaterQualityResults();

  const waterQualityRemappedResults: WaterQualityReamappedResult[] = waterQualityMergedResults
    .map((result) => {
      const signedCogUrl = waterQualityMergedCogsResponse.find((item) => item.data?.data?.id === result.id)?.data?.data
        ?.url;

      return {
        ...result,
        url: signedCogUrl || '',
        indicator_file_path: signedCogUrl || '',
        label: waterBodiesList.find((item) => item.id === result.waterbody_id)?.name || '',
        value: result.waterbody_id,
        provider: result.provider || ProviderType.sentinel_2,
      };
    })
    .map((result) => ({
      ...result,
      url: getAzureBlobStorageUrl(result.url),
    }));

  const waterQualityRemappedResultsLoading = waterQualityResultsLoading;

  return { waterQualityRemappedResults, waterQualityRemappedResultsLoading };
};
