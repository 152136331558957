import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type ReportsDatasetExtended, type ReportsDataset } from 'domain/reports/types';

interface ReportsSliceState {
  takeScreenshotFlag: boolean;
  setTakeScreenshotFlag: (flag: boolean) => void;
  reportScreenshotSrc: string | undefined;
  setReportScreenshotSrc: (screenshot: string | undefined) => void;
  isGenerateReportButtonEnabled: boolean;
  setIsGenerateReportButtonEnabled: (isEnabled: boolean) => void;
  isReportsSecondDateSelected: boolean;
  setIsReportsSecondDateSelected: (isSeleted: boolean) => void;
  firstDateReportsSegmentationDataset: ReportsDatasetExtended | undefined;
  setFirstDateReportsSegmentationDataset: (data: ReportsDataset | undefined) => void;
  secondDateReportsSegmentationDataset: ReportsDatasetExtended | undefined;
  setSecondDateReportsSegmentationDataset: (data: ReportsDataset | undefined) => void;
  clearReportsSegmentationDataset: (datasetId?: number) => void;
  firstDateReportsExtractionDataset: ReportsDatasetExtended | undefined;
  setFirstDateReportsExtractionDataset: (data: ReportsDataset | undefined) => void;
  secondDateReportsExtractionDataset: ReportsDatasetExtended | undefined;
  setSecondDateReportsExtractionDataset: (data: ReportsDataset | undefined) => void;
  clearReportsExtractionDataset: (datasetId?: number) => void;
  isReportGenerating: boolean;
  setIsReportGenerating: (isGenerating: boolean) => void;
  clearReports: () => void;
}

const initialState = {
  takeScreenshotFlag: false,
  reportScreenshotSrc: undefined,
  isGenerateReportButtonEnabled: false,
  isReportsSecondDateSelected: false,
  firstDateReportsSegmentationDataset: undefined,
  secondDateReportsSegmentationDataset: undefined,
  firstDateReportsExtractionDataset: undefined,
  secondDateReportsExtractionDataset: undefined,
  isReportGenerating: false,
};

const useReportsStoreBase = create<ReportsSliceState>()(
  devtools(
    (set) => ({
      takeScreenshotFlag: initialState.takeScreenshotFlag,
      setTakeScreenshotFlag: (flag) => set({ takeScreenshotFlag: flag }, false, 'reports/setTakeScreenshotFlag'),
      reportScreenshotSrc: initialState.reportScreenshotSrc,
      setReportScreenshotSrc: (screenshot) =>
        set({ reportScreenshotSrc: screenshot }, false, 'reports/setReportScreenshotSrc'),
      isGenerateReportButtonEnabled: initialState.isGenerateReportButtonEnabled,
      setIsGenerateReportButtonEnabled: (isEnabled) =>
        set({ isGenerateReportButtonEnabled: isEnabled }, false, 'reports/setIsGenerateReportButtonEnabled'),
      isReportsSecondDateSelected: initialState.isReportsSecondDateSelected,
      setIsReportsSecondDateSelected: (isSelected) =>
        set({ isReportsSecondDateSelected: isSelected }, false, 'reports/setIsReportsSecondDateSelected'),
      firstDateReportsSegmentationDataset: initialState.firstDateReportsSegmentationDataset,
      setFirstDateReportsSegmentationDataset: (data) =>
        set(
          {
            firstDateReportsSegmentationDataset: data ? { ...data, id: 1 } : undefined,
            reportScreenshotSrc: initialState.reportScreenshotSrc,
          },
          false,
          'reports/setFirstDateReportsSegmentationDataset',
        ),
      secondDateReportsSegmentationDataset: initialState.secondDateReportsSegmentationDataset,
      setSecondDateReportsSegmentationDataset: (data) =>
        set(
          {
            secondDateReportsSegmentationDataset: data ? { ...data, id: 2 } : undefined,
            reportScreenshotSrc: initialState.reportScreenshotSrc,
          },
          false,
          'reports/setSecondDateReportsSegmentationDataset',
        ),
      clearReportsSegmentationDataset: (datasetId) =>
        set(
          (state) => {
            return {
              firstDateReportsSegmentationDataset:
                datasetId === 2 ? state.firstDateReportsSegmentationDataset : undefined,
              secondDateReportsSegmentationDataset: undefined,
              reportScreenshotSrc: initialState.reportScreenshotSrc,
            };
          },
          false,
          'reports/clearReportsSegmentationDataset',
        ),
      firstDateReportsExtractionDataset: initialState.firstDateReportsExtractionDataset,
      setFirstDateReportsExtractionDataset: (data) =>
        set(
          {
            firstDateReportsExtractionDataset: data ? { ...data, id: 1 } : undefined,
            reportScreenshotSrc: initialState.reportScreenshotSrc,
          },
          false,
          'reports/setFirstDateReportsExtractionDataset',
        ),
      secondDateReportsExtractionDataset: initialState.secondDateReportsExtractionDataset,
      setSecondDateReportsExtractionDataset: (data) =>
        set(
          {
            secondDateReportsExtractionDataset: data ? { ...data, id: 2 } : undefined,
            reportScreenshotSrc: initialState.reportScreenshotSrc,
          },
          false,
          'reports/setSecondDateReportsExtractionDataset',
        ),
      clearReportsExtractionDataset: (datasetId) =>
        set(
          (state) => {
            return {
              firstDateReportsExtractionDataset: datasetId === 2 ? state.firstDateReportsExtractionDataset : undefined,
              secondDateReportsExtractionDataset: undefined,
              reportScreenshotSrc: initialState.reportScreenshotSrc,
            };
          },
          false,
          'reports/clearReportsExtractionDataset',
        ),
      isReportGenerating: initialState.isReportGenerating,
      setIsReportGenerating: (isGenerating) =>
        set({ isReportGenerating: isGenerating }, false, 'reports/setIsReportGenerating'),
      clearReports: () => set(initialState, false, 'reports/clearReports'),
    }),
    { name: 'reportsStore' },
  ),
);

export const useReportsStore = createSelectors(useReportsStoreBase);
