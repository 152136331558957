import { type AxiosResponse } from 'axios';

import { restAPI } from 'services/rest/restApiService';
import { CALCULATIONS_API_URLS } from 'infrastructure/calculations/calculationsApiUrls';
import { CALCULATIONS_API_PREFIX } from 'infrastructure/calculations/constants';
import { type CalculationRequest, type CalculationResponse } from 'domain/calculations/types';

export const calculationsAPI = {
  postRequest: (request: CalculationRequest): Promise<AxiosResponse<CalculationResponse>> =>
    restAPI.services.post(`${CALCULATIONS_API_PREFIX}/api/v1${CALCULATIONS_API_URLS.requests}`, request),

  getStatus: (requestId: string): Promise<CalculationResponse> =>
    restAPI.services.get(
      `${CALCULATIONS_API_PREFIX}/api/v1${CALCULATIONS_API_URLS.status.replace(':requestId', requestId)}`,
    ),
};
