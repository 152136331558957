import { useLocation } from 'react-router';

import { calculatePolygonCenter, getDisplayableMapPoint } from 'common/utils/map';
import { SegmentationReportsExternalData } from 'views/reports/external/data/SegmentationReportsExternalData';
import { WaterAreaReportsExternalData } from 'views/reports/external/data/WaterAreaReportsExternalData';
import { WaterQualityReportsExternalData } from 'views/reports/external/data/WaterQualityReportsExternalData';
import { ExtractionReportsExternalData } from 'views/reports/external/data/ExtractionReportsExternalData';
import { VegetationReportsExternalData } from 'views/reports/external/data/VegetationReportsExternalData';
import { useTimelineService } from 'components/Timeline/timelineService';
import { useAoiNumber } from 'common/navigation/hooks';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { WaterActiveTab } from 'domain/water/enums';
import { MINE_EXTRACTION_PATH_NAME } from 'domain/extraction/constants';
import { EXTERNAL_REPORT_SURFACE_AREA_UNIT } from 'domain/reports/constants';
import { type AreaOfInterestDetails } from 'domain/areas/types';

export const ReportsExternalData = ({ areaDetails }: { areaDetails: AreaOfInterestDetails }) => {
  const { pathname } = useLocation();
  const areaId = useAoiNumber();

  const { timestamp } = useTimelineService();

  const lngLat = calculatePolygonCenter(areaDetails.geometry.coordinates[0]);
  const areaLocation = getDisplayableMapPoint(lngLat);

  const reportInfo = {
    areaName: areaDetails.properties.display_name,
    areaLocation: {
      lat: areaLocation.lat,
      lng: areaLocation.lng,
    },
    areaSize: {
      value: areaDetails.properties.area * 100,
      unit: EXTERNAL_REPORT_SURFACE_AREA_UNIT,
    },
  };

  switch (true) {
    case pathname.includes(GreeneryActiveTab.SEGMENTATION):
      return areaId && <SegmentationReportsExternalData areaId={areaId} reportInfo={reportInfo} />;

    case pathname.includes(GreeneryActiveTab.VEGETATION):
      return (
        areaId &&
        timestamp && <VegetationReportsExternalData areaId={areaId} timestamp={timestamp} reportInfo={reportInfo} />
      );

    case pathname.includes(WaterActiveTab.SURFACE):
      return (
        areaId &&
        timestamp && <WaterAreaReportsExternalData areaId={areaId} timestamp={timestamp} reportInfo={reportInfo} />
      );

    case pathname.includes(WaterActiveTab.QUALITY):
      return (
        areaId &&
        timestamp && <WaterQualityReportsExternalData areaId={areaId} timestamp={timestamp} reportInfo={reportInfo} />
      );

    case pathname.includes(MINE_EXTRACTION_PATH_NAME):
      return <ExtractionReportsExternalData timestamp={timestamp} reportInfo={reportInfo} />;

    default:
      return null;
  }
};
