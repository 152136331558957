import { useEffect } from 'react';
import styled from 'styled-components';

import { Button, Select } from 'ui/atoms';
import { type ValueAndLabel } from 'common/types';
import { mapQuarterlyParamsToOptions } from 'common/utils/datetime';
import { fadeIn } from 'common/animations';

interface QuarterlyComparatorProps {
  comparatorData: {
    comparedDatesOptions: string[] | undefined;
    firstComparedDate: string | null | undefined;
    isFetching: boolean;
    secondComparedDate?: string | null | undefined;
    label?: string;
  };
  setFirstComparedDate: (firstComparedDate: string) => void;
  setSecondComparedDate?: (secondComparedDate: string) => void;
  locked?: boolean;
  setLocked?: (locked: boolean) => void;
  onGenerate?: () => void;
}

export const QuarterlyComparator = ({
  comparatorData: { comparedDatesOptions, firstComparedDate, isFetching, secondComparedDate, label },
  setFirstComparedDate,
  setSecondComparedDate,
  onGenerate,
  locked,
  setLocked,
}: QuarterlyComparatorProps) => {
  useEffect(() => {
    if (!firstComparedDate && comparedDatesOptions) {
      setFirstComparedDate(comparedDatesOptions.filter((date) => date !== secondComparedDate)[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!secondComparedDate && comparedDatesOptions) {
      setSecondComparedDate &&
        setSecondComparedDate(comparedDatesOptions.filter((date) => date !== firstComparedDate)[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedDatesOptions, firstComparedDate, setSecondComparedDate]);

  const availableBaseDateOptions = comparedDatesOptions && comparedDatesOptions.slice(0, -1);

  const firstDateSelectOptions =
    availableBaseDateOptions &&
    mapQuarterlyParamsToOptions(
      availableBaseDateOptions
        .filter((date) => date !== secondComparedDate)
        .sort((a, b) => Date.parse(b) - Date.parse(a)),
    );
  const firstDateSelectChosenOption = firstComparedDate
    ? mapQuarterlyParamsToOptions([firstComparedDate])[0]
    : undefined;

  const availableSecondDateSelectOptions = comparedDatesOptions
    ? comparedDatesOptions.filter((option) => (firstComparedDate ? option < firstComparedDate : option))
    : [];

  const secondDateSelectOptions = mapQuarterlyParamsToOptions(
    availableSecondDateSelectOptions.filter((date) => date !== firstComparedDate),
  );

  const secondDateSelectChosenOption = secondComparedDate
    ? mapQuarterlyParamsToOptions([secondComparedDate])[0]
    : undefined;

  const isFirstDateEarlier = firstComparedDate && secondComparedDate && firstComparedDate < secondComparedDate;

  const onFirstDateChange = (option: ValueAndLabel) => {
    setFirstComparedDate(String(option.value));
    setLocked && setLocked(false);
  };

  const onSecondDateChange = (option: ValueAndLabel) => {
    setSecondComparedDate && setSecondComparedDate(String(option.value));
    setLocked && setLocked(false);
  };

  const onButtonClick = () => {
    firstComparedDate && onGenerate && onGenerate();
    setLocked && setLocked(true);
  };

  return (
    <ComparatorWrapper inline={!!label}>
      <Select
        {...{
          options: firstDateSelectOptions,
          selectedOption: firstDateSelectChosenOption,
          initialStateLabel: 'Select',
          noOptionsLabel: 'No Options',
          title: label || '',
          name: 'first date selection',
          isFetching: isFetching,
        }}
        onChange={onFirstDateChange}
        minWidth={160}
      />
      <Select
        {...{
          options: secondDateSelectOptions,
          selectedOption: isFirstDateEarlier
            ? mapQuarterlyParamsToOptions(availableSecondDateSelectOptions)[0]
            : secondDateSelectChosenOption,
          initialStateLabel: 'Select',
          noOptionsLabel: 'No Options',
          title: label || '',
          name: 'second date selection',
          isFetching: isFetching || !setSecondComparedDate,
        }}
        onChange={onSecondDateChange}
        minWidth={160}
      />
      <Button
        variant="LIGHT"
        onClick={onButtonClick}
        disabled={locked || !firstDateSelectChosenOption || !secondDateSelectChosenOption}
      >
        Generate
      </Button>
    </ComparatorWrapper>
  );
};

const ComparatorWrapper = styled.div<{ inline: boolean }>`
  margin-top: ${({ inline }) => (inline ? 0 : 54)}px;
  margin-left: ${({ inline }) => (inline ? '10px' : '70px')};
  margin-right: auto;
  display: flex;
  min-width: 240px;
  z-index: 1001;
  background-color: transparent;
  animation: ${fadeIn} 0.5s ease-out;
  gap: 10px;
`;
