import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { type Position, type Feature, type Polygon as PolygonType } from 'geojson';

import { createPolygonJsonFromPoints } from 'ui/map/areaSelector/helpers';
import { Polygon } from 'ui/map/areaSelector/Polygon';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { useDeleteFeatures } from 'views/areas/areaRequestNew/useDeleteFeatures';

interface SelectionBoxProps {
  enabled?: boolean;
  setFeatures: (features: Feature<PolygonType>[]) => void;
  alert: boolean;
}

export const DrawRectangle = ({ enabled, setFeatures, alert }: SelectionBoxProps) => {
  const map = useMap();
  const onFinish = useAreaRequestStore.use.disableDrawMode();
  const { deleteFeatures } = useDeleteFeatures();

  const [firstCorner, setFirstCorner] = useState<Position | undefined>();
  const [cursor, setCursor] = useState<Position | undefined>();

  const onCreate = useCallback(() => {
    if (firstCorner && cursor) {
      setFeatures([createPolygonJsonFromPoints([firstCorner, cursor])]);
    }

    setFirstCorner(undefined);
    onFinish();
  }, [cursor, firstCorner, onFinish, setFeatures]);

  const isFirstCornerChosen = !!firstCorner;

  useEffect(() => {
    if (enabled) {
      setFirstCorner(undefined);
      setCursor(undefined);

      map.off('click');
      map.off('mousemove');
    }
  }, [enabled, map]);

  useEffect(() => {
    if (enabled) {
      map.on('click', async (event: { originalEvent: MouseEvent }) => {
        const latlng = await map.mouseEventToLatLng(event.originalEvent);

        !isFirstCornerChosen ? setFirstCorner([latlng.lat, latlng.lng]) : onCreate();
      });
    }

    return () => {
      map.off('click');
    };
  }, [enabled, isFirstCornerChosen, map, onCreate]);

  useEffect(() => {
    enabled &&
      map.on('mousemove', async (event: { originalEvent: MouseEvent }) => {
        const latlng = await map.mouseEventToLatLng(event.originalEvent);

        firstCorner ? setCursor([latlng.lat, latlng.lng]) : undefined;
      });

    return () => {
      map.off('mousemove');
      setCursor(undefined);
    };
  }, [enabled, firstCorner, map]);

  useEffect(() => {
    const handleRightClick = (event: MouseEvent) => {
      if (!isFirstCornerChosen) return;

      event.preventDefault();

      setFirstCorner(undefined);
      deleteFeatures();
    };

    window.addEventListener('contextmenu', handleRightClick);

    return () => {
      window.removeEventListener('contextmenu', handleRightClick);
    };
  }, [isFirstCornerChosen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    firstCorner &&
    cursor && (
      <Polygon
        coordinates={[
          [firstCorner[0], firstCorner[1]],
          [firstCorner[0], cursor[1]],
          [cursor[0], cursor[1]],
          [cursor[0], firstCorner[1]],
        ]}
        minLength={4}
        onVertexClick={() => {}}
        alert={alert}
      />
    )
  );
};
