import styled from 'styled-components';

import { Chevron } from 'ui/atoms';

interface ReturnButtonProps {
  label: string;
  onClick: () => void;
  visible?: boolean;
}

export const ReturnButton = ({ label, visible, onClick }: ReturnButtonProps) => (
  <ReturnButtonWrapper visible={!!visible}>
    <StyledButton onClick={onClick} aria-label={label}>
      <Chevron variant="LEFT" width={12} />
      {label}
    </StyledButton>
  </ReturnButtonWrapper>
);

const ReturnButtonWrapper = styled.div<{ visible: boolean }>`
  z-index: 200;
  min-height: ${({ visible }) => (visible ? '40px' : '0px')};
  max-height: ${({ visible }) => (visible ? '40px' : '0px')};
  margin-bottom: ${({ visible }) => (visible ? '8px' : '0')};
  transition: max-height ${({ theme }) => theme.transitionFunction.main},
    min-height ${({ theme }) => theme.transitionFunction.main};
  overflow: hidden;
`;

const StyledButton = styled.button`
  height: 40px;
  width: auto;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.color.gallery};
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.color.suvaGray};
  }
`;
