import { type ComponentProps, type CSSProperties, type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { type Palette } from 'ui/types';

type LinkVariants = 'PRIMARY' | 'PRIMARY_SMALL' | 'SECONDARY' | 'SECONDARY_SMALL' | 'TERTIARY';

type LinkStyle = Required<
  Pick<
    CSSProperties,
    'color' | 'backgroundColor' | 'fontSize' | 'fontWeight' | 'textTransform' | 'letterSpacing' | 'padding'
  > & {
    height: number;
    hoverColor?: CSSProperties['color'];
    hoverBackgroundColor?: CSSProperties['backgroundColor'];
  }
>;

const basicStyle: LinkStyle = {
  color: 'white',
  backgroundColor: 'selectGray',
  hoverColor: 'white',
  hoverBackgroundColor: 'mineShaftLight',
  height: 30,
  fontSize: 14,
  fontWeight: 600,
  textTransform: 'none',
  letterSpacing: 0,
  padding: '0 15px',
};

const styles: Palette<LinkVariants, LinkStyle> = {
  PRIMARY: {
    ...basicStyle,
    height: 40,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    backgroundColor: 'primary',
    color: 'white',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
  },
  PRIMARY_SMALL: {
    ...basicStyle,
    textTransform: 'capitalize',
    backgroundColor: 'primary',
    color: 'white',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
  },
  SECONDARY: {
    ...basicStyle,
    height: 40,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
  },
  SECONDARY_SMALL: {
    ...basicStyle,
    textTransform: 'capitalize',
  },
  TERTIARY: {
    ...basicStyle,
    height: 40,
    textTransform: 'uppercase',
    letterSpacing: 0.15,
    backgroundColor: 'donkeyBrown',
    color: 'white',
    hoverColor: 'white',
    hoverBackgroundColor: 'shadow',
  },
};

export interface LinkProps extends ComponentProps<'a'> {
  children: ReactNode;
  name?: string;
  variant?: LinkVariants;
  minWidth?: number;
  disabled?: boolean;
  sameSite?: boolean;
  squared?: boolean;
}

export const Link = ({
  children,
  name,
  variant = 'PRIMARY',
  href,
  disabled = false,
  minWidth = 100,
  sameSite = false,
  squared = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: LinkProps) => {
  return (
    <StyledLink
      {...{ variant, squared, href, disabled, minWidth, onClick, onMouseEnter, onMouseLeave }}
      data-testid={`link-${name || 'generic'}`}
      target={sameSite ? '_self' : '_blank'}
      rel={sameSite ? '' : 'noopener noreferrer'}
    >
      {children}
    </StyledLink>
  );
};

interface StyledLinkProps {
  variant?: LinkVariants;
  squared?: boolean;
  disabled: boolean;
  minWidth: number;
}

const StyledLink = styled.a<StyledLinkProps>`
  color: ${({ theme, variant }) => theme.color[styles[variant!].color]};
  background-color: ${({ theme, variant }) => theme.color[styles[variant!].backgroundColor]};
  height: ${({ variant }) => styles[variant!].height}px;
  width: 100%;
  min-width: ${({ minWidth }) => minWidth}px;
  border-radius: ${({ variant, squared }) => (squared ? 0 : styles[variant!].height / 2)}px;
  outline: none;
  border: none;
  padding: ${({ variant }) => styles[variant!].padding};
  cursor: pointer;
  font-family: 'Barlow', sans-serif;
  font-weight: ${({ variant }) => styles[variant!].fontWeight};
  font-size: ${({ variant }) => styles[variant!].fontSize}px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: ${({ variant }) => styles[variant!].letterSpacing}em;
  text-transform: ${({ variant }) => styles[variant!].textTransform};
  transition: background-color ${({ theme }) => theme.transitionFunction.main};
  user-select: none;
  text-decoration: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:hover {
    color: ${({ theme, variant }) => theme.color[styles[variant!].hoverColor]};
    background-color: ${({ theme, variant }) => theme.color[styles[variant!].hoverBackgroundColor]};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.color.lightGray};
      background-color: ${({ theme }) => theme.color.selectGray};
      pointer-events: none;
    `}
`;

Link.displayName = 'Link';
