import styled from 'styled-components';

import { Notification } from 'ui/atoms/notification';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { AreaInfoEnvironmentsIcons } from 'domain/areas/AreaInfoEnvironmentsIcons';
import type { NotificationType } from 'domain/monitoring/types';
import { ReactComponent as BaseChevronRightIcon } from './chevron-bold-right.svg';

interface NotificationsGroupProps {
  title: string;
  notifications: NotificationType[] | undefined;
}

export const NotificationsGroup = ({ title, notifications }: NotificationsGroupProps) => {
  const closeSidebarPanel = useSidebarStore.use.closeSidebarPanel();

  return (
    notifications && (
      <div>
        <NotificationsTitle>{title}</NotificationsTitle>
        <NotificationsList>
          {notifications.map((item, i) => (
            <li key={i}>
              <Notification
                variant={item.properties.danger ? 'DANGER' : 'PRIMARY'}
                to={item.properties.route}
                onClick={closeSidebarPanel}
                $width="100%"
                icon={AreaInfoEnvironmentsIcons[item.properties.view].icon}
                $iconSize={AreaInfoEnvironmentsIcons[item.properties.view].$iconSize}
              >
                <NotificationText>
                  {item.properties.text}
                  <BaseChevronRightIcon width={10} height={10} />
                </NotificationText>
              </Notification>
            </li>
          ))}
        </NotificationsList>
      </div>
    )
  );
};

const NotificationsTitle = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.white};
  padding-bottom: 7px;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGray};
`;

const NotificationsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NotificationText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 9px;
  color: ${({ theme }) => theme.color.white};
`;
