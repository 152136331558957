import styled, { css } from 'styled-components';

interface PageBackgroundProps {
  children: React.ReactNode;
  background?: string;
  color?: string;
  width?: number;
  height?: number;
  bordered?: boolean;
  padding?: number;
}

export const PageBackground = styled.div<PageBackgroundProps>`
  background: ${(props) => props.background || 'rgba(28, 26, 25, 0.5)'};
  color: ${(props) => props.color || '#fff'};
  width: ${(props) => props.width || 300}px;
  height: ${(props) => props.height || 300}px;
  padding: ${(props) => props.padding || 0}px;
  display: grid;
  place-items: center;
  border: none;
  overflow: hidden;

  ${({ bordered }) =>
    bordered &&
    css`
      background-color: lightgray;
      border: 1px solid #000;
    `}
`;

export const InvisibleContainer = styled.div<{ width?: number; height?: number }>`
  padding: 12px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const ComponentBorder = styled.div`
  border: 1px solid red;
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
  min-width: 800px;
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.mineShaftDark};
  overflow: auto;
`;

export const WithCustomScrollbarWrapper = styled.div`
  ::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.mineShaftLight};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: 0.8;
  }

  @supports (scrollbar-color: #000 #fff) {
    scrollbar-color: ${({ theme }) => theme.color.mineShaftLight} transparent;
    scrollbar-width: thin;
  }
`;
