import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { ErrorsModel } from 'domain/@errors/ErrorsModel';
import { WarningErrorType } from 'domain/@errors/enums';

export const useReportWeatherDataError = (path: string) => {
  const updateWarningError = useErrorsStore.use.updateWarningError();

  const reportError = (message: string) => {
    const errorCause = ErrorsModel.getErrorCause(message);
    updateWarningError({
      [WarningErrorType.WEATHER_DATA]: {
        type: WarningErrorType.WEATHER_DATA,
        viewPath: path,
        errorCause,
      },
    });
  };

  return { reportError };
};
