import { Fragment } from 'react';
import { TileLayer } from 'react-leaflet';

import { type UrlTuple } from 'common/types';
import { useGetCogWithBounds } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import * as map from 'components/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { WATER_AREA_GAIN_COLOR, WATER_AREA_LOSS_COLOR } from 'domain/water/colors';
import { useGetWaterAreaComparatorCogsUseCase } from './getWaterAreaComparatorCogsUseCase';

export const WaterAreaMapContentComparison = () => {
  const dataLayerVisibility: boolean = useLayerManagerStore.use.isDataLayerVisible();
  const { comparatorCogsUrlTuples } = useGetWaterAreaComparatorCogsUseCase();

  return (
    <map.Layer visible={dataLayerVisibility} name={'waterComparisonLayer'} zIndex={495}>
      {comparatorCogsUrlTuples
        ? comparatorCogsUrlTuples.map((urlTuple) => (
            <WaterAreaComparisonMapLayer key={urlTuple[0]} urlTuple={urlTuple} pane={'waterComparisonLayer'} />
          ))
        : null}
    </map.Layer>
  );
};

interface WaterAreaComparisonMapLayerProps {
  urlTuple: UrlTuple;
  pane: string;
}

const WaterAreaComparisonMapLayer = ({ urlTuple, pane }: WaterAreaComparisonMapLayerProps) => {
  const { cogUrl, cogBounds } = useGetCogWithBounds(titilerApiAdapter.buildComparatorImageUrl, urlTuple[1], {
    currentLayerId: 1,
    gainColor: WATER_AREA_GAIN_COLOR,
    lossColor: WATER_AREA_LOSS_COLOR,
  });

  return (
    <Fragment key={urlTuple[0]}>{cogBounds && <TileLayer pane={pane} url={cogUrl} bounds={cogBounds} />}</Fragment>
  );
};
