import { type LayerObject } from 'common/types/mapData';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { SingleDateReportsCreatorContent } from 'views/reports/creator/content/SingleDateReportsCreatorContent';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { useAvailableLayers } from 'views/greenery/segmentation/useAvailableLayers';

interface SegmentationSingleDateReportsCreatorContentProps {
  greeneryObjects: LayerObject[];
  greeneryObjectsLoading: boolean;
  timestamp: string;
  areaId: number;
  cogUrl: string;
}

export const SegmentationSingleDateReportsCreatorContent = ({
  greeneryObjects,
  greeneryObjectsLoading,
  timestamp,
  areaId,
  cogUrl,
}: SegmentationSingleDateReportsCreatorContentProps) => {
  const { availableLayers } = useAvailableLayers(cogUrl, greeneryObjects);

  const { classesVisibility: layersVisibility, toggleMultipleQueries: toggleLayerVisibility } =
    useGreeneryLayersVisibility();

  const { segmentationAreaResults, areSegmentationAreaResultsLoading } =
    greeneryRepository.useFetchSegmentationAreaResults(areaId, timestamp);

  const isLoading = greeneryObjectsLoading || areSegmentationAreaResultsLoading;

  const data = greeneryObjects
    .filter(({ name }) => availableLayers?.includes(name))
    .filter(({ id }) => segmentationAreaResults.some(({ greenery_object_id }) => greenery_object_id === id))
    .map(({ id, name, label_name }) => ({
      id,
      label: label_name,
      checked: !!layersVisibility[name],
      onChange: () => toggleLayerVisibility(name),
    }));

  return <SingleDateReportsCreatorContent data={data} isLoading={isLoading} />;
};
