import { useEffect } from 'react';

import { type ListItemStatus } from 'common/types';
import { AppLayerManager } from 'components/layerManager/legacy';
import { LayerManagerLegendItem } from 'components/layerManager/legacy/LegendItem';
import { HABITATS_MAP_OCCURENCE_LAYER_COLOR, HABITATS_MAP_PREDICTION_LAYER_COLOR } from 'domain/greenery/colors';
import {
  HABITATS_EXPANDED_AREA_LAYER_NAME,
  HABITATS_OCCURENCE_LAYER_NAME,
  HABITATS_PREDICTION_LAYER_NAME,
} from 'domain/greenery/constants';
import { useHabitatsLayersVisibility } from './useHabitatsLayersVisibility';

export const HabitatsLayerManager = () => {
  const { classesVisibility, updateClassesVisibility } = useHabitatsLayersVisibility();

  const layerManagerOptions = [
    {
      id: 1,
      name: HABITATS_EXPANDED_AREA_LAYER_NAME,
      label_name: 'Expanded project area',
      color: '',
    },
    {
      id: 2,
      name: HABITATS_OCCURENCE_LAYER_NAME,
      label_name: 'Hamatocaulis lapponicus (HL) Occurrence',
      color: HABITATS_MAP_OCCURENCE_LAYER_COLOR,
    },
    {
      id: 3,
      name: HABITATS_PREDICTION_LAYER_NAME,
      label_name: 'HL Predicted Occurance',
      color: HABITATS_MAP_PREDICTION_LAYER_COLOR,
    },
  ].map((item) => ({ ...item, status: 'active' as ListItemStatus }));

  useEffect(() => {
    if (!Object.values(classesVisibility).some((value) => value === true)) {
      updateClassesVisibility({
        [HABITATS_EXPANDED_AREA_LAYER_NAME]: true,
        [HABITATS_OCCURENCE_LAYER_NAME]: true,
        [HABITATS_PREDICTION_LAYER_NAME]: true,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppLayerManager>
      <>
        {layerManagerOptions.map((option) => (
          <LayerManagerLegendItem
            key={option.id}
            option={option}
            objectVisibility={!!classesVisibility[option.name]}
            handleObjectsVisibility={() =>
              updateClassesVisibility({
                ...classesVisibility,
                [option.name]: !classesVisibility[option.name],
              })
            }
          />
        ))}
      </>
    </AppLayerManager>
  );
};
