import styled, { css } from 'styled-components';

export const LegendItemWraper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

export const AuxiliaryControlsWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};
`;

export const LabeledSliderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
`;
