import { useMapStore } from 'components/map/mapStore';

export const useMapAlign = () => {
  const setMapAlign = useMapStore.use.setMapAlign();
  const resetMapAlign = useMapStore.use.resetMapAlign();

  const alignMap = () => {
    setMapAlign();

    setTimeout(() => resetMapAlign());
  };

  return alignMap;
};
