import { type ColormapDictionary } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { buildDiscreteColorArray, normalizeColormapObject } from 'common/utils/color';
import { getAzureBlobStorageUrl, sanitizeMlStorageUrl } from './utils';
import { TITILER_PREFIX, TITILER_COG_API_URLS } from './titilerApiUrls';

export const titilerApiAdapter = {
  buildSatelliteImageUrl: (
    url: string,
    params: { rescaleArray: number[][]; bidxArray: string[]; colorFormula: string },
  ) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    params.rescaleArray.forEach((rescale) => {
      cogQuery.append('rescale', rescale.toString());
    });
    params.bidxArray.forEach((bidx) => {
      cogQuery.append('bidx', bidx);
    });
    cogQuery.append('color_formula', `${params.colorFormula}`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildGrayscaleSatelliteImageUrl: (url: string, params: { rescale: number[]; bidx: string; colorMap: string }) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    cogQuery.append('rescale', `${params.rescale.toString()}`);
    cogQuery.append('colormap', `${params.colorMap}`);
    cogQuery.append('colormap_type', 'linear');
    cogQuery.append('bidx', params.bidx);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildGenericRGBImageUrl: (url: string, params: { expression: string; rescaleFactors: number[][] }) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    cogQuery.append('expression', params.expression);
    params.rescaleFactors.forEach((rescaleFactor) => {
      cogQuery.append('rescale', rescaleFactor.toString());
    });

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildGrayscaleTitilerImageUrl: (
    url: string,
    params: {
      bidx: string;
      maxSize: number;
      imageHeight: number;
      imageWidth: number;
      rescale: number[];
      colorMap: string;
    },
  ) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getImage);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    cogQuery.append('bidx', params.bidx);
    cogQuery.append('max_size', `${params.maxSize}`);
    cogQuery.append('height', `${params.imageHeight}`);
    cogQuery.append('width', `${params.imageWidth}`);
    cogQuery.append('rescale', `${params.rescale.toString()}`);
    cogQuery.append('color_map', `${params.colorMap}`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildGenericPreviewTifUrl: (url: string) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getTif);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    cogQuery.append('max_size', `4096`);
    cogQuery.append('return_mask', `false`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildGenericMonocolorCogUrl: (
    url: string,
    params: {
      colormap: string;
      expression: string;
    },
  ) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    cogQuery.append('colormap', `${params.colormap}`);
    cogQuery.append('expression', `${params.expression}`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildMultilayerMonocolorCogUrlGeneric: (
    url: string | undefined,
    params: {
      objects: LayerObject[];
      nodataValue?: number | string;
    },
  ) => {
    if (typeof url !== 'string') {
      return;
    }

    const activeObjects = params.objects;

    if (activeObjects.length === 0) {
      return;
    }

    const colormap = activeObjects.map(({ id, color }) => `"${id}":"${color}"`).join(', ');
    const expression = activeObjects.map(({ id }) => `${id}*b${id}`).join('+');

    return TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog)
      .concat(`?url=${url}`)
      .concat(`&colormap={${encodeURIComponent(colormap)}}`)
      .concat(
        `${params.nodataValue ? `&nodata=${params.nodataValue}` : `&expression=${encodeURIComponent(expression)}`}`,
      );
  },

  buildMonocolorFloatCogUrl: (url: string | undefined, params: { color: string }) => {
    if (typeof url !== 'string') {
      return;
    }

    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('bidx', '1');
    cogQuery.append('rescale', '0,1');
    cogQuery.append('colormap', `{"255": "${params.color}"}`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildDiscreteColorScaleFloatCogUrl: (
    url: string | undefined,
    params: { range: number[]; colormapObject: ColormapDictionary; noDataValue: string | number },
  ): string | undefined => {
    if (typeof url !== 'string') {
      return;
    }

    const segmentedColorArray = buildDiscreteColorArray(params.colormapObject, params.range);
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    cogQuery.append('nodata', `${String(params.noDataValue)}`);
    cogQuery.append('colormap', JSON.stringify(segmentedColorArray));

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildNamedColorScaleIndicesCogUrl: (
    url: string | undefined,
    params: { expression: string; colormap: string; noDataValue: string | number },
  ) => {
    if (typeof url !== 'string') {
      return;
    }

    const baseResultUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('expression', params.expression);
    cogQuery.append('colormap', params.colormap);
    cogQuery.append('nodata', `${String(params.noDataValue)}`);

    return baseResultUrl.concat(`?${cogQuery.toString()}`);
  },

  buildNamedColorScaleSpectralCogUrl: (
    url: string | undefined,
    params: { range: number[]; expression: string; colormapName: string; noDataValue: string | number },
  ) => {
    if (typeof url !== 'string') {
      return;
    }

    const baseResultUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('expression', `${params.expression}`);
    cogQuery.append('rescale', `${params.range[0]},${params.range[1]}`);
    cogQuery.append('colormap_name', `${params.colormapName}`);
    cogQuery.append('nodata', `${String(params.noDataValue)}`);

    return baseResultUrl.concat(`?${cogQuery.toString()}`);
  },

  buildNamedColorScaleFloatCogUrl: (
    url: string | undefined,
    params: { range: number[]; colormapName: string; noDataValue: string | number },
  ) => {
    if (typeof url !== 'string') {
      return;
    }

    const baseResultUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('rescale', `${params.range[0]},${params.range[1]}`);
    cogQuery.append('tileformat', 'png');
    cogQuery.append('colormap_name', `${params.colormapName}`);
    cogQuery.append('nodata', `${String(params.noDataValue)}`);

    return baseResultUrl.concat(`?${cogQuery.toString()}`);
  },

  buildComparatorImageUrl: (
    url: string | undefined,
    params: { currentLayerId: number; gainColor: string; lossColor: string; noChangeColor?: string },
  ) => {
    if (typeof url !== 'string') {
      return;
    }

    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('bidx', `${params.currentLayerId}`);
    cogQuery.append(
      'colormap',
      `{${params.noChangeColor ? `"0": "${params.noChangeColor}", ` : ''}"1":"${params.gainColor}", "255":"${
        params.lossColor
      }"}`,
    );

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildGenericGeorasterInfoUrl: (url: string) =>
    TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCogInfo).concat(`?url=${url}`),

  buildGeorasterStatisticsUrl: (
    url: string,
    props: { expression?: string; range?: number[]; bins?: number; bidx?: number },
  ) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCogStatistics);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    props.expression && cogQuery.append('expression', props.expression);
    props.range && cogQuery.append('histogram_range', `${props.range}`);
    props.bins && cogQuery.append('histogram_bins', `${props.bins}`);
    props.bidx && cogQuery.append('bidx', `${props.bidx}`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildGenericGeorasterPointInfoUrl: (url: string, lat: number, lng: number, layer?: number) =>
    TITILER_PREFIX.concat(TITILER_COG_API_URLS.getPointInfo).concat(
      `/${lat},${lng}?url=${url}${layer ? `&expression=b${layer}` : ''}`,
    ),

  buildGenericSwappedCogPointUrl: (url: string, lng: number, lat: number, bidx: number) => {
    const baseUrl = TITILER_PREFIX.concat(
      TITILER_COG_API_URLS.getCogPoint.replace('{lng}', `${lng}`).replace('{lat}', `${lat}`),
    );

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', url);
    cogQuery.append('bidx', `${bidx}`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildDefinedColorScaleFloatCogUrl: (
    url: string | undefined,
    params: { range: number[]; colormapObject: ColormapDictionary; noDataValue: string | number },
  ) => {
    if (typeof url !== 'string') {
      return;
    }

    const normalizedColormapObject = normalizeColormapObject(params.colormapObject);

    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('nodata', `${String(params.noDataValue)}`);
    cogQuery.append('rescale', `${params.range[0]},${params.range[1]}`);
    cogQuery.append('colormap', JSON.stringify(normalizedColormapObject));
    cogQuery.append('colormap_type', 'linear');

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildCogUrlWithColormapName: (
    url: string,
    params: { range: number[]; colormapName: string; noDataValue: string | number },
  ) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('rescale', `${params.range[0]},${params.range[1]}`);
    cogQuery.append('colormap_name', `${params.colormapName}`);
    cogQuery.append('nodata', `${String(params.noDataValue)}`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildExternalGeorasterInfoUrl: (url: string) =>
    TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCogInfo).concat(`?url=${sanitizeMlStorageUrl(url)}`),

  buildHillshadeCogUrl: (url: string) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('bidx', '1');
    cogQuery.append('expression', 'b1');
    cogQuery.append('colormap_name', 'gray');
    cogQuery.append('colormap_type', 'explicit');

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },

  buildDEMColormapUrl: (url: string, params: { range: number[] }) => {
    const baseUrl = TITILER_PREFIX.concat(TITILER_COG_API_URLS.getCog);

    const cogQuery = new URLSearchParams();
    cogQuery.append('url', getAzureBlobStorageUrl(url));
    cogQuery.append('rescale', `${params.range[0]},${params.range[1]}`);
    cogQuery.append('colormap_name', 'terrain');
    cogQuery.append('nodata', `0`);

    return baseUrl.concat(`?${cogQuery.toString()}`);
  },
};
