import { getApiData, restAPI } from 'services/rest/restApiService';
import { DATA_REQUEST_API_URLS } from 'infrastructure/dataRequest/dataRequestApiUrls';
import { type DataRequestOrder } from 'domain/dataRequest/types';

export const dataRequestAPI = {
  getDataTypes: ({ limit, offset }: { limit?: number; offset?: number }) =>
    getApiData(DATA_REQUEST_API_URLS.getDataTypes, { limit, offset }),

  getAnalysisTypes: ({ limit, offset }: { limit?: number; offset?: number }) =>
    getApiData(DATA_REQUEST_API_URLS.getAnalysisTypes, { limit, offset }),

  postOrder: (order: DataRequestOrder) => restAPI.private.post(DATA_REQUEST_API_URLS.postOrder, order),
};
