import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'common/navigation/routes';
import { timestampToQuarterlyParams } from 'components/Timeline/helpers';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { calculationsRepository } from 'infrastructure/calculations/calculationsRepository';
import { useUserStore } from 'infrastructure/user/userStore';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { type SubscriptionRequestAction, type SubscriptionRequest } from 'domain/subscription/types';
import { type AoiData, type SummaryVariant } from 'domain/areas/types';
import { isAoiDataValid } from 'domain/areas/typeguards';

export const useHandleRequestSubmit = (
  initialAoiData: AoiData,
  onSubscriptionRequest: (request: SubscriptionRequest, action: SubscriptionRequestAction) => void,
  showSummary: (variant: SummaryVariant) => void,
  clearCreator: () => void,
) => {
  const navigate = useNavigate();

  const setRequestStatus = useAreaRequestStore.use.setRequestStatus();
  const requestStatus = useAreaRequestStore.use.requestStatus();
  const currentDeposit = useAreaRequestStore.use.currentDeposit();
  const requestedQuarterEnd = useAreaRequestStore.use.requestedQuarterEnd();
  const selectedSubscription = useAreaRequestStore.use.selectedSubscription();
  const suppliersSelection = useAreaRequestStore.use.suppliersSelection();
  const selectedSuppliers = Object.entries(suppliersSelection)
    .filter((item) => !!item[1])
    .map((item) => item[0]);

  const requestedQuarter = requestedQuarterEnd ? timestampToQuarterlyParams(requestedQuarterEnd) : undefined;

  const { clientSecret, sessionId } = useUserStore();

  const { setCalculationsPayloadPartial: setCalculationsPayload } = calculationsRepository.useCalculationsPayload();

  const { setAreaRequestPayload } = areasRepository.useAreaRequestPayload();

  const handleRequestSubmit = async () => {
    if (isAoiDataValid(initialAoiData)) {
      setAreaRequestPayload(initialAoiData);
    } else {
      return;
    }

    if (!currentDeposit || !requestedQuarter || !selectedSuppliers.length) return;

    const { year, quarter } = requestedQuarter;

    setRequestStatus({ mode: 'REQUEST_SUBMITTED' });

    if (requestStatus.mode === 'CUSTOM_CONTRACT') {
      clearCreator();
      showSummary('custom');
      return;
    }

    if (requestStatus.mode === 'REQUEST_ALLOWED' && selectedSubscription) {
      clearCreator();
      navigate(ROUTES.areasList.concat('?summary=request'));
      setCalculationsPayload({
        deposit: currentDeposit,
        quarter,
        year,
        subscriptionName: selectedSubscription.name,
        providers: selectedSuppliers,
      });
    }

    if (['TRIAL_ENABLED', 'PAYMENT_REQUIRED'].includes(requestStatus.mode) && selectedSubscription) {
      if (!clientSecret || !sessionId) {
        setCalculationsPayload({
          deposit: currentDeposit,
          quarter,
          year,
          subscriptionName: selectedSubscription.name,
          providers: selectedSuppliers,
        });

        if (requestStatus.mode === 'PAYMENT_REQUIRED' && requestStatus.isUpgrading) {
          onSubscriptionRequest({ name: selectedSubscription.label, trial: false }, 'UPGRADE');
          return;
        }

        onSubscriptionRequest(
          {
            name: selectedSubscription.label,
            trial: requestStatus.mode === 'TRIAL_ENABLED',
          },
          'CREATE',
        );
        return;
      }

      clearCreator();
      navigate(ROUTES.paymentProcess);
    }
  };

  return { handleRequestSubmit };
};
