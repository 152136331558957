import styled from 'styled-components';

import { ReactComponent as BellIcon } from 'assets/svg/notifications-bell.svg';
import { ReactComponent as QuestionBaloon } from 'assets/svg/question-baloon.svg';
import { IconButton } from 'ui/atoms/iconButton';
import { type IconButtonProps } from 'ui/atoms/iconButton/IconButton';
import { useGetUserInfo } from 'ui/sidebar/useGetUserInfo';
import { getInitials } from 'domain/user/utils';

export const UserIcon = (props: IconButtonProps) => {
  const { userName } = useGetUserInfo();
  const userInitials = getInitials(userName);

  return <IconButton {...props} icon={userInitials} $iconSize={24} description={userName} />;
};

export const NotificationIcon = (props: IconButtonProps) => {
  return (
    <NotificationIconWrapper rotation={props.rotation}>
      <IconButton {...props} icon={<BellIcon />} description="notifications" rotation={undefined} />
    </NotificationIconWrapper>
  );
};

const NotificationIconWrapper = styled.div<{ rotation: number | undefined }>`
  position: relative;
  transform: ${({ rotation }) => `rotate(${rotation}deg)`};
  transition: all ${({ theme }) => theme.transitionFunction.main};

  &&::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.red};
  }
`;

export const AssistantIcon = (props: IconButtonProps) => {
  return <IconButton {...props} icon={<QuestionBaloon />} $iconSize={16} description="open assistant" />;
};
