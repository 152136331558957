import { Fragment } from 'react';
import { MapNoteMarkers } from './MapNoteMarkers';
import { MapWaterAreas } from './MapWaterAreas';
import { MapReclamationAreas } from './MapReclamationAreas';

export const MonitoringMapContent = () => {
  return (
    <Fragment>
      <MapNoteMarkers />
      <MapWaterAreas />
      <MapReclamationAreas />
    </Fragment>
  );
};
