import { type CSSProperties, forwardRef, type ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { Modal as MuiModal } from '@mui/base/Modal';
import clsx from 'clsx';

import { type Palette } from 'ui/types';
import { CloseIcon } from 'ui/atoms/closeIcon';
import { ScrollbarWrapper } from 'ui';

export type ModalVariant = 'DEFAULT' | 'FULLSCREEN';

type ModalStyles = Required<Pick<CSSProperties, 'backgroundColor' | 'backdropFilter' | 'boxShadow'>> & {
  closeIconType: 'SOLID' | 'THIN';
  closeIconSize: number;
};

const styles: Palette<ModalVariant, ModalStyles> = {
  DEFAULT: {
    backgroundColor: 'mineShaftABitLighter',
    backdropFilter: '0',
    closeIconType: 'THIN',
    closeIconSize: 24,
    boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.5)',
  },
  FULLSCREEN: {
    backgroundColor: 'grayTransparent90',
    backdropFilter: 'blur(20px)',
    closeIconType: 'SOLID',
    closeIconSize: 36,
    boxShadow: 'none',
  },
};

export interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  variant?: ModalVariant;
  fullWidth?: boolean;
  maxWidth?: CSSProperties['maxWidth'];
  padding?: CSSProperties['padding'];
}

export const BaseModal = ({ isOpen, closeModal, variant, children, fullWidth, maxWidth, padding }: ModalProps) => (
  <StyledModal aria-labelledby="Modal Popup" open={!!isOpen} onClose={closeModal} slots={{ backdrop: StyledBackdrop }}>
    <div>
      <ScrollbarWrapper>
        <StyledModalContainer fullWidth={fullWidth} maxWidth={maxWidth} padding={padding} $variant={variant}>
          <CloseIconWraper>
            <CloseIcon
              variant={styles[variant!].closeIconType}
              $iconSize={24}
              size={styles[variant!].closeIconSize}
              onClick={closeModal}
            />
          </CloseIconWraper>
          {children}
        </StyledModalContainer>
      </ScrollbarWrapper>
    </div>
  </StyledModal>
);

BaseModal.displayName = 'Modal';
BaseModal.defaultProps = {
  variant: 'DEFAULT' as ModalVariant,
};

// eslint-disable-next-line react/display-name
const Backdrop = forwardRef<HTMLDivElement, { open?: boolean; className: string; ownerState: unknown }>(
  (props, ref) => {
    const {
      open,
      className,
      ownerState: _ownerState,
      ...other
    } = props as unknown as ComponentProps<typeof MuiModal> & {
      ownerState: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    };
    return <div className={clsx({ 'MuiBackdrop-open': open }, className)} ref={ref} {...other} />;
  },
);

const StyledModal = styled(MuiModal)`
  position: fixed;
  z-index: 2100;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  color: ${({ theme }) => theme.color.white};
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.blackTransparent50};
  -webkit-tap-highlight-color: transparent;
`;

interface StyledModalContainerProps {
  $variant?: ModalVariant;
  fullWidth?: boolean;
  maxWidth?: CSSProperties['maxWidth'];
  padding?: CSSProperties['padding'];
}

const StyledModalContainer = styled.div<StyledModalContainerProps>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  max-width: ${({ maxWidth, $variant }) => ($variant !== 'FULLSCREEN' && maxWidth ? `${maxWidth}px` : 'unset')};
  max-height: 90vh;
  padding: ${({ padding }) => padding || 30}px;
  background-color: ${({ theme, $variant }) => theme.color[styles[$variant!].backgroundColor]};
  backdrop-filter: ${({ $variant }) => styles[$variant!].backdropFilter};
  box-shadow: ${({ $variant }) => styles[$variant!].boxShadow};
  position: relative;
  overflow-y: auto;
  outline: none;

  ${({ $variant }) =>
    $variant === 'FULLSCREEN' &&
    css`
      position: fixed;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
    `}
`;

const CloseIconWraper = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 500;
  outline: none;
`;

BaseModal.displayName = 'Modal';

BaseModal.defaultProps = {
  variant: 'DEFAULT' as ModalVariant,
};
