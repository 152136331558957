import { useEffect, useState } from 'react';

import { type LayersVisibilityDictionary } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { FoldableSectionCheckbox } from './foldableSection';

interface TransientStateCheckboxProps {
  title: string;
  description: string;
  options: LayerObject[];
  objectsVisibility: LayersVisibilityDictionary;
  updateLayersVisibility: (layers: LayersVisibilityDictionary) => void;
  disabled?: boolean;
}

export const TransientStateCheckbox = ({
  title,
  description,
  options,
  objectsVisibility,
  updateLayersVisibility,
  disabled,
}: TransientStateCheckboxProps) => {
  const [availableLayers, setAvailableLayers] = useState<LayersVisibilityDictionary>({});
  const [allLayersChecked, setAllLayersChecked] = useState(false);
  const [transitionalState, setTransitionalState] = useState(false);

  useEffect(() => {
    const layers: LayersVisibilityDictionary = {};

    for (const { name } of options) {
      layers[name] = !!objectsVisibility[name];
    }
    setAvailableLayers(layers);

    if (Object.values(layers).every((value) => !value)) {
      setAllLayersChecked(false);
      setTransitionalState(false);
      return;
    }

    if (Object.values(layers).every((value) => value)) {
      setAllLayersChecked(true);
      setTransitionalState(false);
      return;
    }

    setTransitionalState(true);
  }, [options, objectsVisibility]);

  const sameStateLayers = { ...availableLayers };
  Object.keys(sameStateLayers).forEach((key) => (sameStateLayers[key] = !allLayersChecked));

  return (
    <FoldableSectionCheckbox
      variant={'TRANSITIONAL'}
      title={title}
      description={description}
      transitionalState={transitionalState}
      sameStateLayers={sameStateLayers}
      updateLayersVisibility={() => updateLayersVisibility(sameStateLayers)}
      isCheckboxChecked={allLayersChecked || transitionalState}
      disabled={disabled}
    />
  );
};
