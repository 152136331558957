import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { ExtractionSingleDateReportsExternalData } from 'views/reports/external/data/ExtractionSingleDateReportsExternalData';
import { ExtractionDoubleDateReportsExternalData } from 'views/reports/external/data/ExtractionDoubleDateReportsExternalData';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface ExtractionReportsExternalDataProps {
  timestamp: string | null;
  reportInfo: ExternalReportHeaderCommon;
}

export const ExtractionReportsExternalData = ({ timestamp, reportInfo }: ExtractionReportsExternalDataProps) => {
  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const firstDateReportsExtractionDataset = useReportsStore.use.firstDateReportsExtractionDataset();
  const secondDateReportsExtractionDataset = useReportsStore.use.secondDateReportsExtractionDataset();

  return isCompareDatesType
    ? firstDateReportsExtractionDataset && secondDateReportsExtractionDataset && (
        <ExtractionDoubleDateReportsExternalData
          reportInfo={reportInfo}
          firstDataset={firstDateReportsExtractionDataset}
          secondDataset={secondDateReportsExtractionDataset}
        />
      )
    : timestamp && <ExtractionSingleDateReportsExternalData reportInfo={reportInfo} timestamp={timestamp} />;
};
