import { useGetAoiName } from 'common/hooks/getAoiName';
import { Loading } from 'ui/molecules/loading';
import { InfoArea, useInfoAreaService } from 'ui/infoArea';
import { AppLayerManager } from 'components/layerManager/legacy';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { MoistureLayerManagerLegendItem } from './MoistureLayerManagerLegendItem';
import { useMoistureIndicators } from './indicators/useMoistureIndicators';
import { MoistureColorScale } from './MoistureColorScale';
import { WithMoistureData } from './WithMoistureData';
import { MoistureTimeline } from './MoistureTimeline';
import { MoistureLayerManagerLegend } from './MoistureLayerManagerLegend';
import { WithMoistureMultiData } from './WithMoistureMultiData';
import { MoistureIndicatorsSelect } from './indicators';

export const Moisture = () => {
  const { areaName, isAreaNameLoading } = useGetAoiName();
  const { selectedIndicator } = useMoistureIndicators();

  const infoAreaProps = useInfoAreaService();
  const areReportsActive = useGetAreReportsActiveUseCase();

  if (isAreaNameLoading) {
    return <Loading />;
  }

  return (
    <AreaDetailsLayout
      legend={!selectedIndicator?.is_multi_layers && <WithMoistureData Component={MoistureColorScale} />}
      toolbarContent={
        <>
          <InfoArea {...infoAreaProps}>{selectedIndicator?.description}</InfoArea>
          {!areReportsActive && <MoistureIndicatorsSelect />}
        </>
      }
      toolboxContent={!areReportsActive && <LayerManagerControlButton />}
    >
      {!areReportsActive && (
        <AppLayerManager>
          {selectedIndicator?.is_multi_layers ? (
            <WithMoistureMultiData Component={MoistureLayerManagerLegend} />
          ) : (
            <WithMoistureData Component={MoistureLayerManagerLegendItem} />
          )}
        </AppLayerManager>
      )}
      {areaName && selectedIndicator && <MoistureTimeline areaName={areaName} indicator={selectedIndicator} />}
    </AreaDetailsLayout>
  );
};
