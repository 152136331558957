import { type ValueAndLabel } from 'common/types';
import { useDeleteQueryParameters, useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { FALSY_QUERY_PARAMETERS, SPATIAL_RESOLUTION_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useCallback, useEffect, useMemo } from 'react';

export const useSpatialResolution = () => {
  const resolutionOptions = useMemo(
    () => [
      {
        value: 15,
        label: '15m spatial resolution',
      },
      {
        value: 30,
        label: '30m spatial resolution',
      },
    ],
    [],
  );

  const resolutionQuery = useQueryParameter(SPATIAL_RESOLUTION_QUERY_PARAMETER);
  const resolution = resolutionQuery
    ? resolutionOptions.find((option) => option.value === parseInt(resolutionQuery))
    : undefined;

  const replaceQueryParameter = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeResolution = useCallback(
    (option: ValueAndLabel): void => {
      FALSY_QUERY_PARAMETERS.includes(option.value)
        ? deleteQueryParameters([SPATIAL_RESOLUTION_QUERY_PARAMETER])
        : replaceQueryParameter([
            {
              key: SPATIAL_RESOLUTION_QUERY_PARAMETER,
              value: String(option.value),
            },
          ]);
    },
    [deleteQueryParameters, replaceQueryParameter],
  );

  useEffect(() => {
    if (!resolution) {
      changeResolution(resolutionOptions[0]);
    }
  }, [changeResolution, resolution, resolutionOptions]);

  return { resolutionOptions, resolution, changeResolution };
};
