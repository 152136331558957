import { type ReactNode } from 'react';
import styled from 'styled-components';

import background from 'assets/img/login-background.jpg';
import { ReactComponent as TerraEyeIcon } from 'assets/svg/hero-logo.svg';

interface FormLayoutProps {
  children: ReactNode;
  header: string;
}

export const FormLayout = ({ children, header }: FormLayoutProps) => {
  return (
    <BackgroundWrapper>
      <Wrapper>
        <IconWrapper>
          <TerraEyeIcon />
        </IconWrapper>
        <ContentWrapper>
          <h1>{header}</h1>
          {children}
        </ContentWrapper>
      </Wrapper>
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  display: grid;
  place-items: center;
  flex-grow: 1;
  min-height: 100vh;
  overflow-y: auto;
  background: ${({ theme }) => theme.color.masala};
  background: no-repeat center/cover url(${background});
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  max-width: 486px;
  width: 100%;

  h1 {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Barlow Condensed', sans-serif;
  }
`;

const IconWrapper = styled.div`
  padding: 32px 30px;
  background: ${({ theme }) => theme.color.mineShaftDark};
  color: ${({ theme }) => theme.color.white};

  svg {
    width: 200px;
  }
`;

const ContentWrapper = styled.div`
  padding: 28px 30px 30px;
`;
