import axios, { type AxiosError, type AxiosInstance, type AxiosResponse } from 'axios';

import { ROUTES } from 'common/navigation/routes';
import { LOCAL_STORAGE_ACCESS_TOKEN_NAME, LOCAL_STORAGE_REFRESH_TOKEN_NAME } from 'common/constants/services';
import { USER_API_URLS } from 'infrastructure/user/userApiUrls';
import { localStorageService } from 'services/localStorageService';
import { memoizedRefreshToken } from 'services/rest/refreshTokenHelper';

// Types are broken in axios form 0.x versions: https://github.com/axios/axios/issues/5494. Used 'any' as temporary solution
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRequest = (config: any) => {
  const localStorageAccessToken = localStorageService.getValue(LOCAL_STORAGE_ACCESS_TOKEN_NAME);

  if (localStorageAccessToken && config.headers && config.url !== USER_API_URLS.login) {
    config.headers = {
      Authorization: `Bearer ${localStorageAccessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  return config;
};

const onRequestError = (error: AxiosError) => {
  console.log('Unable to send request...');
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse) => response;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onResponseError = async (error: any, axiosInstance: AxiosInstance) => {
  const originalConfig = error.config;

  if (originalConfig?.url !== USER_API_URLS.login && error.response) {
    if (error.response?.status === 401 && error.config.url === USER_API_URLS.tokenRefresh) {
      if (window.location.href.includes(ROUTES.login)) {
        return;
      }

      localStorageService.removeValue(LOCAL_STORAGE_ACCESS_TOKEN_NAME);
      localStorageService.removeValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
      window.location.replace(`#${ROUTES.logout}`);
    }

    if ([401, 403].includes(error.response?.status) && !originalConfig._retry) {
      originalConfig._retry = true;

      const access = await memoizedRefreshToken(axiosInstance);

      axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;

      return await axiosInstance(originalConfig);
    }

    return Promise.reject(error);
  }
};

export const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, (error: unknown) => onResponseError(error, axiosInstance));

  return axiosInstance;
};
