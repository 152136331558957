import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAoiNumber } from 'common/navigation/hooks';
import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';
import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { GREENERY_PATH_NAME } from 'domain/greenery/constants';

export const useGreeneryLifecycleService = () => {
  const { pathname } = useLocation();
  const areaId = useAoiNumber();

  const removeWarningErrorsByViewPath = useErrorsStore.use.removeWarningErrorsByViewPath();
  const clearComparatorUrl = useGreeneryStore.use.clearComparatorUrl();

  const clearGreeneryData = () => {
    clearComparatorUrl();
  };

  useEffect(() => {
    clearComparatorUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    return () => {
      clearGreeneryData();
      removeWarningErrorsByViewPath(GREENERY_PATH_NAME);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);
};
