import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';

interface ClustersLayerManagerState {
  isClustersVisible: boolean;
  toggleClustersVisibility: () => void;
  clustersSupplierId: number | null;
  setClustersSupplierId: (id: number) => void;
  clustersAmount: number;
  setClustersAmount: (amount: number) => void;
  isClustersSelectionMode: boolean;
  enableClustersSelectionMode: () => void;
  confirmClustersSelection: () => void;
  selectedClustersSaved: number[];
  selectedClustersInProgress: number[];
  toggleSelectedCluster: (no: number) => void;
  clearSelectedClusters: () => void;
  cancelClustersSelection: () => void;
  clearClustersData: () => void;
}

const initialState = {
  isClustersVisible: false,
  clustersSupplierId: null,
  clustersAmount: 20,
  isClustersSelectionMode: false,
  selectedClustersSaved: [],
  selectedClustersInProgress: [],
};

const useClustersLayerManagerStoreBase = create<ClustersLayerManagerState>()(
  devtools(
    persist(
      (set) => ({
        isClustersVisible: initialState.isClustersVisible,
        toggleClustersVisibility: () =>
          set(
            (state) => ({ isClustersVisible: !state.isClustersVisible }),
            false,
            'layerManager/toggleClustersVisibility',
          ),
        clustersSupplierId: initialState.clustersSupplierId,
        setClustersSupplierId: (id: number) =>
          set({ clustersSupplierId: id }, false, 'layerManager/setClustersSupplierId'),
        clustersAmount: initialState.clustersAmount,
        setClustersAmount: (amount: number) => set({ clustersAmount: amount }, false, 'layerManager/setClustersAmount'),
        isClustersSelectionMode: initialState.isClustersSelectionMode,
        enableClustersSelectionMode: () =>
          set(
            (state) => ({ isClustersSelectionMode: true, selectedClustersInProgress: state.selectedClustersSaved }),
            false,
            'layerManager/enableClustersSelectionMode',
          ),
        confirmClustersSelection: () =>
          set(
            (state) => ({ isClustersSelectionMode: false, selectedClustersSaved: state.selectedClustersInProgress }),
            false,
            'layerManager/disableClustersSelectionMode',
          ),
        selectedClustersSaved: initialState.selectedClustersSaved,
        selectedClustersInProgress: initialState.selectedClustersInProgress,
        toggleSelectedCluster: (no: number) => {
          set(
            (state) => {
              const clustersSet = new Set(state.selectedClustersInProgress);
              clustersSet.has(no) ? clustersSet.delete(no) : clustersSet.add(no);

              return { selectedClustersInProgress: Array.from(clustersSet) };
            },
            false,
            'layerManager/toggleSelectedCluster',
          );
        },
        clearSelectedClusters: () =>
          set(
            {
              selectedClustersSaved: initialState.selectedClustersSaved,
              selectedClustersInProgress: initialState.selectedClustersSaved,
            },
            false,
            'layerManager/clearSelectedClusters',
          ),
        cancelClustersSelection: () =>
          set(
            { selectedClustersInProgress: initialState.selectedClustersInProgress, isClustersSelectionMode: false },
            false,
            'layerManager/cancelClustersSelection',
          ),
        clearClustersData: () =>
          set(
            {
              isClustersVisible: initialState.isClustersVisible,
              clustersSupplierId: initialState.clustersSupplierId,
              clustersAmount: initialState.clustersAmount,
              isClustersSelectionMode: initialState.isClustersSelectionMode,
              selectedClustersSaved: initialState.selectedClustersSaved,
              selectedClustersInProgress: initialState.selectedClustersInProgress,
            },
            false,
            'layerManager/clearClustersData',
          ),
      }),
      { name: 'RSOM_clustersLayerManagerStore', storage: createJSONStorage(() => localStorage) },
    ),
    { name: 'clustersLayerManagerStore' },
  ),
);

export const useClustersLayerManagerStore = createSelectors(useClustersLayerManagerStoreBase);
