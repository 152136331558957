import { Outlet } from 'react-router-dom';

import { TabSwitcher, TopButtonsWrapper } from 'components';
import { useUserStore } from 'infrastructure/user/userStore';
import { getAvailableTabSwitcherOptions } from 'common/navigation/utils';
import { DISPLACEMENTS_TAB_SWITCHER_BUTTONS } from 'domain/displacements/constants';
import { useDisplacementsLifecycle } from './useDisplacementsLifecycle';

export const Displacements = () => {
  const { userPermissions } = useUserStore();

  useDisplacementsLifecycle();

  if (!userPermissions) return null;

  const displacementsTabSwitcherOptions = getAvailableTabSwitcherOptions(
    DISPLACEMENTS_TAB_SWITCHER_BUTTONS,
    userPermissions.granted_permissions,
  );

  return (
    <>
      <TopButtonsWrapper>
        <TabSwitcher options={displacementsTabSwitcherOptions} />
      </TopButtonsWrapper>
      <Outlet />
    </>
  );
};
