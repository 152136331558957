import styled, { css } from 'styled-components';

import { ReactComponent as CrossIcon } from 'assets/svg/cross-icon.svg';
import { ReactComponent as CrossIconThin } from 'assets/svg/cross-icon-thin.svg';

type CloseIconVariant = 'DEFAULT' | 'THIN' | 'SOLID';

interface StyledIconProps {
  variant?: CloseIconVariant;
  $iconSize?: number;
  size?: number;
}

interface CloseIconProps extends StyledIconProps {
  onClick?: () => void;
}

export const CloseIcon = (props: CloseIconProps) => {
  switch (props.variant) {
    case 'DEFAULT':
      return (
        <ButtonBody {...props}>
          <DefaultIcon {...props} />
        </ButtonBody>
      );
    case 'THIN':
      return (
        <ButtonBody {...props}>
          <ThinIcon {...props} />
        </ButtonBody>
      );
    case 'SOLID':
      return (
        <ButtonBody {...props}>
          <ThinIcon {...props} />
        </ButtonBody>
      );
    default:
      return null;
  }
};

const sharedStyle = css<StyledIconProps>`
  position: absolute;
  width: ${({ $iconSize }) => $iconSize}px;
  height: ${({ $iconSize }) => $iconSize}px;
  cursor: pointer;
`;

const DefaultIcon = styled(CrossIcon)<StyledIconProps>`
  ${sharedStyle}
  transform: translate(-50%, -50%);
`;

const ThinIcon = styled(CrossIconThin)<StyledIconProps>`
  ${sharedStyle}
  transform: translate(-50%, -50%);
`;

const ButtonBody = styled.button<StyledIconProps>`
  color: ${({ theme, variant }) => (variant === 'SOLID' ? theme.color.white : theme.color.suvaGray)};
  background-color: ${({ theme, variant }) => (variant === 'SOLID' ? theme.color.mineShaftABitLighter : 'transparent')};
  width: ${({ size, $iconSize }) => size || $iconSize}px;
  height: ${({ size, $iconSize }) => size || $iconSize}px;
  border-radius: 50%;
  border: none;
  transition: background-color ${({ theme }) => theme.transitionFunction.main};

  &:hover {
    background-color: ${({ theme, variant }) => (variant === 'SOLID' ? theme.color.selectGray : 'transparent')};
  }
`;

CloseIcon.defaultProps = {
  variant: 'DEFAULT' as CloseIconVariant,
  $iconSize: 16,
  size: 24,
};

CloseIcon.displayName = 'CloseIcon';
