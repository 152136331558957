import { areasRepository } from 'infrastructure/areas/areasRepository';
import { type NavigationItemConfig, type AreaEnvironmentsTypes } from 'common/navigation/types';
import { useAoiNumber } from 'common/navigation/hooks';
import { AreaEnvironments } from 'common/navigation/constants';
import { useGetNavigationConfigByAreaType } from '../../useGetNavigationConfigByAreaType';

export const useGetAreasNavConfig = (): { areaNavigationConfig: NavigationItemConfig[] | [] } => {
  const areaId = useAoiNumber();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);
  const aoiType = areaOfInterestDetails?.properties.type;
  const areaNavigationConfig = useGetNavigationConfigByAreaType(aoiType);
  const avaialbleEnvironments = areaOfInterestDetails?.properties.environments || [];

  return {
    areaNavigationConfig: areaNavigationConfig.filter(
      ({ type }) =>
        !Object.values(AreaEnvironments).includes(type as AreaEnvironmentsTypes) ||
        avaialbleEnvironments.includes(type as AreaEnvironmentsTypes),
    ),
  };
};
