import chroma from 'chroma-js';

import { Loading } from 'ui/molecules/loading';
import { DataLayer } from 'components/map';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { type LeafletBounds } from 'common/types/mapData';
import { type HabitatsResult } from 'domain/greenery/types';
import { HABITATS_MAP_PREDICTION_LAYER_COLOR } from 'domain/greenery/colors';
import { HABITATS_PREDICTION_LAYER_NAME } from 'domain/greenery/constants';
import { useHabitatsLayersVisibility } from './useHabitatsLayersVisibility';

export const HabitatsMapPredictionLayer = ({ result }: { result: HabitatsResult }) => {
  const url = getAzureBlobStorageUrl(result.url);
  const { classesVisibility } = useHabitatsLayersVisibility();

  const colormapArray = [
    [
      [1, 1.001],
      [...chroma(HABITATS_MAP_PREDICTION_LAYER_COLOR).rgb(), 255],
    ],
  ];

  const titilerUrl = titilerApiAdapter.buildGenericMonocolorCogUrl(url, {
    expression: 'b1',
    colormap: JSON.stringify(colormapArray),
  });

  const { info, isInfoLoading } = titilerInfoService.useGetInfoGeneric(url);
  const bounds = getBoundsFromCogInfo(info) as LeafletBounds | undefined;

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !bounds) {
    return null;
  }

  return (
    <DataLayer
      isVisible={!!classesVisibility[HABITATS_PREDICTION_LAYER_NAME]}
      layerName={HABITATS_PREDICTION_LAYER_NAME}
      zIndex={478}
      cogUrl={titilerUrl}
      bounds={bounds}
    />
  );
};
