import { type LayerObject } from 'common/types/mapData';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { Select } from 'ui/atoms';
import { useAvailableLayers } from 'views/greenery/segmentation/useAvailableLayers';
import { greeneryControlsService } from 'views/greenery/services';
import { useHandleGreneryObjectsSelectUseCase } from 'views/greenery/useCases/handleGreneryObjectsSelectUseCase';

interface SegmentationObjectsSelectProps {
  layerObjects: LayerObject[];
  cogUrl: string;
}

export const SegmentationObjectsSelect = ({ layerObjects, cogUrl }: SegmentationObjectsSelectProps) => {
  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();

  const { segmentationObjectsSelectData } = greeneryControlsService.useGreeneryObjectsOptions(greeneryObjects, false);
  const { selectGreeneryObject } = useHandleGreneryObjectsSelectUseCase();

  const { availableLayers } = useAvailableLayers(cogUrl, layerObjects);

  const filteredSelectOptions = segmentationObjectsSelectData.options.filter(({ value }) =>
    availableLayers?.includes(value),
  );

  const filteredSegmentationObjectsSelectData = {
    ...segmentationObjectsSelectData,
    options: filteredSelectOptions,
  };

  return <Select {...filteredSegmentationObjectsSelectData} onChange={selectGreeneryObject} minWidth={210} />;
};
