import styled from 'styled-components';

import areaPreviewPlaceholder from 'assets/img/area-placeholder.png';
import { Select } from 'ui/atoms';
import { Sidebar } from 'ui/sidebar';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { SIDEBAR_IMAGE_RATIO, SIDEBAR_MAX_WIDTH, SIDEBAR_MIN_WIDTH } from 'common/constants';
import { SidebarNavigation } from './sidebarNavigation/SidebarNavigation';
import { useChangeAreaUseCase } from './changeAreaUseCase';
import { AreaDetailsSidebarImage } from './AreaDetailsSidebarImage';
import { useAreasOfInterestSelectService } from '../areasOfInterestSelectService';
import { WithSatelliteCog } from '../satelliteImage/WithSatelliteCog';

interface Props {
  areaId: number;
}

export const AreaDetailsSidebar = ({ areaId }: Props) => {
  const isSidebarFolded = useSidebarStore.use.isSidebarFolded();

  const { selectData } = useAreasOfInterestSelectService();

  const { changeArea } = useChangeAreaUseCase();

  return (
    <Sidebar variant={isSidebarFolded ? 'FOLDED' : 'UNFOLDED'} allowHiding>
      <ContentWrapper scrollable={!isSidebarFolded}>
        <ImageWrapper hide={isSidebarFolded}>
          <StyledImgBox>
            <WithSatelliteCog Component={AreaDetailsSidebarImage} {...{ areaId }} />
          </StyledImgBox>
          <SelectWrapper>
            {!isSidebarFolded && <Select {...selectData} onChange={changeArea} special searchable />}
          </SelectWrapper>
        </ImageWrapper>
        <SidebarNavigation />
      </ContentWrapper>
    </Sidebar>
  );
};

const ContentWrapper = styled.div<{ scrollable?: boolean }>`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.codGray};

  overflow-y: ${({ scrollable }) => (scrollable ? 'scroll' : 'hidden')};
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.color.midGray} ${({ theme }) => theme.color.selectGray};

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.selectGray};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.midGray};
    border-radius: 8px;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const ImageWrapper = styled.div<{ hide: boolean }>`
  transition: margin ${({ theme }) => theme.transitionFunction.main};
  margin: ${({ hide }) =>
    hide
      ? `${Math.max(
          -SIDEBAR_MAX_WIDTH / SIDEBAR_IMAGE_RATIO,
          -SIDEBAR_MAX_WIDTH / SIDEBAR_IMAGE_RATIO + SIDEBAR_MIN_WIDTH - 20,
        )}px 0 0 ${-SIDEBAR_MAX_WIDTH}px`
      : 0};
`;

const StyledImgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SIDEBAR_MAX_WIDTH}px;
  aspect-ratio: ${SIDEBAR_IMAGE_RATIO};
  background-image: url(${areaPreviewPlaceholder});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const SelectWrapper = styled.div`
  padding: 0 10px 14px;
  margin-top: -44px;
  max-width: 300px;
`;
