import { useEffect, useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import styled, { css } from 'styled-components';

import { ReactComponent as CheckIcon } from 'assets/svg/check-circle.svg';
import { Button, Checkbox, Container, InfoPoint, LabeledCheckbox } from 'ui';
import { SummaryModal } from 'ui/modal';
import { SectionHead } from 'ui/atoms/sectionHead';
import { LoadingDots } from 'ui/atoms/loadingDots';
import { TextArea } from 'ui/textArea';
import { useDataRequest } from 'views/dataRequest/useDataRequest';
import { useDataRequestStore } from 'infrastructure/dataRequest/dataRequestStore';
import { DATA_REQUEST_DATA_TYPE_HEADERS } from 'domain/dataRequest/configs';
import { getDataRequestModalSectionDescription } from 'domain/dataRequest/helpers';

export const DataRequestForm = () => {
  const {
    dataTypes,
    analysisTypes,
    areDataTypesLoading,
    areAnalysisTypesLoading,
    customReport,
    setCustomReport,
    isSubmitDataLoading,
    isSubmitDisabled,
    onOrderSubmit,
    orderPostStatus,
  } = useDataRequest();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const clearDataRequestStore = useDataRequestStore.use.clearDataRequestStore();

  const closeModals = () => {
    clearDataRequestStore();
    setIsConfirmModalOpen(false);
  };

  useEffect(() => {
    if (orderPostStatus === 'success') {
      setIsConfirmModalOpen(true);
    }
  }, [orderPostStatus]);

  return (
    <>
      <form onSubmit={onOrderSubmit}>
        <Container variant="VERTICAL_START" gap={40}>
          <SectionWrapper>
            <SectionHead
              header="Data Types"
              description={getDataRequestModalSectionDescription(areDataTypesLoading, dataTypes.length)}
            />
            <Container variant="VERTICAL_START" mt={14}>
              {areDataTypesLoading ? (
                <LoadingDots />
              ) : (
                <>
                  {dataTypes.length ? (
                    <StyledTable>
                      <tbody>
                        <tr>
                          {Object.values(DATA_REQUEST_DATA_TYPE_HEADERS).map((header) => (
                            <th key={header}>{header}</th>
                          ))}
                          <th>Make selection</th>
                        </tr>
                        {dataTypes.map(
                          ({
                            id,
                            type,
                            source,
                            waveLength,
                            tir,
                            bands,
                            resolution,
                            checked,
                            disabled,
                            info,
                            onChange,
                          }) => (
                            <StyledTableDataRow key={id} onClick={disabled ? undefined : onChange} $disabled={disabled}>
                              <td>{type}</td>
                              <td>
                                <Container gap={8}>
                                  {source}
                                  {info && (
                                    <InfoPoint
                                      name={`request-form-${id}`}
                                      tooltipContent={<StyledMarkdownEditor source={info} />}
                                      color="selectGray"
                                    />
                                  )}
                                </Container>
                              </td>
                              <td>{waveLength}</td>
                              <td>{tir}</td>
                              <td>{bands}</td>
                              <td>{resolution}</td>
                              <td>
                                <Checkbox checked={!!checked} disabled={disabled} />
                              </td>
                            </StyledTableDataRow>
                          ),
                        )}
                      </tbody>
                    </StyledTable>
                  ) : null}
                </>
              )}
            </Container>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHead
              header="Analysis Types"
              description={getDataRequestModalSectionDescription(areAnalysisTypesLoading, analysisTypes.length)}
            />
            <Container variant="VERTICAL_START" gap={12} mt={14}>
              {areAnalysisTypesLoading ? (
                <LoadingDots />
              ) : (
                <>
                  {analysisTypes.length ? (
                    <>
                      {analysisTypes.map(({ id, label, checked, disabled, info, onChange }) => (
                        <Container key={id} gap={8}>
                          <LabeledCheckbox checked={!!checked} disabled={disabled} onChange={onChange} reversed>
                            {label}
                          </LabeledCheckbox>
                          <InfoPoint
                            name={`request-form-${id}`}
                            tooltipContent={<StyledMarkdownEditor source={info} />}
                            color="selectGray"
                          />
                        </Container>
                      ))}
                    </>
                  ) : null}
                </>
              )}
            </Container>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHead
              header="Request a custom report with interpretation."
              description="Describe to us what you are looking for in a report so we can better understand your use case."
            />
            <Container mt={14}>
              <TextArea
                value={customReport}
                onChange={(e) => setCustomReport(e.target.value)}
                placeholder="Type your request here"
              />
            </Container>
          </SectionWrapper>
          <SubmitSection>
            {isSubmitDataLoading ? (
              <>Collecting necessary data...</>
            ) : orderPostStatus === 'error' ? (
              <span>Something went wrong. Please try again.</span>
            ) : null}
            <Button
              type="submit"
              disabled={isSubmitDisabled}
              squared
              isLoading={orderPostStatus === 'loading'}
              minWidth={180}
            >
              submit request
            </Button>
          </SubmitSection>
        </Container>
      </form>
      <SummaryModal
        icon={<CheckIcon />}
        headerText="Thank you for your request."
        paragraphText="We will contact you directly via email with pricing for the selected options."
        isOpen={isConfirmModalOpen}
        closeModal={closeModals}
      >
        <Button squared onClick={closeModals} minWidth={100}>
          OK
        </Button>
      </SummaryModal>
    </>
  );
};

const SectionWrapper = styled.div`
  align-self: stretch;
`;

const StyledTable = styled.table`
  font-size: 12px;
  text-align: left;
  border-collapse: collapse;

  > tbody {
    > tr {
      th {
        vertical-align: top;
      }

      th,
      td {
        font-weight: 500;
        line-height: 16px;
        padding: 8px;

        @media (max-width: 500px) {
          padding: 8px 2px;
        }
      }

      th:first-child,
      td:first-child {
        padding-left: 8px;
      }

      th:last-child,
      td:last-child {
        text-align: end;
        padding-right: 8px;
      }

      :first-child {
        border-bottom: 1px solid white;
      }
    }

    > tr:not(:last-child) {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.color.midGray};
      }
    }
  }
`;

const StyledTableDataRow = styled.tr<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled
      ? css`
          color: ${({ theme }) => theme.color.whiteTransparent40};
        `
      : css`
          :hover {
            background-color: ${({ theme }) => theme.color.selectGray};
            cursor: pointer;
          }
        `}
`;

const StyledMarkdownEditor = styled(MDEditor.Markdown)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 4px 0;
`;

const SubmitSection = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  font-size: 14px;

  > span {
    color: ${({ theme }) => theme.color.errorPink};
  }
`;
