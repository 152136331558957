import styled from 'styled-components';
import MDEditor from '@uiw/react-md-editor';

import { calculatePolygonCenter, getDisplayableMapPoint } from 'common/utils/map';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { areasRepository } from 'infrastructure/areas/areasRepository';

export const AreaCardInfo = ({ areaId }: { areaId: number }) => {
  const { areaOfInterestDetails, areaOfInterestDetailsLoading } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  if (areaOfInterestDetailsLoading) {
    return <LoadingDots />;
  }

  if (!areaOfInterestDetails) {
    return 'No data available';
  }

  const description = areaOfInterestDetails.properties.description;
  const surface = areaOfInterestDetails.properties.area;

  const lngLat = calculatePolygonCenter(areaOfInterestDetails.geometry.coordinates[0]);
  const areaLocation = getDisplayableMapPoint(lngLat);

  return (
    <>
      {description && <StyledMarkdownEditor source={description} />}
      {!!surface && (
        <div>
          Total area: {+surface.toFixed(2)} km<sup>2</sup>
        </div>
      )}
      <CoordinatesWrapper>
        Coordinates:
        <Coordinates>
          <span>Latitude {areaLocation.lat}</span>
          <span>Longitude {areaLocation.lng}</span>
        </Coordinates>
      </CoordinatesWrapper>
    </>
  );
};

const CoordinatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Coordinates = styled.span`
  display: flex;
  gap: 10px;
  white-space: nowrap;
`;

const StyledMarkdownEditor = styled(MDEditor.Markdown)`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background-color: inherit;

  strong {
    font-weight: 700;
  }
`;
