import { LOCAL_STORAGE_ACCEPT_COOKIE } from 'common/constants/services';
import { useEffect } from 'react';
import { updateTelemetryStatus } from 'services/appInsightsService';

export const useUpdateTelemetryStatus = () => {
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORAGE_ACCEPT_COOKIE) {
        updateTelemetryStatus();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    updateTelemetryStatus();

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
};
