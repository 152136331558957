export const AREA_PATH_NAME = 'area';
export const AREAS_PATH_NAME = 'areas';

export const TITILER_RESCALE_FACTOR = '0,8192';
export const TITILER_FILE_TYPE = 'png';

export const INFRASTRUCTURE_LAYER_DEFAULT_PRIMARY_COLOR = '#FFFFFF';
export const INFRASTRUCTURE_LAYER_DEFAULT_SECONDARY_COLOR = '#FFBB38';
export const INFRASTRUCTURE_LAYER_DEFAULT_REPORT_COLOR = '#EB0505';

export const AREAS_LIST_OFFSET_TOP = 90;
export const AREAS_LIST_COLUMNS_BREAKPOINT = 1200;
export const AREA_CARD_MIN_HEIGHT = 215;
export const AREA_CARD_GAP = 20;

export const MINIMUM_VIABLE_AREA = 1_000_000;

export const AreaCardStatuses = {
  NEW: 'N',
  ARCHIVED: 'X',
  PENDING: 'P',
  REJECTED: 'R',
} as const;

export const AreaStatuses = {
  ...AreaCardStatuses,
  ACTIVE: 'A',
} as const;
