import { addAlphaToColormapObject } from 'common/utils/color';
import { LayerManagerLegendItem } from 'components/layerManager/legacy/LegendItem';
import { useBiomass } from 'views/greenery/segmentation/useBiomass';
import { BIOMASS_COLORMAP } from 'domain/greenery/colors';
import { BIOMASS_OPACITY } from 'domain/greenery/configs';

export const SegmentationSpecialLayerManager = () => {
  const { isBiomassVisible, toggleBiomassVisibility } = useBiomass();

  const colormap = addAlphaToColormapObject(BIOMASS_COLORMAP, BIOMASS_OPACITY);

  return (
    <LayerManagerLegendItem
      option={{
        id: 200,
        name: 'biomass',
        label_name: 'Biomass',
        color: '',
        status: 'active',
      }}
      handleObjectsVisibility={toggleBiomassVisibility}
      objectVisibility={isBiomassVisible}
      iconColormap={colormap}
    />
  );
};
