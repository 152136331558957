import styled from 'styled-components';

interface TextAndLinkProps {
  text: string;
  link?: string;
  linkedText?: string;
  markedText?: string;
  handleClick?: () => void;
}

export const TextAndLink = ({ text, link, linkedText = link, markedText, handleClick }: TextAndLinkProps) => (
  <Wrapper>
    {markedText && <MarkedText>{markedText}</MarkedText>}
    <span>{text} </span>
    <Link>
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {linkedText}
        </a>
      )}
      {handleClick && <span onClick={handleClick}>{linkedText}</span>}
    </Link>
  </Wrapper>
);

const Wrapper = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: none;
`;

const MarkedText = styled.div`
  font-weight: 700;
`;

const Link = styled.span`
  a,
  span {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
`;
