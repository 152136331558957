import styled from 'styled-components';

export const ShareBlockActionWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
`;

export const ShareWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 50px 10px 0;
`;
