import { useAoiNumber } from 'common/navigation/hooks';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';

export const segmentationDataService = {
  useSegmentationResult: (timestamp: string) => {
    const areaId = useAoiNumber();

    const { segmentationResults, areSegmentationResultsLoading } = greeneryRepository.useFetchSegmentationResults(
      areaId,
      timestamp,
    );

    const resultId = segmentationResults.length > 0 ? segmentationResults[0].id : undefined;

    return { resultId, isResultLoading: areSegmentationResultsLoading };
  },

  useSegmentationDoubleResults: (timestamp: string, secondTimestamp: string) => {
    const { resultId: firstResultId, isResultLoading: isFirstResultLoading } =
      segmentationDataService.useSegmentationResult(timestamp);

    const { resultId: secondResultId, isResultLoading: isSecondResultLoading } =
      segmentationDataService.useSegmentationResult(secondTimestamp);

    const areResultsLoading = isFirstResultLoading || isSecondResultLoading;

    return { firstResultId, secondResultId, areResultsLoading };
  },
};
