import styled from 'styled-components';
import { ReactComponent as QuestionBaloon } from 'assets/svg/question-baloon.svg';
import { chatWelcomePageMessage } from 'ui/chat/chatWelcomePage/content';

interface ChatWelcomePageProps {
  message: string[];
}

export const ChatWelcomePage = ({ message }: ChatWelcomePageProps) => (
  <ContentWrapper>
    <IconWrapper>
      <QuestionBaloon />
    </IconWrapper>
    {message.map((line) => (
      <MessageLine key={line}>{line}</MessageLine>
    ))}
  </ContentWrapper>
);

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.color.white};
`;

const IconWrapper = styled.div`
  margin-bottom: 50px;

  svg {
    width: 75px;
    height: 75px;
  }
`;

const MessageLine = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
`;

ChatWelcomePage.defaultProps = {
  message: chatWelcomePageMessage,
};

ChatWelcomePage.displayName = 'ChatWelcomePage';
