import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useAoiNumber } from 'common/navigation/hooks';
import { DataRequestButton } from './DataRequestButton';

export const DataRequestConditionalButton = () => {
  const areaId = useAoiNumber();
  const areReportsActive = useGetAreReportsActiveUseCase();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);
  const aoiType = areaOfInterestDetails?.properties.type;

  if (areReportsActive || aoiType !== 'P') {
    return null;
  }

  return <DataRequestButton />;
};
