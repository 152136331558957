import { useLocation } from 'react-router-dom';

import { useLayerOrderStore } from 'components/layerManager/layersOrderStore';
import { TURBO_COLORMAP } from 'domain/exploration/colors';
import { type SpectralClassifierDTO } from 'domain/exploration/types';
import { MineralsLayerManager } from './MineralsLayerManager';
import { useLayersDisplayState } from './useLayersDisplayState';
import { useSamLayersVisibility } from '../useSurfaceLayersVisibility';
import { EXPLORATION_LAYERS_KEYS } from '../../explorationDraggableLayersKeys';

interface MineralsLayerManagerSamProps {
  id: string;
  classifiers: SpectralClassifierDTO[];
}

export const MineralsLayerManagerSam = ({ id, classifiers }: MineralsLayerManagerSamProps) => {
  const classifierId = 1;
  const { pathname } = useLocation();
  const samLayersVisibility = useSamLayersVisibility();

  const { nestedLayersOrderRecord, setNestedLayersOrderRecord } = useLayerOrderStore();

  const layersOrderRecord = nestedLayersOrderRecord
    ? nestedLayersOrderRecord[pathname]?.[EXPLORATION_LAYERS_KEYS.SAM]
    : null;

  const setSamLayersOrderRecord = (layersOrder: number[]): void => {
    setNestedLayersOrderRecord(pathname, EXPLORATION_LAYERS_KEYS.SAM, layersOrder);
  };

  const { classesVisibility, updateClassesVisibility, updateLayerVisibility } = useLayersDisplayState(
    classifierId,
    samLayersVisibility,
  );

  const description = classifiers.find(({ id }) => id === classifierId)?.description;

  return (
    <MineralsLayerManager
      id={id}
      classifierId={classifierId}
      classesVisibility={classesVisibility}
      updateClassesVisibility={updateClassesVisibility}
      updateLayerVisibility={updateLayerVisibility}
      title="minerals - SAM"
      defaultDescription={description || 'Spectral Angle Mapper (SAM)'}
      gradientModeColormap={TURBO_COLORMAP}
      layersOrderRecord={layersOrderRecord}
      setLayersOrderRecord={setSamLayersOrderRecord}
    />
  );
};
