import { PrivateRoute } from 'common/navigation/PrivateRoute';
import { ROUTES } from 'common/navigation/routes';
import { useUserStore } from 'infrastructure/user/userStore';
import { WaterArea } from 'views/water/surface/WaterArea';
import { WaterDetection } from 'views/water/WaterDetection';
import { WaterQuality } from 'views/water/quality/WaterQuality';
import { Moisture } from 'views/water/moisture/Moisture';
import { SnowCover } from 'views/water/snow/SnowCover';
import { WaterActiveTab } from 'domain/water/enums';

export const useBuildWaterRouteObject = () => {
  const { userPermissions } = useUserStore();

  return {
    path: ROUTES.waterDetection,
    element: <WaterDetection />,
    children: [
      {
        path: ROUTES.waterQuality,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(WaterActiveTab.QUALITY)}
            redirectPath={ROUTES.areasList}
          >
            <WaterQuality />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.waterArea,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(WaterActiveTab.SURFACE)}
            redirectPath={ROUTES.areasList}
          >
            <WaterArea />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.waterMoisture,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(WaterActiveTab.MOISTURE)}
            redirectPath={ROUTES.areasList}
          >
            <Moisture />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.snowCover,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(WaterActiveTab.SNOW_COVER)}
            redirectPath={ROUTES.areasList}
          >
            <SnowCover />
          </PrivateRoute>
        ),
      },
    ],
  };
};
