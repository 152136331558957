import { EmbeddedCheckout } from '@stripe/react-stripe-js';

import { useUserStore } from 'infrastructure/user/userStore';
import { PaymentProvider } from 'services/payments/PaymentProvider';

export const CheckoutPage = () => {
  const { clientSecret } = useUserStore();

  return (
    <div id="checkout">
      <PaymentProvider {...{ clientSecret }}>
        <EmbeddedCheckout />
      </PaymentProvider>
    </div>
  );
};
