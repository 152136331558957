import { Button, UpgradeModal } from 'ui';
import { type SubscriptionType } from 'domain/subscription/types';
import { useHandleUpgradeModal } from './useHandleUpgradeModal';

interface SubscriptionUpgradeProps {
  subscriptionType: SubscriptionType;
  disabled: boolean;
  setErrorMessage: (message: string) => void;
  setLoading?: (state: boolean) => void;
  message?: string;
  large?: boolean;
}

export const SubscriptionUpgrade = ({
  subscriptionType,
  disabled,
  setErrorMessage,
  setLoading,
  message,
  large,
}: SubscriptionUpgradeProps) => {
  const { upgradeModalVisible, openUpgradeModal, closeUpgradeModal, handleConfirm, upgradeData, getUpgradeData } =
    useHandleUpgradeModal(subscriptionType, setErrorMessage, setLoading);

  return (
    <>
      <Button
        minWidth={100}
        variant={large ? 'PRIMARY' : 'PRIMARY_SMALL'}
        disabled={disabled || !getUpgradeData(subscriptionType)}
        onClick={openUpgradeModal}
      >
        {message || 'add more calculation points'}
      </Button>
      {upgradeData && (
        <UpgradeModal
          type={subscriptionType}
          data={upgradeData}
          isOpen={upgradeModalVisible}
          closeModal={closeUpgradeModal}
          onConfirm={handleConfirm}
          header="add more calculation points"
        />
      )}
    </>
  );
};
