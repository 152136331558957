import { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as FiltersIcon } from 'assets/svg/filters.svg';
import { ReactComponent as CrossIcon } from 'assets/svg/rounded-cross.svg';

interface FilterButtonProps {
  selected?: boolean;
  hasDot?: boolean;
  highlighted?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
}

export const FilterButton = ({ selected = false, hasDot = false, highlighted = false, onClick }: FilterButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const isCross = selected && isHovering;

  return (
    <StyledFilterButton
      $selected={selected}
      $highlighted={highlighted}
      $hasDot={hasDot}
      onClick={onClick}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      Filter {<IconWrapper $isCross={isCross}>{isCross ? <CrossIcon /> : <FiltersIcon />}</IconWrapper>}
    </StyledFilterButton>
  );
};

const StyledFilterButton = styled.div<{ $selected: boolean; $highlighted: boolean; $hasDot: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100px;
  height: 30px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color.midGray};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${({ theme }) => theme.color.white};
  letter-spacing: 0;
  cursor: pointer;
  padding: 0 12px;
  border-radius: 15px;
  transition: background-color ${({ theme }) => theme.transitionFunction.main};

  &:hover {
    background-color: ${({ theme }) => theme.color.selectGray};
  }

  ${({ $selected, $highlighted }) =>
    ($selected || $highlighted) &&
    css`
      background-color: ${({ theme }) => theme.color.primary};

      &:hover {
        background-color: ${({ theme }) => theme.color.primaryDark};
      }
    `}

  &:after {
    display: block;
    opacity: ${({ $hasDot, $highlighted }) => ($hasDot || $highlighted ? 1 : 0)};
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${({ theme, $selected, $highlighted }) =>
      $highlighted || $selected ? theme.color.white : theme.color.primary};
    transition: ${({ theme }) => theme.transitionFunction.main};
    transition-property: background-color, opacity;
  }
`;

const IconWrapper = styled.div<{ $isCross: boolean }>`
  width: 18px;
  height: 18px;

  ${({ $isCross }) =>
    $isCross &&
    css`
      width: 12px;
      height: 12px;
      margin-right: 3px;
    `}
`;

FilterButton.displayName = 'FilterButton';
