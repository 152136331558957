import styled from 'styled-components';

import { SIDEBAR_MIN_WIDTH, CREATOR_HEIGHT } from 'common/constants';

export const CreatorWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100vw - ${SIDEBAR_MIN_WIDTH}px);
  height: ${CREATOR_HEIGHT}px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.mineShaftDarkBlur};
  backdrop-filter: blur(10px);
  padding: 22px 30px;
  z-index: 500;
  margin-top: auto;
  margin-bottom: ${({ isOpen }) => (isOpen ? 0 : -CREATOR_HEIGHT)}px;
  transition: ${({ theme }) => theme.transitionFunction.main};
  transition-property: margin-bottom, width;
`;

export const Label = styled.h2`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const HideShowButtonWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 50px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  margin-left: auto;
`;

export const TrialUserInformation = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;
