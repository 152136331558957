import { useEffect } from 'react';

import { useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { FILTERS_STATUS_QUERY_PARAM_NAME } from 'common/navigation/queryParams';
import { type AreaStatus } from 'domain/areas/types';

export const useAreasLifecycleService = () => {
  const statusQuery = useQueryParameter(FILTERS_STATUS_QUERY_PARAM_NAME);
  const replaceQueryParameters = useReplaceQueryParameters();

  useEffect(() => {
    !statusQuery &&
      replaceQueryParameters([
        {
          key: FILTERS_STATUS_QUERY_PARAM_NAME,
          value: 'X' as AreaStatus,
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
