import { AssistantChat } from 'ui/chat';
import { Notifications } from 'ui/notifications';
import { UserSettings } from 'ui/userSettings';
import { AssistantIcon, NotificationIcon, UserIcon } from 'ui/sidebar/icons';
import { useGetUserInfo } from 'ui/sidebar/useGetUserInfo';

export const useGetSidebarPanelContent = () => {
  const { userName, email } = useGetUserInfo();

  const SIDEBAR_PANEL_CONTENT = {
    USER_PANEL: {
      title: `Hello, ${userName}`,
      icon: <UserIcon variant="PRIMARY" />,
      component: <UserSettings userName={userName} email={email} />,
    },
    ASSISTANT_PANEL: {
      title: 'Assistant',
      icon: <AssistantIcon variant="OVERVIEW" />,
      component: <AssistantChat />,
    },
    NOTIFICATIONS_PANEL: {
      title: 'Notifications',
      icon: <NotificationIcon variant="OVERVIEW" />,
      component: <Notifications />,
    },
  };

  return {
    SIDEBAR_PANEL_CONTENT,
  };
};
