import { useGetAoiName } from 'common/hooks/getAoiName';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';

export const useSegmentationOtherProviderResult = (timestamp: string) => {
  const { areaName } = useGetAoiName();
  const dataProvider = useGreeneryStore.use.dataProvider();

  const { segmentationResultsMocked } = greeneryRepository.useFetchSegmentationResultsMocked(areaName);

  const cogUrl = segmentationResultsMocked.find(
    ({ img_date, provider }) => img_date === timestamp && provider === dataProvider,
  )?.url;

  return { cogUrl };
};
