import { AreaInfo, Container, WaterbodiesReclamationsSection, NotificationsSection } from 'ui';
import { useOverridableFeatureFlag } from 'services/configCat';

export const MonitoringAreaInfo = () => {
  const enableNotifications = useOverridableFeatureFlag('enableNotifications', false, 'REACT_APP_ENABLE_NOTIFICATIONS');

  return (
    <AreaInfo>
      <Container variant="HORIZONTAL_START" gap={20}>
        <WaterbodiesReclamationsSection />
        {enableNotifications && <NotificationsSection />}
      </Container>
    </AreaInfo>
  );
};
