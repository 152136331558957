import styled from 'styled-components';
import { Button } from 'ui/atoms/button';

interface ModalButtonsProps {
  consentAction: () => void;
  cancelAction: () => void;
  consentText?: string;
  cancelText?: string;
  large?: boolean;
}

export const ModalButtons = ({
  consentAction,
  cancelAction,
  consentText = 'yes',
  cancelText = 'cancel',
}: ModalButtonsProps) => {
  return (
    <ModalButtonsWrapper>
      <Button variant="PRIMARY" squared onClick={consentAction} minWidth={130}>
        {consentText}
      </Button>
      <Button variant="SECONDARY" squared onClick={cancelAction} minWidth={130}>
        {cancelText}
      </Button>
    </ModalButtonsWrapper>
  );
};

const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;
