import { type TimelineLabel, type TimelinePoint } from 'components/Timeline/types';
import { type ValueAndLabel, type QuarterlyResult } from 'common/types';
import { type Timeframe, type Datetime } from 'common/types/time';
import { minBy } from 'common/utils';
import { getDottedDateFromUnixEpochTime } from 'common/utils/datetime';

export const quarterlyParamsToTimestamp = (year: number, quarter: number): Datetime => {
  const month = quarter * 3;
  return `${year}-${month > 9 ? month : '0' + month}-30T00:00:00Z`;
};

export const timestampToQuarterlyParams = (timestamp: Datetime) => {
  return {
    year: Number(timestamp.slice(0, 4)),
    quarter: Math.floor(Number(timestamp.slice(5, 7)) / 3),
  };
};

export const mapResultsToTimestamps = (results: QuarterlyResult[]): string[] => {
  return results?.map(({ year, quarter }) => quarterlyParamsToTimestamp(year, quarter));
};

export const createGetHandlePosition = (firstDate: number, lastDate: number) => {
  const getHandlePositionHelper = (date: number) => {
    return ((date - firstDate) / (lastDate - firstDate)) * 100;
  };

  return { getHandlePositionHelper };
};

export const getQuarterlyResultId = (timestamp: Datetime, quarterlyResults: QuarterlyResult[]): number => {
  const currentResult = timestampToQuarterlyParams(timestamp);

  return quarterlyResults.filter(
    ({ year, quarter }) => year === currentResult.year && quarter === currentResult.quarter,
  )[0].id;
};

export const getQuarterLabelFromDate = (datestamp: Datetime) => {
  const year = datestamp.slice(6);
  const month = datestamp.slice(3, 6);
  const quarter = Number(month) / 3;
  return `Q${quarter}\xa0${year}`;
};

export const getClosestPoint = (positionX: number, points: TimelinePoint[]) => {
  const closestPoint = minBy(
    points.map((point) => ({
      date: point.date,
      distance: Math.abs(point.positionX - positionX),
    })),
    (object: { date: number; distance: number }) => object.distance,
  );

  return closestPoint?.date;
};

export const getTimelinePoints = (dates: number[] | undefined): TimelinePoint[] | undefined => {
  if (dates) {
    const minDate = dates[0];
    const maxDate = dates[dates.length - 1];
    const difference = maxDate - minDate;

    if (dates.length === 1)
      return [
        {
          date: minDate,
          positionX: 50,
        },
      ];

    return dates.map((date) => ({
      date,
      positionX: ((date - minDate) / difference) * 100,
    }));
  }
};

export const getPositionOnTrack = (date: number | undefined, points: TimelinePoint[] | undefined) => {
  if (date && points) {
    return points.find((point) => point.date === date)?.positionX;
  }
};

export const getHandlePosition = (clientX: number, left: number, width: number): number =>
  ((clientX - left) / width) * 100;

export const getSelectedDate = (
  startHandleX: number,
  pageX: number,
  startPageX: number,
  timelineWidth: number,
  timelinePoints: TimelinePoint[],
) => {
  const positionX = startHandleX + Number(pageX) - startPageX;
  const handleX = positionX < 0 ? 0 : ((positionX > timelineWidth ? timelineWidth : positionX) / timelineWidth) * 100;
  const selectedDate = getClosestPoint(handleX, timelinePoints);
  return selectedDate;
};

export const getStartHandleX = (
  timelinePoints: TimelinePoint[],
  timelineWidth: number,
  selectedDate: number | undefined,
) => {
  const chosenDate = timelinePoints.find((point) => point.date === selectedDate);
  if (!chosenDate) {
    return;
  }

  const startHandleX = (chosenDate.positionX * timelineWidth) / 100;
  return startHandleX;
};

export const getTrackDates = (dates: number[] | undefined): TimelineLabel[] | undefined => {
  if (!dates) {
    return;
  }

  let trackDatesQty = dates.length;
  const minDate = dates[0];
  const maxDate = dates[trackDatesQty - 1];
  const datesDiff = maxDate - minDate;
  const trackDates: TimelineLabel[] = [];

  if (trackDatesQty >= 2) {
    trackDatesQty > 5 && (trackDatesQty = 5);
    for (let i = 0; i < trackDatesQty; i++) {
      const dateProgress = (datesDiff / (trackDatesQty - 1)) * i + minDate;

      trackDates.push({
        date: getDottedDateFromUnixEpochTime(dateProgress),
        positionX: (100 / (trackDatesQty - 1)) * i,
      });
    }
  }

  const trackDatesNumber = datesDiff < 189734400000 ? 3 : 6;

  if (datesDiff > 16070400000) {
    trackDates.forEach(
      (trackDate, i) =>
        (trackDates[i] = {
          date: String(trackDate.date).slice(trackDatesNumber),
          positionX: trackDate.positionX,
        }),
    );
  }

  return trackDates;
};

export const getQuarterlyTrackDates = (dates: number[] | undefined): TimelineLabel[] | undefined => {
  if (!dates) {
    return;
  }

  const trackDatesQty = dates.length;
  const minDate = dates[0];
  const maxDate = dates[trackDatesQty - 1];
  const datesDiff = maxDate - minDate;

  const datestamps = dates.map((date) => getDottedDateFromUnixEpochTime(date));

  const quarterlyDatestamps: TimelineLabel[] = dates.map((datestamp) => ({
    date: getQuarterLabelFromDate(getDottedDateFromUnixEpochTime(datestamp)),
    positionX: ((datestamp - minDate) * 100) / datesDiff,
  }));

  const getYearlyDatestamp = (datestamps: number[]): TimelineLabel[] =>
    datestamps
      .filter((datestamp) => getDottedDateFromUnixEpochTime(datestamp).slice(3, 5) === '12')
      .map((datestamp) => ({
        date: getDottedDateFromUnixEpochTime(datestamp).slice(6),
        positionX: ((datestamp - minDate) * 100) / datesDiff,
      }));

  const yearlyDatestamps: TimelineLabel[] = getYearlyDatestamp(dates);

  const trackDates = datestamps.length > 8 ? yearlyDatestamps : quarterlyDatestamps;

  return trackDates;
};

export const timestampToOption = (timestamp: Datetime): ValueAndLabel => {
  const { year, quarter } = timestampToQuarterlyParams(timestamp);

  return {
    value: timestamp,
    label: `Q${quarter} ${year}`,
  };
};

export const mapTimeframesToOptions = (timeframes: Timeframe[]): ValueAndLabel[] =>
  timeframes.map((timeframe) => timestampToOption(timeframe.end));
