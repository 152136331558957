import styled from 'styled-components';

import { tagStyles, type TagVariant } from 'ui/atoms/tag/Tag';
import { type AreaCardStatus } from 'domain/areas/types';
import { FILTERS_AREA_STATUS_SET } from 'domain/areas/configs';

interface StatusLabelProps {
  status: AreaCardStatus;
}

export const StatusLabel = ({ status }: StatusLabelProps) => {
  const { variant, text } = FILTERS_AREA_STATUS_SET[status];

  return <StyledStatusLabel $variant={variant}>{text}</StyledStatusLabel>;
};

const StyledStatusLabel = styled.div<{ $variant: TagVariant }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.color[tagStyles[props.$variant!].backgroundColor]};
  color: ${({ theme }) => theme.color.white};
  height: 24px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding: 0 18px;
`;

StatusLabel.displayName = 'StatusLabel';
