import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { type LayersTresholdsDictionary, type LayersVisibilityDictionary } from 'common/types';
import { createSelectors } from 'common/utils/createSelectors';

interface LayerManagerState {
  isLayerManagerOpen: boolean;
  openLayerManager: () => void;
  closeLayerManager: () => void;
  toggleLayerManager: () => void;
  isDataLayerVisible: boolean;
  toggleDataLayerVisibility: () => void;
  dataLayerOpacity: number;
  setDataLayerOpacity: (opacity: number) => void;
  isExtentVisible: boolean;
  toggleExtentVisibility: () => void;
  geoJSONsVisibility: LayersVisibilityDictionary;
  updateGeoJSONsVisibility: (layersStateUpdate: LayersVisibilityDictionary) => void;
  toggleGeoJSONVisibility: (layerName: string) => void;
  isHillshadeVisible: boolean;
  toggleHillshadeVisibility: () => void;
  hillshadeOpacity: number;
  setHillshadeOpacity: (opacity: number) => void;
  isDemVisible: boolean;
  toggleDemVisibility: () => void;
  demOpacity: number;
  setDemOpacity: (opacity: number) => void;
  isSatelliteVisible: boolean;
  toggleSatelliteVisibility: () => void;
  isSatelliteGrayscale: boolean;
  toggleSatelliteGrayscale: () => void;
  grayscaleSatelliteBrightness: number;
  setGrayscaleSatelliteBrightness: (brightness: number) => void;
  layersVisibility: LayersVisibilityDictionary;
  updateLayersVisibility: (layersStateUpdate: LayersVisibilityDictionary) => void;
  toggleLayerVisibility: (layerName: string) => void;
  setLayerVisible: (layerName: string) => void;
  clearLayersVisibility: () => void;
  layersTresholdsValues: LayersTresholdsDictionary;
  updateLayersTresholdsValues: (layersStateUpdate: LayersTresholdsDictionary) => void;
  changeLayerTresholdValue: (layerState: LayersTresholdsDictionary) => void;
  resetLayersTresholdsValues: () => void;
}

const initialState = {
  isLayerManagerOpen: true,
  isDataLayerVisible: true,
  dataLayerOpacity: 1,
  isExtentVisible: true,
  geoJSONVisibility: {},
  isHillshadeVisible: false,
  hillshadeOpacity: 0.8,
  isDemVisible: false,
  demOpacity: 0.6,
  isSatelliteVisible: true,
  isSatelliteGrayscale: true,
  grayscaleSatelliteBrightness: 2,
  layersVisibility: {},
  layersTresholdsValues: {},
};

const useLayerManagerStoreBase = create<LayerManagerState>()(
  devtools(
    persist(
      (set) => ({
        isLayerManagerOpen: initialState.isLayerManagerOpen,
        openLayerManager: () => set({ isLayerManagerOpen: true }, false, 'layerManager/open'),
        closeLayerManager: () => set({ isLayerManagerOpen: false }, false, 'layerManager/close'),
        toggleLayerManager: () =>
          set((state) => ({ isLayerManagerOpen: !state.isLayerManagerOpen }), false, 'layerManager/toggle'),
        isDataLayerVisible: initialState.isDataLayerVisible,
        toggleDataLayerVisibility: () =>
          set(
            (state) => ({ isDataLayerVisible: !state.isDataLayerVisible }),
            false,
            'layerManager/toggleDataVisibility',
          ),
        dataLayerOpacity: initialState.dataLayerOpacity,
        setDataLayerOpacity: (opacity: number) =>
          set({ dataLayerOpacity: opacity }, false, 'layerManager/setDataLayerOpacity'),
        isExtentVisible: initialState.isExtentVisible,
        toggleExtentVisibility: () =>
          set((state) => ({ isExtentVisible: !state.isExtentVisible }), false, 'layerManager/toggleExtentVisibility'),
        geoJSONsVisibility: initialState.geoJSONVisibility,
        updateGeoJSONsVisibility: (layersStateUpdate: LayersVisibilityDictionary) =>
          set(
            (state) => ({ geoJSONsVisibility: { ...state.geoJSONsVisibility, ...layersStateUpdate } }),
            false,
            'layerManager/updateGeoJSONsVisibility',
          ),
        toggleGeoJSONVisibility: (layerName: string) =>
          set(
            (state) => ({
              geoJSONsVisibility: { ...state.geoJSONsVisibility, [layerName]: !state.geoJSONsVisibility[layerName] },
            }),
            false,
            'layerManager/toggleGeoJSONVisibility',
          ),
        isHillshadeVisible: initialState.isHillshadeVisible,
        toggleHillshadeVisibility: () =>
          set(
            (state) => ({ isHillshadeVisible: !state.isHillshadeVisible }),
            false,
            'layerManager/toggleHillshadeVisibility',
          ),
        hillshadeOpacity: initialState.hillshadeOpacity,
        setHillshadeOpacity: (opacity: number) =>
          set({ hillshadeOpacity: opacity }, false, 'layerManager/setHillshadeOpacity'),
        isDemVisible: initialState.isDemVisible,
        toggleDemVisibility: () =>
          set((state) => ({ isDemVisible: !state.isDemVisible }), false, 'layerManager/toggleTopographyVisibility'),
        demOpacity: initialState.demOpacity,
        setDemOpacity: (opacity: number) => set({ demOpacity: opacity }, false, 'layerManager/setTopographyOpacity'),
        isSatelliteVisible: initialState.isSatelliteVisible,
        toggleSatelliteVisibility: () =>
          set(
            (state) => ({ isSatelliteVisible: !state.isSatelliteVisible }),
            false,
            'layerManager/toggleSatelliteVisibility',
          ),
        isSatelliteGrayscale: initialState.isSatelliteGrayscale,
        toggleSatelliteGrayscale: () =>
          set(
            (state) => ({ isSatelliteGrayscale: !state.isSatelliteGrayscale }),
            false,
            'layerManager/toggleSatelliteGrayscale',
          ),
        grayscaleSatelliteBrightness: initialState.grayscaleSatelliteBrightness,
        setGrayscaleSatelliteBrightness: (brightness: number) =>
          set({ grayscaleSatelliteBrightness: brightness }, false, 'layerManager/setGrayscaleSatelliteBrightness'),
        layersVisibility: initialState.layersVisibility,
        updateLayersVisibility: (layersStateUpdate: LayersVisibilityDictionary) =>
          set(
            (state) => ({ layersVisibility: { ...state.layersVisibility, ...layersStateUpdate } }),
            false,
            'layerManager/updateLayersVisibility',
          ),
        toggleLayerVisibility: (layerName: string) =>
          set(
            (state) => ({
              layersVisibility: { ...state.layersVisibility, [layerName]: !state.layersVisibility[layerName] },
            }),
            false,
            'layerManager/toggleLayerVisibility',
          ),
        setLayerVisible: (layerName: string) =>
          set(
            (state) => ({
              layersVisibility: { ...state.layersVisibility, [layerName]: true },
            }),
            false,
            'layerManager/setLayerVisible',
          ),
        clearLayersVisibility: () =>
          set({ layersVisibility: initialState.layersVisibility }, false, 'layerManager/clearLayersVisibility'),
        layersTresholdsValues: initialState.layersTresholdsValues,
        updateLayersTresholdsValues: (layersStateUpdate: LayersTresholdsDictionary) =>
          set(
            (state) => ({ layersTresholdsValues: { ...state.layersTresholdsValues, ...layersStateUpdate } }),
            false,
            'layerManager/updateLayersTresholdValues',
          ),
        changeLayerTresholdValue: (layerState: LayersTresholdsDictionary) =>
          set(
            (state) => ({
              layersTresholdsValues: {
                ...state.layersTresholdsValues,
                ...layerState,
              },
            }),
            false,
            'layerManager/changeLayerTresholdValue',
          ),
        resetLayersTresholdsValues: () =>
          set(
            { layersTresholdsValues: initialState.layersTresholdsValues },
            false,
            'layerManager/resetLayersTresholdValues',
          ),
      }),
      { name: 'RSOM_layerManagerStore', storage: createJSONStorage(() => localStorage) },
    ),
    { name: 'layerManagerStore' },
  ),
);

export const useLayerManagerStore = createSelectors(useLayerManagerStoreBase);
