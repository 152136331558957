import { type ReactNode } from 'react';
import styled from 'styled-components';

import { LabeledCheckbox, Slider, Switcher } from 'ui';
import { ReactComponent as BrightnessIcon } from 'assets/svg/brightness-icon.svg';
import { BasemapSelector } from 'components/layerManager/commonContent/basemap/BasemapSelector';
import { AuxiliaryControlsWrapper, LabeledSliderWrapper } from './styled';
import { TopographyLayersGroup } from './TopographyLayersGroup';
import { useLayerManagerStore } from '../layerManagerStore';

export interface LayerManagerGeometry {
  top?: number;
  right?: number;
  minWidth?: number;
  zIndex?: number;
  lowerClearance?: number;
}

interface LayerManagerCoreProps {
  areaId: number;
  areaName: string;
  children?: ReactNode;
  geometry?: LayerManagerGeometry;
}

export const LayerManager = ({ areaId, areaName, children, geometry }: LayerManagerCoreProps) => {
  const isLayerManagerOpen = useLayerManagerStore.use.isLayerManagerOpen();
  const isSatelliteVisible = useLayerManagerStore.use.isSatelliteVisible();
  const toggleSatelliteVisibility = useLayerManagerStore.use.toggleSatelliteVisibility();
  const isSatelliteGrayscale = useLayerManagerStore.use.isSatelliteGrayscale();
  const toggleSatelliteGrayscale = useLayerManagerStore.use.toggleSatelliteGrayscale();
  const grayscaleSatelliteBrightness = useLayerManagerStore.use.grayscaleSatelliteBrightness();
  const setGrayscaleSatelliteBrightness = useLayerManagerStore.use.setGrayscaleSatelliteBrightness();

  const onBrightnessChange = (value: number | number[]) => {
    setGrayscaleSatelliteBrightness(Array.isArray(value) ? value[0] : value);
  };

  return (
    <LayerManagerWrapper visible={isLayerManagerOpen} geometry={geometry}>
      <LayerManagerContent>
        <LayerManagerHeader>layers</LayerManagerHeader>
        {children}
        {/* TODO: update when dedicated endpoint is ready */}
        <TopographyLayersGroup {...{ areaId, areaName }} />
        <SatelliteItemWrapper>
          <LabeledCheckbox variant="WIDE" onChange={toggleSatelliteVisibility} checked={isSatelliteVisible}>
            satellite
          </LabeledCheckbox>
          <AuxiliaryControlsWrapper disabled={!isSatelliteVisible}>
            <SwitcherLabel onClick={toggleSatelliteGrayscale}>
              <Switcher checked={isSatelliteGrayscale} />
              black & white mode
            </SwitcherLabel>
            <LabeledSliderWrapper>
              <BrightnessIcon />
              <Slider
                ariaLabel="Brightness slider"
                marks={{
                  '0': '',
                  '1': '',
                  '2': '',
                  '3': '',
                }}
                value={grayscaleSatelliteBrightness}
                onChange={onBrightnessChange}
                defaultValue={1}
                min={0}
                max={3}
                step={1}
              />
            </LabeledSliderWrapper>
          </AuxiliaryControlsWrapper>
        </SatelliteItemWrapper>
        <BasemapSelectorWrapper>
          <BasemapSelector />
        </BasemapSelectorWrapper>
      </LayerManagerContent>
    </LayerManagerWrapper>
  );
};

LayerManager.displayName = 'LayerManager';

interface LayerManagerWrapperProps {
  visible: boolean;
  geometry?: LayerManagerGeometry;
}

export const LayerManagerWrapper = styled.div<LayerManagerWrapperProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  top: ${({ geometry }) => geometry?.top || 127}px;
  right: ${({ geometry }) => geometry?.right || 66}px;
  min-width: ${({ geometry }) => geometry?.minWidth || 248}px;
  z-index: ${({ geometry }) => geometry?.zIndex || 951};
`;

export const LayerManagerContent = styled.div`
  border-radius: 8px;
  padding: 1rem;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
`;

const LayerManagerHeader = styled.h4`
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const SatelliteItemWrapper = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const SwitcherLabel = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-top: 2px;
  cursor: pointer;
`;

const BasemapSelectorWrapper = styled.div`
  padding: 10px 20px;
  margin: 0 -1rem -1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${({ theme }) => theme.color.selectGray};
`;
