import { type ReactNode } from 'react';
import styled from 'styled-components';

interface TitledSectionProps {
  title: string;
  children: ReactNode;
}

export const TitledSection = ({ title, children }: TitledSectionProps) => {
  return (
    <Segment>
      <SegmentHeader>{title} </SegmentHeader>
      {children}
    </Segment>
  );
};

const Segment = styled.section<{ mt?: number }>`
  margin-top: 26px;
`;

const SegmentHeader = styled.header`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.1em;
  padding-bottom: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGray};
`;
