import styled from 'styled-components';

export const TopButtonsWrapper = styled.div`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  min-width: 300px;
  height: 50px;
  margin-top: -50px;
  padding: 0.75rem 0.5rem 0;
  background: transparent;
  z-index: 1020;
  white-space: nowrap;
`;
