import { type ValueAndLabel } from 'common/types';
import { Select } from 'ui';
import { useMoistureIndicators } from 'views/water/moisture/indicators/useMoistureIndicators';

export const MoistureIndicatorsSelect = () => {
  const { indicatorsSelectData, handleIndicatorChange: changeIndicator } = useMoistureIndicators();

  const handleIndicatorChange = (option: ValueAndLabel) => {
    changeIndicator(option.value);
  };

  return <Select {...indicatorsSelectData} onChange={handleIndicatorChange} />;
};
