import { barDiagramFontSize } from 'views/reports/external/pdfGenerator/styles';

export const customCanvasBackgroundColor = {
  id: 'customCanvasBackgroundColor',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#ffffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const yTitleTopPlugin = {
  id: 'yTitleTopPlugin',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  afterDraw: function (chart, args, options) {
    const lines = options.text.split('\n');
    let linesYoffset = 0;

    const { ctx } = chart;

    const fontSize = barDiagramFontSize;
    const fontFamily = 'Barlow';
    ctx.font = fontSize + 'px ' + fontFamily;
    ctx.fillStyle = 'black';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'middle';

    const textWidth = ctx.measureText(lines[0]).width;

    const yAxis = chart.scales.y;
    const xOffset = 3 - textWidth;
    const yOffset = 0;

    ctx.fillStyle = 'white';
    ctx.fillRect(yAxis.left - 5, yAxis.top + yOffset - fontSize + 5, textWidth + 5, lines.length * fontSize + 10);

    ctx.fillStyle = 'black';

    for (let i = 0; i < lines.length; i++) {
      ctx.fillText(lines[i], yAxis.left - xOffset, yAxis.top + yOffset + linesYoffset);
      linesYoffset += fontSize;
    }
  },
};

export const hideTopGridLine = {
  id: 'hideTopGridLine',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  afterDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.fillStyle = options.color || '#ffffff';
    ctx.fillRect(chart.chartArea.left, chart.chartArea.top, chart.width, 2);
  },
};
