import { ConfigCatProvider, LogLevel, createConsoleLogger } from 'configcat-react';
import { type PropsWithChildren } from 'react';
import env from 'react-dotenv';

export const ConfigCat = ({ children }: PropsWithChildren) => {
  const logger = createConsoleLogger(LogLevel.Info);

  const configcatKey = env.REACT_APP_CONFIGCAT_SDK_KEY;

  if (!configcatKey) {
    throw new Error('REACT_APP_CONFIGCAT_SDK_KEY is not provided');
  }

  return (
    <ConfigCatProvider sdkKey={configcatKey} options={{ logger }}>
      {children}
    </ConfigCatProvider>
  );
};
