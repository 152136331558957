import { useState } from 'react';
import { Polyline, useMap, useMapEvents } from 'react-leaflet';

import { type MapPoint } from 'common/types/mapData';
import { Polygon } from 'ui/map/areaSelector/Polygon';
import { useAreaRequestStore } from './AreaRequestStore';

interface DrawPolygonProps {
  enabled?: boolean;
}

const DrawPolygonPolyline = ({ linePoint }: { linePoint: number[] | undefined }) => {
  const map = useMap();

  const [currentPoint, setCurrentPoint] = useState<number[] | null>(null);

  const positions = currentPoint && linePoint ? [linePoint, currentPoint] : undefined;

  useMapEvents({
    mousemove: async (event: { originalEvent: MouseEvent }) => {
      const latLng = (await map.mouseEventToLatLng(event.originalEvent)) as MapPoint;
      if (!latLng) return;

      setCurrentPoint([latLng.lat, latLng.lng]);
    },
  });

  return positions ? (
    <Polyline
      positions={positions}
      pathOptions={{
        color: 'white',
        fill: false,
        opacity: 0.7,
        weight: 2,
        fillOpacity: 0.1,
        pane: 'verticesLayer',
        dashArray: '10,10',
      }}
    />
  ) : null;
};

export const DrawPolygon = ({ enabled }: DrawPolygonProps) => {
  const polygonPoints = useAreaRequestStore.use.polygonPoints();
  const setPolygonPoints = useAreaRequestStore.use.setPolygonPoints();

  const linePoint = polygonPoints.length > 0 ? polygonPoints.slice(-1)[0] : undefined;

  useMapEvents({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    click: (event: any) => {
      if (!enabled) return;

      const latlng = event.latlng as MapPoint;

      if (!latlng) return;

      setPolygonPoints([...polygonPoints, [latlng.lat, latlng.lng]]);
    },
  });

  return (
    <>
      {enabled && <DrawPolygonPolyline linePoint={linePoint} />}
      <Polygon coordinates={polygonPoints} minLength={1} onVertexClick={() => {}} alert={false} />
    </>
  );
};
