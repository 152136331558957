import styled from 'styled-components';

import { ReactComponent as PolygonIcon } from 'assets/svg/polygon-icon.svg';
import { MapControlButton } from 'ui/map/mapControlButton';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { createPolygon } from 'ui/map/areaSelector/helpers';

export const DrawPolygonControlButton = ({ disabled }: { disabled: boolean }) => {
  const setFeatures = useAreaRequestStore.use.setFeatures();

  const polygonPoints = useAreaRequestStore.use.polygonPoints();
  const setPolygonPoints = useAreaRequestStore.use.setPolygonPoints();

  const drawMode = useAreaRequestStore.use.drawMode();
  const toggleDrawMode = useAreaRequestStore.use.toggleDrawMode();
  const disableDrawMode = useAreaRequestStore.use.disableDrawMode();

  const resetStatus = useAreaRequestStore.use.resetStatus();
  const resetArea = useAreaRequestStore.use.resetArea();

  const handleSetPolygonDraw = () => {
    toggleDrawMode('polygon');
    setPolygonPoints([]);
    setFeatures([]);
    resetArea();
    resetStatus();
  };

  const onSaveClick = () => {
    if (polygonPoints.length > 2) {
      setFeatures([createPolygon(polygonPoints)]);
      setPolygonPoints([]);
      disableDrawMode();
    }
  };

  const onCancelClick = () => {
    setFeatures([]);
    setPolygonPoints([]);
    disableDrawMode();
    resetArea();
    resetStatus();
  };

  return (
    <StyledDrawPolygonControlButton>
      <MapControlButton
        icon={<PolygonIcon />}
        iconWidth={20}
        active={drawMode === 'polygon'}
        disabled={disabled}
        onClick={handleSetPolygonDraw}
      />
      {drawMode === 'polygon' && (
        <DrawPolygonOptions>
          <button disabled={polygonPoints.length < 3} onClick={onSaveClick}>
            Save
          </button>
          <button onClick={onCancelClick}>Cancel</button>
        </DrawPolygonOptions>
      )}
    </StyledDrawPolygonControlButton>
  );
};

const StyledDrawPolygonControlButton = styled.div`
  position: relative;
`;

const DrawPolygonOptions = styled.div`
  position: absolute;
  right: calc(100% - 12px);
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  height: 100%;
  background-color: ${({ theme }) => theme.color.midGray};
  width: 112px;
  border-radius: 4px 0 0 4px;
  padding-right: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  button {
    position: relative;
    background-color: transparent;
    border: none;
    height: 100%;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;

    &:first-child {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background-color: ${({ theme }) => theme.color.selectGray};
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.6;
      cursor: auto;
    }
  }
`;
