import { COORDINATES_PRECISION } from 'common/constants';
import { type TooltipData } from 'common/types/mapData';

export const buildDisplacementsTooltipStringHelper = (tooltip: TooltipData) => {
  return `
    <div>
      <h4>Coordinates</h4>
      <p>${tooltip.lat.toFixed(COORDINATES_PRECISION)}</p>
      <p>${tooltip.lng.toFixed(COORDINATES_PRECISION)}</p>
      <h4>Cumulative displacement [cm]</h4>
      <p>${tooltip.value}</p>
    </div>
  `;
};
