import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { type Map } from 'leaflet/index';

export const useActiveMarker = (isDataLayerVisible: boolean) => {
  const map: Map = useMap();

  useEffect(() => {
    const markerPane = map.createPane('clickedMarkerPane');
    markerPane.style.zIndex = String(492);

    return () => {
      markerPane.remove();
    };
  }, [map, isDataLayerVisible]);
};
