import { useAoiNumber } from 'common/navigation/hooks';
import { areasRepository } from 'infrastructure/areas/areasRepository';

export const useGetExplorationFileNameEnding = () => {
  const areaId = useAoiNumber();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const fileNameEnding = `${
    areaOfInterestDetails
      ? `_${areaOfInterestDetails.id}_${areaOfInterestDetails.properties.display_name
          .replaceAll('   ', ' ')
          .replaceAll('  ', ' ')
          .replaceAll(' ', '_')
          .replaceAll(/[(),]/g, '')
          .toLocaleLowerCase()}`
      : ''
  }`;

  return fileNameEnding;
};
