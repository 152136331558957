import {
  AREA_CARD_GAP,
  AREAS_LIST_OFFSET_TOP,
  AREA_CARD_MIN_HEIGHT,
  AREAS_LIST_COLUMNS_BREAKPOINT,
} from 'domain/areas/constants';

export const getLoadingCardsArray = (): number[] => {
  const areasListColumnsQty = window.innerWidth > AREAS_LIST_COLUMNS_BREAKPOINT ? 2 : 1;
  const loadingCardsQuantity =
    Math.ceil((window.innerHeight - AREAS_LIST_OFFSET_TOP - AREA_CARD_GAP) / (AREA_CARD_MIN_HEIGHT + AREA_CARD_GAP)) *
      areasListColumnsQty -
    1;

  const quantity = loadingCardsQuantity > 0 ? loadingCardsQuantity : 1;
  const loadingCards = [...new Array(quantity)].map((_, i) => i);

  return loadingCards;
};
