import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { VIEW_MODE_QUERY_PARAMETER, WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { Loading } from 'ui/molecules/loading';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import * as map from 'components/map';
import { ShareModal } from 'components/downloadModal/legacyShareModal';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { WATERMASKS_MAIN_COLOR, WATERMASKS_REPORT_COLOR } from 'domain/water/colors';
import { WaterActiveTab } from 'domain/water/enums';
import { WaterAreaMapLayer } from './WaterAreaMapLayer';
import { useGetWaterAreaComparatorCogsUseCase } from './getWaterAreaComparatorCogsUseCase';
import { WaterAreaMapContentComparison } from './WaterAreaMapContentComparison';
import { useGetWaterAreaRemappedResults } from './useGetWaterAreaRemappedResults';
import { WaterAreaTitilerTooltip } from './tooltip';
import { useDataProviders } from '../dataProviders/useDataProviders';

export const WaterAreaMapContent = () => {
  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const takeScreenshotFlag = useReportsStore.use.takeScreenshotFlag();

  const areReportsActive = useGetAreReportsActiveUseCase();
  const waterAreaLayerColor = areReportsActive ? WATERMASKS_REPORT_COLOR : WATERMASKS_MAIN_COLOR;

  const viewQuery: string | null = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const { remappedResults, areRemappedResultsLoading } = useGetWaterAreaRemappedResults();
  const { comparatorCogsUrlTuples, comparatorCogsLoading } = useGetWaterAreaComparatorCogsUseCase();

  const { selectedProvider } = useDataProviders();

  const filteredCogsUrlWithBounds = (
    waterbodyIdQuery
      ? remappedResults.filter(
          (item) => item.waterbody_id === Number(waterbodyIdQuery) || item.waterbody_id === undefined,
        )
      : remappedResults
  ).filter(({ provider }) => provider === selectedProvider?.name);

  const availableCogsUrlWithBounds = isComparisonActive
    ? filteredCogsUrlWithBounds.filter(({ id }) => comparatorCogsUrlTuples.some((tuple) => tuple[0] === String(id)))
    : filteredCogsUrlWithBounds;

  const shareModalLinks = filteredCogsUrlWithBounds.map(({ cog_url }) => cog_url || '');

  if (areRemappedResultsLoading || comparatorCogsLoading) {
    return <Loading />;
  }

  return (
    <>
      <map.Layer visible={isDataLayerVisible} name={'dataLayer'} zIndex={490}>
        {availableCogsUrlWithBounds.map(
          (set) =>
            set?.cog_url && (
              <WaterAreaMapLayer key={set.id} url={set.cog_url} pane={'dataLayer'} color={waterAreaLayerColor} />
            ),
        )}
        {!takeScreenshotFlag && (
          <WaterAreaTitilerTooltip
            isDataLayerVisible={isDataLayerVisible}
            urlsWithIds={filteredCogsUrlWithBounds.map(({ cog_url, id }) => ({ url: cog_url || '', id }))}
          />
        )}
      </map.Layer>
      {isComparisonActive ? <WaterAreaMapContentComparison /> : null}
      <ShareModal links={isDataLayerVisible ? shareModalLinks : []} activeTab={WaterActiveTab.SURFACE} />
    </>
  );
};
