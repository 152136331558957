import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createSelectors } from 'common/utils/createSelectors';
import { localStorageService } from 'services/localStorageService';
import { LOCAL_STORAGE_SIDEBAR_UNLOCK_NAME } from 'common/constants/services';
import { SIDEBAR_PANEL_CLOSE_TIMEOUT } from 'common/constants';

export type SidebarPanelType = 'USER_PANEL' | 'NOTIFICATIONS_PANEL' | 'ASSISTANT_PANEL';

interface sidebarState {
  isSidebarFolded: boolean;
  unfoldSidebar: () => void;
  foldSidebar: () => void;
  isSidebarUnlocked: boolean;
  prevIsSidebarUnlockedState?: boolean;
  lockSidebar: () => void;
  unlockSidebar: () => void;
  toggleSidebar: () => void;
  isSidebarPanelOpen: boolean;
  sidebarPanelType?: SidebarPanelType;
  openSidebarPanel: (sidebarPanelType: SidebarPanelType) => void;
  closeSidebarPanel: () => void;
}

const initialState = {
  isSidebarUnfolded: true,
  isSidebarUnlocked: localStorageService.getValue<boolean>(LOCAL_STORAGE_SIDEBAR_UNLOCK_NAME, false),
  isSidebarPanelOpen: false,
  sidebarPanelType: undefined,
  prevIsSidebarUnlockedState: undefined,
};

const useSidebarStoreBase = create<sidebarState>()(
  devtools(
    (set) => ({
      isSidebarFolded: initialState.isSidebarUnfolded,
      unfoldSidebar: () => set({ isSidebarFolded: false }, false, 'sidebar/show'),
      foldSidebar: () => set({ isSidebarFolded: true }, false, 'sidebar/hide'),
      isSidebarUnlocked: initialState.isSidebarUnlocked,
      lockSidebar: () => set({ isSidebarUnlocked: false }, false, 'sidebar/lock'),
      unlockSidebar: () => set({ isSidebarUnlocked: true }, false, 'sidebar/unlock'),
      toggleSidebar: () => set((state) => ({ isSidebarUnlocked: !state.isSidebarUnlocked }), false, 'sidebar/unlock'),
      isSidebarPanelOpen: initialState.isSidebarPanelOpen,
      openSidebarPanel: (sidebarPanelType: SidebarPanelType) =>
        set(
          ({}) => {
            return {
              sidebarPanelType,
              isSidebarPanelOpen: true,
              isSidebarFolded: true,
              isSidebarUnlocked: true,
              prevIsSidebarUnlockedState: localStorageService.getValue<boolean>(
                LOCAL_STORAGE_SIDEBAR_UNLOCK_NAME,
                false,
              ),
            };
          },
          false,
          'sidepanel/open',
        ),
      closeSidebarPanel: () => {
        set(
          ({ prevIsSidebarUnlockedState }) => {
            return {
              isSidebarPanelOpen: false,
              isSidebarUnlocked: prevIsSidebarUnlockedState,
              prevIsSidebarUnlockedState: undefined,
            };
          },
          false,
          'sidepanel/close',
        );
        setTimeout(() => set({ sidebarPanelType: undefined }), SIDEBAR_PANEL_CLOSE_TIMEOUT);
      },
      sidebarPanelType: initialState.sidebarPanelType,
      prevIsSidebarUnlockedState: initialState.prevIsSidebarUnlockedState,
    }),

    { name: 'sidebarStore' },
  ),
);

export const useSidebarStore = createSelectors(useSidebarStoreBase);
