import { formatDate } from 'common/utils/datetime';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useQueryParameter } from 'common/navigation/hooks';
import { type ExtendedIndicator } from 'common/types';
import { capitalizeAllFirstLetters, simpleRound } from 'common/utils';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdfSimpleContent } from 'views/reports/external/pdfGenerator/simple/ReportPdfSimpleContent';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { reportsQueryService } from 'views/reports/reportsQueryService';
import { REPORTS_ALL_WATERBODIES_LABEL, REPORTS_INDICATOR_STATS } from 'domain/reports/constants';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';
import { getAllWaterbodiesIndicatorStats } from 'domain/reports/helpers';

interface WaterQualitySingleDateReportsExternalDataProps {
  areaId: number;
  timestamp: string;
  selectedIndicator: ExtendedIndicator;
  reportInfo: ExternalReportHeaderCommon;
}

export const WaterQualitySingleDateReportsExternalData = ({
  areaId,
  timestamp,
  selectedIndicator,
  reportInfo,
}: WaterQualitySingleDateReportsExternalDataProps) => {
  const { waterBodiesList: waterbodies } = waterRepository.useFetchWaterbodies(areaId);
  const selectedWaterbodyId = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const selectedWaterbodyLabel = selectedWaterbodyId
    ? waterbodies.find(({ id }) => String(id) === selectedWaterbodyId)?.name || ''
    : REPORTS_ALL_WATERBODIES_LABEL;

  const { waterQualityResults } = waterRepository.useFetchWaterQualityResults(areaId, timestamp, selectedIndicator.id);

  const { indicatorStatsQuery } = reportsQueryService.useReportsIndicatorStats();

  const reportScreenshotSrc = useReportsStore.use.reportScreenshotSrc();

  const allWaterbodiesStats = getAllWaterbodiesIndicatorStats(waterQualityResults, selectedIndicator);

  const selectedWaterbodyStats = waterQualityResults.find(
    ({ waterbody_id }) => String(waterbody_id) === selectedWaterbodyId,
  );

  const data = REPORTS_INDICATOR_STATS.filter(({ id }) => indicatorStatsQuery?.includes(String(id)))
    .filter(({ isForAll }) => selectedWaterbodyId || isForAll)
    .map(({ id, name, label }) => {
      const selectedWaterbodyStatsValue = selectedWaterbodyStats ? selectedWaterbodyStats[name] : null;

      const indicatorValue = selectedWaterbodyId
        ? selectedWaterbodyStatsValue
        : allWaterbodiesStats[name as keyof typeof allWaterbodiesStats];

      const value =
        typeof indicatorValue === 'number' ? simpleRound(indicatorValue, selectedIndicator.precision) : null;

      return {
        id,
        label: capitalizeAllFirstLetters(label),
        value,
      };
    });

  const header = {
    ...reportInfo,
    reportType: 'Single Date Report',
    reportFunctionality: `Water Quality: ${selectedWaterbodyLabel}`,
    reportAdditionalLabel: selectedIndicator.indicator_name,
    dates: {
      first: formatDate(timestamp),
    },
  };

  const colorScale = {
    labels: selectedIndicator.labels,
    colormap: selectedIndicator.colormap,
    unit: selectedIndicator.unit,
  };

  const table = {
    tableHeader: `${selectedIndicator.indicator_name} Statistics For ${selectedWaterbodyLabel}`,
    dataHeader: 'Parameter Name',
    unit: selectedIndicator.unit,
    data,
  };

  if (!reportScreenshotSrc) {
    return null;
  }

  return (
    <>
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} colorScale={colorScale} />
          <ReportPdfSimpleContent screenshotScr={reportScreenshotSrc} table={table} />
        </>
      </ReportPdf>
    </>
  );
};
