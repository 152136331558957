import { type CSSProperties, type ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { type Palette } from 'ui/types';

export type VariantsType = 'PRIMARY' | 'DANGER';

type StylesType = Required<
  Pick<CSSProperties, 'backgroundColor'> & {
    iconColor?: CSSProperties['color'];
    iconColorHover?: CSSProperties['color'];

    iconBackgroundColor?: CSSProperties['backgroundColor'];
    iconBackgroundHoverColor?: CSSProperties['backgroundColor'];

    backgroundColor?: CSSProperties['backgroundColor'];
    backgroundColorHover?: CSSProperties['backgroundColor'];
  }
>;

const styles: Palette<VariantsType, StylesType> = {
  PRIMARY: {
    iconColor: 'white',
    iconColorHover: 'red',

    iconBackgroundColor: 'red',
    iconBackgroundHoverColor: 'white',

    backgroundColor: 'masala',
    backgroundColorHover: 'midGray',
  },
  DANGER: {
    iconColor: 'white',
    iconColorHover: 'red',

    iconBackgroundColor: 'red',
    iconBackgroundHoverColor: 'white',

    backgroundColor: 'notificationDarkRed',
    backgroundColorHover: 'error',
  },
};

interface NotificationProps {
  children: ReactNode;
  icon: JSX.Element;
  $iconSize?: number;
  $width?: number | string;
  variant?: VariantsType;
  to: string;
  onClick?: () => void;
}

export const Notification = ({ children, icon, variant = 'PRIMARY', $width, to, onClick }: NotificationProps) => {
  return (
    <StyledNotification to={to} onClick={onClick} $variant={variant} $width={$width}>
      <StyledButton $variant={variant} data-icon>
        {icon}
      </StyledButton>
      {children}
    </StyledNotification>
  );
};

const StyledButton = styled.div<{ $variant: VariantsType }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme, $variant }) => theme.color[styles[$variant].iconColor]};
  background-color: ${({ theme, $variant }) => theme.color[styles[$variant].iconBackgroundColor]};
`;

const StyledNotification = styled(NavLink)<{ $variant: VariantsType; $width?: number | string }>`
  width: 220px;
  height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme, $variant }) => theme.color[styles[$variant].backgroundColor]};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;

  ${({ $width }) =>
    $width && typeof $width === 'number'
      ? css`
          width: ${$width}px;
        `
      : css`
          width: ${$width};
        `};

  &:hover {
    background-color: ${({ theme, $variant }) => theme.color[styles[$variant].backgroundColorHover]};

    & > [data-icon] {
      background-color: ${({ theme, $variant }) => theme.color[styles[$variant].iconBackgroundHoverColor]};
      color: ${({ theme, $variant }) => theme.color[styles[$variant].iconColorHover]};
    }
  }
`;
