import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from 'common/navigation/routes';
import { LOCAL_STORAGE_SIDEBAR_UNLOCK_NAME } from 'common/constants/services';
import { fadeIn } from 'common/animations';
import { ReactComponent as MenuLockIcon } from 'assets/svg/menu-locked-icon.svg';
import { IconButton } from 'ui/atoms/iconButton';
import { Container } from 'ui/atoms/container';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { useGetSidebarHomeButtonHover } from 'ui/sidebar/useGetSidebarHomeButtonHover';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { HomeButton } from 'ui/atoms';

interface SidebarHeaderProps {
  userName: string | undefined;
  isFolded?: boolean;
}

export const SidebarHeader = ({ userName, isFolded }: SidebarHeaderProps) => {
  const navigate = useNavigate();
  const isSidebarUnlocked = useSidebarStore.use.isSidebarUnlocked();

  const toggleSidebar = useSidebarStore.use.toggleSidebar();

  const toggleSidebarLock = () => {
    localStorage.setItem(LOCAL_STORAGE_SIDEBAR_UNLOCK_NAME, JSON.stringify(!isSidebarUnlocked));
    toggleSidebar();
  };

  const clearReports = useReportsStore.use.clearReports();

  const { handleHomeButtonHover } = useGetSidebarHomeButtonHover();

  const navigateToAreasList = () => {
    navigate(ROUTES.areasList);
    clearReports();
  };

  return (
    <SidebarHeaderWrapper>
      <div>
        <HomeButton onClick={navigateToAreasList} onHover={handleHomeButtonHover} />
      </div>
      {userName && !isFolded && (
        <Container m={10}>
          <IconWrapper>
            <IconButton
              variant="LOCKED"
              icon={<MenuLockIcon />}
              description={isSidebarUnlocked ? 'lock sidebar' : 'unlock sidebar'}
              onClick={toggleSidebarLock}
              active={!isSidebarUnlocked}
            />
          </IconWrapper>
        </Container>
      )}
    </SidebarHeaderWrapper>
  );
};

const SidebarHeaderWrapper = styled.header`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.codGray};
  z-index: 1080;
`;

const IconWrapper = styled.div`
  animation: ${fadeIn} 0.2s ease-out 0.1s both;
`;
