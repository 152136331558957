import styled, { type CSSProperties, css } from 'styled-components';

import { type Palette } from 'ui/types';

export type CheckboxVariant = 'PRIMARY' | 'LOGIN' | 'TRANSITIONAL';

type CheckboxStyles = Required<
  Pick<CSSProperties, 'borderRadius' | 'borderWidth' | 'borderColor'> & {
    dotColor: CSSProperties['backgroundColor'];
    disabledBorderColor: CSSProperties['borderColor'];
    disabledDotColor: CSSProperties['backgroundColor'];
  }
>;

const styles: Palette<CheckboxVariant, CheckboxStyles> = {
  PRIMARY: {
    borderRadius: 0,
    borderWidth: 1,
    borderColor: 'white',
    dotColor: 'white',
    disabledBorderColor: 'whiteTransparent40',
    disabledDotColor: 'whiteTransparent40',
  },
  LOGIN: {
    borderRadius: 0,
    borderWidth: 1,
    borderColor: 'primary',
    dotColor: 'primary',
    disabledBorderColor: 'whiteTransparent40',
    disabledDotColor: 'whiteTransparent40',
  },
  TRANSITIONAL: {
    borderRadius: 0,
    borderWidth: 1,
    borderColor: 'white',
    dotColor: 'tapa',
    disabledBorderColor: 'whiteTransparent40',
    disabledDotColor: 'whiteTransparent40',
  },
};

interface CheckboxProps {
  variant?: CheckboxVariant;
  checked: boolean;
  radio?: boolean;
  disabled?: boolean;
}

export const Checkbox = ({ variant, checked, radio, disabled }: CheckboxProps) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} readOnly />
    <StyledCheckbox $checked={checked} $variant={variant!} $radio={radio!} $disabled={disabled!} />
  </CheckboxContainer>
);

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

interface StyledCheckboxProps {
  $variant: CheckboxVariant;
  $checked: boolean;
  $radio: boolean;
  $disabled: boolean;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  position: relative;
  width: 14px;
  height: 14px;
  border-width: ${({ $variant }) => styles[$variant!].borderWidth}px;
  border-style: solid;
  border-color: ${({ theme, $variant, $disabled }) =>
    $disabled ? theme.color[styles[$variant!].disabledBorderColor!] : theme.color[styles[$variant!].borderColor!]};
  border-radius: ${({ $radio, $variant }) => ($radio ? '50%' : `${styles[$variant!].borderRadius}px`)};

  &:after {
    display: none;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    margin-top: -3px;
    margin-left: -3px;
    border-radius: 50%;
    background-color: ${({ theme, $variant, $disabled }) =>
      $disabled ? theme.color[styles[$variant!].disabledDotColor!] : theme.color[styles[$variant!].dotColor!]};

    ${({ $checked }) =>
      $checked &&
      css`
        display: block;
      `}
  }
`;

Checkbox.defaultProps = {
  variant: 'PRIMARY' as CheckboxVariant,
  radio: false,
  disabled: false,
};

Checkbox.displayName = 'Checkbox';
