import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { capitalizeAllFirstLetters } from 'common/utils';
import { setColorOpacity } from 'common/utils/color';
import {
  customCanvasBackgroundColor,
  hideTopGridLine,
  yTitleTopPlugin,
} from 'views/reports/external/pdfGenerator/helpers';
import { barDiagramFontSize } from 'views/reports/external/pdfGenerator/styles';
import { type ExternalReportExtendedTableData } from 'domain/reports/types';

interface ReportPdfBarChartGenerateProps {
  data: ExternalReportExtendedTableData[];
  header: string;
  unit: string;
  setChartSrc: React.Dispatch<React.SetStateAction<string | null>>;
}

Chart.register(ChartDataLabels);
export const ReportPdfBarChartGenerate = ({ data, header, unit, setChartSrc }: ReportPdfBarChartGenerateProps) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const filteredData = data.filter((item) => !item.special);

  useEffect(() => {
    if (!chartRef?.current) return;
    const datasetsData1 = filteredData.map((item) => Number(item.firstValue));
    const datasetsData2 = filteredData.map((item) => Number(item.secondValue));
    const datasetsDataMax = Math.max(
      ...datasetsData1.filter((i) => i !== null),
      ...datasetsData2.filter((i) => i !== null),
    );

    const chart = new Chart(chartRef.current, {
      type: 'bar',
      data: {
        labels: filteredData.map((item) => capitalizeAllFirstLetters(item.label)),
        datasets: [
          {
            label: filteredData.length < 10 ? 'Date 1' : '',
            data: datasetsData1,
            backgroundColor: data
              .filter((item) => !item.special)
              .map((item) => item.color && setColorOpacity(item.color, 0.6)),
          },
          {
            label: filteredData.length < 10 ? 'Date 2' : '',
            data: datasetsData2,
            backgroundColor: filteredData.map((item) => item.color),
          },
        ],
      },
      plugins: [customCanvasBackgroundColor, yTitleTopPlugin, hideTopGridLine],
      options: {
        aspectRatio: 2.5,
        layout: {
          padding: {
            left: 3,
            top: 20,
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                lineHeight: 4,
                size: barDiagramFontSize,
                weight: 'bold',
              },
            },
            border: {
              color: 'black',
            },
            grid: {
              display: false,
            },
          },
          y: {
            max: datasetsDataMax * 1.3,
            border: {
              color: 'black',
            },
            grid: {
              tickLength: 0,
            },
            ticks: {
              padding: 45,
              font: {
                size: barDiagramFontSize,
              },
            },
          },
        },
        plugins: {
          title: {
            text: header,
            display: true,
            font: {
              lineHeight: 4,
              size: 26,
              weight: 'bold',
            },
            align: 'start',
            color: '#000',
          },
          legend: {
            display: false,
          },
          datalabels: {
            formatter: function (value, context) {
              const label = context.dataset.label;
              return label;
            },
            anchor: 'start',
            align: 'bottom',
            font: {
              size: barDiagramFontSize,
              weight: 400,
            },
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          customCanvasBackgroundColor: {
            color: 'white',
          },
          yTitleTopPlugin: {
            text: `Value\n( ${unit} )`,
          },
          hideTopGridLine: {
            color: 'white',
          },
        },
        animation: {
          onComplete: (event) => {
            if (event.initial) {
              const blob = chart.toBase64Image('image/png', 1);
              setChartSrc(blob);
              imgRef.current && (imgRef.current.src = blob);
            }
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <BarBackground>
      <BarCanvas ref={chartRef} id="myChart" />
      <img ref={imgRef} width="100%" alt="genereting blob for pdf" />
    </BarBackground>
  );
};

const BarBackground = styled.div`
  position: absolute;
  z-index: -1;
  background-color: white;
  width: 1500px;
`;

const BarCanvas = styled.canvas`
  visibility: hidden;
`;
