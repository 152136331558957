import { getWeatherGraphData } from './helpers';
import { type AdditionalDataTypes, type AdditionalOptionsTypes } from './types';
import { GraphSeries } from './enums';
import { useGraphStore } from './graphStore';
import { useWeatherData } from './useWeatherData';

export const useHandleMeteoDataUseCase = () => {
  const { getWeatherData } = useWeatherData();

  const additionalData = useGraphStore.use.additionalData();
  const graphData = useGraphStore.use.graphData();
  const dataPoint = useGraphStore.use.chosenPoint();
  const setGraphFetching = useGraphStore.use.setGraphFetching();
  const resetGraphFetching = useGraphStore.use.resetGraphFetching();
  const setAdditionalData = useGraphStore.use.setAdditionalData();
  const setAdditionalDataOption = useGraphStore.use.setAdditionalDataOption();

  const setMeteoDataUseCase = async (logError: (message: string) => void) => {
    try {
      if (additionalData.precipitation && additionalData.temperature) return;
      setGraphFetching();
      setAdditionalData(additionalData);

      if (!dataPoint || !graphData) throw new Error('No data point selected');

      const weatherResponse = await getWeatherData(dataPoint, graphData);
      const { temperatureData, precipitationData } = getWeatherGraphData(weatherResponse);
      setAdditionalData({ temperature: temperatureData, precipitation: precipitationData } as AdditionalDataTypes);
    } catch (error) {
      if (error instanceof Error) {
        logError(error.message);
        console.error(error);
      }
    } finally {
      resetGraphFetching();
    }
  };

  const setAdditionalDataOptionUseCase = (
    additionalDataOptions: AdditionalOptionsTypes,
    logError: (message: string) => void,
  ) => {
    setAdditionalDataOption(additionalDataOptions);
    if (additionalDataOptions[GraphSeries.PRECIPITATION] || additionalDataOptions[GraphSeries.TEMPERATURE]) {
      setMeteoDataUseCase(logError);
    }
  };

  return { setMeteoDataUseCase, setAdditionalDataOptionUseCase };
};
