import { MONITORING_PATH_NAME } from 'domain/monitoring/constants';
import { PROSPECTING_PATH_NAME } from 'domain/prospecting/constants';
import { type GrantedUserPermissions } from 'domain/user/types';
import { type NavigationGroupConfig, type TabSwitcherOption, type ViewConfig } from './types';

export const getRouteUrlHelper = (rawPath: string, id: number | string | undefined, idTemplate: string): string =>
  rawPath.replace(idTemplate, String(id));

export const getBaseUrlHelper = (url: string) => url.replace(/\?~*.*/g, '');

export const getRawPathHelper = (path: string, areaId: number | string | undefined, idTemplate: string) =>
  path.replace(String(areaId), idTemplate);

export const addActiveGroupToListHelper = async (
  config: NavigationGroupConfig[],
  expandedGroupsLabels: string[],
): Promise<string[] | undefined> => {
  const activeGroup = config?.filter(({ children }) => children?.some(({ active }) => active));

  const activeGroupLabel = activeGroup?.length ? activeGroup[0].label_name : undefined;

  if (!activeGroupLabel || expandedGroupsLabels.includes(activeGroupLabel)) {
    return;
  }

  return expandedGroupsLabels.length ? [...expandedGroupsLabels, activeGroupLabel] : [activeGroupLabel];
};

export const toggleCurrentGroupStatusHelper = (
  expandedGroups: string[],
  currentGroup: NavigationGroupConfig,
): string[] | undefined => {
  return expandedGroups.includes(currentGroup.label_name)
    ? [...expandedGroups.filter((label_name) => label_name !== currentGroup.label_name)]
    : [...expandedGroups, currentGroup.label_name];
};

export const getAvailableTabSwitcherOptions = (buttons: TabSwitcherOption[], userPermissions: GrantedUserPermissions) =>
  userPermissions && buttons.filter(({ value }) => userPermissions?.views.includes(value));

export const isParticularViewAvailable = (view: string, userPermissions: GrantedUserPermissions) =>
  userPermissions?.views.includes(view) || false;

export const areAnyTabsDefined = (config: ViewConfig) => Object.keys(config.tabs).length > 0;

export const isAnyViewAvailable = (config: ViewConfig, userPermissions: GrantedUserPermissions) =>
  Object.keys(config.tabs).some((tabName) => isParticularViewAvailable(tabName, userPermissions)) ||
  isParticularViewAvailable(config.name, userPermissions);

export const getFirstAvailablePath = (config: ViewConfig, userPermissions: GrantedUserPermissions) =>
  Object.keys(config.tabs).map((tabName) =>
    isParticularViewAvailable(tabName, userPermissions) ? config.tabs[tabName as keyof typeof config.tabs].path : '',
  )[0];

export const getPermittedRoutes = (config: ViewConfig, userPermissions: GrantedUserPermissions) => {
  if (config.name === MONITORING_PATH_NAME || config.name === PROSPECTING_PATH_NAME) {
    return [config.path];
  }

  const permittedRoutes: string[] = [];

  if (isAnyViewAvailable(config, userPermissions)) {
    permittedRoutes.push(config.path);
  }

  Object.keys(config.tabs).forEach((tabName) => {
    if (!isParticularViewAvailable(tabName, userPermissions)) {
      return;
    }

    const tabPath = config.tabs[tabName].path;

    permittedRoutes.push(tabPath);
  });

  return permittedRoutes;
};
