import { type AxiosResponse } from 'axios';

import { type CogResultApiResponse, type LayerObjectsApiResponse, type UrlResult } from 'common/types/api';
import { type LayerObject } from 'common/types/mapData';
import { GREENERY_API_URLS } from 'infrastructure/greenery/greeneryApiUrls';
import { getApiData, getExternalData } from 'services/rest/restApiService';
import {
  type GreeneryHotspotsApiResponse,
  type GreeneryIndicatorStats,
  type GreeneryIndicatorsApiResponse,
  type GreeneryQuarterlyResultsApiResponse,
  type GreeneryResultsApiResponse,
  type GreeneryDateResultsApiResponse,
  type GreeneryVciResultsApiResponse,
  type SegmentationResultsApiResponse,
  type SegmentationAreaResultsApiResponse,
} from 'domain/greenery/types';

export const greeneryApi = {
  getObjectTypes: async ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<LayerObjectsApiResponse>> =>
    getApiData(GREENERY_API_URLS.getObjectTypes, { limit, offset }),

  getDateResults: ({
    areaId,
    limit,
    offset,
  }: {
    areaId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<GreeneryDateResultsApiResponse>> =>
    getApiData(GREENERY_API_URLS.getDateResults, {
      aoi_id: areaId,
      limit,
      offset,
    }),

  getSegmentationResults: ({
    areaId,
    date,
    limit,
    offset,
  }: {
    areaId: number;
    date: string;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<SegmentationResultsApiResponse>> =>
    getApiData(GREENERY_API_URLS.getSegmentationResults, {
      aoi_id: areaId,
      start_date: date,
      end_date: date,
      limit,
      offset,
    }),

  getSegmentationResultsMocked: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(GREENERY_API_URLS.getSegmentationResultsMocked, {
      limit,
      offset,
    }),

  getSegmentationCog: (resultId: number): Promise<AxiosResponse<CogResultApiResponse>> =>
    getApiData(GREENERY_API_URLS.getSegmentationCog.replace(':resultId', String(resultId)), {}),

  getSegmentationComparatorCog: ({
    firstResultId,
    secondResultId,
  }: {
    firstResultId: number;
    secondResultId: number;
  }): Promise<AxiosResponse<CogResultApiResponse>> =>
    getApiData(GREENERY_API_URLS.getSegmentationComparatorCog, {
      first_result_id: firstResultId,
      second_result_id: secondResultId,
    }),

  getSegmentationAreaResults: ({
    areaId,
    date,
    limit,
    offset,
  }: {
    areaId: number;
    date: string;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<SegmentationAreaResultsApiResponse>> | undefined =>
    getApiData(GREENERY_API_URLS.getSegmentationAreaResults, {
      aoi_id: areaId,
      start_date: date,
      end_date: date,
      limit,
      offset,
    }),

  getQuarterlySegmentationResults: ({
    areaId,
    year,
    quarter,
    limit,
    offset,
  }: {
    areaId?: number;
    year?: number;
    quarter?: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<GreeneryQuarterlyResultsApiResponse>> =>
    getApiData(GREENERY_API_URLS.getQuarterlySegmentationResults, {
      aoi_id: areaId,
      year: year,
      quarter: quarter,
      limit: limit,
      offset: offset,
    }),

  getQuarterlySegmentationCog: (resultId: string | number): Promise<AxiosResponse<CogResultApiResponse>> =>
    getApiData(GREENERY_API_URLS.getQuarterlySegmentationCog.replace(':resultId', String(resultId)), {}),

  getQuarterlySegmentationComparatorCog: ({
    firstResultId,
    secondResultId,
  }: {
    firstResultId: number;
    secondResultId: number;
  }) =>
    getApiData(GREENERY_API_URLS.getQuarterlySegmentationComparatorCog, {
      first_result_id: firstResultId,
      second_result_id: secondResultId,
    }),

  getVegetationIndicators: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<GreeneryIndicatorsApiResponse>> =>
    getApiData(GREENERY_API_URLS.getVegetationIndicators, { limit, offset }),

  getVegetationIndicatorStats: (resultId: number): Promise<AxiosResponse<GreeneryIndicatorStats>> =>
    getApiData(GREENERY_API_URLS.getVegetationIndicatorStats.replace(':resultId', String(resultId)), {}),

  getVegetationResults: ({
    areaId,
    date,
    indicatorId,
    limit,
    offset,
  }: {
    areaId: number;
    date: string;
    indicatorId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<GreeneryResultsApiResponse>> =>
    getApiData(GREENERY_API_URLS.getVegetationResults, {
      start_date: date,
      end_date: date,
      aoi_id: areaId,
      indicator_id: indicatorId,
      limit,
      offset,
    }),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getVegetationResultsMocked: (): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(GREENERY_API_URLS.getVegetationResultsMocked, {}),

  getVegetationCog: (resultId: number): Promise<AxiosResponse<CogResultApiResponse>> =>
    getApiData(GREENERY_API_URLS.getVegetationCog.replace(':resultId', String(resultId)), {}),

  getVegetationComparatorCog: ({
    firstResultId,
    secondResultId,
  }: {
    firstResultId: number;
    secondResultId: number;
  }): Promise<AxiosResponse<CogResultApiResponse>> =>
    getApiData(GREENERY_API_URLS.getVegetationComparatorCog, {
      first_result_id: firstResultId,
      second_result_id: secondResultId,
    }),

  getHotspotsResults: ({
    areaId,
    startDate,
    endDate,
    limit,
    offset,
  }: {
    areaId: number;
    startDate?: string;
    endDate?: string;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<GreeneryHotspotsApiResponse>> =>
    getApiData(GREENERY_API_URLS.getHotspotsResults, {
      aoi_id: areaId,
      start_date: startDate,
      end_date: endDate,
      limit,
      offset,
    }),

  getHotspotsCog: (resultId: number): Promise<AxiosResponse<CogResultApiResponse>> =>
    getApiData(GREENERY_API_URLS.getHotspotsCog.replace(':resultId', String(resultId)), {}),

  getVhrObjectTypes: ({ limit, offset }: { limit?: number; offset?: number }): Promise<AxiosResponse<LayerObject[]>> =>
    getApiData(GREENERY_API_URLS.getVhrObjectTypes, { limit, offset }),

  getVhrSegmentationCog: (): Promise<AxiosResponse<UrlResult>> =>
    getApiData(GREENERY_API_URLS.getVhrSegmentationCog, {}),

  getVciResults: ({
    areaId,
    limit,
    offset,
  }: {
    areaId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<GreeneryVciResultsApiResponse>> =>
    getApiData(GREENERY_API_URLS.getVciResults, {
      aoi_id: areaId,
      limit: limit,
      offset: offset,
    }),

  getVciCog: (resultId: number): Promise<AxiosResponse<CogResultApiResponse>> =>
    getApiData(GREENERY_API_URLS.getVciCog.replace('{id}', String(resultId)), {}),

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  getHabitatsExpandedAreaResults: (): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(GREENERY_API_URLS.getHabitatsExpandedAreaResults, {}),

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  getHabitatsOccurenceResults: (): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(GREENERY_API_URLS.getHabitatsOccurenceResults, {}),

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  getHabitatsPredictionResults: (): Promise<AxiosResponse<any, any> | undefined> =>
    getExternalData(GREENERY_API_URLS.getHabitatsPredictionResults, {}),
};
