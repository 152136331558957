import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  type LayersStatsDictionary,
  type LayerManagerSliderConfigLegacy,
  type LayersTresholdsDictionary,
} from 'common/types';
import { buildSliderMarks, restrictValue } from 'common/utils';
import { Container, Slider } from 'ui';
import { Input } from 'ui/atoms/input';
import { HIDE_THRESHOLD_INPUT_TIMEOUT } from 'domain/exploration/constants';

interface ThresholdSliderProps {
  layerObjectName: string;
  thresholds: LayersTresholdsDictionary;
  config: LayerManagerSliderConfigLegacy;
  isSliderWithInput?: boolean;
  layersStats?: LayersStatsDictionary;
  changeLayerTreshold: (layerState: LayersTresholdsDictionary) => void;
}

export const LayerManagerThresholdSlider = ({
  layerObjectName,
  thresholds,
  config: { range, min, max, step, segments, defaultValue, accuracy, variant },
  isSliderWithInput,
  layersStats,
  changeLayerTreshold,
}: ThresholdSliderProps) => {
  const [showInput, setShowInput] = useState(false);

  const currentMin = layersStats && layersStats[layerObjectName] ? layersStats[layerObjectName].min : range[0];
  const currentMax = layersStats && layersStats[layerObjectName] ? layersStats[layerObjectName].max : range[1];

  const sliderMarks = buildSliderMarks(currentMin, currentMax, segments, accuracy);

  const sliderValue = thresholds[layerObjectName];

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        showInput && setShowInput(false);
      }, HIDE_THRESHOLD_INPUT_TIMEOUT);
    };

    resetTimeout();

    return () => clearTimeout(timeoutId);
  }, [showInput, sliderValue]);

  if (sliderValue === undefined) {
    return null;
  }

  const inputConfig = {
    defaultValue,
    value: sliderValue,
    onChange: (value: number | number[]) => {
      !showInput && setShowInput(true);
      changeLayerTreshold({
        ...thresholds,
        [layerObjectName]: restrictValue(value, max, min, accuracy),
      });
    },
    min: currentMin,
    max: currentMax,
    step,
  };

  return (
    <Container ph={4} pv={10} wMax variant="VERTICAL">
      <Slider ariaLabel="Treshold adjustment" variant={variant} marks={sliderMarks} {...inputConfig} isThumbLabel />
      {isSliderWithInput && typeof sliderValue === 'number' && (
        <DetailInputWrapper mv={showInput ? 4 : 6} wMax isOpen={showInput}>
          <p>Detail Adjustment</p>
          <Input
            type="number"
            {...inputConfig}
            value={sliderValue}
            defaultValue={undefined}
            onChange={({ target }) => {
              const value = target.valueAsNumber;
              if (isNaN(value)) return;
              inputConfig.onChange(value);
            }}
          />
        </DetailInputWrapper>
      )}
    </Container>
  );
};

const DetailInputWrapper = styled(Container)<{ isOpen: boolean }>`
  display: grid;
  grid-template-columns: 59px 56px;
  align-items: end;
  justify-content: end;
  gap: 8px;
  overflow-y: hidden;
  height: ${({ isOpen }) => (isOpen ? '24px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '100' : '0')};
  transition: height ${({ theme }) => theme.transitionFunction.main},
    opacity ${({ theme }) => theme.transitionFunction.main};

  p {
    font-size: 10px;
    font-weight: 600;
    line-height: 11px;
    text-align: right;
    margin: 0;
    color: ${({ theme }) => theme.color.silverDark};
  }

  input {
    font-size: 14px;
    font-weight: 600;
    line-height: 11px;
    height: 24px;
  }
`;
