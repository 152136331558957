import { type InitialWarningError } from 'domain/@errors/types';
import { WarningErrorType } from 'domain/@errors/enums';
import { AREAS_PATH_NAME, AREA_PATH_NAME } from 'domain/areas/constants';
import { WATER_PATH_NAME } from 'domain/water/constants';
import { GREENERY_PATH_NAME } from 'domain/greenery/constants';
import { DISPLACEMENTS_PATH_NAME } from 'domain/displacements/constants';
import { MINE_EXTRACTION_PATH_NAME } from 'domain/extraction/constants';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { WaterActiveTab } from 'domain/water/enums';

export const initialWarningErrors: InitialWarningError[] = [
  //aoi
  {
    content: 'Unable to load available areas',
    priority: 2,
    type: WarningErrorType.AOI_LIST,
    isCount: false,
    viewPath: AREAS_PATH_NAME,
    emptyResponseInfo: 'No areas assigned to current account',
  },
  {
    content: 'Unable to fetch up to date satellite image',
    priority: 1,
    type: WarningErrorType.SATELLITE,
    isCount: false,
    viewPath: AREA_PATH_NAME,
    emptyResponseInfo: undefined,
  },

  //mining area
  {
    content: 'Unable to fetch land cover classes for selected area',
    priority: 2,
    type: WarningErrorType.EXTRACTION_RESULTS,
    isCount: false,
    viewPath: MINE_EXTRACTION_PATH_NAME,
    emptyResponseInfo: 'No land cover classes assigned to selected area',
  },
  {
    content: 'Unable to fetch land cover results for selected date',
    priority: 2,
    type: WarningErrorType.EXTRACTION_POLYGONS,
    isCount: false,
    viewPath: MINE_EXTRACTION_PATH_NAME,
    emptyResponseInfo: 'No land cover results for selected date',
  },

  //water
  {
    content: 'Unable to fetch waterbodies for selected area',
    priority: 2,
    type: WarningErrorType.WATERBODIES,
    isCount: false,
    viewPath: WATER_PATH_NAME,
    emptyResponseInfo: 'No waterbodies assigned to selected area',
  },
  {
    content: 'Unable to fetch timeline dates for selected area',
    priority: 2,
    type: WarningErrorType.WATER_DATES,
    isCount: false,
    viewPath: WATER_PATH_NAME,
    emptyResponseInfo: 'No timeline dates assigned to selected area',
  },
  {
    content: 'Unable to load weather data',
    priority: 2,
    isCount: false,
    type: WarningErrorType.WEATHER_DATA,
    viewPath: WATER_PATH_NAME,
    emptyResponseInfo: undefined,
  },

  {
    content: 'Unable to fetch indicators',
    priority: 2,
    type: WarningErrorType.WATER_QUALITY_INDICATORS,
    isCount: false,
    viewPath: WaterActiveTab.QUALITY,
    emptyResponseInfo: 'No indicators available',
  },
  {
    content: 'Unable to fetch quality data for selected date and indicator',
    priority: 2,
    type: WarningErrorType.WATER_QUALITY_RESULTS,
    isCount: false,
    viewPath: WaterActiveTab.QUALITY,
    emptyResponseInfo: 'No quality data for selected date and indicator',
  },
  {
    content: 'Unable to fetch quality map results for selected date and indicator',
    priority: 2,
    type: WarningErrorType.WATER_QUALITY_COGS,
    isCount: false,
    viewPath: WaterActiveTab.QUALITY,
    emptyResponseInfo: 'No quality map results for selected date and indicator',
  },
  {
    content: 'Unable to fetch time series data assigned to selected point',
    priority: 2,
    type: WarningErrorType.WATER_POINT_HISTORY,
    isCount: false,
    viewPath: WaterActiveTab.QUALITY,
    emptyResponseInfo: undefined,
  },

  {
    content: 'Unable to fetch surface area data for selected date',
    priority: 2,
    type: WarningErrorType.WATER_AREA_RESULTS,
    isCount: false,
    viewPath: WaterActiveTab.SURFACE,
    emptyResponseInfo: 'No surface area data for selected date',
  },
  {
    content: 'Unable to fetch surface area map results for selected date',
    priority: 2,
    type: WarningErrorType.WATER_AREA_COGS,
    isCount: false,
    viewPath: WaterActiveTab.SURFACE,
    emptyResponseInfo: 'No surface area map results for selected date',
  },
  {
    content: 'Unable to fetch surface area map comparison results for current selection',
    priority: 2,
    type: WarningErrorType.WATER_AREA_COMPARATOR_COGS,
    isCount: false,
    viewPath: WaterActiveTab.SURFACE,
    emptyResponseInfo: 'No surface area map comparison results for current selection',
  },

  //landcover
  {
    content: 'Unable to fetch land cover classes',
    priority: 2,
    type: WarningErrorType.LAND_COVER_CLASSES,
    isCount: false,
    viewPath: GREENERY_PATH_NAME,
    emptyResponseInfo: 'No land cover classes available',
  },
  {
    content: 'Unable to fetch daily segmentation and vegetation timeline dates for selected area',
    priority: 2,
    type: WarningErrorType.LAND_COVER_DATES,
    isCount: false,
    viewPath: GREENERY_PATH_NAME,
    emptyResponseInfo: 'No daily segmentation and vegetation timeline dates assigned to selected area',
  },

  {
    content: 'Unable to fetch daily segmentation results for selected date',
    priority: 2,
    type: WarningErrorType.DAILY_SEGMENTATION_COG,
    isCount: false,
    viewPath: GreeneryActiveTab.SEGMENTATION,
    emptyResponseInfo: 'No daily segmentation results for selected date',
  },
  {
    content: 'Unable to fetch daily segmentation comparison results for current selection',
    priority: 2,
    type: WarningErrorType.DAILY_SEGMENTATION_COMPARATOR_COG,
    isCount: false,
    viewPath: GreeneryActiveTab.SEGMENTATION,
    emptyResponseInfo: 'No daily segmentation comparison results for current selection',
  },

  {
    content: 'Unable to fetch quarterly segmentation data for selected area',
    priority: 2,
    type: WarningErrorType.QUARTERLY_SEGMENTATION_RESULTS,
    isCount: false,
    viewPath: GreeneryActiveTab.QUARTERLY,
    emptyResponseInfo: 'No quarterly segmentation data assigned to selected area',
  },
  {
    content: 'Unable to fetch quarterly segmentation results for selected date',
    priority: 2,
    type: WarningErrorType.QUARTERLY_SEGMENTATION_COG,
    isCount: false,
    viewPath: GreeneryActiveTab.QUARTERLY,
    emptyResponseInfo: 'No quarterly segmentation results for selected date',
  },
  {
    content: 'Unable to fetch quarterly segmentation comparison results for current selection',
    priority: 2,
    type: WarningErrorType.QUARTERLY_SEGMENTATION_COMPARATOR_COG,
    isCount: false,
    viewPath: GreeneryActiveTab.QUARTERLY,
    emptyResponseInfo: 'No quarterly segmentation comparison results for current selection',
  },

  {
    content: 'Unable to fetch indicators',
    priority: 2,
    type: WarningErrorType.VEGETATION_INDICATORS,
    isCount: false,
    viewPath: GreeneryActiveTab.VEGETATION,
    emptyResponseInfo: 'No indicators available',
  },
  {
    content: 'Unable to fetch vegetation results for selected date and indicator',
    priority: 2,
    type: WarningErrorType.VEGETATION_RESULTS,
    isCount: false,
    viewPath: GreeneryActiveTab.VEGETATION,
    emptyResponseInfo: 'No vegetation results for selected date and indicator',
  },
  {
    content: 'Unable to fetch vegetation comparison results for current selection',
    priority: 2,
    type: WarningErrorType.VEGETATION_COMPARATOR_COG,
    isCount: false,
    viewPath: GreeneryActiveTab.VEGETATION,
    emptyResponseInfo: 'No vegetation comparison results for current selection',
  },
  {
    content: 'Unable to fetch hotspot types',
    priority: 2,
    type: WarningErrorType.HOTSPOT_TYPES,
    isCount: false,
    viewPath: GreeneryActiveTab.VEGETATION,
    emptyResponseInfo: 'No hotspot types available',
  },

  {
    content: 'Unable to fetch vegetation condition index data for selected area',
    priority: 2,
    type: WarningErrorType.VCI_DATA,
    isCount: false,
    viewPath: GreeneryActiveTab.VCI,
    emptyResponseInfo: 'No vegetation condition index data assigned to selected area',
  },

  //displacements
  {
    content: 'Unable to fetch displacements data for selected area',
    priority: 2,
    type: WarningErrorType.DISPLACEMENTS_RESULTS,
    isCount: false,
    viewPath: DISPLACEMENTS_PATH_NAME,
    emptyResponseInfo: undefined,
  },
  {
    content: 'Unable to parse selected kmz data',
    priority: 2,
    type: WarningErrorType.DISPLACEMENTS_KMZ_RESULTS,
    isCount: false,
    viewPath: DISPLACEMENTS_PATH_NAME,
    emptyResponseInfo: undefined,
  },
  {
    content: 'Unable to display ground deformation results',
    priority: 2,
    type: WarningErrorType.DISPLACEMENTS_COG,
    isCount: false,
    viewPath: DISPLACEMENTS_PATH_NAME,
    emptyResponseInfo: undefined,
  },
];
