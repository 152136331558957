import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as UnsubscribeIcon } from 'assets/svg/unsubscribe-icon.svg';
import { Button } from 'ui/atoms/button';
import { SummaryModal } from 'ui/modal';
import { type Datetime } from 'common/types/time';
import { useCancelSubscription } from './useCancelSubscription';

interface SubscriptionCancelProps {
  disabled: boolean;
  setErrorMessage: (message: string) => void;
  setIsSubscriptionCanceling: (state: boolean) => void;
  isCancelingSubscriptionCombined: boolean;
  expiryDate: Datetime | null;
  large?: boolean;
}

export const SubscriptionCancel = ({
  disabled,
  setErrorMessage,
  setIsSubscriptionCanceling,
  isCancelingSubscriptionCombined,
  expiryDate,
  large,
}: SubscriptionCancelProps) => {
  const [unsubscribeModalVisible, setUnsubscribeModalVisible] = useState(false);
  const [unsubscribeConfirmModalVisible, setUnsubscribeConfirmModalVisible] = useState(false);

  const openUnsubscribeModal = () => setUnsubscribeModalVisible(true);
  const closeUnsubscribeModal = () => setUnsubscribeModalVisible(false);

  const closeConfirmModal = () => setUnsubscribeConfirmModalVisible(false);
  const openConfirmModal = () => setUnsubscribeConfirmModalVisible(true);

  const { handleUnsubscribe, isSubscriptionCanceling } = useCancelSubscription(
    closeUnsubscribeModal,
    openConfirmModal,
    setErrorMessage,
  );

  useEffect(() => {
    setIsSubscriptionCanceling && setIsSubscriptionCanceling(isSubscriptionCanceling);
  }, [isSubscriptionCanceling, setIsSubscriptionCanceling]);

  return (
    <>
      <Button
        minWidth={100}
        variant={large ? 'SECONDARY' : 'PRIMARY_SMALL'}
        disabled={disabled}
        onClick={openUnsubscribeModal}
        isLoading={isCancelingSubscriptionCombined}
        spinnerSize={20}
        spinnerStroke={2}
      >
        Unsubscribe
      </Button>
      <SummaryModal
        icon={<UnsubscribeIcon />}
        headerText="You are about to unsubscribe from TerraEye"
        paragraphText="Once you unsubscribe you will lose access to your previously defined exploration areas."
        isOpen={unsubscribeModalVisible}
        closeModal={closeUnsubscribeModal}
      >
        <ModalButtonsContainer>
          <Button variant="SECONDARY" squared minWidth={145} onClick={closeUnsubscribeModal}>
            cancel
          </Button>
          <Button
            disabled={isCancelingSubscriptionCombined}
            variant="SECONDARY"
            squared
            minWidth={145}
            onClick={handleUnsubscribe}
            isLoading={isCancelingSubscriptionCombined}
          >
            unsubscribe
          </Button>
        </ModalButtonsContainer>
      </SummaryModal>
      <SummaryModal
        icon={<UnsubscribeIcon />}
        headerText="Unsubscribed"
        paragraphText={`You successfully unsubscribed from TerraEye. You can access your exploration areas until ${new Date(
          expiryDate || 0,
        ).toLocaleDateString()}.`}
        isOpen={unsubscribeConfirmModalVisible}
        closeModal={closeConfirmModal}
      >
        <Button variant="PRIMARY" squared minWidth={100} onClick={closeConfirmModal}>
          ok
        </Button>
      </SummaryModal>
    </>
  );
};

const ModalButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
