import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Select } from 'ui';
import { type ValueAndLabel } from 'common/types';
import { type BandCombosData } from 'domain/composites/types';
import { useCompositesStore } from '../compositesStore';

interface Props {
  isSelected: boolean;
  bandCombosData: BandCombosData[];
}

export const BandCombosSelect = ({ isSelected, bandCombosData }: Props) => {
  const { areaId } = useParams();

  const bandCombosDataOptions = useMemo(
    () =>
      bandCombosData.map(({ id, label }) => ({
        value: id,
        label: label.split(' (')[0],
      })) || [],
    [bandCombosData],
  );

  const [selectedBand, setSelectedBand] = useState<ValueAndLabel | undefined>(bandCombosDataOptions[0]);

  const selectedBandCombos = useCompositesStore.use.selectedBandCombos();
  const onBandComboSelect = useCompositesStore.use.setSelectedBandCombos();

  const setBandCombosDataByOption = useCallback(
    (option: ValueAndLabel): void => {
      const selectedBandCombo = bandCombosData.find(({ id }) => id === option.value);
      selectedBandCombo && onBandComboSelect(selectedBandCombo);
    },
    [bandCombosData, onBandComboSelect],
  );

  const handleBandComboSelect = (option: ValueAndLabel) => {
    setSelectedBand(option);
    setBandCombosDataByOption(option);
  };

  useEffect(() => {
    isSelected && selectedBand && setBandCombosDataByOption(selectedBand);
  }, [isSelected, selectedBand, setBandCombosDataByOption]);

  useEffect(() => {
    !selectedBandCombos && isSelected && setSelectedBand(bandCombosDataOptions[0]);
  }, [areaId, selectedBandCombos, bandCombosDataOptions, isSelected]);

  return (
    <Select
      name={`${selectedBand?.label || 'No'} band combo`}
      options={bandCombosDataOptions}
      initialStateLabel="Select band combo"
      selectedOption={selectedBand}
      onChange={handleBandComboSelect}
    />
  );
};
