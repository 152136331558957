import { type LatLng } from 'leaflet';

type Unit = 'mi' | 'ac' | 'yd' | 'ha' | 'm' | 'km';
type CustomUnit = 'tokens' | Unit;

const defaultPrecision = {
  km: 2,
  ha: 1,
  m: 0,
  mi: 2,
  ac: 2,
  yd: 0,
  ft: 0,
  nm: 2,
};

export const geodesicArea = (latLngs: typeof LatLng): number => {
  const pointsCount = latLngs.length;
  let area = 0.0;
  const d2r = Math.PI / 180;
  let p1: typeof LatLng, p2: typeof LatLng;

  if (pointsCount > 2) {
    for (let i = 0; i < pointsCount; i++) {
      p1 = latLngs[i];
      p2 = latLngs[(i + 1) % pointsCount];
      area += (p2.lng - p1.lng) * d2r * (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
    }
    area = (area * 6378137.0 * 6378137.0) / 2.0;
  }

  return Math.abs(area);
};

export const formatNumber = (n: number, precision: number): string => {
  let formatted = parseFloat(String(n)).toFixed(precision);
  let thousands;
  let decimal;

  if (thousands || decimal) {
    const splitValue = formatted.split('.');
    formatted = thousands ? splitValue[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousands) : splitValue[0];
    decimal = decimal || '.';
    if (splitValue.length > 1) {
      formatted = formatted + decimal + splitValue[1];
    }
  }

  return formatted;
};

export const getReadableArea = (area: number, unit?: CustomUnit, precision?: number) => {
  switch (unit) {
    case 'mi':
      //3097600 square yards in 1 square mile
      return (
        formatNumber(area / 0.836127 / 3097600, precision !== undefined ? precision : defaultPrecision['mi']) + ' mi²'
      );
    case 'ac':
      //4840 square yards in 1 acre
      return (
        formatNumber(area / 0.836127 / 4840, precision !== undefined ? precision : defaultPrecision['ac']) + ' acres'
      );
    case 'yd':
      return formatNumber(area, precision !== undefined ? precision : defaultPrecision['yd']) + ' yd²';
    case 'ha':
      return formatNumber(area * 0.0001, precision !== undefined ? precision : defaultPrecision['ha']) + ' ha';
    case 'm':
      return formatNumber(area, precision !== undefined ? precision : defaultPrecision['m']) + ' m²';
    default:
      return formatNumber(area * 0.000001, precision !== undefined ? precision : defaultPrecision['km']) + ' km²';
  }
};

export const readableDistance = (
  distance: number,
  isMetric: boolean,
  isFeet: boolean,
  isNauticalMile: boolean,
  precision: typeof defaultPrecision,
) => {
  let distanceStr, units;

  if (isMetric) {
    units = typeof isMetric == 'string' ? isMetric : 'metric';
  } else if (isFeet) {
    units = 'feet';
  } else if (isNauticalMile) {
    units = 'nauticalMile';
  } else {
    units = 'yards';
  }

  switch (units) {
    case 'metric':
      // show metres when distance is < 1km, then show km
      if (distance > 1000) {
        distanceStr = formatNumber(distance / 1000, precision['km']) + ' km';
      } else {
        distanceStr = formatNumber(distance, precision['m']) + ' m';
      }
      break;
    case 'feet':
      distance *= 1.09361 * 3;
      distanceStr = formatNumber(distance, precision['ft']) + ' ft';

      break;
    case 'nauticalMile':
      distance *= 0.53996;
      distanceStr = formatNumber(distance / 1000, precision['nm']) + ' nm';
      break;
    case 'yards':
    default:
      distance *= 1.09361;

      if (distance > 1760) {
        distanceStr = formatNumber(distance / 1760, precision['mi']) + ' miles';
      } else {
        distanceStr = formatNumber(distance, precision['yd']) + ' yd';
      }
      break;
  }
  return distanceStr;
};
