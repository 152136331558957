import { type ReactNode, useState, useRef } from 'react';
import styled from 'styled-components';

import { IconButton } from 'ui/atoms/iconButton';
import { ReactComponent as InfoIcon } from 'assets/svg/infoIcon.svg';
import { useOnClickOutside } from 'common/hooks';

export type InfoPointVariant = 'DEFAULT' | 'SMALL';

export interface InfoPointProps {
  variant?: InfoPointVariant;
  children?: ReactNode;
  icon?: ReactNode;
  iconSize?: number;
}

export const InfoPoint = ({ variant, children, icon, iconSize }: InfoPointProps) => {
  const [isInfoAreaOpen, setIsInfoAreaOpen] = useState(false);
  const infoPointRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(infoPointRef, () => setIsInfoAreaOpen(false));

  return (
    <StyledInfoPoint ref={infoPointRef}>
      <IconButton
        active={isInfoAreaOpen}
        icon={icon || <InfoIcon />}
        size={variant === 'SMALL' ? 20 : 30}
        $iconSize={iconSize || variant === 'SMALL' ? 3 : 4}
        onClick={() => setIsInfoAreaOpen((prev) => !prev)}
      />
      {isInfoAreaOpen && (
        <StyledInfoArea $small={variant === 'SMALL'}>{children || 'no data available'}</StyledInfoArea>
      )}
    </StyledInfoPoint>
  );
};

const StyledInfoPoint = styled.div`
  position: relative;
`;

const StyledInfoArea = styled.div<{ $small?: boolean }>`
  position: absolute;
  top: ${({ $small }) => ($small ? 24 : 34)}px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 280px;
  min-width: 125px;
  background-color: ${({ theme }) => theme.color.selectGray};
  color: ${({ theme }) => theme.color.white};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px;
  border-radius: 8px;
  z-index: 100;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  cursor: default;
`;

InfoPoint.displayName = 'InfoPoint';
InfoPoint.defaultProps = {
  variant: 'DEFAULT' as InfoPointVariant,
};
