import { type ComponentProps, type CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { type Palette } from 'ui/types';

export type InputVariant = 'PRIMARY' | 'SECONDARY' | 'BASIC' | 'SEARCH' | 'CHAT';

type InputStyle = Required<
  Pick<CSSProperties, 'color' | 'backgroundColor' | 'fontSize' | 'fontWeight' | 'height' | 'borderRadius'> & {
    disabledColor?: CSSProperties['color'];
    disabledBackgroundColor?: CSSProperties['backgroundColor'];
    placeholderColor?: CSSProperties['color'];
    errorColor?: CSSProperties['color' | 'borderBottomColor'];
    paddingLeft?: CSSProperties['paddingLeft'];
    paddingRight?: CSSProperties['paddingRight'];
    underlineColor?: CSSProperties['borderBottomColor'];
  }
>;

const styles: Palette<InputVariant, InputStyle> = {
  PRIMARY: {
    color: 'white',
    backgroundColor: 'transparent',
    disabledColor: 'suvaGray',
    disabledBackgroundColor: 'transparent',
    placeholderColor: 'white',
    errorColor: 'redLoginError',
    fontSize: 16,
    fontWeight: 400,
    height: 30,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    underlineColor: 'silverDark',
  },
  SECONDARY: {
    color: 'white',
    backgroundColor: 'transparent',
    disabledColor: 'suvaGray',
    disabledBackgroundColor: 'transparent',
    placeholderColor: 'white',
    errorColor: 'redLoginError',
    fontSize: 14,
    fontWeight: 500,
    height: 30,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    underlineColor: 'white',
  },
  BASIC: {
    color: 'white',
    backgroundColor: 'mineShaftABitLighter',
    disabledColor: 'silver',
    disabledBackgroundColor: 'suvaGray',
    placeholderColor: 'suvaGray',
    errorColor: 'redLoginError',
    fontSize: 14,
    fontWeight: 600,
    height: 30,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 15,
    underlineColor: 'none',
  },
  SEARCH: {
    color: 'white',
    backgroundColor: 'duneDarker',
    disabledColor: 'white',
    disabledBackgroundColor: 'suvaGray',
    placeholderColor: 'white',
    errorColor: 'redLoginError',
    fontSize: 14,
    fontWeight: 600,
    height: 30,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 15,
    underlineColor: 'none',
  },
  CHAT: {
    color: 'white',
    backgroundColor: 'selectGray',
    disabledColor: 'white',
    disabledBackgroundColor: 'suvaGray',
    placeholderColor: 'cloud',
    errorColor: 'redLoginError',
    fontSize: 14,
    fontWeight: 600,
    height: 30,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 15,
    underlineColor: 'none',
  },
};

export interface InputProps extends ComponentProps<'input'> {
  $variant?: InputVariant;
  $paddingLeft?: number;
  $paddingRight?: number;
  $borderRadius?: number;
  $error?: boolean;
}

export const Input = styled.input<InputProps>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ $variant }) => styles[$variant!].height}px;
  line-height: ${({ $variant }) => styles[$variant!].height}px;
  font-weight: ${({ $variant }) => styles[$variant!].fontWeight};
  font-size: ${({ $variant }) => styles[$variant!].fontSize}px;
  background-color: ${({ $variant, theme }) => theme.color[styles[$variant!].backgroundColor]};
  color: ${({ $variant, theme }) => theme.color[styles[$variant!].color]};
  padding-left: ${({ $paddingLeft, $variant }) =>
    $paddingLeft !== undefined ? `${$paddingLeft}px` : `${styles[$variant!].paddingLeft}px`};
  padding-right: ${({ $paddingRight, $variant }) =>
    $paddingRight !== undefined ? `${$paddingRight}px` : `${styles[$variant!].paddingRight}px`};
  outline: none;
  border: none;
  border-bottom: ${({ $variant, theme }) => `1px solid ${theme.color[styles[$variant!].underlineColor]}`};
  border-radius: ${({ $borderRadius, $variant }) =>
    $borderRadius !== undefined ? `${$borderRadius}px` : `${styles[$variant!].borderRadius}px`};

  ::placeholder {
    color: ${({ $variant, theme }) => theme.color[styles[$variant!].placeholderColor]};
  }

  :disabled {
    color: ${({ $variant, theme }) => theme.color[styles[$variant!].disabledColor]};
    border-bottom-color: ${({ $variant, theme }) => theme.color[styles[$variant!].disabledColor]};
    background-color: ${({ $variant, theme }) => theme.color[styles[$variant!].disabledBackgroundColor]};
    cursor: not-allowed;

    ::placeholder {
      color: ${({ $variant, theme }) => theme.color[styles[$variant!].disabledColor]};
    }
  }

  ${({ $error, $variant }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.color[styles[$variant!].errorColor]};
      border-bottom-color: ${({ theme }) => theme.color[styles[$variant!].errorColor]};

      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus {
        font-size: ${({ $variant }) => styles[$variant!].fontSize}px;
        -webkit-text-fill-color: ${({ $variant, theme }) => theme.color[styles[$variant!].errorColor]};
      }

      ::placeholder {
        color: ${({ $variant, theme }) => theme.color[styles[$variant!].errorColor]};
      }
    `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

Input.defaultProps = {
  $variant: 'PRIMARY' as InputVariant,
  type: 'text',
  height: 30,
  disabled: false,
  $error: false,
};

Input.displayName = 'InputElement';
