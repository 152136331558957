import styled, { css } from 'styled-components';

import { FormLayout, FormButtons } from 'ui/form';
import { Button } from 'ui/atoms/button';
import { Input as BaseInput } from 'ui/atoms/input';
import { useHandleChangePasswordUseCase } from 'views/user/handleChangePasswordUseCase';

export const ChangePassword = () => {
  const {
    passwords: { firstPassword, secondPassword },
    showPasswordsMatchError,
    passwordsMatch,
    status,
    errorInfo,
    isTokenError,
    onSubmit,
    onInputChange,
    onUserRedirect,
  } = useHandleChangePasswordUseCase();

  return (
    <FormLayout header="Set a New Password">
      {status === 'success' && (
        <Container>
          <div>
            <Message variant="info">You have successfully changed your password.</Message>
            <Message variant="info">Now you can log back into your account.</Message>
          </div>
          <Button onClick={onUserRedirect} squared>
            log in
          </Button>
        </Container>
      )}
      {status !== 'success' && !isTokenError && (
        <Form onSubmit={onSubmit}>
          <InputsWrapper>
            <Input
              name="firstPassword"
              $variant="PRIMARY"
              placeholder="Enter new password"
              type="password"
              value={firstPassword}
              onChange={onInputChange}
              required
            />
            <Input
              name="secondPassword"
              $variant="PRIMARY"
              placeholder="Re-enter password"
              type="password"
              value={secondPassword}
              onChange={onInputChange}
              required
            />
          </InputsWrapper>

          {showPasswordsMatchError && !passwordsMatch && <Message variant="error">Passwords do not match</Message>}

          {errorInfo &&
            errorInfo.map((error) => (
              <Message key={error} variant="error">
                {error}
              </Message>
            ))}
          <FormButtons isLoading={status === 'loading'} handleCancel={onUserRedirect} />
        </Form>
      )}
      {status === 'error' && isTokenError && (
        <Container>
          <Message variant="error">The link has expired. Send request again.</Message>
          <Button onClick={onUserRedirect} squared>
            back
          </Button>
        </Container>
      )}
    </FormLayout>
  );
};

const Form = styled.form`
  margin: 40px 0 0;
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.color.white};
`;

const Input = styled(BaseInput)`
  height: 30px;
  font-size: 16px;
  max-width: 240px;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Message = styled.p<{ variant: 'info' | 'error' }>`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme, variant }) => (variant === 'info' ? theme.color.white : theme.color.errorPink)};

  ${({ variant }) =>
    variant === 'error' &&
    css`
      margin-top: 22px;
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 38px;
  margin-top: 14px;
`;
