import { useGetAoiCogs } from 'infrastructure/areas/useGetAoiCogs';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { registryRepository } from 'infrastructure/registry/registryRepository';
import { getCompositesDescription } from './layerManager/getCompositesDescription';

export const useCompositesBeta = () => {
  const { composites } = explorationRepository.useFetchComposites();
  const { providers } = registryRepository.useFetchProductProviders();
  const { aoiCogs } = useGetAoiCogs();

  // TODO: resign from filtering by isRGB field when no more 3-bands cogs available in database
  const providersWithOptions = providers
    .filter((provider) => composites.some(({ provider_name }) => provider_name === provider.name))
    .filter((provider) =>
      aoiCogs.filter(({ isRGB }) => !isRGB).some(({ provider_name }) => provider_name === provider.name),
    )
    .map(({ id, name, label }) => {
      const providerComposites = composites.filter(({ provider_name }) => provider_name === name);

      const options = providerComposites.map(({ label, name }) => ({
        value: name,
        label,
      }));

      const descriptionOptions = providerComposites.map(({ id, description }) => ({
        id,
        description,
      }));

      return {
        id,
        name,
        label,
        options,
        description: getCompositesDescription(label, descriptionOptions),
      };
    });

  return { providersWithOptions };
};
