import L from 'leaflet';
import { type ReactNode, memo } from 'react';
import { Marker } from 'react-leaflet';

import { theme } from 'common/theme';
import CircleRed from 'assets/img/ellipse-red.png';
import CirclePink from 'assets/img/ellipse-pink.png';
import { convertComponentToUrl, styleSvgIcon } from 'common/utils';
import { type NotificationType } from 'domain/monitoring/types';
import { AreaInfoEnvironmentsIcons } from 'domain/areas/AreaInfoEnvironmentsIcons';

interface NoteProps {
  item: NotificationType;
  onInit?: (marker: unknown) => void;
  active?: boolean;
  onClick?: (note: NotificationType) => void;
  children?: ReactNode;
}

export const MapNoteMarker = memo(({ item, onInit, onClick, active, children }: NoteProps) => {
  const url = convertComponentToUrl(
    styleSvgIcon(AreaInfoEnvironmentsIcons[item.properties.view].icon, {
      color: active ? theme.color.red : theme.color.white,
    }),
    { type: 'image/svg+xml' },
  );

  const itemMouseEventsHandles = {
    click: () => onClick && onClick(item),
  };

  const icon = L.icon({
    iconUrl: url,
    iconSize: [10, 10],
    shadowUrl: active ? CirclePink : CircleRed,
    shadowSize: [16, 16],
    shadowAnchor: [8, 8],
  });

  return (
    <Marker position={item.geometry.coordinates} icon={icon} ref={onInit} eventHandlers={itemMouseEventsHandles}>
      {children}
    </Marker>
  );
});
