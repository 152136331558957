export class AbortControllerWithReload {
  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  reload() {
    this.controller = new AbortController();
  }
}
