import { useQueries } from '@tanstack/react-query';

import { greeneryApi } from 'infrastructure/greenery/greeneryApiAdapter';
import { GREENERY_INDICATOR_STATS_QUERY_KEY } from 'infrastructure/greenery/constants';
import { vegetationDataService } from 'views/greenery/vegetation/vegetationDataService';
import { type GreeneryIndicatorStats } from 'domain/greenery/types';

export const useGetGreeneryIndicatorStatsUseCase = (timestamp: string | null) => {
  const { greeneryResults, greeneryResultsLoading } = vegetationDataService.useFetchVegetationResults(timestamp);

  const resultIds = greeneryResults.map((object) => object.id);

  const stats = useQueries({
    queries: resultIds.map((id) => ({
      queryKey: [GREENERY_INDICATOR_STATS_QUERY_KEY, id],
      queryFn: () => greeneryApi.getVegetationIndicatorStats(id),
    })),
  });

  const indicatorStats = stats.map((item) => item.data?.data).filter((item) => item) as GreeneryIndicatorStats[];
  const indicatorStatsLoading = stats.some((result) => result.isLoading) || greeneryResultsLoading;

  return { indicatorStats, indicatorStatsLoading };
};
