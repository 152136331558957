import { ColorScale } from 'ui/colorScale';
import { ShareModalControlButton } from 'components/downloadModal/legacyShareModal';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { WaterQualityTimeline } from './WaterQualityTimeline';
import { useWaterIndicators } from './indicators/useWaterIndicators';
import { useWaterQualityLifeCycle } from './useWaterQualityLifeCycle';
import { WaterQualityInfoArea } from './WaterQualityInfoArea';
import { WaterQualityLayerManager } from './WaterQualityLayerManager';
import { useGetWaterQualityRemappedResults } from './useGetWaterQualityRemappedResults';
import { WaterIndicatorsSelect } from './indicators';
import { WaterbodiesResetButton, WaterbodiesSelect } from '../waterbodies';
import { DataProvidersSelect } from '../dataProviders';

export const WaterQuality = () => {
  const { selectedIndicator } = useWaterIndicators();
  const areReportsActive = useGetAreReportsActiveUseCase();
  const { waterQualityRemappedResults, waterQualityRemappedResultsLoading } = useGetWaterQualityRemappedResults();

  useWaterQualityLifeCycle();

  return (
    <AreaDetailsLayout
      returnButton={<WaterbodiesResetButton />}
      legend={
        selectedIndicator && (
          <ColorScale
            colormap={selectedIndicator.colormap}
            labels={selectedIndicator.labels}
            unit={selectedIndicator.unit}
            segmented={true}
          />
        )
      }
      toolbarContent={
        <>
          <WaterQualityInfoArea />
          {!areReportsActive && (
            <>
              <WaterbodiesSelect />
              <DataProvidersSelect
                results={waterQualityRemappedResults}
                areResultsLoading={waterQualityRemappedResultsLoading}
              />
              <WaterIndicatorsSelect />
            </>
          )}
        </>
      }
      toolboxContent={
        <>
          {!areReportsActive && <LayerManagerControlButton />}
          {!areReportsActive && <ShareModalControlButton />}
        </>
      }
    >
      {!areReportsActive && <WaterQualityLayerManager selectedIndicator={selectedIndicator} />}
      <WaterQualityTimeline
        indicatorsLabel={selectedIndicator?.indicator_name}
        indicatorsUnit={selectedIndicator?.unit}
      />
    </AreaDetailsLayout>
  );
};
