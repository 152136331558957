import { DataLayer } from 'components/map';
import { useGetCogWithBounds } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { buildCubehelixDiscreteValuesColormapHelper } from 'domain/exploration/helpers';

interface SelectedClustersMapLayerProps {
  clusters: number[];
  url: string;
  amount: number;
  opacity: number;
  isVisible: boolean;
  zIndex: number;
}

export const SelectedClustersMapLayer = ({
  clusters,
  url,
  amount,
  opacity,
  isVisible,
  zIndex,
}: SelectedClustersMapLayerProps) => {
  const colormap = buildCubehelixDiscreteValuesColormapHelper(clusters, amount - 1);

  const { cogUrl, cogBounds } = useGetCogWithBounds(titilerApiAdapter.buildNamedColorScaleIndicesCogUrl, url, {
    expression: `((b1>=0)&(b1<=${amount - 1}))*b1`,
    colormap,
    noDataValue: '-1',
  });

  if (!cogUrl || !cogBounds) {
    return null;
  }

  return (
    <DataLayer
      isVisible={isVisible}
      layerName="single-clusters-layer"
      zIndex={zIndex}
      cogUrl={cogUrl}
      bounds={cogBounds}
      opacity={opacity}
    />
  );
};
