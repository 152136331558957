import { type AxiosResponse } from 'axios';

import { getApiData } from 'services/rest/restApiService';
import { REGISTRY_API_URLS } from './reqistryApiUrls';

export const reqistryAPI = {
  getProductProviders: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<AxiosResponse<any, any> | undefined> =>
    getApiData(REGISTRY_API_URLS.getProductProviders, { limit, offset }),
};
