import styled from 'styled-components';
import { ReactComponent as GraphIcon } from 'assets/svg/graphIcon.svg';
import { MapControlButton } from 'ui/map/mapControlButton';

interface OpenGraphButtonProps {
  isGraphOpen: boolean;
  isTimelineVisible: boolean;
  onClick: () => void;
}

export function OpenGraphButton({ isGraphOpen, isTimelineVisible, onClick }: OpenGraphButtonProps) {
  return (
    <OpenGraphButtonBox isGraphOpen={isGraphOpen} isTimelineVisible={isTimelineVisible}>
      <MapControlButton onClick={onClick} icon={<GraphIcon />} active={isGraphOpen} />
    </OpenGraphButtonBox>
  );
}

const OpenGraphButtonBox = styled.div<{ isGraphOpen: boolean; isTimelineVisible: boolean }>`
  position: absolute;
  top: ${({ isGraphOpen, isTimelineVisible }) => (isGraphOpen || !isTimelineVisible ? '-80px' : '-120px')};
  transition: top ${({ theme }) => theme.transitionFunction.main};
  right: 0;
  padding: ${({ theme }) => theme.size.toolboxPadding};
`;
