/* eslint-disable @typescript-eslint/no-explicit-any */
import { type AxiosResponse } from 'axios';
import { type TemperatureResultsApiResponse } from 'domain/temperature/types';

export const areTemperatureResultsValid = (data: any): data is AxiosResponse<TemperatureResultsApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.aoi_name === 'string' &&
        typeof result.img_date === 'string' &&
        typeof result.resolution === 'number' &&
        typeof result.url === 'string',
    )
  );
};
