import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { SegmentationSingleDateReportsExternalData } from 'views/reports/external/data/SegmentationSingleDateReportsExternalData';
import { SegmentationDoubleDateReportsExternalData } from 'views/reports/external/data/SegmentationDoubleDateReportsExternalData';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';
import { withSecondaryColor } from 'domain/greenery/decorators';

interface SegmentationReportsExternalDataProps {
  areaId: number;
  reportInfo: ExternalReportHeaderCommon;
}

export const SegmentationReportsExternalData = ({ areaId, reportInfo }: SegmentationReportsExternalDataProps) => {
  const { validTimestamp } = useGreeneryTimestamps();

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();
  const mappedGreeneryObjects = withSecondaryColor(greeneryObjects);

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const firstDateReportsSegmentationDataset = useReportsStore.use.firstDateReportsSegmentationDataset();
  const secondDateReportsSegmentationDataset = useReportsStore.use.secondDateReportsSegmentationDataset();

  return isCompareDatesType
    ? firstDateReportsSegmentationDataset && secondDateReportsSegmentationDataset && (
        <SegmentationDoubleDateReportsExternalData
          reportInfo={reportInfo}
          firstDataset={firstDateReportsSegmentationDataset}
          secondDataset={secondDateReportsSegmentationDataset}
          greeneryObjects={mappedGreeneryObjects}
          areaId={areaId}
        />
      )
    : validTimestamp && (
        <SegmentationSingleDateReportsExternalData
          reportInfo={reportInfo}
          areaId={areaId}
          timestamp={validTimestamp}
          greeneryObjects={mappedGreeneryObjects}
        />
      );
};
