/* eslint-disable @typescript-eslint/no-explicit-any */
import { isMoneyValid } from 'common/typeguards';
import {
  type TrialDurationResponseDTO,
  type SubscriptionProductDTO,
  type SubscriptionStatusResponseDTO,
} from 'domain/subscription/types';

export const isAvailableQuotaValid = (availableQuota: any): availableQuota is number => {
  return typeof availableQuota === 'number';
};

export const isCurrentSubscriptionValid = (currentSubscription: any): currentSubscription is SubscriptionProductDTO => {
  return (
    typeof currentSubscription?.id === 'number' &&
    typeof currentSubscription?.name === 'string' &&
    typeof currentSubscription?.area_quota === 'number' &&
    currentSubscription?.price &&
    isMoneyValid(currentSubscription.price)
  );
};

export const isSubscriptionProductValid = (product: any): product is SubscriptionProductDTO => {
  return (
    typeof product?.label === 'string' &&
    typeof product?.area_quota === 'number' &&
    typeof product?.info === 'string' &&
    product?.price &&
    isMoneyValid(product.price)
  );
};

export const isSubscriptionProductsListValid = (products: any): products is SubscriptionProductDTO[] => {
  return products.every((product: any) => isSubscriptionProductValid(product));
};

export const isTrialDurationValid = (response: any): response is TrialDurationResponseDTO => {
  return response && typeof response.trial_in_days === 'number';
};

export const isActiveSubscriptionDataValid = (response: any): response is SubscriptionStatusResponseDTO => {
  return (
    typeof response?.name === 'string' &&
    typeof response?.status === 'string' &&
    (typeof response?.expires_at === 'string' || response?.expires_at === null) &&
    typeof response?.price === 'number' &&
    typeof response?.currency === 'string' &&
    typeof response?.capacity === 'number' &&
    typeof response?.canceled === 'boolean'
  );
};
