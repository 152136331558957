import { Container, Slider } from 'ui';
import { useLineaments } from '../useLineaments';

export const LineamentsLengthRangeSlider = () => {
  const { selectedOption, ranges, changeOption } = useLineaments();

  const onTresholdsChange = (value: number | number[]) => {
    changeOption(Array.isArray(value) && value.length >= 2 ? [value[0], value[1]] : null);
  };

  const marks = Object.assign({}, ...ranges.map(String).map((i) => ({ [i]: i })));
  return (
    <Container wMax gap={10} pr={10} mt={10}>
      length range
      <Slider
        ariaLabel="Lineaments length range slider"
        marks={marks}
        value={selectedOption || ranges}
        variant="RANGE"
        onChange={onTresholdsChange}
        defaultValue={ranges}
        min={ranges[0]}
        max={ranges[ranges.length - 1]}
        step={null}
      />
    </Container>
  );
};
