/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  type PointInfo,
  type SingleBandPointInfo,
  type TitilerInfo,
  type TitilerStats,
  type TitilerBandStats,
} from './types';

export const areCogBandStatsValid = (stats: any): stats is TitilerBandStats => {
  return (
    stats &&
    typeof stats.min === 'number' &&
    typeof stats.max === 'number' &&
    typeof stats.mean === 'number' &&
    typeof stats.count === 'number' &&
    typeof stats.sum === 'number' &&
    typeof stats.std === 'number' &&
    typeof stats.median === 'number' &&
    typeof stats.majority === 'number' &&
    typeof stats.minority === 'number' &&
    typeof stats.unique === 'number' &&
    typeof stats.valid_percent === 'number' &&
    typeof stats.masked_pixels === 'number' &&
    typeof stats.valid_pixels === 'number' &&
    typeof stats.percentile_98 === 'number' &&
    typeof stats.percentile_2 === 'number' &&
    Array.isArray(stats.histogram) &&
    stats.histogram.every((item: any) => Array.isArray(item))
  );
};

export const areCogStatsValid = (stats: any): stats is TitilerStats => {
  return stats && Object.keys(stats).every(String) && Object.values(stats).every(areCogBandStatsValid);
};

export const isCogInfoValid = (info: any): info is TitilerInfo => {
  return (
    info &&
    Array.isArray(info.bounds) &&
    info.bounds.every(Number) &&
    typeof info.minzoom === 'number' &&
    typeof info.maxzoom === 'number' &&
    Array.isArray(info.band_metadata) &&
    info.band_metadata.every(
      (metadata: any) =>
        Array.isArray(metadata) &&
        typeof metadata[0] === 'string' &&
        typeof metadata[1] === 'object' &&
        metadata[1] !== null,
    ) &&
    Array.isArray(info.band_descriptions) &&
    info.band_descriptions.every(
      (description: any) =>
        Array.isArray(description) && typeof description[0] === 'string' && typeof description[1] === 'string',
    ) &&
    typeof info.dtype === 'string' &&
    typeof info.nodata_type === 'string' &&
    Array.isArray(info.colorinterp) &&
    info.colorinterp.every(String) &&
    typeof info.width === 'number' &&
    typeof info.driver === 'string' &&
    typeof info.count === 'number' &&
    typeof info.height === 'number' &&
    Array.isArray(info.overviews) &&
    info.overviews.every(Number)
  );
};

export const isPointInfoValid = (info: any): info is SingleBandPointInfo => {
  return (
    info.coordinates &&
    Array.isArray(info.coordinates) &&
    info.coordinates.length === 2 &&
    info.coordinates.every(Number) &&
    info.band_names &&
    Array.isArray(info.band_names) &&
    info.band_names.length === 1 &&
    typeof info.band_names[0] === 'string' &&
    info.values &&
    Array.isArray(info.values) &&
    info.values.length === 1 &&
    typeof info.values[0] === 'number'
  );
};

export const isPointInfoArrayValid = <T>(data: PointInfo<T, any>[]): data is PointInfo<T, SingleBandPointInfo>[] => {
  return data && Array.isArray(data) && data.every((item) => isPointInfoValid(item.info));
};
