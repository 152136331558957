import { type LayerObject } from 'common/types/mapData';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { useGetExtractionPolygonsUseCase } from 'views/mine/useGetExtractionPolygonsUseCase';

export const useWithVisibleLayersOnly = (layers: LayerObject[]): LayerObject[] => {
  const { minePolygons } = useGetExtractionPolygonsUseCase();

  return layers
    .filter(({ status }) => status !== 'hidden')
    .filter((objectOption) =>
      Object.keys(minePolygons).some((polygonPropertyId) => Number(polygonPropertyId) === objectOption.id),
    );
};

export const useWithProperLayerColor = (layers: LayerObject[]): LayerObject[] => {
  const areReportsActive = useGetAreReportsActiveUseCase();

  return layers.map((option) => ({
    ...option,
    color: (areReportsActive && option.secondaryColor) || option.color,
  }));
};
