import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { useMapStore } from 'components/map/mapStore';

export const LoadEndNotifier = ({ zoom }: { zoom: number | undefined }) => {
  const map = useMap();
  const mapReady = useMapStore.use.mapReady();
  const setMapReady = useMapStore.use.setMapReady();

  useEffect(() => {
    map.whenReady(() => {
      if (zoom) return setMapReady();

      map.on('zoomend', () => {
        if (!mapReady) {
          setMapReady();
        }
      });
    });
  }, [map, mapReady, setMapReady, zoom]);

  return null;
};
