import styled, { css } from 'styled-components';

import { type Money } from 'common/types';
import { type SubscriptionType } from 'domain/subscription/types';
import { type SubscriptionColorsConfig } from './types';
import { getColorsConfig } from './colorsConfig';

interface PriceTileProps {
  price: Money;
  previousPrice?: Money;
  name: SubscriptionType;
}

export const PriceTile = ({ price, previousPrice, name }: PriceTileProps) => {
  const colorsConfig = getColorsConfig(name);

  return (
    <TileBody $bgColor={colorsConfig.themeBgColor}>
      <>
        {previousPrice && (
          <PreviousPriceWrapper>
            <PreviousPriceLabel>
              promo
              <br />
              price!
            </PreviousPriceLabel>
            <PriceWrapper>
              <Price oldPrice>
                {price.symbol}
                {price.amount}
              </Price>
              <Currency transparent>{price.currency}</Currency>
            </PriceWrapper>
          </PreviousPriceWrapper>
        )}
        <>
          <PriceWrapper>
            <Price>
              {price.symbol}
              {price.amount}
            </Price>
            <Currency>{price.currency}</Currency>
          </PriceWrapper>
          <Period>monthly</Period>
        </>
      </>
    </TileBody>
  );
};

const TileBody = styled.div<{ $bgColor: SubscriptionColorsConfig['themeBgColor'] }>`
  padding: 12px;
  margin-right: -50px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme, $bgColor }) => theme.color[$bgColor]};
  text-transform: uppercase;
  height: 98px;
  width: 134px;
  display: grid;
  place-items: center;
`;

const PreviousPriceWrapper = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 6px;
`;

const PreviousPriceLabel = styled.div`
  font-family: 'Barlow', sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
`;

const Price = styled.div<{ oldPrice?: boolean }>`
  position: relative;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: ${({ oldPrice }) => (oldPrice ? '24px' : '44px')};
  font-weight: 600;
  line-height: 1;
  color: ${({ theme, oldPrice }) => oldPrice && theme.color.whiteTransparent50};

  ${({ oldPrice }) =>
    oldPrice &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 13px;
        left: 0;
        display: block;
        height: 2px;
        width: 62px;
        background-color: ${({ theme }) => theme.color.white};
        transform: rotate(22deg);
      }
    `}
`;

const Currency = styled.div<{ transparent?: boolean }>`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  color: ${({ theme, transparent }) => transparent && theme.color.whiteTransparent50};
`;

const Period = styled.div`
  display: block;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.14em;
  width: 100%;
`;
