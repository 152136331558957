import { areasRepository } from 'infrastructure/areas/areasRepository';

export const useGetAllAreasTags = (): string[] => {
  const { areasList } = areasRepository.useFetchAreasOfInterestList();

  const tags = areasList
    .map(({ tags }) => tags && Object.keys(tags))
    .flat()
    .filter((tag): tag is string => !!tag);

  const uniqueTags = Array.from(new Set(tags));

  return uniqueTags;
};
