import { type LayerRange } from 'common/types';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayerManager/draggableLayersKeys';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { TileLayer } from 'components/map/layers/TileLayer';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { Loading } from 'ui/molecules/loading';

interface DemLayerProps {
  url: string;
  layerRange: LayerRange;
}

export const DemLayer = ({ url, layerRange: { min, max } }: DemLayerProps) => {
  const isDemVisible = useLayerManagerStore.use.isDemVisible();
  const demOpacity = useLayerManagerStore.use.demOpacity();

  const { cogUrl, extentLatLngBounds, isInfoLoading } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildDEMColormapUrl,
    url,
    { range: [min, max] },
  );

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!cogUrl || !extentLatLngBounds) {
    return null;
  }

  return (
    <WithLayerIndex
      Component={TileLayer}
      props={{
        isVisible: isDemVisible,
        layerName: LAYERS_KEYS.DEM,
        bounds: extentLatLngBounds,
        opacity: demOpacity,
        cogUrl: cogUrl,
      }}
      sectionId={LAYERS_KEYS.DEM}
    />
  );
};
