import { COMMON_QUERY_PARAMETERS, TERRAIN_CLASSES_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { type LayerObject } from 'common/types/mapData';
import { MINE_EXTRACTION_PATH_NAME } from 'domain/extraction/constants';
import { ExtractionActiveTab } from './enums';

export const EXTRACTION_VIEW_CONFIG = {
  name: MINE_EXTRACTION_PATH_NAME,
  path: ROUTES.mine,
  params: COMMON_QUERY_PARAMETERS,
  tabs: {
    [ExtractionActiveTab.TERRAIN]: {
      path: ROUTES.terrain,
      params: [TERRAIN_CLASSES_QUERY_PARAMETER],
    },
  },
};

export const EXTRACTION_TAB_SWITCHER_BUTTONS = [
  {
    id: 1,
    label: 'modified terrain',
    value: ExtractionActiveTab.TERRAIN,
  },
];

export const SEGMENTATION_OBJECTS_OPTIONS: LayerObject[] = [
  {
    id: 1,
    name: 'background',
    label_name: 'background',
    color: '',
    status: 'hidden',
  },
  {
    id: 2,
    name: 'excavator',
    label_name: 'multi-bucket excavator',
    color: 'rgba(12, 240, 50, 0.8)',
    status: 'inactive',
  },
  {
    id: 3,
    name: 'material',
    label_name: 'loose material',
    color: 'rgba(12, 225, 240, 0.8)',
    status: 'inactive',
  },
  {
    id: 4,
    name: 'obscured',
    label_name: 'obscured',
    color: '',
    status: 'hidden',
  },
  {
    id: 5,
    name: 'pre_operation',
    label_name: 'pre-operation',
    color: '#FF90B8',
    secondaryColor: '#FA35C3',
    status: 'active',
  },
  {
    id: 6,
    name: 'overburden',
    label_name: 'overburden removing zone',
    color: 'rgba(207, 104, 60, 0.8)',
    status: 'inactive',
  },
  {
    id: 7,
    name: 'stockpile',
    label_name: 'stockpile',
    color: '#B770FF',
    secondaryColor: '#7500D0',
    status: 'active',
  },
  {
    id: 8,
    name: 'dam',
    label_name: 'dam',
    color: 'rgba(16, 12, 240, 0.8)',
    status: 'inactive',
  },
  {
    id: 9,
    name: 'outcrop',
    label_name: 'deposit outcrop',
    color: '#FFD600',
    secondaryColor: '#094EFF',
    status: 'active',
  },
  {
    id: 10,
    name: 'excavation',
    label_name: 'mining excavation',
    color: '#FF9A23',
    secondaryColor: '#EC6300',
    status: 'active',
  },
  {
    id: 11,
    name: 'plant',
    label_name: 'infrastructure',
    color: '#FF3939',
    secondaryColor: '#EB0505',
    status: 'active',
  },
  {
    id: 12,
    name: 'tsf',
    label_name: 'tailing storage facility',
    color: '#5AE2A9',
    secondaryColor: '#00868E',
    status: 'active',
  },
  {
    id: 13,
    name: 'settling',
    label_name: 'settling pond',
    color: '#48BDFF',
    secondaryColor: '#0094FF',
    status: 'active',
  },
  {
    id: 14,
    name: 'prod_slope',
    label_name: 'production slope',
    color: 'rgba(128, 16, 176, 0.8)',
    status: 'inactive',
  },
  {
    id: 15,
    name: 'haul_slope',
    label_name: 'haulage slope',
    color: 'rgba(90, 226, 169, 0.8)',
    status: 'inactive',
  },
  {
    id: 16,
    name: 'stacker',
    label_name: 'stacker',
    color: 'rgba(250, 32, 203, 0.8)',
    status: 'inactive',
  },
  {
    id: 17,
    name: 'dump',
    label_name: 'dumping ground',
    color: '#FFFFFF',
    secondaryColor: '#000000',
    status: 'active',
  },
  {
    id: 18,
    name: 'inner_dump',
    label_name: 'inner dumping ground',
    color: 'rgba(219, 194, 169, 0.39)',
    status: 'inactive',
  },
  {
    id: 19,
    name: 'outer_dump',
    label_name: 'outer dumping ground',
    color: 'rgba(214, 137, 60, 0.8)',
    status: 'inactive',
  },
  {
    id: 20,
    name: 'mining',
    label_name: 'total transformed area',
    color: '#10C200',
    secondaryColor: '#03A100',
    status: 'active',
  },
];

export const MINE_ACTIVITY_INDEX_LABEL = 'mining activity index';
export const MINE_ACTIVITY_INDEX_COLORSCALE_LABELS = ['low', 'high'];
