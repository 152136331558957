import styled from 'styled-components';

import { ROUTES } from 'common/navigation/routes';
import { useNavigateToAreaWithQuery } from 'common/navigation/hooks';
import { DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { ReactComponent as TargetIcon } from 'assets/svg/target.svg';
import { Container, Button } from 'ui';
import { Popover } from 'ui/atoms/popover/Popover';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';

export const AreaCardTargets = ({ areaId }: { areaId: number }) => {
  const { prospectingTargets } = explorationRepository.useProspectingTargets();
  const targets = prospectingTargets[areaId] || [];

  const navigateToAreaWithQuery = useNavigateToAreaWithQuery();
  const handleClick = (target: string) => {
    navigateToAreaWithQuery(ROUTES.prospecting, String(areaId), [
      { key: DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER, value: target },
    ]);
  };

  return (
    <StyledAreaCardTargets>
      {targets.slice(0, 3).map((target) => (
        <TargetButton key={target} label={target} handleClick={() => handleClick(target)} />
      ))}
      {targets.length >= 4 && (
        <Popover
          zIndex={100}
          button={
            <Button variant="SECONDARY_SUPER_SMALL" minWidth={48}>
              more
            </Button>
          }
        >
          <TargetButtonsWrapper>
            {targets.slice(3).map((target) => (
              <TargetButton key={target} label={target} handleClick={() => handleClick(target)} />
            ))}
          </TargetButtonsWrapper>
        </Popover>
      )}
    </StyledAreaCardTargets>
  );
};

const TargetButton = ({ label, handleClick }: { label: string; handleClick: () => void }) => (
  <Button variant="PRIMARY_SUPER_SMALL" onClick={handleClick} minWidth={48}>
    <Container gap={5}>
      <StyledTargetIcon /> {label}
    </Container>
  </Button>
);

const StyledAreaCardTargets = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 10px;
`;

const TargetButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
  gap: 6px;
  max-width: 230px;
`;

const StyledTargetIcon = styled(TargetIcon)`
  width: 12px;
  height: 12px;
`;
