import { useQuery } from '@tanstack/react-query';

import { isSuccessfulQueryUrl } from 'common/typeguards';
import { type LayerObject } from 'common/types/mapData';
import { GREENERY_QUARTERLY_RESULTS_COG_QUERY_KEY } from 'infrastructure/greenery/constants';
import { greeneryApi } from 'infrastructure/greenery/greeneryApiAdapter';
import { GreeneryLayerManagerLegend } from 'views/greenery/components/GreeneryLayerManagerLegend';

interface QuarterlyLayerManagerLegendWrapperProps {
  areaId: number;
  layerObjects: LayerObject[];
  viewSpecificDisabledLayers?: string[];
  noBadges?: boolean;
  chosenResultId: number;
}

export const QuarterlyLayerManagerLegendWrapper = (props: QuarterlyLayerManagerLegendWrapperProps) => {
  const { data } = useQuery([GREENERY_QUARTERLY_RESULTS_COG_QUERY_KEY, { resultId: props.chosenResultId }], () =>
    greeneryApi.getQuarterlySegmentationCog(props.chosenResultId),
  );

  const cogUrl = isSuccessfulQueryUrl(data) ? data?.data?.url : undefined;

  return <>{cogUrl && <GreeneryLayerManagerLegend {...props} cogUrl={cogUrl} />}</>;
};
