import { View } from '@react-pdf/renderer';

import { tableStyles } from 'views/reports/external/pdfGenerator/styles';

interface PdfTableColorColumnProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  dataKey: string;
}

export const PdfTableColorColumn = ({ data, dataKey }: PdfTableColorColumnProps) => (
  <View style={tableStyles.table__column}>
    <View
      style={{ ...tableStyles.table__color__square, ...tableStyles.table__value, backgroundColor: 'transparent' }}
    />
    {data.map((item, index) => (
      <View
        key={index}
        style={{
          ...tableStyles.table__color__square,
          ...tableStyles.table__value,
          backgroundColor: item[dataKey],
        }}
      />
    ))}
  </View>
);
