import { type ValueAndLabel } from 'common/types';
import { useReplaceQueryParameters } from 'common/navigation/hooks';
import { GREENERY_OBJECT_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';

export const useHandleGreneryObjectsSelectUseCase = () => {
  const clearComparatorUrl = useGreeneryStore.use.clearComparatorUrl();

  const replaceQueryParameter = useReplaceQueryParameters();

  const selectGreeneryObject = (option: ValueAndLabel) => {
    if (option) {
      replaceQueryParameter([
        {
          key: GREENERY_OBJECT_QUERY_PARAMETER,
          value: String(option.value),
        },
      ]);

      clearComparatorUrl();
    }
  };

  return { selectGreeneryObject };
};
