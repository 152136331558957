import styled from 'styled-components';

export const CreatorColumnWrapper = styled.div<{ width?: number }>`
  flex-shrink: 0;
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-width: 180px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  border-right: 1px solid ${({ theme }) => theme.color.blackTransparent20};
  word-break: break-word;
`;
