import { useEffect } from 'react';

import { CreatorSimpleDataColumn } from 'ui/creator/creatorSimpleDataColumn';
import { type CreatorDataColumnData } from 'ui/creator/types';
import { useReportsStore } from 'infrastructure/reports/reportsStore';

interface SingleDateReportsCreatorContentProps {
  data: CreatorDataColumnData[];
  isLoading?: boolean;
}

export const SingleDateReportsCreatorContent = ({ data, isLoading }: SingleDateReportsCreatorContentProps) => {
  const setIsGenerateReportButtonEnabled = useReportsStore.use.setIsGenerateReportButtonEnabled();

  useEffect(() => {
    const isEnabled = data.some(({ checked }) => checked) && !isLoading;
    setIsGenerateReportButtonEnabled(isEnabled);

    return () => setIsGenerateReportButtonEnabled(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <CreatorSimpleDataColumn data={data} isLoading={isLoading} />;
};
