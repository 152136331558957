import { type LayerObject } from 'common/types/mapData';
import { GREENERY_OBJECTS_REPORT_COLORMAP } from 'domain/greenery/colors';

export const withSecondaryColor = (greeneryObjects: LayerObject[]): LayerObject[] => {
  const mappedGreeneryObjects = greeneryObjects.map((object) => {
    const color = GREENERY_OBJECTS_REPORT_COLORMAP.find((objectColor) => objectColor.id === object.id)?.color;

    return color ? { ...object, color } : object;
  });

  return mappedGreeneryObjects;
};
