import { MapValueClick } from 'components/map/MapValueClick';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { ClustersMapLayer } from './ClustersMapLayer';
import { SelectedClustersMapLayer } from './SelectedClustersMapLayer';
import { useClusters } from './useClusters';
import { useClustersLayerManagerStore } from './clustersLayerManagerStore';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

export const ClustersMapContent = () => {
  const { selectedClustersSupplier, currentClustersResult } = useClusters();

  const isClustersVisible = useClustersLayerManagerStore.use.isClustersVisible();
  const isClustersSelectionMode = useClustersLayerManagerStore.use.isClustersSelectionMode();
  const selectedClustersSaved = useClustersLayerManagerStore.use.selectedClustersSaved();
  const selectedClustersInProgress = useClustersLayerManagerStore.use.selectedClustersInProgress();
  const toggleSelectedCluster = useClustersLayerManagerStore.use.toggleSelectedCluster();

  if (!selectedClustersSupplier || !currentClustersResult) {
    return null;
  }

  const selectedClusters = isClustersSelectionMode ? selectedClustersInProgress : selectedClustersSaved;

  return (
    <>
      {(!selectedClustersSaved.length || isClustersSelectionMode) && (
        <WithLayerIndex
          Component={ClustersMapLayer}
          props={{
            url: currentClustersResult.url,
            amount: currentClustersResult.amount,
            opacity: isClustersSelectionMode ? selectedClustersSupplier.opacity / 4 : selectedClustersSupplier.opacity,
            isVisible: isClustersVisible,
          }}
          sectionId={EXPLORATION_LAYERS_KEYS.CLUSTERS}
        />
      )}
      {!!selectedClusters.length && (
        <WithLayerIndex
          Component={SelectedClustersMapLayer}
          props={{
            clusters: selectedClusters,
            url: currentClustersResult.url,
            amount: currentClustersResult.amount,
            opacity: selectedClustersSupplier.opacity,
            isVisible: isClustersVisible,
          }}
          sectionId={EXPLORATION_LAYERS_KEYS.CLUSTERS}
          layerId={1}
        />
      )}
      {isClustersSelectionMode && <MapValueClick url={currentClustersResult.url} handleClick={toggleSelectedCluster} />}
    </>
  );
};
