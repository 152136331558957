import { useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { CreatorOptionsColumn } from 'ui/creator/creatorOptionsColumn';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useWaterTimestamps } from 'views/water/useWaterTimestamps';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { reportsQueryService } from 'views/reports/reportsQueryService';
import { SingleDateReportsCreatorContent } from 'views/reports/creator/content/SingleDateReportsCreatorContent';
import { ReportsCreatorOptionsColumnContent } from 'views/reports/creator/components/ReportsCreatorOptionsColumnContent';
import { useWaterIndicators } from 'views/water/quality/indicators/useWaterIndicators';
import { useHandleWaterIndicatorChangeUseCase } from 'views/water/quality/handleWaterIndicatorChangeUseCase';
import { useWaterbodies } from 'views/water/waterbodies';
import { REPORTS_ALL_WATERBODIES_LABEL, REPORTS_INDICATOR_STATS } from 'domain/reports/constants';

interface WaterQualityReportsCreatorContentProps {
  areaId: number;
  timestamp: string | null;
}

export const WaterQualityReportsCreatorContent = ({ areaId, timestamp }: WaterQualityReportsCreatorContentProps) => {
  const { timestamps } = useWaterTimestamps();

  const { indicatorStatsQuery, toggleIndicatorStats } = reportsQueryService.useReportsIndicatorStats();

  const { selectedIndicator, indicatorOptions } = useWaterIndicators();

  const { changeWaterbody, resetWaterbody } = useWaterbodies();

  const { changeWaterIndicator } = useHandleWaterIndicatorChangeUseCase();

  const isReportsSecondDateSelected = useReportsStore.use.isReportsSecondDateSelected();

  const { waterBodiesList: waterbodies } = waterRepository.useFetchWaterbodies(areaId);
  const selectedWaterbodyId = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const options = indicatorOptions.map(({ value, label }) => {
    return {
      id: Number(value),
      label,
      selected: selectedIndicator?.id === value,
      onClick: () => changeWaterIndicator({ value, label: '' }),
    };
  });

  const waterbodyOptions = waterbodies.map(({ id, name }) => ({
    id,
    label: name,
    selected: selectedWaterbodyId === String(id),
    onClick: () => changeWaterbody({ value: id, label: '' }),
  }));

  if (waterbodies.length > 1) {
    waterbodyOptions.unshift({
      id: -1,
      label: REPORTS_ALL_WATERBODIES_LABEL,
      selected: selectedWaterbodyId === null,
      onClick: () => resetWaterbody(),
    });
  }

  const data = REPORTS_INDICATOR_STATS.filter(({ isForAll }) => selectedWaterbodyId || isForAll).map(
    ({ id, label }) => ({
      id,
      label,
      checked: !!indicatorStatsQuery?.includes(String(id)),
      onChange: () => toggleIndicatorStats(String(id)),
    }),
  );

  if (isCompareDatesType ? !isReportsSecondDateSelected : !(timestamp && timestamps.includes(timestamp))) {
    return null;
  }

  const isSomeIndicatorSelected = options.some(({ selected }) => selected);
  const isSomeWaterbodySelected = waterbodyOptions.some(({ selected }) => selected);

  return (
    <>
      <CreatorOptionsColumn width={240}>
        <ReportsCreatorOptionsColumnContent options={options} small />
      </CreatorOptionsColumn>
      {isSomeIndicatorSelected && (
        <CreatorOptionsColumn width={240}>
          <ReportsCreatorOptionsColumnContent options={waterbodyOptions} small />
        </CreatorOptionsColumn>
      )}
      {isSomeIndicatorSelected && isSomeWaterbodySelected && <SingleDateReportsCreatorContent data={data} />}
    </>
  );
};
