import { ReactComponent as HomeIcon } from 'assets/svg/home-icon-fill.svg';
import { AreaPages } from 'common/navigation/constants';
import { useBuildNavigationItem } from 'views/areas/areaDetails/useBuildNavigationItem';
import { PROSPECTING_VIEW_CONFIG } from 'domain/prospecting/configs';

export const useBuildProspectingNavItem = () => {
  return useBuildNavigationItem({
    config: PROSPECTING_VIEW_CONFIG,
    label: 'about site',
    icon: <HomeIcon />,
    iconSize: 26,
    type: AreaPages.OVERVIEW,
  });
};
