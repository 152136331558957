import { useEffect, useState } from 'react';

import { useDeleteQueryParameters } from 'common/navigation/hooks';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { calculationsRepository } from 'infrastructure/calculations/calculationsRepository';
import { useUserStore } from 'infrastructure/user/userStore';
import { useHandleErrorMessage } from 'views/areas/areaRequestNew/useHandleErrorMessage';
import { useSummaryPopupState } from 'views/areas/areaRequestNew/useSummaryPopupState';
import { useCheckoutSessionCleanup } from 'common/hooks/useCheckoutSessionCleanup';
import { type CalculationsPayloadPartial } from 'domain/calculations/types';
import { type AoiData } from 'domain/areas/types';
import { defineModalType } from 'domain/areas/helpers';

export const useRequestedArea = () => {
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const deleteQueryParameters = useDeleteQueryParameters();

  const closeSummaryModal = () => {
    setIsSummaryModalOpen(false);
    deleteQueryParameters(['summary']);
  };

  const { isSummaryVisible, summaryType } = useSummaryPopupState();
  const { setIsSubscriptionStatusPending } = useUserStore();

  const modalType = defineModalType(summaryType);

  const { areaRequestPayload, clearAreaRequestPayload } = areasRepository.useAreaRequestPayload();

  const { postNewAreaRequest, isRequestLoading, insertedAoiId, error } = areasRepository.usePostNewAreaRequest();
  useHandleErrorMessage(error);

  const { calculationsPayloadPartial: calculationsPayload, clearCalculationsPayloadPartial } =
    calculationsRepository.useCalculationsPayload();

  const { triggerSamCalculations, isCalculationLoading, calculationError } =
    calculationsRepository.useTriggerCalculations();
  useHandleErrorMessage(calculationError);

  useCheckoutSessionCleanup();

  useEffect(() => {
    if (isSummaryVisible) {
      if (modalType === 'PAYMENT_SUCCESS') {
        setIsSubscriptionStatusPending(true);
      }

      if (areaRequestPayload) {
        postNewAreaRequest(areaRequestPayload as unknown as AoiData);
      }
    } else {
      if (!!calculationsPayload && !!areaRequestPayload) {
        clearAreaRequestPayload();
        clearCalculationsPayloadPartial();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSummaryVisible]);

  const handleSamCalculations = async () => {
    const payload = calculationsPayload as unknown as CalculationsPayloadPartial;

    if (payload && insertedAoiId) {
      const calcId = await triggerSamCalculations({ ...payload, aoiId: insertedAoiId });

      if (calcId) {
        console.log('Calculation started successfully:', calcId);
        setIsSummaryModalOpen(true);
        clearAreaRequestPayload();
        clearCalculationsPayloadPartial();
      } else {
        console.error('Calculation failed');
      }
    }
  };

  useEffect(() => {
    if (insertedAoiId) {
      console.log('AOI added successfully:', insertedAoiId);
      handleSamCalculations();
    }
  }, [insertedAoiId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isSummaryModalOpen,
    closeSummaryModal,
    isRequestLoading,
    isCalculationLoading,
    modalType,
  };
};
