import { AxiosError } from 'axios';

import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { getIsSubscriptionExpired } from './helpers';

export const useGetActiveSubscription = () => {
  const { activeSubscriptionData, isActiveSubscriptionLoading, activeSubscriptionError } =
    subscriptionRepository.useGetStatus();

  const subscriptionStatusGetErrorCode =
    activeSubscriptionError instanceof AxiosError &&
    activeSubscriptionError.response?.status &&
    activeSubscriptionError.response.status !== 404
      ? activeSubscriptionError.response.status
      : null;

  const isSubscriptionExpired = getIsSubscriptionExpired(activeSubscriptionData);

  return {
    activeSubscriptionData,
    isActiveSubscriptionLoading,
    activeSubscriptionError,
    subscriptionStatusGetErrorCode,
    isSubscriptionExpired,
  };
};
