import { type AxiosResponse } from 'axios';
import env from 'react-dotenv';

import { type DataPoint } from 'common/types/mapData';
import { restAPI } from 'services/rest/restApiService';
import { type WeatherApiResponse } from 'components/graph/types';

export const weatherApi = {
  getDaily: (
    dataPoint: DataPoint,
    datesRange: { start: string; end: string },
  ): Promise<AxiosResponse<WeatherApiResponse>> => {
    const weatherServiceBaseUrl = env.REACT_APP_WEATHER_SERVICE_BASE_URL;
    const weatherServiceSubscriptionKey = env.REACT_APP_WEATHER_SERVICE_SUBSCRIPTION_KEY;
    const weatherServiceClientId = env.REACT_APP_WEATHER_SERVICE_CLIENT_ID;

    if (!weatherServiceBaseUrl) {
      throw new Error('REACT_APP_WEATHER_SERVICE_BASE_URL is not provided');
    }

    if (!weatherServiceSubscriptionKey) {
      throw new Error('REACT_APP_WEATHER_SERVICE_SUBSCRIPTION_KEY is not provided');
    }

    if (!weatherServiceClientId) {
      throw new Error('REACT_APP_WEATHER_SERVICE_CLIENT_ID is not provided');
    }

    const url = `${weatherServiceBaseUrl}/daily/json`;

    return restAPI.external.get(url, {
      params: {
        'api-version': 1.1,
        query: `${dataPoint.lat},${dataPoint.lng}`,
        startDate: datesRange.start,
        endDate: datesRange.end,
        'subscription-key': weatherServiceSubscriptionKey,
      },
      headers: {
        'x-ms-client-id': weatherServiceClientId,
      },
    });
  },
};
