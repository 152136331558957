import { type AxiosResponse } from 'axios';
import {
  type MineActivityIndexHexagonsApiResponse,
  type MineActivityIndexResultsApiResponse,
  type MineResult,
  type MineResultsApiResponse,
} from 'domain/extraction/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const areMineResultsValid = (data: any): data is AxiosResponse<MineResultsApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: MineResult) =>
        typeof result.id === 'number' &&
        typeof result.aoi_id === 'number' &&
        typeof result.img_date === 'string' &&
        typeof result.mine_object.id === 'number' &&
        typeof result.mine_object.label_name === 'string' &&
        typeof result.segmentation_model_id === 'number' &&
        (typeof result.area === 'number' || result.area === null) &&
        typeof result.cog_file_path === 'string',
    )
  );
};

export const areMineActivityIndexResultsValid = (
  data: any,
): data is AxiosResponse<MineActivityIndexResultsApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.aoi_id === 'number' &&
        typeof result.aoi_name === 'string' &&
        typeof result.date === 'string' &&
        typeof result.description === 'string' &&
        typeof result.url === 'string',
    )
  );
};

export const areMineActivityIndexHexagonsValid = (
  data: any,
): data is AxiosResponse<MineActivityIndexHexagonsApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.features) &&
    data.data.features.every(
      (feature: any) =>
        typeof feature.id === 'string' &&
        Array.isArray(feature.geometry.coordinates) &&
        typeof feature.properties['count%'] === 'number',
    )
  );
};
