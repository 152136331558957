import { Polyline } from 'react-leaflet';
import { type LatLngExpression, type PathOptions } from 'leaflet/index';

interface props {
  positions: LatLngExpression[][] | number[][];
  pathOptions: PathOptions;
  pane: string;
}

export const PolylineView = ({ positions, pathOptions, pane }: props) => {
  return <Polyline positions={positions} pathOptions={pathOptions} pane={pane} />;
};
