import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import bbox from 'geojson-bbox';
import { type BBox } from 'geojson';

import { useQueryParameter } from 'common/navigation/hooks';
import { type MapBounds } from 'common/types/mapData';
import { LAT_LNG_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { type MapBehaviourProps } from 'domain/displacements/types';

export const MapCenterer = ({ selectedGeoJSONData, isGeoDataAvailable }: MapBehaviourProps) => {
  const map = useMap();
  const centerFromUrl = useQueryParameter(LAT_LNG_QUERY_PARAMETER);

  useEffect(() => {
    if (selectedGeoJSONData && selectedGeoJSONData?.features?.length > 0) {
      const box = bbox(selectedGeoJSONData) as BBox;

      const bounds: MapBounds = [
        [box[1], box[0]],
        [box[3], box[2]],
      ];

      const extent = box
        ? bounds
        : [
            [49.505, -2.09],
            [53.505, 2.09],
          ];

      !centerFromUrl && map.fitBounds(extent as MapBounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeoJSONData, isGeoDataAvailable, map]);

  return null;
};
