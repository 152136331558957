import { type ValueAndLabel } from 'common/types';
import { BaseMapVariant } from 'ui/map/baseMap/enums';

export const createOtionFromMapStyle = (mapStyle: BaseMapVariant): ValueAndLabel => {
  return { value: mapStyle, label: mapStyle };
};

export const createBasemapOptions = (): ValueAndLabel[] => {
  return Object.values(BaseMapVariant).map((optionName) => ({ value: optionName, label: optionName }));
};
