import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLayerOrderStore } from 'components';

export const useInitializeLayersOrderRecord = (section: string, layersOrder?: number[]) => {
  const { pathname } = useLocation();
  const { setNestedLayersOrderRecord } = useLayerOrderStore();

  useEffect(() => {
    setNestedLayersOrderRecord(pathname, section, layersOrder || [0]);
  }, [JSON.stringify(layersOrder), pathname, section]); // eslint-disable-line react-hooks/exhaustive-deps
};
