import styled from 'styled-components';

import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from 'ui/atoms/button';
import { Container } from 'ui/atoms/container';
import { LabeledInput } from 'ui/molecules/labeledInput';
import { type InputElementProps } from 'ui/molecules/labeledInput/LabeledInput';
import { type FormElementProps } from 'domain/user/types';

interface ForgotPasswordProps {
  inputElementProps: InputElementProps;
  formElement: FormElementProps;
  disableSubmitButton: boolean;
  handleClose: () => void;
  handleOkButtonClick: () => void;
}

export const ForgotPasswordForm = ({
  inputElementProps,
  formElement,
  disableSubmitButton,
  handleClose,
  handleOkButtonClick,
}: ForgotPasswordProps) => {
  const { error } = inputElementProps;

  return (
    <StyledForm ref={formElement.ref} onSubmit={formElement.handleSubmit}>
      <StyledContainer mb={12}>
        <LabeledInput {...inputElementProps} />
        {!!error && <Error>{error}</Error>}
      </StyledContainer>
      {formElement.captchaKey && (
        <ReCAPTCHA
          ref={formElement.captchaRef}
          onChange={formElement.onCaptchaValidate}
          onExpired={formElement.onCaptchaExpiry}
          sitekey={formElement.captchaKey}
          theme="dark"
        />
      )}
      <Buttons>
        <Button variant="SECONDARY" squared onClick={handleClose} minWidth={164}>
          cancel
        </Button>
        <Button variant="PRIMARY" squared onClick={handleOkButtonClick} disabled={disableSubmitButton}>
          receive email
        </Button>
      </Buttons>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.errorPink};
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;
