import { useEffect, useState } from 'react';

import { type LayerObject } from 'common/types/mapData';
import { DateSelect } from 'ui';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { Container } from 'ui/atoms/container';
import { CreatorDataColumn, type CreatorDataColumnState } from 'ui/creator/creatorDataColumn/CreatorDataColumn';
import { isCreatorDataColumnDataValid } from 'ui/creator/typeguards';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { ReportsCreatorDataColumnDatesLabel } from 'views/reports/creator/components/ReportsCreatorDataColumnDatesLabel';
import { ReportsCreatorMapOptions } from 'views/reports/creator/components/ReportsCreatorMapOptions';
import { useAvailableLayers } from 'views/greenery/segmentation/useAvailableLayers';

interface SegmentationDoubleDateReportsCreatorContentProps {
  greeneryObjects: LayerObject[];
  greeneryObjectsLoading: boolean;
  timestamp: string;
  timestamps: string[];
  areaId: number;
  cogUrl: string;
  changeTimestamp: (timestamp: string | null) => void;
}

export const SegmentationDoubleDateReportsCreatorContent = ({
  greeneryObjects,
  greeneryObjectsLoading,
  timestamp,
  timestamps,
  areaId,
  cogUrl,
  changeTimestamp,
}: SegmentationDoubleDateReportsCreatorContentProps) => {
  const { availableLayers } = useAvailableLayers(cogUrl, greeneryObjects);

  const { segmentationAreaResults, areSegmentationAreaResultsLoading } =
    greeneryRepository.useFetchSegmentationAreaResults(areaId, timestamp);

  const isLoading = greeneryObjectsLoading || areSegmentationAreaResultsLoading;

  const {
    classesVisibility: layersVisibility,
    toggleMultipleQueries: toggleLayerVisibility,
    updateClassesVisibility: updateLayersVisibility,
  } = useGreeneryLayersVisibility();

  const firstDateReportsSegmentationDataset = useReportsStore.use.firstDateReportsSegmentationDataset();
  const setFirstDateReportsSegmentationDataset = useReportsStore.use.setFirstDateReportsSegmentationDataset();
  const setSecondDateReportsSegmentationDataset = useReportsStore.use.setSecondDateReportsSegmentationDataset();

  const reportScreenshotSrc = useReportsStore.use.reportScreenshotSrc();
  const setTakeScreenshotFlag = useReportsStore.use.setTakeScreenshotFlag();

  const [columnState, setColumnState] = useState<CreatorDataColumnState>('DEFAULT');

  const handleConfirmClick = () => {
    setColumnState('SAVING');
    setTakeScreenshotFlag(true);
  };

  const currentDateDataset = greeneryObjects
    .filter(({ name }) => availableLayers?.includes(name))
    .filter(({ id }) => segmentationAreaResults.some(({ greenery_object_id }) => greenery_object_id === id))
    .map(({ id, name, label_name }) => {
      const otherDateOption = firstDateReportsSegmentationDataset?.data.find((item) => item.id === id);
      const label =
        firstDateReportsSegmentationDataset && !otherDateOption ? `${label_name} (Date 2 only)` : label_name;
      const checked = otherDateOption ? otherDateOption.checked : !!layersVisibility[name];

      return {
        id,
        label,
        checked,
        disabled: !!otherDateOption,
        onChange: () => toggleLayerVisibility(name),
      };
    });

  const firstDateDatasetOnly = firstDateReportsSegmentationDataset
    ? firstDateReportsSegmentationDataset.data
        .filter(({ id }) => !currentDateDataset.some((item) => item.id === id))
        .map(({ id, checked }) => {
          const option = greeneryObjects.find((object) => object.id === id);

          if (!option) {
            return undefined;
          }

          return {
            id,
            label: `${option.label_name} (Date 1 Only)`,
            checked,
            disabled: true,
            onChange: () => toggleLayerVisibility(option.name),
          };
        })
        .filter((item) => item)
    : [];

  const validFirstDateDatasetOnly = isCreatorDataColumnDataValid(firstDateDatasetOnly) ? firstDateDatasetOnly : [];
  const data = [...currentDateDataset, ...validFirstDateDatasetOnly];

  const isConfirmDisabled = !data.some(({ checked }) => checked) || isLoading;

  useEffect(() => {
    if (reportScreenshotSrc && columnState === 'SAVING') {
      const reportsDataset = {
        timestamp,
        data,
        screenshotScr: reportScreenshotSrc,
      };

      firstDateReportsSegmentationDataset
        ? setSecondDateReportsSegmentationDataset(reportsDataset)
        : setFirstDateReportsSegmentationDataset(reportsDataset);

      setColumnState('DEFAULT');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportScreenshotSrc]);

  useEffect(() => {
    if (firstDateReportsSegmentationDataset) {
      const layersStateUpdate = firstDateReportsSegmentationDataset.data
        .map(({ id, checked }) => {
          const option = greeneryObjects.find((object) => object.id === id);

          return (
            option && {
              [option.name]: checked,
            }
          );
        })
        .filter((item) => item !== undefined);

      layersStateUpdate.length && updateLayersVisibility(Object.assign({}, ...layersStateUpdate));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDateReportsSegmentationDataset]);

  return (
    <CreatorDataColumn
      data={data}
      state={columnState}
      isLoading={isLoading}
      isConfirmDisabled={isConfirmDisabled}
      handleConfirmClick={handleConfirmClick}
      topContent={
        <>
          <ReportsCreatorDataColumnDatesLabel no={firstDateReportsSegmentationDataset ? 2 : 1} />
          <DateSelect
            {...{ timestamps, timestamp, changeTimestamp }}
            forbiddenTimestamp={firstDateReportsSegmentationDataset?.timestamp}
          />
        </>
      }
    >
      <Container mt={15}>
        <ReportsCreatorMapOptions />
      </Container>
    </CreatorDataColumn>
  );
};
