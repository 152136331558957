import { useEffect } from 'react';

import { Select } from 'ui/atoms/select';
import { type ValueAndLabel } from '../../types';
import { convertTimestampToSelectOption, mapTimestampsToSelectOptions } from '../../utils';

interface DateSelectProps {
  timestamps: string[];
  areTimestampsLoading?: boolean;
  forbiddenTimestamp?: string;
  timestamp?: string | null;
  changeTimestamp: (timestamp: string | null) => void;
}

export const DateSelect = ({
  timestamps,
  areTimestampsLoading = false,
  forbiddenTimestamp,
  timestamp,
  changeTimestamp,
}: DateSelectProps) => {
  const availableTimestamps = timestamps.filter((timestamp) => timestamp !== forbiddenTimestamp);

  const selectData = {
    options: mapTimestampsToSelectOptions(availableTimestamps),
    selectedOption:
      timestamp && availableTimestamps.includes(timestamp) ? convertTimestampToSelectOption(timestamp) : undefined,
    isFetching: areTimestampsLoading,
    initialStateLabel: 'Select date',
    noOptionsLabel: 'No dates',
    name: 'date selection',
  };

  const onChange = (option: ValueAndLabel) => changeTimestamp(String(option.value));

  useEffect(() => {
    if (forbiddenTimestamp && forbiddenTimestamp === timestamp && availableTimestamps.length) {
      changeTimestamp(availableTimestamps[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forbiddenTimestamp]);

  return <Select {...selectData} onChange={onChange} minWidth={140} />;
};
