import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';

interface LineamentsLayerManagerState {
  areLineamentsVisible: boolean;
  toggleLineamentsVisibility: () => void;
  lineamentsOpacity: number;
  setLineamentsOpacity: (opacity: number) => void;
  isLineamentsSelectionMode: boolean;
  enableLineamentsSelectionMode: () => void;
  confirmLineamentsSelection: () => void;
  selectedLineamentsSaved: number[];
  selectedLineamentsInProgress: number[];
  toggleSelectedLineament: (no: number) => void;
  clearSelectedLineaments: () => void;
  cancelLineamentsSelection: () => void;
  isLineamentDensityVisible: boolean;
  toggleLineamentDensityVisibility: () => void;
  lineamentDensityOpacity: number;
  setLineamentDensityOpacity: (opacity: number) => void;
  clearLineamentsData: () => void;
}

const initialState = {
  areLineamentsVisible: false,
  lineamentsOpacity: 1,
  isLineamentsSelectionMode: false,
  selectedLineamentsSaved: [],
  selectedLineamentsInProgress: [],
  lineamentDensityOpacity: 1,
  isLineamentDensityVisible: false,
};

const useLineamentsLayerManagerStoreBase = create<LineamentsLayerManagerState>()(
  devtools(
    persist(
      (set) => ({
        areLineamentsVisible: initialState.areLineamentsVisible,
        toggleLineamentsVisibility: () =>
          set(
            (state) => ({ areLineamentsVisible: !state.areLineamentsVisible }),
            false,
            'layerManager/toggleLineamentsVisibility',
          ),
        lineamentsOpacity: initialState.lineamentsOpacity,
        setLineamentsOpacity: (opacity: number) =>
          set({ lineamentsOpacity: opacity }, false, 'layerManager/setLineamentsOpacity'),
        isLineamentsSelectionMode: initialState.isLineamentsSelectionMode,
        enableLineamentsSelectionMode: () =>
          set(
            (state) => ({
              isLineamentsSelectionMode: true,
              selectedLineamentsInProgress: state.selectedLineamentsSaved,
            }),
            false,
            'layerManager/enableLineamentsSelectionMode',
          ),
        confirmLineamentsSelection: () =>
          set(
            (state) => ({
              isLineamentsSelectionMode: false,
              selectedLineamentsSaved: state.selectedLineamentsInProgress,
            }),
            false,
            'layerManager/disableLineamentsSelectionMode',
          ),
        selectedLineamentsSaved: initialState.selectedLineamentsSaved,
        selectedLineamentsInProgress: initialState.selectedLineamentsInProgress,
        toggleSelectedLineament: (no: number) => {
          set(
            (state) => {
              const lineamentsSet = new Set(state.selectedLineamentsInProgress);
              lineamentsSet.has(no) ? lineamentsSet.delete(no) : lineamentsSet.add(no);

              return { selectedLineamentsInProgress: Array.from(lineamentsSet) };
            },
            false,
            'layerManager/toggleSelectedLineament',
          );
        },
        cancelLineamentsSelection: () =>
          set(
            (state) => ({
              selectedLineamentsInProgress: state.selectedLineamentsSaved,
              isLineamentsSelectionMode: false,
            }),
            false,
            'layerManager/cancelLineamentsSelection',
          ),
        clearSelectedLineaments: () =>
          set(
            {
              selectedLineamentsSaved: initialState.selectedLineamentsSaved,
              selectedLineamentsInProgress: initialState.selectedLineamentsSaved,
            },
            false,
            'layerManager/clearSelectedLineaments',
          ),
        lineamentDensityOpacity: initialState.lineamentDensityOpacity,
        setLineamentDensityOpacity: (opacity: number) =>
          set({ lineamentDensityOpacity: opacity }, false, 'layerManager/setLineamentDensityOpacity'),
        isLineamentDensityVisible: initialState.isLineamentDensityVisible,
        toggleLineamentDensityVisibility: () =>
          set(
            (state) => ({ isLineamentDensityVisible: !state.isLineamentDensityVisible }),
            false,
            'layerManager/toggleLineamentDensityVisibility',
          ),
        clearLineamentsData: () =>
          set(
            {
              areLineamentsVisible: initialState.areLineamentsVisible,
              lineamentsOpacity: initialState.lineamentsOpacity,
              isLineamentsSelectionMode: initialState.isLineamentsSelectionMode,
              selectedLineamentsSaved: initialState.selectedLineamentsSaved,
              selectedLineamentsInProgress: initialState.selectedLineamentsInProgress,
              lineamentDensityOpacity: initialState.lineamentDensityOpacity,
              isLineamentDensityVisible: initialState.isLineamentDensityVisible,
            },
            false,
            'layerManager/clearLineamentsData',
          ),
      }),
      { name: 'RSOM_lineamentsLayerManagerStore', storage: createJSONStorage(() => localStorage) },
    ),
    { name: 'lineamentsLayerManagerStore' },
  ),
);

export const useLineamentsLayerManagerStore = createSelectors(useLineamentsLayerManagerStoreBase);
