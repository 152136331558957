import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useLayerOrderStore } from 'components';

export const useInitializeLegacyLayersIndices = () => {
  const { pathname } = useLocation();

  const setLayersOrderTable = useLayerOrderStore.use.setLayersOrderTable();

  const legacyLayersOrderTable: [string, number][] = useMemo(
    () => [
      ['extent', 0],
      ['infrastructure', 0],
      ['dem', 0],
      ['hillshade', 0],
      ['satellite', 0],
    ],
    [],
  );

  useEffect(() => {
    if (!pathname.includes('exploration')) {
      setLayersOrderTable(legacyLayersOrderTable);
    }
  }, [legacyLayersOrderTable, pathname, setLayersOrderTable]);
};
