import { ReactComponent as InvisibilityIcon } from 'assets/svg/invisibility-icon.svg';
import { Tooltip } from 'ui/atoms/tooltip';

export const PixelStatisticsInvisibilityIcon = () => {
  return (
    <div>
      <Tooltip
        content="Value falls outside of the selected threshold and is invisible in map visualization"
        interactive={true}
      >
        <InvisibilityIcon />
      </Tooltip>
    </div>
  );
};
