import { useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useUserStore } from 'infrastructure/user/userStore';
import { type SubscriptionRequest } from 'domain/subscription/types';
import {
  isActiveSubscriptionDataValid,
  isSubscriptionProductsListValid,
  isTrialDurationValid,
} from 'domain/subscription/typeguards';
import {
  SUBSCRIPTIONS_LIST_QUERY_KEY,
  SUBSCRIPTION_CANCEL_POLLING_INTERVAL,
  SUBSCRIPTION_STATUS_QUERY_KEY,
  TRIAL_DURATION_QUERY_KEY,
} from './constants';
import { subscriptionAPI } from './subscriptionApiAdapter';

export const subscriptionRepository = {
  useGetProductsList: () => {
    const { data, isLoading, error } = useQuery([SUBSCRIPTIONS_LIST_QUERY_KEY], subscriptionAPI.getProductsList);

    const subscriptionRawProductsList = data?.data && isSubscriptionProductsListValid(data.data) ? data.data : [];

    const normalizedSubscriptionProductsList = subscriptionRawProductsList.sort(
      (a, b) => a.price.amount - b.price.amount,
    );

    return {
      subscriptionProductsList: normalizedSubscriptionProductsList,
      isSubscriptionProductsListLoading: isLoading,
      subscriptionProductsListError: error,
    };
  },

  useCreate: () => {
    const { mutate, data, isLoading, error } = useMutation((request: SubscriptionRequest) =>
      subscriptionAPI.create(request),
    );

    return {
      onSubscriptionCreate: mutate,
      newSubscriptionData: data,
      isSubscriptionCreating: isLoading,
      subscriptionCreatingError: error,
    };
  },

  useGetStatus: () => {
    const {
      isSubscriptionStatusPending,
      setIsSubscriptionStatusPending,
      isWaitingForSubscriptionCancel,
      setIsWaitingForSubscriptionCancel,
    } = useUserStore();

    const {
      data,
      isLoading,
      error: activeSubscriptionError,
    } = useQuery([SUBSCRIPTION_STATUS_QUERY_KEY], subscriptionAPI.getStatus, {
      retry: isSubscriptionStatusPending ? true : 1,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex),
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchInterval: isWaitingForSubscriptionCancel ? SUBSCRIPTION_CANCEL_POLLING_INTERVAL : false,
    });

    useEffect(() => {
      if (data && isSubscriptionStatusPending) {
        setIsSubscriptionStatusPending(false);
      }
    }, [data, isSubscriptionStatusPending, setIsSubscriptionStatusPending]);

    useEffect(() => {
      if (data && isWaitingForSubscriptionCancel) {
        data.data.canceled && data.data.expires_at !== null && setIsWaitingForSubscriptionCancel(false);
      }
    }, [data, isWaitingForSubscriptionCancel, setIsWaitingForSubscriptionCancel]);

    const activeSubscriptionData = data?.data && isActiveSubscriptionDataValid(data.data) ? data.data : undefined;

    return { activeSubscriptionData, isActiveSubscriptionLoading: isLoading, activeSubscriptionError };
  },

  useCancel: (onMutate?: () => void) => {
    const { mutate, data, isLoading, error } = useMutation(subscriptionAPI.cancel, {
      onMutate,
    });

    return {
      handleSubscriptionCancel: mutate,
      isSubscriptionCanceling: isLoading,
      subscriptionCancelData: data?.data,
      subscriptionCancelError: error,
    };
  },

  useUpgrade: () => {
    const { mutate, isLoading, data, error } = useMutation((request: SubscriptionRequest) =>
      subscriptionAPI.upgrade(request),
    );

    return {
      handleSubscriptionUpgrade: mutate,
      isSubscriptionUpgrading: isLoading,
      subscriptionUpgradeResponse: data?.data,
      subscriptionUpgradeError: error,
    };
  },

  useGetTrialDuration: () => {
    const { data } = useQuery([TRIAL_DURATION_QUERY_KEY], subscriptionAPI.trialDuration);

    const trialDuration = data?.data && isTrialDurationValid(data.data) ? data.data : undefined;

    return {
      trialDuration,
    };
  },
};
