import { SummaryModal } from 'ui/modal/summaryModal';

import { ReactComponent as CheckIcon } from 'assets/svg/check-circle.svg';
import { Button } from 'ui/atoms/button';
import { type SummaryModalVariant } from 'domain/areas/types';

const configurations = {
  PAYMENT_SUCCESS: {
    headerText: 'Thank you for your payment',
    paragraphText:
      'The requested area will be marked as “pending” until the data is ready to be viewed. It should only take a few minutes.',
  },
  REQUEST_SUCCESS: {
    headerText: 'Thank you for your request',
    paragraphText:
      'The requested area will be marked as “pending” until the data is ready to be viewed. It should only take a few minutes.',
  },
  CUSTOM_REQUEST: {
    headerText: 'Thank you for your request',
    paragraphText:
      'We will contact you directly to discuss details of this potential analysis. The area you requested will be marked as “pending” for now.',
  },
};

interface RequestSuccessModalProps {
  variant: SummaryModalVariant;
  isOpen: boolean;
  closeModal: () => void;
  onConfirm?: () => void;
}

export const RequestSuccessModal = ({ variant, isOpen, closeModal, onConfirm }: RequestSuccessModalProps) => {
  return (
    <SummaryModal
      icon={<CheckIcon />}
      headerText={configurations[variant].headerText}
      paragraphText={configurations[variant].paragraphText}
      {...{ isOpen, closeModal }}
    >
      <Button variant="PRIMARY" squared onClick={onConfirm || closeModal} minWidth={100}>
        OK
      </Button>
    </SummaryModal>
  );
};
