import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { Sidebar } from 'ui/sidebar';
import { Link } from 'ui/atoms/link';
import { AppHeader } from 'layout/appHeader';
import { AppLayout } from 'layout/appLayout/AppLayout';
import { Filters } from 'views/areas/areasList/Filters';
import { Search } from 'views/areas/areasList/Search';
import { SubscriptionsLink } from 'views/subscriptions/SubscriptionsLink';

export const Areas = () => (
  <AppLayout sidebar={<Sidebar variant="BARE" />}>
    <AppHeader>
      <LinksWrapper>
        <div>
          <Link name="blog" variant="TERTIARY" href="https://terraeye.blogspot.com/">
            how it works
          </Link>
        </div>
        <SubscriptionsLink />
      </LinksWrapper>
      <FilteringSection>
        <Filters />
        <Search />
      </FilteringSection>
    </AppHeader>
    <Outlet />
  </AppLayout>
);

const FilteringSection = styled.div`
  display: flex;
  margin-left: auto;
  gap: 6px;
  min-width: 210px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 15px;
`;
