import { type ChangeEvent, type SyntheticEvent } from 'react';
import styled from 'styled-components';

import { Input } from 'ui/atoms/input';
import { IconButton } from 'ui/atoms/iconButton';
import { ReactComponent as SendIcon } from 'assets/svg/send-icon.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg';
import { ReactComponent as RoundedCross } from 'assets/svg/rounded-cross.svg';
import { type InputVariant } from 'ui/atoms/input/Input';
import { type IconButtonVariants } from 'ui/atoms/iconButton/IconButton';
import { type Palette } from 'ui/types';

export type InputFormVariant = 'CHAT' | 'FILTER' | 'SEARCH';

type InputFormStyle = Required<{
  inputVariant: InputVariant;
  buttonVariant: IconButtonVariants;
  iconSize: number;
  clearable: boolean;
  autocomplete: boolean;
  withButton: boolean;
  withInnerIcon: boolean;
}>;

const styles: Palette<InputFormVariant, InputFormStyle> = {
  CHAT: {
    inputVariant: 'CHAT',
    buttonVariant: 'LIGHT',
    iconSize: 30,
    clearable: false,
    autocomplete: true,
    withButton: true,
    withInnerIcon: false,
  },
  FILTER: {
    inputVariant: 'SEARCH',
    buttonVariant: 'PRIMARY_STATIC',
    iconSize: 15,
    clearable: true,
    autocomplete: false,
    withButton: true,
    withInnerIcon: false,
  },
  SEARCH: {
    inputVariant: 'SEARCH',
    buttonVariant: 'PRIMARY_STATIC',
    iconSize: 15,
    clearable: true,
    autocomplete: true,
    withButton: false,
    withInnerIcon: true,
  },
};

export interface InputFormProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  name: string;
  variant: InputFormVariant;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (e: SyntheticEvent) => void;
  width?: number;
}

export const InputForm = ({
  inputRef,
  name,
  variant,
  type,
  placeholder,
  value,
  onChange,
  onSubmit,
  width,
}: InputFormProps) => (
  <StyledForm onSubmit={onSubmit}>
    <FormInnerContainer>
      {styles[variant].withInnerIcon && (
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      )}
      <Input
        ref={inputRef}
        $variant={styles[variant].inputVariant}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete={styles[variant].autocomplete ? 'on' : 'off'}
        width={width}
        $paddingLeft={styles[variant].withInnerIcon ? 30 : 15}
        $paddingRight={30}
      />
      <CancelIconWrapper
        onClick={() => onChange && onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)}
      >
        {value?.length && styles[variant!].clearable ? <RoundedCross /> : null}
      </CancelIconWrapper>
    </FormInnerContainer>
    {styles[variant].withButton && (
      <IconButton variant={styles[variant].buttonVariant} $iconSize={styles[variant].iconSize} icon={<SendIcon />} />
    )}
  </StyledForm>
);

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const FormInnerContainer = styled.div`
  width: 100%;
  display: flex;
`;

const CancelIconWrapper = styled.div`
  color: ${({ theme }) => theme.color.silver};
  height: 30px;
  width: 10px;
  display: grid;
  place-items: center;
  margin-left: -20px;
  cursor: pointer;
`;

const SearchIconWrapper = styled.div`
  height: 30px;
  width: 30px;
  padding: 8px;
  margin-right: -30px;
  color: ${({ theme }) => theme.color.white};
  z-index: 101;
`;

InputForm.displayName = 'InputForm';

InputForm.defaultProps = {
  variant: 'CHAT' as InputFormVariant,
};
