import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';

interface LayerOrderState {
  sectionsOrderRecord: Record<string, string[]> | null;
  setSectionsOrderRecord: (key: string, layersOrder: string[]) => void;
  layersOrderRecord: Record<string, number[]> | null;
  setLayersOrderRecord: (key: string, layersOrder: number[]) => void;
  nestedLayersOrderRecord: Record<string, Record<string, number[]>> | null;
  setNestedLayersOrderRecord: (key: string, subkey: string, layersOrder: number[]) => void;
  layersOrderTable: [string, number][] | null;
  setLayersOrderTable: (order: [string, number][]) => void;
}

const useLayerOrderStoreBase = create(
  persist(
    devtools<LayerOrderState>(
      (set) => ({
        sectionsOrderRecord: null,
        layersOrderRecord: null,
        nestedLayersOrderRecord: null,
        layersOrderTable: null,
        setSectionsOrderRecord: (key, layersOrder) =>
          set(
            (state) => ({
              sectionsOrderRecord: {
                ...state.sectionsOrderRecord,
                [key]: layersOrder,
              },
            }),
            false,
            'layerManager/setSectionsOrderRecord',
          ),
        setLayersOrderRecord: (key, layersOrder) => {
          set(
            (state) => ({
              ...state,
              layersOrderRecord: {
                ...state.layersOrderRecord,
                [key]: layersOrder,
              },
            }),
            false,
            'layerManager/setLayersOrderRecord',
          );
        },
        setNestedLayersOrderRecord: (key, subkey, layersOrder) => {
          set(
            (state) => ({
              ...state,
              nestedLayersOrderRecord: {
                ...state.nestedLayersOrderRecord,
                [key]: {
                  ...state.nestedLayersOrderRecord?.[key],
                  [subkey]: layersOrder,
                },
              },
            }),
            false,
            'layerManager/setNestedLayersOrderRecord',
          );
        },
        setLayersOrderTable(order) {
          set({ layersOrderTable: order }, false, 'layerManager/setLayersOrderTable');
        },
      }),
      { name: 'layerOrderStore' },
    ),
    { name: 'RSOM_layers_order' },
  ),
);

export const useLayerOrderStore = createSelectors(useLayerOrderStoreBase);
