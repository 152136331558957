import { ReactComponent as MineIcon } from 'assets/svg/mine-icon.svg';
import { AreaPages } from 'common/navigation/constants';
import { useBuildNavigationItem } from 'views/areas/areaDetails/useBuildNavigationItem';
import { EXTRACTION_VIEW_CONFIG } from 'domain/extraction/configs';

export const useBuildExtractionNavItem = () => {
  return useBuildNavigationItem({
    config: EXTRACTION_VIEW_CONFIG,
    label: 'mine',
    icon: <MineIcon />,
    iconSize: 14,
    type: AreaPages.MINE,
  });
};
