import { type ReactNode } from 'react';

import { type MapPoint } from 'common/types/mapData';
import { extractArrayFromUrlParam } from 'common/utils';
import { useQueryParameter } from 'common/navigation/hooks';
import { LOCAL_STORAGE_MAP_CENTER_NAME } from 'common/constants/services';
import { LAT_LNG_QUERY_PARAMETER, ZOOM_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { localStorageService } from 'services/localStorageService';
import { BaseMap } from 'ui/map/baseMap';
import { BaseMapVariant } from 'ui/map/baseMap/enums';
import { isEncodedArrayValid } from 'ui/map/baseMap/typeguards';
import { MapReferenceMemoizer } from './mapReferenceMemoizer';
import { CustomMapScaleWatcherPluggedIn } from './CustomMapScaleWatcherPluggedIn';

interface MapComponentProps {
  mapVariant?: BaseMapVariant;
  children?: ReactNode;
}

export const Map = ({ mapVariant = BaseMapVariant.DEFAULT, children }: MapComponentProps) => {
  const centerFromLocalStorage: unknown = localStorageService.getValue(
    LOCAL_STORAGE_MAP_CENTER_NAME,
    '{ "lat": "0", "lng": "0" }',
  );

  const centerFromUrl = useQueryParameter(LAT_LNG_QUERY_PARAMETER);
  const zoomFromUrl = useQueryParameter(ZOOM_QUERY_PARAMETER);

  return (
    <BaseMap
      variant={mapVariant}
      center={
        centerFromUrl && isEncodedArrayValid(extractArrayFromUrlParam(centerFromUrl))
          ? extractArrayFromUrlParam(centerFromUrl)
          : [(centerFromLocalStorage as MapPoint)?.lat || 0, (centerFromLocalStorage as MapPoint)?.lng || 0]
      }
      zoom={Number(zoomFromUrl) || undefined}
    >
      <MapReferenceMemoizer />
      <CustomMapScaleWatcherPluggedIn />
      {children}
    </BaseMap>
  );
};
