import { ROUTES } from 'common/navigation/routes';
import { RequireAuth } from 'common/navigation/RequireAuth';
import { Subscriptions } from './Subscriptions';
import { SubscriptionsManage } from './SubscriptionsManage';
import { SubscriptionsRoutePermission } from './SubscriptionsRoutePermission';
import { SubscriptionsStart } from './SubscriptionsStart';

export const useBuildSubscriptionsRouteObject = () => {
  return {
    path: ROUTES.subscriptions,
    element: (
      <RequireAuth>
        <Subscriptions />
      </RequireAuth>
    ),
    children: [
      {
        path: ROUTES.subscriptionsStart,
        element: (
          <SubscriptionsRoutePermission startPageChecking={true}>
            <SubscriptionsStart />
          </SubscriptionsRoutePermission>
        ),
      },
      {
        path: ROUTES.subscriptionsManage,
        element: (
          <SubscriptionsRoutePermission startPageChecking={false}>
            <SubscriptionsManage />
          </SubscriptionsRoutePermission>
        ),
      },
    ],
  };
};
