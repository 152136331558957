import styled from 'styled-components';

import { Link } from 'ui';
import { createMailtoLink } from './createMailtoLink';

interface ContactLinkProps {
  message: {
    url: string;
    title: string;
    body: string;
  };
}

export const ContactLink = ({ message }: ContactLinkProps) => {
  const contactLink = createMailtoLink(message.url, message.title, message.body);

  return (
    <Wrapper>
      <Link variant="PRIMARY" href={contactLink}>
        Contact us
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 144px;
`;
