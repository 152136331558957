import { type ExtendedIndicator } from 'common/types';
import { Layer } from 'components/map';
import { SingleLayerClickToValueTitilerTooltip } from 'components/map/tooltip/SingleLayerClickToValueTitilerTooltip';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { VegetationMapLayer } from './VegetationMapLayer';
import { useVegetationOtherProviderResults } from '../useVegetationOtherProviderResults';
import { useGreeneryLayersVisibility } from '../../useGreeneryLayersVisibility';

interface Props {
  timestamp: string;
  indicator: ExtendedIndicator;
}

export const VegetationOtherProvidersMapContent = ({ timestamp, indicator }: Props) => {
  const { results } = useVegetationOtherProviderResults(timestamp, indicator.id);

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();
  const { classesVisibility } = useGreeneryLayersVisibility();

  const selectedClasses = greeneryObjects.filter(({ name }) => Object.keys(classesVisibility).includes(name));
  const selectedClassesUrls = results
    .filter(({ class_id }) => selectedClasses.some(({ id }) => id === class_id))
    .map(({ url }) => url);

  return (
    <>
      <Layer name={'vegetation-planet-scope-layer'} zIndex={480}>
        {results.map(({ id, url, class_id }) => (
          <VegetationMapLayer
            key={id}
            resultId={id}
            vegetationCogUrl={getAzureBlobStorageUrl(url)}
            layerId={class_id}
            greeneryObjects={greeneryObjects}
            layersVisibility={classesVisibility}
            colormap={indicator.colormap}
            range={[indicator.min_value, indicator.max_value]}
          />
        ))}
      </Layer>
      {selectedClassesUrls.length === 1 && (
        <SingleLayerClickToValueTitilerTooltip
          url={getAzureBlobStorageUrl(selectedClassesUrls[0])}
          unit={indicator.unit}
          precision={indicator.precision}
        />
      )}
    </>
  );
};
