import { useAoiNumber } from 'common/navigation/hooks';
import { areasRepository } from 'infrastructure/areas/areasRepository';

export const useGetAoiName = () => {
  const aoiId = useAoiNumber();

  const { areaOfInterestDetails, areaOfInterestDetailsLoading } = areasRepository.useFetchAreaOfInterestDetails(aoiId);
  const areaName = areaOfInterestDetails?.properties.name;

  if (!areaName && !areaOfInterestDetailsLoading) {
    throw new Error('Area name is not defined');
  }

  return { areaName, isAreaNameLoading: areaOfInterestDetailsLoading };
};
