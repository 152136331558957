import { ConfirmationModal } from 'ui/modal';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { useDeleteFeatures } from './useDeleteFeatures';

export const DeleteConfirmationPopup = () => {
  const deleteMode = useAreaRequestStore.use.deleteMode();
  const disableDeleteMode = useAreaRequestStore.use.disableDeleteMode();
  const { deleteFeatures } = useDeleteFeatures();

  return (
    <ConfirmationModal
      isOpen={deleteMode}
      closeModal={disableDeleteMode}
      consentAction={deleteFeatures}
      cancelAction={disableDeleteMode}
    >
      You are about to delete the selection. Are you sure?
    </ConfirmationModal>
  );
};
