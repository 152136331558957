import { useGetAoiName } from 'common/hooks/getAoiName';
import { FoldableSectionContentWrapper, AuxiliaryControlsWrapper, LegendItemWraper } from 'components/layerManager';
import { useInitializeLayersOrderRecord } from 'components/layerManager/draggableLayerManager/useInitializeLayersOrderRecord';
import { useLineamentsLayerManagerStore } from './lineamentsLayerManagerStore';
import { LineamentsDensityLayerManager } from './LineamentsDensityLayerManager';
import { LineamentsLengthRangeSlider } from './LineamentsLengthRangeSlider';
import { LineamentsFoldableSection } from './LineamentsFoldableSection';
import { LineamentsOpacitySlider } from './LineamentsOpacitySlider';
import { LineamentsSelectionButtons } from './LineamentsSelectionButtons';
import { EXPLORATION_LAYERS_KEYS } from '../../explorationDraggableLayersKeys';

interface LineamentsLayerManagerProps {
  id: string;
}

export const LineamentsLayerManager = ({ id }: LineamentsLayerManagerProps) => {
  const { areaName } = useGetAoiName();
  const areLineamentsVisible = useLineamentsLayerManagerStore.use.areLineamentsVisible();
  const isLineamentsSelectionMode = useLineamentsLayerManagerStore.use.isLineamentsSelectionMode();
  const selectedLineamentsSaved = useLineamentsLayerManagerStore.use.selectedLineamentsSaved();

  useInitializeLayersOrderRecord(EXPLORATION_LAYERS_KEYS.LINEAMENTS, [0, 1]);

  return (
    <LineamentsFoldableSection id={id}>
      <LegendItemWraper>
        <FoldableSectionContentWrapper>
          <div>
            <AuxiliaryControlsWrapper
              disabled={!areLineamentsVisible || isLineamentsSelectionMode || !!selectedLineamentsSaved.length}
            >
              <LineamentsLengthRangeSlider />
            </AuxiliaryControlsWrapper>
            <AuxiliaryControlsWrapper disabled={!areLineamentsVisible}>
              <LineamentsOpacitySlider />
            </AuxiliaryControlsWrapper>
            <AuxiliaryControlsWrapper disabled={!areLineamentsVisible}>
              <LineamentsSelectionButtons />
            </AuxiliaryControlsWrapper>
            {areaName && <LineamentsDensityLayerManager areaName={areaName} />}
          </div>
        </FoldableSectionContentWrapper>
      </LegendItemWraper>
    </LineamentsFoldableSection>
  );
};
