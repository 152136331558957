import { type ExtendedIndicator } from 'common/types';
import { useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { capitalizeAllFirstLetters } from 'common/utils';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdfExtendedContent } from 'views/reports/external/pdfGenerator/extended/ReportPdfExtendedContent';
import { reportsQueryService } from 'views/reports/reportsQueryService';
import { type ExternalReportHeaderCommon, type ReportsInitialDataset } from 'domain/reports/types';
import { REPORTS_ALL_WATERBODIES_LABEL, REPORTS_INDICATOR_STATS } from 'domain/reports/constants';
import { getAllWaterbodiesIndicatorStats, getExtendedTableData } from 'domain/reports/helpers';
import { isExternalReportExtendedTableDataValid } from 'domain/reports/typeguards';

interface WaterQualityDobuleDateReportsExternalDataProps {
  areaId: number;
  initialDataset: ReportsInitialDataset;
  selectedIndicator: ExtendedIndicator;
  reportInfo: ExternalReportHeaderCommon;
}

export const WaterQualityDobuleDateReportsExternalData = ({
  areaId,
  initialDataset,
  selectedIndicator,
  reportInfo,
}: WaterQualityDobuleDateReportsExternalDataProps) => {
  const { waterBodiesList: waterbodies } = waterRepository.useFetchWaterbodies(areaId);

  const selectedWaterbodyId = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const selectedWaterbodyLabel = selectedWaterbodyId
    ? waterbodies.find(({ id }) => String(id) === selectedWaterbodyId)?.name
    : REPORTS_ALL_WATERBODIES_LABEL;

  const { firstTimestamp, firstDate, firstScreenshotSrc, secondTimestamp, secondDate, secondScreenshotSrc } =
    initialDataset;

  const { waterQualityResults: firstDateWaterQualityResults } = waterRepository.useFetchWaterQualityResults(
    areaId,
    firstTimestamp,
    selectedIndicator.id,
  );

  const { waterQualityResults: secondDateWaterQualityResults } = waterRepository.useFetchWaterQualityResults(
    areaId,
    secondTimestamp,
    selectedIndicator.id,
  );

  const { indicatorStatsQuery } = reportsQueryService.useReportsIndicatorStats();

  const firstDateAllWaterbodiesStats = getAllWaterbodiesIndicatorStats(firstDateWaterQualityResults, selectedIndicator);
  const secondDateAllWaterbodiesStats = getAllWaterbodiesIndicatorStats(
    secondDateWaterQualityResults,
    selectedIndicator,
  );

  const firstDateSelectedWaterbodyStats = firstDateWaterQualityResults.find(
    ({ waterbody_id }) => String(waterbody_id) === selectedWaterbodyId,
  );

  const secondDateSelectedWaterbodyStats = secondDateWaterQualityResults.find(
    ({ waterbody_id }) => String(waterbody_id) === selectedWaterbodyId,
  );

  const data = REPORTS_INDICATOR_STATS.filter(({ id }) => indicatorStatsQuery?.includes(String(id)))
    .filter(({ isForAll }) => selectedWaterbodyId || isForAll)
    .map(({ id, name, label }) => {
      const firstDateSelectedWaterbodyStatsValue = firstDateSelectedWaterbodyStats
        ? firstDateSelectedWaterbodyStats[name]
        : null;

      const secondDateSelectedWaterbodyStatsValue = secondDateSelectedWaterbodyStats
        ? secondDateSelectedWaterbodyStats[name]
        : null;

      const first = selectedWaterbodyId
        ? firstDateSelectedWaterbodyStatsValue
        : firstDateAllWaterbodiesStats[name as keyof typeof firstDateAllWaterbodiesStats];

      const second = selectedWaterbodyId
        ? secondDateSelectedWaterbodyStatsValue
        : secondDateAllWaterbodiesStats[name as keyof typeof secondDateAllWaterbodiesStats];

      const { firstValue, secondValue, difference, trend } = getExtendedTableData(
        first,
        second,
        selectedIndicator.precision,
      );

      return {
        id,
        label: capitalizeAllFirstLetters(label),
        firstValue,
        secondValue,
        difference,
        trend,
      };
    });

  const validData = isExternalReportExtendedTableDataValid(data) ? data : [];

  const header = {
    ...reportInfo,
    reportType: 'Comparison Report',
    reportFunctionality: `Water Quality: ${selectedWaterbodyLabel}`,
    reportAdditionalLabel: selectedIndicator.indicator_name,
    dates: {
      first: firstDate,
      second: secondDate,
    },
  };

  const colorScale = {
    labels: selectedIndicator.labels,
    colormap: selectedIndicator.colormap,
    unit: selectedIndicator.unit,
  };

  const table = {
    tableHeader: `${selectedIndicator.indicator_name} Statistics For ${selectedWaterbodyLabel}`,
    dataHeader: 'Parameter Name',
    unit: selectedIndicator.unit,
    firstDate,
    secondDate,
    data: validData,
  };

  const screenshots = {
    first: {
      src: firstScreenshotSrc,
      label: `Date 1: ${firstDate}`,
    },
    second: {
      src: secondScreenshotSrc,
      label: `Date 2: ${secondDate}`,
    },
  };

  return (
    <>
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} colorScale={colorScale} />
          <ReportPdfExtendedContent screenshots={screenshots} table={table} />
        </>
      </ReportPdf>
    </>
  );
};
