import { type CSSProperties, type ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { type Palette } from 'ui/types';
import { Tooltip } from 'ui/atoms/tooltip';

export type IconButtonVariants =
  | 'PRIMARY'
  | 'SECONDARY'
  | 'LOCKED'
  | 'PRIMARY_STATIC'
  | 'SECONDARY_STATIC'
  | 'OVERVIEW'
  | 'OVERVIEW_NO_HOVER'
  | 'NOTIFICATION_NO_HOVER'
  | 'LIGHT'
  | 'NO_BORDER';

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right';

type ButtonStyles = Required<
  Pick<CSSProperties, 'color' | 'backgroundColor' | 'width' | 'height'> & {
    hoverColor?: CSSProperties['color'];
    hoverBackgroundColor?: CSSProperties['backgroundColor'];
    activeColor?: CSSProperties['color'];
    activeBackgroundColor?: CSSProperties['backgroundColor'];
    activeHoverColor?: CSSProperties['backgroundColor'];
    activeHoverBackgroundColor?: CSSProperties['backgroundColor'];
    disabledColor?: CSSProperties['color'];
    disabledBackgroundColor?: CSSProperties['backgroundColor'];
    static?: boolean;
  }
>;

const styles: Palette<IconButtonVariants, ButtonStyles> = {
  PRIMARY: {
    color: 'white',
    backgroundColor: 'primary',
    hoverColor: 'white',
    hoverBackgroundColor: 'primaryDark',
    activeColor: 'primary',
    activeBackgroundColor: 'white',
    activeHoverColor: 'primaryDark',
    activeHoverBackgroundColor: 'white',
    disabledColor: 'silver',
    disabledBackgroundColor: 'white',
    width: 32,
    height: 32,
    static: false,
  },
  SECONDARY: {
    color: 'white',
    backgroundColor: 'mineShaftABitLighter',
    hoverColor: 'white',
    hoverBackgroundColor: 'selectGray',
    activeColor: 'black',
    activeBackgroundColor: 'white',
    activeHoverColor: 'black',
    activeHoverBackgroundColor: 'silverDark',
    disabledColor: 'silver',
    disabledBackgroundColor: 'white',
    width: 30,
    height: 30,
    static: false,
  },
  LOCKED: {
    color: 'white',
    backgroundColor: 'mineShaftABitLighter',
    hoverColor: 'white',
    hoverBackgroundColor: 'selectGray',
    activeColor: 'white',
    activeBackgroundColor: 'primary',
    activeHoverColor: 'white',
    activeHoverBackgroundColor: 'primaryDark',
    disabledColor: 'silver',
    disabledBackgroundColor: 'white',
    width: 30,
    height: 30,
    static: false,
  },
  PRIMARY_STATIC: {
    color: 'black',
    backgroundColor: 'primary',
    hoverColor: 'black',
    hoverBackgroundColor: 'primary',
    activeColor: 'black',
    activeBackgroundColor: 'primary',
    activeHoverColor: 'black',
    activeHoverBackgroundColor: 'primary',
    disabledColor: 'black',
    disabledBackgroundColor: 'primary',
    width: 30,
    height: 30,
    static: true,
  },
  SECONDARY_STATIC: {
    color: 'mineShaftABitLighter',
    backgroundColor: 'silver',
    hoverColor: 'mineShaftABitLighter',
    hoverBackgroundColor: 'silver',
    activeColor: 'mineShaftABitLighter',
    activeBackgroundColor: 'silver',
    activeHoverColor: 'mineShaftABitLighter',
    activeHoverBackgroundColor: 'silver',
    disabledColor: 'mineShaftABitLighter',
    disabledBackgroundColor: 'silver',
    width: 30,
    height: 30,
    static: true,
  },
  OVERVIEW: {
    color: 'white',
    backgroundColor: 'selectGray',
    hoverColor: 'white',
    hoverBackgroundColor: 'mineShaftABitLighter',
    activeColor: 'lightGray',
    activeBackgroundColor: 'selectGray',
    activeHoverColor: 'lightGray',
    activeHoverBackgroundColor: 'selectGray',
    disabledColor: 'black',
    disabledBackgroundColor: 'gallery',
    width: 22,
    height: 22,
    static: false,
  },
  OVERVIEW_NO_HOVER: {
    color: 'lightGray',
    backgroundColor: 'selectGray',
    hoverColor: 'lightGray',
    hoverBackgroundColor: 'selectGray',
    activeColor: 'lightGray',
    activeBackgroundColor: 'selectGray',
    activeHoverColor: 'lightGray',
    activeHoverBackgroundColor: 'selectGray',
    disabledColor: 'black',
    disabledBackgroundColor: 'gallery',
    width: 22,
    height: 22,
    static: false,
  },
  NOTIFICATION_NO_HOVER: {
    color: 'notificationPink',
    backgroundColor: 'red',
    hoverColor: 'notificationPink',
    hoverBackgroundColor: 'red',
    activeColor: 'notificationPink',
    activeBackgroundColor: 'red',
    activeHoverColor: 'notificationPink',
    activeHoverBackgroundColor: 'red',
    disabledColor: 'black',
    disabledBackgroundColor: 'gallery',
    width: 20,
    height: 20,
    static: false,
  },
  LIGHT: {
    color: 'black',
    backgroundColor: 'white',
    hoverColor: 'black',
    hoverBackgroundColor: 'silverDark',
    activeColor: 'black',
    activeBackgroundColor: 'white',
    activeHoverColor: 'black',
    activeHoverBackgroundColor: 'silverDark',
    disabledColor: 'black',
    disabledBackgroundColor: 'silverDark',
    width: 22,
    height: 22,
    static: false,
  },
  NO_BORDER: {
    color: 'white',
    backgroundColor: 'transparent',
    hoverColor: 'silverDark',
    hoverBackgroundColor: 'transparent',
    activeColor: 'silverDark',
    activeBackgroundColor: 'transparent',
    activeHoverColor: 'silverDark',
    activeHoverBackgroundColor: 'transparent',
    disabledColor: 'selectGray',
    disabledBackgroundColor: 'transparent',
    width: 22,
    height: 22,
    static: false,
  },
};

export interface IconButtonProps {
  icon?: ReactNode;
  variant?: IconButtonVariants;
  disabled?: boolean;
  description?: string;
  tooltipContent?: ReactNode;
  tooltipPlacement?: TooltipPlacement;
  size?: number;
  $iconSize?: number;
  onClick?: () => void;
  onHover?: () => void;
  rotation?: number;
  active?: boolean;
  interactive?: boolean;
}

export const IconButton = ({
  variant,
  disabled,
  icon,
  description,
  tooltipContent,
  tooltipPlacement,
  onClick,
  onHover,
  size,
  $iconSize,
  rotation,
  active,
  interactive,
}: IconButtonProps) => {
  const buttonDescription = `${description?.replaceAll(' ', '-')}-button`;

  const iconButtonCore = (
    <StyledIconButton
      type="button"
      role="button"
      aria-label={buttonDescription}
      data-testid={buttonDescription}
      $variant={variant}
      size={size}
      disabled={!!disabled}
      active={!!active}
      onClick={onClick}
      onMouseEnter={onHover}
    >
      <IconWrapper size={$iconSize} rotation={rotation}>
        {icon}
      </IconWrapper>
    </StyledIconButton>
  );

  return (
    <div>
      {description ? (
        <Tooltip content={tooltipContent || description} placement={tooltipPlacement} interactive={!!interactive}>
          {iconButtonCore}
        </Tooltip>
      ) : (
        iconButtonCore
      )}
    </div>
  );
};

interface StyledIconButtonProps {
  $variant?: IconButtonVariants;
  disabled: boolean;
  active: boolean;
  size?: number;
}

const StyledIconButton = styled.button<StyledIconButtonProps>`
  display: grid;
  place-content: center;
  width: ${({ $variant, size }) => size || styles[$variant!].width}px;
  height: ${({ $variant, size }) => size || styles[$variant!].height}px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  color: ${({ theme, $variant }) => theme.color[styles[$variant!].color]};
  background-color: ${({ theme, $variant }) => theme.color[styles[$variant!].backgroundColor]};
  transition: background-color ${({ theme }) => theme.transitionFunction.main};

  &:hover {
    color: ${({ theme, $variant }) => theme.color[styles[$variant!].hoverColor]};
    background-color: ${({ theme, $variant }) => theme.color[styles[$variant!].hoverBackgroundColor]};
  }

  ${({ active, $variant }) =>
    active &&
    css`
      color: ${({ theme }) => theme.color[styles[$variant!].activeColor]};
      background-color: ${({ theme }) => theme.color[styles[$variant!].activeBackgroundColor]};

      &:hover {
        color: ${({ theme, $variant }) => theme.color[styles[$variant!].activeHoverColor]};
        background-color: ${({ theme, $variant }) => theme.color[styles[$variant!].activeHoverBackgroundColor]};
      }
    `}

  &:disabled {
    pointer-events: none;
    color: ${({ theme, $variant }) => theme.color[styles[$variant!].disabledColor]};
    background-color: ${({ theme, $variant }) => theme.color[styles[$variant!].disabledBackgroundColor]};
  }

  ${({ $variant }) =>
    styles[$variant!].static &&
    css`
      pointer-events: none;
    `}
`;

const IconWrapper = styled.div<{ size?: number; rotation?: number }>`
  width: ${({ size }) => size || 13}px;
  height: ${({ size }) => size || 13}px;
  transform: ${({ rotation }) => `rotate(${rotation}deg)`};
  transition: all ${({ theme }) => theme.transitionFunction.main};
  display: grid;
  place-items: center;

  svg {
    width: 100%;
    height: auto;
  }
`;

IconButton.defaultProps = {
  variant: 'SECONDARY' as IconButtonVariants,
  disabled: false,
  size: 30,
  iconSize: 13,
  active: false,
};

IconButton.displayName = 'IconButton';
