import { Text, View } from '@react-pdf/renderer';

import { ArrowUpPdf, ArrowDownPdf } from 'views/reports/external/pdfGenerator/components/ReportPdfIcons';
import { PdfTableValueColumn } from 'views/reports/external/pdfGenerator/components/PdfTableValueColumn';
import { PdfTableColorColumn } from 'views/reports/external/pdfGenerator/components/PdfTableColorColumn';
import { PdfTableBottomLine } from 'views/reports/external/pdfGenerator/components/PdfTableBottomLine';
import { commonStyles, tableStyles } from 'views/reports/external/pdfGenerator/styles';
import { type ExternalReportExtendedTable } from 'domain/reports/types';

export const ReportPdfExtendedTable = ({
  table: { tableHeader, dataHeader, unit, firstDate, secondDate, data },
}: {
  table: ExternalReportExtendedTable;
}) => (
  <View style={tableStyles.table}>
    <Text style={commonStyles.subheader}>{tableHeader}</Text>
    <View style={tableStyles.column_wrapper}>
      {data.some((item) => item.color) && <PdfTableColorColumn data={data} dataKey="color" />}
      <PdfTableValueColumn headerText={dataHeader} data={data} dataKey="label" fg1 />
      <PdfTableValueColumn
        headerText={`${firstDate} Value ( ${unit ? unit : '—'} )`}
        data={data}
        dataKey="firstValue"
        alignRight
      />
      <PdfTableValueColumn
        headerText={`${secondDate} Value ( ${unit ? unit : '—'} )`}
        data={data}
        dataKey="secondValue"
        alignRight
      />
      <PdfTableValueColumn headerText={`Change ( ${unit ? unit : '—'} )`} data={data} dataKey="difference" alignRight />
      <PdfTableValueColumn headerText="Trend" dataKey="trend" alignRight>
        {data.map((item, index) => {
          switch (item.trend) {
            case true:
              return <ArrowUpPdf key={index} style={{ height: 8, maxWidth: 10, alignSelf: 'flex-end' }} />;
            case false:
              return <ArrowDownPdf key={index} style={{ height: 8, maxWidth: 10, alignSelf: 'flex-end' }} />;
          }
          return (
            <Text key={index} style={{ textAlign: 'right', alignSelf: 'flex-end' }}>
              —
            </Text>
          );
        })}
      </PdfTableValueColumn>
      <PdfTableBottomLine length={data.length + 1} />
    </View>
  </View>
);
