import { type Datetime } from 'common/types/time';
import { sanitizeDate } from 'common/utils/datetime';
import { useDeleteQueryParameters, useReplaceQueryParameters } from 'common/navigation/hooks';
import { DATE_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const useTimestampChange = () => {
  const replaceQueryParameters = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeTimestamp = (timestamp: Datetime | null | undefined): void => {
    const sanitizedTimestamp = typeof timestamp === 'string' ? sanitizeDate(timestamp) : undefined;

    setTimeout(() => {
      sanitizedTimestamp
        ? replaceQueryParameters([{ key: DATE_QUERY_PARAMETER, value: sanitizedTimestamp }])
        : deleteQueryParameters([DATE_QUERY_PARAMETER]);
    });
  };

  return { changeTimestamp };
};
