import { VegetationMapContent } from './VegetationMapContent';
import { VegetationOtherProvidersMapContent } from './VegetationOtherProvidersMapContent';
import { useVegetationIndicators } from '../indicators/useVegetationIndicators';
import { useGreeneryDataProvider } from '../../useGreeneryDataProvider';
import { useGreeneryTimestamps } from '../../useGreeneryTimestamps';

export const VegetationMapContentSwitcher = () => {
  const { isSentinelProvider } = useGreeneryDataProvider();

  const { validTimestamp } = useGreeneryTimestamps();
  const { selectedIndicator } = useVegetationIndicators();

  if (!validTimestamp || !selectedIndicator) {
    return null;
  }

  if (isSentinelProvider) {
    return <VegetationMapContent />;
  }

  return <VegetationOtherProvidersMapContent timestamp={validTimestamp} indicator={selectedIndicator} />;
};
