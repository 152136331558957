import { useQueries, useQuery } from '@tanstack/react-query';
import { titilerApiService } from './titilerApiService';
import { areCogBandStatsValid, areCogStatsValid, isCogInfoValid } from './typeguards';
import { convertCogBandStatsToRange } from './utils';
import { COG_INFO_QUERY_KEY, COG_STATS_QUERY_KEY } from './constants';
import { type TitilerInfo } from './types';

export const titilerInfoService = {
  useGetInfoGeneric: (url: string) => {
    const { data, isLoading } = useQuery([COG_INFO_QUERY_KEY, url], () => titilerApiService.getInfoGeneric(url));

    const info = isCogInfoValid(data) ? data : undefined;

    return { info, isInfoLoading: isLoading };
  },

  useGetArrayInfo: (sets: { url: string; id: number }[]) => {
    const results = useQueries({
      queries: sets.map(({ url, id }) => ({
        queryKey: [COG_INFO_QUERY_KEY, id],
        queryFn: () => titilerApiService.getRichInfo(url, id),
      })),
    });

    return results;
  },

  useGetStatsGeneric: (
    url: string,
    props?: { expression?: string; range?: number[]; bins?: number; bidx?: number },
  ) => {
    const { data, isLoading } = useQuery([COG_STATS_QUERY_KEY, url, props], () =>
      titilerApiService.getStatsGeneric(url, props || {}),
    );

    const stats = areCogStatsValid(data) ? data : undefined;

    return { stats, isStatsLoading: isLoading };
  },

  useGetCogBandRange: (url: string, enabled: boolean, precision = 2, band = 1) => {
    const { data, isInitialLoading } = useQuery(
      [COG_STATS_QUERY_KEY, url, band],
      () => titilerApiService.getStatsGeneric(url, { bidx: band }),
      {
        enabled,
      },
    );

    const stats = data && Object.values(data);
    const bandStats = stats && Array.isArray(stats) && stats.length && stats[0];
    const validBandStats = bandStats && areCogBandStatsValid(bandStats) ? bandStats : null;
    const cogBandRange = validBandStats ? convertCogBandStatsToRange(validBandStats, precision) : null;

    return { cogBandRange, isLoading: isInitialLoading };
  },

  useGetCogsWithInfo: <T>(cogs: (T & { url: string })[]) => {
    const response = useQueries({
      queries: cogs.map((cog) => ({
        queryKey: [COG_INFO_QUERY_KEY, cog],
        queryFn: () => titilerApiService.useGetCogWithInfo(cog),
      })),
    });

    const cogsWithInfo = response
      .map((item) => item.data)
      .filter((item): item is { info: TitilerInfo; cog: T } => isCogInfoValid(item?.info));

    return { cogsWithInfo };
  },
};
