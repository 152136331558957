import { useEffect } from 'react';

import { useAoiNumber } from 'common/navigation/hooks';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useTimelineService } from 'components/Timeline/timelineService';

export const useVciResults = () => {
  const areaId = useAoiNumber();
  const { vciResults, vciResultsLoading: resultsLoading } = greeneryRepository.useGetVciResults(areaId);

  const { timestamp, changeTimestamp } = useTimelineService();

  const timestamps = vciResults.map(({ img_date }) => img_date);
  const currentResult = vciResults.find((result) => result.img_date === timestamp);

  useEffect(() => {
    if ((!timestamp || !timestamps.includes(timestamp)) && timestamps.length) {
      changeTimestamp(timestamps[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamps]);

  return {
    timestamps,
    currentResult,
    resultsLoading,
  };
};
