import { useLocation } from 'react-router-dom';

import { useAoiNumber } from 'common/navigation/hooks';
import {
  FoldableSection,
  NO_DATA_MESSAGE,
  TransientStateCheckbox,
  useFoldableSectionStatus,
  useLayerOrderStore,
} from 'components/layerManager';
import { LayerManagerLegendDndList } from 'components/layerManager/LegendDndListEnhanced';
import { INDICES_INFO_AREA_DESCRIPTION, INDICES_SLIDER_CONFIG } from 'domain/exploration/configs';
import { useIndices } from './useIndices';
import { useIndicesLayersVisibility } from './useIndicesLayersVisibility';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

interface IndicesLayerManagerProps {
  id: string;
}

export const IndicesLayerManager = ({ id }: IndicesLayerManagerProps) => {
  const areaId = useAoiNumber();
  const { pathname } = useLocation();
  const { indicesResults } = useIndices();
  const { nestedLayersOrderRecord, setNestedLayersOrderRecord } = useLayerOrderStore();

  const layersOrderRecord = nestedLayersOrderRecord
    ? nestedLayersOrderRecord[pathname]?.[EXPLORATION_LAYERS_KEYS.INDICES]
    : null;

  const setLayersOrderRecord = (layersOrder: number[]): void => {
    setNestedLayersOrderRecord(pathname, EXPLORATION_LAYERS_KEYS.INDICES, layersOrder);
  };

  const { classesVisibility, toggleMultipleQueries, updateClassesVisibility } = useIndicesLayersVisibility();

  const { description, areResultsAvailable } = useFoldableSectionStatus({
    defaultDescription: INDICES_INFO_AREA_DESCRIPTION,
    noDataMessage: NO_DATA_MESSAGE,
    areResultsAvailable: !!indicesResults.length,
  });

  return (
    <FoldableSection
      id={id}
      headerContent={
        <TransientStateCheckbox
          title="geological spectral indices"
          description={description}
          options={indicesResults}
          objectsVisibility={classesVisibility}
          updateLayersVisibility={updateClassesVisibility}
          disabled={!areResultsAvailable}
        />
      }
      disabled={!areResultsAvailable}
    >
      <LayerManagerLegendDndList
        areaId={areaId}
        layersOptions={indicesResults}
        layersVisibility={classesVisibility}
        toggleLayerVisibility={toggleMultipleQueries}
        sliderConfig={INDICES_SLIDER_CONFIG}
        layersOrderRecord={layersOrderRecord}
        setLayersOrderRecord={setLayersOrderRecord}
      />
    </FoldableSection>
  );
};
