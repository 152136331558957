import { type LayersVisibilityDictionary } from 'common/types';
import styled from 'styled-components';

import { Container, InfoPoint } from 'ui/atoms';
import { LabeledCheckbox } from 'ui/molecules';

type BasicProps = {
  title: string;
  description: string;
  isCheckboxChecked: boolean;
  disabled?: boolean;
};

type OptionalProps =
  | {
      variant: 'NORMAL';
      onCheckboxToggle: () => void;
    }
  | {
      variant: 'TRANSITIONAL';
      transitionalState: boolean;
      sameStateLayers: { [x: string]: boolean };
      updateLayersVisibility: (layers: LayersVisibilityDictionary) => void;
    };

export const FoldableSectionCheckbox = (props: BasicProps & OptionalProps) => {
  const { title, description, isCheckboxChecked, variant } = props;

  const isTransitional = variant === 'TRANSITIONAL';

  const onCheckboxToggle = isTransitional
    ? () => props.updateLayersVisibility(props.sameStateLayers)
    : props.onCheckboxToggle;

  return (
    <LabeledCheckbox
      variant={isTransitional && props.transitionalState ? 'WIDE_TRANSITIONAL' : 'WIDE'}
      onChange={props.disabled ? undefined : onCheckboxToggle}
      checked={isCheckboxChecked}
      disabled={props.disabled}
    >
      <Container gap={6}>
        {title}
        <InfoPointWrapper>
          <InfoPoint
            name={`${title.replaceAll(' ', '-')}-info`}
            tooltipContent={<span style={{ textTransform: 'none', opacity: 1 }}>{description}</span>}
            color="midGray"
          />
        </InfoPointWrapper>
      </Container>
    </LabeledCheckbox>
  );
};

const InfoPointWrapper = styled.div`
  pointer-events: all;
`;
