import { useEffect } from 'react';

import { useAoiNumber } from 'common/navigation/hooks';
import { useClustersLayerManagerStore } from './clustersLayerManagerStore';

export const useClearClustersData = () => {
  const areaId = useAoiNumber();

  const clearClustersData = useClustersLayerManagerStore.use.clearClustersData();

  useEffect(() => clearClustersData(), [areaId, clearClustersData]);
};
