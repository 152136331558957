import { DateSelect } from 'ui';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { useExtractionTimestamps } from 'views/mine/useExtractionTimestamps';
import { useTimelineService } from 'components/Timeline/timelineService';
import {
  REPORTS_CREATOR_COLUMN_INSUFFICIENT_DATA_LABEL,
  REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_NO_SELECT_DATE_LABEL,
  REPORTS_CREATOR_SINGLE_TYPE_COLUMN_DATE_LABEL,
} from 'domain/reports/constants';

export const ExtractionReportsCreatorDates = () => {
  const { timestamps, areTimestampsLoading } = useExtractionTimestamps();
  const { timestamp, changeTimestamp } = useTimelineService();

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  if (areTimestampsLoading) {
    return <LoadingDots />;
  }

  if (!timestamps.length || (isCompareDatesType && timestamps.length <= 1)) {
    return REPORTS_CREATOR_COLUMN_INSUFFICIENT_DATA_LABEL;
  }

  return isCompareDatesType ? (
    <div>{REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_NO_SELECT_DATE_LABEL}</div>
  ) : (
    <>
      <div>{REPORTS_CREATOR_SINGLE_TYPE_COLUMN_DATE_LABEL}</div>
      <DateSelect {...{ timestamps, timestamp, changeTimestamp }} />
    </>
  );
};
