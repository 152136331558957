import { useEffect, useState } from 'react';

import { isArrayOfType } from 'common/typeguards';
import { TO_FULL_DAY_OFFSET } from 'common/constants/datetime';
import { convertDatesToUnixTimestamps } from 'common/utils/datetime';

export const useRangeSliderService = (timestamps: string[]) => {
  const availableDates = convertDatesToUnixTimestamps(timestamps);
  const firstDate = availableDates[0];
  const lastDate = availableDates[availableDates.length - 1];
  const initialRange = [0, 100];
  const [rangeValue, setRangeValue] = useState<number[]>(initialRange);
  const [availableRange, setAvailableRange] = useState<number[]>([]);

  const getAvailableDateRangeParams = (availableDateRange: number[]) => {
    const firstDate = availableDateRange[0];
    const lastDate = availableDateRange[availableDateRange.length - 1];
    const dateRange = lastDate - firstDate;

    return { dateRange, firstDate, lastDate };
  };

  const calculateRealTimerange = (availableDateRange: number[], currentValue: number): number => {
    const { dateRange, firstDate } = getAvailableDateRangeParams(availableDateRange);

    return (currentValue / 100) * dateRange + firstDate;
  };

  const initializeAvailableRange = () => {
    if (isArrayOfType(availableDates, 'number')) {
      setAvailableRange([
        calculateRealTimerange(availableDates, initialRange[0]),
        calculateRealTimerange(availableDates, initialRange[1]),
      ]);
    }
  };

  useEffect(() => {
    initializeAvailableRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!timestamps.length]);

  const getSliderPosition = (availableDateRange: number[], currentValue: number): number => {
    const { dateRange } = getAvailableDateRangeParams(availableDateRange);

    return ((currentValue - availableDateRange[0]) / dateRange) * 100;
  };

  const updateRangeSliderValue = () => {
    if (isArrayOfType(availableRange, 'number')) {
      setRangeValue([
        getSliderPosition(availableDates, availableRange[0]),
        getSliderPosition(availableDates, availableRange[1]),
      ]);
    }
  };

  useEffect(() => {
    updateRangeSliderValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamps.length]);

  const getLimitedDateValue = (availableDateRange: number[], currentValue: number): number => {
    const { dateRange, firstDate } = getAvailableDateRangeParams(availableDateRange);

    return (currentValue / 100) * dateRange + firstDate;
  };

  const selectedRange = [
    getLimitedDateValue(availableDates, rangeValue[0]),
    getLimitedDateValue(availableDates, rangeValue[1]),
  ];

  const getInitialDates = (availableDates: number[] | undefined, selectedRange: number[] | undefined) => {
    if (selectedRange && selectedRange.length && availableDates) {
      const localSelectedDates = availableDates.filter(
        (date) => date >= selectedRange[0] && date <= selectedRange[1] + TO_FULL_DAY_OFFSET,
      );

      return localSelectedDates;
    }
  };

  const selectedTimestamps = getInitialDates(availableDates, selectedRange);

  return {
    rangeValue,
    setRangeValue,
    selectedTimestamps,
    firstDate,
    lastDate,
  };
};
