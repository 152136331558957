import { useGetAoiName } from 'common/hooks/getAoiName';
import { formatDate } from 'common/utils/datetime';
import { extractionRepository } from 'infrastructure/extraction/extractionRepository';
import { MineActivityIndexHexagons } from 'views/mine/MineActivityIndexHexagons';

interface MineActivityIndexProps {
  timestamp: string;
}

export const MineActivityIndex = ({ timestamp }: MineActivityIndexProps) => {
  const { areaName } = useGetAoiName();
  const date = formatDate(timestamp);

  const { result } = extractionRepository.useFetchMineActivityIndexResults(areaName, date);

  if (!result) {
    return null;
  }

  return <MineActivityIndexHexagons id={result.id} url={result.url} />;
};
