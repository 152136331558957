import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { type ValueAndLabel } from 'common/types';
import {
  useAoiNumber,
  useDeleteQueryParameters,
  useQueryParameter,
  useReplaceQueryParameters,
} from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER, FALSY_QUERY_PARAMETERS } from 'common/navigation/queryParams';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useWaterStore } from 'infrastructure/water/waterStore';
import { useMapAlign } from 'components/map/hooks';
import { usePointHistory } from 'components/graph/usePointHistory';
import { useGraphStore } from 'components/graph/graphStore';
import { mapWaterBodiesToOptionsHelper } from 'domain/water/helpers';

export const useWaterbodies = () => {
  const areaId = useAoiNumber();
  const location = useLocation();

  const { waterBodiesList, waterBodiesLoading } = waterRepository.useFetchWaterbodies(areaId);
  const waterBodyOptions = mapWaterBodiesToOptionsHelper(waterBodiesList);
  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const selectedWaterbody: ValueAndLabel | undefined = waterBodyOptions.find(
    ({ value }) => String(value) === waterbodyIdQuery,
  );

  const replaceQueryParameter = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeWaterbody = (option: ValueAndLabel): void => {
    FALSY_QUERY_PARAMETERS.includes(option.value)
      ? deleteQueryParameters([WATERBODY_QUERY_PARAMETER])
      : replaceQueryParameter([
          {
            key: WATERBODY_QUERY_PARAMETER,
            value: String(option.value),
          },
        ]);
  };

  const { clearPointHistoryUseCase } = usePointHistory();
  const clearDefaultView = useWaterStore.use.clearWaterBodyDefaultView();
  const resetGraphStore = useGraphStore.use.resetGraphStore();
  const alignMap = useMapAlign();

  const resetWaterbody = (): void => {
    clearPointHistoryUseCase();
    clearDefaultView();

    deleteQueryParameters([WATERBODY_QUERY_PARAMETER]);
    resetGraphStore();
    alignMap();
  };

  useEffect(() => {
    if (!waterBodyOptions || waterbodyIdQuery === 'undefined' || waterbodyIdQuery === 'null') {
      deleteQueryParameters([WATERBODY_QUERY_PARAMETER]);
    }
  }, [waterBodiesList, location]); // eslint-disable-line react-hooks/exhaustive-deps

  const { fetchWaterBodyDefaultView } = waterRepository.useFetchWaterBodyDefaultView();

  const getWaterbodyDefultView = () => {
    if (typeof waterbodyIdQuery === 'string') {
      fetchWaterBodyDefaultView(Number(waterbodyIdQuery));
    }
  };

  useEffect(() => {
    getWaterbodyDefultView();
  }, [waterbodyIdQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return { waterBodyOptions, selectedWaterbody, changeWaterbody, resetWaterbody, waterBodiesLoading };
};
