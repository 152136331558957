import styled from 'styled-components';

import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg';
import { ReactComponent as RoundedCross } from 'assets/svg/rounded-cross.svg';
import { Input } from 'ui/atoms/input';
import { IconWrapper } from 'ui/atoms/iconWrapper';

interface SearchItemProps {
  isSearchNeeded: boolean;
  rowHeight: number;
  query: string;
  onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchElement = ({ isSearchNeeded, rowHeight, query, onQueryChange }: SearchItemProps) => {
  return isSearchNeeded || query ? (
    <SearchItemWrapper rowHeight={rowHeight}>
      <IconWrapper iconWidth={14}>
        <SearchIcon />
      </IconWrapper>
      <Input
        $variant="BASIC"
        $borderRadius={0}
        $paddingLeft={6}
        $paddingRight={6}
        value={query}
        onChange={onQueryChange}
        placeholder="Search"
      />
      {query?.length ? (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <IconWrapper iconWidth={12} onClick={() => onQueryChange({ target: { value: '' } } as any)}>
          <RoundedCross />
        </IconWrapper>
      ) : (
        ''
      )}
    </SearchItemWrapper>
  ) : null;
};

const SearchItemWrapper = styled.div<{ rowHeight: number }>`
  display: flex;
  height: ${({ rowHeight }) => rowHeight}px;
  padding: 0 24px 0 12px;
`;
