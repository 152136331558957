import { type ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'ui/atoms/button';

interface CookieBannerProps {
  isBannerOpen: boolean;
  handleClose: () => void;
  children: ReactNode;
  buttonText?: string;
}

export const CookieBanner = ({ isBannerOpen, handleClose, children, buttonText }: CookieBannerProps) => {
  return (
    <StyledCookieBanner isOpen={isBannerOpen}>
      <CookieBannerContent>
        <p>{children}</p>
        <Button variant="PRIMARY_REVERSE" onClick={handleClose} minWidth={120} squared>
          {buttonText || 'Close'}
        </Button>
      </CookieBannerContent>
    </StyledCookieBanner>
  );
};

interface StyledCookieBannerProps {
  isOpen: boolean;
}

const StyledCookieBanner = styled.div<StyledCookieBannerProps>`
  min-height: 90px;
  position: absolute;
  bottom: -120px;
  z-index: 20000;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.primary};
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      bottom: 0;
      opacity: 1;
      visibility: visible;
    `}
`;

const CookieBannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;

  p {
    margin: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.color.white};
  }
`;
