import { PUBLIC_CONTAINER_URL } from 'common/constants/services';

export const AREAS_API_URLS = {
  getAreasOfInterest: '/areas/area-of-interest/',
  getAreaOfInterestById: '/areas/area-of-interest/:areaId/',
  getAreaOfInterestSatellite: '/areas/area-of-interest/:areaId/get_cog/',
  // getAreaOfInterestShapes: '/areas/area-of-interest/shapes/',
  getAreaOfInterestShapes: `${PUBLIC_CONTAINER_URL}/shapes/shapes.json`, // TODO: restore line above this line when the API is ready
  // getTopographyLayers: '/areas/area-of-interest/:areaId/topography/',
  getTopographyLayers: `${PUBLIC_CONTAINER_URL}/dem/topography.json`, // TODO: restore line above this line when the API is ready
};
