import { useQuery } from '@tanstack/react-query';

import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import {
  COMPOSITES_INITIAL_DATA_CACHE_TIME,
  COMPOSITES_INITIAL_DATA_STALE_TIME,
  COMPOSITES_PROVIDERS_QUERY_KEY,
  COMPOSITES_RESULTS_QUERY_KEY,
} from 'infrastructure/composites/constants';
import { compositesAPI } from 'infrastructure/composites/compositesApiAdapter';
import {
  isCompositesProvidersApiResponseValid,
  isCompositesResultsApiResponseValid,
} from 'domain/composites/typeguards';

export const compositesRepository = {
  useFetchProviders: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        COMPOSITES_PROVIDERS_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => compositesAPI.getProviders({ limit, offset }),
      {
        staleTime: COMPOSITES_INITIAL_DATA_STALE_TIME,
        cacheTime: COMPOSITES_INITIAL_DATA_CACHE_TIME,
      },
    );

    const compositesProviders = isCompositesProvidersApiResponseValid(data) ? data.data.results : [];

    return {
      compositesProviders,
      areProvidersLoading: isLoading,
    };
  },

  useFetchResults: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        COMPOSITES_RESULTS_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => compositesAPI.getResults({ limit, offset }),
    );

    const compositesResults = isCompositesResultsApiResponseValid(data) ? data.data.results : [];

    return {
      compositesResults,
      areCompositesResultsLoading: isLoading,
    };
  },
};
