const scriptURL = window.location.origin + '/service-worker.js';

export async function registerServiceWorker() {
  if (!('serviceWorker' in navigator)) {
    return console.warn('Service worker is not supported');
  }
  try {
    // const registrations = await navigator.serviceWorker.getRegistrations()
    // for (let registration of registrations) {
    //   await registration.unregister()
    // }

    navigator.serviceWorker
      .register(scriptURL)
      .then(
        function (registration) {
          console.log('ServiceWorker registration success');
          registration.update();
        },
        function (err) {
          console.warn('ServiceWorker registration failed: ', err);
        },
      )
      .catch(function (err) {
        console.error('ServiceWorker file registration failed: ', err);
      });
  } catch (error) {
    console.warn(error);
  }
}
