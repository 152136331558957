import { useAoiNumber } from 'common/navigation/hooks';
import { useTimelineService } from 'components/Timeline/timelineService';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useWaterViewOptions } from '../viewOptions';

export const useWaterAreaResults = (date?: string | null, waterbodyId?: number) => {
  const areaId = useAoiNumber();
  const { timestamp } = useTimelineService();

  const { waterAreaResults, waterAreaResultsLoading } = waterRepository.useFetchWaterAreaResults(
    date || timestamp || undefined,
    areaId,
    waterbodyId,
  );
  const waterAreaResultIds = waterAreaResults.map(({ id }) => id);

  const { waterAreaCogsResponse, waterAreaCogsLoading } =
    waterRepository.useFetchWaterAreaCogsResponse(waterAreaResultIds);

  const cogResultUrls = waterAreaCogsResponse.map((item) => ({
    cog_url: item.data?.data.url || '',
    id: item.data?.data.id as number,
  }));

  const { waterAreaMockedResults, waterAreaMockedResultsLoading } = waterRepository.useFetchWaterAreaResultsMocked(
    date || timestamp || undefined,
    areaId,
    waterbodyId,
  );
  const waterAreaMockedResultCogs = waterAreaMockedResults.map(({ id, cog_url }) => ({
    id,
    cog_url,
  }));

  const { mapViewOption } = useWaterViewOptions();

  const isComparisonView = mapViewOption?.value === 'comparison';

  const waterAreaMergedResults = [...waterAreaResults, ...(!isComparisonView ? waterAreaMockedResults : [])];

  const waterAreaMergedCogsResponse = [...cogResultUrls, ...(!isComparisonView ? waterAreaMockedResultCogs : [])];

  return {
    waterAreaResults: waterAreaMergedResults,
    waterAreaCogsResponse: waterAreaMergedCogsResponse,
    areWaterAreaResultsLoading: waterAreaResultsLoading || waterAreaMockedResultsLoading || waterAreaCogsLoading,
  };
};
