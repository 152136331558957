import { ROUTES } from 'common/navigation/routes';
import { theme } from 'common/theme';
import { AreaEnvironments } from 'common/navigation/constants';
import { ReportFunctionality, ReportType } from 'domain/reports/enums';
import { type ReportsCreatorTypeOption, type ReportsIndicatorStats } from 'domain/reports/types';

export const REPORTS_INFO_AREA_DESCRIPTION =
  'Generate report by selecting the options below. First, be conscious of map visibility - map boundaries on screen define the exported map inside the report. Press the “Hide Menus” button on the right side of the screen to reveal the full map. After positioning the map make your choices in selection menu and generate your report.';

export const REPORTS_INDICATOR_STATS: ReportsIndicatorStats[] = [
  { id: 1, name: 'avg_value', label: 'average', isForAll: false },
  { id: 2, name: 'min_value', label: 'minimum', isForAll: true },
  { id: 3, name: 'max_value', label: 'maximum', isForAll: true },
  { id: 4, name: 'std_dev', label: 'standard deviation', isForAll: false },
];

export const REPORTS_ALL_WATERBODIES_LABEL = 'All Waterbodies Together';

export const REPORTS_SECOND_SCREENSHOT_DELAY = 5_000;

export const REPORTS_CREATOR_COLUMN_INSUFFICIENT_DATA_LABEL = 'Insufficient data to generate report.';
export const REPORTS_CREATOR_SINGLE_TYPE_COLUMN_DATE_LABEL = 'Select one date to start.';
export const REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_SELECT_DATE_LABEL = 'Select two dates to start a comparison.';
export const REPORTS_CREATOR_DOUBLE_TYPE_COLUMN_NO_SELECT_DATE_LABEL =
  'Begin by selecting the first date with desired classes, then follow with the next date.';

export const REPORTS_CREATOR_OPTIONS: ReportsCreatorTypeOption[] = [
  {
    id: 1,
    name: ReportType.COMPARE_DATES,
    label: 'compare dates',
    options: [
      {
        id: 1,
        name: ReportFunctionality.SEGMENTATION,
        label: 'land cover',
        navigateTo: ROUTES.landCover,
        environment: AreaEnvironments.LAND_COVER,
      },
      {
        id: 2,
        name: ReportFunctionality.VEGETATION,
        label: 'vegetation',
        navigateTo: ROUTES.vegetation,
        environment: AreaEnvironments.LAND_COVER,
      },
      {
        id: 3,
        name: ReportFunctionality.SURFACE,
        label: 'water - surface area',
        navigateTo: ROUTES.waterArea,
        environment: AreaEnvironments.WATER,
      },
      {
        id: 4,
        name: ReportFunctionality.QUALITY,
        label: 'water - quality',
        navigateTo: ROUTES.waterQuality,
        environment: AreaEnvironments.WATER,
      },
      {
        id: 5,
        name: ReportFunctionality.TERRAIN,
        label: 'modified terrain',
        navigateTo: ROUTES.terrain,
        environment: AreaEnvironments.MINE,
      },
    ],
  },
  {
    id: 2,
    name: ReportType.SINGLE_DATE,
    label: 'one date focus',
    options: [
      {
        id: 1,
        name: ReportFunctionality.SEGMENTATION,
        label: 'land cover',
        navigateTo: ROUTES.landCover,
        environment: AreaEnvironments.LAND_COVER,
      },
      {
        id: 2,
        name: ReportFunctionality.VEGETATION,
        label: 'vegetation',
        navigateTo: ROUTES.vegetation,
        environment: AreaEnvironments.LAND_COVER,
      },
      {
        id: 3,
        name: ReportFunctionality.SURFACE,
        label: 'water - surface area',
        navigateTo: ROUTES.waterArea,
        environment: AreaEnvironments.WATER,
      },
      {
        id: 4,
        name: ReportFunctionality.QUALITY,
        label: 'water - quality',
        navigateTo: ROUTES.waterQuality,
        environment: AreaEnvironments.WATER,
      },
      {
        id: 5,
        name: ReportFunctionality.TERRAIN,
        label: 'modified terrain',
        navigateTo: ROUTES.terrain,
        environment: AreaEnvironments.MINE,
      },
    ],
  },
];

export const EXTERNAL_REPORT_SURFACE_AREA_UNIT = 'ha';
export const EXTERNAL_REPORT_EXTRACTION_SPECIAL_CLASS_IDS = [20];

export const EXTERNAL_REPORT_OTHER_TABLE_CLASS = {
  label: 'Other',
  color: theme.color.suvaGray,
};

export const EXTERNAL_REPORT_UNCLASSIFIED_TABLE_CLASS = {
  label: 'Unclassified',
  color: theme.color.silverDark,
};
