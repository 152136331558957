import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';

import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useReportsStore } from 'infrastructure/reports/reportsStore';

const snapshotOptions = {
  hidden: true,
};

const format = 'image';

const overridedPluginOptions = {
  mimeType: 'image/jpeg',
};

export const Screenshoter = () => {
  const setReportScreenshotSrc = useReportsStore.use.setReportScreenshotSrc();
  const setTakeScreenshotFlag = useReportsStore.use.setTakeScreenshotFlag();

  const map = useMap();
  const screenshoter = new SimpleMapScreenshoter(snapshotOptions);
  screenshoter.addTo(map);

  const closeLayerManager = useLayerManagerStore.use.closeLayerManager();

  useEffect(() => {
    closeLayerManager();

    screenshoter
      .takeScreen(format, overridedPluginOptions)
      .then((src) => {
        setReportScreenshotSrc(String(src));
      })
      .catch((e: Error) => {
        console.error(e);
      })
      .finally(() => {
        setTakeScreenshotFlag(false);
      });
    // eslint-disable-next-line
  }, []);

  return null;
};
