import { formatDate } from 'common/utils/datetime';
import { type ExtendedIndicator } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { capitalizeAllFirstLetters, simpleRound } from 'common/utils';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdfSimpleContent } from 'views/reports/external/pdfGenerator/simple/ReportPdfSimpleContent';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { reportsQueryService } from 'views/reports/reportsQueryService';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';
import { type GreeneryIndicatorStats } from 'domain/greenery/types';
import { REPORTS_INDICATOR_STATS } from 'domain/reports/constants';

interface VegetationSingleDateReportsExternalDataProps {
  areaId: number;
  timestamp: string;
  selectedIndicator: ExtendedIndicator;
  selectedSegmentationClass: LayerObject;
  selectedIndicatorStats: GreeneryIndicatorStats;
  reportInfo: ExternalReportHeaderCommon;
}

export const VegetationSingleDateReportsExternalData = ({
  timestamp,
  selectedIndicator,
  selectedSegmentationClass,
  selectedIndicatorStats,
  reportInfo,
}: VegetationSingleDateReportsExternalDataProps) => {
  const { indicatorStatsQuery } = reportsQueryService.useReportsIndicatorStats();

  const reportScreenshotSrc = useReportsStore.use.reportScreenshotSrc();

  const data = REPORTS_INDICATOR_STATS.filter(({ id }) => indicatorStatsQuery?.includes(String(id))).map(
    ({ id, name, label }) => {
      return {
        id,
        label: capitalizeAllFirstLetters(label),
        value: simpleRound(selectedIndicatorStats[name], selectedIndicator.precision),
      };
    },
  );

  const selectedIndicatorLabel = selectedIndicator.indicator_name;
  const selectedSegmentationClassLabel = selectedSegmentationClass.label_name;

  const header = {
    ...reportInfo,
    reportType: 'Single Date Report',
    reportFunctionality: `Vegetation: ${selectedSegmentationClassLabel}`,
    reportAdditionalLabel: selectedIndicatorLabel,
    dates: {
      first: formatDate(timestamp),
    },
  };

  const colorScale = {
    labels: selectedIndicator.labels,
    colormap: selectedIndicator.colormap,
    unit: selectedIndicator.unit,
  };

  const table = {
    tableHeader: `${selectedIndicatorLabel} Statistics For ${selectedSegmentationClassLabel}`,
    dataHeader: 'Parameter Name',
    unit: selectedIndicator.unit,
    data,
  };

  if (!reportScreenshotSrc) {
    return null;
  }

  return (
    <>
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} colorScale={colorScale} />
          <ReportPdfSimpleContent screenshotScr={reportScreenshotSrc} table={table} />
        </>
      </ReportPdf>
    </>
  );
};
