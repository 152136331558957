import { type ColormapDictionary } from 'common/types';

export const GREENERY_COMPARATOR_LOSS_COLOR = '#FF0000';
export const GREENERY_COMPARATOR_NO_CHANGE_COLOR = '#C3C3C3';
export const GREENERY_COMPARATOR_GAIN_COLOR = '#00966A';

export const VEGETATION_COMPARISON_COLORMAP: ColormapDictionary = {
  0: '#FF0000',
  0.17: '#E46463',
  0.33: '#D19394',
  0.5: '#C3C3C3',
  0.67: '#86B7AE',
  0.83: '#51AA95',
  1: '#00966A',
};

export const MULTILAYER_SEGMENTATION_COLORMAP: ColormapDictionary = {
  0: GREENERY_COMPARATOR_LOSS_COLOR,
  0.5: GREENERY_COMPARATOR_NO_CHANGE_COLOR,
  1: GREENERY_COMPARATOR_GAIN_COLOR,
};

export const VCI_COLORMAP: ColormapDictionary = {
  0: '#E91E1C',
  0.17: '#F17B3C',
  0.33: '#F8C958',
  0.5: '#FCFF6B',
  0.67: '#A5D355',
  0.83: '#65B245',
  1: '#168A31',
};

export const GREENERY_OBJECTS_REPORT_COLORMAP = [
  {
    id: 3,
    color: '#5A9201',
  },
  {
    id: 4,
    color: '#7ECB00',
  },
  {
    id: 5,
    color: '#E99A00',
  },
  {
    id: 8,
    color: '#00BFFC',
  },
  {
    id: 9,
    color: '#0045F8',
  },
  {
    id: 10,
    color: '#00BDB2',
  },
  {
    id: 11,
    color: '#007C97',
  },
  {
    id: 12,
    color: '#00AA3A',
  },
];

export const BIOMASS_COLORMAP: ColormapDictionary = {
  0: '#000000',
  1: '#00ff00',
};

export const HABITATS_MAP_EXPANDED_AREA_LAYER_COLOR = '#FFF';
export const HABITATS_MAP_OCCURENCE_LAYER_COLOR = '#FFF';
export const HABITATS_MAP_PREDICTION_LAYER_COLOR = '#2A76FC';
