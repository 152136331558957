import styled from 'styled-components';

interface TextAreaProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  autoComplete?: string;
}

export const TextArea = ({ value, onChange, placeholder, readOnly, required, autoComplete = 'off' }: TextAreaProps) => (
  <StyledTextArea
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    readOnly={readOnly}
    required={required}
    autoComplete={autoComplete}
  />
);

export const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 10px;
  min-height: 124px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: ${({ theme }) => theme.color.lightGray};
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.color.lightGray};
  caret-color: ${({ theme }) => theme.color.lightGray};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.lightGray};
  }
`;

TextArea.displayName = 'TextArea';
