import styled from 'styled-components';

import { useCheckoutSessionCleanup } from 'common/hooks/useCheckoutSessionCleanup';
import { isBoundsArray } from 'common/typeguards';
import { Layer, ViewUpdater } from 'components/map';
import { useUserStore } from 'infrastructure/user/userStore';
import { AreaRequestPreview } from 'ui/infoArea/AreaRequestPreview';
import { DrawRectangle } from 'ui/map/areaSelector/DrawRectangle';
import { Polygon } from 'ui/map/areaSelector/Polygon';
import 'ui/map/areaSelector/styleOverrideCommon.css';
import 'ui/map/areaSelector/styleOverrideView.css';
import { GeocodeSearch } from 'ui/organisms/geocodeSearch';
import { useGeocodeSearch } from 'ui/organisms/geocodeSearch/useGeocodeSearch';
import { Sidebar } from 'ui/sidebar';
import { AppLayout } from 'layout/appLayout/AppLayout';
import { Map } from 'components/map/Map';
import { AreaRequestCreator } from 'views/areas/areaRequestNew/AreaRequestCreator';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { DeleteConfirmationPopup } from 'views/areas/areaRequestNew/DeleteConfirmationPopup';
import { DrawControlButtons } from 'views/areas/areaRequestNew/DrawControlButtons';
import { BaseMapVariant } from 'ui/map/baseMap/enums';
import { type AoiType, type SourceApp } from 'domain/areas/types';
import { DrawPolygon } from './DrawPolygon';

export const AreaRequestNew = () => {
  const features = useAreaRequestStore.use.features();
  const setFeatures = useAreaRequestStore.use.setFeatures();
  const searchResult = useAreaRequestStore.use.searchResult();
  const setSearchResult = useAreaRequestStore.use.setSearchResult();
  const resetSearchResult = useAreaRequestStore.use.resetSearchResult();
  const drawMode = useAreaRequestStore.use.drawMode();
  const status = useAreaRequestStore.use.selectionStatus();
  const areaName = useAreaRequestStore.use.areaName();
  const { userPermissions } = useUserStore();

  useCheckoutSessionCleanup();

  const geocodeSearchProps = useGeocodeSearch(setSearchResult);

  if (!userPermissions) return null;

  const cleanupPositionOnMapZoomEnd = () => resetSearchResult();

  const initialAoiData = {
    source: 'Terraeye' as SourceApp,
    name: areaName.toLowerCase().replace(/\s/g, '-'),
    display_name: areaName,
    user_id: userPermissions.id,
    aoi_type: 'prospecting' as AoiType,
    payload: features[0],
  };

  const viewUpdatedDefaultView = searchResult
    ? isBoundsArray(searchResult)
      ? searchResult
      : {
          lat: searchResult.lat,
          lon: searchResult.lon,
          zoom: 10,
        }
    : undefined;

  return (
    <AppLayout sidebar={<Sidebar variant="FOLDED" />}>
      <ToolbarWrapper>
        <AreaRequestPreview features={features} initialVisible={false} />
        <GeocodeSearch {...geocodeSearchProps} />
      </ToolbarWrapper>
      <DrawControlButtons />
      <AreaRequestCreator initialAoiData={initialAoiData} />
      <DeleteConfirmationPopup />
      <Map mapVariant={BaseMapVariant.SATELLITE}>
        <Layer name="verticesLayer" zIndex={560}>
          <DrawRectangle enabled={drawMode === 'rectangle'} setFeatures={setFeatures} alert={'invalidArea' in status} />
          <DrawPolygon enabled={drawMode === 'polygon'} />
          {features.map((feature) => (
            <Polygon
              key={`${feature.id}-preview`}
              coordinates={feature.geometry.coordinates[0]}
              minLength={3}
              onVertexClick={console.log}
              alert={'invalidArea' in status}
            />
          ))}
        </Layer>
        {searchResult && (
          <ViewUpdater defaultView={viewUpdatedDefaultView} animate cleanupAction={cleanupPositionOnMapZoomEnd} />
        )}
      </Map>
    </AppLayout>
  );
};

const ToolbarWrapper = styled.div`
  background: transparent;
  display: flex;
  height: 50px;
  padding: 16px 24px;
  gap: 10px;
`;
