import { quarterlyTimelineService } from 'views/greenery/quarterly/quarterlyTimelineService';
import { useUpdateQuarterlyComparisonCogsUseCase } from 'views/greenery/quarterly/updateQuarterlyComparisonCogsUseCase';
import { QuarterlyComparator } from 'views/greenery/quarterly/QuarterlyComparator';
import { useTimeline } from 'components/Timeline/useTimeline';
import { Graph } from 'components/graph';
import { Timeline } from 'components/Timeline/Timeline';
import { useAoiNumber } from 'common/navigation/hooks';

interface QuarterlyTimelineProps {
  isComparisonActive: boolean;
  areReportsActive: boolean;
  comparatorLocked: boolean;
  setComparatorLocked: (locked: boolean) => void;
}

export const QuarterlyTimeline = ({
  isComparisonActive,
  areReportsActive,
  comparatorLocked,
  setComparatorLocked,
}: QuarterlyTimelineProps) => {
  const areaId = useAoiNumber();

  const { timelineData, comparatorData } = quarterlyTimelineService.useQuarterlyTimeline(areaId, isComparisonActive);

  const { onFirstTimestampChange, onSecondTimestampChange } = quarterlyTimelineService.useTimestampsChange();

  const { updateQuarterlyComparatorCogs } = useUpdateQuarterlyComparisonCogsUseCase(areaId);

  const {
    filteredTimestamps,
    firstDate,
    lastDate,
    rangeValue,
    selectedTimestamps,
    setRangeValue,
    isTimelineShowed,
    firstTimestamp,
    lastTimestamp,
  } = useTimeline(timelineData.timestamps, timelineData.visible);

  return (
    <>
      {isComparisonActive && !areReportsActive && (
        <QuarterlyComparator
          comparatorData={comparatorData}
          setFirstComparedDate={onFirstTimestampChange}
          setSecondComparedDate={onSecondTimestampChange}
          onGenerate={updateQuarterlyComparatorCogs}
          locked={comparatorLocked}
          setLocked={setComparatorLocked}
        />
      )}
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          quarterly: true,
          visible: timelineData.visible,
        }}
      />
      {!areReportsActive && (
        <Graph
          isTimelineVisible={isTimelineShowed}
          dateRange={{ firstDate: firstTimestamp, lastDate: lastTimestamp }}
        />
      )}
    </>
  );
};
