import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type PointInfo, type SingleBandPointInfo } from 'services/titiler/types';
import { type ExplorationLayer, type GradientModeStateDictionary } from 'domain/exploration/types';

interface ExplorationLayerManagerState {
  gradientModeState: GradientModeStateDictionary;
  setGradientModeState: (classifierId: number, mode: boolean) => void;
  toggleGradientMode: (classifierId: number) => void;
  mineralIndicatorRange: number[];
  setMineralIndicatorRange: (range: number[]) => void;
  explorationLayersPointInfo: PointInfo<ExplorationLayer, SingleBandPointInfo>[];
  setExplorationLayersPointInfo: (info: PointInfo<ExplorationLayer, SingleBandPointInfo>[]) => void;
}

const initialState = {
  gradientModeState: {},
  mineralIndicatorRange: [0, 1],
  explorationLayersPointInfo: [],
};

const useExplorationLayerManagerStoreBase = create<ExplorationLayerManagerState>()(
  devtools(
    persist(
      (set) => ({
        gradientModeState: initialState.gradientModeState,
        setGradientModeState: (classifierId: number, mode: boolean) =>
          set(
            (state) => {
              const newState = { ...state.gradientModeState };
              newState[classifierId] = mode;

              return { gradientModeState: newState };
            },
            false,
            'layerManager/setGradientModeState',
          ),
        toggleGradientMode: (classifierId: number) =>
          set(
            (state) => {
              const newState = { ...state.gradientModeState };
              newState[classifierId] = !newState[classifierId];

              return { gradientModeState: newState };
            },
            false,
            'layerManager/toggleGradientMode',
          ),
        mineralIndicatorRange: initialState.mineralIndicatorRange,
        setMineralIndicatorRange: (range: number[]) =>
          set({ mineralIndicatorRange: range }, false, 'layerManager/setMineralIndicatorRange'),
        explorationLayersPointInfo: initialState.explorationLayersPointInfo,
        setExplorationLayersPointInfo: (info: PointInfo<ExplorationLayer, SingleBandPointInfo>[]) =>
          set({ explorationLayersPointInfo: info }, false, 'layerManager/setExplorationLayersPointInfo'),
      }),
      { name: 'RSOM_explorationLayerManagerStore', storage: createJSONStorage(() => localStorage) },
    ),
    { name: 'explorationLayerManagerStore' },
  ),
);

export const useExplorationLayerManagerStore = createSelectors(useExplorationLayerManagerStoreBase);
