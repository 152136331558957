import { useEffect } from 'react';

import { type ValueAndLabel } from 'common/types';
import { mapIndicatorsToOptions } from 'common/utils';
import { Select } from 'ui';
import { useSnowCoverIndicators } from 'views/water/snow/useSnowCoverIndicators';

export const SnowCoverIndicatorsSelect = () => {
  const { selectedIndicator, indicators, areIndicatorsLoading, changeIndicator } = useSnowCoverIndicators();

  const options = mapIndicatorsToOptions(indicators);
  const selectedOption = options.find(({ value }) => value === selectedIndicator?.id);

  const indicatorsSelectData = {
    options,
    selectedOption,
    initialStateLabel: 'Select Indicator',
    noOptionsLabel: 'No Indicators',
    name: 'snow cover indicator selection',
    title: '',
    isFetching: areIndicatorsLoading,
  };

  useEffect(() => {
    if (!selectedOption && options.length) {
      changeIndicator(options[0].value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption?.value, options.length]);

  const handleIndicatorChange = (option: ValueAndLabel) => {
    changeIndicator(option.value);
  };

  return <Select {...indicatorsSelectData} onChange={handleIndicatorChange} />;
};
