import { DEFAULT_DATE_FORMAT_DASH } from 'common/constants/datetime';
import { useAoiNumber } from 'common/navigation/hooks';
import { roundNumber } from 'common/utils';
import { formatDate } from 'common/utils/datetime';
import { calculatePolygonCenter, getDisplayableMapPoint } from 'common/utils/map';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { AreaInfoDrawer } from './AreaInfoDrawer';

interface Props {
  children?: React.ReactNode;
}

export const AreaInfo = ({ children }: Props) => {
  const areaId = useAoiNumber();

  const { areaOfInterestDetails: aoiDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  if (!aoiDetails) {
    return null;
  }

  const label = aoiDetails.properties.display_name;
  const environments = aoiDetails.properties.environments;
  const type = aoiDetails.properties.type;
  const location = getDisplayableMapPoint(calculatePolygonCenter(aoiDetails.geometry.coordinates[0]));
  const size = roundNumber(aoiDetails.properties.area, 2);
  const createdDate = formatDate(aoiDetails.properties.created_date, DEFAULT_DATE_FORMAT_DASH);
  const description = aoiDetails.properties.description;

  return (
    <AreaInfoDrawer {...{ label, environments, type, location, size, createdDate, description }}>
      {children}
    </AreaInfoDrawer>
  );
};
