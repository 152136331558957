import { type AxiosResponse } from 'axios';

import { getApiData } from 'services/rest/restApiService';
import { type HotspotTypeApiResponse } from 'domain/hotspots/types';
import { HOTSPOTS_API_URLS } from './hotpotsApiUrls';

export const hotspotsApi = {
  getObjectTypes: ({
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  }): Promise<AxiosResponse<HotspotTypeApiResponse>> => getApiData(HOTSPOTS_API_URLS.getObjectTypes, { limit, offset }),
};
