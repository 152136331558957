import { useEffect, useMemo } from 'react';

import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { useIsTrialUser } from 'views/user/useIsTrialUser';
import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import {
  calculatePointsForArea,
  getOptionButtonCoverageAnnotationHelper,
  getOptionButtonPermanentDisabledStateHelper,
  getOptionButtonTopInfoHelper,
  getOptionButtonWarningStateHelper,
} from 'domain/areas/helpers';
import { type UserSubscriptionOption } from 'domain/subscription/types';
import { useGetAvailableQuota } from './useGetAvailableQuota';

export const useSubscriptions = () => {
  const isTrialUser = useIsTrialUser();
  const selectedAreaInMeters = useAreaRequestStore.use.area();
  const selectedSubscription = useAreaRequestStore.use.selectedSubscription();
  const setSelectedSubscription = useAreaRequestStore.use.setSelectedSubscription();

  const { subscriptionProductsList } = subscriptionRepository.useGetProductsList();
  const { activeSubscriptionData } = subscriptionRepository.useGetStatus();

  const activeSubscriptionName = activeSubscriptionData ? activeSubscriptionData.name : undefined;
  const activeSubscriptionCapacity = activeSubscriptionData ? activeSubscriptionData.capacity : undefined;
  const availableAreaQuota = useGetAvailableQuota(activeSubscriptionCapacity);

  const subscriptionOptions: UserSubscriptionOption[] = subscriptionProductsList.map((subscription) => {
    const { label, name, area_quota, info, price } = subscription;

    const topInfo = getOptionButtonTopInfoHelper(activeSubscriptionName, activeSubscriptionCapacity, name, area_quota);

    const disabledPermanently = getOptionButtonPermanentDisabledStateHelper(
      isTrialUser,
      activeSubscriptionName,
      name,
      topInfo,
    );

    const pointsForselectedArea = selectedAreaInMeters && calculatePointsForArea(selectedAreaInMeters, 1);

    const warning =
      !disabledPermanently &&
      getOptionButtonWarningStateHelper(
        activeSubscriptionName,
        name,
        area_quota,
        pointsForselectedArea,
        availableAreaQuota,
      );

    return {
      name,
      label,
      annotation: getOptionButtonCoverageAnnotationHelper(
        activeSubscriptionName,
        activeSubscriptionCapacity,
        name,
        area_quota,
        pointsForselectedArea,
        availableAreaQuota,
      ),
      warning,
      info,
      topInfo,
      content: `Price: ${price.symbol}${price.amount} ${price.currency} / Month`,
      selected: selectedSubscription?.name === name,
      onClick: () => setSelectedSubscription(subscription),
      disabled: disabledPermanently || warning,
    };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSubscriptionOptions = useMemo(() => subscriptionOptions, [JSON.stringify(subscriptionOptions)]);

  const currentSubscription = subscriptionProductsList.find(({ name }) => activeSubscriptionData?.name === name);

  useEffect(() => {
    if (currentSubscription) {
      setSelectedSubscription(currentSubscription);
    }
  }, [!!currentSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    subscriptionOptions: memoizedSubscriptionOptions,
  };
};
