import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { ErrorsModel } from 'domain/@errors/ErrorsModel';
import { initialWarningErrors } from 'domain/@errors/constants';
import { AREAS_PATH_NAME } from 'domain/areas/constants';

export const useGetAreasListErrors = (): string[] => {
  const warningErrors = useErrorsStore.use.warningErrors();
  const activeErrors = ErrorsModel.getAvailableCombinedWarningErrors(
    initialWarningErrors,
    Object.values(warningErrors),
    '',
    AREAS_PATH_NAME as string,
  );

  const { displayableErrors } = ErrorsModel.getDisplayableWarningErrors(activeErrors);
  const areasListErrors = displayableErrors.map(({ content }) => content);

  return areasListErrors;
};
