import { userRepository } from 'infrastructure/user/userRepository';
import { getInitials, getUsername } from 'domain/user/utils';

export const useGetUserInfo = () => {
  const { data } = userRepository.useGetUserDetails();

  const userDetails = data?.data;

  if (!userDetails) {
    return {
      userName: '',
      userInitials: '',
    };
  }

  const userConfig = userDetails;
  const email = userDetails.email;
  const firstName = userDetails.first_name;
  const lastName = userDetails.last_name;

  const userName = getUsername(email, firstName, lastName);
  const userInitials = getInitials(email);

  return { userConfig, userName, userInitials, email };
};
