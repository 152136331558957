import env from 'react-dotenv';
import { restAPI } from 'services/rest/restApiService';

export const geocodeSearchClient = async (query: string) => {
  const apiKey = env.REACT_APP_TOMTOM_API_KEY;

  if (!apiKey) {
    throw new Error('REACT_APP_TOMTOM_API_KEY is not provided');
  }

  const geocodeBaseUrl = `https://api.tomtom.com/search/2/geocode/${query}.json?storeResult=false&view=Unified&key=${apiKey}`;
  const response = await restAPI.external(geocodeBaseUrl);

  return response.data.results;
};
