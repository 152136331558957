import { type ReactNode } from 'react';
import styled from 'styled-components';

import { Checkbox, Chevron } from 'ui';

type OptionButtonVariant = 'CHEVRON' | 'RADIOBOX' | 'CHECKBOX';

interface OptionButtonProps {
  children: ReactNode;
  selected: boolean;
  label: string;
  small?: boolean;
  disabled?: boolean;
  disabledPartly?: boolean;
  variant?: OptionButtonVariant;
  onClick: (event: React.SyntheticEvent) => void;
}

export const OptionButton = ({
  children,
  selected,
  label,
  small = false,
  disabled = false,
  disabledPartly = false,
  variant = 'CHEVRON',
  onClick,
}: OptionButtonProps) => (
  <OptionButtonWrapper
    role="button"
    aria-label={`option button ${label?.toLowerCase()}`}
    onClick={disabled ? undefined : onClick}
    selected={selected}
    small={small}
    disabled={disabled}
    disabledPartly={disabledPartly}
  >
    <ContentWrapper>{children}</ContentWrapper>
    <ChevronWrapper>
      {variant === 'CHEVRON' ? (
        <Chevron variant="LEFT" isFlipped width={small ? 15 : undefined} />
      ) : (
        <Checkbox checked={selected} disabled={disabled} radio={variant === 'RADIOBOX'} />
      )}
    </ChevronWrapper>
  </OptionButtonWrapper>
);

const OptionButtonWrapper = styled.div<{
  selected: boolean;
  small: boolean;
  disabled: boolean;
  disabledPartly: boolean;
}>`
  display: flex;
  min-height: ${({ small }) => (small ? 38 : 46)}px;
  background-color: ${({ selected, theme }) => (selected ? theme.color.selectGray : 'transparent')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  line-height: 1.4;
  word-break: break-word;
  border-bottom: 1px solid ${({ theme }) => theme.color.mineShaftDark};

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.color.midGray};
  }

  color: ${({ theme, disabled, disabledPartly }) =>
    disabled && !disabledPartly ? theme.color.whiteTransparent40 : theme.color.white};
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ChevronWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  border-left: ${({ theme }) => `1px solid ${theme.color.mineShaftDark}`};
`;

OptionButton.displayName = 'OptionButton';
