import { type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { useUserStore } from 'infrastructure/user/userStore';
import { REPORTS_QUERY_PARAMETER, REPORTS_QUERY_PARAMETER_VALUE } from 'common/navigation/queryParams';
import { type NavigationItemConfig, type PageAreaTypes, type ViewConfig } from 'common/navigation/types';
import { getFirstAvailablePath, getPermittedRoutes, isAnyViewAvailable } from 'common/navigation/utils';
import { useQueryParameter } from 'common/navigation/hooks';
import { MONITORING_PATH_NAME } from 'domain/monitoring/constants';
import { PROSPECTING_PATH_NAME } from 'domain/prospecting/constants';

interface BuildNavigationItemProps {
  config: ViewConfig;
  label: string;
  icon: JSX.Element;
  iconSize: number;
  type: PageAreaTypes;
  children?: ReactNode;
  betaStatus?: boolean;
}

export const useBuildNavigationItem = ({
  config,
  label,
  icon,
  iconSize,
  type,
  children,
  betaStatus,
}: BuildNavigationItemProps) => {
  const { pathname } = useLocation();
  const { userPermissions } = useUserStore();
  const reportsQuery = useQueryParameter(REPORTS_QUERY_PARAMETER);
  const areReportsActive = reportsQuery === REPORTS_QUERY_PARAMETER_VALUE;

  if (!userPermissions) return {} as NavigationItemConfig;

  const navigationItem: NavigationItemConfig = {
    label_name: label,
    icon,
    iconSize,
    route: getFirstAvailablePath(config, userPermissions.granted_permissions) || config.path,
    permittedRoutes: getPermittedRoutes(config, userPermissions.granted_permissions),
    enabled:
      isAnyViewAvailable(config, userPermissions.granted_permissions) ||
      config.name === MONITORING_PATH_NAME ||
      config.name === PROSPECTING_PATH_NAME,
    active: (pathname.includes(`/${config.name}/`) || pathname.endsWith(`/${config.name}`)) && !areReportsActive,
    type: type,
    children,
    betaStatus,
  };

  return navigationItem;
};
