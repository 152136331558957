import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';

export const useGetCogRange = (layerName: string, min: number, max: number): [number, number] => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();

  const currentTreshold = layersTresholds[layerName];
  const startValue = typeof currentTreshold === 'number' ? currentTreshold : min;

  return [startValue, max];
};
