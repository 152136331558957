import { css, keyframes } from 'styled-components';

const fetchingFlashing = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const fetchingFlashingAnimation = css`
  animation: ${fetchingFlashing} 3s linear infinite;
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const waveLoading = keyframes`
  from {
    background-position-x: 125%;
  }
  to {
    background-position-x: -25%;
  }
`;

export const progressBarLoading = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
`;
