import { type ComponentType } from 'react';

import { useGetAoiName } from 'common/hooks/getAoiName';
import { Loading } from 'ui/molecules/loading';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { type MoistureIndicator, type MoistureResult } from 'domain/water/types';

interface Props<T> {
  Component: ComponentType<T & { results: MoistureResult[]; indicator: MoistureIndicator }>;
  props?: T;
  timestamp: string;
  indicator: MoistureIndicator;
  enableLoading?: boolean;
}

export const WithMoistureMultiResults = <T,>({ Component, props, timestamp, indicator, enableLoading }: Props<T>) => {
  const { areaName } = useGetAoiName();

  const { results, isLoading } = waterRepository.useFetchMoistureResults(areaName);

  const filteredResults = results.filter(
    ({ img_date, indicator_id }) => img_date === timestamp && indicator_id === indicator.id,
  );

  if (enableLoading && isLoading) {
    return <Loading />;
  }

  if (!filteredResults.length) {
    return null;
  }

  return <Component {...(props as T)} results={filteredResults} indicator={indicator} />;
};
