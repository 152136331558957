import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { useSnowCoverIndicators } from './useSnowCoverIndicators';
import { SnowCoverMapTimeSeriesLayer } from './SnowCoverMapTimeSeriesLayer';
import { SnowCoverIndicatorMapLayer } from './SnowCoverIndicatorMapLayer';
import { useSnowViewOptions } from './viewOptions';

export const SnowCoverMapContent = () => {
  const { isIndicatorsView } = useSnowViewOptions();

  const { selectedIndicator } = useSnowCoverIndicators();

  if (!isIndicatorsView) {
    return <SnowCoverMapTimeSeriesLayer />;
  }

  if (!selectedIndicator) {
    return null;
  }

  const { source_url, colormap, unit, precision } = selectedIndicator;

  return (
    <WithCogBandRange
      Component={SnowCoverIndicatorMapLayer}
      props={{ url: source_url, colormap, unit }}
      url={source_url}
      precision={precision}
    />
  );
};
