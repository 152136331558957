import { type Position } from 'geojson';
import Dygraph from 'dygraphs';

import { type DataPoint } from 'common/types/mapData';
import { transformDateStringToUTC } from 'common/utils/datetime';
import { COORDINATES_PRECISION } from 'common/constants';
import {
  type MultiGraphData,
  type GraphData,
  type AdditionalDataTypes,
  type GraphLabelType,
  type WeatherResponse,
  type DatesGraphDictType,
} from './types';
import { GraphSeries } from './enums';

export const getCoordinateString = (name: string, coordinates: Position | DataPoint) => {
  if ('lat' in coordinates && 'lng' in coordinates) coordinates = [+coordinates.lat, +coordinates.lng];
  return `${name}: ${coordinates[0].toFixed(COORDINATES_PRECISION)}˚ ${coordinates[1].toFixed(COORDINATES_PRECISION)}˚`;
};

interface CreateMultiGraphDataScalesLabelsCommandProps {
  label: GraphLabelType;
  seriesName: keyof AdditionalDataTypes;
  additionalData: AdditionalDataTypes;
  data: GraphData | MultiGraphData;
}

export const createMultiGraphDataScalesLabelsCommand = ({
  label,
  seriesName,
  additionalData,
  data,
}: CreateMultiGraphDataScalesLabelsCommandProps): [GraphData | MultiGraphData, GraphLabelType] => {
  if (!additionalData[seriesName]) {
    return [data, label];
  }

  const label_ = label && [...label];
  label_ && label_.push(seriesName);

  let data_ = [...data];

  const mergedData = mergeDateNumberArrays(data_ as GraphData, additionalData[seriesName] as GraphData);
  data_ = mergedData;

  return [data_, label_];
};

export const mergeDateNumberArrays = (dateNumberArray: GraphData, right: GraphData) => {
  const leftValuesLength = dateNumberArray[0].length - 1;
  const rightValuesLength = right[0].length - 1;

  const datesGraphDict = dateNumberArray.reduce((acc, dateNumber) => {
    const [date, ...values] = dateNumber;
    const dateMS = date.getTime();
    acc[dateMS] = values;
    return acc;
  }, {} as DatesGraphDictType);

  const mergedDatesGraphDict = right.reduce((acc, dateNumber) => {
    const [date, ...values] = dateNumber;
    const dateMS = date.getTime();
    acc[dateMS] = acc[dateMS] ? acc[dateMS].concat(values) : [...Array(leftValuesLength).fill(NaN), ...values];
    return acc;
  }, datesGraphDict);

  const mergedData = Object.entries(mergedDatesGraphDict)
    .sort((a, b) => +a[0] - +b[0])
    .map((dateNumber) => {
      const [date, ...values] = dateNumber.flat();
      const mergedDataItem = [new Date(+date), ...values];
      const lengthDifference = leftValuesLength + rightValuesLength - values.length;
      if (lengthDifference > 0) mergedDataItem.push(...Array(lengthDifference).fill(NaN));
      return mergedDataItem;
    });

  return mergedData as MultiGraphData;
};

export const getWeatherGraphData = (weatherResponse: WeatherResponse[]) => {
  if (!weatherResponse || !weatherResponse.length) throw Error('There are no data in getWeatherGraphData');

  const temperatureData = weatherResponse.map((weather) => [
    new Date(transformDateStringToUTC(weather.date)),
    weather.temperature?.average?.value ?? null,
  ]);
  const precipitationData = weatherResponse.map((weather) => [
    new Date(transformDateStringToUTC(weather.date)),
    weather.precipitation?.value ?? null,
  ]);

  return { temperatureData, precipitationData };
};

export const checkY2state = (graphLabel: string[] | undefined) => {
  const isTemperature = graphLabel?.includes(GraphSeries.TEMPERATURE);
  const isPrecipitation = graphLabel?.includes(GraphSeries.PRECIPITATION);
  const isY2hidden = (isTemperature && isPrecipitation) || (!isTemperature && !isPrecipitation) ? true : false;
  return { isY2hidden, isTemperature, isPrecipitation };
};

export type CanvasPoint = { canvasx: number; canvasy: number };

export const drawScatterPoints = (
  ctx: CanvasRenderingContext2D,
  points: CanvasPoint[],
  color: string,
  width: number,
) => {
  ctx.fillStyle = color;
  ctx.lineWidth = width;

  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    ctx.beginPath();
    ctx.arc(point.canvasx, point.canvasy, width * 1.5, 0, 2 * Math.PI);
    ctx.fill();
  }
};

export const drawMinMaxBounds = (
  ctx: CanvasRenderingContext2D,
  points: CanvasPoint[],
  color: string,
  width: number,
) => {
  ctx.lineWidth = width * 20;
  ctx.strokeStyle = color;

  ctx.beginPath();

  const pointsLastIndex = points.length - 1;
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    if (i === 0) {
      ctx.moveTo(point.canvasx - 10, point.canvasy);
    } else if (i === pointsLastIndex) {
      ctx.lineTo(point.canvasx + 10, point.canvasy);
    } else {
      ctx.lineTo(point.canvasx, point.canvasy);
    }
  }
  ctx.stroke();
};

export const getFirstAndLastDateFromGraphData = (graphData: GraphData | undefined) => {
  if (!graphData || !graphData.length) return { firstGraphDate: 0, lastGraphDate: Infinity };

  const firstGraphDate = graphData?.[0][0].getTime();
  const lastGraphDate = graphData?.[graphData.length - 1][0].getTime();
  if (firstGraphDate === lastGraphDate) {
    return {
      firstGraphDate: firstGraphDate - 24 * 60 * 60 * 1000,
      lastGraphDate: firstGraphDate + 24 * 60 * 60 * 1000,
    };
  }
  return { firstGraphDate, lastGraphDate };
};

export const getAllAxesNamesWithPlotter = (names: string[]) =>
  names.reduce(
    (acc, item) => ({
      ...acc,
      [item.toUpperCase()]: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        plotter: (e: any) => {
          const points = e.points.filter((p: any) => !isNaN(p.y)); // eslint-disable-line @typescript-eslint/no-explicit-any
          Dygraph.smoothPlotter({ ...e, points: points });

          const ctx = e.drawingContext;
          const strokeWidth = e.dygraph.getOption('strokeWidth');

          drawMinMaxBounds(ctx, points, 'rgba(255, 255, 255, 0.2)', strokeWidth);
          drawScatterPoints(ctx, points, e.color, strokeWidth);
        },
      },
    }),
    {},
  );
