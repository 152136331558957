import { useState } from 'react';
import { useUpgradeSubscription } from 'views/subscriptions/SubscriptionUpgrade/useUpgradeSubscription';
import { type SubscriptionType } from 'domain/subscription/types';

export const useHandleUpgradeModal = (
  subscriptionType: SubscriptionType,
  setErrorMessage: (message: string) => void,
  setLoading?: (state: boolean) => void,
) => {
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);

  const openUpgradeModal = () => setUpgradeModalVisible(true);
  const closeUpgradeModal = () => setUpgradeModalVisible(false);

  const { getUpgradeData, handleUpgrade } = useUpgradeSubscription(setErrorMessage, setLoading);

  const handleConfirm = () => {
    handleUpgrade(subscriptionType);
    closeUpgradeModal();
  };

  const upgradeData = getUpgradeData(subscriptionType);

  return { upgradeModalVisible, openUpgradeModal, closeUpgradeModal, handleConfirm, upgradeData, getUpgradeData };
};
