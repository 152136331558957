import { useEffect } from 'react';

import { useGraphStore } from 'components/graph/graphStore';
import { useWaterbodies } from '../waterbodies';

export const useWaterAreaLifeCycleUseCase = () => {
  const resetGraphStore = useGraphStore.use.resetGraphStore();
  const graphData = useGraphStore.use.graphData();
  const { selectedWaterbody } = useWaterbodies();
  const waterbodyId = selectedWaterbody?.value;

  useEffect(() => {
    if (graphData && !waterbodyId) {
      resetGraphStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, waterbodyId]);

  useEffect(() => {
    return () => {
      resetGraphStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterbodyId]);
};
