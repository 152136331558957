import { formatDate } from 'common/utils/datetime';
import { capitalizeAllFirstLetters, simpleRound, replaceSquareMetersWithHectares } from 'common/utils';
import { SURFACE_PRECISION_HA } from 'common/constants';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useQueryParameter } from 'common/navigation/hooks';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdfSimpleContent } from 'views/reports/external/pdfGenerator/simple/ReportPdfSimpleContent';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { useWaterAreaResults } from 'views/water/surface/useWaterAreaResults';
import { EXTERNAL_REPORT_SURFACE_AREA_UNIT, REPORTS_ALL_WATERBODIES_LABEL } from 'domain/reports/constants';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';
import { WATERMASKS_REPORT_COLOR } from 'domain/water/colors';

interface WaterAreaSingleDateReportsExternalDataProps {
  areaId: number;
  timestamp: string;
  reportInfo: ExternalReportHeaderCommon;
}

export const WaterAreaSingleDateReportsExternalData = ({
  areaId,
  timestamp,
  reportInfo,
}: WaterAreaSingleDateReportsExternalDataProps) => {
  const { waterBodiesList: waterbodies } = waterRepository.useFetchWaterbodies(areaId);
  const selectedWaterbodyId = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const { waterAreaResults } = useWaterAreaResults();

  const reportScreenshotSrc = useReportsStore.use.reportScreenshotSrc();

  const allWaterbodiesSurfaceInM2 = waterAreaResults.reduce((acc, { area }) => acc + area, 0);
  const allWaterbodiesSurfaceInHa = replaceSquareMetersWithHectares(allWaterbodiesSurfaceInM2);
  const allWaterbodiesValue =
    typeof allWaterbodiesSurfaceInHa === 'number' ? simpleRound(allWaterbodiesSurfaceInHa, SURFACE_PRECISION_HA) : null;

  const data = selectedWaterbodyId
    ? waterbodies
        .filter(({ id }) => selectedWaterbodyId === String(id))
        .map(({ id, name }) => {
          const surfaceInM2 = waterAreaResults.find((item) => item.waterbody_id === id)?.area;
          const surfaceInHa = replaceSquareMetersWithHectares(surfaceInM2);
          const value = typeof surfaceInHa === 'number' ? simpleRound(surfaceInHa, SURFACE_PRECISION_HA) : null;

          return {
            id,
            label: capitalizeAllFirstLetters(name),
            color: WATERMASKS_REPORT_COLOR,
            value,
          };
        })
    : [
        {
          id: -1,
          label: REPORTS_ALL_WATERBODIES_LABEL,
          color: WATERMASKS_REPORT_COLOR,
          value: allWaterbodiesValue,
        },
      ];

  const header = {
    ...reportInfo,
    reportType: 'Single Date Report',
    reportFunctionality: 'Water Surface Area',
    dates: {
      first: formatDate(timestamp),
    },
  };

  const table = {
    tableHeader: 'Water Surface Area',
    dataHeader: 'Waterbody Name',
    unit: EXTERNAL_REPORT_SURFACE_AREA_UNIT,
    data,
  };

  if (!reportScreenshotSrc) {
    return null;
  }

  return (
    <>
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} />
          <ReportPdfSimpleContent screenshotScr={reportScreenshotSrc} table={table} />
        </>
      </ReportPdf>
    </>
  );
};
