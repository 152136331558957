import { LabeledCheckbox } from 'ui';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useLayerManagerStore } from '../../layerManagerStore';
import { AuxiliaryControlsWrapper, IndentedContentWraper } from '../../styled';
import { DraggableLayerManagerSection } from '../../draggableLayerManager';
import { useInitializeLayersOrderRecord } from '../../draggableLayerManager/useInitializeLayersOrderRecord';
import { type LAYERS_KEYS } from '../../draggableLayerManager/draggableLayersKeys';
import { OpacitySlider } from '../../OpacitySlider';

interface DemItemProps {
  id: LAYERS_KEYS;
  areaId: number;
}

export const DemItem = ({ id, areaId }: DemItemProps) => {
  const { aoiCog: demCog } = areasRepository.useFetchAreaOfInterestCog(areaId, { productType: 'DEM' });
  const isDemVisible = useLayerManagerStore.use.isDemVisible();
  const toggleDemVisibility = useLayerManagerStore.use.toggleDemVisibility();
  const demOpacity = useLayerManagerStore.use.demOpacity();
  const setDemOpacity = useLayerManagerStore.use.setDemOpacity();

  const onTopographyOpacityChange = (value: number | number[]) => {
    setDemOpacity(Array.isArray(value) ? value[0] : value);
  };

  useInitializeLayersOrderRecord(id);

  if (!demCog) {
    return null;
  }

  return (
    <DraggableLayerManagerSection id={id}>
      <IndentedContentWraper>
        <LabeledCheckbox variant="WIDE" onChange={toggleDemVisibility} checked={isDemVisible}>
          hypsometric
        </LabeledCheckbox>
        <AuxiliaryControlsWrapper disabled={!isDemVisible}>
          <OpacitySlider ariaLabel="dem opacity slider" opacity={demOpacity} setOpacity={onTopographyOpacityChange} />
        </AuxiliaryControlsWrapper>
      </IndentedContentWraper>
    </DraggableLayerManagerSection>
  );
};
