import { type ExtendedIndicator } from 'common/types';
import { ColorScaleBadge } from 'ui/colorScale';
import { AppLayerManager } from 'components/layerManager/legacy';

interface WaterQualityLayerManagerProps {
  selectedIndicator: ExtendedIndicator | undefined;
}

export const WaterQualityLayerManager = ({ selectedIndicator }: WaterQualityLayerManagerProps) => {
  return (
    <AppLayerManager
      mainLayerLabel={
        selectedIndicator && (
          <ColorScaleBadge
            colormap={selectedIndicator.colormap}
            label={selectedIndicator.indicator_name}
            unit={selectedIndicator.unit}
            segmented={true}
          />
        )
      }
    />
  );
};
