import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AI_ASSISTANT_MESSAGES_SESSION_STORAGE_KEY } from 'common/constants';
import { LOCAL_STORAGE_ACCESS_TOKEN_NAME, LOCAL_STORAGE_REFRESH_TOKEN_NAME } from 'common/constants/services';
import { ROUTES } from 'common/navigation/routes';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import {
  clearAppInsightsAuthenticatedUserHelper,
  setAppInsightsAuthenticatedUserHelper,
} from 'infrastructure/user/helpers';
import { userRepository } from 'infrastructure/user/userRepository';
import { useUserStore } from 'infrastructure/user/userStore';
import { localStorageService } from 'services/localStorageService';
import { isSessionResponseValid } from 'domain/user/typeguards';

export const useLoginService = () => {
  const navigate = useNavigate();

  const { setResetPasswordRequestStatus, hideLoginPrompt } = useUserStore();

  const clearReports = useReportsStore.use.clearReports();

  const useHandleLoginUseCase = () => {
    const { data: userDetails, isLoading } = userRepository.useGetUserDetails();

    useEffect(() => {
      if (userDetails) {
        window.location.replace(`#${ROUTES.areasList}`);
      }
    }, [userDetails]);

    return { isCheckingUserSession: isLoading };
  };

  const useHandleSessionDataSaveUseCase = (refresh?: boolean, redirect: boolean = true) => {
    const queryClient = useQueryClient();

    const { mutate, error, isLoading } = userRepository.useLoginUser();

    const loginUser = (loginData: { username: string; password: string }) =>
      mutate(loginData, {
        onSuccess: (data) => {
          if (!data || !isSessionResponseValid(data)) return;

          queryClient.invalidateQueries();
          localStorageService.setValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, data.data.refresh_token);
          localStorageService.setValue(LOCAL_STORAGE_ACCESS_TOKEN_NAME, data.data.access_token);
          setAppInsightsAuthenticatedUserHelper(data.data.user.email);
          hideLoginPrompt();

          redirect && window.location.replace(`#${ROUTES.areasList}`);
          refresh && window.location.reload();
        },
      });

    return { loginUser, error, isLoading, setResetPasswordRequestStatus };
  };

  const useHandleLogoutUseCase = () => {
    const queryClient = useQueryClient();
    const { clearAllUserData } = useUserStore();

    const onLogout = () => {
      sessionStorage.removeItem(AI_ASSISTANT_MESSAGES_SESSION_STORAGE_KEY);
      localStorageService.removeValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
      localStorageService.removeValue(LOCAL_STORAGE_ACCESS_TOKEN_NAME);
      queryClient.clear();
      clearAllUserData();
      hideLoginPrompt();
      clearReports();
      clearAppInsightsAuthenticatedUserHelper();
      navigate(ROUTES.login);
    };

    const logoutMutation = userRepository.useLogoutUser();

    useEffect(() => {
      logoutMutation.mutate(undefined, {
        onSuccess: onLogout,
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  };

  const loginService = {
    useHandleLoginUseCase,
    useHandleSessionDataSaveUseCase,
    useHandleLogoutUseCase,
  };

  return loginService;
};
