import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { type MapLayerMouseEvent } from 'maplibre-gl';

import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { type GenericTooltipInputResult, type DefaultView, type TooltipData } from 'common/types/mapData';
import { type ValueAndLabel } from 'common/types';
import { debounce } from 'common/utils';
import { MapTooltip } from 'ui/map/tooltip';
import { examinePointInsideBoundsHelper } from './helpers';

interface GenericMapTooltipProps<T> {
  enabled: boolean;
  results: (T extends GenericTooltipInputResult ? T : never)[];
  defaultView?: DefaultView;
  onPointClick?: (longitude: number, latitude: number, value: number, currentWaterbody: ValueAndLabel) => void;
  builder?: (tooltip: TooltipData) => string;
}

export const GenericMapTooltip = <T,>({
  enabled,
  results,
  defaultView,
  onPointClick,
  builder,
}: GenericMapTooltipProps<T>) => {
  const [tooltip, setTooltip] = useState<TooltipData | undefined>();

  const map = useMap();
  const currentZoom = map.getZoom();

  useEffect(() => {
    map.off('click'); // cleanup previous handlers
    map.off('mousemove');

    map.on(
      'mousemove',
      debounce(async (event: MapLayerMouseEvent) => {
        const latLng = await map.mouseEventToLatLng(event.originalEvent);

        results.forEach(async (result) => {
          const value = await examinePointInsideBoundsHelper(result.id, result.url, latLng);

          if (value) {
            setTooltip({
              id: result.id,
              lat: latLng?.lat,
              lng: latLng?.lng,
              value,
              label: result.label.toString(),
            });
          } else {
            setTooltip(undefined);
          }
        });
      }, TOOLTIP_DISPLAY_DELAY),
    );

    map.on('click', async (event: { originalEvent: MouseEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      results.forEach(async (result) => {
        const value = await examinePointInsideBoundsHelper(result.id, result.url, latLng);
        if (onPointClick && value && enabled) {
          onPointClick(latLng?.lat, latLng?.lng, value, { value: result.value, label: result.label });
          defaultView?.zoom && map.flyTo(latLng, Math.max(currentZoom, defaultView?.zoom));
        }
      });
    });

    return () => {
      map.off('click');
      map.off('mousemove');
    };
  }, [map, results, onPointClick, defaultView, enabled, currentZoom]);

  if (tooltip && builder) {
    return <MapTooltip tooltipData={tooltip} content={builder(tooltip)} />;
  }

  return null;
};
