import { useAoiNumber } from 'common/navigation/hooks';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { DemItem } from './DemItem';
import { HillshadeItem } from './HillshadeItem';
import { LAYERS_KEYS } from '../../draggableLayerManager/draggableLayersKeys';

export const useGetTopographyLayersItem = () => {
  const areaId = useAoiNumber();
  const { areaName } = useGetAoiName();

  return areaName
    ? [
        { id: LAYERS_KEYS.DEM, name: 'dem', content: <DemItem {...{ areaId }} id={LAYERS_KEYS.DEM} /> },
        {
          id: LAYERS_KEYS.HILLSHADE,
          name: 'hillshade',
          content: <HillshadeItem {...{ areaName }} id={LAYERS_KEYS.HILLSHADE} />,
        },
      ]
    : [];
};
