import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useDeleteQueryParameters, useQueryParameter } from 'common/navigation/hooks';
import {
  FILTERS_STATUS_QUERY_PARAM_NAME,
  FILTERS_TAG_QUERY_PARAM_NAME,
  FILTERS_ENVIRONMENT_QUERY_PARAM_NAME,
  FILTERS_TYPE_QUERY_PARAM_NAME,
} from 'common/navigation/queryParams';
import { Button } from 'ui/atoms/button/Button';
import { FilterButton } from 'ui/atoms/filterButton';
import { Container } from 'ui/atoms/container';
import { TagList } from 'ui/molecules/tagList';
import { useGetAreasStatusSet } from 'views/areas/areasList/getAreasStatusSet';
import { useGetAreasTagSet } from 'views/areas/areasList/getAreasTagSet';
import { useGetAreasEnvironmentSet } from 'views/areas/areasList/getAreasEnvironmentSet';
import { useGetAreasTypeSet } from 'views/areas/areasList/getAreasTypeSet';
import { useOnClickOutside } from 'common/hooks';

export const Filters = () => {
  const filtersRef = useRef<HTMLDivElement>(null);

  const [isFilterAreaOpen, setIsFilterAreaOpen] = useState(false);
  const [filterButtonHighlight, setFilterButtonHighlight] = useState(false);

  const statusSet = useGetAreasStatusSet();
  const tagSet = useGetAreasTagSet();
  const areasEnvironmentSet = useGetAreasEnvironmentSet();
  const areasTypeSet = useGetAreasTypeSet();

  const isFilteringOn = [...statusSet, ...tagSet, ...areasEnvironmentSet, ...areasTypeSet].some(
    (item) => !item.checked,
  );

  const statusSetExist = !!statusSet.length;
  const tagSetExist = !!tagSet.length;
  const areasEnvironmentSetExist = !!areasEnvironmentSet.length;
  const areasTypeSetExist = !!areasTypeSet.length;
  const filtersEmpty = !(statusSetExist || tagSetExist || areasEnvironmentSetExist || areasTypeSetExist);

  const tagQuery = useQueryParameter(FILTERS_TAG_QUERY_PARAM_NAME);
  const environmentQuery = useQueryParameter(FILTERS_ENVIRONMENT_QUERY_PARAM_NAME);
  const deleteQueryParameters = useDeleteQueryParameters();

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const handleFiltersReset = () => {
    deleteQueryParameters([
      FILTERS_STATUS_QUERY_PARAM_NAME,
      FILTERS_TAG_QUERY_PARAM_NAME,
      FILTERS_ENVIRONMENT_QUERY_PARAM_NAME,
      FILTERS_TYPE_QUERY_PARAM_NAME,
    ]);
  };

  useEffect(() => {
    if (tagQuery || environmentQuery) {
      setFilterButtonHighlight(true);
      timer.current = setTimeout(() => {
        setFilterButtonHighlight(false);
        timer.current = null;
      }, 1000);
    }
  }, [tagQuery, environmentQuery]);

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []);

  useOnClickOutside(filtersRef, () => setIsFilterAreaOpen(false));

  return (
    <StyledFilters ref={filtersRef}>
      <FilterButton
        selected={isFilterAreaOpen}
        hasDot={isFilteringOn}
        onClick={() => setIsFilterAreaOpen((prev) => !prev)}
        highlighted={filterButtonHighlight}
      />
      {isFilterAreaOpen && (
        <StyledFilterArea>
          {filtersEmpty ? (
            'No tags available'
          ) : (
            <>
              {statusSetExist && (
                <Container mb={16}>
                  <TagList header="Status" tags={statusSet} />
                </Container>
              )}
              {tagSetExist && (
                <Container mb={16}>
                  <TagList header="Tags" tags={tagSet} />
                </Container>
              )}
              {areasEnvironmentSetExist && (
                <Container mb={16}>
                  <TagList header="Analysis Type" tags={areasEnvironmentSet} />
                </Container>
              )}
              {areasTypeSetExist && <TagList header="AOI type" tags={areasTypeSet} />}
              <Container mt={20}>
                <Button variant="PRIMARY_SMALL" onClick={handleFiltersReset} disabled={!isFilteringOn}>
                  Reset selection
                </Button>
              </Container>
            </>
          )}
        </StyledFilterArea>
      )}
    </StyledFilters>
  );
};

const StyledFilters = styled.div`
  position: relative;
`;

const StyledFilterArea = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 345px;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  color: ${({ theme }) => theme.color.white};
  font-size: 12px;
  font-weight: 500;
  padding: 14px 12px 18px;
  border-radius: 8px;
`;
