import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { HabitatsLayerManager } from './HabitatsLayerManager';

export const Habitats = () => {
  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <AreaDetailsLayout toolboxContent={!areReportsActive && <LayerManagerControlButton />}>
      {!areReportsActive && <HabitatsLayerManager />}
    </AreaDetailsLayout>
  );
};
