import { CreatorDataColumn } from 'ui/creator/creatorDataColumn';
import { Select } from 'ui/atoms/select';
import { ReportsCreatorDataColumnDatesLabel } from 'views/reports/creator/components/ReportsCreatorDataColumnDatesLabel';
import { formatDate } from 'common/utils/datetime';
import { type ReportsDatasetExtended } from 'domain/reports/types';

interface ReportsCreatorSavedDataColumnProps {
  dataset: ReportsDatasetExtended;
  clearDataset: (datasetId?: number | undefined) => void;
}

export const ReportsCreatorSavedDataColumn = ({
  dataset: { id, data, timestamp },
  clearDataset,
}: ReportsCreatorSavedDataColumnProps) => (
  <CreatorDataColumn
    data={data}
    state={'SAVED'}
    handleConfirmClick={() => clearDataset(id)}
    topContent={
      <>
        <ReportsCreatorDataColumnDatesLabel no={id} />
        <Select
          {...{
            options: [],
            selectedOption: {
              value: 0,
              label: formatDate(timestamp),
            },
            name: 'date selection',
          }}
        />
      </>
    }
  />
);
