import { Loading } from 'ui/molecules/loading';
import { DataLayer } from 'components/map';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

interface Props {
  url: string;
  expression: string;
  rescaleArray: number[][];
  visible: boolean;
  opacity?: number;
}

export const CompositesMapLayerBeta = ({ url, expression, rescaleArray, visible, opacity }: Props) => {
  const titilerUrl = titilerApiAdapter.buildGenericRGBImageUrl(url, {
    expression,
    rescaleFactors: rescaleArray,
  });

  const { extentLatLngBounds, isInfoLoading } = useGetExtentPolygon(url);

  if (!extentLatLngBounds) {
    return null;
  }

  if (isInfoLoading) {
    return <Loading />;
  }

  return (
    <WithLayerIndex
      Component={DataLayer}
      props={{
        isVisible: visible,
        layerName: 'compositesLayerBeta',
        cogUrl: titilerUrl,
        bounds: extentLatLngBounds,
        opacity,
      }}
      sectionId={EXPLORATION_LAYERS_KEYS.COMPOSITES_BETA}
    />
  );
};
