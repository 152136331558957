import styled from 'styled-components';

import { BaseModal, ModalHeader } from 'ui/modal';
import { type SubscriptionType } from 'domain/subscription/types';
import { SubscriptionUpgradeTile } from '../../../views/subscriptions/SubscriptionUpgrade/SubscriptionUpgradeTile';

interface UpgradeModalProps {
  type: SubscriptionType;
  data: {
    addedQuota: number;
    price: string;
  };
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  header: string;
  description?: string;
}

export const UpgradeModal = ({
  type,
  data,
  isOpen = true,
  closeModal,
  onConfirm,
  header,
  description,
}: UpgradeModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <BaseModal {...{ isOpen, closeModal, maxWidth: 360 }}>
      <ContentContainer>
        <ModalHeader>{header}</ModalHeader>
        {description && <Description>{description}</Description>}
        <SubscriptionUpgradeTile
          type={type}
          quota={data.addedQuota}
          price={data.price}
          handleUpgrade={handleConfirm}
          handleCancel={closeModal}
        />
      </ContentContainer>
    </BaseModal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 54px;
`;

const Description = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin: 0;
`;
