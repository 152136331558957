import { PUBLIC_CONTAINER_URL } from 'common/constants/services';
import { GeoJSONLayer, Layer } from 'components/map';
import { Loading } from 'ui/molecules/loading';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { type HabitatsResult } from 'domain/greenery/types';
import { HABITATS_EXPANDED_AREA_LAYER_NAME } from 'domain/greenery/constants';
import { HABITATS_MAP_EXPANDED_AREA_LAYER_COLOR } from 'domain/greenery/colors';
import { useHabitatsLayersVisibility } from './useHabitatsLayersVisibility';

export const HabitatsMapExpandedAreaLayer = ({ result }: { result: HabitatsResult }) => {
  const { id, url } = result;
  const { classesVisibility } = useHabitatsLayersVisibility();

  const { geoJsonShape, geoJsonShapeLoading } = areasRepository.useFetchGeoJsonShape(
    id,
    `${PUBLIC_CONTAINER_URL}${url}`,
  );

  if (geoJsonShapeLoading) {
    return <Loading />;
  }

  if (!geoJsonShape) return null;

  return (
    <Layer
      visible={!!classesVisibility[HABITATS_EXPANDED_AREA_LAYER_NAME]}
      name={HABITATS_EXPANDED_AREA_LAYER_NAME}
      zIndex={480}
    >
      <GeoJSONLayer
        pane={HABITATS_EXPANDED_AREA_LAYER_NAME}
        geoJson={geoJsonShape}
        color={HABITATS_MAP_EXPANDED_AREA_LAYER_COLOR}
        fillOpacity={0}
        opacity={0.8}
      />
    </Layer>
  );
};
