import { Outlet } from 'react-router-dom';

import { TopButtonsWrapper, TabSwitcher } from 'components';
import { useUserStore } from 'infrastructure/user/userStore';
import { useTreeShakeQueryParams } from 'common/navigation/hooks';
import { getAvailableTabSwitcherOptions } from 'common/navigation/utils';
import { WATERBODY_TAB_SWITCHER_BUTTONS, WATER_VIEW_CONFIG } from 'domain/water/configs';
import { useWaterLifecycle } from './useWaterLifecycle';

export const WaterDetection = () => {
  const { userPermissions } = useUserStore();

  useWaterLifecycle();

  useTreeShakeQueryParams(WATER_VIEW_CONFIG);

  if (!userPermissions) return null;

  const waterTabSwitcherOptions = getAvailableTabSwitcherOptions(
    WATERBODY_TAB_SWITCHER_BUTTONS,
    userPermissions.granted_permissions,
  );

  return (
    <>
      <TopButtonsWrapper>
        <TabSwitcher options={waterTabSwitcherOptions} />
      </TopButtonsWrapper>
      <Outlet />
    </>
  );
};
