import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Chevron } from 'ui';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { getRouteUrlHelper } from 'common/navigation/utils';
import { REPORTS_QUERY_PARAMETER, VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { type NavigationItemConfig } from 'common/navigation/types';
import { NavigationItem } from 'components/navigationItem';

interface NavigationExtendedItemProps {
  item: NavigationItemConfig;
  active?: boolean;
  areaId?: number;
}

export const NavigationExtendedItem = ({ item, active, areaId }: NavigationExtendedItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isNarrowed = useSidebarStore.use.isSidebarFolded();

  const [isFolded, setIsFolded] = useState(false);

  const toggleFoldedState = () => setIsFolded(!isFolded);

  const handleNavItemClick = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(REPORTS_QUERY_PARAMETER);
    searchParams.delete(VIEW_MODE_QUERY_PARAMETER);
    const currentUrl = getRouteUrlHelper(item.route, areaId, ':areaId').concat(`?${searchParams.toString()}`);

    navigate(currentUrl);
  };

  useEffect(() => {
    setIsFolded(!active);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ExtendedWrapper>
      <NavItemWrapper>
        <NavigationItem
          isNarrowed={isNarrowed}
          icon={item.icon}
          iconSize={item.iconSize}
          onClick={handleNavItemClick}
          active={!!active}
          disabled={!item.enabled}
          label_name={item.label_name}
          disabledStatusText="Unavailable with current plan"
          betaStatus={item.betaStatus}
        />
        {item.children && !isNarrowed ? (
          <FoldButton onClick={toggleFoldedState}>
            <Chevron width={12} isFlipped={!isFolded} />
          </FoldButton>
        ) : null}
      </NavItemWrapper>
      <ExtraContentWrapper isFolded={isFolded || isNarrowed}>{item.children}</ExtraContentWrapper>
    </ExtendedWrapper>
  );
};

const NavItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ExtendedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FoldButton = styled.button`
  height: 100%;
  padding: 16px;
  background-color: transparent;
  color: ${({ theme }) => theme.color.white};
  border: none;
  cursor: pointer;
  margin-left: -44px;
`;

const ExtraContentWrapper = styled.div<{ isFolded?: boolean }>`
  display: ${({ isFolded }) => (isFolded ? 'none' : 'block')};
  width: 100%;
  transition: all ${({ theme }) => theme.transitionFunction.main};
`;
