import { useEffect, useState } from 'react';

import { updateTelemetryStatus } from 'services/appInsightsService';
import { localStorageService } from 'services/localStorageService';
import { LOCAL_STORAGE_ACCEPT_COOKIE } from 'common/constants/services';
import { CookieBanner } from 'ui';
import { BUTTON_TEXT, TIMEOUT } from './constants';

export const AppCookieBanner = () => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);

  const { setValue } = localStorageService;

  const handleClose = () => {
    setValue(LOCAL_STORAGE_ACCEPT_COOKIE, true);
    updateTelemetryStatus();
    setIsBannerOpen(false);
  };

  const isCookieAccepted = localStorageService.getValue<boolean>(LOCAL_STORAGE_ACCEPT_COOKIE, false);

  useEffect(() => {
    if (!isCookieAccepted) {
      const timerId = setTimeout(() => {
        setIsBannerOpen(true);
      }, TIMEOUT);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [isCookieAccepted]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORAGE_ACCEPT_COOKIE && !isBannerOpen) {
        setIsBannerOpen(true);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isBannerOpen]);

  return (
    <CookieBanner {...{ isBannerOpen, handleClose, buttonText: BUTTON_TEXT }}>
      This site collects information to provide a better browsing experience. By continuing to visit this site you agree
      to our collection of information.
    </CookieBanner>
  );
};
