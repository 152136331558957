import { useHandleQueryParameter } from 'common/navigation/hooks';
import { SNOW_COVER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { waterRepository } from 'infrastructure/water/waterRepository';

export const useSnowCoverIndicators = () => {
  const [indicatorQueryId, changeIndicator, clearIndicator] = useHandleQueryParameter(
    SNOW_COVER_INDICATOR_QUERY_PARAMETER,
  );

  const { indicators, isLoading } = waterRepository.useFetchSnowCoverIndicators();
  const selectedIndicator = indicators.find(({ id }) => String(id) === indicatorQueryId);

  return {
    indicators: indicators,
    selectedIndicator,
    areIndicatorsLoading: isLoading,
    changeIndicator,
    clearIndicator,
  };
};
