import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { type TabSwitcherOption } from 'common/navigation/types';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';

interface TabSwitcherProps {
  options: (TabSwitcherOption | undefined)[];
}

export const TabSwitcher = ({ options }: TabSwitcherProps) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  searchParams.delete(VIEW_MODE_QUERY_PARAMETER);

  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <>
      {!areReportsActive ? (
        <>
          {options.map(
            (option) =>
              option && (
                <TabSwitcherButton to={option.value.concat(`?${searchParams.toString()}`)} key={option.id}>
                  {option.label}
                </TabSwitcherButton>
              ),
          )}
        </>
      ) : (
        <TabSwitcherButton to="">New Report</TabSwitcherButton>
      )}
    </>
  );
};

const TabSwitcherButton = styled(NavLink)`
  background-color: transparent;
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3;
  margin: 0 1rem;
  border: none;
  transition: background-color ${({ theme }) => theme.transitionFunction.main};

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.color.lightGray};
  }

  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.color.white};
    pointer-events: none;
  }
`;
