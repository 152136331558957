import { ROUTES } from 'common/navigation/routes';
import { Extraction } from 'views/mine/Extraction';
import { PrivateRoute } from 'common/navigation/PrivateRoute';
import { useUserStore } from 'infrastructure/user/userStore';
import { ExtractionActiveTab } from 'domain/extraction/enums';
import { Mine } from './Mine';

export const useBuildExtractionRouteObject = () => {
  const { userPermissions } = useUserStore();

  return {
    path: ROUTES.mine,
    element: <Mine />,
    children: [
      {
        path: ROUTES.terrain,
        index: true,
        element: (
          <PrivateRoute
            isAllowed={!!userPermissions?.granted_permissions.views.includes(ExtractionActiveTab.TERRAIN)}
            redirectPath={ROUTES.areasList}
          >
            <Extraction />
          </PrivateRoute>
        ),
      },
    ],
  };
};
