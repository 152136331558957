import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { ROUTES } from 'common/navigation/routes';
import { SUBSCRIPTION_STATUS_QUERY_KEY } from 'infrastructure/subscription/constants';
import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { useUserStore } from 'infrastructure/user/userStore';
import { type SubscriptionType } from 'domain/subscription/types';
import { useGetActiveSubscription } from '../useGetActiveSubscription';

export const useUpgradeSubscription = (
  setErrorMessage: (message: string) => void,
  setLoading?: (state: boolean) => void,
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { subscriptionProductsList } = subscriptionRepository.useGetProductsList();
  const { handleSubscriptionUpgrade, isSubscriptionUpgrading } = subscriptionRepository.useUpgrade();
  const { activeSubscriptionData } = useGetActiveSubscription();

  useEffect(() => {
    setLoading && setLoading(isSubscriptionUpgrading);
  }, [isSubscriptionUpgrading, setLoading]);

  const getUpgradeData = (type: SubscriptionType) => {
    const targetSubscription = subscriptionProductsList.find((product) => product.name === type);

    if (!targetSubscription || !activeSubscriptionData) return;

    return {
      addedQuota: targetSubscription.area_quota - activeSubscriptionData.capacity,
      price: `+${targetSubscription.price.symbol}${targetSubscription.price.amount - activeSubscriptionData.price} ${
        targetSubscription.price.currency
      } / Month`,
    };
  };

  const handleUpgrade = (subscriptionType: SubscriptionType) => {
    handleSubscriptionUpgrade(
      { name: subscriptionType, trial: false },
      {
        onSuccess: ({ data: subscriptionUpgradeResponse }) => {
          queryClient.invalidateQueries({ queryKey: [SUBSCRIPTION_STATUS_QUERY_KEY] });
          setClientSecret(subscriptionUpgradeResponse.client_secret);
          setSessionId(subscriptionUpgradeResponse.session_id);
          navigate(ROUTES.paymentProcess);
        },
        onError: (error) => {
          setErrorMessage(`Unable to finish operation: ${error}`);
        },
      },
    );
  };

  const { setClientSecret, setSessionId } = useUserStore();

  return { getUpgradeData, handleUpgrade };
};
