import { type LayerManagerSliderConfig } from 'common/types';
import { MINERALIZATION_SLIDER_CONFIG } from 'domain/exploration/configs';

export const useGetMineralizationSliderConfig = (classifierId: number): LayerManagerSliderConfig | undefined => {
  switch (classifierId) {
    case 1:
      return MINERALIZATION_SLIDER_CONFIG.sam;
    case 2:
      return MINERALIZATION_SLIDER_CONFIG.mtmf;
    default:
      return undefined;
  }
};
