import { useMemo } from 'react';
import chroma from 'chroma-js';

import { pixelValueToHexHelper } from 'common/utils/color';
import { useGraphStore } from 'components/graph/graphStore';
import { type ColormapDictionary, type Limits } from 'common/types';

export const useSelectedMapPoint = (colormap: ColormapDictionary | undefined, limits: Limits) => {
  const scale = useMemo(() => {
    const colormapEntries = colormap && Object.entries(colormap);
    const sortedColormapEntries = colormapEntries && colormapEntries.sort((a, b) => Number(a[0]) - Number(b[0]));
    const colors = sortedColormapEntries && sortedColormapEntries.map((entry) => entry[1]);
    const domain = sortedColormapEntries && sortedColormapEntries.map((entry) => Number(entry[0]));

    if (!colors || !domain) return chroma.scale(['#000000', '#ffffff']).domain([0, 1]);
    return chroma.scale(colors).domain(domain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colormap]);

  const selectedPoint = useGraphStore.use.chosenPoint();

  if (!selectedPoint) {
    return undefined;
  }

  const center = [selectedPoint.lat, selectedPoint.lng];
  const color = pixelValueToHexHelper(selectedPoint.value, limits, scale);

  return { center, color };
};
