import { GeoJSONLayer, Layer } from 'components/map';
import { areasRepository } from 'infrastructure/areas/areasRepository';

interface ShapeProps {
  id: number;
  url: string;
  name: string;
  color: string;
  isVisible: boolean;
  zIndex: number;
}

export const Shape = ({ id, url, name, color, isVisible, zIndex }: ShapeProps) => {
  const { geoJsonShape } = areasRepository.useFetchGeoJsonShape(id, url);

  if (!geoJsonShape) return null;

  return (
    <Layer visible={isVisible} name={name} zIndex={zIndex}>
      <GeoJSONLayer pane={name} geoJson={geoJsonShape} color={color} />
    </Layer>
  );
};
