import { getReadableArea } from 'common/utils/leafletDraw';
import { CreatorOptionsColumn } from 'ui/creator/creatorOptionsColumn';
import { ContentInteractive, OptionButton } from 'ui/creator/optionButton';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { useAreaRequestStatus } from 'views/areas/areaRequestNew/useAreaRequestStatus';
import { useSubscriptions } from 'views/subscriptions/useSubscriptions';
import { useSubscriptionColumnLoading } from './useSubscriptionColumnLoading';

export const SubscriptionsColumn = () => {
  const selectedArea = useAreaRequestStore.use.area();

  const { subscriptionOptions } = useSubscriptions();

  const { isLoading } = useSubscriptionColumnLoading();

  useAreaRequestStatus();

  return (
    <>
      <CreatorOptionsColumn header="Select a subscription plan" width={300} isLoading={isLoading}>
        {subscriptionOptions.map(
          ({ name, label, warning, annotation, info, topInfo, selected, content, disabled, onClick }) => (
            <OptionButton
              key={name}
              {...{ label, selected, disabled, onClick }}
              variant="RADIOBOX"
              disabledPartly={warning}
            >
              <ContentInteractive {...{ annotation, info, label, warning, topInfo, selected }}>
                {content}
              </ContentInteractive>
            </OptionButton>
          ),
        )}
        {/* TODO: handle custom option below when custom contract flow handled */}
        <OptionButton key={999} label="Custom option" selected={false} disabled variant="RADIOBOX" onClick={() => {}}>
          <ContentInteractive
            {...{
              annotation: `Selected Area: ${selectedArea !== undefined ? getReadableArea(selectedArea) : 'N/A'}`,
              info: "Let's talk about individual contract.",
              label: 'custom option',
              warning: false,
            }}
          >
            {'We will contact you to discuss the details.'}
          </ContentInteractive>
        </OptionButton>
      </CreatorOptionsColumn>
    </>
  );
};
