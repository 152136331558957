import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { DATE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { type useDisplacementsTimeline } from './useDisplacementsTimeline';

export const useCogFixer = (timelineData: ReturnType<typeof useDisplacementsTimeline>['timelineData']) => {
  const { areaId } = useParams();
  const dateQuery = useQueryParameter(DATE_QUERY_PARAMETER);
  const replaceQueryParameter = useReplaceQueryParameters();

  const timestamp = dateQuery || timelineData.timestamp || timelineData.timestamps.at(-1) || undefined;
  const { aoiSatelliteUrl } = areasRepository.useFetchCurrentSatelliteImageUrl(Number(areaId), timestamp);
  useEffect(() => {
    if (aoiSatelliteUrl && timestamp) {
      replaceQueryParameter([{ key: DATE_QUERY_PARAMETER, value: timestamp || null }]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoiSatelliteUrl, timestamp]);
};
