import { LabeledCheckbox, Slider } from 'ui';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { AuxiliaryControlsWrapper, LabeledSliderWrapper, LegendItemWraper } from './styled';
import { useLayerManagerStore } from '../layerManagerStore';

interface TopographyLayersGroupProps {
  areaId: number;
  areaName: string;
}

export const TopographyLayersGroup = ({ areaId, areaName }: TopographyLayersGroupProps) => {
  const { aoiCog: demCog } = areasRepository.useFetchAreaOfInterestCog(areaId, { productType: 'DEM' });
  const { layersList } = areasRepository.useFetchTopographyLayers(areaName);

  const isHillshadeVisible = useLayerManagerStore.use.isHillshadeVisible();
  const toggleHillshadeVisibility = useLayerManagerStore.use.toggleHillshadeVisibility();
  const hillshadeOpacity = useLayerManagerStore.use.hillshadeOpacity();
  const setHillshadeOpacity = useLayerManagerStore.use.setHillshadeOpacity();
  const isDemVisible = useLayerManagerStore.use.isDemVisible();
  const toggleDemVisibility = useLayerManagerStore.use.toggleDemVisibility();
  const demOpacity = useLayerManagerStore.use.demOpacity();
  const setDemOpacity = useLayerManagerStore.use.setDemOpacity();

  const onTopographyOpacityChange = (value: number | number[]) => {
    setDemOpacity(Array.isArray(value) ? value[0] : value);
  };

  const onHillshadeOpacityChange = (value: number | number[]) => {
    setHillshadeOpacity(Array.isArray(value) ? value[0] : value);
  };

  return (
    <>
      {demCog && (
        <LegendItemWraper>
          <LabeledCheckbox variant="WIDE" onChange={toggleDemVisibility} checked={isDemVisible}>
            hypsometric
          </LabeledCheckbox>
          <AuxiliaryControlsWrapper disabled={!isDemVisible}>
            <LabeledSliderWrapper>
              opacity
              <Slider
                ariaLabel="Opacity slider"
                marks={{
                  '0': '',
                  '1': '',
                }}
                value={demOpacity}
                onChange={onTopographyOpacityChange}
                defaultValue={1}
                min={0}
                max={1}
                step={0.01}
              />
            </LabeledSliderWrapper>
          </AuxiliaryControlsWrapper>
        </LegendItemWraper>
      )}
      {!!layersList.length && (
        <LegendItemWraper>
          <LabeledCheckbox variant="WIDE" onChange={toggleHillshadeVisibility} checked={isHillshadeVisible}>
            hillshade
          </LabeledCheckbox>
          <AuxiliaryControlsWrapper disabled={!isHillshadeVisible}>
            <LabeledSliderWrapper>
              opacity
              <Slider
                ariaLabel="Opacity slider"
                marks={{
                  '0': '',
                  '1': '',
                }}
                value={hillshadeOpacity}
                onChange={onHillshadeOpacityChange}
                defaultValue={1}
                min={0}
                max={1}
                step={0.01}
              />
            </LabeledSliderWrapper>
          </AuxiliaryControlsWrapper>
        </LegendItemWraper>
      )}
    </>
  );
};
