import { type Polygon, type Feature, type Position } from 'geojson';

export const createPolygon = (points: Position[]) => {
  const polygon = {
    type: 'Feature' as Feature['type'],
    geometry: {
      type: 'Polygon',
      coordinates: [points],
    },
    properties: {},
  };

  return polygon as Feature<Polygon>;
};

export const createPolygonJsonFromPoints = (points: Position[]) => {
  const nodes = [points[0], [points[1][0], points[0][1]], points[1], [points[0][0], points[1][1]], points[0]];
  return createPolygon(nodes);
};
