import { type LayerObject } from 'common/types/mapData';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { GreeneryLayerManagerLegend } from '../../components/GreeneryLayerManagerLegend';

interface VegetationLayerManagerLegendWrapperProps {
  areaId: number;
  layerObjects: LayerObject[];
  viewSpecificDisabledLayers?: string[];
  noBadges?: boolean;
  chosenResultId: number;
}

export const VegetationLayerManagerLegendWrapper = (props: VegetationLayerManagerLegendWrapperProps) => {
  const { segmentationCogUrl: cogUrl } = greeneryRepository.useFetchSegmentationCog(props.chosenResultId);

  return <>{cogUrl && <GreeneryLayerManagerLegend {...props} cogUrl={cogUrl} />}</>;
};
