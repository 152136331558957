import { useAoiNumber } from 'common/navigation/hooks';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { areasRepository } from 'infrastructure/areas/areasRepository';

// TODO: remove after backend implementation, issue: https://github.com/FourPointSPZOO/terraeye-backend/issues/435
export const useFilteredSpectralClassifiers = () => {
  const areaId = useAoiNumber();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);
  const { spectralClassifiers } = explorationRepository.useFetchSpectralClassifiers();

  const areaName = areaOfInterestDetails?.properties.name;
  const filteredSpectralClassifiers = spectralClassifiers.filter(
    ({ name }) => !(name === 'euclidean_distance' && areaName !== 'fides_petrolina'),
  );

  return { spectralClassifiers: filteredSpectralClassifiers };
};
