import { Loading } from 'ui/molecules/loading';
import { useLoginService } from './loginService';

export const LoginAuto = () => {
  const { useHandleLoginUseCase } = useLoginService();

  const { isCheckingUserSession } = useHandleLoginUseCase();

  return isCheckingUserSession && <Loading zIndex={2000} />;
};
