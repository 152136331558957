import styled, { css } from 'styled-components';

import { type SpecialSelectProps } from '../types';
import { SELECT_DROPDOWN_ROW_HEIGHT } from '../constants';

interface DropdownItemProps extends SpecialSelectProps {
  selected: boolean;
}

export const DropdownItem = styled.div<DropdownItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: ${SELECT_DROPDOWN_ROW_HEIGHT}px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  transition: background-color ${({ theme }) => theme.transitionFunction.main};
  padding: 0 1rem;
  font-weight: 500;
  text-transform: capitalize;
  z-index: 1040;

  &:first-child {
    border-top: 2px solid ${({ theme }) => theme.color.mineShaftABitLighter};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.selectGray};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.color.selectGray};
      pointer-events: none;
    `};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.6;
      cursor: default;

      &:hover {
        background-color: inherit;
      }
    `};
`;
