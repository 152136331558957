import { Fragment, useRef } from 'react';
import styled, { css } from 'styled-components';

import { type StateValueAndLabel, type ValueAndLabel } from 'common/types';
import { type SpecialSelectProps } from 'ui/atoms/select/types';
import { selectComponentService } from './selectComponentService';
import { SELECT_DROPDOWN_ROW_HEIGHT } from './constants';
import { SelectDropdown } from './dropdown/Dropdown';
import { DropdownItem } from './dropdown';
import { Chevron } from '../chevron';
import { LoadingDots } from '../loadingDots';
import { Backdrop } from '../backdrop';

interface SelectProps {
  options: StateValueAndLabel[] | undefined;
  selectedOption: ValueAndLabel | undefined;
  name: string;
  isFetching?: boolean;
  initialStateLabel?: string;
  noOptionsLabel?: string;
  title?: string;
  onChange?: (option: ValueAndLabel) => void;
  reset?: () => void;
  minWidth?: number;
  maxHeight?: number;
  special?: boolean;
  searchable?: boolean;
  disabled?: boolean;
}

export const Select = ({
  options = [],
  selectedOption,
  isFetching = false,
  initialStateLabel = 'Initial state',
  noOptionsLabel = 'No Options',
  title,
  onChange,
  reset,
  name = 'generic dropdown',
  minWidth,
  maxHeight,
  special = false,
  searchable = false,
  disabled = false,
}: SelectProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const availableOptionsNumber = options.length;
  const haveOptions = !!availableOptionsNumber;
  const label = !haveOptions ? noOptionsLabel : initialStateLabel;

  const { isOpen, onSelectClicked, onOptionClicked, onResetOptionClicked } =
    selectComponentService.useHandleUserActions(wrapperRef, options, onChange, reset);

  return (
    <Fragment key={name}>
      <SelectWrapper ref={wrapperRef} special={special} $disabled={disabled}>
        {title && <Title>{title}</Title>}
        <SelectInnerWrapper special={special} minWidth={minWidth}>
          <SelectButton
            onClick={() => {
              !isFetching && !disabled && onSelectClicked();
            }}
            special={special}
            open={haveOptions && isOpen}
            selected={false}
            role="combobox"
            aria-label={name}
          >
            {isFetching ? (
              <LoadingDots />
            ) : (
              <>
                <SelectLabelWrapper>{selectedOption?.label || label}</SelectLabelWrapper>
                <ChevronWrapper>
                  <Chevron isFlipped={haveOptions && isOpen} width={14} marginLeft={10} />
                </ChevronWrapper>
              </>
            )}
          </SelectButton>
          {isOpen && (
            <SelectDropdown
              {...{
                options,
                haveOptions,
                selectedOption,
                onOptionClicked,
                onResetOptionClicked,
                isOpen,
                initialStateLabel,
                reset,
                special,
                maxHeight,
                searchable,
              }}
            />
          )}
        </SelectInnerWrapper>
      </SelectWrapper>
      {isOpen && special && <Backdrop zIndex={1010} />}
    </Fragment>
  );
};

const SelectWrapper = styled.div<SpecialSelectProps>`
  color: ${({ theme }) => theme.color.white};
  width: 100%;
  z-index: 1020;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.color.midGray};
    `};
`;

const Title = styled.div`
  font-weight: 600;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: 0 0.25rem 0 1rem;
`;

interface SelectInnerWrapperProps extends SpecialSelectProps {
  minWidth?: number;
}

const SelectInnerWrapper = styled.div<SelectInnerWrapperProps>`
  position: relative;
  min-width: ${({ minWidth }) => minWidth}px;
  width: 100%;
  margin: 0;
  height: ${SELECT_DROPDOWN_ROW_HEIGHT}px;

  ${({ special }) =>
    special &&
    css`
      z-index: 1040;
      margin: 0;
    `};
`;

interface SelectButtonProps extends SpecialSelectProps {
  open: boolean;
}

const SelectButton = styled(DropdownItem)<SelectButtonProps>`
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  border-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;

  &:first-child {
    border-top: none;
  }

  ${({ special }) =>
    special &&
    css`
      filter: opacity(0.8);
    `};

  ${({ open }) =>
    open &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${({ theme }) => theme.color.selectGray};
      filter: opacity(1);
    `};
`;

const ChevronWrapper = styled.div<{ dropdown?: boolean }>`
  color: ${({ theme }) => theme.color.scropion};

  ${({ dropdown }) =>
    dropdown &&
    css`
      color: ${({ theme }) => theme.color.silver};
    `};
`;

const SelectLabelWrapper = styled.div`
  overflow: hidden;
`;
