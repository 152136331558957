import { useEffect, useMemo, useState } from 'react';
import { LatLngBounds } from 'leaflet';

import { useAoiNumber } from 'common/navigation/hooks';
import { standarizeLatLngArray } from 'common/utils/map';
import { areasRepository } from 'infrastructure/areas/areasRepository';

export const useGetExtentPolygon = (cogUrl?: string) => {
  const areaId = useAoiNumber();
  const [isLoading, setIsLoading] = useState(true);

  const { areaOfInterestDetails, areaOfInterestDetailsLoading } = areasRepository.useFetchAreaOfInterestDetails(areaId);
  const polygon = areaOfInterestDetails?.geometry.coordinates[0];

  const standarizedExtentPolygon = useMemo(() => polygon && standarizeLatLngArray(polygon), [polygon]);

  useEffect(() => {
    setIsLoading(false);
  }, [areaOfInterestDetailsLoading, cogUrl, standarizedExtentPolygon]);

  const extentLatLngBounds = new LatLngBounds(standarizedExtentPolygon);

  return {
    extentPolygon: standarizedExtentPolygon,
    extentLatLngBounds,
    isInfoLoading: areaOfInterestDetailsLoading || isLoading,
  };
};
