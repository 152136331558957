import styled from 'styled-components';

import { ReactComponent as BaseSearchIcon } from 'assets/svg/search-icon.svg';
import { Input } from 'ui/atoms/input';
import { WithCustomScrollbarWrapper } from 'ui/storyUtils';
import { NotificationsGroup } from './NotificationsGroup';
import { useNotificationsMockData } from './useNotificationsMockData';

export const Notifications = () => {
  const notificationsCollection = useNotificationsMockData();

  return (
    <StyledNotifications>
      <InputWrapper>
        <SearchIcon />
        <NotificationsInput $variant="BASIC" placeholder="Search..." />
      </InputWrapper>

      <NotificationsContent>
        <AllNotifications>
          {notificationsCollection.map(({ id, title, notifications }) => (
            <NotificationsGroup key={id} title={title} notifications={notifications} />
          ))}
        </AllNotifications>
      </NotificationsContent>
    </StyledNotifications>
  );
};

const StyledNotifications = styled.div`
  padding: 30px 10px 30px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.color.selectGray};
  border-radius: 20px;
  margin-right: 10px;
`;

const NotificationsInput = styled(Input)`
  border-radius: 0;
  padding-left: 0;
  background-color: transparent;

  &::placeholder {
    color: ${({ theme }) => theme.color.white};
  }
`;

const SearchIcon = styled(BaseSearchIcon)`
  width: 18px;
  height: 18px;
  margin-left: 6px;
`;

const NotificationsContent = styled(WithCustomScrollbarWrapper)`
  margin: 16px 0 16px;
  padding: 16px 10px 0 0;
  height: 100%;
  overflow-y: auto;
`;

const AllNotifications = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-bottom: 20px;
`;
