import { theme } from 'common/theme';
import {
  type WarningError,
  type CountedWarningError,
  type DisplayableWarningError,
  type CombinedWarningError,
  type InitialWarningError,
} from 'domain/@errors/types';

export const ErrorsModel = {
  getAvailableCombinedWarningErrors: (
    initialErrors: InitialWarningError[],
    errors: WarningError[],
    pathname: string,
    strictErrorViewPath?: string,
  ): CombinedWarningError[] => {
    const countedErrors = errors.reduce((acc, curr) => {
      const existing = acc[curr.type];
      if (existing) {
        existing.counter += 1;
        return acc;
      }
      acc[curr.type] = { ...curr, counter: 1 };
      return acc;
    }, {} as Record<string, CountedWarningError>);

    const combinedErrors = Object.values(countedErrors)
      .map((error) => {
        const errorType = error.type;
        const initialError = initialErrors.find((error) => error.type === errorType);

        return initialError
          ? {
              ...error,
              ...initialError,
            }
          : undefined;
      })
      .filter(
        (error): error is CombinedWarningError =>
          !!error && (strictErrorViewPath ? error.viewPath === strictErrorViewPath : pathname.includes(error.viewPath)),
      )
      .map((error) => {
        let counter = error.counter;
        let priority = error.priority;

        if (error.isCount && error.allDataQty) {
          if (counter >= error.allDataQty) {
            counter = error.allDataQty;

            if (priority === 1) {
              priority = 2;
            }
          }
        }

        return {
          ...error,
          counter,
          priority,
        };
      })
      .sort((a, b) => b.priority - a.priority);

    return combinedErrors;
  },

  getDisplayableWarningErrors: (errors: CombinedWarningError[]) => {
    const displayableErrors = errors.map((error) => {
      let content = error.customContent ? error.customContent : error.content;

      if (error.isCount) {
        content += `: ${error.counter}`;

        if (error.allDataQty) {
          content += `/${error.allDataQty}`;
        }
      }

      if (error.errorCause) {
        content += ` (${error.errorCause})`;
      }

      return {
        content,
        priority: error.priority,
      };
    }) as DisplayableWarningError[];

    const highestPriority = displayableErrors[0]?.priority;

    const infoAreaIconColor =
      highestPriority === 2 ? theme.color.errorRed : highestPriority === 1 ? theme.color.errorYellow : undefined;

    const isInfoAreaToShow = highestPriority === 2;

    return { displayableErrors, infoAreaIconColor, isInfoAreaToShow };
  },

  getErrorCause: (status: string) => {
    switch (status) {
      case '400':
        return '400:\u00A0bad request syntax';
      case '401':
        return '401:\u00A0re-authentication needed';
      case '403':
        return "403:\u00A0current account doesn't have permission to this data";
      case '404':
        return '404:\u00A0data not found on server';
      case '500':
        return '500:\u00A0internal server error';
      default:
        return undefined;
    }
  },
};
