import { type ReactNode } from 'react';
import styled from 'styled-components';

import { DataRequestConditionalButton } from 'components';
import { PluggedInMapToolbox } from 'views/areas/PluggedInMapToolbox';

interface AreaDetailsLayoutProps {
  legend?: ReactNode;
  toolbarContent?: ReactNode;
  returnButton?: ReactNode;
  toolboxContent?: ReactNode;
  children?: ReactNode;
}

export const AreaDetailsLayout = ({
  legend,
  toolbarContent,
  returnButton,
  toolboxContent,
  children,
}: AreaDetailsLayoutProps) => {
  return (
    <>
      <RequestButtonWrapper>
        <DataRequestConditionalButton />
      </RequestButtonWrapper>
      <ToolbarWrapper>
        <MapControlsWrapper>
          {returnButton}
          <MapControlsInnerWrapper>{toolbarContent}</MapControlsInnerWrapper>
        </MapControlsWrapper>
        <MapLegendWrapper>{legend}</MapLegendWrapper>
      </ToolbarWrapper>
      <PluggedInMapToolbox>{toolboxContent}</PluggedInMapToolbox>
      {children}
    </>
  );
};

export const RequestButtonWrapper = styled.div`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  padding: 0.75rem 0.5rem 0;
  background: transparent;
  z-index: 1020;
  white-space: nowrap;
`;

const ToolbarWrapper = styled.div`
  background: transparent;
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-bottom: -50px;
`;

const MapControlsWrapper = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

const MapControlsInnerWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 10px;
`;

const MapLegendWrapper = styled.div`
  margin: 0 1.75rem;
  padding: 0.5rem 0;
  flex-grow: 1;
  max-width: 500px;
  z-index: 1008;
`;
