import styled from 'styled-components';

import { type LayerRange } from 'common/types';
import { Container } from 'ui';
import { ColorScaleBar } from 'ui/colorScale/ColorScaleBar';
import { PixelStatisticsInvisibilityIcon } from './PixelStatisticsInvisibilityIcon';
import { StatisticsDot, StatisticsValue } from './styled';

interface Props {
  label: string;
  value: number;
  colormap: Record<number, string>;
  layerRange: LayerRange;
  isBeyondThreshold?: boolean;
}

export const PixelStatisticsTableRow = ({
  label,
  value,
  colormap,
  layerRange: { min, max },
  isBeyondThreshold,
}: Props) => {
  return (
    <StyledTableRow grayed={isBeyondThreshold}>
      <td>
        <Container gap={8}>
          <StatisticsDot>
            <ColorScaleBar colormap={colormap} />
          </StatisticsDot>
          <StatisticsValue grayed={isBeyondThreshold}>{label}</StatisticsValue>
          {isBeyondThreshold && <PixelStatisticsInvisibilityIcon />}
        </Container>
      </td>
      <td>{value}</td>
      <td>{min}</td>
      <td>{max}</td>
    </StyledTableRow>
  );
};

const StyledTableRow = styled.tr<{ grayed?: boolean }>`
  > td:not(:first-child) {
    color: ${({ grayed, theme }) => grayed && theme.color.lightGray};
  }
`;
