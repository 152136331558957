import { InfoArea, useInfoAreaService } from 'ui/infoArea';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { AppLayerManager } from 'components/layerManager/legacy';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { SnowViewOptionsSelect } from 'views/water/snow/viewOptions';
import { useSnowCoverIndicators } from './useSnowCoverIndicators';
import { SnowCoverLayerManagerLegendItem } from './SnowCoverLayerManagerLegendItem';
import { SnowCoverTimeline } from './SnowCoverTimeline';
import { SnowCoverColorScale } from './SnowCoverColorScale';
import { useSnowViewOptions } from './viewOptions';
import { SnowCoverIndicatorsSelect } from './SnowCoverIndicatorsSelect';

export const SnowCover = () => {
  const { areaName } = useGetAoiName();
  const infoAreaProps = useInfoAreaService();
  const areReportsActive = useGetAreReportsActiveUseCase();

  const { selectedIndicator } = useSnowCoverIndicators();

  const { isIndicatorsView } = useSnowViewOptions();

  return (
    <AreaDetailsLayout
      legend={
        isIndicatorsView && selectedIndicator ? (
          <WithCogBandRange
            Component={SnowCoverColorScale}
            props={{
              colormap: selectedIndicator.colormap,
              unit: selectedIndicator.unit,
            }}
            url={selectedIndicator.source_url}
            precision={selectedIndicator.precision}
          />
        ) : undefined
      }
      toolbarContent={
        <>
          <InfoArea {...infoAreaProps}>{isIndicatorsView && selectedIndicator?.description}</InfoArea>
          <SnowViewOptionsSelect />
          {!areReportsActive && isIndicatorsView && <SnowCoverIndicatorsSelect />}
        </>
      }
      toolboxContent={!areReportsActive && <LayerManagerControlButton />}
    >
      {!areReportsActive && (
        <AppLayerManager>
          <SnowCoverLayerManagerLegendItem indicator={isIndicatorsView ? selectedIndicator : undefined} />
        </AppLayerManager>
      )}
      {areaName && <SnowCoverTimeline areaName={areaName} visible={!areReportsActive && !isIndicatorsView} />}
    </AreaDetailsLayout>
  );
};
