import { type ReactNode } from 'react';

import { PrivateRoute } from 'common/navigation/PrivateRoute';
import { ROUTES } from 'common/navigation/routes';
import { useGetActiveSubscription } from './useGetActiveSubscription';

type Props = {
  startPageChecking: boolean;
  children: ReactNode;
};

export const SubscriptionsRoutePermission = ({ startPageChecking, children }: Props) => {
  const { activeSubscriptionData } = useGetActiveSubscription();

  const isAllowed = startPageChecking ? !activeSubscriptionData : !!activeSubscriptionData;
  const redirectPath = startPageChecking ? ROUTES.subscriptionsManage : ROUTES.subscriptionsStart;

  return <PrivateRoute {...{ isAllowed, redirectPath }}>{children}</PrivateRoute>;
};
