import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSortedNestedLayersData = <T extends Record<string, any>>(
  layersOrderRecord: Record<string, Record<string, number[]>> | null,
  subkey: string,
  data: T[],
  idKey: string,
) => {
  const [sortedData, setSortedData] = useState(data);

  const location = useLocation();

  useEffect(() => {
    const checkisKeyIdValid = data.every((item) => item[idKey] !== undefined);

    if (!checkisKeyIdValid) {
      throw new Error('idKey is not valid');
    }

    const optionsIds = layersOrderRecord ? layersOrderRecord[location.pathname]?.[subkey] : [];

    if (!optionsIds?.length && !!data?.length) {
      setSortedData(data);
      return;
    }

    let newMineralsData: T[] | undefined;

    if (!!optionsIds?.length && !!data?.length) {
      newMineralsData = optionsIds
        .map((id) => {
          return data.find((item) => item[idKey] === id);
        })
        .filter((option) => option !== undefined) as typeof data;

      const newOptions = data.filter((item) => {
        return !optionsIds.includes(item[idKey]);
      });

      if (newOptions.length > 0) {
        newMineralsData = [...newOptions, ...newMineralsData];
      }
    }

    setSortedData(newMineralsData || data);
  }, [data, layersOrderRecord, location.pathname, idKey]); // eslint-disable-line react-hooks/exhaustive-deps
  return sortedData;
};
