import MDEditor from '@uiw/react-md-editor';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CustomMathJax } from './CustomMathJax';

export const MarkdownMathPreview = ({ content }: { content: string }) => {
  const location = useLocation();

  return (
    <CustomMathJax key={location.key} dynamic>
      <StyledMarkdownEditor source={content} />
    </CustomMathJax>
  );
};

const StyledMarkdownEditor = styled(MDEditor.Markdown)`
  background-color: inherit;
  font-size: inherit;
`;
