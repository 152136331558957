/* eslint-disable @typescript-eslint/no-explicit-any */
import { type CreatorDataColumnData } from 'ui/creator/types';

export const isCreatorDataColumnDataValid = (data: any): data is CreatorDataColumnData[] => {
  return (
    data &&
    Array.isArray(data) &&
    data.every(
      (item: any) =>
        typeof item.id === 'number' &&
        typeof item.label === 'string' &&
        typeof item.checked === 'boolean' &&
        typeof item.onChange === 'function',
    )
  );
};
