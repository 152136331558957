import { useMutation, useQuery } from '@tanstack/react-query';

import { userAPI } from 'infrastructure/user/userApiAdapter';
import {
  USER_DETAILS_QUERY_KEY,
  USER_PERMISSIONS_QUERY_KEY,
  USER_SUBSCRIPTION_DETAILS_QUERY_KEY,
} from 'domain/user/constants';
import { areUserPermissionsValid, areUserSubscriptionDetailsValid } from 'domain/user/typeguards';

export const userRepository = {
  useSignUp: () => {
    const { mutate, status, error } = useMutation(userAPI.signUp);

    return {
      mutate,
      status,
      error,
    };
  },

  useLoginUser: () => {
    const { mutate, data, isLoading, error } = useMutation(userAPI.login);

    return {
      mutate,
      data,
      isLoading,
      error,
    };
  },

  useGetUserDetails: () => {
    const { data, isLoading, isFetchedAfterMount, error } = useQuery([USER_DETAILS_QUERY_KEY], userAPI.getUserDetails, {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: 'always',
      retry: false,
      refetchOnWindowFocus: false,
    });

    return {
      data,
      isLoading,
      isFetchedAfterMount,
      error,
    };
  },

  useGetUserPermissions: (userId: number) => {
    const { data, isLoading, error } = useQuery(
      [USER_PERMISSIONS_QUERY_KEY, { userId }],
      () => userAPI.getUserPermissions(userId),
      {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: 'always',
      },
    );

    const userPermissions = data && areUserPermissionsValid(data.data) ? data.data : undefined;

    if (error) {
      console.error('Error while fetching user permissions', error);
    }

    if (!isLoading && !userPermissions) {
      console.error('User permissions are not valid');
    }

    return {
      userPermissions,
      isLoading,
      error,
    };
  },

  usePasswordReset: () => {
    const { mutate, data, isLoading, error } = useMutation(userAPI.resetPassword, {
      onMutate: () => {
        if (!navigator.onLine) {
          throw new Error('No internet connection');
        }
      },
    });

    return {
      mutate,
      data,
      isLoading,
      error,
    };
  },

  useChangePassword: () => {
    const { mutate, data, status, error } = useMutation(userAPI.changePassword);

    return {
      mutate,
      data,
      status,
      error,
    };
  },

  useLogoutUser: () => {
    const logoutMutation = useMutation(userAPI.logout);

    return logoutMutation;
  },

  useFetchUserSubscriptionDetails: () => {
    const { data, isLoading } = useQuery(
      [USER_SUBSCRIPTION_DETAILS_QUERY_KEY],
      () => userAPI.getUserSubscriptionDetails(),
      {
        retry: 1,
        staleTime: 0,
        cacheTime: 0,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
      },
    );

    const userSubscriptionDetails = areUserSubscriptionDetailsValid(data) ? data.data : undefined;

    return {
      userSubscriptionDetails,
      areUserSubscriptionDetailsLoading: isLoading,
    };
  },
};
