import { Layer } from 'components/map';
import { MAP_DATA_LAYER } from 'common/constants';
import { useGraphStore } from 'components/graph/graphStore';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { Loading } from 'ui/molecules/loading';
import { GeoKmzLayer } from 'views/displacements/mapContent/GeoKmzLayer';
import { useKmzData } from './useKmzData';
import { useActiveMarker } from './useActiveMarker';
import { useDisplacementsQuery } from './useDisplacementsQuery';

export const SubsidenceMapContent = () => {
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();

  const kmzId = useDisplacementsStore.use.kmzId();
  const openedMarkerData = useGraphStore.use.graphData();

  useDisplacementsQuery();

  const { kmzLoadingState, isGeoDataAvailable, geoData, levelsOfDetail, selectedGeoJSONData, setSelectedGeoJSONData } =
    useKmzData(kmzId);

  useActiveMarker(isDataLayerVisible);

  return (
    <>
      <Layer visible={isDataLayerVisible} name={MAP_DATA_LAYER} zIndex={490}>
        <GeoKmzLayer
          geoData={geoData}
          isGeoDataAvailable={isGeoDataAvailable}
          levelsOfDetail={levelsOfDetail}
          selectedGeoJSONData={selectedGeoJSONData}
          setSelectedGeoJSONData={setSelectedGeoJSONData}
          pane={MAP_DATA_LAYER}
          openedMarkerData={openedMarkerData as string | undefined} // TODO: fix type
        />
      </Layer>
      {kmzLoadingState !== 'DEFAULT' && <Loading variant={kmzLoadingState} />}
    </>
  );
};
