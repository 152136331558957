import { useAreaRequestStore } from './AreaRequestStore';

export const useDeleteFeatures = () => {
  const resetFeatures = useAreaRequestStore.use.resetFeatures();
  const resetStatus = useAreaRequestStore.use.resetStatus();
  const resetArea = useAreaRequestStore.use.resetArea();
  const disableDeleteMode = useAreaRequestStore.use.disableDeleteMode();

  const deleteFeatures = () => {
    resetFeatures();
    resetArea();
    resetStatus();
    disableDeleteMode();
  };

  return { deleteFeatures };
};
