import { AreaEnvironments } from 'common/navigation/constants';
import { ROUTES } from 'common/navigation/routes';
import { useMonitoringInfoStore } from 'views/monitoring/store';
import type { NotificationType } from 'domain/monitoring/types';

export const useNotificationsMockData = () => {
  const currentlyOpenNotifications = useMonitoringInfoStore.use.notifications();

  const areaId = '364';

  const notificationsPlaceholder: NotificationType[] = [
    {
      properties: {
        id: 1,
        text: 'Fast ground elevation change',
        view: AreaEnvironments.DISPLACEMENT,
        danger: true,
        route: ROUTES.subsidence,
      },
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [0, 0],
      },
    },
    {
      properties: {
        id: 2,
        text: 'Drop in NDVI Value',
        view: AreaEnvironments.LAND_COVER,
        danger: false,
        route: `${ROUTES.vegetation.replace(':areaId', areaId)}?greenery-indicator=1`,
      },
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [0, 0],
      },
    },
    {
      properties: {
        id: 3,
        text: 'Drop in NDII Value',
        view: AreaEnvironments.LAND_COVER,
        danger: false,
        route: `${ROUTES.vegetation.replace(':areaId', areaId)}?greenery-indicator=2`,
      },
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [0, 0],
      },
    },
  ];

  const notificationsCollection: {
    id: number;
    title: string;
    notifications: NotificationType[] | undefined;
  }[] = [
    {
      id: 1,
      title: 'Currently open area name',
      notifications: currentlyOpenNotifications?.properties.notifications,
    },
    {
      id: 2,
      title: 'Alphabetically 1st Area Name',
      notifications: notificationsPlaceholder,
    },
    {
      id: 3,
      title: 'Alphabetically 2nd Area Name',
      notifications: notificationsPlaceholder,
    },
    {
      id: 4,
      title: 'Alphabetically 3rd Area Name',
      notifications: notificationsPlaceholder,
    },
    {
      id: 5,
      title: 'Alphabetically 4th Area Name',
      notifications: notificationsPlaceholder,
    },
  ];

  return notificationsCollection;
};
