import styled, { css } from 'styled-components';

import { ContactLink, Container, LoadingDots, ScrollbarWrapper, TitledSection } from 'ui';
import { useUserStore } from 'infrastructure/user/userStore';
import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { type SubscriptionType, type ActiveSubscription } from 'domain/subscription/types';
import { SubscriptionUpgrade } from '../SubscriptionUpgrade';
import { SubscriptionCancel } from '../SubscriptionCancel';
import { SubscriptionPreview } from '../SubscriptionPreview';
import { useGetActiveSubscription } from '../useGetActiveSubscription';
import { getIsSubscriptionExpired } from '../helpers';
import { Notification } from '../styled';

export const SubscriptionsManage = () => {
  const { activeSubscriptionData, isActiveSubscriptionLoading } = useGetActiveSubscription();

  const isSubscriptionExpired = getIsSubscriptionExpired(activeSubscriptionData);

  const { subscriptionProductsList } = subscriptionRepository.useGetProductsList();

  const { isWaitingForSubscriptionCancel } = useUserStore();

  const upgradePossibilities =
    subscriptionProductsList.filter(
      ({ area_quota }) => area_quota && activeSubscriptionData && area_quota > activeSubscriptionData?.capacity,
    ) || [];

  const convertedPossibilities: (ActiveSubscription & { type: SubscriptionType; info: string })[] =
    upgradePossibilities.map(({ name, label, price, area_quota, info }) => ({
      type: name,
      name: label,
      price: price.amount,
      capacity: area_quota || 0,
      info,
      expires_at: null,
      canceled: false,
      currency: price.currency,
      areaQuota: area_quota || 0,
      status: 'active',
    }));

  return (
    <Wrapper>
      <StyledList>
        <Title>manage your subscription</Title>
        <SubscriptionsGrid>
          <TitledSection title="current subscription">
            {isActiveSubscriptionLoading && (
              <Container mv={12}>
                <LoadingDots />
              </Container>
            )}
            {activeSubscriptionData?.canceled && !isSubscriptionExpired && (
              <Notification>subscription cancelled</Notification>
            )}
            {isSubscriptionExpired && <Notification>subscription has expired</Notification>}
            {activeSubscriptionData && (
              <SubscriptionPreview
                subscription={activeSubscriptionData}
                variant="EXTENDED"
                info={
                  subscriptionProductsList.find(
                    ({ name }) => name === activeSubscriptionData.name.split(' ')[0].toLowerCase(),
                  )?.info
                }
              />
            )}
            <Container mt={30}>
              <SubscriptionCancel
                disabled={!activeSubscriptionData || activeSubscriptionData.canceled}
                setErrorMessage={console.dir}
                setIsSubscriptionCanceling={console.dir}
                isCancelingSubscriptionCombined={isWaitingForSubscriptionCancel}
                expiryDate={activeSubscriptionData?.expires_at || null}
                large
              />
            </Container>
          </TitledSection>
          {convertedPossibilities.length ? (
            <TitledSection title="upgrade plan">
              <UpgradeOptionsList>
                {convertedPossibilities.map((option, index) => (
                  <UpgradeOptionsListItem key={option.type} last={index === convertedPossibilities.length - 1}>
                    <SubscriptionPreview key={option.name} subscription={option} variant="DEFAULT" info={option.info} />
                    <Container mt={30}>
                      <SubscriptionUpgrade
                        subscriptionType={option.type}
                        disabled={isWaitingForSubscriptionCancel}
                        setErrorMessage={console.dir}
                        setLoading={console.dir}
                        message="upgrade to this plan"
                        large
                      />
                    </Container>
                  </UpgradeOptionsListItem>
                ))}
              </UpgradeOptionsList>
            </TitledSection>
          ) : null}
          <div>
            {/* <TitledSection title="add more calculation points">
              <AdditionalInfoSection>
                <SubTitle>Need even more Calculation Points for this month? No worries we got you covered.</SubTitle>
                <AdditionalInfoParagraph>
                  We offer additional point options for one-time payment of $50 or $99. Those points will not carry over
                  to the next month and will not change your subscription plan. They will be marked as an additional
                  charge on top of your plan for this month.
                </AdditionalInfoParagraph>
                <Button variant="PRIMARY">
                  <ButtonContent>+ 1000 exploration points / $75</ButtonContent>
                </Button>
              </AdditionalInfoSection>
            </TitledSection> */}
            <TitledSection title="need a custom solution?">
              <AdditionalInfoSection>
                <AdditionalInfoParagraph>
                  We are here to help! Feel free to reach out to us if you need custom types of calculations, custom
                  amounts of points or an enterprise solution.
                </AdditionalInfoParagraph>
                <ContactLink
                  message={{
                    url: 'sales@terraeye.co',
                    title: 'Inquiry about your products',
                    body: 'Hello,\n\nI am interested in learning more about your products.\n\nBest regards,\nYour Name',
                  }}
                />
              </AdditionalInfoSection>
            </TitledSection>
          </div>
        </SubscriptionsGrid>
      </StyledList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  padding: 12px 20px 120px 0;
`;

const StyledList = styled(ScrollbarWrapper)`
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    margin: 110px 0 20px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  @-moz-document url-prefix() {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.color.midGray} transparent;
    scrollbar-gutter: stable;
  }
`;

const Title = styled.h1`
  text-transform: capitalize;
`;

const SubscriptionsGrid = styled.article`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  margin-right: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoint.xl}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    grid-template-columns: 1fr;
  }
`;

const UpgradeOptionsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const UpgradeOptionsListItem = styled.li<{ last?: boolean }>`
  padding-bottom: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGray};

  ${({ last }) =>
    last &&
    css`
      padding-bottom: 0;
      border: none;
    `}
`;

const AdditionalInfoSection = styled.section`
  max-width: 280px;
`;

const AdditionalInfoParagraph = styled.p`
  font-family: 'Barlow';
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 30px;
`;

// const SubTitle = styled.h2`
//   font-family: 'Barlow';
//   font-size: 14px;
//   line-height: 18px;
//   font-weight: 600;
//   margin-top: 18px;
// `;

// const ButtonContent = styled.span`
//   white-space: nowrap;
// `;
