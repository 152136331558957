import { useViewOptions } from 'common/navigation/useViewOptions';
import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';

export const useGreeneryViewOptions = () => {
  const clearComparatorUrl = useGreeneryStore.use.clearComparatorUrl();

  const props = useViewOptions(clearComparatorUrl);

  return props;
};
