import { mapIndicatorsToOptions } from 'common/utils';
import { areExtendedIndicatorsValid } from 'common/typeguards';
import { withIndicatorConfig, withIndicatorLabels } from 'common/decorators';
import { useDeleteQueryParameters, useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { GREENERY_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { VEGETATION_INDICATORS_CONFIGURATION } from 'domain/greenery/configs';

export const useVegetationIndicators = () => {
  const { greeneryIndicators, greeneryIndicatorsLoading } = greeneryRepository.useFetchGreeneryIndicators();

  const indicatorsWithConfig = withIndicatorConfig(greeneryIndicators, VEGETATION_INDICATORS_CONFIGURATION);
  const indicatorsWithLabels: unknown = withIndicatorLabels(indicatorsWithConfig, false);
  const extendedIndicators = areExtendedIndicatorsValid(indicatorsWithLabels) ? indicatorsWithLabels : [];

  const selectedIndicatorId = useQueryParameter(GREENERY_INDICATOR_QUERY_PARAMETER);
  const selectedIndicator = extendedIndicators.find(({ id }) => selectedIndicatorId === String(id));

  const indicatorOptions = mapIndicatorsToOptions(extendedIndicators);

  const replaceQueryParameter = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeIndicator = (value: string | number): void => {
    if (value) {
      replaceQueryParameter([
        {
          key: GREENERY_INDICATOR_QUERY_PARAMETER,
          value: String(value),
        },
      ]);
    }
  };

  const resetIndicator = (): void => {
    deleteQueryParameters([GREENERY_INDICATOR_QUERY_PARAMETER]);
  };

  return {
    indicators: extendedIndicators,
    selectedIndicator,
    indicatorOptions,
    areIndicatorsLoading: greeneryIndicatorsLoading,
    changeIndicator,
    resetIndicator,
  };
};
