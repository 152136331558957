import { type LayerObject } from 'common/types/mapData';

export const layerManagerOptionsBuildHelper = (objectsConfiguration: LayerObject[], onlyVisibleIds?: number[]) => {
  const options = objectsConfiguration.map(({ id, name }) => {
    const visibility = onlyVisibleIds ? !!onlyVisibleIds?.some((visibleId) => visibleId === id) : true;
    return [name, visibility];
  });

  return Object.fromEntries(options);
};

export const areValuesEqual = (firstValue: number | number[], secondValue: number | number[]): boolean => {
  if (typeof firstValue === 'number' && typeof secondValue === 'number' && firstValue === secondValue) {
    return true;
  }

  if (
    Array.isArray(firstValue) &&
    firstValue.length === 2 &&
    Array.isArray(secondValue) &&
    secondValue.length === 2 &&
    firstValue[0] === secondValue[0] &&
    firstValue[1] === secondValue[1]
  ) {
    return true;
  }

  return false;
};
