import { type ReactNode } from 'react';
import MDEditor from '@uiw/react-md-editor';
import styled from 'styled-components';

import { type DisplayableMapPoint } from 'common/types/mapData';
import { type AreaEnvironmentsTypes, type AreaType } from 'common/navigation/types';
import { AreaDataBadge, Drawer } from 'ui';

interface Props {
  label: string;
  environments: AreaEnvironmentsTypes[];
  type: AreaType;
  location: DisplayableMapPoint;
  size: number;
  createdDate: string;
  description: string;
  children?: ReactNode;
}

export const AreaInfoDrawer = ({
  label,
  environments,
  type,
  location,
  size,
  createdDate,
  description,
  children,
}: Props) => {
  return (
    <Drawer buttonLabelShow="show info" buttonLabelHide="hide info" contentInitialHeight={200}>
      <Layout>
        <LeftContent>
          <Header>
            <Label>{label}</Label>
            <AreaDataBadge {...{ environments, type }} />
          </Header>
          <Details>
            <span>
              Location: {location.lat}, {location.lng}
            </span>
            <span>
              Area Size: {size} km<sup>2</sup>
            </span>
            <span>Date Added: {createdDate}</span>
          </Details>
          {description && <MarkdownDescription source={description} />}
        </LeftContent>
        {children}
      </Layout>
    </Drawer>
  );
};

const Layout = styled.section`
  display: flex;
  gap: 26px;
  padding: 20px;
`;

const LeftContent = styled.main`
  flex-grow: 1;
`;

const Header = styled.header`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const Label = styled.h3`
  font-family: 'Barlow Condensed';
  font-size: 30px;
  font-weight: 600;
`;

const Details = styled.p`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: 'Barlow';
  font-size: 14px;
  font-weight: 400;
  margin: 12px 0 0;
`;

const MarkdownDescription = styled(MDEditor.Markdown)`
  max-width: 800px;
  font-family: 'Barlow';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: inherit;
  color: inherit;
  margin-top: 30px;

  strong {
    font-weight: 700;
  }
`;
