import { Font, PDFViewer, Document, Page } from '@react-pdf/renderer';
import styled, { createGlobalStyle } from 'styled-components';

import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { commonStyles } from 'views/reports/external/pdfGenerator/styles';
import { Button } from 'ui/atoms/button';
import BarlowSemiBold from 'assets/fonts/Barlow/Barlow-SemiBold.ttf';
import BarlowRegular from 'assets/fonts/Barlow/Barlow-Regular.ttf';

Font.register({
  family: 'Barlow',
  fonts: [
    {
      src: BarlowRegular,
      fontWeight: 400,
    },
    {
      src: BarlowSemiBold,
      fontWeight: 600,
    },
  ],
});

export const ReportPdf = ({ children }: { children: JSX.Element }) => {
  const setReportScreenshotSrc = useReportsStore.use.setReportScreenshotSrc();
  const setIsReportGenerating = useReportsStore.use.setIsReportGenerating();

  const handleReturnButtonClick = () => {
    setIsReportGenerating(false);
    setReportScreenshotSrc(undefined);
  };

  return (
    <Wrapper>
      <GlobalStyle />
      <StyledPdfViewer>
        <Document>
          <Page style={commonStyles.body}>{children}</Page>
        </Document>
      </StyledPdfViewer>
      <ButtonWrapper>
        <Button onClick={handleReturnButtonClick}>back to app</Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Barlow';
    src: url(${BarlowRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  
  @font-face {
    font-family: 'Barlow';
    src: url(${BarlowSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }
`;

const StyledPdfViewer = styled(PDFViewer)`
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 9999;
`;
