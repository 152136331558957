import { type Scalars, type Exact, TypedDocumentString } from 'gql/graphql';

export type QueryGptChatQuery = {
  conversation: { id: string; object: string; created: number; model: string; choices: Array<unknown> };
};

export type QueryGptChatQueryVariables = Exact<{
  messages: Array<Scalars['JSON']['input']> | Scalars['JSON']['input'];
}>;

export const QueryGptChatDocument = new TypedDocumentString(`
  query queryGPTChat($messages: [JSON!]!) {
    conversation(messages: $messages) {
      id
      object
      created
      model
      choices
    }
  }
  
  `) as unknown as TypedDocumentString<QueryGptChatQuery, QueryGptChatQueryVariables>;
