import { useEffect } from 'react';

import { type Datetime } from 'common/types/time';
import { useTimelineService } from 'components/Timeline/timelineService';

export const useGetPreviousTimestamp = (
  timestamps: Datetime[],
  setPreviousTimestamp: (timestamp: Datetime) => void,
) => {
  const { timestamp } = useTimelineService();

  useEffect(() => {
    if (timestamp) {
      const currentDateIndex = timestamps.indexOf(timestamp);
      const previousDateIndex = currentDateIndex + 1;
      setPreviousTimestamp(timestamps[previousDateIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);
};
