import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';
import { GREENERY_DATA_PROVIDER_DEFAULT } from 'domain/greenery/configs';

export const useGreeneryDataProvider = () => {
  const dataProvider = useGreeneryStore.use.dataProvider();

  const isSentinelProvider = dataProvider === GREENERY_DATA_PROVIDER_DEFAULT.value;

  return {
    isSentinelProvider,
  };
};
