import { type FeatureCollection } from 'geojson';
import { type GeoJSONArray, type GeoJSONCollection } from 'domain/displacements/types';
import { concatGeoJSON, concatMultiGeoJSON } from './concatGeoJSON';
import { DISPLACEMENTS_QUANTITY_WHEN_SHOW_ALL, OVERRIDEN_LEVELS_OF_DETAILS } from '../constants';

export const getGeoDataBasedOnZoom = (
  geoData: GeoJSONCollection,
  currentLevelOfDetail: number,
  _levelsOfDetail: number[],
) => {
  const { twoByTwoPoints, fiveByFivePoints, twentyByTwentyPoints } = geoData;
  const [firstLevelOfDetail, secondLevelOFDetail] = OVERRIDEN_LEVELS_OF_DETAILS;
  const geoDataQuantity = getGeoDataQuantity([twoByTwoPoints, fiveByFivePoints, twentyByTwentyPoints]);

  switch (true) {
    case geoDataQuantity < DISPLACEMENTS_QUANTITY_WHEN_SHOW_ALL:
      return concatMultiGeoJSON([twoByTwoPoints, fiveByFivePoints, twentyByTwentyPoints]) as FeatureCollection;
    case currentLevelOfDetail < firstLevelOfDetail:
      return concatGeoJSON(twentyByTwentyPoints) as FeatureCollection;
    case currentLevelOfDetail < secondLevelOFDetail:
      return concatGeoJSON(fiveByFivePoints) as FeatureCollection;
    case currentLevelOfDetail >= secondLevelOFDetail:
      return concatGeoJSON(twoByTwoPoints) as FeatureCollection;
    default:
      return null;
  }
};

function getGeoDataQuantity(geoDataArray: GeoJSONArray[]) {
  return geoDataArray.reduce((acc, geoData) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    acc += geoData?.reduce((acc, layer: []) => (acc += layer?.features?.length || 0), 0) || 0;
    return acc;
  }, 0);
}
