import styled, { css } from 'styled-components';

import { DEFAULT_BACKDROP_Z_INDEX } from 'common/constants';
import { fadeIn } from 'common/animations';

export const Backdrop = styled.div<{ zIndex?: number; solid?: boolean }>`
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.blackTransparent40};
  animation: ${fadeIn} 1s ease-in-out;
  z-index: ${({ zIndex }) => zIndex || DEFAULT_BACKDROP_Z_INDEX};

  ${({ solid }) =>
    solid &&
    css`
      background-color: ${({ theme }) => theme.color.black};
    `}
`;
