import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { GREENERY_DATA_PROVIDER_DEFAULT } from 'domain/greenery/configs';

interface GreenerySliceState {
  comparatorUrl: string | undefined;
  setComparatorUrl: (url: string) => void;
  clearComparatorUrl: () => void;
  dataProvider: string;
  setDataProvider: (provider: string) => void;
  resetDataProvider: () => void;
}

const initialState = {
  comparatorUrl: undefined,
  dataProvider: GREENERY_DATA_PROVIDER_DEFAULT.value,
};

const useGreeneryStoreBase = create<GreenerySliceState>()(
  devtools(
    (set) => ({
      comparatorUrl: initialState.comparatorUrl,
      setComparatorUrl: (url) => set({ comparatorUrl: url }, false, 'greenery/setComparatorUrl'),
      clearComparatorUrl: () =>
        set({ comparatorUrl: initialState.comparatorUrl }, false, 'greenery/clearComparatorUrl'),
      dataProvider: initialState.dataProvider,
      setDataProvider: (provider) => set({ dataProvider: provider }, false, 'greenery/setDataProvider'),
      resetDataProvider: () => set({ dataProvider: initialState.dataProvider }, false, 'greenery/resetDataProvider'),
    }),
    {
      name: 'greeneryStore',
    },
  ),
);

export const useGreeneryStore = createSelectors(useGreeneryStoreBase);
