import { type ChangeEvent } from 'react';

import { useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import {
  FILTERS_ENVIRONMENT_QUERY_PARAM_NAME,
  FILTERS_STATUS_QUERY_PARAM_NAME,
  FILTERS_TAG_QUERY_PARAM_NAME,
  FILTERS_TYPE_QUERY_PARAM_NAME,
  SEARCH_QUERY_PARAM_NAME,
} from 'common/navigation/queryParams';
import { useGetAllAreasTags } from 'views/areas/areasList/getAllAreasTags';
import { type AreaOfInterest } from 'domain/areas/types';

export const areasListQueryService = {
  useFilterList: () => {
    const query = useQueryParameter(SEARCH_QUERY_PARAM_NAME);
    const replaceQueryParameters = useReplaceQueryParameters();

    const onQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
      replaceQueryParameters([
        {
          key: SEARCH_QUERY_PARAM_NAME,
          value: event.target.value.trim() !== '' ? event.target.value : null,
        },
      ]);
    };

    return { query, onQueryChange };
  },

  useGetFilteredList: (areasList: AreaOfInterest[]) => {
    const searchQuery = useQueryParameter(SEARCH_QUERY_PARAM_NAME);
    const statusQuery = useQueryParameter(FILTERS_STATUS_QUERY_PARAM_NAME);
    const tagQuery = useQueryParameter(FILTERS_TAG_QUERY_PARAM_NAME);
    const environmentQuery = useQueryParameter(FILTERS_ENVIRONMENT_QUERY_PARAM_NAME);
    const typeQuery = useQueryParameter(FILTERS_TYPE_QUERY_PARAM_NAME);

    const filteredAreasList = areasList.filter(
      ({ display_name, status, tags, type, environments }) =>
        display_name.toLowerCase().includes(searchQuery?.toLowerCase() || '') &&
        !statusQuery?.includes(status) &&
        (tags ? Object.keys(tags).some((tag) => !tagQuery?.split('-').includes(tag)) : true) &&
        (environments.length
          ? environments.some((environment) => !environmentQuery?.split('-').includes(environment))
          : true) &&
        !typeQuery?.includes(type),
    );

    return { filteredAreasList };
  },

  useOnAreaCardTagClick: () => {
    const allTags = useGetAllAreasTags();
    const replaceQueryParameters = useReplaceQueryParameters();

    const onAreaCardTagClick = (clickedTag: string) => {
      const rejectedTags = allTags.filter((tag) => tag !== clickedTag).join('-');

      replaceQueryParameters([
        {
          key: FILTERS_TAG_QUERY_PARAM_NAME,
          value: rejectedTags,
        },
      ]);
    };

    return { onAreaCardTagClick };
  },
};
