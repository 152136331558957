import styled from 'styled-components';

import { Comparator } from 'components';
import { useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGetWaterAreaComparatorCogsUseCase } from '../getWaterAreaComparatorCogsUseCase';
import { useWaterTimestamps } from '../../useWaterTimestamps';

export const WaterAreaComparator = () => {
  const { timestamps, areTimestampsLoading } = useWaterTimestamps();
  const { comparatorGenerateDisabled, updateComparatorCogs } = useGetWaterAreaComparatorCogsUseCase();
  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  return (
    <>
      <Offset exist={!!waterbodyIdQuery} />
      <Comparator
        variant="NEW_TO_OLDER"
        timestamps={timestamps}
        areTimestampsLoading={areTimestampsLoading}
        isCompareDisabled={comparatorGenerateDisabled}
        handleCompare={updateComparatorCogs}
      />
    </>
  );
};

const Offset = styled.div<{ exist: boolean }>`
  margin-top: ${({ exist }) => exist && 48}px;
  transition: margin-top ${({ theme }) => theme.transitionFunction.main};
`;
