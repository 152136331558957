/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type SessionDTO,
  type UserDetails,
  type UserDetailsResponse,
  type UserPermissionsDTO,
  type RefreshTokenDTO,
  type RefreshTokenResponse,
  type UserSubscriptionDetailsDTO,
} from './types';

export const areUserDetailsValid = (userDetails: any): userDetails is UserDetails => {
  return (
    typeof userDetails?.pk === 'number' &&
    typeof userDetails?.email === 'string' &&
    typeof userDetails?.first_name === 'string' &&
    typeof userDetails?.last_name === 'string'
  );
};

export const isUserDetailsResponseValid = (userDetailsResponse: any): userDetailsResponse is UserDetailsResponse => {
  return !!userDetailsResponse?.data && areUserDetailsValid(userDetailsResponse.data);
};

export const isRefreshTokenValid = (refreshToken: any): refreshToken is RefreshTokenDTO => {
  return typeof refreshToken?.access === 'string' && typeof refreshToken?.access_token_expiration === 'string';
};

export const isRefreshTokenResponseValid = (
  refreshTokenResponse: any,
): refreshTokenResponse is RefreshTokenResponse => {
  return !!refreshTokenResponse?.data && isRefreshTokenValid(refreshTokenResponse.data);
};

export const isSessionValid = (session: any): session is SessionDTO => {
  return (
    typeof session?.access_token === 'string' &&
    typeof session?.refresh_token === 'string' &&
    session?.user &&
    areUserDetailsValid(session.user)
  );
};

export const isSessionResponseValid = (sessionResponse: any): sessionResponse is SessionDTO => {
  return !!sessionResponse?.data && isSessionValid(sessionResponse.data);
};

export const areUserPermissionsValid = (userPermissions: any): userPermissions is UserPermissionsDTO => {
  return (
    typeof userPermissions?.id === 'number' &&
    typeof userPermissions?.email === 'string' &&
    (typeof userPermissions?.organization === 'number' || userPermissions?.organization === null) &&
    typeof userPermissions?.role === 'number' &&
    typeof userPermissions?.granted_permissions === 'object' &&
    Array.isArray(userPermissions?.granted_permissions?.views) &&
    userPermissions?.granted_permissions?.views.every((element: any) => typeof element === 'string')
  );
};

export const areUserSubscriptionDetailsValid = (data: any): data is UserSubscriptionDetailsDTO => {
  return (
    data?.data &&
    typeof data.data.user_id === 'number' &&
    typeof data.data.valid_from === 'string' &&
    typeof data.data.valid_to === 'string' &&
    typeof data.data.external_id === 'string' &&
    typeof data.data.quota_limit === 'number' &&
    typeof data.data.current_usage === 'number'
  );
};
