import { useEffect } from 'react';

import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';

export const useHandleErrorMessage = (error: unknown) => {
  const setRequestStatus = useAreaRequestStore.use.setRequestStatus();

  useEffect(() => {
    if (error && error instanceof Error) {
      setRequestStatus({ warning: error.message, mode: 'ERROR' });
      return;
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps
};
