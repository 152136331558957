import { ExplanationTile } from 'ui/creator/explanationTile';
import { type ExplanationColumnVariant } from 'domain/areas/types';

interface ExplanationColumnProps {
  variant: ExplanationColumnVariant;
}

export const ExplanationColumn = ({ variant }: ExplanationColumnProps) => {
  switch (variant) {
    case 'FIRST':
      return (
        <ExplanationTile
          header="First."
          description="Start by naming your new Prospecting Area and choosing the Year Quarter for analysis."
        />
      );
    case 'SECOND':
      return <ExplanationTile header="Second." description="Choose prospecting direction." />;
    case 'THIRD':
      return (
        <ExplanationTile
          header="Third."
          description="On the map, use the rectangle tool to select an area you want to analyze and your payment plan."
        />
      );
    case 'FOURTH':
      return (
        <ExplanationTile
          header="Fourth."
          description="Choose data providers."
          accent="For a tailored analysis made exactly to your needs and using multiple data sources."
          url="mailto:sales@terraeye.co"
        />
      );
    default:
      return null;
  }
};
