import { useRef } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as MinimizeIcon } from 'assets/svg/minimize.svg';
import { IconButton } from 'ui/atoms/iconButton';
import { ChatWelcomePage } from 'ui/chat/chatWelcomePage';
import { InputForm } from 'ui/molecules/inputForm';
import { WithCustomScrollbarWrapper } from 'ui/storyUtils';
import { type Status } from 'common/types';
import { ChatList } from './ChatList';
import { useChatStore } from '../chatStore';
import type { Message } from '../types';
import './markdownOverride.css';

interface ChatProps {
  messages: Message[];
  status: Status;
  promptChat: (messageContent: string) => void;
}

export const Chat = ({ messages, status, promptChat }: ChatProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const isChatFullscreen = useChatStore.use.isChatFullscreen();
  const closeChatFullscreen = useChatStore.use.closeChatFullscreen();

  const handleSend = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!inputRef.current) return;

    if (inputRef.current.value.trim()) {
      promptChat(inputRef.current.value);
    }

    inputRef.current.value = '';
    inputRef.current?.focus();
  };

  return (
    <ChatContainer isChatFullscreen={isChatFullscreen}>
      {isChatFullscreen && (
        <CloseChatButtonWrapper>
          <IconButton onClick={closeChatFullscreen} icon={<MinimizeIcon />} $iconSize={26} />
        </CloseChatButtonWrapper>
      )}
      <ChatWrapper>
        {messages.length === 0 ? (
          <ChatWelcomePage />
        ) : (
          <ChatList messages={messages} status={status} isChatFullscreen={isChatFullscreen} />
        )}
      </ChatWrapper>
      <FormWrapper isChatFullscreen={isChatFullscreen}>
        <InputForm
          variant="CHAT"
          inputRef={inputRef}
          name="chat-prompt"
          placeholder="ex. Which of all my sites in Australia have the biggest copper potential?"
          onSubmit={handleSend}
        />
      </FormWrapper>
    </ChatContainer>
  );
};

interface StyledChatProps {
  isChatFullscreen: boolean;
}

const ChatContainer = styled.div<StyledChatProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  height: 100%;
  padding: 20px 10px 20px 20px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.mineShaftDark};

  ${({ isChatFullscreen }) =>
    isChatFullscreen &&
    css`
      width: auto;
      position: fixed;
      inset: 0;
      left: 50px;
      padding: 55px 10px 20px 20px;
    `}
`;

const ChatWrapper = styled(WithCustomScrollbarWrapper)`
  width: 100%;
  margin: auto auto 0;
  overflow-y: auto;
  padding: 20px 10px 0 0;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const FormWrapper = styled.div<StyledChatProps>`
  max-width: 750px;
  width: 100%;
  margin-inline: auto;
  padding-right: 10px;

  ${({ isChatFullscreen }) =>
    isChatFullscreen &&
    css`
      padding: 0 10px 0 0;
    `}
`;

const CloseChatButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

Chat.displayName = 'ChatPopup';
