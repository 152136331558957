import { useMemo } from 'react';

import { useLoopedQuery } from 'services/rest/hooks';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { getUniqueSortedTimestamps } from 'common/utils/datetime';
import { useAoiNumber } from 'common/navigation/hooks';
import { areWaterDatesValid } from 'domain/water/typeguards';
import { useWaterViewOptions } from './viewOptions';

export const useWaterTimestamps = () => {
  const areaId = useAoiNumber();

  const { collection, isLoading: areTimestampsLoading } = useLoopedQuery(waterRepository.useFetchWaterDates, areaId, [
    areaId,
  ]);

  const { collection: datesMocked, isLoading: areMockedTimestampsLoading } = useLoopedQuery(
    waterRepository.useFetchWaterDatesMocked,
    areaId,
    [areaId],
  );

  const { mapViewOption } = useWaterViewOptions();

  const isComparisonView = mapViewOption?.value === 'comparison';

  const datesMerged = useMemo(
    () => [
      ...(areWaterDatesValid(collection) ? collection : []),
      ...(!isComparisonView && areWaterDatesValid(datesMocked) ? datesMocked : []),
    ],
    [collection.length, datesMocked.length, isComparisonView], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const timestampsObjects = datesMerged.map(({ img_date }) => img_date);

  const timestamps = getUniqueSortedTimestamps(Object.values(timestampsObjects));

  return {
    timestamps,
    areTimestampsLoading: areTimestampsLoading || areMockedTimestampsLoading,
  };
};
