import { type ListItemStatus } from 'common/types';
import { LayerManagerLegendItem } from 'components/layerManager/legacy/LegendItem';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { SNOW_COVER_COLOR } from 'domain/water/colors';
import { type SnowCoverIndicator } from 'domain/water/types';

interface Props {
  indicator?: SnowCoverIndicator;
}

export const SnowCoverLayerManagerLegendItem = ({ indicator }: Props) => {
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const toggleDataLayerVisibility = useLayerManagerStore.use.toggleDataLayerVisibility();

  const layerManagerOption = {
    id: indicator ? indicator.id : 1,
    name: indicator ? indicator.indicator_name : 'snow_cover',
    label_name: indicator ? indicator.indicator_label : 'Snow Cover',
    color: '',
    status: 'active' as ListItemStatus,
  };

  const iconColormap = indicator ? indicator.colormap : { 0: SNOW_COVER_COLOR, 1: SNOW_COVER_COLOR };

  return (
    <LayerManagerLegendItem
      option={layerManagerOption}
      objectVisibility={isDataLayerVisible}
      handleObjectsVisibility={toggleDataLayerVisibility}
      iconColormap={iconColormap}
    />
  );
};
